import { post as idV4Post } from '~/biz/helpers/apiHelper'
import { post as postFromExpress } from '~/api/express.api'
import PATHS from '~/api/paths'
import { AppType } from '~/types'
import { get, post } from '../fazzbizBaseApi'
import type {
  HubspotRegistrationType,
  OperationEmailType,
  ValidateAccountRequestType,
} from './common.type'

export const BankServiceQueryKey = {
  getBankList: 'getBankList',
}

export const BankServiceEndpoints = {
  SgBankList: 'dashboard/payments/sg/banks',
  IdBanksList: 'dashboard/payments/id/banks',
  ValidateBankAccount: 'api/v4/validation_services/bank_account_validation',
  SgValidateBankAccount: '/dashboard/payments/sg/banks/validate-bank-account',
  IdValidateBankAccount: '/dashboard/payments/id/banks/validate-bank-account',
}

export const HubspotEndpoints = {
  Registration: '/dashboard/hubspot',
}

export const OperationEndpoints = {
  TriggerEmail: '/dashboard/operations/emails',
}

export const SessionEndpoints = {
  ExtendSession: '/extend_session',
}

export const sendSystemEmail = (emailType: OperationEmailType) =>
  post(`${OperationEndpoints.TriggerEmail}/${emailType}`)

export const getBankList = (appType: AppType) => {
  let endpoint: typeof BankServiceEndpoints[keyof typeof BankServiceEndpoints] =
    BankServiceEndpoints.SgBankList

  if (appType === AppType.SINGAPORE) {
    endpoint = BankServiceEndpoints.SgBankList
  }

  if (appType === AppType.INDONESIA) {
    endpoint = BankServiceEndpoints.IdBanksList
  }

  return get(endpoint)
}

export const validateBankAccountByCountry = (
  appType: AppType,
  { accountNo, bankShortCode }: ValidateAccountRequestType
) => {
  let endpoint: typeof BankServiceEndpoints[keyof typeof BankServiceEndpoints] =
    BankServiceEndpoints.SgValidateBankAccount

  if (appType === AppType.SINGAPORE) {
    endpoint = BankServiceEndpoints.SgValidateBankAccount
  }

  if (appType === AppType.INDONESIA) {
    endpoint = BankServiceEndpoints.IdValidateBankAccount
  }

  return post(endpoint, {
    data: {
      attributes: {
        accountNo,
        bankShortCode,
      },
    },
  })
}

export const validateIdBankAccount = ({
  accountNo,
  bankShortCode,
}: {
  accountNo: string
  bankShortCode: string
}) =>
  idV4Post(
    PATHS.API_V4_VALIDATE_BANK_ACCOUNT,
    {
      data: {
        attributes: {
          accountNo,
          bankShortCode,
        },
      },
    },
    { 'Content-Type': 'application/vnd.api+json' }
  )

export const registerHubspot = (requestBody: HubspotRegistrationType) =>
  post(HubspotEndpoints.Registration, requestBody)

export const getBusinessEntityInformation = () => get(PATHS.FAZZBIZ_API_BUSINESS_ENTITY)

export const postExtendSession = () => postFromExpress<null>(SessionEndpoints.ExtendSession)
