import type { Reducer } from 'redux'
import type { ActionTypes, FilterValues, TSgdAccountVa, TPaynowQr } from './types'
import { SGDAccountActions } from './types'

const initialState = {
  filterValues: {
    startDate: '',
    endDate: '',
    currentPage: 1,
    pageLimit: 10,
    searchString: '',
    transactionTypes: [],
    sortType: '',
    isTransactionLoading: false,
    transactions: [],
    newTransactions: [],
    totalCount: 0,
    methodTypes: [],
  },
  walletBalance: {
    isWalletBalanceLoading: false,
    availableBalance: '',
    holdingBalance: '',
  },
  accountDetails: {
    loading: false,
    sgdAccountVa: {
      accountNumber: '',
      bankName: '',
      accountHolderName: '',
    },
    paynowQr: {
      imageBase64: '',
      VpaNumber: '',
    },
  },
}

type State = {
  filterValues: FilterValues
  walletBalance: {
    isWalletBalanceLoading: boolean
    availableBalance: string
    holdingBalance: string
  }
  accountDetails: {
    loading: boolean
    sgdAccountVa: TSgdAccountVa
    paynowQr: TPaynowQr
  }
}

export const sgdAccount: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case SGDAccountActions.GET_NEW_TRANSACTIONS: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          newTransactions: action.newTransactions,
          totalCount: action.totalCount,
        },
      }
    }

    case SGDAccountActions.GET_TRANSACTIONS: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          transactions: action.transactions,
          totalCount: action.totalCount,
        },
      }
    }

    case SGDAccountActions.SET_IS_TRANSACTION_LOADING: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          isTransactionLoading: action.isTransactionLoading,
        },
      }
    }

    case SGDAccountActions.SET_FILTERS: {
      const { filterValuesObject } = action

      const newTabFilterValues: FilterValues = {
        ...state.filterValues,
      }

      Object.keys(filterValuesObject).forEach((key: string) => {
        newTabFilterValues[key] = filterValuesObject[key]
      })

      return {
        ...state,
        filterValues: {
          ...newTabFilterValues,
        },
      }
    }

    case SGDAccountActions.RESET_FILTERS: {
      return {
        ...state,
        filterValues: initialState.filterValues,
      }
    }

    case SGDAccountActions.SET_IS_WALLET_BALANCE_LOADING: {
      return {
        ...state,
        walletBalance: {
          ...state.walletBalance,
          isWalletBalanceLoading: action.isWalletBalanceLoading,
        },
      }
    }

    case SGDAccountActions.SET_WALLET_BALANCE: {
      return {
        ...state,
        walletBalance: {
          ...state.walletBalance,
          availableBalance: action.availableBalance,
          holdingBalance: action.holdingBalance,
        },
      }
    }

    case SGDAccountActions.SET_ACCOUNT_DETAILS_LOADING: {
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          loading: action.isAccountDetailsLoading,
        },
      }
    }

    case SGDAccountActions.SET_SGD_ACCOUNT_VA: {
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          sgdAccountVa: action.sgdAccountVa,
        },
      }
    }

    case SGDAccountActions.SET_PAYNOW_QR: {
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          paynowQr: action.paynowQr,
        },
      }
    }

    default:
      return state
  }
}
