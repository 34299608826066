import type { Reducer } from 'redux'
import type { ActionTypes, LoanVerification, LocationDetail } from './types'
import { LoansVerificationActions } from './types'

const initialState = {
  loanVerification: {
    keypersonIdentityCardNumber: undefined,
    keypersonName: undefined,
    keypersonPlaceOfBirth: undefined,
    keypersonPosition: undefined,
    keypersonAddress: undefined,
    companyTypeName: undefined,
    companyName: undefined,
    companyEmail: undefined,
    companyPhoneNumber: undefined,
    companyNibNumber: undefined,
    companyTaxCardNumber: undefined,
    companyIdentityCardType: undefined,
    companyAddress: undefined,
    companyProvince: undefined,
    companyCity: undefined,
    companyDistrict: undefined,
    companyVillage: undefined,
    companyPostCode: undefined,
    companyLinkNib: undefined,
    companyLinkNpwp: undefined,
    companyLinkAktaPendirian: undefined,
    companyLinkSkt: undefined,
    companyLinkAktaPerubahan: undefined,
    keypersonLinkKtp: undefined,
    keypersonLinkSelfieWithKtp: undefined,
    keypersonLinkNpwp: undefined,
    keypersonDob: undefined,
    kcBizId: undefined,
    companyTotalEmployee: undefined,
    companyFounded: undefined,
    companyBuildingStatus: undefined,
    companyDescription: undefined,
    keypersonTaxCardNumber: undefined,
  },
  loanVerificationLoading: false,
  currentScreen: undefined,
  loanVerificationProvince: undefined,
  loanVerificationCity: undefined,
  loanVerificationWard: undefined,
  loanVerificationDistrict: undefined,
}

type State = {
  loanVerification: LoanVerification
  loanVerificationLoading: boolean
  currentScreen: string | undefined
  loanVerificationProvince: LocationDetail[] | undefined
  loanVerificationCity: LocationDetail[] | undefined
  loanVerificationWard: LocationDetail[] | undefined
  loanVerificationDistrict: LocationDetail[] | undefined
}

export const loanVerification: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case LoansVerificationActions.SET_LOAN_VERIFICATION: {
      return {
        ...state,
        loanVerification: action.loanVerification,
      }
    }

    case LoansVerificationActions.SET_LOAN_VERIFICATION_LOADING: {
      return {
        ...state,
        loanVerificationLoading: action.loanVerificationLoading,
      }
    }

    case LoansVerificationActions.SET_CURRENT_SCREEN: {
      return {
        ...state,
        currentScreen: action.currentScreen,
      }
    }

    case LoansVerificationActions.SET_LOAN_VERIFICATION_PROVINCE: {
      return {
        ...state,
        loanVerificationProvince: action.loanVerificationProvince,
      }
    }

    case LoansVerificationActions.SET_LOAN_VERIFICATION_CITY: {
      return {
        ...state,
        loanVerificationCity: action.loanVerificationCity,
      }
    }

    case LoansVerificationActions.SET_LOAN_VERIFICATION_WARD: {
      return {
        ...state,
        loanVerificationWard: action.loanVerificationWard,
      }
    }

    case LoansVerificationActions.SET_LOAN_VERIFICATION_DISTRICT: {
      return {
        ...state,
        loanVerificationDistrict: action.loanVerificationDistrict,
      }
    }

    default:
      return state
  }
}
