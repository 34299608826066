/* eslint-disable max-lines */
import { generatePath } from '~/hooks/useReactRouter'
import type { Route } from '~/types'
import { PERMISSIONS } from '~/types'
import { getIsStagingOrLocal } from '~/managers/ConfigManager'
import { sgSidebarLabels } from '~/constants/sidebarFeaturesLabel.config'
import AppUtils from '~/utils/AppUtils'
import { PATHS } from './paths'

const { lazyWithRetry } = AppUtils

const GetStarted = lazyWithRetry(
  () => import(/* webpackChunkName: "GetStarted" */ '~/bizRegional/pages/GetStarted')
)

const LandingPage = lazyWithRetry(
  () => import(/* webpackChunkName: "LandingPage" */ '~/bizRegional/pages/LandingPage')
)

const NotFound = lazyWithRetry(
  () => import(/* webpackChunkName: "NotFound" */ '~/bizRegional/pages/NotFound')
)

const Error = lazyWithRetry(() => import(/* webpackChunkName: "Error" */ '~/sharedModules/Error'))

const SGDAccount = lazyWithRetry(
  () => import(/* webpackChunkName: "SGDAccount" */ '~/bizRegional/pages/SGDAccount')
)

const SGDCashAccountDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "SGDCashAccountDetails" */ '~/bizRegional/pages/SGDCashAccountDetails'
    )
)

const PayoutFormDetails = lazyWithRetry(
  () => import(/* webpackChunkName: "PayoutFormDetails" */ '~/bizRegional/pages/PayoutFormDetails')
)

const TransactionDetails = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "TransactionDetails" */ '~/bizRegional/pages/TransactionDetails')
)

const Disbursements = lazyWithRetry(
  () => import(/* webpackChunkName: "Disbursements" */ '~/bizRegional/pages/Disbursements')
)

const DisbursementList = lazyWithRetry(
  () => import(/* webpackChunkName: "Disbursement List" */ '~/bizRegional/pages/DisbursementList')
)

const SendAPIList = lazyWithRetry(
  () => import(/* webpackChunkName: "Disbursement List" */ '~/bizRegional/pages/SendAPI')
)

const SendAPIDetail = lazyWithRetry(
  () => import(/* webpackChunkName: "Disbursement List" */ '~/bizRegional/pages/SendAPIDetail')
)

const SimulateDeposit = lazyWithRetry(
  () => import(/* webpackChunkName: "SimulateDeposit" */ '~/bizRegional/pages/SimulateDeposit')
)
const Deposit = lazyWithRetry(
  () => import(/* webpackChunkName: "Deposit" */ '~/bizRegional/pages/Deposit')
)

const DepositRequests = lazyWithRetry(
  () => import(/* webpackChunkName: "DepositRequests" */ '~/bizRegional/pages/DepositRequests')
)
const MyAccount = lazyWithRetry(
  () => import(/* webpackChunkName: "MyAccount" */ '~/bizRegional/pages/MyAccount')
)
const Withdraw = lazyWithRetry(
  () => import(/* webpackChunkName: "Withdraw" */ '~/bizRegional/pages/SGDAccount/Withdraw')
)

const TransferOut = lazyWithRetry(
  () => import(/* webpackChunkName: "TransferOut" */ '~/bizRegional/pages/SGDAccount/TransferOut')
)

const TransferOutInternational = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "TransferOutInternational" */ '~/bizRegional/pages/SGDAccount/TransferOut/International'
    )
)

const DeveloperTools = lazyWithRetry(
  () => import(/* webpackChunkName: "DeveloperTools" */ '~/bizRegional/pages/DeveloperTools')
)

const Team = lazyWithRetry(() => import(/* webpackChunkName: "Team" */ '~/bizRegional/pages/Team'))

const Invoicing = lazyWithRetry(
  () => import(/* webpackChunkName: "Invoicing" */ '~/bizRegional/pages/Invoicing')
)

const InvoicingDetails = lazyWithRetry(
  () => import(/* webpackChunkName: "InvoicingDetails" */ '~/bizRegional/pages/InvoicingDetails')
)

const NewVerification = lazyWithRetry(
  () => import(/* webpackChunkName: "NewVerification" */ '~/bizRegional/pages/NewVerification')
)

const VerificationMyInfoCallback = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "NewVerification" */ '~/bizRegional/pages/NewVerification/MyInfoDetails'
    )
)

const InternalTools = lazyWithRetry(
  () => import(/* webpackChunkName: "InternalTools" */ '~/sharedModules/InternalTools')
)

const BizKycInformation = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "BizKycInformation" */ '~/bizRegional/pages/BusinessVerification/BusinessInformations'
    )
)

const BizKycIntendedRelations = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "BizKycIntendedRelations" */ '~/bizRegional/pages/BusinessVerification/IntendedRelations'
    )
)

const BizKycBusinessActivities = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "BizKycBusinessActivities" */ '~/bizRegional/pages/BusinessVerification/BusinessActivities'
    )
)

const BizKycTaxResidency = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "BizKycTaxResidency" */ '~/bizRegional/pages/BusinessVerification/TaxResidency'
    )
)

const BizKycIndividualOfficers = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "BizKycIndividualOfficers" */ '~/bizRegional/pages/BusinessVerification/IndividualOfficers'
    )
)

const BizKycUploadDocuments = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "BizKycUploadDocuments" */ '~/bizRegional/pages/BusinessVerification/UploadDocuments'
    )
)

const BizKycDirectorsAndBo = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "BizKycDirectorsAndBo" */ '~/bizRegional/pages/BusinessVerification/DirectorsAndBo'
    )
)

const BizKycReview = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "BizKycReview" */ '~/bizRegional/pages/BusinessVerification/Review')
)

const BizKycComplete = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "BizKycComplete" */ '~/bizRegional/pages/BusinessVerification/Complete'
    )
)

const DebitCardsAll = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "DebitCardsAllCards" */ '../../sharedModules/DebitCards/AllCards')
)

const DebitCardsCreate = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "DebitCardsCreateCard" */ '../../sharedModules/DebitCards/CreateCard'
    )
)

const DebitCardsMyCard = lazyWithRetry(
  () => import(/* webpackChunkName: "DebitCardsMyCard" */ '../../sharedModules/DebitCards/MyCard')
)

const DebitCardsMyCardTransactionDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "DebitCardsMyCardTransactionDetails" */ '../../sharedModules/DebitCards/TransactionDetails'
    )
)

const CustomerCards = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "CustomerCards" */ '../../sharedModules/DebitCards/CustomerCards')
)

const CustomerCardsDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "CustomerCardsDetails" */ '../../sharedModules/DebitCards/CustomerCards/Transactions/TransactionsDetails'
    )
)

const CustomerCardsFraudDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "CustomerCardsFraudDetails" */ '../../sharedModules/DebitCards/CustomerCards/Fraud/FraudDetails'
    )
)

const CustomerCardsCustomersDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "CustomerCardsCustomersDetails" */ '../../sharedModules/DebitCards/CustomerCards/Customers/CustomersDetails'
    )
)

const CustomerCardsCardsDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "CustomerCardsCardsDetails" */ '../../sharedModules/DebitCards/CustomerCards/Cards/CardDetails'
    )
)

const Earn = lazyWithRetry(() => import(/* webpackChunkName: "Earn" */ '~/bizRegional/pages/Earn'))

const EarnDeposit = lazyWithRetry(
  () => import(/* webpackChunkName: "EarnDeposit" */ '~/bizRegional/pages/Earn/Deposit')
)

const EarnWithdraw = lazyWithRetry(
  () => import(/* webpackChunkName: "EarnWithdraw" */ '~/bizRegional/pages/Earn/Withdraw')
)

const EarnTransaction = lazyWithRetry(
  () => import(/* webpackChunkName: "EarnTransaction" */ '~/bizRegional/pages/Earn/Transactions')
)

const UsdEarn = lazyWithRetry(
  () => import(/* webpackChunkName: "Earn" */ '~/bizRegional/pages/UsdEarn')
)

const UsdEarnDeposit = lazyWithRetry(
  () => import(/* webpackChunkName: "EarnDeposit" */ '~/bizRegional/pages/UsdEarn/Deposit')
)

const UsdEarnWithdraw = lazyWithRetry(
  () => import(/* webpackChunkName: "EarnDeposit" */ '~/bizRegional/pages/UsdEarn/Withdraw')
)

const USDAccount = lazyWithRetry(
  () => import(/* webpackChunkName: "USDAccount" */ '~/bizRegional/pages/USDAccount')
)

const USDAccountWithdraw = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "USDAccountWithdraw" */ '~/bizRegional/pages/USDAccount/Withdraw')
)

const USDAccountAddRecipient = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "USDAccountAddRecipient" */ '~/bizRegional/pages/USDAccount/AddRecipient'
    )
)

const ReceiveTransactions = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "ReceiveTransactions" */ '~/bizRegional/pages/ReceiveTransactions')
)

const ReceiveTransactionDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ReceiveTransactionDetails" */ '~/bizRegional/pages/ReceiveTransactionDetails'
    )
)

const RecipientsTable = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ReceiveTransactionDetails" */ '~/bizRegional/pages/Recipients/RecipientsTable'
    )
)

const CreditLineDashboard = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "CreditLineDashboard" */ '~/bizRegional/pages/CreditLineDashboard')
)

const CreditLineDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "CreditLineDashboard" */ '~/bizRegional/pages/CreditLineTransactionDetails'
    )
)

const TermLoanDashboard = lazyWithRetry(
  () => import(/* webpackChunkName: "TermLoanDashboard" */ '~/bizRegional/pages/TermLoanDashboard')
)

const TermLoanRepayInstallment = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "TermLoanRepayInstallment" */ '~/bizRegional/pages/TermLoanDashboard/RepayInstallment'
    )
)

const TermLoanDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "TermLoanDetails" */ '~/bizRegional/pages/TermLoanDashboard/LoanDetails'
    )
)

const TermLoanBillDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "TermLoanBillDetails" */ '~/bizRegional/pages/TermLoanDashboard/BillDetails'
    )
)

const TermLoanPaymentDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "TermLoanPaymentDetails" */ '~/bizRegional/pages/TermLoanDashboard/PaymentDetails'
    )
)

const TermLoanDisbursementDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "TermLoanDisbursementDetails" */ '~/bizRegional/pages/TermLoanDashboard/DisbursementDetails'
    )
)

const TermLoanTransactionDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "TermLoanTransactionDetails" */ '~/bizRegional/pages/TermLoanDashboard/TransactionDetails'
    )
)

const RegisterMerchant = lazyWithRetry(
  () => import(/* webpackChunkName: "RegisterMerchant" */ '~/bizRegional/pages/RegisterMerchant')
)

const AccountSelector = lazyWithRetry(
  () => import(/* webpackChunkName: "AccountSelector" */ '~/components/AccountSelector')
)

const PhysicalCardholderKYC = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PhysicalCardholderKYC" */ '~/bizRegional/pages/PhysicalCardholderKYC'
    )
)

const PhysicalCardVerificationMyInfoCallback = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PhysicalCardVerificationMyInfoCallback" */ '~/bizRegional/pages/PhysicalCardholderKYC/MyInfoFlow'
    )
)

const Integrations = lazyWithRetry(
  () => import(/* webpackChunkName: "Integrations" */ '~/bizRegional/pages/Integrations')
)

const Blank = lazyWithRetry(() => import(/* webpackChunkName: "Blank" */ '~/components/Blank'))

const PendingApprovals = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PaymentApprovals" */ '~/bizRegional/pages/PaymentApprovals/PendingApprovals/PendingApprovals'
    )
)

const PendingApprovalsTransactionDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PendingApprovalsTransactionDetails" */ '~/bizRegional/pages/PaymentApprovals/PendingApprovalsTransactionDetails/PendingApprovalsTransactionDetails'
    )
)

const PaymentApprovalSettings = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PaymentApprovalSettings" */ '~/bizRegional/pages/PaymentApprovals/ApprovalSettings'
    )
)

const regionalRoutes: Route[] = [
  {
    path: PATHS.LANDING_PAGE,
    component: LandingPage,
    exact: true,
    sideBar: sgSidebarLabels.Home,
    breadcrumbName: 'Home',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_LANDING_PAGE,
  },
  {
    path: PATHS.ACCOUNTS,
    component: SGDAccount,
    // note: permission at main route will affect
    // - menu visibility (no regard to path)
    // - access to component via page route defined in path
    // (coz the path+compone permission pair here defined above actual clickable link in subroute,
    // the permission defined in subroute only affect submenu visibility,
    // meanwhile route access depend on first permission declaration)
    // this is temp hack just use PERMISSIONS.TRANSACTION_ACCOUNT_BALANCE (owner/admin/operations) to control menu visibility
    // coz it covers bigger set than PERMISSIONS.USD_ACCOUNT (admin/operations/owner with flipper whitelist)
    // TODO: use flexible permission that covers all items in subRoutes
    permission: PERMISSIONS.TRANSACTION_ACCOUNT_BALANCE,
    exact: true,
    sideBar: sgSidebarLabels.Accounts,
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_WITHDRAWAL,
    subRoutes: [
      {
        sideBar: sgSidebarLabels.SGDCashAccount,
        path: PATHS.SGD_ACCOUNT,
        permission: PERMISSIONS.TRANSACTION_ACCOUNT_BALANCE,
        hasSandboxMode: true,
        sandboxPermission: PERMISSIONS.SB_WITHDRAWAL,
      },
      {
        sideBar: sgSidebarLabels.USDCashAccount,
        path: PATHS.USD_ACCOUNT,
        permission: PERMISSIONS.USD_ACCOUNT,
      },
    ],
  },
  {
    path: PATHS.USD_ACCOUNT,
    component: USDAccount,
    permission: PERMISSIONS.USD_ACCOUNT,
    breadcrumbName: 'USD Cash Account',
    pageTitle: 'USD Cash Account',
    exact: true,
  },
  {
    path: PATHS.USD_ACCOUNT_WITHDRAW,
    component: USDAccountWithdraw,
    permission: PERMISSIONS.USD_ACCOUNT_WALLEX_ACCOUNT_VERIFICATION,
    breadcrumbName: 'Transfer Out',
    pageTitle: 'Transfer Out',
    exact: true,
  },
  {
    path: PATHS.SGD_ACCOUNT_ADD_RECIPIENT,
    component: USDAccountAddRecipient,
    permission: PERMISSIONS.USD_ACCOUNT_WALLEX_ACCOUNT_VERIFICATION,
    breadcrumbName: 'Add New Recipient',
    pageTitle: 'Add New Recipient',
    exact: true,
    hasSandboxMode: true,
  },
  {
    path: PATHS.USD_ACCOUNT_ADD_RECIPIENT,
    component: USDAccountAddRecipient,
    permission: PERMISSIONS.USD_ACCOUNT_WALLEX_ACCOUNT_VERIFICATION,
    breadcrumbName: 'Add New Recipient',
    pageTitle: 'Add New Recipient',
    exact: true,
    hasSandboxMode: true,
  },
  {
    path: PATHS.SGD_ACCOUNT_ADD_RECIPIENT,
    component: USDAccountAddRecipient,
    breadcrumbName: 'Add New Recipient',
    pageTitle: 'Add New Recipient',
    exact: true,
  },
  {
    path: PATHS.XFERS_SEND,
    component: SendAPIList,
    permission: PERMISSIONS.API_MENU,
    breadcrumbName: 'Send API',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_PAYMENT_API,
    exact: true,
  },
  {
    path: PATHS.XFERS_SEND_DETAIL,
    component: SendAPIDetail,
    permission: PERMISSIONS.TRANSACTION,
    breadcrumbName: 'Send API Details',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_PAYMENT_API,
    exact: true,
  },
  {
    path: PATHS.PAYOUT_FORM_TRANSACTIONS,
    component: DisbursementList,
    permission: PERMISSIONS.PAY_TRANSACTION_LIST,
    breadcrumbName: 'Payout Form',
    exact: true,
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_PAY_MENU,
  },
  {
    path: PATHS.PAYOUT_FORM_DETAILS,
    component: PayoutFormDetails,
    permission: PERMISSIONS.PAY_TRANSACTION_LIST,
    breadcrumbName: 'Transaction Details',
    exact: true,
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_PAY_MENU,
  },
  {
    path: PATHS.CREDITLINE_DETAILS,
    permission: PERMISSIONS.SG_CREDITLINE,
    component: CreditLineDetails,
    breadcrumbName: 'Credit Line Details',
  },
  {
    path: PATHS.RECEIVE_TRANSACTIONS,
    component: ReceiveTransactions,
    permission: PERMISSIONS.API_MENU,
    breadcrumbName: 'Accept API',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_PAYMENT_API,
    exact: true,
  },
  {
    path: PATHS.MENU_PAY,
    component: NotFound,
    exact: true,
    permission: PERMISSIONS.DISBURSEMENTS,
    sideBar: sgSidebarLabels.Pay,
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_PAY_MENU,
    subRoutes: [
      {
        sideBar: sgSidebarLabels.PayoutForm,
        breadcrumbName: 'Payout Form',
        path: PATHS.PAYOUT_FORM_TRANSACTIONS,
        permission: PERMISSIONS.PAY_TRANSACTION_LIST,
        hasSandboxMode: true,
        sandboxPermission: PERMISSIONS.SB_PAY_MENU,
      },
      {
        sideBar: sgSidebarLabels.PendingApprovals,
        path: PATHS.PAYOUT_FORM_PENDING_APPROVALS,
      },
      {
        sideBar: sgSidebarLabels.Recipients,
        breadcrumbName: 'Recipients',
        path: PATHS.RECIPIENTS,
        permission: PERMISSIONS.SG_RECIPIENTS,
        hasSandboxMode: true,
        sandboxPermission: PERMISSIONS.SB_PAY_MENU,
      },
    ],
  },
  {
    path: PATHS.PAYOUT_FORM_PENDING_APPROVALS,
    exact: true,
    component: PendingApprovals,
    breadcrumbName: 'Pending My Approvals',
  },
  {
    path: PATHS.PAYOUT_FORM_PENDING_APPROVALS_DETAILS,
    exact: true,
    component: PendingApprovalsTransactionDetails,
    breadcrumbName: 'Transaction Details',
  },
  {
    path: PATHS.PAYOUT_FORM_APPROVAL_SETTINGS,
    exact: true,
    component: PaymentApprovalSettings,
    breadcrumbName: 'Payment Approval Settings',
  },
  {
    path: PATHS.INVOICING,
    exact: true,
    component: Invoicing,
    permission: PERMISSIONS.INVOICING,
    sideBar: sgSidebarLabels.Receive,
    breadcrumbName: 'Payment Link',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_INVOICING,
    subRoutes: [
      {
        sideBar: sgSidebarLabels.PaymentLink,
        path: PATHS.INVOICING,
        permission: PERMISSIONS.INVOICING,
        hasSandboxMode: true,
        sandboxPermission: PERMISSIONS.SB_INVOICING,
      },
    ],
  },
  {
    path: PATHS.MENU_PAYMENTS_API,
    exact: true,
    component: NotFound,
    permission: PERMISSIONS.API_MENU,
    sideBar: sgSidebarLabels.PaymentsAPI,
    breadcrumbName: 'Payments API',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_PAYMENT_API,
    subRoutes: [
      {
        sideBar: sgSidebarLabels.SendAPI,
        breadcrumbName: 'Xfers Send',
        path: PATHS.XFERS_SEND,
        permission: PERMISSIONS.API_MENU,
        hasSandboxMode: true,
        sandboxPermission: PERMISSIONS.SB_PAYMENT_API,
      },
      {
        sideBar: sgSidebarLabels.AcceptAPI,
        path: generatePath(PATHS.RECEIVE_TRANSACTIONS) as PATHS,
        permission: PERMISSIONS.API_MENU,
        hasSandboxMode: true,
        sandboxPermission: PERMISSIONS.SB_PAYMENT_API,
      },
    ],
  },
  {
    path: PATHS.RECEIVE_TRANSACTIONS_DETAIL,
    component: ReceiveTransactionDetails,
    permission: PERMISSIONS.RECEIVE_TRANSACTION_LIST,
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_PAYMENT_API,
    exact: true,
    breadcrumbName: 'Accept API Details',
  },
  {
    path: PATHS.RECIPIENTS,
    component: RecipientsTable,
    breadcrumbName: 'Recipients',
    pageTitle: 'Recipients',
    permission: PERMISSIONS.SG_RECIPIENTS,
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_PAY_MENU,
  },
  {
    path: PATHS.CREDITLINE,
    component: CreditLineDashboard,
    breadcrumbName: 'Credit Line',
    pageTitle: 'Credit Line',
  },
  {
    path: PATHS.TERM_LOAN,
    component: TermLoanDashboard,
    breadcrumbName: 'Term Loan',
    pageTitle: 'Term Loan',
    permission: PERMISSIONS.TERM_LOAN,
    exact: true,
  },
  {
    path: PATHS.TERM_LOAN_REPAY,
    component: TermLoanRepayInstallment,
    breadcrumbName: 'Repay',
    permission: PERMISSIONS.TERM_LOAN,
    pageTitle: 'Loan Details',
    exact: true,
  },
  {
    path: PATHS.TERM_LOAN_DETAILS,
    component: TermLoanDetails,
    breadcrumbName: 'Loan Details',
    permission: PERMISSIONS.TERM_LOAN,
    pageTitle: 'Loan Details',
    exact: true,
  },
  {
    path: PATHS.TERM_LOAN_BILL_DETAILS,
    component: TermLoanBillDetails,
    breadcrumbName: 'Installment',
    permission: PERMISSIONS.TERM_LOAN,
    pageTitle: 'Installment Details',
    exact: true,
  },
  {
    path: PATHS.TERM_LOAN_PAYMENT_DETAILS,
    component: TermLoanPaymentDetails,
    breadcrumbName: 'Repayment Details',
    permission: PERMISSIONS.TERM_LOAN,
    pageTitle: 'Payment Details',
    exact: true,
  },
  {
    path: PATHS.TERM_LOAN_DISBURSEMENT_DETAILS,
    component: TermLoanDisbursementDetails,
    breadcrumbName: 'Transaction Details',
    permission: PERMISSIONS.TERM_LOAN,
    pageTitle: 'Disbursement Details',
    exact: true,
  },
  {
    path: PATHS.TERM_LOAN_TRANSACTION_DETAILS,
    permission: PERMISSIONS.TERM_LOAN,
    component: TermLoanTransactionDetails,
    breadcrumbName: 'Term Loan Details',
  },
  {
    path: PATHS.SGD_ACCOUNT,
    component: SGDAccount,
    permission: PERMISSIONS.TRANSACTION_ACCOUNT_BALANCE,
    breadcrumbName: 'SGD Cash Account',
    pageTitle: 'SGD Cash Account',
    exact: true,
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_WITHDRAWAL,
  },
  {
    path: PATHS.SGD_ACCOUNT_DETAIL,
    component: SGDCashAccountDetails,
    permission: PERMISSIONS.TRANSACTION,
    breadcrumbName: 'Transaction Details',
    pageTitle: 'Transaction Details',
    exact: true,
    hasSandboxMode: true,
  },
  {
    path: PATHS.TRANSACTION_DETAILS,
    component: TransactionDetails,
    permission: PERMISSIONS.TRANSACTION,
    exact: true,
    hasSandboxMode: true,
  },
  {
    path: PATHS.LOANS,
    component: NotFound,
    exact: true,
    permission: PERMISSIONS.SG_LOAN,
    sideBar: sgSidebarLabels.Loans,
    subRoutes: [
      {
        path: PATHS.CREDITLINE,
        permission: PERMISSIONS.SG_CREDITLINE,
        sideBar: sgSidebarLabels.CreditLine,
      },
      {
        path: PATHS.TERM_LOAN,
        permission: PERMISSIONS.TERM_LOAN,
        sideBar: sgSidebarLabels.TermLoan,
      },
    ],
  },
  {
    path: PATHS.DEBIT_CARDS_MYCARD,
    component: DebitCardsMyCard,
    exact: true,
    breadcrumbName: 'Cards',
    sideBar: 'Cards',
    hasSandboxMode: false,
    permission: PERMISSIONS.MY_CARD,
    subRoutes: [
      {
        sideBar: sgSidebarLabels.AllCards,
        path: PATHS.DEBIT_CARDS_ALL,
        permission: PERMISSIONS.ALL_COMPANY_CARDS,
      },
      {
        sideBar: sgSidebarLabels.CustomerCards,
        path: PATHS.CUSTOMER_CARDS,
        permission: PERMISSIONS.CUSTOMERS_CARDS,
      },
      {
        sideBar: sgSidebarLabels.MyCard,
        path: PATHS.DEBIT_CARDS_MYCARD,
        permission: PERMISSIONS.MY_CARD,
      },
    ],
  },
  {
    path: PATHS.DEBIT_CARDS_ALL,
    component: DebitCardsAll,
    permission: PERMISSIONS.ALL_COMPANY_CARDS,
    breadcrumbName: 'All Cards',
    pageTitle: 'All Cards',
    exact: true,
  },
  {
    path: PATHS.DEBIT_CARDS_CREATE_CARD,
    component: DebitCardsCreate,
    permission: PERMISSIONS.ALL_COMPANY_CARDS,
    breadcrumbName: 'New Card',
    pageTitle: 'New Card',
    exact: true,
  },
  {
    path: PATHS.DEBIT_CARDS_MYCARD,
    component: DebitCardsMyCard,
    permission: PERMISSIONS.MY_CARD,
    breadcrumbName: 'My Card',
    pageTitle: 'My Card',
    exact: true,
  },
  {
    path: PATHS.DEBIT_CARDS_DETAIL,
    component: DebitCardsMyCard,
    permission: PERMISSIONS.MY_CARD, // Only Managers can check each card's detail
    breadcrumbName: 'Card Detail',
    pageTitle: 'Card Detail',
    exact: true,
  },
  {
    path: PATHS.DEBIT_CARDS_MYCARD_TRANSACTION_DETAILS,
    component: DebitCardsMyCardTransactionDetails,
    permission: PERMISSIONS.MY_CARD,
    breadcrumbName: 'Transaction Details',
    pageTitle: 'Transaction Details',
    exact: true,
  },
  {
    path: PATHS.CUSTOMER_CARDS,
    component: CustomerCards,
    permission: PERMISSIONS.CUSTOMERS_CARDS,
    breadcrumbName: 'Customer Cards',
    pageTitle: 'Customer Cards',
    exact: true,
  },
  {
    path: PATHS.CUSTOMER_CARDS_TRANSACTION_DETAILS,
    component: CustomerCardsDetails,
    permission: PERMISSIONS.CUSTOMERS_CARDS,
    breadcrumbName: 'Transaction Details',
  },
  {
    path: PATHS.CUSTOMER_CARDS_FRAUD_DETAILS,
    component: CustomerCardsFraudDetails,
    breadcrumbName: 'Fraud Details',
    permission: PERMISSIONS.CUSTOMERS_CARDS,
  },
  {
    path: PATHS.CUSTOMER_CARDS_CARD_DETAILS,
    component: CustomerCardsCardsDetails,
    breadcrumbName: 'Card Details',
    permission: PERMISSIONS.CUSTOMERS_CARDS,
  },
  {
    path: PATHS.CUSTOMER_CARDS_CUSTOMERS_DETAILS,
    component: CustomerCardsCustomersDetails,
    breadcrumbName: 'Customer Details',
    permission: PERMISSIONS.CUSTOMERS_CARDS,
  },
  {
    path: PATHS.EARN,
    component: Earn,
    permission: PERMISSIONS.USD_EARN,
    exact: true,
    breadcrumbName: sgSidebarLabels.Earn,
    sideBar: 'Earn',
    subRoutes: [
      {
        sideBar: sgSidebarLabels.SGDEarnAccount,
        path: PATHS.EARN,
        permission: PERMISSIONS.EARN,
      },
      {
        sideBar: sgSidebarLabels.USDEarnAccount,
        path: PATHS.USD_EARN,
        permission: PERMISSIONS.USD_EARN,
      },
    ],
  },
  {
    path: PATHS.EARN,
    component: Earn,
    permission: PERMISSIONS.EARN,
    breadcrumbName: 'SGD Earn Account',
    pageTitle: 'SGD Earn Account',
    exact: true,
  },
  {
    path: PATHS.USD_EARN,
    component: UsdEarn,
    permission: PERMISSIONS.USD_EARN,
    breadcrumbName: 'USD Earn Account',
    pageTitle: 'USD Earn Account',
    exact: true,
  },
  {
    path: PATHS.EARN_TRANSACTIONS,
    component: EarnTransaction,
    permission: PERMISSIONS.EARN,
    breadcrumbName: 'Transaction History',
    exact: true,
  },
  {
    path: PATHS.EARN_ADD_FUNDS,
    component: EarnDeposit,
    permission: PERMISSIONS.EARN_EXECUTE,
    breadcrumbName: 'Add Funds',
    exact: true,
  },
  {
    path: PATHS.EARN_WITHDRAW,
    component: EarnWithdraw,
    permission: PERMISSIONS.EARN_EXECUTE,
    breadcrumbName: 'Withdraw',
    exact: true,
  },
  {
    path: PATHS.USD_EARN_DEPOSIT,
    component: UsdEarnDeposit,
    permission: PERMISSIONS.USD_EARN,
    breadcrumbName: 'Add Funds',
    exact: true,
  },
  {
    path: PATHS.USD_EARN_WITHDRAW,
    component: UsdEarnWithdraw,
    permission: PERMISSIONS.USD_EARN,
    breadcrumbName: 'Withdraw',
    exact: true,
  },
  {
    path: PATHS.DISBURSEMENTS,
    component: Disbursements,
    breadcrumbName: 'Payout Form',
    permission: PERMISSIONS.DISBURSEMENTS,
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_PAY_MENU,
  },
  {
    path: PATHS.INVOICING_DETAILS,
    component: InvoicingDetails,
    permission: PERMISSIONS.INVOICING,
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_INVOICING,
    breadcrumbName: 'Transaction Details',
  },
  {
    path: PATHS.DEPOSIT,
    component: Deposit,
    permission: PERMISSIONS.DEPOSIT,
    exact: true,
    breadcrumbName: 'Deposit',
    sandboxPermission: PERMISSIONS.SB_DEPOSIT,
    hasSandboxMode: true,
  },
  {
    path: PATHS.DEPOSIT_REQUESTS,
    component: DepositRequests,
    permission: PERMISSIONS.DEPOSIT,
    exact: true,
    breadcrumbName: 'Deposit Requests',
    sandboxPermission: PERMISSIONS.SB_DEPOSIT,
    hasSandboxMode: true,
  },
  {
    path: PATHS.SIMULATE_DEPOSIT,
    component: SimulateDeposit,
    exact: true,
    breadcrumbName: 'Simulate Deposit',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_DEPOSIT,
  },
  {
    path: PATHS.WITHDRAW,
    component: Withdraw,
    permission: PERMISSIONS.WITHDRAW,
    exact: true,
    breadcrumbName: 'Withdraw',
    pageTitle: 'Withdraw',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_WITHDRAWAL,
  },
  {
    path: PATHS.TRANSFER_OUT,
    component: TransferOut,
    permission: PERMISSIONS.TRANSFER_OUT,
    exact: true,
    breadcrumbName: 'Transfer Out',
    pageTitle: 'Transfer Out',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_WITHDRAWAL,
  },
  {
    path: PATHS.TRANSFER_OUT_INTERNATIONAL,
    component: TransferOutInternational,
    permission: PERMISSIONS.TRANSFER_OUT,
    exact: true,
    breadcrumbName: 'Transfer Out',
    pageTitle: 'Transfer Out',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_WITHDRAWAL,
  },
  {
    path: PATHS.MANAGE_ACCOUNT,
    component: MyAccount,
    exact: true,
    breadcrumbName: 'My Account',
  },
  {
    path: PATHS.DEVELOPER_TOOLS,
    component: DeveloperTools,
    permission: PERMISSIONS.DEVELOPER_TOOLS_SG,
    sideBar: 'Developer Tools',
    breadcrumbName: 'Developer Tools',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_DEVELOPER_TOOLS,
  },
  {
    path: PATHS.TEAM,
    component: Team,
    permission: PERMISSIONS.TEAM_READ,
    breadcrumbName: 'Team',
  },
  {
    path: PATHS.VERIFICATION,
    component: NewVerification,
    permission: PERMISSIONS.VERIFICATION,
    exact: true,
    breadcrumbName: 'Verification',
    pageTitle: 'Account Manager Verification',
  },
  {
    path: PATHS.BIZ_KYC_INFORMATION,
    component: BizKycInformation,
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
  },
  {
    path: PATHS.BIZ_KYC_INTENDED_RELATIONS,
    component: BizKycIntendedRelations,
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
  },
  {
    path: PATHS.BIZ_KYC_BUSINESS_ACTIVITIES,
    component: BizKycBusinessActivities,
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
  },
  {
    path: PATHS.BIZ_KYC_TAX_RESIDENCY,
    component: BizKycTaxResidency,
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Tax Residency',
  },
  {
    path: PATHS.BIZ_KYC_INDIVIDUAL_OFFICERS,
    component: BizKycIndividualOfficers,
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Individual Officers / Authorized Representatives ',
  },
  {
    path: PATHS.BIZ_KYC_UPLOAD_DOCUMENTS,
    component: BizKycUploadDocuments,
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
  },
  {
    path: PATHS.BIZ_KYC_DIRECTORS_AND_BO,
    component: BizKycDirectorsAndBo,
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
  },
  {
    path: PATHS.BIZ_KYC_REVIEW,
    component: BizKycReview,
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
  },
  {
    path: PATHS.BIZ_KYC_COMPLETE,
    component: BizKycComplete,
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
  },
  {
    path: PATHS.PHYSICAL_CARDHOLDER_KYC,
    component: PhysicalCardholderKYC,
    exact: true,
    breadcrumbName: 'Verification',
    pageTitle: 'Account Verification',
  },
  {
    path: PATHS.PHYSICAL_CARD_MY_INFO_CALLBACK,
    component: PhysicalCardVerificationMyInfoCallback,
    breadcrumbName: 'Verification',
    pageTitle: 'Account Verification',
  },
  {
    path: PATHS.VERIFICATION_MY_INFO_CALLBACK,
    component: VerificationMyInfoCallback,
    breadcrumbName: 'Verification',
    pageTitle: 'Account Manager Verification',
  },
  {
    path: PATHS.INTEGRATION,
    component: Integrations,
    exact: true,
    breadcrumbName: 'Integrations',
    pageTitle: 'Integrations',
    permission: PERMISSIONS.INTEGRATION,
    sideBar: sgSidebarLabels.Integrations,
  },
  {
    path: PATHS.BLANK,
    component: Blank,
    exact: true,
    pageTitle: 'Blank',
  },
]

const customLayoutRoutes: Route[] = [
  {
    path: PATHS.REGISTER_MERCHANT,
    component: RegisterMerchant,
    exact: true,
    breadcrumbName: 'Register Merchant',
    pageTitle: 'Register a new Merchant Account',
  },
  {
    path: PATHS.ACCOUNT_SELECTOR,
    component: AccountSelector,
    exact: true,
    pageTitle: 'Select your account',
  },
]

const errorRoutes: Route[] = [
  {
    path: PATHS.ERROR,
    component: Error,
  },
  {
    component: NotFound,
  },
]

const nonProductionRoutes: Route[] = [
  {
    path: PATHS.GET_STARTED,
    component: GetStarted,
    exact: true,
    sideBar: sgSidebarLabels.GetStarted,
  },
  {
    path: PATHS.INTERNAL_TOOLS,
    component: InternalTools,
    exact: true,
    breadcrumbName: 'Internal Tools',
    sideBar: sgSidebarLabels.InternalTools,
  },
]

function getRoutes() {
  if (getIsStagingOrLocal()) return [...nonProductionRoutes, ...regionalRoutes, ...errorRoutes]
  return [...regionalRoutes, ...errorRoutes]
}

export function getCustomLayoutRoutes() {
  return [...customLayoutRoutes]
}

export default getRoutes()
