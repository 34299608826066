import { Label } from 'fds3-src/themes/Typography'
import { fdsTheme } from 'fds3-src/themes/theme-fds3'
import { Select } from 'formik-antd'
import { ArrowDown } from '@fazz/design-system-icons'
import { BaseSelectStyles, dropdownStyle } from '../../Select/Select.styles'
import { StyledSelect, StyledFormItem } from './FormikSelect.styles'
import type { FormikSelectProps } from './FormikSelect.types'

const { spacing, colors } = fdsTheme
export function FormikSelect({
  label,
  name,
  children,
  style,
  selectStyles,
  isDisabled,
  helperText,
  placeholder,
  defaultValue,
  onChange,
  className,
  mode,
  optionFilterProp,
  optionLabelProp,
}: FormikSelectProps) {
  return (
    <div style={style} className={className}>
      {label && (
        <Label
          size="md"
          style={{
            display: 'flex',
            color: colors.labelInput,
            marginBottom: spacing.xs,
          }}
        >
          {label}
        </Label>
      )}
      <StyledFormItem name={name} extra={helperText}>
        <StyledSelect
          name={name}
          placeholder={placeholder}
          style={selectStyles}
          dropdownClassName={dropdownStyle}
          disabled={isDisabled}
          defaultValue={defaultValue}
          onChange={onChange}
          mode={mode}
          optionFilterProp={optionFilterProp}
          optionLabelProp={optionLabelProp}
          suffixIcon={<ArrowDown width={16} height={16} color={colors.onNeutral} />}
        >
          {children}
        </StyledSelect>
        <BaseSelectStyles />
      </StyledFormItem>
    </div>
  )
}

FormikSelect.Option = Select.Option
FormikSelect.displayName = 'FormikSelect'
