import * as React from 'react'

function CheckCircleFilled(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.4598 5.54079C26.1937 3.27656 23.3071 1.73502 20.165 1.11106C17.0228 0.487096 13.7663 0.808714 10.8069 2.03526C7.84756 3.2618 5.31828 5.33821 3.53881 8.00198C1.75934 10.6658 0.80957 13.7973 0.80957 17.0008C0.80957 20.2043 1.75934 23.3358 3.53881 25.9996C5.31828 28.6634 7.84756 30.7398 10.8069 31.9663C13.7663 33.1929 17.0228 33.5145 20.165 32.8905C23.3071 32.2666 26.1937 30.725 28.4598 28.4608C29.9656 26.9563 31.1601 25.1698 31.9751 23.2034C32.7901 21.2371 33.2096 19.1294 33.2096 17.0008C33.2096 14.8722 32.7901 12.7645 31.9751 10.7981C31.1601 8.83176 29.9656 7.04528 28.4598 5.54079V5.54079ZM24.4998 13.7908L16.9998 21.2908C16.7582 21.5371 16.47 21.7327 16.1519 21.8662C15.8338 21.9998 15.4923 22.0686 15.1473 22.0686C14.8023 22.0686 14.4608 21.9998 14.1427 21.8662C13.8247 21.7327 13.5364 21.5371 13.2948 21.2908L9.49983 17.6308C9.30111 17.4175 9.19292 17.1355 9.19807 16.844C9.20321 16.5526 9.32128 16.2745 9.5274 16.0684C9.73352 15.8622 10.0116 15.7442 10.303 15.739C10.5945 15.7339 10.8766 15.8421 11.0898 16.0408L14.8248 19.7608C15.0048 19.9558 15.1548 19.9558 15.3498 19.7608L22.8498 12.2608C22.9509 12.1433 23.0753 12.0479 23.215 11.9807C23.3547 11.9135 23.5068 11.8759 23.6617 11.8703C23.8167 11.8647 23.9711 11.8911 24.1153 11.948C24.2595 12.0049 24.3904 12.0909 24.4998 12.2008C24.606 12.3042 24.6903 12.4278 24.7479 12.5644C24.8055 12.7009 24.8352 12.8476 24.8352 12.9958C24.8352 13.144 24.8055 13.2907 24.7479 13.4272C24.6903 13.5637 24.606 13.6874 24.4998 13.7908V13.7908Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CheckCircleFilled
