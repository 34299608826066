import * as React from 'react'

function SvgVecMobile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 130 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.8}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.003 15.228c1.326-4.192 2.56-6.802 3.7-7.83 3.157-2.849 7.522-1.299 8.45-1.077 3.26.782 2.2-4.364 4.997-5.41 1.864-.697 3.397.155 4.6 2.556 1.065-2.24 2.688-3.266 4.87-3.083 3.271.277 4.416 11.328 8.912 8.909 4.497-2.42 10.01-2.973 12.365.623.51.778.704-.429 4.166-4.55 3.462-4.122 6.914-5.938 13.996-3.538 3.22 1.09 5.868 4.045 7.947 8.864 0 6.878 5.06 10.95 15.178 12.213 15.179 1.895 3.397 18.214-15.178 22.993-18.576 4.78-61.343 7.36-84.55-4.716-15.473-8.049-11.957-16.7 10.546-25.954h.001z"
        fill="url(#vec_mobile_svg__paint0_linear)"
      />
      <path
        d="M64.987 76.008c15.457 0 27.987-2.9 27.987-6.478s-12.53-6.478-27.987-6.478C49.53 63.052 37 65.952 37 69.53s12.53 6.478 27.987 6.478z"
        fill="url(#vec_mobile_svg__paint1_linear)"
      />
      <path
        opacity={0.675}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.201 77.613c-21.043 5.278-92.87-.76-100.515-3.516-3.721-1.343-7.075-3.868-10.061-7.576a2.822 2.822 0 012.198-4.593h125.514c2.605 6.938-3.107 12.166-17.136 15.685z"
        fill="url(#vec_mobile_svg__paint2_linear)"
      />
      <path fill="url(#vec_mobile_svg__paint3_linear)" d="M52.172 19.177h26.131v49.648H52.172z" />
      <path
        d="M66.522 64.904c.736 0 1.333-.585 1.333-1.306a1.32 1.32 0 00-1.333-1.307h-2.56a1.32 1.32 0 00-1.333 1.307c0 .721.597 1.306 1.332 1.306h2.561z"
        fill="#2F8DEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.402 33.853a1 1 0 011-1h13.679a1 1 0 110 2H58.402a1 1 0 01-1-1zM57.402 37.781a1 1 0 011-1h8.453a1 1 0 010 2h-8.453a1 1 0 01-1-1z"
        fill="#2F8DEE"
      />
      <path
        d="M62.935 42.7h-4.533a1 1 0 00-1 1v7.146a1 1 0 001 1h4.533a1 1 0 001-1V43.7a1 1 0 00-1-1z"
        fill="url(#vec_mobile_svg__paint4_linear)"
      />
      <path
        d="M72.076 42.7h-4.533a1 1 0 00-1 1v7.146a1 1 0 001 1h4.533a1 1 0 001-1V43.7a1 1 0 00-1-1z"
        fill="url(#vec_mobile_svg__paint5_linear)"
      />
      <path
        d="M58.707 19.177h13.065v2.613a1.96 1.96 0 01-1.96 1.96h-9.145a1.96 1.96 0 01-1.96-1.96v-2.613z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51 23.096A5.095 5.095 0 0156.096 18h18.29a5.095 5.095 0 015.096 5.096v41.808A5.095 5.095 0 0174.387 70H56.096A5.095 5.095 0 0151 64.904V23.096zm5.096-2.744a2.744 2.744 0 00-2.744 2.744v41.808a2.744 2.744 0 002.744 2.744h18.29a2.744 2.744 0 002.745-2.743v-41.81a2.744 2.744 0 00-2.744-2.743H56.096z"
        fill="url(#vec_mobile_svg__paint6_linear)"
      />
      <defs>
        <linearGradient
          id="vec_mobile_svg__paint0_linear"
          x1={67.794}
          y1={37.313}
          x2={67.794}
          y2={-8.37}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DEDEDE" stopOpacity={0} />
          <stop offset={1} stopColor="#A9A9A9" stopOpacity={0.3} />
        </linearGradient>
        <linearGradient
          id="vec_mobile_svg__paint1_linear"
          x1={62.071}
          y1={76.008}
          x2={62.071}
          y2={63.052}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#96A1C5" stopOpacity={0.373} />
        </linearGradient>
        <linearGradient
          id="vec_mobile_svg__paint2_linear"
          x1={65.474}
          y1={79.706}
          x2={65.474}
          y2={58.43}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#919191" stopOpacity={0.15} />
        </linearGradient>
        <linearGradient
          id="vec_mobile_svg__paint3_linear"
          x1={65.417}
          y1={19.177}
          x2={65.417}
          y2={68.825}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECF5FD" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="vec_mobile_svg__paint4_linear"
          x1={60.714}
          y1={42.7}
          x2={60.714}
          y2={51.846}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5FA8F2" />
          <stop offset={1} stopColor="#2F8DEE" />
        </linearGradient>
        <linearGradient
          id="vec_mobile_svg__paint5_linear"
          x1={69.854}
          y1={42.7}
          x2={69.854}
          y2={51.846}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5FA8F2" />
          <stop offset={1} stopColor="#2F8DEE" />
        </linearGradient>
        <linearGradient
          id="vec_mobile_svg__paint6_linear"
          x1={65.437}
          y1={33.533}
          x2={65.437}
          y2={70}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F8DEE" />
          <stop offset={1} stopColor="#5FA8F2" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgVecMobile
