import type { Moment } from '~/types'

export type EarnTransaction = {
  created_at: string
  description: {
    type: string
    direction: string
    data: string
  }
  amount: { prefix: string; amount: string; postfix: string }
  status: string
}

export type FilterOptions = {
  transactionStatuses: Array<{ label: string; value: string }>
  transactionTypes: Array<{ label: string; value: string }>
  sortTypes: Array<{ label: string; value: string }>
}

export type FilterValuesObject = {
  [filterKey: string]:
    | Moment
    | string[]
    | number[]
    | string
    | number
    | EarnTransaction[]
    | boolean
    | undefined
}

export type FilterValues = {
  startDate: Moment | string
  endDate: Moment | string
  currentPage: number
  pageLimit: number
  searchString: string
  transactionTypes: string[]
  transactionStatuses: string[]
  sortType: string
  [key: string]: FilterValuesObject[keyof FilterValuesObject]
}

export enum EarnTransactionsActions {
  GET_TRANSACTIONS = 'EarnTransactionsActions.GET_TRANSACTIONS',
  SET_IS_TRANSACTION_LOADING = 'EarnTransactionsActions.SET_IS_TRANSACTION_LOADING',
  SET_FILTERS = 'EarnTransactionsActions.SET_FILTERS',
  GET_TRANSACTION_FILTER_OPTIONS = 'EarnTransactionsActions.GET_TRANSACTION_FILTER_OPTIONS',
}

type GetTransactions = {
  type: typeof EarnTransactionsActions.GET_TRANSACTIONS
  transactions: EarnTransaction[]
  totalCount: number
}

type SetIsTransactionLoading = {
  type: typeof EarnTransactionsActions.SET_IS_TRANSACTION_LOADING
  isTransactionLoading: boolean
}

type SetFilters = {
  type: typeof EarnTransactionsActions.SET_FILTERS
  filterValuesObject: FilterValuesObject
}

type GetTransactionFilterOptions = {
  type: typeof EarnTransactionsActions.GET_TRANSACTION_FILTER_OPTIONS
  filterOptions: FilterOptions
}

export type ActionTypes =
  | GetTransactions
  | SetIsTransactionLoading
  | SetFilters
  | GetTransactionFilterOptions
