import * as React from 'react'

function SvgEdit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <g clipPath="url(#edit_svg__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.47 23.856L24.52 7.808a1.645 1.645 0 00-2.327-2.326L6.145 21.53l-.768 3.093 3.094-.767zm1.771 2.472l16.4-16.4a4.644 4.644 0 10-6.569-6.568l-16.4 16.4a1.213 1.213 0 00-.32.565l-1.286 5.187c-.363 1.462.96 2.786 2.423 2.423l5.187-1.287c.214-.053.41-.164.565-.32z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="edit_svg__clip0">
          <path fill="#fff" d="M0 0h30v30H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgEdit
