import { SVGProps } from 'react'

function SvgRepay(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 10.49a1.998 1.998 0 0 0-.47-.14V8.23a5 5 0 0 0-5-5h-7a5 5 0 0 0-5 5v6.37c-.356.266-.69.56-1 .88l-.26-.3a.762.762 0 1 0-1.15 1l6 7a.74.74 0 0 0 .58.27.74.74 0 0 0 .48-.18.76.76 0 0 0 .09-1.06L8.11 22l.39-.39a.251.251 0 0 1 .18-.08h2.53A11.34 11.34 0 0 0 21 15.89L22.12 14a2.47 2.47 0 0 0 .19-2A2.42 2.42 0 0 0 21 10.49ZM5 8.23a3.5 3.5 0 0 1 3.5-3.5h7a3.5 3.5 0 0 1 3.5 3.5v2.28a2.59 2.59 0 0 0-.82.59l-.27.3a4.65 4.65 0 0 1-2.64 1.47l-.72.14h-4.08a8.62 8.62 0 0 0-5.47.67V8.23Zm15.79 5-1.09 1.92a9.74 9.74 0 0 1-8.49 4.92H8.68a1.75 1.75 0 0 0-1.24.52l-.31.31-3.65-4.28a7.2 7.2 0 0 1 6.69-2.15c.097.01.194.01.29 0h4a.79.79 0 1 1 0 1.58h-4.39a1.73 1.73 0 0 0-1.17.45l-.61.55a.75.75 0 0 0 1.01 1.11l.6-.54a.23.23 0 0 1 .17-.07h4.41a2.3 2.3 0 0 0 2.29-2.29 2.26 2.26 0 0 0-.34-1.18A6.19 6.19 0 0 0 19 12.4l.27-.3a1 1 0 0 1 1.08-.23.919.919 0 0 1 .52.57.91.91 0 0 1-.06.77l-.02.02Z"
        fill="currentColor"
      />
      <path
        d="M11.83 12.11a2.78 2.78 0 1 0-.056-5.56 2.78 2.78 0 0 0 .056 5.56Zm-.92-3.7a1.3 1.3 0 0 1 2.21.91 1.29 1.29 0 0 1-1.29 1.29 1.32 1.32 0 0 1-.92-.38 1.29 1.29 0 0 1-.37-.91 1.271 1.271 0 0 1 .37-.91ZM7.21 10.03a.71.71 0 1 0 0-1.42.71.71 0 0 0 0 1.42ZM16.45 10a.71.71 0 1 0 0-1.42.71.71 0 0 0 0 1.42Z"
        fill="currentColor"
      />
      <path
        d="M16.33.54H7.91a.75.75 0 1 0 0 1.5h8.42a5.26 5.26 0 0 1 5.25 5.25v1.86a.75.75 0 1 0 1.5 0V7.29A6.76 6.76 0 0 0 16.33.54Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgRepay
