import type React from 'react'
import type { PATHS as BIZ_PATHS } from '~/biz/routes/paths'
import type { PATHS as BIZ_REGIONAL_PATHS } from '~/bizRegional/routes/paths'
import type { PERMISSIONS } from '~/types'
import type { idSidebarLabels, sgSidebarLabels } from '~/constants/sidebarFeaturesLabel.config'

export function isHeaderTranslation(
  breadcrumbName: HeaderText
): breadcrumbName is HeaderTranslation {
  return (breadcrumbName as HeaderTranslation).i18nkey !== undefined
}

export function isHeaderWithSubtitle(breadcrumbName: Header): breadcrumbName is HeaderWithSubtitle {
  return (breadcrumbName as HeaderWithSubtitle).subtitle !== undefined
}

type HeaderTranslation = {
  i18nkey: string
  namespace: string
}

type HeaderText = string | HeaderTranslation

type HeaderWithSubtitle = {
  title: HeaderText
  subtitle: HeaderText
}

export type Header = HeaderText | HeaderWithSubtitle

export type SidebarLabels =
  | typeof sgSidebarLabels[keyof typeof sgSidebarLabels]
  | typeof idSidebarLabels[keyof typeof idSidebarLabels]

type BaseRoute = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>
  path?: BIZ_PATHS | BIZ_REGIONAL_PATHS
  exact?: boolean
  sideBar?: SidebarLabels
  permission?: PERMISSIONS
  breadcrumbName?: Header
  pageTitle?: string
  withCustomBreadcrumb?: boolean
  hasSandboxMode?: boolean
  sandboxPermission?: PERMISSIONS
}

export type SubRoute = Omit<BaseRoute, 'component' | 'exact'>
export type Route = BaseRoute & { subRoutes?: SubRoute[] }
