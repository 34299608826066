import { SVGProps } from 'react'

function BankTransferIconButton(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="40" height="40" rx="16" fill="#F4F5FA" />
      <path
        d="M14.1201 19.49H12.5801C12.0278 19.49 11.5801 19.9377 11.5801 20.49V26.56C11.5801 27.1123 12.0278 27.56 12.5801 27.56H14.1201C14.6724 27.56 15.1201 27.1123 15.1201 26.56V20.49C15.1201 19.9377 14.6724 19.49 14.1201 19.49Z"
        fill="#0C45E1"
      />
      <path
        d="M23.6602 20.49V22.49C24.5884 21.8377 25.6956 21.4884 26.8302 21.49H27.2002V20.49C27.2002 20.2248 27.0948 19.9704 26.9073 19.7829C26.7197 19.5953 26.4654 19.49 26.2002 19.49H24.6602C24.3949 19.49 24.1406 19.5953 23.953 19.7829C23.7655 19.9704 23.6602 20.2248 23.6602 20.49Z"
        fill="#0C45E1"
      />
      <path
        d="M22.1599 24.07V20.49C22.1599 20.2248 22.0545 19.9704 21.867 19.7829C21.6794 19.5953 21.4251 19.49 21.1599 19.49H17.6099C17.3446 19.49 17.0903 19.5953 16.9028 19.7829C16.7152 19.9704 16.6099 20.2248 16.6099 20.49V26.56C16.6099 26.8252 16.7152 27.0796 16.9028 27.2671C17.0903 27.4546 17.3446 27.56 17.6099 27.56H21.3099C21.2999 27.3935 21.2999 27.2265 21.3099 27.06C21.3026 26.0027 21.5975 24.9653 22.1599 24.07Z"
        fill="#0C45E1"
      />
      <path
        d="M26.8299 23C26.041 23.0099 25.2726 23.2528 24.6214 23.6983C23.9702 24.1438 23.4653 24.7719 23.1702 25.5036C22.8751 26.2353 22.803 27.038 22.9629 27.8106C23.1229 28.5832 23.5077 29.2912 24.0691 29.8456C24.6304 30.4 25.3432 30.7761 26.1177 30.9264C26.8923 31.0767 27.6939 30.9947 28.4219 30.6905C29.1499 30.3863 29.7717 29.8737 30.2091 29.217C30.6464 28.5603 30.8798 27.789 30.8799 27C30.8667 25.9346 30.4342 24.9172 29.6761 24.1685C28.918 23.4197 27.8954 22.9999 26.8299 23ZM27.8299 28.57C27.6264 28.7678 27.3622 28.8911 27.0799 28.92V29.49L26.5799 29.59V29C26.4117 28.996 26.2442 28.9759 26.0799 28.94C25.9157 28.9003 25.7551 28.8468 25.5999 28.78L25.6999 28.21C26.0003 28.2645 26.3046 28.2946 26.6099 28.3C27.0099 28.3 27.2099 28.18 27.2099 27.96C27.2111 27.9235 27.2035 27.8872 27.1879 27.8541C27.1722 27.8211 27.1489 27.7922 27.1199 27.77C27.014 27.6969 26.8999 27.6365 26.7799 27.59L26.6199 27.5L26.3599 27.37C26.1265 27.2613 25.9186 27.1045 25.7499 26.91C25.6216 26.7329 25.5548 26.5186 25.5599 26.3C25.5552 26.1681 25.5766 26.0366 25.6229 25.913C25.6693 25.7894 25.7396 25.6763 25.8299 25.58C26.0345 25.3781 26.3034 25.2543 26.5899 25.23V24.67L27.0799 24.57V25.21C27.3363 25.2331 27.5868 25.3007 27.8199 25.41L27.7199 25.98C27.4684 25.9413 27.2143 25.9213 26.9599 25.92C26.8303 25.9124 26.7015 25.9437 26.5899 26.01C26.5446 26.036 26.5076 26.0741 26.4828 26.12C26.4581 26.166 26.4467 26.2179 26.4499 26.27C26.4506 26.3087 26.46 26.3467 26.4773 26.3813C26.4946 26.4159 26.5194 26.4462 26.5499 26.47C26.6399 26.5479 26.741 26.6119 26.8499 26.66L27.1299 26.81C27.3222 26.9005 27.5062 27.0076 27.6799 27.13C27.806 27.2202 27.9117 27.3361 27.9899 27.47C28.0661 27.6075 28.1041 27.7629 28.0999 27.92C28.094 28.1657 27.9979 28.4007 27.8299 28.58V28.57Z"
        fill="#0C45E1"
      />
      <path
        d="M11.4599 17.9999H27.3099C27.8613 17.9369 28.3786 17.7011 28.7879 17.3262C29.1971 16.9513 29.4772 16.4566 29.5882 15.9128C29.6992 15.3691 29.6353 14.8042 29.4057 14.2989C29.1761 13.7937 28.7925 13.374 28.3099 13.0999L22.3099 9.69994C21.3978 9.17507 20.3623 8.9024 19.3099 8.90994C18.258 8.90573 17.2234 9.17817 16.3099 9.69994L10.4599 13.0899C9.9732 13.3622 9.58561 13.7821 9.35314 14.289C9.12068 14.796 9.05538 15.3637 9.16668 15.9102C9.27798 16.4566 9.56011 16.9536 9.97233 17.3292C10.3845 17.7049 10.9055 17.9398 11.4599 17.9999ZM20.5599 14.3499H18.2099C18.011 14.3499 17.8203 14.2709 17.6796 14.1303C17.539 13.9896 17.4599 13.7989 17.4599 13.5999C17.4599 13.401 17.539 13.2103 17.6796 13.0696C17.8203 12.929 18.011 12.8499 18.2099 12.8499H20.5599C20.7589 12.8499 20.9496 12.929 21.0903 13.0696C21.2309 13.2103 21.3099 13.401 21.3099 13.5999C21.3099 13.7989 21.2309 13.9896 21.0903 14.1303C20.9496 14.2709 20.7589 14.3499 20.5599 14.3499Z"
        fill="#0C45E1"
      />
      <path
        d="M22.55 30.58C22.1787 30.13 21.8816 29.6236 21.67 29.08H10.23C10.0311 29.08 9.8403 29.159 9.69965 29.2996C9.559 29.4403 9.47998 29.631 9.47998 29.83C9.47998 30.0289 9.559 30.2196 9.69965 30.3603C9.8403 30.5009 10.0311 30.58 10.23 30.58H22.55Z"
        fill="#0C45E1"
      />
    </svg>
  )
}

export default BankTransferIconButton
