import type {
  AMVerificationMethodType,
  CitizenshipType,
  ResidencyType,
} from '~/api/sgVerification/sgVerification.types'
import type { VerificationStatusType } from '~/types'

export enum SCREENS {
  STATUS = 'STATUS',
  ABOUT = 'ABOUT',
  PERSONAL_DETAILS = 'PERSONAL_DETAILS',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  IDENTITY_DOCUMENT = 'IDENTITY_DOCUMENT',
  MY_INFO = 'MY_INFO',
  REVIEW = 'REVIEW',
}

export enum VerificationActions {
  SET_IMMIGRATION_STATUS = 'VERIFICATION_ACTIONS.SET_IMMIGRATION_STATUS',
  SET_RESIDENCY_STATUS = 'VERIFICATION_ACTIONS.SET_RESIDENCY_STATUS',
  SET_FLOW = 'VERIFICATION_ACTIONS.SET_FLOW',
  SET_USER_DETAILS = 'VERIFICATION_ACTIONS.SET_USER_DETAILS',
  UPDATE_USER = 'VERIFICATION_ACTIONS.UPDATE_USER',
  SET_IS_ID_TYPE_SG_PR = 'VERIFICATION_ACTIONS.SET_IS_ID_TYPE_SG_PR',
  SET_SCREEN = 'VERIFICATION_ACTIONS.SET_SCREEN',
  SET_MULTIPLE_USER_DETAILS = 'VERIFICATION_ACTIONS.SET_MULTIPLE_USER_DETAILS',
  SET_KYC_REJECTED_REASON = 'VERIFICATION_ACTIONS.SET_KYC_REJECTED_REASON',
  SET_RETRIEVED_DETAILS = 'VERIFICATION_ACTIONS.SET_RETRIEVED_DETAILS',
  SET_ERROR = 'VERIFICATION_ACTIONS.SET_ERROR',
  SET_MY_INFO_ONLY_DETAILS = 'VERIFICATION_ACTIONS.SET_MY_INFO_ONLY_DETAILS',
  SET_CONTROLLER = 'SET_CONTROLLER',
}

export const Citizenship: Record<string, CitizenshipType> = {
  SgPr: 'Singapore Citizen / Permanent Residence',
  NonSgPr: 'Not a Singapore Citizen / Permanent Residence',
}
export const Residency: Record<string, ResidencyType> = {
  LivingInSg: 'Living in Singapore',
  NotLivingInSg: 'Not Living in Singapore',
}
export const VerificationMethod: Record<string, AMVerificationMethodType> = {
  Manual: 'manual',
  Singpass: 'singpass',
}

// TODO: To be deprecated post-migration
export enum ResidencyStatusType {
  LIVING_IN_SG = 'LIVING_IN_SG',
  NOT_LIVING_IN_SG = 'NOT_LIVING_IN_SG',
}
export type ImmigrationStatus = 'sg_pr' | 'not_sg_pr' | undefined
export type ResidencyStatus = ResidencyStatusType | undefined
export type VerificationFlows = 'my_info' | 'manual' | undefined
export type Attachment = {
  name?: string
  getUrl?: string
  signedId?: string
}
export type FileObject =
  | {
      getUrl?: string
      signedId?: string
      fileName?: string
      fileData?: string
    }
  | undefined

export type FileObjectArray = FileObject[] | undefined
// TODO^: To be deprecated post-migration

export type State = {
  userDetails: UserDetails
  immigrationStatus: ImmigrationStatus
  residencyStatus: ResidencyStatus
  isIdTypeSgPr: boolean
  isResidencyNonSg: boolean
  isInternationalOnboarding: boolean
  flow: VerificationFlows
  error: string
  screen?: SCREENS
  kycRejectedReason: string | { [key: string]: any } // eslint-disable-line @typescript-eslint/no-explicit-any
  retrievedDetails?: RetrievedDetails
  myInfoOnlyDetails: MyInfoOnlyDetails
  controller?: AbortController
}

export type RetrievedDetails = {
  verificationStatus: VerificationStatusType
  kycRejectedReason: string | { [key: string]: string }
  accountLocked: boolean

  isMyInfoFlow: boolean | undefined
  fullName: string | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateOfBirth: any
  nationality: string | undefined
  gender: 'MALE' | 'FEMALE' | undefined
  addressLine1: string | undefined
  addressLine2: string | undefined
  city: string | undefined
  postalCode: string | undefined
  countryOfResidence: string | undefined
  idNumber: string | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expiryDate: any

  proofOfAddressUrl: string | undefined
  proofOfAddressUploaded: boolean

  idFrontUrl: string | undefined
  idFrontUploaded: boolean

  idBackUrl: string | undefined
  idBackUploaded: boolean
}

export type DocumentTypes = 'idFront' | 'idBack' | 'proofOfAddress' | 'passport'
export type UserDetails = {
  fullName?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateOfBirth?: any
  nationality?: string
  gender?: 'MALE' | 'FEMALE'
  addressLine1?: string
  addressLine2?: string
  city?: string
  countryOfResidence?: string
  postalCode?: string
  idNumber?: string

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expiryDate?: any
  proofOfAddressFile?: FileObject | Attachment
  idFrontFile?: FileObject | Attachment
  idBackFile?: FileObject | Attachment
  passportFile?: FileObject | Attachment
}

export type MyInfoOnlyDetails = {
  race?: string
  residentialStatus?: string
  aliasName?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  passStatusLastUpdated?: any
  state?: string
  withValidAddress?: boolean
  withValidExpiryDate?: boolean
  error?: string
}

type SetImmigrationStatus = {
  type: typeof VerificationActions.SET_IMMIGRATION_STATUS
  status: ImmigrationStatus
}

type SetResidencyStatus = {
  type: typeof VerificationActions.SET_RESIDENCY_STATUS
  status: ResidencyStatus
}

type SetFlow = {
  type: typeof VerificationActions.SET_FLOW
  flow: VerificationFlows
}

type SetUserDetails = {
  type: typeof VerificationActions.SET_USER_DETAILS
  key: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
}

type UpdateUser = {
  type: typeof VerificationActions.UPDATE_USER
  error: string
}

type SetIsIdTypeSgPr = {
  type: typeof VerificationActions.SET_IS_ID_TYPE_SG_PR
  isIdTypeSgPr: boolean
}

type SetScreen = {
  type: typeof VerificationActions.SET_SCREEN
  screen: SCREENS
}

type SetMultipleUserDetails = {
  type: typeof VerificationActions.SET_MULTIPLE_USER_DETAILS
  userDetails: UserDetails
}

type SetKycRejectedReason = {
  type: typeof VerificationActions.SET_KYC_REJECTED_REASON
  kycRejectedReason: string
}

type SetRetrievedDetails = {
  type: typeof VerificationActions.SET_RETRIEVED_DETAILS
  retrievedDetails: RetrievedDetails
}

type SetError = {
  type: typeof VerificationActions.SET_ERROR
  error: string
}

type SetMyInfoOnlyDetails = {
  type: typeof VerificationActions.SET_MY_INFO_ONLY_DETAILS
  myInfoOnlyDetails: MyInfoOnlyDetails
}

type SetController = {
  type: typeof VerificationActions.SET_CONTROLLER
  controller: AbortController
}

export type ActionTypes =
  | SetImmigrationStatus
  | SetResidencyStatus
  | SetFlow
  | SetUserDetails
  | UpdateUser
  | SetIsIdTypeSgPr
  | SetScreen
  | SetMultipleUserDetails
  | SetKycRejectedReason
  | SetRetrievedDetails
  | SetError
  | SetMyInfoOnlyDetails
  | SetController
