import type { Reducer } from 'redux'
import type { VerificationStatusType } from '~/types'
import { VerificationStatus } from '~/constants/verificationStatus'
import type { PendingTasks, AccountInfo, ActionTypes, UsdAccountNumberInfo } from './types'
import { NewLandingPageActions } from './types'

const initialState = {
  SGDAccount: {
    balance: '',
    isLoading: false,
  },
  USDAccount: {
    balance: '',
    isLoading: false,
  },
  USDAccountNumber: {
    accountNumber: '',
    isLoading: false,
  },
  earnAccount: {
    balance: '',
    isLoading: false,
  },
  pendingTasks: {
    tasks: [],
    isLoading: false,
  },
  isSGDAccountInitiated: false,
  isUSDAccountInitiated: false,
  isEarnAccountInitiated: false,
  isHomepageBannerDismissed: false,
  ownerKycStatus: VerificationStatus.Initial,
  businessKybStatus: VerificationStatus.Initial,
}

type State = {
  SGDAccount: AccountInfo
  USDAccount: AccountInfo
  earnAccount: AccountInfo
  pendingTasks: PendingTasks
  USDAccountNumber: UsdAccountNumberInfo
  isSGDAccountInitiated: boolean
  isUSDAccountInitiated: boolean
  isEarnAccountInitiated: boolean
  isHomepageBannerDismissed: boolean
  ownerKycStatus: VerificationStatusType
  businessKybStatus: VerificationStatusType
}

export const landingPage: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case NewLandingPageActions.SET_SGD_ACCOUNT: {
      return {
        ...state,
        SGDAccount: {
          ...state.SGDAccount,
          balance: action.balance,
        },
      }
    }

    case NewLandingPageActions.SET_IS_SGD_ACCOUNT_LOADING: {
      return {
        ...state,
        SGDAccount: {
          ...state.SGDAccount,
          isLoading: action.isLoading,
        },
      }
    }

    case NewLandingPageActions.SET_USD_ACCOUNT: {
      return {
        ...state,
        USDAccount: {
          ...state.USDAccount,
          balance: action.balance,
        },
      }
    }

    case NewLandingPageActions.SET_IS_USD_ACCOUNT_LOADING: {
      return {
        ...state,
        USDAccount: {
          ...state.USDAccount,
          isLoading: action.isLoading,
        },
      }
    }

    case NewLandingPageActions.SET_USD_ACCOUNT_NUMBER: {
      return {
        ...state,
        USDAccountNumber: {
          ...state.USDAccountNumber,
          accountNumber: action.accountNumber,
        },
      }
    }

    case NewLandingPageActions.SET_USD_ACCOUNT_NUMBER_LOADING: {
      return {
        ...state,
        USDAccountNumber: {
          ...state.USDAccountNumber,
          isLoading: action.isLoading,
        },
      }
    }

    case NewLandingPageActions.SET_EARN_ACCOUNT: {
      return {
        ...state,
        earnAccount: {
          ...state.earnAccount,
          balance: action.balance,
        },
      }
    }

    case NewLandingPageActions.SET_IS_EARN_ACCOUNT_LOADING: {
      return {
        ...state,
        earnAccount: {
          ...state.earnAccount,
          isLoading: action.isLoading,
        },
      }
    }

    case NewLandingPageActions.SET_PENDING_TASKS: {
      return {
        ...state,
        pendingTasks: {
          ...state.pendingTasks,
          tasks: action.pendingTasks,
        },
      }
    }

    case NewLandingPageActions.SET_OWNER_KYC_STATUS: {
      return {
        ...state,
        ownerKycStatus: action.ownerKycStatus,
      }
    }

    case NewLandingPageActions.SET_BUSINESS_KYB_STATUS: {
      return {
        ...state,
        businessKybStatus: action.businessKybStatus,
      }
    }

    case NewLandingPageActions.SET_IS_PENDING_TASKS_LOADING: {
      return {
        ...state,
        pendingTasks: {
          ...state.pendingTasks,
          isLoading: action.isLoading,
        },
      }
    }

    case NewLandingPageActions.SET_SGD_ACCOUNT_IS_INITIATED: {
      return {
        ...state,
        isSGDAccountInitiated: action.isSGDAccountInitiated,
      }
    }

    case NewLandingPageActions.SET_EARN_ACCOUNT_IS_INITIATED: {
      return {
        ...state,
        isEarnAccountInitiated: action.isEarnAccountInitiated,
      }
    }

    case NewLandingPageActions.SET_DISMISS_HOMEPAGE_BANNER: {
      return {
        ...state,
        isHomepageBannerDismissed: action.isHomepageBannerDismissed,
      }
    }

    default: {
      return state
    }
  }
}
