import type { Reducer } from 'redux'
import type { ActionTypes, PayTransferWalletDetails, Recipient } from './types'
import { PayTransferActions } from './types'

type State = {
  disbursement: {
    wallet?: PayTransferWalletDetails
    selectedRecipient: Recipient | null
    attachments: [] | null
  }
}

const initialState: State = {
  disbursement: {
    wallet: {
      walletID: '',
      walletBalance: '',
    },
    selectedRecipient: null,
    attachments: null,
  },
}

export const payTransfer: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case PayTransferActions.SET_WALLET_DETAILS: {
      return {
        ...state,
        disbursement: {
          ...state.disbursement,
          wallet: action.wallet,
        },
      }
    }

    case PayTransferActions.SET_ATTACHMENTS: {
      return {
        ...state,
        disbursement: {
          ...state.disbursement,
          attachments: action.attachments,
        },
      }
    }

    case PayTransferActions.SET_SELECTED_RECIPIENT: {
      return {
        ...state,
        disbursement: {
          ...state.disbursement,
          selectedRecipient: action.selectedRecipient,
        },
      }
    }
    case PayTransferActions.RESET_PAYOUTFORM: {
      return {
        ...state,
        disbursement: {
          wallet: {
            walletID: '',
            walletBalance: '',
          },
          selectedRecipient: null,
          attachments: '',
        },
      }
    }
    default:
      return state
  }
}
