import type { Reducer } from 'redux'
import type { TopupRequest, ActionTypes } from './types'
import { DepositRequestsActions } from './types'

const initialState = {
  isLoading: false,
  topupRequests: [],
  currentPage: 1,
  pageLimit: 10,
  totalCount: 0,
}

type State = {
  isLoading: boolean
  topupRequests: TopupRequest[]
  currentPage: number
  pageLimit: number
  totalCount: number
}

export const depositRequests: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case DepositRequestsActions.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }

    case DepositRequestsActions.GET_TOPUP_REQUESTS: {
      return {
        ...state,
        topupRequests: action.topupRequests,
        totalCount: action.totalCount,
        isLoading: false,
      }
    }

    case DepositRequestsActions.SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.currentPage,
      }
    }

    default:
      return state
  }
}
