import { FONT_SIZE, FONT_WEIGHT, LINE_HEIGHT } from './TypographyStyles'
import { DESKTOP_BREAKPOINT } from './breakPoints'
import { BLACK } from './colors'

/** @deprecated please use fazzBizzThemeFds3 fontFamily instead */
export const MAIN_FONT = 'FazzNeue'

/** @deprecated please use fazzBizzThemeFds3 fontStyle or Typography instead */
export const baseStyle = `
  color: ${BLACK.DEFAULT};
  font-family: ${MAIN_FONT};
  margin: 0;
`

/** @deprecated please use fazzBizzThemeFds3 fontStyle or Typography instead */
export const titleStyle = `
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: ${FONT_SIZE.Title};
  line-height: ${LINE_HEIGHT.Title};

  ${DESKTOP_BREAKPOINT} {
    font-size: ${FONT_SIZE.Title};
    line-height: ${LINE_HEIGHT.Title};
  }
`

/** @deprecated please use fazzBizzThemeFds3 fontStyle or Typography instead */
export const titleSmallStyle = `
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: ${FONT_SIZE.TitleSmall};
  line-height: ${LINE_HEIGHT.TitleSmall};

  ${DESKTOP_BREAKPOINT} {
    font-size: ${FONT_SIZE.TitleSmall};
    line-height: ${LINE_HEIGHT.TitleSmall};
  }
`

/** @deprecated please use fazzBizzThemeFds3 fontStyle or Typography instead */
export const labelLargeStyle = `
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: ${FONT_SIZE.LabelLarge};
  line-height: ${LINE_HEIGHT.LabelLarge};

  ${DESKTOP_BREAKPOINT} {
    font-size: ${FONT_SIZE.LabelLarge};
    line-height:${LINE_HEIGHT.LabelLarge};
  }
`

/** @deprecated please use fazzBizzThemeFds3 fontStyle or Typography instead */
export const labelStyle = `
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: '14px';
  line-height: '20px';

  ${DESKTOP_BREAKPOINT} {
    font-size: 14px;
    line-height: '20px';
  }
`

/** @deprecated please use fazzBizzThemeFds3 fontStyle or Typography instead */
export const bodyStyle = `
  font-weight: ${FONT_WEIGHT.REGULAR};
  font-size: 14px;
  line-height: 20px;

  ${DESKTOP_BREAKPOINT} {
    font-size: 14px;
    line-height: 20px;
  }
`

/** @deprecated please use fazzBizzThemeFds3 fontStyle or Typography instead */
export const bodySmallStyle = `
  font-weight: ${FONT_WEIGHT.REGULAR};
  font-size: '12px';
  line-height:  '18px';

  ${DESKTOP_BREAKPOINT} {
    font-size: 12px;
    line-height:  '18px';
  }
`
/** @deprecated please use fazzBizzThemeFds3 fontStyle or Typography instead */
export const buttonStyle = `
  font-weight: ${FONT_WEIGHT.MEDIUM};
  font-size: 18px;

  ${DESKTOP_BREAKPOINT} {
    font-size: 16px;
  }
`

/** @deprecated please use fazzBizzThemeFds3 fontStyle or Typography instead */
export const smallButtonStyle = `
  font-weight: ${FONT_WEIGHT.MEDIUM};
  font-size: 16px;

  ${DESKTOP_BREAKPOINT} {
    font-size: 14px;
  }
`

/** @deprecated please use fazzBizzThemeFds3 fontStyle or Typography instead */
export const newButtonStyle = `
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: 14px;
  height: 48px;
`

/** @deprecated please use fazzBizzThemeFds3 fontStyle or Typography instead */
export const newSmallButtonStyle = `
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: 14px;
  height: 36px;
`

/** @deprecated please use fazzBizzThemeFds3 fontStyle or Typography instead */
export const ActiveDesktopStepperStyle = `
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: 14px;
  line-height: 24px;
`

/** @deprecated please use fazzBizzThemeFds3 fontStyle or Typography instead */
export const InactiveDesktopStepperStyle = `
  font-weight: ${FONT_WEIGHT.REGULAR};
  font-size: 14px;
  line-height: 24px;
`
