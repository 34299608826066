import { Typography, fdsTheme } from 'fds3-src/index'
import { cloneElement } from 'react'
import { InfoFilled } from '@fazz/design-system-icons'
import { StyledInfoCard } from './InfoCard.styles'
import type { InfoCardProps } from './InfoCard.types'

const { colors } = fdsTheme
const { Body } = Typography

export function InfoCard({
  children,
  className,
  iconColor = colors.primary,
  bgColor = colors.surfaceSecondaryNeutral,
  icon = <InfoFilled />,
  padding = 'xs',
  radius = 'xs',
}: // DO NOT USE SPREAD OPERATOR i.e. {...props}
InfoCardProps) {
  const clonedIcon = cloneElement(icon, {
    color: iconColor,
    width: 18,
    height: 18,
    style: {
      verticalAlign: 'text-bottom',
    },
  })

  return (
    <StyledInfoCard bgColor={bgColor} className={className} radius={radius} padding={padding}>
      <div>{clonedIcon}</div>
      <Body size="md">{children}</Body>
    </StyledInfoCard>
  )
}

export default InfoCard
