import { SVGProps } from 'react'

export default function NewCalendar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.15 6.67a.74.74 0 0 1-.75-.75V2.39a.75.75 0 0 1 1.5 0v3.53a.75.75 0 0 1-.75.75ZM16.85 6.67a.75.75 0 0 1-.75-.75V2.39a.75.75 0 1 1 1.5 0v3.53a.74.74 0 0 1-.75.75Z"
        fill="#121212"
      />
      <path
        d="M17.47 4.87A3.5 3.5 0 0 1 21 8.37v6.33a.468.468 0 0 1-.15.35l-5.66 5.67a.5.5 0 0 1-.35.14H6.53A3.501 3.501 0 0 1 3 17.36v-9a3.5 3.5 0 0 1 3.5-3.5h10.97v.01Zm0-1.51H6.53a5 5 0 0 0-5 5v9a5 5 0 0 0 5 5h8.28a2 2 0 0 0 1.41-.58l5.67-5.66a1.999 1.999 0 0 0 .58-1.42V8.37a5 5 0 0 0-5-5v-.01Z"
        fill="#121212"
      />
      <path
        d="m20 15.85-4.33 4.35v-.85a3.502 3.502 0 0 1 3.5-3.5H20Zm2.44-1.5h-3.3a5 5 0 0 0-5 5v3h.29a2.85 2.85 0 0 0 2-.83l5.17-5.17c.53-.531.829-1.25.83-2h.01ZM6.66 8.91h-.73a.53.53 0 0 0-.53.53v.73c0 .293.238.53.53.53h.73a.53.53 0 0 0 .53-.53v-.73a.53.53 0 0 0-.53-.53ZM10.36 8.91h-.73a.53.53 0 0 0-.53.53v.73c0 .293.237.53.53.53h.73a.53.53 0 0 0 .53-.53v-.73a.53.53 0 0 0-.53-.53ZM14.05 8.91h-.73a.53.53 0 0 0-.53.53v.73c0 .293.236.53.53.53h.73a.53.53 0 0 0 .53-.53v-.73a.53.53 0 0 0-.53-.53ZM17.75 8.91h-.73a.53.53 0 0 0-.53.53v.73c0 .293.238.53.53.53h.73a.53.53 0 0 0 .53-.53v-.73a.53.53 0 0 0-.53-.53ZM6.66 12.47h-.73a.53.53 0 0 0-.53.53v.73c0 .293.238.53.53.53h.73a.53.53 0 0 0 .53-.53V13a.53.53 0 0 0-.53-.53ZM10.36 12.47h-.73a.53.53 0 0 0-.53.53v.73c0 .293.237.53.53.53h.73a.53.53 0 0 0 .53-.53V13a.53.53 0 0 0-.53-.53ZM6.66 16.06h-.73a.53.53 0 0 0-.53.53v.73c0 .293.238.53.53.53h.73a.53.53 0 0 0 .53-.53v-.73a.53.53 0 0 0-.53-.53ZM10.36 16.06h-.73a.53.53 0 0 0-.53.53v.73c0 .293.237.53.53.53h.73a.53.53 0 0 0 .53-.53v-.73a.53.53 0 0 0-.53-.53ZM14.05 12.47h-.73a.53.53 0 0 0-.53.53v.73c0 .293.236.53.53.53h.73a.53.53 0 0 0 .53-.53V13a.53.53 0 0 0-.53-.53Z"
        fill="#121212"
      />
    </svg>
  )
}
