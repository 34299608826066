export enum ApiVersion {
  V3 = 'V3',
  V4 = 'V4',
}

export enum TAB {
  API_KEY = 'apiKeys',
  CALLBACK_SETTINGS = 'callbackSettings',
}

export enum CallbackType {
  CONNECT_TOKEN_DISABLED = 'connectTokenDisabled',
  USER_VERIFICATION_STATUS_UPDATED = 'userVerificationStatusUpdated',
  USER_BANK_ACCOUNT_VERIFICATION_STATUS_UPDATED = 'userBankAccountVerificationStatusUpdated',
  WITHDRAWAL_COMPLETED = 'withdrawalCompleted',
  PAYOUT_COMPLETED = 'payoutCompleted',
  CHARGE_COMPLETED = 'chargeCompleted',
  VIRTUAL_ACCOUNT_TOPUP_COMPLETED = 'virtualAccountTopupCompleted',
  V3_PAYMENT_GATEWAY_PAYMENT_EXPIRED = 'v3PaymentGatewayPaymentExpired',
  V3_PAYMENT_GATEWAY_PAYMENT_PAID = 'v3PaymentGatewayPaymentPaid',
  V3_PAYMENT_GATEWAY_PAYMENT_COMPLETED = 'v3PaymentGatewayPaymentCompleted',
  ACCEPT = 'accept',
  SEND = 'send',
}

export type V3KeyResponse = {
  api_key: string
  api_secret: string
  wallet_type: {
    id: number
    name: string
    contractual_model: boolean
    in_use: boolean
  }
  last_used: string
  created_at: string
  enabled: boolean
}

export type V3Key = {
  apiKey: string
  apiSecret: string
  walletType: string
  lastUsed: string
  createdAt: string
  enabled: boolean
}

export type V4KeyResponse = {
  id: string
  type: 'apiKeys'
  attributes: {
    clientId: string
    clientSecret: string
    lastUsed: string
    createdAt: string
    enabled: boolean
  }
}

export type V4Key = {
  name: string
  token: string
  lastUsed: string
  createdAt: string
  enabled: boolean
}

export type DetailType = {
  api_version: string
  enabled_events: string[]
  signing_secret: string
  tag: string
  url: string
}

export type CallbackFunctions = {
  successCallback: () => void
  errorCallback: () => void
}

export enum DeveloperToolsActions {
  SET_IS_LOADING = 'DeveloperToolsActions.SET_IS_LOADING',
  SET_API_KEY_VERSION_V3 = 'DeveloperToolsActions.SET_API_KEY_VERSION_V3',
  GET_INITIAL_URL_V3 = 'DeveloperToolsActions.GET_INITIAL_URL_V3',
  GET_INITIAL_URL_V4 = 'DeveloperToolsActions.GET_INITIAL_URL_V4',
  SET_CALLBACK_URL = 'DeveloperToolsActions.SET_CALLBACK_URL',
  UPDATE_CALLBACK_URL = 'DeveloperToolsActions.UPDATE_CALLBACK_URL',
  RESET_API_KEYS = 'DeveloperToolsActions.RESET_API_KEYS',
  FETCH_API_KEY = 'DeveloperToolsActions.FETCH_API_KEY',
  GENERATE_API_KEY = 'DeveloperToolsActions.GENERATE_API_KEY',
  CLEAR_API_KEYS = 'DeveloperToolsActions.CLEAR_API_KEYS',
  SHOW_V4_SIGNATURE = 'DeveloperToolsActions.SHOW_V4_SIGNATURE',
  SHOW_V3_SIGNATURE = 'DeveloperToolsActions.SHOW_V3_SIGNATURE',
  SET_V3_SIGNATURE = 'DeveloperToolsActions.SET_V3_SIGNATURE',
  GET_VERIFICATION_STATUS = 'DeveloperToolsActions.GET_VERIFICATION_STATUS',
}

export type EventSetting = {
  event_name:
    | 'connect_token_disabled'
    | 'user_verification_status_updated'
    | 'withdrawal_completed'
    | 'payout_completed'
    | 'charge_completed'
    | 'virtual_account_topup_completed'
    | 'v3_payment_gateway_payment_expired'
    | 'v3_payment_gateway_payment_paid'
    | 'v3_payment_gateway_payment_completed'
  url: string
}

export type V3CallbackSettings = {
  connectTokenDisabled: string
  userVerificationStatusUpdated: string
  userBankAccountVerificationStatusUpdated: string
  withdrawalCompleted: string
  payoutCompleted: string
  chargeCompleted: string
  virtualAccountTopupCompleted: string
  v3PaymentGatewayPaymentExpired: string
  v3PaymentGatewayPaymentPaid: string
  v3PaymentGatewayPaymentCompleted: string
  signature: string
  showSig: boolean
}

export type V4CallbackSettings = {
  accept: string
  acceptSignature: string
  send: string
  sendSignature: string
}

type GetInitialUrlV3 = {
  type: typeof DeveloperToolsActions.GET_INITIAL_URL_V3
  v3CallbackSettings: V3CallbackSettings
}

type GetInitialUrlV4 = {
  type: typeof DeveloperToolsActions.GET_INITIAL_URL_V4
  v4CallbackSettings: V4CallbackSettings
}

type SetCallbackUrl = {
  type: typeof DeveloperToolsActions.SET_CALLBACK_URL
  apiVersion: ApiVersion.V3 | ApiVersion.V4
  callbackType: string
  callbackUrl: string
}

type UpdateCallbackUrl = {
  type: typeof DeveloperToolsActions.UPDATE_CALLBACK_URL
  callbackType: string
}

type SetIsLoading = {
  type: typeof DeveloperToolsActions.SET_IS_LOADING
  isLoading: boolean
}

type SetApiKeyVersionV3 = {
  type: typeof DeveloperToolsActions.SET_API_KEY_VERSION_V3
  isV3Merchant: boolean
}

type ResetApiKeys = {
  type: typeof DeveloperToolsActions.RESET_API_KEYS
}

type FetchApiKey = {
  type: typeof DeveloperToolsActions.FETCH_API_KEY
  apiVersion: ApiVersion.V3 | ApiVersion.V4
  apiKeys: V3Key[] | V4Key[]
}

type GenerateApiKey = {
  type: typeof DeveloperToolsActions.GENERATE_API_KEY
  v4Key: V4Key
}

type ClearApiKeys = {
  type: typeof DeveloperToolsActions.CLEAR_API_KEYS
}

type ShowV4Signature = {
  type: typeof DeveloperToolsActions.SHOW_V4_SIGNATURE
  callbackSigType: string
  signature: string
  signatureType: string
}

type ShowV3Signature = {
  type: typeof DeveloperToolsActions.SHOW_V3_SIGNATURE
  showSig: boolean
}

type SetV3Signature = {
  type: typeof DeveloperToolsActions.SET_V3_SIGNATURE
  signature: string
}

type GetVerificationStatus = {
  type: typeof DeveloperToolsActions.GET_VERIFICATION_STATUS
  verificationStatus: string
}

export type ActionTypes =
  | SetIsLoading
  | SetApiKeyVersionV3
  | GetInitialUrlV3
  | GetInitialUrlV4
  | SetCallbackUrl
  | UpdateCallbackUrl
  | ResetApiKeys
  | FetchApiKey
  | GenerateApiKey
  | ClearApiKeys
  | ShowV4Signature
  | ShowV3Signature
  | SetV3Signature
  | GetVerificationStatus
