import * as React from 'react'

function SvgZillet(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#zillet_svg__clip0)">
        <path
          d="M17.998 33.6c8.616 0 15.6-6.984 15.6-15.6 0-8.615-6.984-15.6-15.6-15.6-8.615 0-15.6 6.985-15.6 15.6 0 8.616 6.985 15.6 15.6 15.6z"
          fill="url(#zillet_svg__paint0_linear)"
        />
        <path
          d="M11.415 11.796c.04-.153.057-.31.098-.463.133-.49.479-.73.944-.843.244-.058.491-.06.737-.06 2.981-.004 5.963-.005 8.944-.003.547 0 1.032.169 1.438.531.342.307.532.7.589 1.157.03.227.028.456-.005.683-.045.29-.187.53-.355.762-.898 1.237-1.793 2.474-2.686 3.713a.631.631 0 00-.038.067c-.243.332-.49.665-.731 1-1.064 1.47-2.127 2.94-3.19 4.412-.021.031-.07.062-.05.102.022.04.075.017.113.017h5.06c.35 0 .7-.002 1.047.062.666.122.985.422 1.105 1.089.11.608.094 1.215-.089 1.814-.14.466-.452.713-.92.796-.299.049-.6.072-.903.068-3.048 0-6.096 0-9.144-.002-.737 0-1.32-.31-1.716-.934-.246-.387-.294-.827-.265-1.278.023-.359.196-.647.4-.93a8237.674 8237.674 0 006.644-9.194c.016-.022.042-.042.04-.086h-.117c-1.779 0-3.557-.001-5.336-.003a2.813 2.813 0 01-.698-.098c-.489-.126-.748-.455-.856-.927a3.987 3.987 0 01-.07-1.241c.007-.07.008-.141.01-.211z"
          fill="#fff"
        />
      </g>
      <defs>
        <linearGradient
          id="zillet_svg__paint0_linear"
          x1={9.416}
          y1={29.941}
          x2={27.48}
          y2={4.81}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5DB2AA" />
          <stop offset={1} stopColor="#78BBB6" />
        </linearGradient>
        <clipPath id="zillet_svg__clip0">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgZillet
