import { SVGProps } from 'react'

function SvgAdd(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.65 7.25h-5.9v-5.9a.75.75 0 0 0-1.5 0v5.9h-5.9a.75.75 0 0 0 0 1.5h5.9v5.9a.75.75 0 1 0 1.5 0v-5.9h5.9a.75.75 0 1 0 0-1.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgAdd
