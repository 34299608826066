function SvgPendingFilled(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5.833a9.167 9.167 0 1 0 0 18.334 9.167 9.167 0 0 0 0-18.334Zm2.95 10.617h-3.334a.633.633 0 0 1-.625-.625V7.66a.625.625 0 1 1 1.25 0v2.566h2.675a.625.625 0 1 1 0 1.25l.034-.025Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPendingFilled
