import moment, { Moment } from 'moment'

type DateRangeType = 'today' | 'yesterday' | 'last7Days' | 'last14Days' | 'lastMonth'

const dateRangeLabels: Record<DateRangeType, string> = {
  today: 'Today',
  yesterday: 'Yesterday',
  last7Days: 'Last 7 days',
  last14Days: 'Last 14 days',
  lastMonth: 'Last Month',
}

const defaultParameters: DateRangeType[] = [
  'today',
  'yesterday',
  'last7Days',
  'last14Days',
  'lastMonth',
]

export function dateRangeHelper(
  range: DateRangeType[] = defaultParameters
): Record<string, [Moment, Moment]> {
  const defaultRanges: Record<DateRangeType, [Moment, Moment]> = {
    today: [moment(), moment()],
    yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    last7Days: [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
    last14Days: [moment().subtract(14, 'days'), moment().subtract(1, 'days')],
    lastMonth: [
      moment().subtract(1, 'months').startOf('month'),
      moment().subtract(1, 'months').endOf('month'),
    ],
  }

  return range.reduce((acc, rangeItem) => {
    const label = dateRangeLabels[rangeItem]
    return { ...acc, [label]: defaultRanges[rangeItem] }
  }, {})
}

export function disabledDate(current: Moment): boolean {
  const maxDateRange = 2 * 365 // 2 years
  const minAllowedDate = moment().subtract(maxDateRange, 'days')
  const maxAllowedDate = moment()
  const tooEarly = current.isBefore(minAllowedDate, 'day')
  const tooLate = current.isAfter(maxAllowedDate, 'day')
  return tooEarly || tooLate
}
