// Alphabetize Please
enum ENDPOINTS {
  // API V4
  API_V4_MERCHANT_BSS_VA = 'api/v4/payment_methods/virtual_bank_accounts/product_line/fazzbiz',
  API_V4_PAYNOW_QR = 'api/v4/payment_methods/paynow',
  API_V4_SGD_ACCOUNT_VA = 'api/v4/payment_methods/virtual_bank_accounts',
  API_XFERS_ACCOUNTS_INFO_FOR_SDK = 'api/v3/xfers/accounts/info_for_sdk',
  API_V4_VALIDATE_BANK_ACCOUNT = 'api/v4/validation_services/bank_account_validation',

  // API V3
  API_V3_CALLBACK_SETTINGS = 'api/v3/callback_settings',
  API_V3_DASHBOARD_INFO = 'api/v3/xfers/dashboard',
  API_V3_DIRECT_URL = 'api/v3/user/upload_documents',
  API_V3_GOOGLE_AUTHENTICATOR_INFO = 'api/v3/google_authenticator',
  API_V3_GOOGLE_AUTHENTICATOR_VERIFY_CODE = 'api/v3/google_authenticator/verify_code',
  API_V3_USER_PROFILE = 'api/v3/user/user_profile',
  API_V3_INDO_KTP_OPTIONS_ENDPOINT = 'api/v3/user/indo_ktp_options',
  API_V3_INITIATE_MY_INFO_ENDPOINT = 'api/v3/user/kyc_initiate_myinfo',
  API_V3_MERCHANTS_ACTIVITIES = 'api/v3/merchant/update_business_activities',
  API_V3_MERCHANT_DETAILS = 'api/v3/merchant/get_merchant_details',
  API_V3_MERCHANT_UPLOAD_DOCUMENTS = 'api/v3/merchant/update_business_documents',
  API_V3_MERCHANT_WITHDRAW = 'api/v3/xfers/merchant/withdraw',
  API_V3_MY_INFO_DETAILS_ENDPOINT = 'api/v3/user/kyc_myinfo_details',
  API_V3_NAMECHECK = 'api/v3/disbursements/namecheck',
  API_V3_PAYMENTS_DEPOSIT_REQUEST = 'api/v3/payment_gateway/payments/deposits',
  API_V3_UNIQUE_AMT_PAYMENT = 'api/v3/payment_gateway/payment_methods/unique_amount_bank_transfers',
  API_V3_UPDATE_USER_ENDPOINT = 'api/v3/user/update_account_info_for_sdk',
  API_V3_USER = 'api/v3/user',
  API_V3_USER_BANK_ACCOUNT = 'api/v3/user/bank_account',
  API_V3_USER_CHECK_NRIC_EXISTS = 'api/v3/user/check_nric_exists',
  API_V3_USER_CLAIM_KYC = 'api/v3/user/claim_kyc',
  API_V3_USER_GET_EMAILS_FROM_NRIC = 'api/v3/user/get_emails_from_nric',
  API_V3_USER_TOPUP_REQUEST_FOR_SDK = 'api/v3/user/topup_request_for_sdk',
  API_V3_USER_TOPUP_REQUEST = 'api/v3/user/topup_request',
  API_V3_USER_TRANSFER_INFO = 'api/v3/user/transfer_info',
  API_V3_USER_WITHDRAWAL_LIMITS = 'api/v3/user/bank_account/withdrawal_limits',
  API_V3_SUPPORTED_CURRENCIES = '/api/v3/fx_conversion/currencies',
  API_V3_LATEST_RATES = 'api/v3/fx_conversion/rates',
  API_V3_PAYMENTS = 'api/v3/payments',
  API_V3_PENDING_TASKS = 'api/v3/pending_tasks',
  API_V3_FEE_OPTION = 'api/v3/fx_conversion/fee-option',

  // API V3 Sandbox
  API_V3_SANDBOX_MOCK_USER = 'api/v3/sandbox/mock_user',
  API_V3_SANDBOX_REJECT_USER = 'api/v3/sandbox/reject_user',
  API_V3_SANDBOX_TRANSACTIONS_TOPUP = 'api/v3/sandbox/transactions/topup',
  API_V3_SANDBOX_USERS_CONNECTED_TOKENS = 'api/v3/sandbox/users/connected_tokens',
  API_V3_SANDBOX_USERS_TOKEN = 'api/v3/sandbox/users/token',
  API_V3_SANDBOX_VERIFY_USER = 'api/v3/sandbox/verify_user',

  // API V3 XFERS(Internal only/ Dashboard only)
  API_XFERS_ACCOUNTS = 'api/v3/xfers/accounts',
  API_XFERS_ACCOUNT_SETTINGS = 'api/v3/xfers/account_settings',
  API_XFERS_AUTHENTICATION = 'api/v3/xfers/authentication',
  API_XFERS_FEATURE_MATRIX_PROFILE = 'api/v3/xfers/authentication/feature_matrix_profile',
  API_XFERS_CHECK_FEATURE = 'api/v3/xfers/check_feature',
  API_V3_BULK_DISBURSEMENTS = 'api/v3/xfers/disbursements/bulk_create',
  API_V3_FEES = 'api/v3/xfers/disbursements/show_fees',
  API_V3_FEES_BY_DISBURSEMENT = 'api/v3/xfers/disbursements/show_fees_by_disbursement',
  API_XFERS_DISBURSEMENTS_APPROVE = 'api/v3/xfers/disbursements/approve',
  API_XFERS_DISBURSEMENTS_REJECT = 'api/v3/xfers/disbursements/reject',
  API_XFERS_DISBURSEMENTS_LIMIT = 'api/v3/xfers/transactions/transactions/disbursement_limit',
  API_XFERS_EMAIL_NOTIFICATION_SETTINGS = 'api/v3/xfers/email_notification_settings',
  API_XFERS_GUEST_PHONE_TOKEN_CHECK = 'api/v3/xfers/guest_account_registration/phone_token_check',
  API_XFERS_INDO_DEPOSIT_BANKS = '/api/v3/xfers/identifier_assignments',
  API_XFERS_INTERNAL_TOOLS_ENDPOINT = 'api/v3/xfers/internal_tools',
  API_XFERS_MERCHANT = 'api/v3/xfers/merchant',
  API_XFERS_MERCHANT_DEPOSIT_VA = 'api/v3/xfers/merchant/deposit_virtual_accounts',
  API_XFERS_RESEND_CALLBACK = 'api/v3/xfers/webhook_settings/resend_callback',
  API_XFERS_RESEND_CONFIRMATION_EMAIL = 'api/v3/xfers/account_settings/resend',
  API_XFERS_TRANSACTIONS = 'api/v3/xfers/transactions/transactions',
  API_XFERS_TRANSACTIONS_TOTAL_COUNT = 'api/v3/xfers/transactions/transactions/total_count',
  API_XFERS_TRANSACTIONS_EXPORT_CSV = 'api/v3/xfers/transactions/transactions/export_csv.csv',
  API_XFERS_TRANSACTIONS_EXPORT_PDF = 'api/v3/xfers/transactions/transactions/export_pdf.pdf',
  API_XFERS_TRANSACTIONS_FILTER_OPTIONS = 'api/v3/xfers/transactions/transactions/filter_options',
  API_XFERS_TRANSACTIONS_MERCHANT_TRANSACTION_LIMITS = 'api/v3/xfers/transactions/merchant_transaction_limits',
  API_XFERS_TRANSACTIONS_XFERS_ACCEPT = 'api/v3/xfers/transactions/xfers_accept',
  API_XFERS_TRANSACTIONS_XFERS_ACCEPT_EXPORT_CSV = 'api/v3/xfers/transactions/xfers_accept/export_csv',
  API_XFERS_TRANSACTIONS_XFERS_ACCEPT_EXPORT_PDF = 'api/v3/xfers/transactions/xfers_accept/export_pdf',
  API_XFERS_TRANSACTIONS_XFERS_ACCEPT_FILTER_OPTIONS = 'api/v3/xfers/transactions/xfers_accept/filter_options',
  API_XFERS_SG_TRANSACTIONS_XFERS_ACCEPT = 'api/v3/xfers/transactions/singapore/xfers_accept',
  API_XFERS_SG_TRANSACTIONS_XFERS_ACCEPT_FILTER_OPTIONS = 'api/v3/xfers/transactions/singapore/xfers_accept/filter_options',
  API_XFERS_SG_TRANSACTIONS_EXPORT_CSV = 'api/v3/xfers/transactions/singapore/xfers_accept/export_csv',
  API_XFERS_TRANSACTIONS_XFERS_SEND = 'api/v3/xfers/transactions/xfers_send',
  API_XFERS_TRANSACTIONS_XFERS_SEND_EXPORT_CSV = 'api/v3/xfers/transactions/xfers_send/export_csv',
  API_XFERS_TRANSACTIONS_XFERS_SEND_EXPORT_PDF = 'api/v3/xfers/transactions/xfers_send/export_pdf',
  API_XFERS_TRANSACTIONS_XFERS_SEND_FILTER_OPTIONS = 'api/v3/xfers/transactions/xfers_send/filter_options',
  API_XFERS_TRANSACTIONS_XFERS_DASH = 'api/v3/xfers/transactions/xfers_dash',
  API_XFERS_TRANSACTIONS_XFERS_DASH_EXPORT_CSV = 'api/v3/xfers/transactions/xfers_dash/export_csv',
  API_XFERS_TRANSACTIONS_XFERS_DASH_EXPORT_PDF = 'api/v3/xfers/transactions/xfers_dash/export_pdf',
  API_XFERS_TRANSACTIONS_XFERS_DASH_FILTER_OPTIONS = 'api/v3/xfers/transactions/xfers_dash/filter_options',
  API_XFERS_TRANSACTIONS_XFERS_ACCOUNT_STATEMENT = 'api/v3/xfers/transactions/ledgers',
  API_XFERS_TRANSACTIONS_XFERS_ACCOUNT_STATEMENT_FILTER_OPTIONS = 'api/v3/xfers/transactions/ledgers/filter_options',
  API_XFERS_TRANSACTIONS_XFERS_ACCOUNT_STATEMENT_EXPORT_CSV = 'api/v3/xfers/transactions/ledgers/export_csv',
  API_XFERS_TRANSACTIONS_ACCOUNT_BALANCE = 'api/v3/xfers/transactions/ledgers',
  API_XFERS_USER_MESSAGES = 'api/v3/xfers/user_messages',
  API_XFERS_USER_MESSAGE_ACKNOWLEDGMENTS = 'api/v3/xfers/user_message_acknowledgements',
  API_XFERS_WEBHOOK_SETTINGS = 'api/v3/xfers/webhook_settings',
  API_XFERS_INVOICES = 'api/v3/xfers/invoices',
  API_XFERS_INVOICE = 'api/v3/xfers/invoice',
  API_XFERS_UPLOAD_INVOICE = 'api/v3/xfers/invoice_upload',
  API_XFERS_DOWNLOAD_INVOICE = 'api/v3/xfers/invoice_downloads',
  API_XFERS_CREDIT_CARDS_PAYMENTS = 'api/v3/xfers/payment_instructions',
  API_XFERS_CREDIT_CARDS_DISPUTES = 'api/v3/xfers/external_disputes',
  API_XFERS_EARN_CONSENT = 'api/v3/xfers/transactions/earn/consent_flow',
  API_XFERS_CREATE_EARN_ACCOUNT = 'api/v3/xfers/transactions/earn/create_account',
  API_XFERS_EARN_INTEREST_INFO = 'api/v3/xfers/transactions/earn/interest',
  API_XFERS_CREATE_EARN_ACCOUNT_BALANCE = 'api/v3/xfers/transactions/earn/balance',
  API_XFERS_EARN_TRANSACTION = 'api/v3/xfers/transactions/earn/transactions',
  API_XFERS_EARN_DEPOSIT = 'api/v3/xfers/transactions/earn/deposit',
  API_XFERS_EARN_WITHDRAW = 'api/v3/xfers/transactions/earn/withdrawal',
  API_XFERS_EARN_EXPORT_CSV = 'api/v3/xfers/transactions/earn/export_csv',

  // USD Account APIs
  API_XFERS_USD_ACCOUNT_AGREEMENT = 'api/v3/usd_loan_account/agreement',
  API_XFERS_USD_ACCOUNT_TRANSACTIONS = 'api/v3/usd_loan_account/transactions',
  API_XFERS_USD_ACCOUNT_BALANCE = 'api/v3/usd_loan_account/balance',
  API_XFERS_USD_ACCOUNT_DEPOSIT_REQUEST = 'api/v3/usd_loan_account/deposit_requests',
  API_XFERS_USD_ACCOUNT_WITHDRAW_REQUEST = 'api/v3/usd_loan_account/withdrawal_requests',
  API_XFERS_USD_ACCOUNT_GET_RECIPIENT = 'api/v3/usd_loan_account/beneficiaries',
  API_XFERS_USD_ACCOUNT_CREATE_NEW_RECIPIENT = 'api/v3/usd_loan_account/beneficiaries',
  API_XFERS_USD_ACCOUNT_GET_RECIPIENT_REQUIREMENTS = 'api/v3/usd_loan_account/beneficiary_requirements',

  // new USD Account APIs
  API_XFERS_USD_ACCOUNT_AGREEMENT_SIGN_UP_NEW = 'api/v3/usd_account/signup',
  API_XFERS_USD_ACCOUNT_AGREEMENT_INFO_NEW = 'api/v3/usd_account/agreement',
  API_XFERS_USD_ACCOUNT_BALANCE_NEW = 'api/v3/usd_account/balance',
  API_XFERS_USD_ACCOUNT_DEPOSIT_INFO_NEW = 'api/v3/usd_account/deposit_info',
  API_XFERS_USD_ACCOUNT_TRANSACTIONS_NEW = 'api/v3/usd_account/transactions',

  // USD EARN APIs
  API_XFERS_USD_EARN_BALANCE = 'api/v3/usd_account/balance',
  API_XFERS_USD_EARN_DEPOSIT = 'api/v3/usd_account/earn/deposit',
  API_XFERS_USD_EARN_WITHDRAW = 'api/v3/usd_account/earn/withdraw',
  API_XFERS_USD_EARN_CLAIM = 'api/v3/usd_account/earn/claim',

  // SGD Account FX APIs
  API_XFERS_SGD_ACCOUNT_FX_AGREEMENT = 'api/v3/sgd_account_fx/agreement',
  API_XFERS_SGD_ACCOUNT_FX_BENEFICIARIES = 'api/v3/sgd_account_fx/beneficiaries',
  API_XFERS_SGD_ACCOUNT_FX_GET_RECIPIENT_REQUIREMENTS = 'api/v3/sgd_account_fx/beneficiary_requirements',
  API_XFERS_SGD_ACCOUNT_FX_CONVERSION_REQUEST = 'api/v3/sgd_account_fx/conversion_requests',
  API_XFERS_SGD_ACCOUNT_FX_POST_RECIPIENT = 'api/v3/sgd_account_fx/beneficiaries',

  API_XFERS_RFI_GET_RFI_REQUESTS = 'api/v3/user/rfi_requests',
  API_XFERS_RFI_SUBMIT_RFI_REQUESTS_REPLY = 'api/v3/user/rfi_requests/reply',

  API_TEMP_LOAN_REQUEST = 'api/v3/modalrakyat/manual_processed_loans',

  API_XFERS_MINT = 'api/v3/mint',
  API_XFERS_MINT_SETUP = 'api/v3/mint_setup',
  API_V3_FINANCIAL_INSTITUTIONS = 'api/v3/financial_institutions',

  // Other
  API_SSO_OTP_LOGIN = 'sso/device-login-flow',
  API_SSO_PROFILE = 'sso/profile',
  API_SSO_CLAIM_KYC = 'sso/claim-kyc',

  // Team
  API_SSO_TEAM = 'sso/merchant/members',
  API_SSO_ADD_TEAM_MEMBER = 'sso/merchant/add_member',

  /* Fazzbiz Api Routes */
  FAZZBIZ_API_BUSINESS_ENTITIES = 'dashboard/user-merchant-infos',
  FAZZBIZ_API_BUSINESS_ENTITY = 'dashboard/info',
  FAZZBIZ_API_CARD_BUSINESS_UPDATE_SETTING = '/dashboard/corporate-card-issuing/card-business-settings',
  FAZZBIZ_API_EXTERNAL_IDENTITIES = 'dashboard/external-identities',
  FAZZBIZ_API_PAYMENT_API_KEYS = 'dashboard/payment-api-keys',
  FAZZBIZ_API_ACRA_BUSINESS_INFO = 'dashboard/identity/business-info',
  FAZZBIZ_API_ACRA_BENEFICIAL_OWNERS_INFO = 'dashboard/identity/beneficial-owners-info',
  FAZZBIZ_API_GAUTH = 'dashboard/gauth',
  FAZZBIZ_API_GAUTH_VERIFY_CODE = 'dashboard/gauth/verify_code',
  FAZZBIZ_API_PAYMENT_VIRTUAL_BANK_ACCOUNT = 'dashboard/payments/sg/transfer-in-va',
  FAZZBIZ_API_APPLICATION_FORM = 'dashboard/loans/applications',
  FAZZBIZ_API_USER_CONSENT_CONFIRMATION = 'dashboard/loans/confirmation',
  FAZZBIZ_API_LOANS_SUMMARY = 'dashboard/loans/summary',
  FAZZBIZ_API_LOANS_HISTORY = 'dashboard/loans/history',
  FAZZBIZ_API_LOANS_TIMELINE = 'dashboard/loans/timeline',
  FAZZBIZ_API_LOANS_DETAILS = 'dashboard/loans/detail',
  FAZZBIZ_API_LOAN_VERIFICATION_MORAK = 'dashboard/loans/verification/status',
  FAZZBIZ_API_EMPLOYERS = 'dashboard/employers',
  FAZZBIZ_API_ACCOUNT_STATEMENT_EXPORT_PDF_CSV = 'dashboard/payments/sg/ledger-reports',
  FAZZBIZ_API_LEDGERS = 'dashboard/payments/sg/ledgers',

  // LOAN VERIFICATION
  FAZZBIZ_API_LOAN_VERIFICATION = 'dashboard/loans/verification/data',
  FAZZBIZ_API_SUBMIT_LOAN_VERIFICATION = 'dashboard/loans/verification/',
  FAZZBIZ_API_LOAN_VERIFICATION_LOCATION = 'dashboard/loans/verification/location',

  // PHYSICAL CARD KYC
  FAZZBIZ_API_PHYSICAL_CARD_DETAIL = 'dashboard/corporate-card-issuing/kycs/detail',
  FAZZBIZ_API_PHYSICAL_CARD_KYC_STATE = 'dashboard/corporate-card-issuing/kycs/state',
  FAZZBIZ_API_PHYSICAL_CARD_INSERT_DATA = 'dashboard/corporate-card-issuing/kycs/data-insertion',
  FAZZBIZ_API_PHYSICAL_CARD_SUBMISSION = 'dashboard/corporate-card-issuing/kycs/submission',
  FAZZBIZ_API_PHYSICAL_CARD_SUBMIT_PROOF_OF_ADDRESS = 'dashboard/corporate-card-issuing/kycs/proof-of-address-upload',
  FAZZBIZ_API_PHYSICAL_CARD_SUBMIT_ID = 'dashboard/corporate-card-issuing/kycs/ids-upload',
  FAZZBIZ_API_PHYSICAL_CARD_SUBMIT_SELFIE = 'dashboard/corporate-card-issuing/kycs/selfie-upload',
  FAZZBIZ_API_PHYSICAL_CARD_SUBMIT_PASSPORT = 'dashboard/corporate-card-issuing/kycs/passport-upload',
  FAZZBIZ_API_PHYSICAL_CARD_DELETE_DOCUMENT = 'dashboard/corporate-card-issuing/kycs/document-deletion',

  // MY INFO FOR PHYSICAL CARD KYC
  FAZZBIZ_API_MY_INFO_INITIATE = 'dashboard/corporate-card-issuing/kycs/myinfo/initiate',
  FAZZBIZ_API_MY_INFO_DETAILS = 'dashboard/corporate-card-issuing/kycs/myinfo/personal-details',
}

export default ENDPOINTS
