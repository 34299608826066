import * as React from 'react'

function SvgExternalLink(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <g clipPath="url(#external-link_svg__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 3.5A1.5 1.5 0 0026.5 2h-8a1.5 1.5 0 100 3h4.061l-9.025 9.025a1.5 1.5 0 002.12 2.122L25 6.804V11.5a1.5 1.5 0 003 0v-8zM12.5 7H5v18h18v-7.5a1.5 1.5 0 113 0V26a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2h8.5a1.5 1.5 0 010 3z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="external-link_svg__clip0">
          <path fill="#fff" d="M0 0h30v30H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgExternalLink
