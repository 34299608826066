import { css } from 'emotion'
import Checkbox, { CheckboxProps } from 'antd/es/checkbox'
import 'antd/es/checkbox/style/css'
import { LINE_HEIGHT, MAIN_FONT } from '../../../themes/deprecated/TypographyStyles'
import { useTheme } from '../../../themes/deprecated/themeContext'

export default function CheckboxSingle({ className = '', ...props }: CheckboxProps) {
  const { base, spacing } = useTheme()
  const style = `

    & .ant-checkbox-wrapper-checked {
      border: 1px solid ${base.primary};
    }

    & .ant-checkbox-wrapper {
      padding-left: ${spacing.md};
      padding-top: ${spacing.sm};
      padding-bottom:${spacing.sm};
      line-height: ${LINE_HEIGHT.SMALL};
      color: ${base.neutral};
      font-family: ${MAIN_FONT};
    }



    & .ant-checkbox + span {
      padding-left: ${spacing.xs};
      padding-right: ${spacing.xs};
    }
  `

  return <Checkbox className={`${css(style)} ${className}`} {...props} />
}
