import type { CardInfo } from '~/biz/store/transactions/types'

export type IndoTransactionDetails = {
  key: string
  transactionId: string
  transactionType: string
  walletType: string
  method: string
  accountHolderName?: string | null
  amount: string
  fee: string
  tax: string | null
  netAmount: string
  description: {
    data: string | CardInfo
    direction: string
    internalTransactionType?: string
    paymentId?: string
    type?: string
  }
  createdDate: string
  completedDate: string
  status: string
  note?: string | null
  sourceDestination?: { source: string; destination: string } | null
  merchantDescription?: string | null
  blockedReasons?: string | null
  manualAssignments?: Array<{
    createdAt: string
    amount: { prefix: string; postfix: string }
    status: string
    externalId: string
  }> | null
  accountNo: string | null
  bankName: string | null
}

export type DisbursementDashDetails = {
  transactions: IndoTransactionDetails[]
  filteredTransactions: IndoTransactionDetails[]
  totalCount: number
  contractsExist: boolean
}

export type DisbursementDashFilterOptions = {
  statuses: Array<{ label: string; value: string }>
}

export type DisbursementDashContract = {
  key: string
  refId: string
  amount: string
  createdAt: string
  contractCount: string
  requester: string
  batchStatus: string
  approvedAt?: string | null
  rejectedAt?: string | null
}

export type FilterValuesObject = {
  [filterKey: string]: number | string | string[] | boolean
}

export type FilterValues = {
  currentPage: number
  pageLimit: number
  searchString: string
  statuses: string[]
  [key: string]: FilterValuesObject[keyof FilterValuesObject]
}

export enum IndoTransactionDetailsActions {
  SET_IS_LOADING = 'IndoTransactionDetailsActions.SET_IS_LOADING',
  GET_DISBURSEMENT_DASH_FILTER_OPTIONS = 'IndoTransactionDetailsActions.GET_DISBURSEMENT_DASH_FILTER_OPTIONS',
  GET_INDO_TRANSACTION_DETAILS = 'IndoTransactionDetailsActions.GET_TRANSACTION_DETAILS',
  GET_INDO_TRANSACTION_DETAILS_DISBURSEMENT_DASH = 'IndoTransactionDetailsActions.GET_INDO_TRANSACTION_DETAILS_DISBURSEMENT_DASH',
  SET_SELECTED_DISBURSEMENT_DASH_CONTRACT = 'IndoTransactionDetailsActions.SET_SELECTED_DISBURSEMENT_DASH_CONTRACT',
  SET_FILTERS = 'IndoTransactionDetailsActions.SET_FILTERS',
  GET_FILTERED_DISBURSEMENT_DASH_TRANSACTIONS = 'IndoTransactionDetailsActions.GET_FILTERED_DISBURSEMENT_DASH_TRANSACTIONS',
}

type SetIsLoading = {
  type: typeof IndoTransactionDetailsActions.SET_IS_LOADING
  isLoading: boolean
}

type GetDisbursementDashFilterOptions = {
  type: typeof IndoTransactionDetailsActions.GET_DISBURSEMENT_DASH_FILTER_OPTIONS
  disbursementDashFilterOptions: DisbursementDashFilterOptions
}

type GetIndoTransactionDetails = {
  type: typeof IndoTransactionDetailsActions.GET_INDO_TRANSACTION_DETAILS
  indoTransactionDetails: IndoTransactionDetails
}

type GetIndoTransactionDetailsDisbursementDash = {
  type: typeof IndoTransactionDetailsActions.GET_INDO_TRANSACTION_DETAILS_DISBURSEMENT_DASH
  disbursementDashDetails: DisbursementDashDetails
}

type SetSelectedDisbursementDashContract = {
  type: typeof IndoTransactionDetailsActions.SET_SELECTED_DISBURSEMENT_DASH_CONTRACT
  selectedDisbursementDashContract: DisbursementDashContract
}

type SetFilters = {
  type: typeof IndoTransactionDetailsActions.SET_FILTERS
  filterValuesObject: FilterValuesObject
}

type GetFilteredDisbursementDashTransactions = {
  type: typeof IndoTransactionDetailsActions.GET_FILTERED_DISBURSEMENT_DASH_TRANSACTIONS
  filteredTransactions: IndoTransactionDetails[]
}

export type ActionTypes =
  | SetIsLoading
  | GetDisbursementDashFilterOptions
  | GetIndoTransactionDetails
  | GetIndoTransactionDetailsDisbursementDash
  | SetSelectedDisbursementDashContract
  | SetFilters
  | GetFilteredDisbursementDashTransactions
