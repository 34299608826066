import styled from 'styled-components'
import { Tag } from 'antd'
import { fdsTheme } from '../../../themes/theme-fds3'
import type { BadgeVariantType, BadgeProps, BadgeSizeProps } from './Badge.types'

// Retrieve all theming data
const { radius, spacing, fontWeight, fontSize, colors, fontFamily } = fdsTheme

// Functions when there are more than 2 conditions
const getBackgroundColor = (variant?: BadgeVariantType) => {
  switch (variant) {
    case 'positive':
      return colors.surfaceSubduePositive
    case 'warning':
      return colors.surfaceSubdueWarning
    case 'information':
      return colors.surfaceSubdueInformation
    case 'critical':
      return colors.surfaceSubdueCritical
    case 'default':
      return colors.surfaceDisabledSurface
    default:
      return colors.surfaceDisabledSurface
  }
}

const getBorderAndFontColor = (variant?: BadgeVariantType) => {
  switch (variant) {
    case 'positive':
      return colors.positive
    case 'warning':
      return colors.warning
    case 'information':
      return colors.information
    case 'critical':
      return colors.critical
    case 'default':
      return colors.onNeutral
    default:
      return colors.onNeutral
  }
}

const getBadgeHorizontalPadding = (size?: BadgeSizeProps) => {
  switch (size) {
    case 'xs':
      return spacing.xs
    default:
      return spacing.sm
  }
}

export const StyledBadge = styled(Tag).attrs((props: BadgeProps) => ({
  variant: props.variant,
  size: props.size,
}))`
  ${({ variant, size }) =>
    `
  &.ant-tag{
    padding: 0 ${getBadgeHorizontalPadding(size)};
    font-weight: ${fontWeight.bold};
    border-radius: ${radius.lg};
    background-color:${getBackgroundColor(variant)};
    color:${getBorderAndFontColor(variant)};
    border-color: ${getBorderAndFontColor(variant)};
    font-size: ${fontSize.labelMedium};
    font-family: ${fontFamily.fazzNeue};
    margin: 0;
    height: fit-content;
    width: fit-content;
  }
`}
`
