import { Menu, OverlayScrollbar } from '@fazz/design-system'
import { FazzBusinessLogo, FazzBusinessSandboxLogo } from '@fazz/design-system-logos'
import { useAppTypeContext } from '~/hooks/useAppTypeContext'
import { Link } from '~/hooks/useReactRouter'
import { AppType } from '~/types'
import { ModeManager } from '~/managers/ModeManager'
import { LogoContainer, SidebarContainer } from './Sidebar.styles'
import { getSidebarItems } from './helpers/helpers'

export default function Sidebar() {
  const { appType = AppType.SINGAPORE } = useAppTypeContext()
  const isSandbox = ModeManager.isSandboxMode()

  const sidebarItems = getSidebarItems(appType)

  return (
    <SidebarContainer colorScheme={isSandbox ? 'sandbox' : 'default'}>
      <OverlayScrollbar style={{ height: '100%' }}>
        <LogoContainer>
          <Link to="/">
            {isSandbox ? (
              <FazzBusinessSandboxLogo height={70} />
            ) : (
              <FazzBusinessLogo width={100} height={40} />
            )}
          </Link>
        </LogoContainer>
        <Menu
          collapseInactiveMenus
          items={sidebarItems}
          colorScheme={isSandbox ? 'sandbox' : 'default'}
        />
      </OverlayScrollbar>
    </SidebarContainer>
  )
}
