import * as React from 'react'

function SvgSmbc(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M59.9999 17.7374L51.2135 26.6267L42.4263 17.7374L51.2135 8.85156L59.9999 17.7374Z"
        fill="#EF3E42"
      />
      <path
        d="M0 27.4773L1.939 13.7167C2.37199 10.6807 5.54024 8.03134 8.93833 8.03134C12.3868 8.03134 14.0228 10.4552 13.6909 12.7807L13.4094 14.7841C13.1792 16.4636 11.9525 18.0791 9.7815 19.2736L12.0041 23.0849C12.2598 23.5041 12.3622 24.1497 12.2598 24.8305C12.03 26.2828 10.9565 27.4773 9.7815 27.4773C9.14363 27.4773 8.63188 27.1234 8.32629 26.574L5.05517 20.8547L4.49348 24.861C4.28861 26.315 3.21516 27.4773 2.06554 27.4773H0ZM7.76331 10.8749C7.02171 10.8749 6.3322 11.6184 6.20522 12.5548L5.64569 16.7021C5.51829 17.6394 6.00379 18.3834 6.74453 18.3834C7.48483 18.3834 8.17435 17.639 8.30261 16.7021L8.86215 12.5548C8.98998 11.6184 8.50319 10.8749 7.76331 10.8749ZM15.8572 11.0682C16.0866 9.38781 17.3894 8 18.7181 8C20.0464 8 20.9408 9.38825 20.7105 11.0682L19.7662 18.0129H22.3981L23.3683 11.0682C23.5977 9.38781 24.9014 8 26.2305 8C27.5587 8 28.4527 9.38825 28.2224 11.0682L26.3833 24.4087C26.153 26.0877 24.8502 27.4777 23.5211 27.4777C22.1932 27.4777 21.2989 26.0877 21.5283 24.4087L22.1166 20.0803H19.4601L18.8718 24.4087C18.6411 26.0877 17.3386 27.4777 16.0108 27.4777H13.6079L15.8572 11.0682ZM41.7059 13.5878C41.501 14.8803 40.8106 16.1397 39.584 17.3024C40.4276 18.2075 40.7848 19.4016 40.6062 20.5652L40.4276 21.7602C39.9679 24.8292 36.8246 27.4768 33.4528 27.4768H28.1122L30.0792 13.6858C30.5152 10.6489 33.683 8.00044 37.0798 8.00044C40.5287 8.00044 42.1901 10.4226 41.8316 12.749L41.7059 13.5878ZM35.1387 17.9493C34.2951 17.9493 33.504 18.8209 33.3499 19.8874L32.9668 22.6656C32.814 23.7309 33.3757 24.6028 34.2189 24.6028C35.0621 24.6028 35.8803 23.7309 36.0331 22.6656L36.4166 19.8874C36.5707 18.8209 35.981 17.9493 35.1387 17.9493ZM36.1609 10.8753C35.3182 10.8753 34.5512 11.7477 34.3721 12.8134L34.0661 14.7192C33.8858 15.7853 34.475 16.6569 35.3182 16.6569C36.1613 16.6569 36.9529 15.7853 37.1328 14.7192L37.4384 12.8134C37.6174 11.7477 37.0037 10.8753 36.1609 10.8753Z"
        fill="#0067B1"
      />
    </svg>
  )
}

export default SvgSmbc
