import i18n from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled/macro'
import { Body, DESKTOP_BREAKPOINT, fazzbizTheme, LabelText } from '@fazz/design-system'
import { ArrowDown, Tick, ArrowUp } from '@fazz/design-system-icons'
import { matchPath, useLocation } from '~/hooks/useReactRouter'
import { PATHS } from '~/biz/routes/paths'
import { ELEVATIONS } from '~/common/elevation'
import { Language } from '~/i18n'

const allowedPaths = [
  PATHS.LOAN_ACCOUNT_APPLICATION_HOME,
  PATHS.LOAN_ACCOUNT_APPLICATION_FORM,
  PATHS.PAY_TRANSACTIONS,
  PATHS.IDR_TRANSACTION_DETAILS,
  PATHS.PAY_TRANSACTION_DETAILS,
  PATHS.LOAN_ACCOUNT,
  PATHS.LOAN_ACCOUNT_DETAILS,
  PATHS.LOAN_ACCOUNT_STATUS_DETAILS,
  PATHS.FLEX_PAY_ACCOUNT,
  PATHS.FLEX_PAY_ACCOUNT_REPAYMENT,
  PATHS.FLEX_PAY_ACCOUNT_DETAILS,
  PATHS.FLEXPAY_DASHBOARD,
  PATHS.LOAN_VERIFICATION,
  PATHS.APPLY_LOAN,
  PATHS.PAY_TRANSFER,
  PATHS.PAY_RECIPIENTS,
  '/loan/loan_account',
  '/loan/loan_account_new',
  PATHS.LOAN_USAGE,
  PATHS.LOAN_USAGE_DETAILS,
  PATHS.BIZ_KYC_ACCOUNT_MANAGER,
  PATHS.BIZ_KYC_BUSINESS_ACTIVITIES,
  PATHS.BIZ_KYC_COMPLETE,
  PATHS.BIZ_KYC_DIRECTORS_AND_BO,
  PATHS.BIZ_KYC_INFORMATION,
  PATHS.BIZ_KYC_INTENDED_RELATIONS,
  PATHS.BIZ_KYC_REVIEW,
  PATHS.BIZ_KYC_UPLOAD_DOCUMENTS,
  PATHS.VERIFICATION,
  PATHS.EARN,
  PATHS.EARN_DEPOSIT,
  PATHS.EARN_DEPOSIT_DETAILS,
  PATHS.EARN_LIST_DETAILS,
]

export default function LanguageSwitch() {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const [language, setLanguage] = useState<Language | string>(Language.ENGLISH)
  const [isVisible, setIsVisible] = useState(false)
  const [isAllowedPage, setIsAllowedPage] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const switchLanguage = (value: string) => {
    setLanguage(value)
    i18n.changeLanguage(value)
    setIsVisible(false)
  }
  useEffect(() => {
    setLanguage(i18n.language || Language.ENGLISH)
  }, [])

  const handleClick = (event: MouseEvent): void => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setIsVisible(false)
    }
  }
  const selectedLanguage = () => {
    if (!isAllowedPage) return 'EN'
    return language === Language.ENGLISH ? 'EN' : 'ID'
  }

  useEffect(() => {
    const matchAllowedPage = allowedPaths.find((route) => matchPath(pathname, route))
    setIsAllowedPage(!!matchAllowedPage)
  }, [pathname])

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [dispatch])

  return (
    <DropdownSelect ref={containerRef} active={isVisible}>
      <DropDownSelect onClick={() => setIsVisible(!isVisible)}>
        {selectedLanguage()}
        {isVisible ? (
          <ArrowUp style={{ marginLeft: fazzbizTheme.spacing.xs }} />
        ) : (
          <ArrowDown style={{ marginLeft: fazzbizTheme.spacing.xs }} />
        )}
      </DropDownSelect>
      {isVisible &&
        (!isAllowedPage ? (
          <Container>
            <Row>
              <LabelText>English</LabelText>
              <Tick style={{ color: fazzbizTheme.text.default }} />
            </Row>
            <Row disabled>
              <div>
                <LabelText>Indonesia</LabelText>
                <Body>Only available for selected features</Body>
              </div>
            </Row>
          </Container>
        ) : (
          <Container>
            <Row
              onClick={() => {
                switchLanguage(Language.ENGLISH)
              }}
            >
              <LabelText>English</LabelText>
              {language === Language.ENGLISH && (
                <Tick style={{ color: fazzbizTheme.text.default }} />
              )}
            </Row>
            <Row
              onClick={() => {
                switchLanguage(Language.INDONESIAN)
              }}
            >
              <div>
                <LabelText>Indonesia</LabelText>
                <Body>Only available for selected features</Body>
              </div>
              {language !== Language.ENGLISH && (
                <Tick style={{ color: fazzbizTheme.text.default }} />
              )}
            </Row>
          </Container>
        ))}
    </DropdownSelect>
  )
}

const Container = styled.div`
  width: 204px;
  padding: ${fazzbizTheme.spacing.md} 0;
  position: absolute;
  top: 30px;
  right: -10px;
  z-index: ${ELEVATIONS.MENUS_AND_SUB_MENUS};
  background: ${fazzbizTheme.neutral.white};
  box-shadow: 0px 4px 8px #33333328;
  border-radius: 16px;
`

const DropdownSelect = styled.div<{ active?: boolean }>`
  cursor: pointer;
  position: relative;
  width: 59px;
  background: ${(props) =>
    props.active
      ? 'linear-gradient(0deg, #ffffff14, #ffffff14), #0c45e1'
      : fazzbizTheme.neutral.white};
  border-radius: 12px;
  color: ${(props) => (props.active ? fazzbizTheme.text.white : fazzbizTheme.text.default)};
  display: none;
  margin-right: ${fazzbizTheme.spacing.xxs};
  ${DESKTOP_BREAKPOINT} {
    display: flex;
  }
`

const Row = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${fazzbizTheme.spacing.md};
  background-color: ${(props) => (props.disabled ? '#F2F4F5' : fazzbizTheme.neutral.white)};
  pointer-events: ${(props) => (props.disabled ? 'none' : '')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background: linear-gradient(0deg, #0c45e11e, #0c45e11e), ${fazzbizTheme.neutral.white};
    transition: 0.1s ease-in;
  }
`

const DropDownSelect = styled.div`
  padding: ${fazzbizTheme.spacing.xxs} ${fazzbizTheme.spacing.xs};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
`
