import * as React from 'react'

function SvgTick(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <g clipPath="url(#tick_svg__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.444 7.13a2 2 0 010 2.828l-13.32 13.32a2.5 2.5 0 01-3.536 0L3.56 17.252a2 2 0 012.829-2.828l4.967 4.967 12.26-12.26a2 2 0 012.828 0z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="tick_svg__clip0">
          <path fill="#fff" d="M0 0h30v30H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgTick
