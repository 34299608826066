import { uniqueId } from 'lodash'
import type { Reducer } from 'redux'
import type { State, ActionTypes } from './types'
import { RfiActions } from './types'

export const initialState = {
  rfiData: undefined,
  submissionStatus: undefined,
}

export const requestForInformation: Reducer<State, ActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case RfiActions.SET_RFI_DATA:
      return {
        ...state,
        rfiData: action.rfiData && {
          ...action.rfiData,
          rfiQuestions: action.rfiData.rfiQuestions.map((q) => ({
            ...q,
            key: uniqueId(),
          })),
        },
        submissionStatus: undefined,
      }
    case RfiActions.SET_SUBMISSION_STATUS:
      return {
        ...state,
        submissionStatus: action.submissionStatus,
      }
    default:
      return state
  }
}
