import type { TooltipRenderProps } from 'react-joyride'
import styled from '@emotion/styled'
import { fdsTheme, Typography, Button } from '@fazz/design-system'

const { colors, spacing } = fdsTheme
const { Body, Label } = Typography

function TooltipComponent({
  index,
  step,
  backProps,
  size,
  skipProps,
  primaryProps,
  tooltipProps,
}: TooltipRenderProps) {
  return (
    <Container ref={tooltipProps.ref}>
      <HeaderContainer>
        <div>
          {step.title ? (
            <Label size="md" color={colors.backgroundNeutral}>
              {step.title}
            </Label>
          ) : (
            ''
          )}
          {step.content}
        </div>
        <div style={{ cursor: 'pointer' }} {...skipProps}>
          <Body size="sm" color={colors.backgroundNeutral}>
            Lewati
          </Body>
        </div>
      </HeaderContainer>
      <FooterContainer>
        <Body color={colors.labelInput} size="sm">
          {index + 1} dari {size}{' '}
        </Body>
        <ButtonContainer>
          {index > 0 && (
            <Button
              variant="secondary"
              style={{ marginRight: spacing.xs }}
              size="sm"
              {...backProps}
            >
              Kembali
            </Button>
          )}
          <Button size="sm" {...primaryProps}>
            {index + 1 === size ? 'Selesai' : 'Lanjut'}
          </Button>
        </ButtonContainer>
      </FooterContainer>
    </Container>
  )
}

const Container = styled.div`
  background-color: ${colors.onNeutral};
  padding: ${spacing.sm};
  max-width: 344px;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ButtonContainer = styled.div``

const FooterContainer = styled(HeaderContainer)`
  margin-top: ${spacing.md};
  align-items: center;
`

export default TooltipComponent
