import { Typography } from 'fds3-src/index'
import { fdsTheme } from 'fds3-src/themes/theme-fds3'
import { EmptyDataContainer, StyledTable } from './Table.styles'
import type { EmptyDataProps, TableProps } from './Table.types'
import notFound from './notFound.svg'

const { colors } = fdsTheme
const { Body, Label } = Typography

function EmptyData({ action, subtitle = '', title = 'No results found' }: EmptyDataProps) {
  return (
    <EmptyDataContainer>
      <div>
        <img alt="Empty Box" src={notFound} width={150} height={150} />
        <Label size="md">{title}</Label>
        <Body size="md" color={colors.onNeutralSecondary}>
          {subtitle}
        </Body>
      </div>
      <div>{action}</div>
    </EmptyDataContainer>
  )
}

export function Table<RecordType extends object>({
  columns,
  isLoading,
  dataSource,
  rowKey,
  rowSelection,
  emptyText,
  style,
  className,
}: // DO NOT USE SPREAD OPERATOR i.e. {...props}
TableProps<RecordType>) {
  return (
    <StyledTable
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      rowSelection={rowSelection}
      rowKey={rowKey}
      className={className}
      style={style}
      pagination={false}
      scroll={{ x: 'max-content' }}
      locale={{
        emptyText: !isLoading ? (
          <EmptyData
            title={emptyText?.title}
            subtitle={emptyText?.subtitle}
            action={emptyText?.action}
          />
        ) : (
          <div style={{ height: 150 }}> </div> // This makes loading state have a little more height
        ),
      }}
      // DO NOT USE SPREAD OPERATOR i.e. {...props}
    />
  )
}

export default Table
