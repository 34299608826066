/**
 * Stop using this component.
 * this is because this one will create unnecessary dom in the app,
 * and it is also hard to handle modals with multiple state.
 *
 * we combine context and hook in the new version modal,
 * see more in modalContext.tsx
 * */
import { css as cssClassName } from 'emotion'
import { fdsTheme } from 'fds3-src/themes/theme-fds3'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Global, css, keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import { Cross as CrossIcon } from '@fazz/design-system-icons'
import {
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
  SMALL_SPACING,
} from '../../../themes/deprecated/spacing'
import { useTheme } from '../../../themes/deprecated/themeContext'
import Divider from '../Divider'
import { TitleSmall } from '../Typography'

const { radius } = fdsTheme
const bodyElement = document.body

type ModalProps = {
  onClose: () => void
  open: boolean
  icon: React.ReactNode | false
  title: React.ReactNode
  children: React.ReactNode
  footer: React.ReactNode
  modalClassName?: string
  disabledCloseIcon?: boolean
}

const Modal = (props: ModalProps) => {
  const { neutral } = useTheme()
  // eslint-disable-next-line @typescript-eslint/typedef
  const [mainElement] = useState(document.createElement('div'))

  useEffect(() => {
    bodyElement.appendChild(mainElement)
    return () => {
      bodyElement.removeChild(mainElement)
    }
  }, [mainElement])

  return ReactDOM.createPortal(
    <Container open={props.open}>
      {props.open && (
        <Global
          styles={css`
            body {
              overflow: hidden;
              position: fixed;
            }
          `}
        />
      )}
      <div
        className={`${cssClassName`
            width: calc(100% - 32px);
            max-width: 400px;
            background: white;
            box-shadow: 0px 4px 10px rgba(170, 170, 170, 0.25);
            border-radius: ${radius.lg};
            overflow: hidden;
            -webkit-font-smoothing: antialiased;
            margin-bottom: 1.7vh;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            ${props.modalClassName}`}`}
      >
        <ModalHeader hasIcon={Boolean(props.icon)}>
          <TitleContainer>
            <Title>
              {props.icon}
              <TitleSmall>{props.title}</TitleSmall>
            </Title>
            {!props.disabledCloseIcon && (
              <Close onClick={() => props.onClose()}>
                <CrossIcon color={neutral.text} width={18} />
              </Close>
            )}
          </TitleContainer>
          <Divider />
        </ModalHeader>
        <ModalBody>{props.children}</ModalBody>
        <ModalFooter>{props.footer}</ModalFooter>
      </div>
    </Container>,
    mainElement
  )
}
export default Modal

const showAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Container = styled.div<{ open: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  display: none;
  overflow: auto;
  z-index: 9; // bigger than nav bar z-index: 8

  ${(props) =>
    props.open &&
    css`
      display: block;
      animation: ${showAnimation} 0.3s ease;
    `}
`

const ModalHeader = styled.div<{ hasIcon: boolean }>`
  ${(props) =>
    props.hasIcon
      ? `
      height: 86px;
    `
      : `
      height: 73px;
    `}
  padding-left: ${EXTRA_LARGE_SPACING};
  padding-right: ${EXTRA_LARGE_SPACING};
`

const ModalBody = styled.div`
  border-bottom: 1px solid #e8e8e8;
  padding-top: ${LARGE_SPACING};
  padding-left: ${EXTRA_LARGE_SPACING};
  padding-right: ${EXTRA_LARGE_SPACING};
  padding-bottom: ${EXTRA_LARGE_SPACING};
`

const ModalFooter = styled.div`
  background: #f6f7f9;
  display: flex;
  justify-content: flex-end;
  padding-right: ${EXTRA_LARGE_SPACING};
  padding-top: ${SMALL_SPACING};
  padding-bottom: ${SMALL_SPACING};
`

const TitleContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  & :not(:last-child) {
    margin-right: 10px;
  }
`

const Close = styled.div`
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: -5px;
`
