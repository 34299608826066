import type { Reducer } from 'redux'
import type {
  ActionTypes,
  CardPaymentFilterValues,
  CardPaymentTransaction,
  PaginationCursor,
  PayNowFilterValues,
  PayNowTransaction,
  ReceiveTransactionFilterOption,
  VirtualAccountFilterValues,
  VirtualAccountTransaction,
} from '~/bizRegional/store/receive/types'
import {
  ReceiveActions,
  ReceiveCardPaymentTransactionsActions,
  ReceivePayNowTransactionsActions,
  ReceiveTransactionsTabKey,
  ReceiveVirtualAccountTransactionsActions,
} from '~/bizRegional/store/receive/types'

export type State = {
  transactionsActiveTab: ReceiveTransactionsTabKey
  filterOptions: ReceiveTransactionFilterOption
  paginationCursors: Map<number, PaginationCursor>
  virtualAccount: {
    isLoading: boolean
    transactions: VirtualAccountTransaction[]
    transaction: VirtualAccountTransaction | null
    showAdvancedFilter: boolean
    filterValues: VirtualAccountFilterValues
    totalTransactions: number
  }
  payNow: {
    isLoading: boolean
    transactions: PayNowTransaction[]
    transaction: PayNowTransaction | null
    showAdvancedFilter: boolean
    filterValues: PayNowFilterValues
    totalTransactions: number
  }
  cardPayment: {
    isLoading: boolean
    transactions: CardPaymentTransaction[]
    transaction: CardPaymentTransaction | null
    showAdvancedFilter: boolean
    filterValues: CardPaymentFilterValues
    totalTransactions: number
  }
}

export const RECEIVE_TRANSACTION_DEFAULT_FILTER_VALUES = {
  pageLimit: 10,
  currentPage: 1,
}

export const CARD_PAYMENT_DEFAULT_FILTER_VALUES = {
  ...RECEIVE_TRANSACTION_DEFAULT_FILTER_VALUES,
  cardType: '',
}

const initialState: State = {
  transactionsActiveTab: ReceiveTransactionsTabKey.VIRTUAL_ACCOUNT,
  paginationCursors: new Map(),
  filterOptions: {
    sortTypes: [],
    transactionStatuses: [],
  },
  virtualAccount: {
    isLoading: false,
    transactions: [],
    transaction: null,
    showAdvancedFilter: false,
    filterValues: RECEIVE_TRANSACTION_DEFAULT_FILTER_VALUES,
    totalTransactions: 0,
  },
  payNow: {
    isLoading: false,
    transactions: [],
    showAdvancedFilter: false,
    transaction: null,
    filterValues: RECEIVE_TRANSACTION_DEFAULT_FILTER_VALUES,
    totalTransactions: 0,
  },
  cardPayment: {
    isLoading: false,
    transactions: [],
    transaction: null,
    showAdvancedFilter: false,
    filterValues: CARD_PAYMENT_DEFAULT_FILTER_VALUES,
    totalTransactions: 0,
  },
}

export const receive: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case ReceiveActions.SET_TRANSACTIONS_ACTIVE_TAB:
      return {
        ...state,
        transactionsActiveTab: action.transactionsActiveTab,
      }
    case ReceiveVirtualAccountTransactionsActions.SHOW_ADVANCED_FILTER:
      return {
        ...state,
        virtualAccount: {
          ...state.virtualAccount,
          showAdvancedFilter: action.showAdvancedFilter,
        },
      }
    case ReceiveVirtualAccountTransactionsActions.SET_TRANSACTIONS:
      return {
        ...state,
        virtualAccount: {
          ...state.virtualAccount,
          transactions: action.transactions,
        },
      }
    case ReceiveVirtualAccountTransactionsActions.SET_IS_LOADING:
      return {
        ...state,
        virtualAccount: {
          ...state.virtualAccount,
          isLoading: action.isLoading,
        },
      }
    case ReceiveVirtualAccountTransactionsActions.SET_FILTER_VALUES: {
      return {
        ...state,
        virtualAccount: {
          ...state.virtualAccount,
          filterValues: action.filterValues,
        },
      }
    }
    case ReceiveVirtualAccountTransactionsActions.SET_TRANSACTION:
      return {
        ...state,
        virtualAccount: {
          ...state.virtualAccount,
          transaction: action.transaction,
        },
      }
    case ReceiveVirtualAccountTransactionsActions.SET_TOTAL_TRANSACTIONS:
      return {
        ...state,
        virtualAccount: {
          ...state.virtualAccount,
          totalTransactions: action.totalTransactions,
        },
      }
    case ReceiveVirtualAccountTransactionsActions.RESET_STATE: {
      return {
        ...state,
        paginationCursors: new Map(),
        virtualAccount: {
          isLoading: false,
          transactions: [],
          showAdvancedFilter: false,
          filterValues: RECEIVE_TRANSACTION_DEFAULT_FILTER_VALUES,
          totalTransactions: 0,
          transaction: null,
        },
      }
    }
    case ReceivePayNowTransactionsActions.SHOW_ADVANCED_FILTER:
      return {
        ...state,
        payNow: {
          ...state.payNow,
          showAdvancedFilter: action.showAdvancedFilter,
        },
      }

    case ReceivePayNowTransactionsActions.SET_TRANSACTIONS:
      return {
        ...state,
        payNow: {
          ...state.payNow,
          transactions: action.transactions,
        },
      }
    case ReceivePayNowTransactionsActions.SET_TRANSACTION:
      return {
        ...state,
        payNow: {
          ...state.payNow,
          transaction: action.transaction,
        },
      }
    case ReceivePayNowTransactionsActions.SET_IS_LOADING:
      return {
        ...state,
        payNow: {
          ...state.payNow,
          isLoading: action.isLoading,
        },
      }
    case ReceivePayNowTransactionsActions.SET_FILTER_VALUES: {
      return {
        ...state,
        payNow: {
          ...state.payNow,
          filterValues: action.filterValues,
        },
      }
    }
    case ReceivePayNowTransactionsActions.SET_TOTAL_TRANSACTIONS:
      return {
        ...state,
        payNow: {
          ...state.payNow,
          totalTransactions: action.totalTransactions,
        },
      }
    case ReceivePayNowTransactionsActions.RESET_STATE: {
      return {
        ...state,
        paginationCursors: new Map(),
        payNow: {
          isLoading: false,
          transactions: [],
          transaction: null,
          showAdvancedFilter: false,
          filterValues: RECEIVE_TRANSACTION_DEFAULT_FILTER_VALUES,
          totalTransactions: 0,
        },
      }
    }
    case ReceiveCardPaymentTransactionsActions.SHOW_ADVANCED_FILTER:
      return {
        ...state,
        cardPayment: {
          ...state.cardPayment,
          showAdvancedFilter: action.showAdvancedFilter,
        },
      }

    case ReceiveCardPaymentTransactionsActions.SET_TRANSACTIONS:
      return {
        ...state,
        cardPayment: {
          ...state.cardPayment,
          transactions: action.transactions,
        },
      }
    case ReceiveCardPaymentTransactionsActions.SET_TOTAL_TRANSACTIONS:
      return {
        ...state,
        cardPayment: {
          ...state.cardPayment,
          totalTransactions: action.totalTransactions,
        },
      }
    case ReceiveCardPaymentTransactionsActions.SET_FILTER_VALUES:
      return {
        ...state,
        cardPayment: {
          ...state.cardPayment,
          filterValues: action.filterValues,
        },
      }
    case ReceiveCardPaymentTransactionsActions.SET_IS_LOADING:
      return {
        ...state,
        cardPayment: {
          ...state.cardPayment,
          isLoading: action.isLoading,
        },
      }
    case ReceiveCardPaymentTransactionsActions.SET_TRANSACTION:
      return {
        ...state,
        cardPayment: {
          ...state.cardPayment,
          transaction: action.transaction,
        },
      }
    case ReceiveCardPaymentTransactionsActions.RESET_STATE: {
      return {
        ...state,
        paginationCursors: new Map(),
        cardPayment: {
          isLoading: false,
          transactions: [],
          transaction: null,
          showAdvancedFilter: false,
          filterValues: CARD_PAYMENT_DEFAULT_FILTER_VALUES,
          totalTransactions: 0,
        },
      }
    }
    case ReceiveActions.SET_TRANSACTIONS_FILTER_OPTIONS: {
      return {
        ...state,
        filterOptions: action.filterOptions,
      }
    }
    case ReceiveActions.SET_TRANSACTIONS_PAGINATION_CURSORS: {
      return {
        ...state,
        paginationCursors: action.paginationCursors,
      }
    }
    default:
      return state
  }
}
