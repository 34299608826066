import type { VerificationStatusType } from '~/types'

export type Tasks = { title: string; description: string; type: TaskType; action: string }

export enum TaskType {
  BALANCE_NOTIFICATION = 'balance_notification',
  TRANSFER_OUT_APPROVAL = 'transfer_out_approval',
  CARD_APPROVAL = 'card_approval',
}

export type PendingTasks = {
  tasks: Tasks[]
  isLoading: boolean
}
export type BusinessAccount = {
  generalWallet: {
    availableBalance: string
    holdingBalance?: string
    fundPoolId?: number
  } | null
}

export type BssVaAccount = {
  bankName: string
  bankFullName: string
  accountNumber: string
  recipientName: string
}

export enum NewLandingPageActions {
  SetUserDetails = 'SET_USER_DETAILS',
  SetBusinessVerificationStatus = 'NEW_LANDING_PAGE_ACTIONS.SET_BUSINESS_VERIFICATION_STATUS',
  SetPersonalVerificationStatus = 'NEW_LANDING_PAGE_ACTIONS.SET_PERSONAL_VERIFICATION_STATUS',
  SetPendingTasks = 'NEW_NEW_LANDING_PAGE_ACTIONS.SET_PENDING_TASKS',
  SetIsPendingTasksLoading = 'NEW_NEW_LANDING_PAGE_ACTIONS.SET_IS_PENDING_TASKS_LOADING',
  SetBusinessAccount = 'NEW_LANDING_PAGE_ACTIONS.SET_BUSINESS_ACCOUNT',
  SetIsLoadingBusinessAccount = 'NEW_LANDING_PAGE_ACTIONS.SET_IS_LOADING_BUSINESS_ACCOUNT',
  SetBssVaAccount = 'NEW_LANDING_PAGE_ACTIONS.SET_BSS_VA_ACCOUNT',
  SetIsLoadingBssVaAccount = 'NEW_LANDING_PAGE_ACTIONS.SET_IS_LOADING_BSS_VA_ACCOUNT',
  SetLebaranBannerHidden = 'NEW_LANDING_PAGE_ACTIONS.SET_LEBARAN_BANNER_HIDDEN',
  SET_DISMISS_HOMEPAGE_BANNER = 'SET_DISMISS_HOMEPAGE_BANNER',
}

type SetBusinessVerificationStatus = {
  type: typeof NewLandingPageActions.SetBusinessVerificationStatus
  businessVerificationStatus: VerificationStatusType
}
type SetPersonalVerificationStatus = {
  type: typeof NewLandingPageActions.SetPersonalVerificationStatus
  verificationStatus: VerificationStatusType
}
type SetPendingTasks = {
  type: typeof NewLandingPageActions.SetPendingTasks
  pendingTasks: Tasks[]
}

type SetIsPendingTasksLoading = {
  type: typeof NewLandingPageActions.SetIsPendingTasksLoading
  isLoading: boolean
}

type SetBusinessAccount = {
  type: typeof NewLandingPageActions.SetBusinessAccount
  businessAccount: BusinessAccount
}

type SetIsLoadingBusinessAccount = {
  type: typeof NewLandingPageActions.SetIsLoadingBusinessAccount
  isLoading: boolean
}

type SetBssVaAccount = {
  type: typeof NewLandingPageActions.SetBssVaAccount
  bssVaAccount: BssVaAccount
}

type SetIsLoadingBssVaAccount = {
  type: typeof NewLandingPageActions.SetIsLoadingBssVaAccount
  isLoading: boolean
}

type SetLebaranBannerHidden = {
  type: typeof NewLandingPageActions.SetLebaranBannerHidden
  isHidden: boolean
}

type SetDismissHomepageBanner = {
  type: typeof NewLandingPageActions.SET_DISMISS_HOMEPAGE_BANNER
  isHomepageBannerDismissed: boolean
}

export type ActionTypes =
  | SetBusinessVerificationStatus
  | SetPersonalVerificationStatus
  | SetBusinessAccount
  | SetIsLoadingBusinessAccount
  | SetPendingTasks
  | SetIsPendingTasksLoading
  | SetBssVaAccount
  | SetIsLoadingBssVaAccount
  | SetLebaranBannerHidden
  | SetDismissHomepageBanner
