import * as React from 'react'

function SvgPayout(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.144 14.714c.403.381 1.056.381 1.458 0a.938.938 0 000-1.378l-3.519-3.329h8.252c.367 0 .665-.563.665-1.258 0-.695-.298-1.259-.665-1.259H5.557l4.045-3.826a.938.938 0 000-1.378 1.073 1.073 0 00-1.458 0L2.302 7.81A.949.949 0 002 8.5c0 .259.109.507.302.69l5.842 5.524zM21.856 27.714c-.402.381-1.055.381-1.458 0a.938.938 0 010-1.378l3.52-3.328h-8.252c-.368 0-.666-.564-.666-1.26 0-.694.298-1.258.665-1.258h8.779l-4.046-3.826a.938.938 0 010-1.378 1.073 1.073 0 011.458 0l5.842 5.525c.194.182.302.43.302.689a.949.949 0 01-.302.69l-5.842 5.524z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPayout
