import { SVGProps } from 'react'

function DisconnectedLink(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.9775 0.75C4.4325 0.75 0.75 4.4325 0.75 8.9775C0.75 13.5225 4.4325 17.205 8.9775 17.205C13.5225 17.205 17.205 13.5225 17.205 8.9775C17.205 4.4325 13.5225 0.75 8.9775 0.75ZM9.6 12.1125L8.985 12.7275C8.4675 13.245 7.785 13.5075 7.1025 13.5075C6.42 13.5075 5.7375 13.245 5.22 12.7275C4.1775 11.685 4.1775 10.005 5.22 8.9625L5.835 8.3475C6.0525 8.13 6.4125 8.13 6.63 8.3475C6.8475 8.565 6.8475 8.925 6.63 9.1425L6.015 9.7575C5.7225 10.05 5.565 10.4325 5.565 10.845C5.565 11.2575 5.7225 11.64 6.015 11.9325C6.3075 12.225 6.69 12.3825 7.1025 12.3825C7.515 12.3825 7.8975 12.225 8.19 11.9325L8.805 11.3175C9.0225 11.1 9.3825 11.1 9.6 11.3175C9.8175 11.535 9.8175 11.895 9.6 12.1125ZM12.555 12.6375C12.4425 12.75 12.3 12.8025 12.1575 12.8025C12.015 12.8025 11.8725 12.75 11.76 12.6375L5.0925 5.97C4.875 5.7525 4.875 5.3925 5.0925 5.175C5.31 4.9575 5.67 4.9575 5.8875 5.175L12.555 11.8425C12.7725 12.06 12.7725 12.42 12.555 12.6375ZM12.6225 9.09L12.0225 9.69C11.805 9.9075 11.445 9.9075 11.2275 9.69C11.01 9.4725 11.01 9.1125 11.2275 8.895L11.9325 8.19C12.225 7.8975 12.3825 7.515 12.3825 7.1025C12.3825 6.69 12.225 6.3075 11.9325 6.015C11.64 5.7225 11.2575 5.565 10.845 5.565C10.4325 5.565 10.05 5.7225 9.7575 6.015L9.0525 6.72C8.835 6.9375 8.475 6.9375 8.2575 6.72C8.04 6.5025 8.04 6.1425 8.2575 5.925L8.9625 5.22C9.48 4.7025 10.1625 4.44 10.845 4.44C11.4825 4.44 12.1275 4.6725 12.63 5.13C13.77 6.165 13.71 8.0025 12.63 9.09H12.6225Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default DisconnectedLink
