import * as React from 'react'

function SvgAvatar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 8a3 3 0 11-6 0 3 3 0 016 0zm3 0A6 6 0 119 8a6 6 0 0112 0zm4 16.766V25H5v-.234C6.005 23.097 9.085 19 15 19s8.995 4.097 10 5.766zM28 26c0 1.105-.89 2-1.995 2H3.992A1.993 1.993 0 012 26v-1.337c0-.437.092-.866.311-1.243C3.376 21.58 7.245 16 15 16s11.624 5.58 12.689 7.42c.218.377.311.806.311 1.242V26z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgAvatar
