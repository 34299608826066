export enum DocLinks {
  RootSG = 'https://docs.fazz.com',
  RootID = 'https://docs.fazz.com/v4-ID',
}

export const SgVerificationFaq = 'https://support-sg.fazz.com/hc/en-us/articles/4410353117593'

export enum SupportLinks {
  WebsiteSupportSg = 'https://fazz.com/sg/support/',
  WebsiteSupportID = 'https://fazz.com/id/support/',
  SupportID = 'https://support-id.fazz.com',
  SupportSG = 'https://support-sg.fazz.com',
  CsID = 'https://bit.ly/FazzSupport-ID',
  CsSG = 'https://bit.ly/FazzSupportSG',
}

export const PrivacyPolicyLinks = {
  Sg: 'https://fazz.com/sg/privacy-policy/',
}

export const TermsAndConditionsLinks = {
  UsdAccountCustomerAuthorisationSg:
    'https://fazz.com/sg/terms-and-conditions/fazz-biz/usd-account-customer-authorisation/',
}

export const SgLinks = {
  BusinessFinancing: 'https://fazz.com/sg/business-financing/',
}

export const TERMS_OF_SERVICE_LINK_SG =
  'https://www.fazz.com/sg/terms-and-conditions/fazz-biz/terms-of-service'

export const TERMS_OF_SERVICE_LINK_ID =
  'https://fazz.com/id/terms-and-conditions/fazz-biz/terms-of-service/ '

export enum FeedbackLinks {
  FeedbackLink = 'https://feedback.fazz.com/',
}

export enum InvoiceLinks {
  ProdSG = 'https://invoice.fazz.com',
  StagingSG = 'https://invoice-staging.internal.fazz.com',
  ProdID = 'https://id-payment-link.fazz.com',
  StagingID = 'https://id-payment-link-staging.internal.fazz.com',
}

export enum SSOLinks {
  SsoDevXfers = 'https://sso-dev.internal.xfers.com',
  SsoProdXfers = 'https://sso.xfers.com',
  SsoStagingFazz = 'https://sso-staging.internal.fazz.com',
  SsoProdFazz = 'https://sso.fazz.com',
}

export enum FazzBrandingLinks {
  MainWebsite = 'https://fazz.com/',
  Facebook = 'https://www.facebook.com/FazzBusinessSoutheastAsia',
  Instagram = 'https://www.instagram.com/fazz.businesssea/',
  Twitter = 'https://twitter.com/fazzbusiness',
  LinkedIn = 'https://www.linkedin.com/company/fazz-financial-group/',
}
