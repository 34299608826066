import { StyledCard } from './Card.styles'
import type { CardProps } from './Card.types'

export function Card({
  radius = 'md',
  shadow = 'none',
  padding = 'md',
  hasOutline = false,
  style,
  className,
  children,
}: // DO NOT USE SPREAD OPERATOR i.e. {...props}
CardProps) {
  return (
    <StyledCard
      radius={radius}
      shadow={shadow}
      padding={padding}
      hasOutline={hasOutline}
      className={className}
      style={style}
      // DO NOT USE SPREAD OPERATOR i.e. {...props}
    >
      {children}
    </StyledCard>
  )
}

export default Card
