import { cx, css } from 'emotion'
import Card, { CardProps } from 'antd/es/card'
import 'antd/es/card/style/css'
import { FONT_WEIGHT, FONT_SIZE, LINE_HEIGHT } from '../../../themes/deprecated/TypographyStyles'
import { useTheme } from '../../../themes/deprecated/themeContext'

type CardWithoutType = Omit<CardProps, 'type'>

type CustomCardProps = CardWithoutType & {
  className?: string
  alignTitle?: 'center' | 'flex-start'
  alignBody?: 'left' | 'right'
  type?: 'default' | 'dark'
}

function CustomCard({
  className,
  alignTitle = 'center',
  alignBody = 'left',
  type = 'default',
  ...restProps
}: CustomCardProps) {
  const { card, text, neutral, base } = useTheme()

  const textColor = `
    color: ${type === 'default' ? base.neutral : text.white};

    & > div > div {
      color: ${type === 'default' ? base.neutral : text.white};
    }

    h1 {
      color: ${type === 'default' ? base.neutral : text.white};
    }
  `

  const baseStyle = css`
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: ${neutral.border};
    background: ${type === 'default' ? card.background.default : card.background.dark} !important;

    & > .ant-card-head {
      padding: 0 15px;
      font-weight: ${FONT_WEIGHT.BOLD};
      font-size: ${FONT_SIZE.P1_MOBILE};
      line-height: ${LINE_HEIGHT.P1_MOBILE};
      ${textColor};

      .ant-card-head-title {
        padding: 15px 0;
        white-space: normal;
        ${textColor};
      }

      .ant-card-extra {
        padding: 15px 0;
        font-weight: ${FONT_WEIGHT.BOLD};
        font-size: ${FONT_SIZE.P1_MOBILE};
        line-height: ${LINE_HEIGHT.P1_MOBILE};
        ${textColor};
      }
    }

    & > .ant-card-body {
      padding: 15px;
      font-weight: ${FONT_WEIGHT.REGULAR};
      font-size: ${FONT_SIZE.P1_MOBILE};
      line-height: ${LINE_HEIGHT.P1_MOBILE};
      ${textColor};
    }
  `

  const alignTitleStyle = (props: string) => css`
    .ant-card-head-wrapper {
      align-items: ${props};
    }
  `

  const alignBodyStyle = (props: string) => css`
    .ant-card-body {
      text-align: ${props};
    }
  `

  return (
    <Card
      className={cx(baseStyle, className, alignTitleStyle(alignTitle), alignBodyStyle(alignBody))}
      {...restProps}
    />
  )
}

export default CustomCard
