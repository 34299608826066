import * as React from 'react'
import { SVGProps } from 'react'

function SvgPersonal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.2 16.962a6.838 6.838 0 0 1 6.837 6.788 3.137 3.137 0 0 1-3.137 3.137H9.037A3.137 3.137 0 0 1 5.9 23.75a6.85 6.85 0 0 1 6.837-6.838H17.2v.05Zm0-1.925h-4.463a8.75 8.75 0 0 0-8.75 8.75 5 5 0 0 0 5 5H20.85a5 5 0 0 0 5-5 8.75 8.75 0 0 0-8.75-8.75h.1ZM15 3.075a4.113 4.113 0 1 1-4.138 4.112A4.126 4.126 0 0 1 15 3.075Zm0-1.825a5.987 5.987 0 1 0 0 11.974A5.987 5.987 0 0 0 15 1.25Z"
        fill="#757575"
      />
    </svg>
  )
}

export default SvgPersonal
