import type { CSSProperties, Key, ReactNode, SVGProps } from 'react'
import type { BadgeVariantType } from '../Badge/Badge.types'
import type { menuThemes } from './Menu.styles'

type Divider = {
  type: 'divider'
  className?: string
}

type MenuItemType = {
  /**
   * Set the label for `MenuItem`
   */
  label: string
  /**
   * Set the key for `MenuItem`
   */
  key: Key
  /**
   * Set the icon for `MenuItem`
   */
  icon?: React.ReactElement<SVGProps<SVGSVGElement>>
  /**
   * Override the text to be displayed. Useful for rendering external links instead of routing.
   */
  render?: () => ReactNode
  /**
   * Set the sub items to be displayed for `MenuItem`
   */
  subItems?: SubMenuItem[]
  /**
   * Set the badge to be displayed for `MenuItem`
   */
  badge?: {
    label: string
    variant: BadgeVariantType
  }
  /**
   * Setting className for  `MenuItem`. Required for styled-components.
   */
  className?: string

  /**
   * Setting path for  `MenuItem`. Used for navigation.
   */
  path?: string
}

type MenuInfo = {
  key: React.Key
  keyPath: React.Key[]
  item: React.ReactInstance
  domEvent: React.MouseEvent<HTMLElement>
  path?: string
}

export type SubMenuItem = Omit<MenuItemType, 'icon' | 'subItems'>

export type MenuItem = MenuItemType | Divider

export type MenuProps = {
  /**
   * Set the items to be displayed for `Menu`
   */
  items: MenuItem[]

  /**
   * Override default styles for  `Menu`
   */
  style?: CSSProperties

  /**
   * Setting className for  `Menu`. Required for styled-components.
   */
  className?: string

  /**
   * callback fn when `Menu` is clicked
   */
  onClick?: (info: MenuInfo) => void

  /**
   * Set the color scheme for `Menu`
   */
  colorScheme?: keyof typeof menuThemes

  /**
   * Sets whether to close submenus that are not active for `Menu`
   */
  collapseInactiveMenus?: boolean
}

export function isDivider(item: MenuItemType | Divider): item is Divider {
  return (item as Divider).type === 'divider'
}
