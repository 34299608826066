/* eslint-disable @typescript-eslint/naming-convention */
import type { Transactions } from '~/biz/store/transactions/types'
import type { BusinessVerificationStatus, Moment, PersonalVerificationStatus } from '~/types'

export enum XFERS_ACCEPT_TAB_KEY {
  VIRTUAL_ACCOUNTS = 'virtual_accounts',
  QRIS = 'qris',
  E_WALLET = 'e_wallet',
  RETAIL = 'retail',
  CASHIN = 'cashin',
  CARD = 'card',
}

export enum XFERS_SEND_TAB_KEY {
  DISBURSEMENT_API = 'disbursement_api',
  DISBURSEMENT_DASH = 'disbursement_dash',
  CASHOUT = 'cashout',
}

export const XFERS_ACCOUNT_STATEMENT_KEY = 'xfers_account_statement_key'

export enum XFERS_ACCOUNT_STATEMENT_TRX_TYPE {
  TRANSFER_IN = 'Transfer-in',
  TRANSFER_OUT = 'Transfer-out',
  FEE = 'Fee',
  TAX = 'Tax',
  ADMIN = 'Admin',
}

export enum XFERS_ACCOUNT_STATEMENT_TRX_METHODS {
  BANK_TRANSFER = 'Bank Transfer',
  VIRTUAL_ACCOUNT = 'Virtual Account',
  E_WALLET = 'E-Wallet',
  CONVENIENCE_STORE = 'Convinience Store',
  QRIS = 'QRIS',
  CARD = 'Card',
}

export type IndoTransactions = Omit<
  Transactions,
  'amount' | 'net_amount' | 'transaction_hash_url' | 'transaction_hash' | 'account_holder_name'
> & {
  completed_at: string | null
  fee?: string | undefined
  amount: string
  net_amount: string
  wallet_type: string
  source_destination: {
    source: string
    destination: string
  }
  merchant_description: string
  contract_count?: string
  requester?: string
  batch_status?: string
  ref_id?: string
  approved_at?: string | null
  rejected_at?: string | null
  transactionType: string
  runningBalance?: string
}

type Filters = Array<{ label: string; value: string }>

export type FilterOptions = {
  transactionStatuses: Filters
  transactionTypes: Filters
  sortTypes: Filters
  paymentChannels: Filters | undefined
  cards: Filters | undefined
  banks: Filters | undefined
  retailStoreTypes: Filters | undefined
}

export type FilterValuesObject = {
  [filterKey: string]:
    | Moment
    | string[]
    | number[]
    | string
    | number
    | IndoTransactions[]
    | boolean
    | undefined
}

export type PaginationCursor = {
  lastRecordId: string
  lastRecordDatetime: string
}

export type FilterValues = {
  startDate: Moment | string
  endDate: Moment | string
  transactionStatuses: string[]
  transactionTypes: XFERS_ACCOUNT_STATEMENT_TRX_TYPE[]
  methods: XFERS_ACCOUNT_STATEMENT_TRX_METHODS[]
  paymentMethod: string
  sortType: string
  accountIds: number[]
  currentPage: number
  pageLimit: number
  searchString: string
  contractsExist: boolean
  banks: string[]
  paymentChannels: string[]
  cardTypes: string[]
  retailStoreTypes: string[]
  isTransactionLoading: boolean
  transactions: IndoTransactions[]
  disbursementMethod: string
  totalCount: number
  referenceId: string
  vaNumberOrRetailCode: string
  cashinCode: string
  displayName: string
  eWalletTypes: string
  [key: string]: FilterValuesObject[keyof FilterValuesObject]
}

export enum ID_TRANSACTIONS_ACTIONS {
  SET_IS_TRANSACTION_LOADING = 'ID_TRANSACTIONS_ACTIONS.SET_IS_TRANSACTION_LOADING',
  SET_FILTERS = 'ID_TRANSACTION_ACTIONS.SET_FILTERS',
  SET_IS_FILTER_LOADING = 'ID_TRANSACTION_ACTIONS.SET_IS_FILTER_LOADING',
  GET_TRANSACTIONS = 'ID_TRANSACTIONS_ACTIONS.GET_TRANSACTIONS',
  GET_TRANSACTIONS_FILTER_OPTIONS = 'ID_TRANSACTION_ACTIONS.GET_TRANSACTIONS_FILTER_OPTIONS',
  SET_TRANSACTIONS_FILTER_OPTIONS_LOADING = 'ID_TRANSACTION_ACTIONS.SET_TRANSACTION_FILTER_OPTIONS_LOADING',
  SET_TRANSACTIONS_ERROR_MESSAGE = 'ID_TRANSACTIONS_ACTIONS.SET_TRANSACTIONS_ERROR_MESSAGE',
  SET_TAB_KEY_NAME = 'ID_TRANSACTIONS_ACTIONS.SET_TAB_KEY_NAME',
  SET_TRANSACTION_TYPE = 'ID_TRANSACTIONS_ACTIONS.SET_TRANSACTION_TYPE',
  SET_USE_NEW_PAGINATION = 'ID_TRANSACTIONS_ACTIONS.SET_USE_NEW_PAGINATION',
  SET_PAGINATION_CURSORS = 'ID_TRANSACTIONS_ACTIONS.SET_PAGINATION_CURSORS',
  SET_BUSINESS_VERIFICATION_STATUS = 'ID_TRANSACTIONS_ACTIONS.SET_BUSINESS_VERIFICATION_STATUS',
  SET_PERSONAL_VERIFICATION_STATUS = 'ID_TRANSACTIONS_ACTIONS.SET_PERSONAL_VERIFICATION_STATUS',
}

type SetBusinessVerificationStatus = {
  type: typeof ID_TRANSACTIONS_ACTIONS.SET_BUSINESS_VERIFICATION_STATUS
  businessVerificationStatus: BusinessVerificationStatus
}

type SetPersonalVerificationStatus = {
  type: typeof ID_TRANSACTIONS_ACTIONS.SET_PERSONAL_VERIFICATION_STATUS
  userVerificationStatus: PersonalVerificationStatus
}

type SetIsTransactionLoading = {
  type: typeof ID_TRANSACTIONS_ACTIONS.SET_IS_TRANSACTION_LOADING
  isTransactionLoading: boolean
}

type SetIsFilterLoading = {
  type: typeof ID_TRANSACTIONS_ACTIONS.SET_IS_FILTER_LOADING
  isFilterLoading: boolean
}

type GetTransactions = {
  type: typeof ID_TRANSACTIONS_ACTIONS.GET_TRANSACTIONS
  transactions: IndoTransactions[]
  contractsExist: boolean
  totalCount: number
}

type GetTransactionsFilterOptions = {
  type: typeof ID_TRANSACTIONS_ACTIONS.GET_TRANSACTIONS_FILTER_OPTIONS
  filterOptions: FilterOptions
}

type SetTransactionsFilterOptions = {
  type: typeof ID_TRANSACTIONS_ACTIONS.SET_TRANSACTIONS_FILTER_OPTIONS_LOADING
  isFilterOptionsLoading: boolean
}

type SetFilters = {
  type: typeof ID_TRANSACTIONS_ACTIONS.SET_FILTERS
  filterValuesObject: FilterValuesObject
}

type SetUseNewPagination = {
  type: typeof ID_TRANSACTIONS_ACTIONS.SET_USE_NEW_PAGINATION
  useNewPagination: boolean
}

type SetPaginationCursors = {
  type: typeof ID_TRANSACTIONS_ACTIONS.SET_PAGINATION_CURSORS
  paginationCursors: Array<PaginationCursor>
}

type SetTransactionsErrorMessage = {
  type: typeof ID_TRANSACTIONS_ACTIONS.SET_TRANSACTIONS_ERROR_MESSAGE
  errorMessage: string
}

type SetTabKeyName = {
  type: typeof ID_TRANSACTIONS_ACTIONS.SET_TAB_KEY_NAME
  tabKeyName: string
}

type SetTransactionType = {
  type: typeof ID_TRANSACTIONS_ACTIONS.SET_TRANSACTION_TYPE
  typeName: string
}

export type ActionTypes =
  | SetIsTransactionLoading
  | SetIsFilterLoading
  | GetTransactions
  | GetTransactionsFilterOptions
  | SetTransactionsFilterOptions
  | SetFilters
  | SetTransactionsErrorMessage
  | SetTabKeyName
  | SetTransactionType
  | SetUseNewPagination
  | SetPaginationCursors
  | SetBusinessVerificationStatus
  | SetPersonalVerificationStatus
