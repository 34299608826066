import { MorakLoanVerificationStatus } from '~/types'

export enum WalletTag {
  SGD_ACCOUNT = 'sgd_cash',
  CREDIT_LINE = 'sg_credit_line_supplier_transfer',
  LOAN = 'loan',
  FLEXPAY = 'flexpay',
  IDR_CASH_ACCOUNT = 'idr_cash',
  TERM_LOAN = 'sg_term_loan',
}

export type WalletLedger = {
  id: string
  type: string
  attributes: {
    balance: string
    purpose: WalletLedgerPurpose
  }
}

export type LedgerType = Map<WalletLedgerPurpose, WalletLedger>

export enum WalletLedgerPurpose {
  PendingTransfer = 'PendingTransfer',
  Available = 'Available',
}

export type Wallet = {
  id: string
  type: string
  attributes: {
    tag: WalletTag
    description: string
  }
  relationships: {
    ledgers: Array<WalletLedger>
  }
}

export enum WalletActions {
  SET_WALLETS = 'WalletActions.SET_WALLETS',
  SET_IS_MORAK_VERIFIED = 'WalletActions.SET_IS_MORAK_VERIFIED',
  SET_MORAK_LOAN_VERIFICATION_STATUS = 'WalletActions.SET_MORAK_LOAN_VERIFICATION_STATUS',
  SET_MORAK_LOAN_VERIFICATION_STATUS_LOADING = 'WalletActions.SET_MORAK_LOAN_VERIFICATION_STATUS_LOADING',
  SET_WALLET_IS_INITIATED = 'NEW_LANDING_PAGE_ACTIONS.SET_WALLET_IS_INITIATED ',
}

type SetWallets = {
  type: typeof WalletActions.SET_WALLETS
  wallets: Map<WalletTag, Wallet | null>
}

type SetMorakLoanVerificationStatus = {
  type: typeof WalletActions.SET_MORAK_LOAN_VERIFICATION_STATUS
  morakLoanVerificationStatus: MorakLoanVerificationStatus
}

type SetMorakLoanVerificationStatusLoading = {
  type: typeof WalletActions.SET_MORAK_LOAN_VERIFICATION_STATUS_LOADING
  morakLoanVerificationStatusLoading: boolean
}

type SetIsMorakVerified = {
  type: typeof WalletActions.SET_IS_MORAK_VERIFIED
  isMorakVerified: boolean
}

type SetIsWalletInitiated = {
  type: typeof WalletActions.SET_WALLET_IS_INITIATED
  isWalletInitiated: boolean
}

export type ActionTypes =
  | SetWallets
  | SetMorakLoanVerificationStatus
  | SetMorakLoanVerificationStatusLoading
  | SetIsMorakVerified
  | SetIsWalletInitiated
