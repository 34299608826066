import { useResponsive } from 'fds3-src/hooks'
import { Title } from 'fds3-src/themes/Typography'
import { CloseRounded } from '@fazz/design-system-icons'
import { Button } from '../Button'
import { ButtonGroup } from '../ButtonGroup'
import { IconButton } from '../IconButton'
import { OverlayScrollbar } from '../OverlayScrollbar'
import { ContentContainer, StyledDialog, dialogMaskStyle } from './Dialog.styles'
import { type DialogProps } from './Dialog.types'

export function Dialog({
  open,
  style,
  className,
  children,
  title,
  size = 'sm',
  isClosable = true,
  isMaskClosable = false,
  isDestroyedOnClose = false,
  footerButton = 'group',
  footerLayout = 'horizontal',
  cancelText = 'Cancel',
  okText = 'Agree',
  iconButtonIcon,
  onCancelClick,
  onOkClick,
  onIconClick,
  cancelButtonProps,
  okButtonProps,
  iconButtonProps,
  isDisabled = false,
}: // DO NOT USE SPREAD OPERATOR i.e. {...props}
DialogProps) {
  const { isMobileView } = useResponsive()

  const width = () => {
    switch (size) {
      case 'lg':
        return isMobileView ? 400 : 600
      case 'sm':
      default:
        return isMobileView ? 328 : 400
    }
  }

  return (
    <StyledDialog
      title={<Title size="sm">{title}</Title>}
      closable={isClosable}
      maskClosable={isMaskClosable}
      destroyOnClose={isDestroyedOnClose}
      closeIcon={<IconButton size="sm" variant="ghost" icon={<CloseRounded />} />}
      visible={open}
      className={className}
      style={style}
      maskStyle={dialogMaskStyle}
      onCancel={onCancelClick}
      width={width()}
      footer={
        footerButton === 'group' ? (
          <ButtonGroup variant={footerLayout}>
            {iconButtonIcon && (
              <IconButton
                {...iconButtonProps}
                icon={iconButtonIcon}
                variant="secondary"
                onClick={onIconClick}
              />
            )}
            <Button {...cancelButtonProps} variant="secondary" fullWidth onClick={onCancelClick}>
              {cancelText}
            </Button>
            <Button {...okButtonProps} fullWidth onClick={onOkClick} isDisabled={isDisabled}>
              {okText}
            </Button>
          </ButtonGroup>
        ) : (
          <Button {...okButtonProps} fullWidth onClick={onOkClick} isDisabled={isDisabled}>
            {okText}
          </Button>
        )
      }
    >
      <OverlayScrollbar>
        <ContentContainer>{children}</ContentContainer>
      </OverlayScrollbar>
    </StyledDialog>
  )
}

export default Dialog