import * as React from 'react'

function SvgFile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#file_svg__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.98 6.237c-1.712-1.922-5.517-2.853-8.6.231L7.19 14.656l-1.697-1.697 8.188-8.188c4.155-4.155 9.553-2.98 12.09-.13 1.478 1.657 2.297 3.598 2.164 5.693-.133 2.08-1.192 4.12-3.087 6.015-1.467 1.466-3.193 3.155-4.912 4.837l-.003.003c-1.723 1.686-3.44 3.365-4.897 4.822-1.057 1.057-2.489 1.788-4.036 1.899-1.578.113-3.204-.424-4.558-1.779-1.134-1.133-1.611-2.766-1.584-4.295.026-1.533.562-3.156 1.705-4.3.883-.883 3.162-3.11 5.212-5.11 1.027-1.002 1.999-1.948 2.714-2.644l1.146-1.116a6.106 6.106 0 011.634-1.238c.504-.251 1.159-.469 1.897-.43.768.039 1.525.35 2.194.988.679.647 1.02 1.403 1.067 2.187.046.747-.181 1.412-.438 1.918-.26.51-.585.93-.833 1.212a6.412 6.412 0 01-.425.44l-.02.019-.006.005-.213.205-.616.593c-.52.5-1.235 1.19-2.017 1.947a456.407 456.407 0 00-4.457 4.356l-1.697-1.697c1.08-1.08 2.921-2.87 4.485-4.384l2.02-1.95.618-.595.228-.218.019-.019.016-.014.009-.008.052-.05a3.998 3.998 0 00.697-.929c.14-.277.194-.508.183-.686-.009-.141-.06-.34-.327-.594-.276-.263-.496-.321-.662-.33-.195-.01-.43.046-.699.18a3.404 3.404 0 00-.691.476 3.655 3.655 0 00-.248.237l-.006.008-.029.032-.034.034-.837-.86.837.86-1.173 1.14-2.712 2.643a866.049 866.049 0 00-5.191 5.09c-.612.612-.984 1.59-1.003 2.644-.018 1.057.322 1.996.882 2.556.866.866 1.811 1.145 2.689 1.082.907-.065 1.814-.505 2.511-1.202 1.467-1.467 3.193-3.155 4.912-4.837l.003-.004a663.66 663.66 0 004.897-4.821c1.613-1.614 2.304-3.134 2.388-4.47.084-1.323-.413-2.658-1.56-3.945z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="file_svg__clip0">
          <path fill="#fff" d="M0 0h30v30H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgFile
