import styled from '@emotion/styled'
import { Cross, InfoFilled } from '@fazz/design-system-icons'
import { Body } from '../../../themes/Typography'
import { fdsTheme } from '../../../themes/theme-fds3'
import { Button } from '../Button'
import { StyledBanner } from './Banner.styles'
import { BannerProps } from './Banner.types'

type ActionButtonProps = {
  onClickAction?: (event: React.MouseEvent<HTMLButtonElement>) => void
  color?: string
  actionButtonMessage?: string
}

type CustomMessageProps = {
  message: React.ReactNode
  isBreakLine: boolean
  showIcon: boolean
  onClickAction?: (event: React.MouseEvent<HTMLButtonElement>) => void
  actionButtonMessage?: string
  color?: string
}

// Retrieve all theming data
const { colors, spacing } = fdsTheme

function ActionButton({ onClickAction, color, actionButtonMessage }: ActionButtonProps) {
  return (
    <Button variant="plain" onClick={onClickAction} style={{ color }}>
      {actionButtonMessage && actionButtonMessage}
    </Button>
  )
}

function CustomMessage({
  message,
  isBreakLine,
  showIcon,
  onClickAction,
  actionButtonMessage,
  color,
}: CustomMessageProps) {
  return (
    <MessageContainer showIcon={showIcon} breakLine={isBreakLine}>
      <ResponsiveBody size="md">{message}</ResponsiveBody>
      {actionButtonMessage && onClickAction && color && (
        <ActionButton
          onClickAction={onClickAction}
          actionButtonMessage={actionButtonMessage}
          color={color}
        />
      )}
    </MessageContainer>
  )
}

export function Banner({
  variant = 'information',
  message,
  style,
  className,
  actionButtonMessage,
  onClickAction,
  onClose,
  icon = <InfoFilled />,
  isClosable = true,
  showLeftIcon = false,
}: // DO NOT USE SPREAD OPERATOR i.e. {...props}
BannerProps) {
  const getPrimaryColor = () => {
    switch (variant) {
      case 'positive':
        return colors.positive
      case 'information':
        return colors.information
      case 'warning':
        return colors.warning
      case 'critical':
        return colors.critical
      default:
        return colors.information
    }
  }

  const wordLimit = 20 // number of characters before the line breaks
  const isBreakLine = message.length > wordLimit

  return (
    <StyledBanner
      variant={variant}
      message={
        <CustomMessage
          message={message}
          isBreakLine={isBreakLine}
          showIcon={showLeftIcon}
          onClickAction={onClickAction}
          actionButtonMessage={actionButtonMessage}
          color={getPrimaryColor()}
        />
      }
      className={className}
      style={style}
      onClose={onClose}
      closable={isClosable}
      closeText={isClosable && <Cross />}
      icon={icon}
      breakLine={isBreakLine}
      showIcon={showLeftIcon}
      // DO NOT USE SPREAD OPERATOR i.e. {...props}
    />
  )
}

export default Banner

const MessageContainer = styled.div<{ breakLine: boolean; showIcon: boolean }>`
  display: flex;
  flex-direction: ${({ breakLine }) => (breakLine ? 'column' : 'row')};
  align-items: ${({ breakLine }) => (breakLine ? 'flex-start' : 'initial')};
  gap: ${({ breakLine }) => (breakLine ? spacing.sm : 'initial')};
  justify-content: space-between;
  padding-left: ${({ showIcon }) => (showIcon ? spacing.xxl : spacing.xs)};
  width: 100%;
`

const ResponsiveBody = styled(Body)`
  text-align: start;
`
