import * as React from 'react'

function SvgConfirmation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.059 15c0-5.49 4.45-9.94 9.94-9.94 1.39 0 2.711.285 3.91.798a1.5 1.5 0 001.18-2.758A12.903 12.903 0 0015 2.06C7.852 2.06 2.059 7.853 2.059 15c0 7.147 5.793 12.94 12.94 12.94 7.146 0 12.94-5.793 12.94-12.94 0-.574-.038-1.14-.11-1.695a1.5 1.5 0 10-2.975.39c.056.426.085.862.085 1.305 0 5.49-4.45 9.94-9.94 9.94-5.49 0-9.94-4.45-9.94-9.94zm21-6.94a1.5 1.5 0 10-2.121-2.12l-9.94 9.939-1.389-1.39-2.05-2.05a1.5 1.5 0 00-2.121 2.122l2.05 2.05 2.096 2.096a2 2 0 002.829 0L26.059 8.061z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgConfirmation
