import { ErrorMessage } from 'formik'
import { Radio, RadioGroupProps } from 'formik-antd'
import styled from '@emotion/styled'
import { LabelText } from '../../../deprecated/Typography'

type FormikInputProps = RadioGroupProps & {
  className?: string
  label?: string | React.ReactNode
}

const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
  justify-content: space-between;
`

export default function FormikRadioGroup({
  name,
  validate,
  fast,
  size,
  disabled,
  className,
  label,
  ...rest
}: FormikInputProps) {
  return (
    <>
      {label && (
        <LabelContainer>
          <LabelText style={{ display: 'flex' }}>{label}</LabelText>
        </LabelContainer>
      )}
      <Radio.Group
        name={name}
        validate={validate}
        fast={fast}
        size={size}
        disabled={disabled}
        className={className}
        {...rest}
      />
      <ErrorMessage name={name} />
    </>
  )
}
