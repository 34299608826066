import * as React from 'react'

function SvgVecThumbnailDoc(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 200 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M102.706 99.006a3.613 3.613 0 01-1.831-.49 3.593 3.593 0 01-1.315-4.924c4.589-7.734 18.537-25.807 49.01-25.807 13.2 0 24.776 3.506 34.393 10.416 7.914 5.672 12.349 12.091 14.463 15.185 1.134 1.65.696 3.867-.954 5.002-1.65 1.108-3.919.696-5.053-.954-3.842-5.57-15.495-22.482-42.849-22.482-26.709 0-38.775 15.572-42.719 22.25-.67 1.186-1.882 1.804-3.145 1.804z"
        fill="#F6F7F9"
      />
      <path
        d="M163.91 169.751a3.56 3.56 0 01-.902-.104c-22.095-5.543-30.345-27.869-30.68-28.797l-.052-.206c-.18-.645-4.615-15.959 2.192-24.931 3.119-4.099 7.863-6.187 14.128-6.187 5.826 0 10.029 1.83 12.916 5.62 2.372 3.094 3.326 6.909 4.254 10.596 1.934 7.657 3.326 11.679 11.37 12.091 3.532.181 5.852-1.907 7.167-3.686 3.558-4.847 4.176-12.762 1.495-19.723-3.455-9.023-15.675-26.013-37.228-26.013-9.204 0-17.66 2.99-24.441 8.61-5.62 4.667-10.08 11.241-12.22 17.996-3.97 12.581 1.238 32.355 1.289 32.536.516 1.908-.644 3.893-2.578 4.383-1.934.515-3.944-.645-4.46-2.553-.232-.902-5.801-21.991-1.212-36.532 5.002-15.752 20.419-31.633 43.622-31.633 10.725 0 20.857 3.686 29.313 10.647 6.549 5.415 11.911 12.685 14.696 19.955 3.557 9.281 2.604 19.697-2.424 26.503-3.351 4.538-8.121 6.91-13.406 6.652-13.767-.696-16.242-10.416-18.047-17.506-1.856-7.27-3.042-10.776-10.132-10.776-3.893 0-6.626 1.083-8.327 3.326-2.321 3.068-2.501 7.863-2.243 11.343.258 3.635 1.031 6.575 1.211 7.167.568 1.444 7.941 19.723 25.601 24.158 1.96.489 3.12 2.449 2.63 4.357a3.66 3.66 0 01-3.532 2.707z"
        fill="#F6F7F9"
      />
      <path
        d="M176.284 157.762c-7.734 0-14.308-2.166-19.568-6.471-10.57-8.611-11.756-22.636-11.807-23.229-.155-1.985 1.34-3.713 3.351-3.867 2.011-.155 3.738 1.314 3.893 3.3.026.206 1.083 11.678 9.23 18.278 4.821 3.893 11.266 5.44 19.207 4.538a3.624 3.624 0 014.022 3.171c.232 1.985-1.212 3.764-3.197 3.97-1.753.207-3.481.31-5.131.31zm6.111-95.623c-3.017-1.985-13.716-7.889-33.825-7.889-21.115 0-31.84 6.523-34.212 8.173-.155.103-.31.206-.438.335-.026.026-.052.026-.052.026a3.59 3.59 0 00-1.212 2.68c0 1.986 1.624 3.584 3.635 3.584.8 0 1.522-.257 2.114-.67l-.025.026c.103-.077 9.358-6.961 30.19-6.961 20.831 0 30.086 6.91 30.189 6.96l-.025-.025.051-.051a3.608 3.608 0 002.192.721c2.011 0 3.635-1.598 3.635-3.583a3.586 3.586 0 00-2.217-3.326z"
        fill="#F6F7F9"
      />
      <path
        d="M135.551 168.1c-.98 0-1.934-.387-2.656-1.134-8.843-9.385-13.844-19.878-15.726-33v-.078c-1.057-8.688.49-20.985 8.069-29.442 5.595-6.239 13.458-9.41 23.332-9.41 11.679 0 20.857 5.492 26.58 15.856 4.151 7.528 4.976 15.03 5.002 15.339.206 1.986-1.263 3.739-3.248 3.945-1.986.206-3.79-1.238-3.996-3.197 0-.077-.722-6.523-4.254-12.813-4.435-7.915-11.189-11.937-20.11-11.937-7.708 0-13.741 2.346-17.892 6.987-5.981 6.677-7.141 16.964-6.316 23.77 1.65 11.602 6.059 20.831 13.844 29.081a3.566 3.566 0 01-.18 5.079 3.656 3.656 0 01-2.449.954z"
        fill="#F6F7F9"
      />
      <g filter="url(#vec-thumbnail-doc_svg__filter0_d)">
        <path
          d="M8.748 32.322a6 6 0 014.378-7.266l65.82-16.334L111.6 26.69l26.609 103.842a6 6 0 01-4.389 7.318l-87.48 21.366a6 6 0 01-7.247-4.386L8.748 32.322z"
          fill="#fff"
        />
      </g>
      <path
        opacity={0.8}
        d="M83.961 27.963L78.945 8.722l32.75 18.275-20.431 5.263a6 6 0 01-7.303-4.297z"
        fill="#D4E1EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.54 96.128L39.3 110.721l-1.458-5.82 58.24-14.593 1.459 5.82zM92.54 78.128L34.3 92.721l-1.458-5.82 58.24-14.593 1.459 5.82zM73.42 122.019l-29.12 7.297-1.458-5.821 29.12-7.296 1.459 5.82z"
        fill="#CFDAE4"
      />
      <path
        d="M33.468 64.92l-5.916-6.566 4.884-1.127 3.285 4.167 2.598-5.525 5-1.155-4.768 9.807 5.757 6.418-4.766 1.1-3.245-3.991-2.52 5.322-5.09 1.175 4.781-9.625zM44.326 55.04l2.466-1.127-.158-2.164c-.283-4.18.957-5.922 4.428-6.723 1.324-.305 2.87-.322 3.752-.123l.188 2.56-2.655.581c-1.742.372-1.712 1.17-1.517 3.759l.08 1.159 3.766-.87.227 3.263-3.795.876.85 12.942-4.119.95-.849-12.941-2.471.57-.193-2.713zM66.216 54.447c-.067-1.502-.936-1.643-2.577-1.233-2.787.674-2.638 2.53-2.573 4.56 0 0 5.317-.329 5.15-3.327zm-3.05-4.625c3.346-.804 6.563-.556 7.158 3.088 1.172 7.227-7.766 7.835-8.957 7.645.139 2.478.87 2.96 2.965 2.507 1.75-.342 4.513-1.258 6.269-1.974l.178 3.056c-2.19 1.25-4.638 2.188-7.952 2.735-3.775.687-5.709-2.183-5.968-8.134-.194-5.006.578-7.57 6.306-8.923zM72.295 48.025l3.47-.801.78 1.895c1.019-1.35 2.393-2.782 4.334-3.23.677-.157 1.325-.306 1.748-.218l.299 4.114c-.419.065-1.374.223-2.257.427-1.706.394-2.931.987-3.864 2.72l.743 10.333-4.118.95-1.135-16.19zM85.659 58.015l7.355-1.698c.264-.061 1.023-.268.976-1.279l-.038-.828c-.06-.667-.289-.986-1.142-.79l-3.5.809c-2.766.638-4.678-.532-4.9-3.641l-.09-1.466c-.188-2.56.81-4.278 3.84-4.977 3-.693 7.109-1.549 8.36-1.094l.186 2.684-7.06 1.63c-1.06.244-1.172.83-1.13 1.407l.058.793c.059 1.195.731.884 1.29.755l3.589-.829c3-.692 4.482 1.166 4.623 3.52l.15 1.855c.24 3.321-1.836 4.265-3.63 4.68-2.97.685-7.617 1.635-8.758 1.122l-.18-2.653z"
        fill="#2F8DEE"
      />
      <defs>
        <filter
          id="vec-thumbnail-doc_svg__filter0_d"
          x={4.57}
          y={8.722}
          width={137.829}
          height={158.666}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0.184314 0 0 0 0 0.552941 0 0 0 0 0.933333 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgVecThumbnailDoc
