import type { Moment, TransactionType } from '~/types'

export enum CsvFormatVersion {
  V3 = 'V3',
  V4 = 'V4',
}

export enum TransactionsTabKey {
  XFERS_ACCOUNT = 'xfers_account',
  XFERS_WALLET = 'xfers_wallet',
  MANAGED_ACCOUNT = 'managed_account',
  CREDIT_LINE = 'credit_line',
  TERM_LOAN = 'term_loan',
}

export type CardInfo = {
  isRecurring: boolean
  cardNumber: string
  expiryDate: string
  cardType: string
  type?: string
}

export type Transactions = {
  key: string
  transaction_id: string
  unique_id: string
  created_at: string
  description: {
    type: string
    direction: string
    data: string | CardInfo
    internal_transaction_type?: string
    payment_id?: string
  }
  method: string
  amount: { prefix: string; postfix: string }
  fee?: string
  net_amount?: { prefix: string; postfix: string }
  transaction_status: string
  tax?: string
  account_holder_name?: string
  transaction_type?: TransactionType
  transaction_hash?: string | null
  transaction_hash_url?: string | null
  manual_assignments?: Array<{
    created_at: string
    amount: { prefix: string; postfix: string }
    status: string
    external_id: string
  }>
}
export type ModifiedTransactionsAttributes = {
  orderType: string
  createdAt: string
  status: string
  manualAassignments: string
  details: {
    transactionId: string
    description: {
      data: string
      direction: string
      type: string
      internalTransactionType: string
      paymentId: string
    }
    amount: {
      prefix: string
      postfix: string
    }
    method: string
  }
}

export type ModifiedTransactionsAttributesNew = {
  orderType: string
  createdAt: string
  status: string
  manualAassignments: string
  details: {
    externalId: string
    type: string
    accountNo: string
    transactionId: string
    description: {
      data: string
      direction: string
      type: string
      internalTransactionType: string
      paymentId: string
    }
    amount: string
    method: string
  }
}

export type UnionModifiedTransactionsAttributes =
  | ModifiedTransactionsAttributes
  | ModifiedTransactionsAttributesNew

export type ModifiedTransactions = {
  id: string
  type: string
  attributes: ModifiedTransactionsAttributes | ModifiedTransactionsAttributesNew
}

export type FilterOptions = {
  transactionTypes: Array<{ label: string; value: string }>
  transactionStatuses: Array<{ label: string; value: string }>
  sortTypes: Array<{ label: string; value: string }>
  accountTypes: Array<{ label: string; value: string }> | undefined
}

export type FilterValues = {
  startDate: Moment | string
  endDate: Moment | string
  transactionStatuses: string[]
  transactionTypes: string[]
  sortType: string
  accountIds: number[]
  currentPage: number
  pageLimit: number
  totalCount: number
  searchString: string
  contractsExist: boolean
  transactions: Transactions[] | undefined
  isTxnLoading: boolean
  isPaginationLoading: boolean
  [key: string]: FilterValuesObject[keyof FilterValuesObject]
}

export type FilterValuesObject = {
  [filterKey: string]:
    | Moment
    | string[]
    | number[]
    | string
    | number
    | Transactions[]
    | boolean
    | undefined
}

export enum TransactionsActions {
  SET_IS_TXN_LOADING = 'TransactionsActions.SET_IS_TXN_LOADING',
  SET_IS_FILTER_LOADING = 'TransactionsActions.SET_IS_FILTER_LOADING',
  SET_IS_PAGINATION_LOADING = 'TransactionsActions.SET_IS_PAGINATION_LOADING',
  SET_CSV_DOWNLOAD_COMPLETE = 'TransactionsActions.SET_CSV_DOWNLOAD_COMPLETE',
  GET_TRANSACTIONS = 'TransactionsActions.GET_TRANSACTIONS',
  GET_TRANSACTIONS_TOTAL_COUNT = 'TransactionsActions.GET_TRANSACTIONS_TOTAL_COUNT',
  GET_TRANSACTION_FILTER_OPTIONS = 'TransactionsActions.GET_TRANSACTION_FILTER_OPTIONS',
  SET_FILTERS = 'TransactionsActions.SET_FILTERS',
  RESET_CURRENT_PAGE = 'TransactionsActions.RESET_CURRENT_PAGE',
  SET_CsvFormatVersion = 'TransactionsActions.SET_CsvFormatVersion',
  SWITCH_TRANSACTIONS_TAB = 'TransactionsActions.SWITCH_TRANSACTIONS_TAB',
  GET_TRANSACTION_TAB_OPTIONS = 'TransactionsActions.GET_TRANSACTION_TAB_OPTIONS',
}

type SetIsTxnLoading = {
  type: typeof TransactionsActions.SET_IS_TXN_LOADING
  isTxnLoading: boolean
}

type SetIsFilterLoading = {
  type: typeof TransactionsActions.SET_IS_FILTER_LOADING
  isFilterLoading: boolean
}

type SetIsPaginationLoading = {
  type: typeof TransactionsActions.SET_IS_PAGINATION_LOADING
  isPaginationLoading: boolean
}

type GetTransactions = {
  type: typeof TransactionsActions.GET_TRANSACTIONS
  transactions: Transactions[]
  contractsExist: boolean
}

type GetTransactionsTotalCount = {
  type: typeof TransactionsActions.GET_TRANSACTIONS_TOTAL_COUNT
  totalCount: number
}

type GetTransactionFilterOptions = {
  type: typeof TransactionsActions.GET_TRANSACTION_FILTER_OPTIONS
  viewType: string
  filterOptions: {
    [TransactionsTabKey.XFERS_ACCOUNT]: FilterOptions
    [TransactionsTabKey.XFERS_WALLET]: FilterOptions
    [TransactionsTabKey.MANAGED_ACCOUNT]: FilterOptions
    [TransactionsTabKey.CREDIT_LINE]: FilterOptions
  }
}

type SetFilters = {
  type: typeof TransactionsActions.SET_FILTERS
  filterValuesObject: FilterValuesObject
}

type ResetCurrentPage = {
  type: typeof TransactionsActions.RESET_CURRENT_PAGE
}

type SetCsvFormatVersion = {
  type: typeof TransactionsActions.SET_CsvFormatVersion
  csvFormatVersion: CsvFormatVersion
}

type SwitchTransactionsTab = {
  type: typeof TransactionsActions.SWITCH_TRANSACTIONS_TAB
  transactionsTabKey: TransactionsTabKey
}

type GetTransactionTabOptions = {
  type: typeof TransactionsActions.GET_TRANSACTION_TAB_OPTIONS
  transactionTabOptions: TransactionsTabKey[]
}

export type ActionTypes =
  | SetIsTxnLoading
  | SetIsFilterLoading
  | SetIsPaginationLoading
  | GetTransactions
  | GetTransactionsTotalCount
  | GetTransactionFilterOptions
  | SetFilters
  | ResetCurrentPage
  | SetCsvFormatVersion
  | SwitchTransactionsTab
  | GetTransactionTabOptions
