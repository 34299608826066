import { SVGProps } from 'react'

function ExportFile(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.17 6.39009L11.43 0.650092C11.2428 0.461724 11.0199 0.312537 10.7744 0.211238C10.5288 0.10994 10.2656 0.0585578 10 0.060092H6.25C4.6587 0.060092 3.13258 0.692233 2.00736 1.81745C0.882141 2.94267 0.25 4.46879 0.25 6.06009V15.9401C0.25 17.5314 0.882141 19.0575 2.00736 20.1827C3.13258 21.3079 4.6587 21.9401 6.25 21.9401H11.75C13.3413 21.9401 14.8674 21.3079 15.9926 20.1827C17.1179 19.0575 17.75 17.5314 17.75 15.9401V7.80009C17.7504 7.27195 17.5419 6.76507 17.17 6.39009ZM15.52 6.87009H14.67C13.7417 6.87009 12.8515 6.50134 12.1951 5.84497C11.5387 5.18859 11.17 4.29835 11.17 3.37009V2.52009L15.52 6.87009ZM8 16.4801L5.34 13.7901C5.26847 13.7219 5.21195 13.6396 5.17407 13.5483C5.13619 13.457 5.11777 13.3589 5.12 13.2601C5.11953 13.1616 5.13875 13.0639 5.17653 12.9729C5.21431 12.8819 5.26989 12.7993 5.34 12.7301C5.48063 12.5896 5.67125 12.5108 5.87 12.5108C6.06875 12.5108 6.25937 12.5896 6.4 12.7301L8.24 14.5701V9.00009C8.24 8.80118 8.31902 8.61041 8.45967 8.46976C8.60032 8.32911 8.79109 8.25009 8.99 8.25009C9.18891 8.25009 9.37968 8.32911 9.52033 8.46976C9.66098 8.61041 9.74 8.80118 9.74 9.00009V14.5801L11.6 12.7101C11.7422 12.5776 11.9302 12.5055 12.1245 12.5089C12.3188 12.5123 12.5042 12.5911 12.6416 12.7285C12.779 12.8659 12.8577 13.0513 12.8612 13.2456C12.8646 13.4399 12.7925 13.6279 12.66 13.7701L10 16.4801C9.86914 16.6117 9.71323 16.7158 9.54148 16.7863C9.36973 16.8567 9.18562 16.892 9 16.8901C8.81415 16.8937 8.62952 16.8592 8.45752 16.7887C8.28553 16.7182 8.12981 16.6131 8 16.4801Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ExportFile
