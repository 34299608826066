import { SVGProps } from 'react'

function SvgDelete(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m13.38 4.618-.63 8.245a2.493 2.493 0 0 1-2.472 2.273H6.736A2.493 2.493 0 0 1 4.25 12.87l-.63-8.252h9.76Zm1.14-1.077H2.48l.708 9.393a3.542 3.542 0 0 0 3.542 3.25h3.542a3.542 3.542 0 0 0 3.541-3.25l.709-9.393Z"
        fill="currentColor"
      />
      <path
        d="M15.618 4.59H1.416a.531.531 0 0 1 0-1.049h14.238a.531.531 0 1 1 0 1.063l-.036-.015ZM8.458 13.104a.531.531 0 0 1-.532-.531V6.906a.531.531 0 1 1 1.063 0v5.702a.524.524 0 0 1-.531.496ZM10.78 13.104a.53.53 0 0 1-.516-.545l.184-5.667a.526.526 0 0 1 .531-.517.538.538 0 0 1 .517.545l-.163 5.674a.532.532 0 0 1-.552.51ZM6.141 13.104a.524.524 0 0 1-.531-.51l-.184-5.71a.531.531 0 1 1 1.062 0l.184 5.703a.532.532 0 0 1-.517.545l-.014-.028Z"
        fill="currentColor"
      />
      <path
        d="M5.716 4.102V2.926A1.062 1.062 0 0 1 6.78 1.863h3.442a1.062 1.062 0 0 1 1.063 1.063v1.176h1.062V2.926A2.125 2.125 0 0 0 10.221.8H6.78a2.125 2.125 0 0 0-2.125 2.125v1.176h1.062Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgDelete
