import { SVGProps } from 'react'

function PdfFile(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.17 6.39009L11.43 0.650092C11.2428 0.461724 11.0199 0.312537 10.7744 0.211238C10.5288 0.10994 10.2656 0.0585578 10 0.060092H6.25C4.6587 0.060092 3.13258 0.692233 2.00736 1.81745C0.882141 2.94267 0.25 4.46879 0.25 6.06009V15.9401C0.25 17.5314 0.882141 19.0575 2.00736 20.1827C3.13258 21.3079 4.6587 21.9401 6.25 21.9401H11.75C13.3413 21.9401 14.8674 21.3079 15.9926 20.1827C17.1179 19.0575 17.75 17.5314 17.75 15.9401V7.80009C17.7504 7.27195 17.5419 6.76507 17.17 6.39009Z"
        fill="#121212"
      />
      <path
        d="M14.67 6.87006H15.52L11.17 2.52006V3.37006C11.17 4.29832 11.5387 5.18856 12.1951 5.84493C12.8515 6.50131 13.7417 6.87006 14.67 6.87006Z"
        fill="white"
      />
      <path
        d="M6.25 20.4401H11.75C12.9435 20.4401 14.0881 19.966 14.932 19.122C15.7759 18.2781 16.25 17.1335 16.25 15.9401V8.37006H14.67C13.3439 8.37006 12.0721 7.84327 11.1345 6.90559C10.1968 5.96791 9.67 4.69614 9.67 3.37006V1.56006H6.25C5.05653 1.56006 3.91193 2.03416 3.06802 2.87808C2.22411 3.72199 1.75 4.86658 1.75 6.06006V15.9401C1.75 17.1335 2.22411 18.2781 3.06802 19.122C3.91193 19.966 5.05653 20.4401 6.25 20.4401Z"
        fill="white"
      />
      <path
        d="M3.5 17.4336L4.67969 17.3086V15.8438C4.85547 15.8828 5.05469 15.9062 5.23828 15.9062C6.53125 15.9062 7.26562 15.1836 7.26562 13.9062V13.5234C7.26562 12.2852 6.49609 11.5273 5.23828 11.5273C4.64062 11.5273 4.01953 11.7031 3.5 12.0195V17.4336ZM5.23828 14.9609C5.04688 14.9609 4.82422 14.9219 4.67969 14.8516V12.582C4.82031 12.5156 5.03516 12.4727 5.23828 12.4727C5.76562 12.4727 6.05078 12.75 6.05078 13.2695V14.168C6.05078 14.6836 5.76562 14.9609 5.23828 14.9609Z"
        fill="#D93025"
      />
      <path
        d="M9.92 15.9062C10.4903 15.9062 11.2247 15.7695 11.6583 15.5859V10.0234L10.4786 10.1445V11.5938C10.3028 11.5508 10.0997 11.5273 9.92 11.5273C8.66219 11.5273 7.89266 12.2891 7.89266 13.5234V13.9062C7.89266 15.1836 8.62703 15.9062 9.92 15.9062ZM9.92 14.9609C9.39656 14.9609 9.11141 14.6836 9.11141 14.168V13.2695C9.11141 12.75 9.39656 12.4727 9.92 12.4727C10.127 12.4727 10.338 12.5156 10.4786 12.582V14.8516C10.338 14.9219 10.1153 14.9609 9.92 14.9609Z"
        fill="#D93025"
      />
      <path
        d="M12.3947 15.832H13.5744V12.4922H14.8478V11.6016H13.5744V11.4609C13.5744 11.0508 13.8556 10.8281 14.3517 10.8281C14.5431 10.8281 14.7931 10.8555 15.0119 10.9102L15.1486 10.1797C14.8166 10.0547 14.5002 10 14.1838 10C13.1134 10 12.3947 10.6172 12.3947 11.5742V15.832Z"
        fill="#D93025"
      />
    </svg>
  )
}

export default PdfFile
