import styled from '@emotion/styled'
import Card from '../../deprecated/Card'
import { Title, Body } from '../../deprecated/Typography'

type SummaryCardProps = {
  label: React.ReactNode
  amount: React.ReactNode
  type?: 'default' | 'dark'
  height?: number
  width?: number | string
  isInverted?: boolean
  icon?: React.ReactElement
}

function SummaryCard({
  label,
  amount,
  type = 'default',
  height = 91,
  width = 230,
  isInverted = false,
  icon,
}: SummaryCardProps) {
  const StyledSummaryCard = styled(Card)`
    width: ${typeof width === 'number' ? `${width}px` : width};
    min-height: ${height}px;
    padding: 3px !important;
    border-radius: 5px !important;

    ${icon &&
    `
      & > .ant-card-body {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
      }
    `}

    ${isInverted &&
    `
      & > .ant-card-body > div {
        display: flex !important;
        flex-direction: column-reverse !important;
      }
    `}
  `

  return (
    <StyledSummaryCard type={type}>
      {icon && icon}
      <div>
        <Body style={{ marginBottom: isInverted ? '0' : '5px' }}>{label}</Body>
        <Title style={{ marginBottom: isInverted ? '5px' : '0' }}>{amount}</Title>
      </div>
    </StyledSummaryCard>
  )
}

export default SummaryCard
