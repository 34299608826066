import * as React from 'react'

function SvgOnchainCustodian(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#onchain-custodian_svg__clip0)">
        <path
          d="M25.535 29.892H10.8V33.6h14.734v-3.71zM10.8 15.714h4.543v5.2c0 .693.485 2.496 3.015 2.496 2.462 0 2.531-2.22 2.531-2.496v-5.235h4.614v4.645c0 2.288-.728 6.621-7.661 6.621s-7.003-5.13-7.003-6.551c-.003-.759-.038-4.68-.038-4.68z"
          fill="#6289AA"
        />
        <path
          d="M10.824 2.83v10.849h4.546V9.094c0-1.337 1.872-3.629 4.277-2.827 1.108.458 1.338 1.3 1.338 2.408v4.966h4.468V7.834c0-2.138-1.451-4.928-4.736-5.386-2.212-.268-4.125.611-5.346 2.14V2.81l-4.547.02z"
          fill="#07F"
        />
      </g>
      <defs>
        <clipPath id="onchain-custodian_svg__clip0">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgOnchainCustodian
