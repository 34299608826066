import * as React from 'react'

function SvgAvatarFilled(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        d="M21 8A6 6 0 119 8a6 6 0 0112 0zM28 26c0 1.105-.89 2-1.995 2H3.992A1.993 1.993 0 012 26v-1.337c0-.437.092-.866.311-1.243C3.376 21.58 7.245 16 15 16s11.624 5.58 12.689 7.42c.218.377.311.806.311 1.242V26z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgAvatarFilled
