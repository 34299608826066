import { SVGProps } from 'react'

function IcImageSuccess(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3833 2.97485C14.5988 2.97485 15.7646 3.45774 16.6242 4.31728C17.4837 5.17682 17.9666 6.34261 17.9666 7.55819V12.4415C17.9666 13.0434 17.848 13.6394 17.6177 14.1955C17.3874 14.7516 17.0498 15.2568 16.6242 15.6824C16.1986 16.108 15.6933 16.4456 15.1372 16.676C14.5812 16.9063 13.9852 17.0249 13.3833 17.0249H6.6166C5.40102 17.0249 4.23523 16.542 3.37569 15.6824C2.51615 14.8229 2.03326 13.6571 2.03326 12.4415V7.55819C2.03326 6.34261 2.51615 5.17682 3.37569 4.31728C4.23523 3.45774 5.40102 2.97485 6.6166 2.97485H13.3833ZM13.3833 1.72485H6.6166C5.0695 1.72485 3.58577 2.33944 2.49181 3.4334C1.39785 4.52736 0.783264 6.01109 0.783264 7.55819V12.4415C0.783264 13.9886 1.39785 15.4723 2.49181 16.5663C3.58577 17.6603 5.0695 18.2749 6.6166 18.2749H13.3833C14.9304 18.2749 16.4141 17.6603 17.5081 16.5663C18.602 15.4723 19.2166 13.9886 19.2166 12.4415V7.55819C19.2166 6.01109 18.602 4.52736 17.5081 3.4334C16.4141 2.33944 14.9304 1.72485 13.3833 1.72485Z"
        fill="#0C45E1"
      />
      <path
        d="M1.73307 14.858C1.63903 14.8575 1.54624 14.8363 1.46127 14.796C1.37631 14.7557 1.30125 14.6972 1.2414 14.6247C1.13778 14.4948 1.08933 14.3295 1.10647 14.1642C1.12362 13.999 1.205 13.8471 1.33307 13.7413L4.29974 11.3497C4.65814 11.0564 5.11312 10.9075 5.57556 10.9322C6.038 10.9568 6.47457 11.1533 6.79974 11.483L8.6414 13.333C8.68583 13.3702 8.74179 13.3908 8.79974 13.3913C8.82795 13.3909 8.85579 13.3848 8.88158 13.3734C8.90736 13.3619 8.93055 13.3453 8.94974 13.3247L12.9247 8.93298C13.1629 8.67005 13.452 8.45829 13.7745 8.31057C14.0971 8.16285 14.4463 8.08225 14.8009 8.07368C15.1555 8.06511 15.5082 8.12874 15.8375 8.2607C16.1668 8.39266 16.4658 8.5902 16.7164 8.84132L18.8664 10.9913C18.9768 11.1098 19.0369 11.2665 19.034 11.4284C19.0312 11.5903 18.9656 11.7448 18.8511 11.8593C18.7366 11.9738 18.5821 12.0394 18.4202 12.0423C18.2583 12.0452 18.1016 11.9851 17.9831 11.8747L15.8331 9.74965C15.7022 9.61872 15.546 9.51575 15.3741 9.44699C15.2022 9.37823 15.0182 9.34511 14.8331 9.34965C14.6468 9.35182 14.4631 9.39298 14.2937 9.47048C14.1244 9.54799 13.9731 9.66012 13.8497 9.79965L9.87474 14.1663C9.74126 14.3123 9.57963 14.4297 9.39959 14.5116C9.21954 14.5935 9.0248 14.6381 8.82707 14.6428C8.62934 14.6474 8.4327 14.6121 8.24899 14.5388C8.06528 14.4655 7.89829 14.3558 7.75807 14.2163L5.88307 12.3663C5.77672 12.2526 5.63033 12.1847 5.47483 12.1769C5.31933 12.1691 5.1669 12.2221 5.04974 12.3247L2.12474 14.7163C2.01325 14.8052 1.87563 14.855 1.73307 14.858Z"
        fill="#0C45E1"
      />
      <path
        d="M7.99171 9.13328C8.91679 9.13328 9.66671 8.38336 9.66671 7.45828C9.66671 6.53321 8.91679 5.78328 7.99171 5.78328C7.06663 5.78328 6.31671 6.53321 6.31671 7.45828C6.31671 8.38336 7.06663 9.13328 7.99171 9.13328Z"
        fill="#0C45E1"
      />
    </svg>
  )
}

export default IcImageSuccess
