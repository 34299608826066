import * as React from 'react'
import { SVGProps } from 'react'

function SvgHedera(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 0c9.941 0 18 8.059 18 18s-8.059 18-18 18S0 27.941 0 18 8.059 0 18 0Z"
        fill="#000"
      />
      <path
        d="M10.683 25.785h2.286v-4.86H23.03v4.86h2.286V10.026h-2.286v4.743H12.969v-4.743h-2.286v15.76Zm12.24-6.678H12.861v-2.51h10.062v2.51Z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgHedera
