import { tokensFds3 } from '../style-dictionary/style-dictionary-dist/_tokens-fds3-dist'

const {
  radius,
  spacing,
  fazzbizPrimitives,
  colors,
  rootPrimitives,
  shadow,
  fontWeight,
  fontFamily,
  fontSize,
  lineHeight,
  letterSpacing,
} = tokensFds3

const rootTheme = {
  spacing: {
    xxs: spacing.xxs,
    xs: spacing.xs,
    sm: spacing.sm,
    md: spacing.md,
    lg: spacing.lg,
    xl: spacing.xl,
    xxl: spacing.xxl,
  },
  radius: {
    xs: radius.xs,
    sm: radius.sm,
    md: radius.md,
    lg: radius.lg,
    xl: radius.xl,
  },
  shadow: {
    raised: shadow.raised,
    action: shadow.action,
    shadowOverlay: shadow.overlay,
    reverseRaised: shadow.reverseRaised,
  },
  rootPrimitives: {
    ...rootPrimitives,
  },
  colors: {
    /* ----------------------BACKGROUND COLORS----------------------*/

    /** @description This color don’t represent brand, Used only for Artboard or Main Background of the device (app) */
    backgroundNeutral: colors.white,

    /** @description This color don’t represent brand, Used only for Artboard or Main Background of the device (app) */
    backgroundSecondaryNeutral: colors.gray100,

    /* ----------------------SURFACE COLORS----------------------*/

    /** @description Surfaces live on top of background-neutral. Used for app bar, tab, pages, modals, tables, headers, and cards and etc */
    surfaceNeutral: colors.white,

    /** @description Surfaces live on top of background-neutral. Used for app bar, tab, pages, modals, tables, headers, and cards and etc */
    surfaceSecondaryNeutral: colors.gray100,

    /** @description General case when the surface is disabled. Used on surface (container color) of the components such as input, list, card and etc. */
    surfaceDisabledSurface: colors.gray200,

    /** @description General case of text and icon on disabled surface */
    surfaceDisabledOnSurface: colors.gray800,

    /**
     * @description Display a placeholder preview of your content before the data gets loaded to reduce load-time frustration.
     * Used for card or elements while loading */
    surfaceGradientSkeleton: colors.gradientSkeleton,

    /** @description Currently, we only used subdue on banners and badge to emphasize or give different look for component container color */
    surfaceSubdueInformation: colors.blue300,

    /** @description Currently, we only used subdue on banners and badge to emphasize or give different look for component container color */
    surfaceSubduePositive: colors.green300,

    /** @description Currently, we only used subdue on banners and badge to emphasize or give different look for component container color */
    surfaceSubdueCritical: colors.red300,

    /** @description Currently, we only used subdue on banners and badge to emphasize or give different look for component container color */
    surfaceSubdueWarning: colors.orange300,

    /* ----------------------BASE COLORS----------------------*/

    /** @description Primary roles are used for key components across the UI, such as the FAB, prominent buttons, link color and etc. We differentiate selected states to this token interactive color / active */
    primary: colors.blue500,

    /** @description Colors is applied to the top of elements that have primary or colored container color  */
    onContainer: colors.white,

    /** @description Usually used for primary text, icons or other content */
    onNeutral: colors.black,

    /** @description Usually used for secondary text, icons or other content */
    onNeutralSecondary: colors.gray900,

    /** @description Used for border, divider/separator inside or outside components */
    line: colors.gray500,

    /** @description Used for background overlay when triggered Dialog or Bottom Sheet */
    colorOverlay: colors.overlay,

    /* ----------------------INTERACTIVE COLORS----------------------*/

    /**
     * @description Used for component Active selection for checkbox, radiobutton, tab, toggle, tag, bottom nav.
     *
     * We differentiate Active and Primary color to make color more scalable when facing different needs between active selection and brand color */
    active: colors.blue500,

    /** @description Used for component Inactive selection for checkbox, radiobutton, tab, toggle, tag, bottom nav */
    inactive: colors.gray600,

    /** @description Focused states will add 2px border to the elements This can be use as general case of focused states, except input components */
    focusedDefault: rootPrimitives.vividCerulean,

    /** @description  Currently, this color only affected on Menu or List components */
    selected: colors.blueSecondary500,

    /** @description Hovered states for component checkboxes, radiobutton, Toggle, Input (including date input), card/menu */
    hoveredSurface: colors.gray300,

    /** @description Pressed states for component checkboxes, radiobutton, toggle, input, card/menu */
    pressedSurface: colors.gray400,

    /** @description Focused states for component checkboxes, radiobutton, toggle */
    focusedSurface: colors.gray400,

    /** @description Used for component hover states that have primary color such as, button, tag, or etc. */
    hoveredPrimary: colors.blue600,

    /** @description Pressed states for buttons and other elements that have primary color */
    pressedPrimrary: colors.blue700,

    /** @description Hovered states for buttons or other elements that have critical color */

    hoveredCritical: colors.red600,

    /** @description Pressed states for buttons or other elements that have critical color */
    pressedCritical: colors.red700,

    /* ----------------------STATUS COLORS----------------------*/

    critical: colors.red500,

    /** @description Information colors are for neutral info status elements, */
    information: colors.blue500,

    /** @description Positive colors indicate something success, available, like the success of a merchant action or to illustrate growth. */
    positive: colors.green500,

    /** @description Warning colors let the merchant know they need to take action, and are applied to badges, banners, and exception lists. */
    warning: colors.orange500,

    /* ----------------------COMPONENT COLORS----------------------*/

    /** @description Used for disabled container color for the majority of button components */
    disabledButton: colors.gray600,

    /** @description Used for container color of default secondary button */
    secondaryButton: colors.blueSecondary500,

    /** @description Hovered states for default secondary buttons */
    hoveredSecondary: colors.blueSecondary600,

    /** @description Pressed states for default secondary buttons */
    pressedSecondary: colors.blueSecondary700,

    /** @description Used for container color of default secondary destructive button */
    secondaryDestructiveButton: colors.redSecondary500,

    /** @description Hovered states for destructive secondary buttons */
    hoveredDestructive: colors.redSecondary600,

    /** @description Pressed states for destructive secondary buttons */
    pressedDestructive: colors.redSecondary700,

    /** @description  Label of input elements */
    labelInput: colors.gray900,

    /** @description Helper of input elements */
    helperInput: colors.gray900,

    /** @description Placeholder of input elements */
    placeholderInput: colors.gray700,
  },
  fontStyles: {
    /**
     * @description styling of Display-large. You should always prioritize using <Typography.Display size="large"> before using this
     */
    displayLarge: `
      font-family: ${fontFamily.fazzNeue};
      font-weight: ${fontWeight.bold};
      font-size: ${fontSize.displayLarge};
      line-height: ${lineHeight.displayLarge};
      letter-spacing: ${letterSpacing.display};
    `,
    /**
     * @description styling of Display-medium. You should always prioritize using <Typography.Display size="medium"> before using this
     */
    displayMedium: `
      font-family: ${fontFamily.fazzNeue};
      font-weight: ${fontWeight.bold};
      font-size: ${fontSize.displayMedium};
      line-height: ${lineHeight.displayMedium};
      letter-spacing: ${letterSpacing.display};
    `,
    /**
     * @description styling of Display-small. You should always prioritize using <Typography.Display size="small"> before using this
     */
    displaySmall: `
      font-family: ${fontFamily.fazzNeue};
      font-weight: ${fontWeight.bold};
      font-size: ${fontSize.displaySmall};
      line-height: ${lineHeight.displaySmall};
      letter-spacing: ${letterSpacing.display};
    `,
    /**
     * @description styling of Headline-large. You should always prioritize using <Typography.Headline size="large"> before using this
     */
    headlineLarge: `
      font-family: ${fontFamily.fazzNeue};
      font-weight: ${fontWeight.regular};
      font-size: ${fontSize.headlineLarge};
      line-height: ${lineHeight.headlineLarge};
      letter-spacing: ${letterSpacing.headline};
    `,
    /**
     * @description styling of Headline-medium. You should always prioritize using <Typography.Headline size="medium"> before using this
     */
    headlineMedium: `
      font-family: ${fontFamily.fazzNeue};
      font-weight: ${fontWeight.regular};
      font-size: ${fontSize.headlineMedium};
      line-height: ${lineHeight.headlineMedium};
      letter-spacing: ${letterSpacing.headline};
    `,
    /**
     * @description styling of Headline-small. You should always prioritize using <Typography.Headline size="small"> before using this
     */
    headlineSmall: `
      font-family: ${fontFamily.fazzNeue};
      font-weight: ${fontWeight.regular};
      font-size: ${fontSize.headlineSmall};
      line-height: ${lineHeight.headlineSmall};
      letter-spacing: ${letterSpacing.headline};
    `,

    /**
     * @description styling of Title-large. You should always prioritize using <Typography.Title size="large"> before using this
     */
    titleLarge: `
      font-family: ${fontFamily.fazzNeue};
      font-weight: ${fontWeight.bold};
      font-size: ${fontSize.titleLarge};
      line-height: ${lineHeight.titleLarge};
      letter-spacing: ${letterSpacing.title};
    `,
    /**
     * @description styling of Title-medium. You should always prioritize using <Typography.Title size="medium"> before using this
     */
    titleMedium: `
      font-family: ${fontFamily.fazzNeue};
      font-weight: ${fontWeight.bold};
      font-size: ${fontSize.titleMedium};
      line-height: ${lineHeight.titleMedium};
      letter-spacing: ${letterSpacing.title};
    `,
    /**
     * @description styling of Title-small. You should always prioritize using <Typography.Title size="small"> before using this
     */
    titleSmall: `
      font-family: ${fontFamily.fazzNeue};
      font-weight: ${fontWeight.bold};
      font-size: ${fontSize.titleSmall};
      line-height: ${lineHeight.titleSmall};
      letter-spacing: ${letterSpacing.title};
    `,

    /**
     * @description styling of Label-large. You should always prioritize using <Typography.Label size="large"> before using this
     */
    labelLarge: `
      font-family: ${fontFamily.fazzNeue};
      font-weight: ${fontWeight.bold};
      font-size: ${fontSize.labelLarge};
      line-height: ${lineHeight.labelLarge};
      letter-spacing: ${letterSpacing.label};
    `,
    /**
     * @description styling of Label-medium. You should always prioritize using <Typography.Label size="medium"> before using this
     */
    labelMedium: `
      font-family: ${fontFamily.fazzNeue};
      font-weight: ${fontWeight.bold};
      font-size: ${fontSize.labelMedium};
      line-height: ${lineHeight.labelMedium};
      letter-spacing: ${letterSpacing.label};
    `,
    /**
     * @description styling of Label-small. You should always prioritize using <Typography.Label size="small"> before using this
     */
    labelSmall: `
      font-family: ${fontFamily.fazzNeue};
      font-weight: ${fontWeight.bold};
      font-size: ${fontSize.labelSmall};
      line-height: ${lineHeight.labelSmall};
      letter-spacing: ${letterSpacing.label};
    `,

    /**
     * @description styling of Body-large. You should always prioritize using <Typography.Body size="large"> before using this
     */
    bodyLarge: `
      font-family: ${fontFamily.fazzNeue};
      font-weight: ${fontWeight.regular};
      font-size: ${fontSize.bodyLarge};
      line-height: ${lineHeight.bodyLarge};
      letter-spacing: ${letterSpacing.body};
    `,
    /**
     * @description styling of Body-medium. You should always prioritize using <Typography.Body size="medium"> before using this
     */
    bodyMedium: `
      font-family: ${fontFamily.fazzNeue};
      font-weight: ${fontWeight.regular};
      font-size: ${fontSize.bodyMedium};
      line-height: ${lineHeight.bodyMedium};
      letter-spacing: ${letterSpacing.body};
    `,
    /**
     * @description styling of Body-small. You should always prioritize using <Typography.Body size="small"> before using this
     */
    bodySmall: `
      font-family: ${fontFamily.fazzNeue};
      font-weight: ${fontWeight.regular};
      font-size: ${fontSize.bodySmall};
      line-height: ${lineHeight.bodySmall};
      letter-spacing: ${letterSpacing.body};
    `,
  },
  fontFamily: {
    fazzNeue: fontFamily.fazzNeue,
  },
  fontWeight: {
    regular: fontWeight.regular,
    bold: fontWeight.bold,
  },
  fontSize: {
    displayLarge: fontSize.displayLarge,
    displayMedium: fontSize.displayMedium,
    displaySmall: fontSize.displaySmall,
    headlineLarge: fontSize.headlineLarge,
    headlineMedium: fontSize.headlineMedium,
    headlineSmall: fontSize.headlineSmall,
    titleLarge: fontSize.titleLarge,
    titleMedium: fontSize.titleMedium,
    titleSmall: fontSize.titleSmall,
    labelLarge: fontSize.labelLarge,
    labelMedium: fontSize.labelMedium,
    labelSmall: fontSize.labelSmall,
    bodyLarge: fontSize.bodyLarge,
    bodyMedium: fontSize.bodyMedium,
    bodySmall: fontSize.bodySmall,
  },
  lineHeight: {
    displayLarge: lineHeight.displayLarge,
    displayMedium: lineHeight.displayMedium,
    displaySmall: lineHeight.displaySmall,
    headlineLarge: lineHeight.headlineLarge,
    headlineMedium: lineHeight.headlineMedium,
    headlineSmall: lineHeight.headlineSmall,
    titleLarge: lineHeight.titleLarge,
    titleMedium: lineHeight.titleMedium,
    titleSmall: lineHeight.titleSmall,
    labelLarge: lineHeight.labelLarge,
    labelMedium: lineHeight.labelMedium,
    labelSmall: lineHeight.labelSmall,
    bodyLarge: lineHeight.bodyLarge,
    bodyMedium: lineHeight.bodyMedium,
    bodySmall: lineHeight.bodySmall,
  },
  letterSpacing: {
    display: letterSpacing.display,
    headline: letterSpacing.headline,
    title: letterSpacing.title,
    label: letterSpacing.label,
    body: letterSpacing.body,
  },
}

/**
 *
 * @property {object} spacing - Units used for spacing eg. margins, padding, etc
 * @property {object} radius - Units used for border radius
 * @property {object} colors - Semantic tokens for colors
 * @property {object} shadow - Used to bring UI elements forward, provide hierarchy
 * @property {object} primitives - Primitive Fazz Biz colors */

export const fdsTheme = {
  ...rootTheme,
  fazzBizPrimitives: {
    purple: fazzbizPrimitives.purple,
    yellow: fazzbizPrimitives.yellow,
    subduePurple: fazzbizPrimitives.subduePurple,
    subdueYellow: fazzbizPrimitives.subdueYellow,
    subdueCerulean: fazzbizPrimitives.subdueCerulean,
    subdueTurquoise: fazzbizPrimitives.subdueTurquoise,
  },
}

/**
 * @deprecated - Use the new naming convention: fdsTheme.
 */
export const fazzBizzThemeFds3 = fdsTheme
