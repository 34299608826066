import { VerificationStatusType } from '~/api/idVerification/idVerification.types'

export const VerificationStatus: Record<string, VerificationStatusType> = {
  Initial: 'initial',
  Pending: 'pending',
  Submitted: 'submitted',
  Verifying: 'verifying',
  Verified: 'verified',
  Rejected: 'rejected',
}
