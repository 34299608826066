import { css } from 'emotion'
import type { ReactElement, ReactNode, WheelEvent } from 'react'
import { MaskedInput } from 'antd-mask-input'
import type { MaskedInputProps } from 'antd-mask-input/build/main/lib/MaskedInput'
import type { InputProps } from 'antd/es/input'
import Input from 'antd/es/input'
import 'antd/es/input/style/css'
import styled from '@emotion/styled'
import { Search } from '@fazz/design-system-icons'
import { Body } from '../../../themes/Typography'
// deprecated constants
import { NEUTRAL_COLORS, BLUE_COLORS } from '../../../themes/deprecated/ColorStyles'
import { MAIN_FONT } from '../../../themes/deprecated/TypographyStyles'
import { fazzbizTheme } from '../../../themes/deprecated/theme'
import { useTheme } from '../../../themes/deprecated/themeContext'
import { fdsTheme } from '../../../themes/theme-fds3'

const { colors, spacing, radius, fontStyles } = fdsTheme

const style = `
  -webkit-font-smoothing: antialiased;

  & .ant-input, &.ant-input, &.ant-input-password {
    ${fontStyles.bodyLarge}
    padding: 0;
    padding-left: 15px;
    padding-top: 12px;
    padding-bottom: 13px;

    &::placeholder {
      color: ${fazzbizTheme.input.placeholder};
      font-weight: 400;
    }
  }

  &.ant-input, &.ant-input-password {
    border-radius: ${radius.lg};
    border-color: ${NEUTRAL_COLORS.G200};

    &:hover {
      border-color: ${fazzbizTheme.base.primary};
    }

    &:focus {
      border-color: ${fazzbizTheme.base.primary};
      -webkit-box-shadow: 0 0 0 3px ${BLUE_COLORS.B200};
      box-shadow: 0 0 0 3px ${BLUE_COLORS.B200};
      color: ${fazzbizTheme.base.neutral}
    }
  }

  & .ant-input, .ant-input-password {
    height: 56px;
    border-top-right-radius: ${radius.lg};
    border-bottom-right-radius: ${radius.lg};
    border-color: ${NEUTRAL_COLORS.G200};

    &:hover {
      border-color: ${fazzbizTheme.base.primary};
    }

    &:focus {
      border-color: ${fazzbizTheme.base.primary};
      -webkit-box-shadow: 0 0 0 3px ${BLUE_COLORS.B200};
      box-shadow: 0 0 0 3px ${BLUE_COLORS.B200};
      color: ${fazzbizTheme.base.neutral}
    }
  }

  &.ant-input-disabled {
    background-color: ${NEUTRAL_COLORS.G100};
    border-color: ${NEUTRAL_COLORS.G200};

    &:hover {
      border-color: ${NEUTRAL_COLORS.G200};
    }
  }

  & .ant-input-group-wrapper{
    padding: 0;
  }


  & .ant-input-group-addon:not(:last-child) {
    font-family: ${MAIN_FONT};
    color: ${fazzbizTheme.base.neutral};
    background-color: ${colors.backgroundSecondaryNeutral};
    border-top-left-radius: ${radius.lg};
    border-bottom-left-radius: ${radius.lg};
    border-color: ${NEUTRAL_COLORS.G200};
    border-right: 0;
    padding-left: 15px;
    padding-right: 15px;

    + .ant-input-disabled {
      background-color: ${NEUTRAL_COLORS.G100};
      border-color: ${NEUTRAL_COLORS.G200};
      color: ${fazzbizTheme.input.placeholder};

      &:hover {
        border-color: ${NEUTRAL_COLORS.G200};
      }
    }
  }

  & .ant-input-group-addon:last-child {
    border-top-right-radius: ${radius.lg};
    border-bottom-right-radius: ${radius.lg};
    border-color: ${NEUTRAL_COLORS.G200};

  }

  &.ant-input-search {
    ${fontStyles.bodyLarge}
    padding: 0;
    padding-left: 15px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: ${radius.lg};
    border-color: ${colors.line};
    width: 132px;

    &::placeholder {
      color: ${fazzbizTheme.input.placeholder};
      font-weight: 400;
    }

    &:hover {
      border-color: ${fazzbizTheme.base.primary};
    }

    & > .ant-input-suffix {
      width: 18px;
      margin-right: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.ant-input-affix-wrapper-focused {
    border-color: ${fazzbizTheme.base.primary};
    -webkit-box-shadow: 0 0 0 3px ${BLUE_COLORS.B200};
    box-shadow: 0 0 0 3px ${BLUE_COLORS.B200};
  }
`
function Asterisk() {
  const { status } = useTheme()
  return <span style={{ color: status.critical, marginLeft: 2 }}>*</span>
}

function OptionalText() {
  return (
    <Body size="sm" color={colors.onNeutralSecondary} style={{ marginLeft: spacing.xxs }}>
      Optional
    </Body>
  )
}

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`
const FieldSet = styled.fieldset`
  padding: ${fazzbizTheme.spacing.xs} ${fazzbizTheme.spacing.md};
  border: 1px solid ${fazzbizTheme.base.line};
  border-radius: ${radius.lg};
`

const Legend = styled.legend`
  border: 0 !important;
  margin: 0 !important;
  width: auto !important;
  font-size: 12px !important;
`

type Props = {
  fieldSet?: string
  addonBefore?: string | ReactNode
  placeholder?: string
  disabled?: boolean
  className?: string
  label?: string | ReactElement
  required?: boolean
  optional?: boolean
} & Omit<InputProps, 'addonBefore' | 'placeholder' | 'disabled' | 'className'>

const MainInput = function MainInput({
  addonBefore = '',
  fieldSet = '',
  placeholder = '',
  disabled = false,
  className = '',
  label,
  required,
  optional = false,
  ...rest
}: Props) {
  const numberInputOnWheelPreventChange = (e: WheelEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    target.blur()
    e.stopPropagation()

    setTimeout(() => {
      target.focus()
    }, 0)
  }

  const fieldSetStyle = `	  
  &.ant-input {	 
    ${fontStyles.bodyLarge}
    resize: none;	
    padding-top: 12px;	
    padding-left: ${fieldSet ? '0px' : '15px'};	
    padding-right: 15px;	
    padding-bottom: 12px;	
    border-color: ${NEUTRAL_COLORS.G200};	
    background-color: transparent!important;
  }	
  
  &.ant-input:focus {	
    border-color: #fff!important;	
    -webkit-box-shadow: 0 0 0 3px #fff!important;	
    box-shadow: 0 0 0 3px #fff!important;	
  } 
`

  return (
    <>
      {label && (
        <LabelContainer>
          <Body size="md" color={colors.onNeutralSecondary}>
            {label}
            {required && <Asterisk />}
          </Body>
          {optional && <OptionalText />}
        </LabelContainer>
      )}
      {fieldSet ? (
        <FieldSet>
          <span>
            <Legend>{fieldSet}</Legend>
          </span>
          <StyledInput
            className={`${css(fieldSetStyle)} ${className} `}
            addonBefore={addonBefore}
            placeholder={placeholder}
            bordered={false}
            disabled={disabled}
            onWheel={numberInputOnWheelPreventChange}
            {...rest}
          />
        </FieldSet>
      ) : (
        <StyledInput
          className={`${css(style)} ${className}`}
          addonBefore={addonBefore}
          placeholder={placeholder}
          disabled={disabled}
          onWheel={numberInputOnWheelPreventChange}
          {...rest}
        />
      )}
    </>
  )
}

const StyledInput = styled(Input)`
  && {
    ${fontStyles.bodyLarge}
    border-radius: ${radius.lg};
    height: 56px;
  }
`

type MaskedProps = {
  addonBefore?: string | React.ReactNode
  placeholder?: string
  className?: string
  disabled?: boolean
  value?: string
  label?: string | ReactElement
  required?: boolean
  optional?: boolean
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement> & { maskedValue: string; unmaskedValue: string }
  ) => void
  mask: string
} & Omit<MaskedInputProps, 'addonBefore' | 'placeholder' | 'disabled' | 'className'>

MainInput.Masked = function MainInputMasked({
  addonBefore = '',
  placeholder = '',
  disabled = false,
  className = '',
  optional = false,
  mask = '',
  onChange,
  value,
  label,
  required,
  ...rest
}: MaskedProps) {
  return (
    <>
      {label && (
        <LabelContainer>
          <Body size="md" color={colors.onNeutralSecondary}>
            {label}
            {required && <Asterisk />}
          </Body>
          {optional && <OptionalText />}
        </LabelContainer>
      )}
      <MaskedInput
        mask={mask}
        onChange={onChange}
        className={`${css(style)} ${className}`}
        addonBefore={addonBefore}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        {...rest}
      />
    </>
  )
}

MainInput.Password = function MainInputPassword({
  addonBefore = '',
  placeholder = '',
  disabled = false,
  className = '',
  label,
  required,
  ...rest
}: Props) {
  return (
    <>
      {label && (
        <LabelContainer>
          <Body size="md" color={colors.onNeutralSecondary}>
            {label}
            {required && <Asterisk />}
          </Body>
        </LabelContainer>
      )}
      <Input.Password
        className={`${css(style)} ${className}`}
        addonBefore={addonBefore}
        placeholder={placeholder}
        disabled={disabled}
        visibilityToggle={false}
        {...rest}
      />
    </>
  )
}

type SearchProps = {
  addonBefore?: string | React.ReactNode
  placeholder?: string
  className?: string
  style?: Record<string, unknown>
  disabled?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSearch?: () => void
  value?: string
}

MainInput.Search = function MainInputSearch({
  addonBefore = '',
  placeholder = '',
  onChange,
  onSearch,
  disabled,
  ...rest
}: SearchProps) {
  const { neutral, base } = useTheme()
  const searchStyle = `
    & .ant-input-group-addon:not(:last-child) {
      ${fontStyles.bodyLarge}
      background-color: ${colors.backgroundSecondaryNeutral};
      border-top-left-radius: ${radius.lg};
      border-bottom-left-radius: ${radius.lg};
      border: 1px solid ${neutral.border};
      border-right: 0;
      padding: ${typeof addonBefore === 'string' ? '0 15px' : '0'};
      height: 56px;

      & .ant-select {
        margin: 0;
        
        &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector, &.ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector {
          height: 54px !important;
          border: 0 !important;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;

          .ant-select-selection-item {
            margin-right: ${spacing.md};
          }
        }
        
        &.ant-select-single.ant-select-focused:not(.ant-select-customize-input) .ant-select-selector, &.ant-select-multiple.ant-select-focused:not(.ant-select-customize-input) .ant-select-selector {
          border-color: ${base.primary};
          -webkit-box-shadow: 0 0 0 2px ${base.primary}33;
          box-shadow: 0 0 0 2px ${base.primary}33;
        }
      } 
    }

    & .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: ${radius.lg};
      border-bottom-right-radius: ${radius.lg};
    }

    & .ant-input-affix-wrapper, &.ant-input-affix-wrapper {
      border-radius: ${radius.lg};
      border: 1px solid ${colors.line};
      min-height: 56px;
      padding: ${spacing.sm} ${spacing.md};

      &:hover {
        border-color: ${base.primary};
      }

      &.ant-input:placeholder-shown{
        ${fontStyles.bodyLarge}
        color: ${colors.placeholderInput};
      }

      & input{
        ${fontStyles.bodyLarge}
        color: ${colors.onNeutral};
      }
      
    }

    & .ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
      border-color: ${base.primary};
      -webkit-box-shadow: 0 0 0 2px ${base.primary}33;
      box-shadow: 0 0 0 2px ${base.primary}33;
    }
  `

  return (
    <Input
      placeholder={placeholder}
      addonBefore={addonBefore}
      onChange={onChange}
      onKeyDown={onSearch}
      disabled={disabled}
      className={`${css(searchStyle)}`}
      suffix={<Search onClick={onSearch} style={{ color: neutral.emphasis, cursor: 'pointer' }} />}
      {...rest}
    />
  )
}

type TextAreaProps = {
  placeholder?: string
  className?: string
  style?: Record<string, unknown>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (e: any) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFocus?: any
  label?: string
  required?: boolean
  optional?: boolean
  disabled?: boolean
  value?: string
  rows?: number
  maxLength?: number
  fieldSet?: string
}

MainInput.TextArea = function MainInputTextArea({
  className = '',
  label,
  required = false,
  optional = false,
  fieldSet = '',
  ...rest
}: TextAreaProps) {
  const { base } = useTheme()
  const textAreaStyle = `	  
  &.ant-input {	 
    ${fontStyles.bodyLarge}
    resize: none;	
    border-radius: ${radius.lg} !important;	  
    padding-top: 12px;	
    padding-left: 15px;	
    padding-right: 15px;	
    padding-bottom: 12px;	
    border-color: ${colors.line};	
  }	
  &.ant-input::placeholder {	
    ${fontStyles.bodyLarge}
    color: ${colors.placeholderInput};	
  }	
  &.ant-input:hover {	
    border-color: ${base.primary};	
  }	
  &.ant-input:focus {	
    border-color: ${base.primary};	
    -webkit-box-shadow: 0 0 0 3px ${BLUE_COLORS.B200};	
    box-shadow: 0 0 0 3px ${BLUE_COLORS.B200};	
    color: ${base.neutral}	
  }	
`
  const fieldSetStyle = `	  
  &.ant-input {	 
    ${fontStyles.bodyLarge}
    resize: none;	
    border-radius: ${radius.lg} !important;	  
    padding-top: 12px;	
    padding-left: ${fieldSet ? '0px' : '15px'};	
    padding-right: 15px;	
    padding-bottom: 12px;	
    border: none;
    background-color: transparent!important;
  }	
  
  &.ant-input:focus {	
    border-color: #fff!important;	
    -webkit-box-shadow: 0 0 0 3px #fff!important;	
    box-shadow: 0 0 0 3px #fff!important;	
  } 
`
  return (
    <>
      {label && (
        <LabelContainer>
          <Body size="md" color={colors.onNeutralSecondary}>
            {label}
            {required && <Asterisk />}
          </Body>
          {optional && <OptionalText />}
        </LabelContainer>
      )}
      {fieldSet ? (
        <FieldSet>
          <span>
            <Legend>{fieldSet}</Legend>
          </span>
          <Input.TextArea className={`${css(fieldSetStyle)} ${className}`} {...rest} />
        </FieldSet>
      ) : (
        <Input.TextArea className={`${css(textAreaStyle)} ${className}`} {...rest} />
      )}
    </>
  )
}

MainInput.Group = Input.Group

export default MainInput
