import type { Moment, PersonalVerificationStatus } from '~/types'

export type Invoice = {
  id: number
  uniqueId: string
  status: string
  customerName: string
  customerEmail?: string
  customerPhoneNumber?: string
  createdDate: string
  dueDate: string
  paidDate?: string
  settledDate?: string
  expiredDate?: string
  customerReference: string
  description?: string
  amount: string
  currency: string
  invoiceURL?: string
}

export type PaginationCursor = {
  lastRecordId: string
  lastRecordDatetime: string
}

export type FilterValuesObject = {
  [filterKey: string]:
    | Moment
    | string[]
    | number[]
    | string
    | number
    | Invoice[]
    | boolean
    | undefined
}

export type FilterValues = {
  startDate: Moment | string
  endDate: Moment | string
  startDueDate: Moment | string
  endDueDate: Moment | string
  invoiceStatuses: string[]
  currentPage: number
  pageLimit: number
  searchString: string
  isInvoiceLoading: boolean
  invoices: Invoice[]
  totalCount: number
  [key: string]: FilterValuesObject[keyof FilterValuesObject]
}

export type FilterOptions = {
  invoiceStatuses: Array<{ label: string; value: string }>
  sortTypes: Array<{ label: string; value: string }>
}

export type FormatInvoice = {
  key: number
  status: string
  customerInfo: {
    customerName: string
    customerEmail: string | undefined
  }
  createdDate: string
  dueDate: string
  customerReference: string
  description: string | undefined
  amount: string
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum INVOICING_ACTIONS {
  GET_INVOICES = 'INVOICING_ACTIONS.GET_INVOICES',
  SET_IS_INVOICE_LOADING = 'INVOICING_ACTIONS.SET_IS_INVOICE_LOADING',
  SET_FILTERS = 'INVOICING_ACTIONS.SET_FILTERS',
  SET_INVOICES_FILTER_OPTIONS = 'INVOICING_ACTIONS.SET_INVOICES_FILTER_OPTIONS',
  SET_USE_NEW_PAGINATION = 'INVOICING_ACTIONS.SET_USE_NEW_PAGINATION',
  SET_PAGINATION_CURSORS = 'INVOICING_ACTIONS.SET_PAGINATION_CURSORS',
  SET_PERSONAL_VERIFICATION_STATUS = 'INVOICING_ACTIONS.SET_PERSONAL_VERIFICATION_STATUS',
}

type GetInvoices = {
  type: typeof INVOICING_ACTIONS.GET_INVOICES
  invoices: Invoice[]
  totalCount: number
}

type SetIsInvoiceLoading = {
  type: typeof INVOICING_ACTIONS.SET_IS_INVOICE_LOADING
  isInvoiceLoading: boolean
}

type SetFilters = {
  type: typeof INVOICING_ACTIONS.SET_FILTERS
  filterValuesObject: FilterValuesObject
}

type SetInvoiceFilterOptions = {
  type: typeof INVOICING_ACTIONS.SET_INVOICES_FILTER_OPTIONS
  filterOptions: FilterOptions
}

type SetPaginationCursors = {
  type: typeof INVOICING_ACTIONS.SET_PAGINATION_CURSORS
  paginationCursors: Array<PaginationCursor>
}

type SetPersonalVerificationStatus = {
  type: typeof INVOICING_ACTIONS.SET_PERSONAL_VERIFICATION_STATUS
  userVerificationStatus: PersonalVerificationStatus
}

export type ActionTypes =
  | GetInvoices
  | SetIsInvoiceLoading
  | SetFilters
  | SetInvoiceFilterOptions
  | SetPaginationCursors
  | SetPersonalVerificationStatus
