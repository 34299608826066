import * as React from 'react'

function SvgLedgerVault(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#ledger-vault_svg__clip0)" fillRule="evenodd" clipRule="evenodd" fill="#000">
        <path d="M26.664 33.6h2.292a4.622 4.622 0 003.287-1.376 4.702 4.702 0 001.354-3.32v-2.237h-6.933V33.6zM14.531 26.667h6.934V33.6H14.53v-6.933zM2.398 26.667v2.292A4.642 4.642 0 007.04 33.6h2.292v-6.933H2.398zM2.398 14.534h6.934v6.933H2.398v-6.933zM28.653 2.4H14.53v19.067h19.067V7.334A4.935 4.935 0 0028.653 2.4zM9.332 2.4H7.04a4.642 4.642 0 00-4.642 4.642v2.292h6.934V2.4z" />
      </g>
      <defs>
        <clipPath id="ledger-vault_svg__clip0">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgLedgerVault
