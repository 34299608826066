// START NOTE: DO NOT EDIT THIS MANUALLY , run yarn create-feature-gate <feature_gate_id_name>
export type FeatureGateIDs = {
  fazzbiz_enable_api_menu: boolean
  fazzbiz_enable_earn: boolean
  fazzbiz_usd_account_disburse_interests_whitelisted_merchants: boolean
  fazzbiz_enable_payout_form_whitelist: boolean
  fazzbiz_enable_clevertap: boolean
  fazzbiz_enable_new_flexpay_recipient_form: boolean
  fazzbiz_pay_menu_disabled: boolean
  fazzbiz_enable_usd_earn: boolean
  fazzbiz_disable_withdraw: boolean
  fazzbiz_enable_usd_account_maintenance_page: boolean
  fazzbiz_is_wallex_account_verified: boolean
  fazzbiz_enable_sg_recipients: boolean
  fazzbiz_enable_sgd_earn_maintenance_page: boolean
  fazzbiz_kyb_ubo_enabled: boolean
  fazzbiz_enable_singpass_kyc_verification: boolean
  fazzbiz_enable_usd_credit_card: boolean
}
// END NOTE: DO NOT EDIT THIS MANUALLY , run yarn create-feature-gate <feature_gate_id_name>

export type FeatureGateIsLoading = boolean

export type State = {
  isFeatureGateLoading: FeatureGateIsLoading
  featureGateIDs: FeatureGateIDs
}

export enum GetFeatureGateActions {
  SET_FEATURE_GATE_IDS = 'GetFeatureGateActions.SET_FEATURE_GATE_IDS',
  SET_FEATURE_GATE_IS_LOADING = 'GetFeatureGateActions.SET_FEATURE_GATE_LOADING',
}

type SetFeatureGateIDs = {
  type: typeof GetFeatureGateActions.SET_FEATURE_GATE_IDS
  featureGateIDs: FeatureGateIDs
}

type SetFeatureGateIsLoading = {
  type: typeof GetFeatureGateActions.SET_FEATURE_GATE_IS_LOADING
  isFeatureGateLoading: FeatureGateIsLoading
}

export type ActionTypes = SetFeatureGateIDs | SetFeatureGateIsLoading
