import * as React from 'react'

function SvgMiz(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#MIZ_svg__clip0)">
        <path
          d="M8.954 9.179C8.455 10.452 7.92 11.708 7.384 13L6.647 14.7 5.89 13A84.143 84.143 0 014.283 9.18H.13v.332c.462 0 .831.388.831.85l-.222 2.62-.276 2.678A89.814 89.814 0 010 19.48h1.33c.073-1.274.184-2.547.313-3.821l.406-4.025c.591 1.218 1.145 2.455 1.699 3.692l.147.333c.554 1.255 1.09 2.529 1.607 3.821h.406a111.687 111.687 0 011.587-3.821l.24-.554c.536-1.237 1.09-2.474 1.662-3.693l.425 4.247c.129 1.274.24 2.547.313 3.821h3.046a89.789 89.789 0 01-.461-3.821l-.277-2.677a88.778 88.778 0 01-.314-3.822H8.954v.019zm13.661 9.267c.333-.646.702-1.292 1.071-1.901l3.692-6.166v-.259h-8.141v1.902h.314c0-.462.35-.831.794-.85.258 0 .517-.018.757-.018h2.621a37.07 37.07 0 01-1.052 1.865l-3.711 6.203v.258h8.788v-1.108c-.628.056-1.237.074-1.865.074h-3.268zm15.046 1.034h.074a21.813 21.813 0 01-.074-1.901v-2.327h3.637v2.327c0 .646-.018 1.273-.073 1.901h2.806a21.813 21.813 0 01-.074-1.901V12.04c0-.646.018-1.274.074-1.92h-3.563v.296h.073c.407 0 .72.313.739.72 0 .295.018.609.018.904v2.197h-3.637V12.04c0-.646.019-1.274.074-1.92h-3.563v.296h.074c.388 0 .72.313.739.701.018.295.018.61.018.905v6.498a7.69 7.69 0 01-1.126.074 9.02 9.02 0 01-1.219-.092c-1.126-.222-1.292-1.274-1.292-1.92v-4.579c0-.627.037-1.237.074-1.864h-3.563v.295h.074c.406 0 .72.314.738.702.019.295.019.609.019.904v4.08c0 .923.295 2.954 2.787 3.323.776.111 1.57.166 2.382.166 1.274 0 2.53-.055 3.784-.129zm18.351-4.67c0 3.193-2.75 4.8-5.464 4.8-2.714 0-5.465-1.625-5.465-4.8 0-3.194 2.75-4.8 5.465-4.8 2.713-.02 5.464 1.606 5.464 4.8zm-2.677 0c0-1.736-.72-3.785-2.806-3.785-2.068 0-2.806 2.049-2.806 3.784 0 1.736.72 3.785 2.806 3.785 2.086-.018 2.806-2.05 2.806-3.785zm-35.63-2.77c0-.646.018-1.274.074-1.92h-3.656v.332h.074c.48 0 .85.388.85.868 0 .24.018.48.018.72v5.539c0 .646-.019 1.273-.074 1.901h2.806a21.813 21.813 0 01-.074-1.901l-.018-5.539z"
          fill="#183181"
        />
        <path
          d="M5.095 24.614a104.228 104.228 0 0122.91-2.53c11.114 0 21.84 1.662 31.939 4.763a.102.102 0 01.055.093c0 .037-.055.074-.092.055a220.265 220.265 0 00-31.846-2.861c-7.643-.13-15.305.129-22.91.793-.075 0-.148-.037-.167-.11-.018-.093.037-.185.11-.203z"
          fill="#ED1A3A"
        />
      </g>
      <defs>
        <clipPath id="MIZ_svg__clip0">
          <path fill="#fff" d="M0 0h60v35H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgMiz
