/* eslint-disable max-lines */
import type { Reducer } from 'redux'
import type { ActionTypes, V3Key, V4Key } from './types'
import { ApiVersion, DeveloperToolsActions } from './types'

const initialState = {
  isV3Merchant: false,

  isLoading: false,
  verificationStatus: '',
  callbackSettings: {
    V3: {
      connectTokenDisabled: '',
      userVerificationStatusUpdated: '',
      userBankAccountVerificationStatusUpdated: '',
      withdrawalCompleted: '',
      payoutCompleted: '',
      chargeCompleted: '',
      virtualAccountTopupCompleted: '',
      v3PaymentGatewayPaymentExpired: '',
      v3PaymentGatewayPaymentPaid: '',
      v3PaymentGatewayPaymentCompleted: '',
      signature: '',
      showSig: false,
    },
    V4: {
      accept: '',
      acceptSignature: '',
      showAcceptSig: false,
      send: '',
      sendSignature: '',
      showSendSig: false,
      invoice: '',
      invoiceSignature: '',
      showInvoiceSig: false,
    },
  },
  apiKeys: {
    V3: {
      v3Keys: [],
      isLoading: false,
    },
    V4: {
      v4Keys: [],
      isLoading: false,
    },
  },
}

type State = {
  isV3Merchant: boolean

  isLoading: boolean
  verificationStatus: string
  callbackSettings: {
    V3: {
      connectTokenDisabled: string
      userVerificationStatusUpdated: string
      userBankAccountVerificationStatusUpdated: string
      withdrawalCompleted: string
      payoutCompleted: string
      chargeCompleted: string
      virtualAccountTopupCompleted: string
      v3PaymentGatewayPaymentExpired: string
      v3PaymentGatewayPaymentPaid: string
      v3PaymentGatewayPaymentCompleted: string
      signature: string
      showSig: boolean
    }
    V4: {
      accept: string
      acceptSignature: string
      showAcceptSig: boolean
      send: string
      sendSignature: string
      showSendSig: boolean
      invoice: string
      invoiceSignature: string
      showInvoiceSig: boolean
    }
  }
  apiKeys: {
    V3: {
      v3Keys: V3Key[]
      isLoading: boolean
    }
    V4: {
      v4Keys: V4Key[]
      isLoading: boolean
    }
  }
}

export const developerTools: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case DeveloperToolsActions.SET_API_KEY_VERSION_V3: {
      const { isV3Merchant } = action
      return {
        ...state,
        isV3Merchant,
      }
    }

    case DeveloperToolsActions.GET_INITIAL_URL_V3: {
      return {
        ...state,
        callbackSettings: {
          ...state.callbackSettings,
          V3: action.v3CallbackSettings,
        },
      }
    }

    case DeveloperToolsActions.GET_INITIAL_URL_V4: {
      const { accept, acceptSignature, send, sendSignature, invoice, invoiceSignature } =
        action.v4CallbackSettings
      return {
        ...state,
        callbackSettings: {
          ...state.callbackSettings,
          V4: {
            ...state.callbackSettings.V4,
            accept,
            acceptSignature,
            send,
            sendSignature,
            invoice,
            invoiceSignature,
          },
        },
      }
    }

    case DeveloperToolsActions.SET_CALLBACK_URL: {
      const { apiVersion, callbackType, callbackUrl } = action

      return {
        ...state,
        callbackSettings: {
          ...state.callbackSettings,
          [apiVersion]: {
            ...state.callbackSettings[apiVersion],
            [callbackType]: callbackUrl,
          },
        },
      }
    }

    case DeveloperToolsActions.UPDATE_CALLBACK_URL: {
      return {
        ...state,
      }
    }

    case DeveloperToolsActions.RESET_API_KEYS: {
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          V3: {
            ...state.apiKeys.V3,
            v3Keys: [],
          },
          V4: {
            ...state.apiKeys.V4,
            v4Keys: [],
          },
        },
      }
    }

    case DeveloperToolsActions.FETCH_API_KEY: {
      const { apiKeys, apiVersion } = action
      const keyField = apiVersion === ApiVersion.V3 ? 'v3Keys' : 'v4Keys'

      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          [apiVersion]: {
            ...state.apiKeys[apiVersion],
            [keyField]: apiKeys,
          },
        },
      }
    }

    case DeveloperToolsActions.SET_IS_LOADING: {
      const { isLoading } = action
      return {
        ...state,
        isLoading,
      }
    }

    case DeveloperToolsActions.GENERATE_API_KEY: {
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          V4: {
            ...state.apiKeys.V4,
            v4Keys: [...state.apiKeys.V4.v4Keys, action.v4Key],
          },
        },
      }
    }

    case DeveloperToolsActions.CLEAR_API_KEYS: {
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          V4: {
            ...state.apiKeys.V4,
            v4Keys: [],
          },
        },
      }
    }

    case DeveloperToolsActions.SHOW_V4_SIGNATURE: {
      const { callbackSigType, signature, signatureType } = action
      return {
        ...state,
        callbackSettings: {
          ...state.callbackSettings,
          V4: {
            ...state.callbackSettings.V4,
            [callbackSigType]: signature,
            [signatureType]: true,
          },
        },
      }
    }

    case DeveloperToolsActions.SHOW_V3_SIGNATURE: {
      const { showSig } = action
      return {
        ...state,
        callbackSettings: {
          ...state.callbackSettings,
          V3: {
            ...state.callbackSettings.V3,
            showSig,
          },
        },
      }
    }

    case DeveloperToolsActions.SET_V3_SIGNATURE: {
      const { signature } = action
      return {
        ...state,
        callbackSettings: {
          ...state.callbackSettings,
          V3: {
            ...state.callbackSettings.V3,
            signature,
          },
        },
      }
    }

    case DeveloperToolsActions.GET_VERIFICATION_STATUS: {
      return {
        ...state,
        verificationStatus: action.verificationStatus,
      }
    }

    default:
      return state
  }
}
