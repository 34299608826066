import * as React from 'react'

function CloseCrossCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99964 0.793295C6.5743 0.793295 5.18096 1.21596 3.99583 2.00784C2.8107 2.79972 1.887 3.92524 1.34155 5.24209C0.796091 6.55894 0.653375 8.00796 0.931446 9.40591C1.20952 10.8039 1.89589 12.088 2.90376 13.0958C3.91163 14.1037 5.19573 14.7901 6.59369 15.0682C7.99164 15.3462 9.44067 15.2035 10.7575 14.6581C12.0744 14.1126 13.1999 13.1889 13.9918 12.0038C14.7836 10.8186 15.2063 9.42531 15.2063 7.99996C15.2063 6.08863 14.447 4.25559 13.0955 2.90408C11.744 1.55257 9.91097 0.793295 7.99964 0.793295V0.793295ZM10.1863 9.45996C10.2746 9.55475 10.3227 9.68011 10.3204 9.80964C10.3181 9.93918 10.2657 10.0628 10.1741 10.1544C10.0824 10.246 9.95885 10.2985 9.82932 10.3007C9.69979 10.303 9.57442 10.2549 9.47964 10.1666L7.99964 8.70663L6.51964 10.1866C6.42486 10.2749 6.29949 10.323 6.16996 10.3207C6.04042 10.3185 5.91683 10.266 5.82522 10.1744C5.73362 10.0828 5.68114 9.95918 5.67886 9.82964C5.67657 9.70011 5.72465 9.57475 5.81297 9.47996L7.29297 7.99996L5.81297 6.51996C5.72465 6.42518 5.67657 6.29981 5.67886 6.17028C5.68114 6.04075 5.73362 5.91716 5.82522 5.82555C5.91683 5.73394 6.04042 5.68146 6.16996 5.67918C6.29949 5.67689 6.42486 5.72498 6.51964 5.8133L7.99964 7.2933L9.47964 5.8133C9.57442 5.72498 9.69979 5.67689 9.82932 5.67918C9.95885 5.68146 10.0824 5.73394 10.1741 5.82555C10.2657 5.91716 10.3181 6.04075 10.3204 6.17028C10.3227 6.29981 10.2746 6.42518 10.1863 6.51996L8.70631 7.99996L10.1863 9.45996Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CloseCrossCircle
