import { useEffect } from 'react'
import styled from '@emotion/styled'
import { Tick } from '@fazz/design-system-icons'
import { FONT_WEIGHT } from '../../../themes/deprecated/TypographyStyles'
import { fazzbizTheme } from '../../../themes/deprecated/theme'
import { useTheme } from '../../../themes/deprecated/themeContext'
import Hidden from '../Hidden'
import { Body } from '../Typography'
import DesktopSteps from './DesktopSteps'

type ResponsiveStepsProps = {
  current: number
  steps: Array<React.ReactNode>
  desktopNote?: React.ReactNode
  hasExtraSpaceMobileLastStep?: boolean
} & React.HTMLAttributes<HTMLDivElement>

function ResponsiveSteps({
  current,
  steps,
  desktopNote,
  hasExtraSpaceMobileLastStep = false,
}: ResponsiveStepsProps) {
  const { base, status, neutral, interactive } = useTheme()

  useEffect(() => {
    const activeStep = document.querySelector(`#mobile-${current}`)
    if (activeStep) {
      activeStep.scrollIntoView({ inline: 'start' })
    }
  }, [current])

  const MobileView = styled.div`
    height: 58px;
    padding-left: 16px;
    border-bottom: 1px solid ${neutral.border};
    max-width: 100vw;
    display: flex;
    align-items: center;
    overflow: auto;
    background: ${neutral.background};

    &::-webkit-scrollbar {
      display: none;
    }
  `

  const StepIcon = styled.div<{ current: number; index: number }>`
    color: ${fazzbizTheme.brand.primary.contrast};
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
    background: ${neutral.emphasis};
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) => {
      if (props.current === props.index) {
        return `
        background: ${base.primary};
      `
      }
      if (props.current > props.index) {
        return `
        background: ${status.positive};
      `
      }
      return ``
    }}
  `

  const StepConnector = styled.div`
    position: relative;
    width: 20px;
    margin-left: 10px;
    margin-right: 10px;
    height: 1px;
    background: ${neutral.emphasis};
    display: inline-block;
    margin-bottom: 4px;
  `

  const MobileSteps = styled.div`
    padding-right: 16px;
    width: 1px;
    white-space: nowrap;
  `

  const StepItem = styled(Body)<{ inactive: boolean; current: boolean }>`
    color: ${base.neutral};
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    font-weight: ${FONT_WEIGHT.BOLD};
    ${(props) =>
      props.inactive &&
      `
    color: ${interactive.inactive};
  `}

    &:last-child {
      padding-right: 16px;
    }

    ${(props) =>
      props.current &&
      `
      color: ${base.primary}
  `}
  `
  return (
    <>
      <Hidden only="sm">
        <div style={{ width: '275px' }}>
          <DesktopSteps
            progressDot={(
              _: React.ReactNode,
              { index, status: stat }: { index: number; status: string }
            ) =>
              stat === 'finish' ? (
                <IconDots stat={stat}>
                  <Tick color={fazzbizTheme.brand.primary.contrast} />
                </IconDots>
              ) : (
                <IconDots stat={stat}>{index + 1}</IconDots>
              )
            }
            current={current}
            direction="vertical"
            style={{ marginBottom: '34px' }}
          >
            {steps.map((step) => (
              <DesktopSteps.Step key={`desktop-${step}`} title={step} />
            ))}
          </DesktopSteps>
          {desktopNote}
        </div>
      </Hidden>
      <Hidden only="lg">
        <MobileView>
          <MobileSteps id="hi">
            {steps.map((step, index) => (
              <StepSpan
                key={`mobile-${step}`}
                id={`mobile-${index}`}
                hasExtraSpaceForLastStep={
                  current + 1 === steps.length && hasExtraSpaceMobileLastStep
                }
              >
                <StepItem inactive={current < index} current={current === index}>
                  <StepIcon index={index} current={current}>
                    {current > index ? (
                      <Tick color={fazzbizTheme.brand.primary.contrast} />
                    ) : (
                      index + 1
                    )}
                  </StepIcon>
                  {step}
                </StepItem>
                {index !== steps.length - 1 && <StepConnector />}
              </StepSpan>
            ))}
          </MobileSteps>
        </MobileView>
      </Hidden>
    </>
  )
}

const StepSpan = styled.span<{ hasExtraSpaceForLastStep: boolean }>`
  ${(props) => {
    if (props.hasExtraSpaceForLastStep) {
      return `
        &:last-child {
          margin-right: calc(100vw - 150px);
        }
      `
    }
    return ``
  }}
`

const IconDots = styled.div`
  border-radius: 100%;
  font-weight: ${FONT_WEIGHT.BOLD};
  color: ${fazzbizTheme.brand.primary.contrast};
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props: { stat: string }) =>
    (props.stat === 'finish' && fazzbizTheme.status.positive) ||
    (props.stat === 'wait' && fazzbizTheme.interactive.inactive) ||
    (props.stat === 'process' && fazzbizTheme.base.primary)};
`

export default ResponsiveSteps
