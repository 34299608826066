import type { Reducer } from 'redux'
import type { BusinessVerificationStatus, PersonalVerificationStatus } from '~/types'
import type {
  ActionTypes,
  BusinessAccount,
  Transaction,
  CorporateAccount,
  ManagedAccount,
  TransferInfo,
  DashboardBanner,
  MerchantTransactionLimits,
} from './types'
import { LandingPageActions } from './types'

const initialState = {
  // user related fields, currently getting from API_V3_USER
  name: '',
  verificationStatus: null,

  // business account related fields
  businessAccount: null,
  isLoadingBusinessAccount: false,
  latestBusinessTransactions: null,
  merchantBusinessVerifyStatus: null,

  // corporate account related fields
  corporateAccount: null,

  // managed account related fields
  managedAccounts: null,

  // misc
  businessVerificationStatus: null,
  transferInfo: {
    recipientName: '',
    recipientBankName: '',
    recipientAccountNo: '',
    referenceNumber: '',
    expiredDate: '',
  },

  isDashboardBannerManagementEnabled: false,
  dashboardBanners: [],
  merchantTransactionLimits: null,

  disbursementLimitAmount: Number.MAX_VALUE,
}

type State = {
  name: string
  verificationStatus: PersonalVerificationStatus

  businessAccount: BusinessAccount | null
  isLoadingBusinessAccount: boolean
  latestBusinessTransactions: Transaction[] | null
  merchantBusinessVerifyStatus: string | null

  corporateAccount: CorporateAccount | null

  managedAccounts: ManagedAccount[] | null

  businessVerificationStatus: BusinessVerificationStatus | null

  transferInfo: TransferInfo

  isDashboardBannerManagementEnabled: boolean
  dashboardBanners: DashboardBanner[]
  merchantTransactionLimits: MerchantTransactionLimits | null

  disbursementLimitAmount: number
}

export const landingPage: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case LandingPageActions.SET_USER_INFO: {
      return {
        ...state,
        name: action.name,
        verificationStatus: action.verificationStatus,
      }
    }

    case LandingPageActions.SET_BUSINESS_ACCOUNT: {
      return {
        ...state,
        businessAccount: action.businessAccount,
      }
    }

    case LandingPageActions.SET_LATEST_BUSINESS_TRANSACTIONS: {
      return {
        ...state,
        latestBusinessTransactions: action.latestBusinessTransactions,
      }
    }

    case LandingPageActions.SET_CORPORATE_ACCOUNT: {
      return {
        ...state,
        corporateAccount: action.corporateAccount,
      }
    }

    case LandingPageActions.SET_MANAGED_ACCOUNTS: {
      return {
        ...state,
        managedAccounts: action.managedAccounts,
      }
    }

    case LandingPageActions.SET_BUSINESS_VERIFICATION_STATUS: {
      return {
        ...state,
        businessVerificationStatus: action.businessVerificationStatus,
      }
    }

    case LandingPageActions.SET_TRANSFER_INFO: {
      return {
        ...state,
        transferInfo: action.transferInfo,
      }
    }

    case LandingPageActions.SET_DASHBOARD_BANNERS: {
      return {
        ...state,
        dashboardBanners: action.dashboardBanners,
      }
    }

    case LandingPageActions.SET_IS_DASHBOARD_BANNER_MANAGEMENT_ENABLED: {
      return {
        ...state,
        isDashboardBannerManagementEnabled: action.isDashboardBannerManagementEnabled,
      }
    }

    case LandingPageActions.SET_MERCHANT_TRANSACTION_LIMITS: {
      return {
        ...state,
        merchantTransactionLimits: action.merchantTransactionLimits,
      }
    }

    case LandingPageActions.SET_IS_LOADING_BUSINESS_ACCOUNT: {
      return {
        ...state,
        isLoadingBusinessAccount: action.isLoading,
      }
    }

    case LandingPageActions.SET_DISBURSEMENT_LIMIT_AMOUNT: {
      return {
        ...state,
        disbursementLimitAmount: action.disbursementLimitAmount,
      }
    }

    default:
      return state
  }
}
