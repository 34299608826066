import * as React from 'react'

function SvgFireblocks(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#fireblocks_svg__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.398 33.314h31.2V3.6h-31.2v29.714zM9.827 24.4H26.17l-8.213-13.372L9.827 24.4z"
          fill="url(#fireblocks_svg__paint0_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="fireblocks_svg__paint0_linear"
          x1={5.37}
          y1={25.885}
          x2={33.598}
          y2={5.828}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00081E" />
          <stop offset={1} stopColor="#0A4269" />
        </linearGradient>
        <clipPath id="fireblocks_svg__clip0">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgFireblocks
