import { SVGProps } from 'react'

function SvgImageFile(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.383 2.975a4.583 4.583 0 0 1 4.584 4.583v4.883a4.583 4.583 0 0 1-4.584 4.584H6.617a4.583 4.583 0 0 1-4.584-4.584V7.558a4.583 4.583 0 0 1 4.584-4.583h6.766Zm0-1.25H6.617A5.833 5.833 0 0 0 .783 7.558v4.883a5.833 5.833 0 0 0 5.834 5.834h6.766a5.834 5.834 0 0 0 5.834-5.834V7.558a5.833 5.833 0 0 0-5.834-5.833Z"
        fill="currentColor"
      />
      <path
        d="M1.733 14.858a.64.64 0 0 1-.492-.234.633.633 0 0 1 .092-.883L4.3 11.35a1.858 1.858 0 0 1 2.5.134l1.841 1.85a.25.25 0 0 0 .159.058.208.208 0 0 0 .15-.067l3.975-4.391a2.615 2.615 0 0 1 3.791-.092l2.15 2.15a.625.625 0 0 1-.883.883l-2.15-2.125a1.367 1.367 0 0 0-1-.4 1.333 1.333 0 0 0-.983.45l-3.975 4.367a1.468 1.468 0 0 1-2.117.05l-1.875-1.85a.6.6 0 0 0-.833-.042l-2.925 2.392a.65.65 0 0 1-.392.142ZM7.992 9.133a1.675 1.675 0 1 0 0-3.35 1.675 1.675 0 0 0 0 3.35Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgImageFile
