import * as React from 'react'

function SvgThumbnailTwoTone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        d="M23.152 5.429c-.171 0-.286-.058-.457-.115 0 0-2.743-1.6-7.543-1.6-4.743 0-6.914 1.543-6.914 1.543-.4.286-.914.172-1.2-.171-.286-.4-.229-.915.171-1.2C7.324 3.829 9.781 2 15.152 2c5.315 0 8.343 1.771 8.457 1.829.4.228.515.742.286 1.2-.171.228-.457.4-.743.4z"
        fill="#1B2736"
      />
      <path
        d="M22.468 25.428c-1.029 0-3.543-.228-5.315-2-2.171-2.17-2.514-4.685-2.514-4.8-.057-.457.286-.914.743-.97.457-.058.914.285.971.742 0 0 .286 2.057 2.057 3.771 1.658 1.657 4.458 1.486 4.458 1.486a.81.81 0 01.914.8.81.81 0 01-.8.914c-.057.058-.229.058-.514.058z"
        fill="currentColor"
      />
      <path
        d="M12.293 27.429c-.228 0-.457-.114-.628-.286-.114-.171-3.2-3.714-3.2-8.286 0-2.914 1.428-6.342 5.371-7.428 1.772-.457 3.429-.286 4.857.571 2.915 1.714 3.886 5.6 3.886 5.772a.852.852 0 11-1.657.4c0-.058-.857-3.315-3.143-4.686-1.028-.629-2.171-.743-3.543-.4-3.771.971-4.057 4.629-4.057 5.714 0 3.886 2.743 7.143 2.8 7.143.286.343.286.914-.057 1.2-.229.229-.457.286-.629.286z"
        fill="#1B2736"
      />
      <path
        d="M26.009 12c-.286 0-.571-.172-.743-.4-.114-.229-3.143-5.029-10.114-5.029-6.972 0-9.429 4.743-9.543 4.972-.229.4-.743.571-1.143.4-.4-.229-.571-.743-.4-1.143.172-.286 3.029-5.943 11.086-5.943 8 0 11.429 5.6 11.6 5.828.229.4.114.915-.286 1.2-.171.058-.286.115-.457.115z"
        fill="currentColor"
      />
      <path
        d="M19.152 28h-.172c-5.143-1.257-7.543-6-7.543-8.857 0-3.143 1.543-4.857 4.4-4.857 1.03 0 2.972.743 3.6 3.543.343 1.428.915 2.742 2.858 2.742.685 0 1.257-.228 1.6-.742.685-.915.685-2.343.4-3.315v-.057c-.058-.286-1.543-6.743-9.143-6.743-2.914 0-5.143 1.086-6.572 3.2-2.057 2.972-1.885 7.086-.914 9.657.172.458-.057.915-.514 1.086-.457.172-.914-.057-1.086-.514-1.143-2.972-1.314-7.714 1.086-11.257 1.2-1.772 3.6-3.943 8-3.943 8.743 0 10.685 7.428 10.857 8.057.4 1.429.343 3.429-.686 4.8-.686.914-1.714 1.429-2.971 1.429-2.4 0-3.943-1.372-4.572-4.115-.457-2.171-1.828-2.171-1.943-2.171-1.257 0-2.685.343-2.685 3.143 0 2 1.828 6.114 6.171 7.143a.852.852 0 01.629 1.028c-.057.457-.4.743-.8.743z"
        fill="#1B2736"
      />
    </svg>
  )
}

export default SvgThumbnailTwoTone
