import * as React from 'react'
import { SVGProps } from 'react'

function SvgEyeOpenFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.215 7.085a9 9 0 0 0-14.43 0 4.05 4.05 0 0 0 0 4.83 9 9 0 0 0 14.43 0 4.05 4.05 0 0 0 0-4.83ZM9 13.032a3.533 3.533 0 1 1 0-7.065 3.533 3.533 0 0 1 0 7.065Z"
        fill="currentColor"
      />
      <path
        d="M9 11.907a2.408 2.408 0 1 0 0-4.815 2.408 2.408 0 0 0 0 4.815Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgEyeOpenFilled
