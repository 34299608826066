export type AccountDetails = {
  accountBalance: {
    currentAccountBalance: string
    remainingDeposit: string
    depositLimit: string
    availableBalance: string
  }
  interestEarned: {
    totalInterestEarned: string
    fixedAPY: string
  }
}

export enum EarnAccountDetailsActions {
  GET_ACCOUNT_DETAILS = 'EarnAccountDetailsActions.GET_ACCOUNT_DETAILS',
  SET_IS_ACCOUNT_DETAILS_LOADING = 'EarnAccountDetailsActions.SET_IS_ACCOUNT_DETAILS_LOADING',
}

type GetAccountDetails = {
  type: typeof EarnAccountDetailsActions.GET_ACCOUNT_DETAILS
  accountDetails: AccountDetails
}

type SetIsAccountDetailsLoading = {
  type: typeof EarnAccountDetailsActions.SET_IS_ACCOUNT_DETAILS_LOADING
  isLoading: boolean
}

export type ActionTypes = GetAccountDetails | SetIsAccountDetailsLoading
