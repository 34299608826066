import type moment from 'moment'
import { type UploadFile } from 'antd/lib/upload/interface'
import { PERMISSIONS } from './permissions'

export { PERMISSIONS }
export * from './featureMatrix'
export * from './route'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FixMeLater = any

export type AxiosRequestHeaders = {
  [x: string]: string | number | boolean
}

export type AxiosRequestConfig = Record<string, AxiosRequestHeaders>

export type BusinessEntity = {
  attributes: {
    bizId: string
    companyId?: string
    companyName?: string
    displayName?: string
    enableUploadReceiptFeature?: string
    ownerContactNo?: string
    ownerEmail?: string
    ownerFullName?: string
    ownerKcId: string
    registrationCountry?: BusinessRegistrationCountry
    roles?: string[]
  }
  id: string
}

export type Employers = Pick<BusinessEntity, 'id'> & {
  attributes: Pick<
    BusinessEntity['attributes'],
    'bizId' | 'companyId' | 'companyName' | 'displayName' | 'registrationCountry' | 'roles'
  >
}

export enum BusinessRegistrationCountry {
  SINGAPORE = 'SG',
  INDONESIA = 'ID',
}

export enum AppType {
  SINGAPORE = 'BASED_ON_SINGAPORE_ISSUED_LICENSE',
  INDONESIA = 'BASED_ON_INDONESIA_ISSUED_LICENSE',
}

export type WalletIdType = WALLET_TYPES.GENERAL | WALLET_TYPES.TEMPORARY_HOLDING_BALANCE | undefined

export type Wallet = {
  type: WalletIdType
  balance: string
  formattedBalance: string
  label: string
  accountId: number
  accountType: string
  fundPoolId: number
}

// TODO: change all enum variables to PascalCase
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum WALLET_TYPES {
  GENERAL = 1,
  XSGD = 7,
  TEMPORARY_HOLDING_BALANCE = 9,
  INDO_GENERAL_FUNDS = 2354,
}
// TODO: change all enum variables to PascalCase
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ACCOUNT_TYPES {
  PERSONAL = 'PersonalAccount',
  CORPORATE = 'CorporateAccount',
  BUSINESS = 'BusinessAccount',
  TEMPORARY_HOLDING_BALANCE = 'TemporaryHoldingBalanceAccount',
  BUSINESS_SETTLEMENT = 'BusinessSettlementAccount',
  MANAGED = 'ManagedAccount',
  SOBATKU_PERSONAL = 'SobatkuPersonalAccount',
  XIDR_PERSONAL = 'Stablecoin::Models::PersonalAccount',
  XIDR_BUSINESS = 'Stablecoin::Models::BusinessAccount',
}

export type TransactionStatus =
  | 'completed'
  | 'processing'
  | 'failed'
  | 'cancelled'
  | 'refunded'
  | 'paid'
  | 'pending'

export type TransactionType =
  | 'Payment'
  | 'Withdrawal'
  | 'Deposit'
  | 'Stablecoin Withdrawal'
  | 'Stablecoin Deposit'
  | 'Disbursement'
  | 'Disbursement Dash'
  | 'Invoice'

export type BlockedReasons = 'Name mismatch' | 'Under review' | 'Exceed limit'

export type DepositRequestStatus = 'completed' | 'pending' | 'expired'

export type VerificationStatusType =
  | 'initial'
  | 'pending'
  | 'submitted'
  | 'verifying'
  | 'verified'
  | 'rejected'
  | 'permanently rejected'

export type BusinessVerificationStatus =
  | 'initial'
  | 'unverified'
  | 'verified'
  | 'pending'
  | 'rejected'
  | 'permanently rejected'
  | undefined

export type PersonalVerificationStatus =
  | 'initial'
  | 'verified'
  | 'pending'
  | 'rejected'
  | 'permanently rejected'
  | null

// TODO: change all enum variables to PascalCase
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum BUSINESS_VERIFICATION_STATUS {
  INITIAL = 'initial',
  UNVERIFIED = 'unverified',
  VERIFIED = 'verified',
  PENDING = 'pending',
  REJECTED = 'rejected',
  PERMANENTLY_REJECTED = 'permanently rejected',
}

// TODO: change all enum variables to PascalCase
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum PERSONAL_VERIFICATION_STATUS {
  INITIAL = 'initial',
  VERIFIED = 'verified',
  PENDING = 'pending',
  REJECTED = 'rejected',
  PERMANENTLY_REJECTED = 'permanently rejected',
}

export type FundPoolTypes = 1 | 2 | 3

export type AccountLimitation = {
  max: string
  remaining: string
  used: string
}

export type Account = {
  attributes: {
    type: ACCOUNT_TYPES
    wallet_id: WALLET_TYPES
    fund_pool_id: number
    deleted_at: null | string
    limitations: {
      [key: string]: AccountLimitation
    }
  }
}

// TODO: change all enum variables to PascalCase
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum UserRoles {
  OWNER = 'owner',
  ADMIN = 'admin',
  OPERATIONS = 'operations',
  DEVELOPER = 'developer',
  VIEWER = 'viewer',
  FINANCE = 'finance',
  BASIC = 'basic', // THIS SHOULD BE DEPRECATED
}

export type Moment = moment.Moment

export type LabelTypes = 'success' | 'error' | 'warning' | 'info' | 'neutral'

export type Map<T extends string | number | symbol> = {
  [key in T]: {
    [key: string]: string
  }
}

export type TransactionsTableColumnEntity =
  | 'settlementDate'
  | 'createdDate'
  | 'amount'
  | 'netAmount'
  | 'completedDate'
  | 'status'
  | 'transactionDate'
  | 'description'

export type EvidenceType =
  | 'proofOfDelivery'
  | 'invoiceOrReceipt'
  | 'distinctTxnInvoice'
  | 'customerCommutation'
  | 'refundPolicy'
  | 'recurringTxn'
  | 'additionalEvidence'

export type ManualAssignmentTableColumnEntity = 'assignedAmount' | 'completedDate'

export type SGDAccountTableColumnEntity = 'createdDate'

export type ValueOf<T> = T[keyof T]

export type UploadDocumentType =
  | 'bizfile'
  | 'memorandum'
  | 'beneficialOwnerDeclaration'
  | 'proofOfAddress'
  | 'idFront'
  | 'idBack'

export type FileType = {
  fileData: string
  fileName: string
}

export type FilePairType = {
  type: UploadDocumentType
  file: UploadFile
}

export type ParsedFilePairType = {
  type: UploadDocumentType
  file: {
    fileData: string
    fileName: string
  }
}

export type MorakLoanVerificationStatus =
  | 'unverified'
  | 'rejected'
  | 'verified'
  | 'pending'
  | undefined

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum MORAK_LOAN_VERIFICATION_STATUS {
  UNVERIFIED = 'unverified',
  REJECTED = 'rejected',
  VERIFIED = 'verified',
  PENDING = 'pending',
}

export enum UserAgent {
  MOBILE_APP = 'MOBILE_APP',
  WEB = 'WEB',
}

export enum TransactionsMethodTypes {
  BankTransfer = 'Bank Transfer',
  PayNowVpa = 'PayNow',
  PayNowQr = 'PayNow - QR Code',
  CardPayment = 'Card',
  Earn = 'Earn',
  Wallet = 'Wallet',
}

export type TransactionDetailParams = {
  transactionId: string
  transactionType: 'Transfer-in' | 'Transfer-out' | 'Fee'
  transactionMethod: TransactionsMethodTypes
}

export const RecipientListTypes = {
  RECIPIENT_NAME: 'companyNameKeyword',
  COMPANY_EMAIL: 'companyPicEmailKeyword',
  BANK_ACCOUNT_NUMBER: 'bankAccountHolderKeyword',
} as const
