import { StyledToast } from './Toast.styles'
import { ToastProps } from './Toast.types'

export function Toast({
  type,
  description,
  style = {},
  className = '',
  icon = null,
  duration = 3,
  onClick = () => {},
  onClose = () => {},
}: // DO NOT USE SPREAD OPERATOR i.e. {...props}
ToastProps) {
  return StyledToast({
    type,
    description,
    style,
    className,
    icon,
    duration,
    onClick,
    onClose,
  })
}

export default Toast
