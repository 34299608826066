import styled from '@emotion/styled'
import { DESKTOP_BREAKPOINT } from '../../../themes/deprecated/breakPoints'
import { titleStyle, bodyStyle, MAIN_FONT } from '../../../themes/deprecated/fontStyles'
import { useTheme } from '../../../themes/deprecated/themeContext'

type ErrorPageProps = {
  title: string
  subtitle?: string
  content?: string | React.ReactNode
  fallbackContent?: React.ReactNode
}

function ErrorPage({ title, subtitle, content, fallbackContent }: ErrorPageProps) {
  const { neutral, base, interactive } = useTheme()

  const Title = styled.div`
    font-family: ${MAIN_FONT};
    font-weight: 800;
    color: ${interactive.hoverOnNeutral};
    font-size: 100px;
    margin-bottom: 20px;
  `
  const Content = styled.div`
    font-family: ${MAIN_FONT};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    ${DESKTOP_BREAKPOINT} {
      background-color: ${neutral.background};
    }
  `

  const Subtitle = styled.div`
    font-family: ${MAIN_FONT};
    font-style: normal;
    text-align: center;
    color: ${base.neutral};
    margin-bottom: 5px;
    word-break: break-word;
    ${titleStyle}
  `

  const Body = styled.div`
    text-align: center;
    color: ${base.neutral};
    flex: none;
    order: 0;
    align-self: center;
    margin-bottom: 20px;
    ${bodyStyle}
  `

  return (
    <Content>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Body>{content}</Body>
      {fallbackContent}
    </Content>
  )
}

export default ErrorPage
