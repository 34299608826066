import { css } from 'emotion'
import { useTheme } from '../../../themes/deprecated/themeContext'
import { LabelText } from '../Typography'

type ContentBreakdownProps = {
  title: string
  children: React.ReactElement
}

function ContentBreakdown({ title, children }: ContentBreakdownProps) {
  const { spacing, neutral } = useTheme()
  const style = `
    margin-top: ${spacing.sm};
    padding: ${spacing.md};
    background: ${neutral.background};

    & > ul {
      margin: 0;
      padding: 0;
      list-style-position: inside;
    }
  `

  return (
    <div className={css(style)}>
      <LabelText style={{ marginBottom: spacing.sm }}>{title}</LabelText>
      {children}
    </div>
  )
}

export default ContentBreakdown
