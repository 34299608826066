import type { Moment } from '~/types'

export enum TransferSourceType {
  IDR_CASH_ACCOUNT = 'IDR Cash Account',
  LOAN_ACCOUNT = 'Loan Account',
  FLEX_PAY = 'FlexPay',
}

export const recipientType = new Map([
  [TransferSourceType.IDR_CASH_ACCOUNT, { serviceName: 'ID_PAYMENT_SERVICE', status: 'verified' }],
  [TransferSourceType.LOAN_ACCOUNT, { serviceName: 'MORAK', status: 'approved' }],
  [TransferSourceType.FLEX_PAY, { serviceName: 'FLEXPAY', status: 'active' }],
])

export const TRANSFER_SOURCE_TYPE_LABEL = new Map<TransferSourceType, string>([
  [TransferSourceType.IDR_CASH_ACCOUNT, 'IDR Cash Account'],
  [TransferSourceType.LOAN_ACCOUNT, 'Loan Account'],
  [TransferSourceType.FLEX_PAY, 'FlexPay Account'],
])

export type TransferSource = {
  type: TransferSourceType
  active: boolean
  balance: number
}

export type TransferRecipient = {
  id: number
  accountHolderName: string
  name: string
  bank: string
  accountNumber: string
}

export enum PayTransactionStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
  FAILED = 'failed',
  PAID = 'paid',
  COMPLETED = 'completed',
}

type FilterOption = {
  label: string
  value: string
}

export type PayTransactionFilterOption = {
  transactionStatuses: Array<FilterOption>
  sortTypes: Array<FilterOption>
  searchTypes: Array<FilterOption>
}

export enum PayTransactionsActions {
  SET_TRANSACTION = 'PayTransactionsActions.SET_TRANSACTION',
  SET_IS_LOADING = 'PayTransactionsActions.SET_IS_LOADING',
}

export type TransferDetails = {
  amount: string
  paymentId: string
  status: string
}

export type LoanAccountTransaction = {
  key: number
  transferId: string
  recipientName: string
  createdDate: Moment | string
  completedDate?: Moment | string
  transferSource?: string
  bankAccount: {
    bankName: string
    bankCode: number
    bankSite: string
  }
  notes?: string
  category?: string
  recipientEmail?: string
  amount: string
  status: PayTransactionStatus
  amountDetails?: {
    amount: string
    tax: string
    fee: string
    netAmount: string
  }
  transferDetails?: Array<TransferDetails>
  files?: Array<string>
}

export type LoanAccountFilterValues = {
  startDate?: Moment
  endDate?: Moment
  transactionStatuses?: string
  searchType?: string
  sortBy?: string
  searchTerm?: string
  currentPage: number
  pageLimit: number
}

type SetLoanAccountTransaction = {
  type: typeof PayTransactionsActions.SET_TRANSACTION
  transaction: LoanAccountTransaction
}

type SetLoanAccountIsLoading = {
  type: typeof PayTransactionsActions.SET_IS_LOADING
  isLoading: boolean
}

// Recipients Types

export type RecipientFiltervalues = {
  searchTerm?: string
  searchBy?: string
  currentPage: number
  pageLimit: number
}

export type RecipientFilterOptions = {
  searchTypes: Array<FilterOption>
}

export type Recipient = {
  recipientName: string
  bankAccount: {
    bankName: string
    bankCode: number
    bankSite: string
  }
  email: string
  description: string
}

// Refactor below for generalized transactions state when working on filters

export type ActionTypes = SetLoanAccountTransaction | SetLoanAccountIsLoading
