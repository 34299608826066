import { SVGProps } from 'react'

function SvgEditPin(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.78 13.73H4.91a4 4 0 0 1-4-4V6.42a4 4 0 0 1 4-4h12.35a4 4 0 0 1 4 3.76.76.76 0 0 1-.7.8.77.77 0 0 1-.8-.71 2.49 2.49 0 0 0-2.49-2.35H4.91a2.5 2.5 0 0 0-2.5 2.5v3.31a2.5 2.5 0 0 0 2.5 2.5h6.87a.75.75 0 0 1 0 1.5Z"
        fill="currentColor"
      />
      <path
        d="M21.939 8.28a2.64 2.64 0 0 0-1.55-.49 3 3 0 0 0-2.07.87l-8.39 8.39a2.65 2.65 0 0 0-.68 1.12l-.91 2.86a1.19 1.19 0 0 0 .3 1.2 1.158 1.158 0 0 0 .84.35c.123-.008.245-.035.36-.08l2.86-.9c.42-.134.8-.368 1.11-.68l8.48-8.42a2.73 2.73 0 0 0 .79-2.17 2.77 2.77 0 0 0-1.14-2.05Zm-9.19 11.6c-.14.141-.311.247-.5.31l-2.3.73.73-2.3a1.23 1.23 0 0 1 .31-.51l6.9-6.9 1.77 1.77-6.91 6.9Zm8.48-8.47-.51.5-1.72-1.76.43-.43a1.44 1.44 0 0 1 1-.43c.24-.002.474.072.67.21a1.251 1.251 0 0 1 .17 1.91h-.04ZM6.31 9.25a1.17 1.17 0 1 0 0-2.34 1.17 1.17 0 0 0 0 2.34ZM10.99 9.25a1.17 1.17 0 1 0 0-2.34 1.17 1.17 0 0 0 0 2.34ZM15.68 9.25a1.17 1.17 0 1 0 0-2.34 1.17 1.17 0 0 0 0 2.34Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgEditPin
