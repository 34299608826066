import {
  NavDashboard as NavDashboardIcon,
  DeveloperTool as DeveloperToolIcon,
  Employee as TeamIcon,
  Invoice as InvoiceIcon,
  Flag as GetStartedIcon,
  CreditCard as CreditCardIcon,
  Filter as SettingIcon,
  Faq as HelpIcon,
  Payout as PayoutFormIcon,
  Earn as EarnIcon,
  Loan as LoanIcon,
  BankFilled as Accounts,
  Receive as ReceiveIcon,
  NavHyperLink as NavIntegrationIcon,
  Request as RequestIcon,
  SendInvitation as SendInvitationIcon,
  NavTransactions as NavTransactionsIcon,
} from '@fazz/design-system-icons'
import type { BadgeVariantType } from '@fazz/design-system/src/components/atoms/Badge/Badge.types'
import { Link } from '~/hooks/useReactRouter'
import { type SidebarLabels } from '~/types'

// Returns the Iicon for feature. Improvement would be to have typesafety between routes and featureName.
const getMenuItemIcon = (featureName?: SidebarLabels) => {
  switch (featureName) {
    case '[non-prod] Get Started':
      return <GetStartedIcon />
    case 'Home':
      return <NavDashboardIcon />
    case 'Pay':
      return <SendInvitationIcon />
    case 'Developer Tools':
      return <DeveloperToolIcon />
    case 'Team':
      return <TeamIcon />
    case 'Payment Link':
      return <InvoiceIcon />
    case 'Settings':
      return <SettingIcon />
    case 'FAQ':
      return <HelpIcon />
    case 'Payout Form':
      return <PayoutFormIcon />
    case 'Earn':
      return <EarnIcon />
    case 'Cards':
      return <CreditCardIcon />
    case '[non-prod] Internal Tools':
      return <DeveloperToolIcon />
    case 'Accounts':
      return <Accounts />
    case 'Loans':
      return <LoanIcon />
    case 'Receive':
      return <ReceiveIcon />
    case 'Request a Feature':
      return <RequestIcon />
    case 'Payments API':
      return <NavTransactionsIcon />
    case 'Integrations':
      return <NavIntegrationIcon />
    default:
      return undefined
  }
}
// Renders menu item as a Link for accessibility
const renderSidebarLink = ({
  externalLink,
  link,
  label,
}: {
  externalLink: boolean
  link: string
  label: string
}) => {
  if (externalLink) {
    return (
      <Link to={{ pathname: link }} target="_blank">
        {label}
      </Link>
    )
  }

  return <Link to={link}>{label}</Link>
}

// Update this to display badges on desired menu item
const showBadge = (
  featureName?: SidebarLabels
): { label: string; variant: BadgeVariantType } | undefined => {
  switch (featureName) {
    case 'Integrations':
    case 'Cards':
      return { label: 'New', variant: 'information' }
    default:
      return undefined
  }
}

export { getMenuItemIcon, showBadge, renderSidebarLink }
