import { css } from 'emotion'
import 'antd/es/tooltip/style/css'
import AntdTooltip from 'antd/lib/tooltip'
import styled from '@emotion/styled'
import { DESKTOP_BREAKPOINT } from '../../../themes/deprecated/breakPoints'

export type TooltipPlacement =
  | 'top'
  | 'left'
  | 'right'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'leftTop'
  | 'leftBottom'
  | 'rightTop'
  | 'rightBottom'

type RenderFunction = () => React.ReactNode

type Props = {
  children: React.ReactElement
  title: React.ReactNode | RenderFunction
  footer?: React.ReactNode | RenderFunction
  placement?: TooltipPlacement
  overlayClassName?: string
  mouseEnterDelay?: number
  mouseLeaveDelay?: number
  zIndex?: number
}

const defaultOverlayStyle = css`
  box-shadow: none;
  max-width: 200px;

  & .ant-tooltip-inner {
    border-radius: 5px;
    box-shadow: 0px 4px 15px rgba(149, 149, 149, 0.25);
    transform: translateX(10px);
    background-color: rgba(0, 0, 0, 0.95);
  }

  ${DESKTOP_BREAKPOINT} {
    max-width: 260px;
  }
`

const Container = styled.div`
  padding: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    color: #ffffff;
    margin-bottom: 10px;
  }
`

const FooterContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  a {
    margin-left: 20px;
  }
`

export default function Tooltip({
  children,
  placement = 'bottomRight',
  overlayClassName = defaultOverlayStyle,
  title,
  footer,
  mouseEnterDelay = 0,
  mouseLeaveDelay = 0,
  ...rest
}: Props) {
  return (
    <AntdTooltip
      overlayClassName={overlayClassName}
      arrowPointAtCenter
      placement={placement}
      title={
        <Container>
          {title}
          {footer && <FooterContainer>{footer}</FooterContainer>}
        </Container>
      }
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      {...rest}
    >
      {children}
    </AntdTooltip>
  )
}
