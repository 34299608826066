import React from 'react'
import { Breadcrumb, Col, Row } from 'antd'
import 'antd/es/breadcrumb/style/css'
import styled from '@emotion/styled'
import { useTheme } from '../../../themes/deprecated/themeContext'
import Link from '../Link'
import { Title } from '../Typography'

type Item = {
  breadcrumbName: string
  path?: string
}
interface CustomBreadcrumbBarProps extends React.HTMLAttributes<HTMLElement> {
  items: Item[]
  title: string
  itemRender?: (path: string, breadcrumbName: string) => React.ReactNode
}

function BreadcrumbBar({ items, title, children, itemRender, ...props }: CustomBreadcrumbBarProps) {
  const { base } = useTheme()

  const StyledBreadcrumbBar = styled(Row)`
    min-height: 92px;
    width: 100%;
    padding: 20px 50px;
    background-color: #fff;
  `

  const CustomLink = styled(Link)<{
    active: boolean
  }>`
    font-size: 14px;
    line-height: 17px;
    color: ${(p) => (p.active ? `${base.primary}!important` : '#000!important')};
    cursor: ${(p) => (p.active ? 'pointer' : 'auto')};
    :hover {
      text-decoration: ${(p) => (p.active ? 'underline' : 'none')};
      pointer-event: ${(p) => (p.active ? 'auto' : 'none')};
    }
  `

  return (
    <StyledBreadcrumbBar {...props}>
      <Col xs={0} md={24}>
        <Breadcrumb>
          {items &&
            items.map(({ breadcrumbName, path }) => (
              <Breadcrumb.Item key={`breadcrumb-item-${breadcrumbName}`}>
                {path && itemRender ? (
                  itemRender(breadcrumbName, path)
                ) : (
                  <CustomLink active={!!path} href={path}>
                    {breadcrumbName}
                  </CustomLink>
                )}
              </Breadcrumb.Item>
            ))}
        </Breadcrumb>
      </Col>
      <Title style={{ marginTop: '5px' }}>{title}</Title>
      {children}
    </StyledBreadcrumbBar>
  )
}

export default BreadcrumbBar
