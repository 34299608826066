import type { Dispatch } from '~/biz/store/types'
import type { ActionTypes } from './types'
import { OnboardingFlowActions } from './types'

export const toogleProductTourVisibility =
  (status: boolean) => (dispatch: Dispatch<ActionTypes>) => {
    dispatch({
      type: OnboardingFlowActions.SET_PRODUCT_TOUR_VISIBILITY,
      isProductTourShowing: status,
    })
  }

export const toogleProductTourActive = (status: boolean) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: OnboardingFlowActions.SET_PRODUCT_TOUR_ACTIVE_STATUS,
    isProductTourActive: status,
  })
}

export const setStepIndex = (index: number) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: OnboardingFlowActions.SET_PRODUCT_TOUR_STEP_INDEX,
    stepIndex: index,
  })
}

export const setProductTourId = (id: string) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: OnboardingFlowActions.SET_PRODUCT_TOUR_ID,
    productTourId: id,
  })
}
