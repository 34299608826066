import { BusinessRegistrationCountry } from '~/types'
import indonesiaFlag from '~/assets/regionSelectorIndonesiaFlag.svg'
import singaporeFlag from '~/assets/regionSelectorSingaporeFlag.svg'

export interface Country {
  name: string
  flag: string
  code: BusinessRegistrationCountry
}

export const countries: Country[] = [
  { code: BusinessRegistrationCountry.INDONESIA, name: 'Indonesia', flag: indonesiaFlag },
  { code: BusinessRegistrationCountry.SINGAPORE, name: 'Singapore', flag: singaporeFlag },
]
