import type { Reducer } from 'redux'
import type { TeamMember, ActionTypes } from './types'
import { TeamActions } from './types'

const initialState = {
  team: [],
}

type State = {
  team: TeamMember[]
}

export const team: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case TeamActions.SET_TEAM:
      return {
        ...state,
        team: action.team,
      }
    default:
      return state
  }
}
