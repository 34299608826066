import type { WALLET_TYPES } from '~/types'

export type Wallet = {
  type: WALLET_TYPES
  balance: string
  label: string
  accountType: string
  accountId: number
}

export type Account = {
  attributes: {
    wallet_id: number
    limitations: Record<string, string>
    type: string
  }
}

export type BankAccount = {
  bankName: string
  accountNumber: string
  id: number
  status: 'unverified' | 'verified' | 'pending' | 'rejected'
}

export type TransferInfo = {
  accountHolderName: string
  recipientName: string
  recipientAccountNo: string
  referenceNumber?: string
  expiredDate?: string
  depositAmount?: string
  userBankAccount: string
  bankNameFull: string
}

export type CopyTextCountry = {
  sg: CopyTextViewType
}

export type CopyTextViewType = {
  walletForm: WalletFormCopyText
}

export type WalletFormCopyText = {
  title: string
  subtitle: string
  selectTitle: string
  placeholder: string
}

export type DepositMethod = 'bankTransfer' | 'virtualAccount' | undefined

export enum DepositActions {
  SET_IS_LOADING = 'DepositActions.SET_IS_LOADING',
  SET_WALLETS = 'DepositActions.SET_WALLETS',
  SET_BANK_ACCOUNTS = 'DepositActions.SET_BANK_ACCOUNTS',
  SET_DESTINATION = 'DepositActions.SET_DESTINATION',
  SET_DESTINATION_AMOUNT = 'DepositActions.SET_DESTINATION_AMOUNT',
  SET_TRANSFER_INFO = 'DepositActions.SET_TRANSFER_INFO',
  SET_UNIQUE_AMT_PAYMENT_INSTRUCTIONS = 'SET_UNIQUE_AMT_PAYMENT_INSTRUCTIONS',
  SET_ACCOUNTS = 'DepositActions.SET_ACCOUNTS',
  SET_IS_MERCHANT_FIAT_DEPOSIT_ENABLED = 'DepositActions.SET_IS_MERCHANT_FIAT_DEPOSIT_ENABLED',
  SET_DEPOSIT_METHOD = 'DepositActions.SET_DEPOSIT_METHOD',
  SET_INDO_VIRTUAL_ACCOUNTS = 'DepositActions.SET_INDO_VIRTUAL_ACCOUNTS',
  SET_SELECTED_INDO_VIRTUAL_ACCOUNT = 'DepositActions.SET_SELECTED_INDO_VIRTUAL_ACCOUNT',
}

type SetIsLoading = {
  type: typeof DepositActions.SET_IS_LOADING
  isLoading: boolean
}

type SetWallets = {
  type: typeof DepositActions.SET_WALLETS
  wallets: Wallet[]
}

type SetBankAccounts = {
  type: typeof DepositActions.SET_BANK_ACCOUNTS
  bankAccounts: BankAccount[]
}

type SetDestination = {
  type: typeof DepositActions.SET_DESTINATION
  destination: number | undefined
}

type SetDestinationAmount = {
  type: typeof DepositActions.SET_DESTINATION_AMOUNT
  destinationAmount: string
}

type SetTransferInfo = {
  type: typeof DepositActions.SET_TRANSFER_INFO
  transferInfo: TransferInfo
}

type SetAccounts = {
  type: typeof DepositActions.SET_ACCOUNTS
  accounts: { [key: string]: Account }
}

type SetDepositMethod = {
  type: typeof DepositActions.SET_DEPOSIT_METHOD
  depositMethod: DepositMethod
}

export type ActionTypes =
  | SetIsLoading
  | SetWallets
  | SetBankAccounts
  | SetDestination
  | SetDestinationAmount
  | SetTransferInfo
  | SetAccounts
  | SetDepositMethod
