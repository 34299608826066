import * as React from 'react'

function SvgUniswap(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#uniswap_svg__clip0)" fill="#FF007A">
        <path d="M15.43 9.501c-.336-.052-.35-.058-.192-.082.304-.047 1.02.017 1.515.134 1.153.274 2.203.977 3.323 2.224l.298.331.425-.068c1.794-.288 3.619-.06 5.145.646.42.194 1.082.58 1.165.68.026.03.074.234.107.451.113.752.057 1.328-.173 1.758-.125.234-.132.308-.048.509.067.16.254.278.44.278.379 0 .787-.614.976-1.467l.075-.339.15.169c.816.925 1.457 2.186 1.567 3.084l.029.234-.138-.213c-.236-.366-.473-.615-.777-.816-.548-.363-1.127-.486-2.66-.567-1.386-.073-2.17-.191-2.948-.445-1.322-.431-1.99-1.005-3.56-3.067-.698-.915-1.13-1.422-1.559-1.83-.975-.927-1.933-1.413-3.16-1.604z" />
        <path d="M27.422 11.546c.035-.614.118-1.019.285-1.388.066-.147.128-.266.138-.266.01 0-.02.108-.064.24-.122.358-.141.849-.058 1.42.107.724.167.828.933 1.61.359.368.776.83.928 1.03l.275.36-.276-.258c-.336-.316-1.11-.931-1.281-1.02-.115-.058-.132-.057-.203.013-.065.065-.079.162-.088.623-.014.718-.112 1.18-.348 1.64-.128.25-.148.196-.032-.085.086-.21.095-.302.094-.998-.001-1.397-.167-1.732-1.14-2.308a10.675 10.675 0 00-.902-.467c-.25-.111-.448-.208-.44-.215.027-.027.975.25 1.357.396.568.219.662.247.73.22.047-.017.07-.152.092-.547zM16.086 13.936c-.684-.941-1.106-2.384-1.015-3.463l.028-.333.156.028c.292.053.796.24 1.032.384.647.393.927.91 1.212 2.24.084.39.193.83.244.979.081.24.388.8.637 1.165.18.262.06.386-.337.35-.606-.054-1.427-.62-1.957-1.35zM26.593 20.938c-3.195-1.286-4.32-2.402-4.32-4.286 0-.277.01-.504.022-.504.011 0 .135.092.274.204.648.52 1.373.741 3.381 1.034 1.182.172 1.847.312 2.46.515 1.95.647 3.156 1.959 3.443 3.746.084.52.035 1.493-.1 2.006-.108.406-.434 1.136-.52 1.164-.025.008-.048-.084-.054-.209-.033-.671-.372-1.324-.942-1.814-.647-.556-1.517-1-3.644-1.856zM24.351 21.473a5.672 5.672 0 00-.154-.675l-.081-.243.151.17c.21.235.375.536.515.936.108.306.12.397.119.894-.001.488-.014.59-.113.866-.156.434-.35.742-.675 1.072-.583.594-1.333.923-2.416 1.06-.188.023-.736.063-1.218.088-1.215.063-2.015.194-2.734.446-.103.036-.195.058-.205.049-.029-.03.46-.32.865-.515.57-.275 1.137-.425 2.407-.636.628-.105 1.277-.232 1.441-.282 1.555-.477 2.354-1.707 2.098-3.23z" />
        <path d="M25.815 24.073c-.425-.912-.522-1.793-.29-2.615.025-.087.065-.16.09-.16.023 0 .123.055.222.121.195.132.588.354 1.633.923 1.303.711 2.047 1.262 2.553 1.89.442.551.716 1.179.848 1.944.075.433.031 1.476-.08 1.912-.35 1.376-1.166 2.456-2.328 3.087-.17.092-.323.168-.34.169-.016 0 .046-.158.139-.351.39-.817.435-1.612.14-2.497-.182-.542-.55-1.203-1.296-2.32-.866-1.3-1.078-1.645-1.291-2.103zM13.818 28.996c1.185-1.001 2.66-1.712 4.003-1.93.58-.094 1.544-.057 2.08.08.86.22 1.628.713 2.028 1.3.39.573.558 1.073.733 2.185.069.439.143.88.166.98.131.576.386 1.036.702 1.268.502.367 1.367.39 2.217.058.144-.056.27-.095.279-.086.03.03-.398.317-.7.468-.406.203-.73.281-1.16.281-.778 0-1.425-.396-1.964-1.204-.106-.159-.345-.635-.53-1.058-.57-1.299-.851-1.695-1.513-2.128-.575-.377-1.318-.444-1.876-.17-.734.359-.938 1.297-.413 1.89.209.237.598.44.917.48a.979.979 0 001.107-.98c0-.391-.15-.614-.528-.784-.516-.233-1.07.039-1.068.524.001.206.091.336.299.43.133.06.136.065.027.042-.473-.098-.584-.669-.203-1.048.457-.455 1.402-.254 1.726.367.137.261.153.78.034 1.094-.266.702-1.042 1.072-1.83.87-.535-.136-.753-.284-1.4-.95-1.122-1.156-1.558-1.38-3.176-1.633l-.31-.048.353-.298z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.353 3.092c3.749 4.55 9.529 11.634 9.816 12.031.236.328.147.622-.259.853-.225.128-.69.258-.922.258-.262 0-.558-.127-.774-.331-.152-.145-.768-1.063-2.187-3.266a240.094 240.094 0 00-2.02-3.107c-.058-.054-.057-.052 1.91 3.465 1.233 2.209 1.65 2.99 1.65 3.094 0 .212-.058.324-.32.616-.437.487-.632 1.035-.773 2.168-.158 1.27-.602 2.167-1.833 3.703-.721.899-.84 1.064-1.021 1.426-.23.456-.292.712-.318 1.288a4.01 4.01 0 00.212 1.585c.163.51.333.847.768 1.52.375.581.591 1.013.591 1.182 0 .134.026.135.607.003 1.391-.314 2.521-.866 3.157-1.544.393-.419.485-.65.488-1.225.002-.375-.011-.454-.113-.67-.165-.352-.466-.645-1.13-1.098-.868-.594-1.24-1.072-1.342-1.73-.084-.54.013-.921.494-1.929.498-1.043.621-1.487.705-2.539.053-.68.128-.947.323-1.162.204-.224.387-.3.89-.369.822-.112 1.345-.325 1.775-.72.373-.344.529-.676.553-1.174l.018-.378-.208-.243C15.335 13.92 4.848 2.4 4.8 2.4c-.01 0 .238.312.552.692zm4.944 22.887a.668.668 0 00-.206-.88c-.27-.18-.688-.095-.688.139 0 .071.039.123.128.169.15.077.16.163.043.34-.12.18-.11.337.027.444.22.173.532.078.696-.212zM16.818 17.513c-.385.118-.76.527-.876.955-.071.262-.03.72.075.862.171.228.337.289.785.285.878-.006 1.642-.382 1.73-.853.073-.386-.262-.92-.724-1.155-.238-.122-.745-.17-.99-.094zm1.026.802c.136-.192.077-.4-.154-.54-.438-.268-1.101-.047-1.101.367 0 .207.346.432.663.432.21 0 .5-.126.593-.259z"
        />
      </g>
      <defs>
        <clipPath id="uniswap_svg__clip0">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgUniswap
