export type MerchantIdFlippers = {
  /** @deprecated move to featureGate types.ts instead */
  xfpay_disable_withdraw: boolean
  /** @deprecated move to featureGate types.ts instead */
  xfpay_disable_disbursements: boolean
  /** @deprecated move to featureGate types.ts instead */
  xfpay_disable_xfers_accept: boolean
  /** @deprecated move to featureGate types.ts instead */
  xfpay_enable_xfers_send: boolean
  /** @deprecated move to featureGate types.ts instead */
  xfpay_disable_payment_link_by_merchants: boolean
  /** @deprecated move to featureGate types.ts instead */
  fazzbiz_enable_loan_account: boolean
  /** @deprecated move to featureGate types.ts instead */
  fazzbiz_debitcard_phase3_enabled: boolean
  /** @deprecated move to featureGate types.ts instead */
  fazzbiz_id_cash_account_pdf_export_enabled: boolean
  // NOTE: Stop using merchantId for flipper actor identifier, use bizId instead.
  // - MerchantId is a legacy identifier which might overlap between realms.
  //   So when you for example set whitelist for merchantId=1 thinking that it's meant for ID only,
  //   when you use it to enable some feature in a shared component between ID & SG,
  //   or when it's used in parallel feature between ID & SG,
  //   it would actually be enabled for SG merchant with merchantId=1 too.
  // - Meanwhile, bizId is a UUID (universally unique identifier) which won't have this issue.
  // - We are going to refactor these items, so we are expecting it to be less and less until we completely remove all.
  //   There are 14 items now, the number should not be growing ya!!
}

export type EmailIdFlippers = {
  /** @deprecated move to featureGate types.ts instead */
  xfregional_enable_credit_cards_production: boolean
  /** @deprecated move to featureGate types.ts instead */
  fazzbiz_enable_usd_account: boolean
  /** @deprecated move to featureGate types.ts instead */
  fazzbiz_enable_test_financing_management_by_email: boolean
  /** @deprecated move to featureGate types.ts instead */
  fazzbiz_enable_bss_va_fp3: boolean
  /** @deprecated move to featureGate types.ts instead */
  direct_fast_va_adaption: boolean
  /** @deprecated move to featureGate types.ts instead */
  fazzbiz_enable_new_loan_dashboard: boolean
  /** @deprecated move to featureGate types.ts instead */
  fazzbiz_id_test_whitelist_email_loan_self_serve: boolean
  // NOTE: For new item, please use format: `fazzbiz_{id/sg/-}_{test/-}_{whitelist/blacklist}-email_{featureName}`
  //       e.g. fazzbiz_blacklist-email_teamPage, fazzbiz_id_test_whitelist-email_xIDRExchange
  // NOTE: Are you sure email is a proper identifier for your use case? Most likely should use bizId instead.
  // - There might be multiple user email associated to one merchant,
  //   and normally we want to enable some feature for selected merchant instead of selected user.
  //   So please use bizId so that the feature is accessible to all user under that merchant.
  // - You may prefer to use email for testing use case, to quickly whitelist some test account, which is for temporary use case.
  //   In this case, please make the naming explicit with format: `fazzbiz_{id/sg/-}_test_whitelist-email_{featureName}`
  //   e.g.: fazzbiz_id_test_whitelist-email_cursorPaginationInDisbursements
  //   and immediately make follow up task to do cleanup after the feature is fully released to all user
  //   (remove implementation in code base and flipper item itself in admin panel).
  // - If there is special use case where it is necessary to add flipper with email whitelist for permanent use production user,
  //   please elaborate in PR. Otherwise, reviewer should help notice and request change.
}

export type BizIDflippers = {
  /** @deprecated move to featureGate types.ts instead */
  fazzbiz_enable_flex_pay_account: boolean
  /** @deprecated move to featureGate types.ts instead */
  fazzbiz_whitelist_rbac133_id_legacy: boolean
  /** @deprecated move to featureGate types.ts instead */
  fazzbiz_enable_api_menu: boolean
  /** @deprecated move to featureGate types.ts instead */
  fazzbiz_is_wallex_account_verified: boolean
  /** @deprecated move to featureGate types.ts instead */
  fazzbiz_sg_enable_usd_transfer_out: boolean
  fazzbiz_whitelist_disable_sgd_account_details: boolean
  fazzbiz_whitelist_enable_sandbox_pay_menu: boolean
  // NOTE: For new item, please use format: `fazzbiz_{id/sg/-}_{whitelist/blacklist}-bizId_{featureName}`
  // e.g. fazzbiz_whitelist-bizId_cryptoExchange, fazzbiz_id_blacklist-bizId_xIDRExchange, fazzbiz_sg_whitelist-bizId_xSGDExchange
}

export type EnvMerchantIdFlippers = {
  /** @deprecated move to featureGate types.ts instead */
  enable_merchants_with_high_nesting_risk: boolean
  /** @deprecated move to featureGate types.ts instead */
  enable_v3_for_morak_merchants: boolean
}

export type InitialState = {
  merchantId: MerchantIdFlippers
  emailId: EmailIdFlippers
  bizId: BizIDflippers
  envFlipperId: EnvMerchantIdFlippers
  isFlipperInitiated: boolean
}

export enum GetFlipperActions {
  SET_EMAIL_FLIPPERS = 'GetFlipperActions.SET_EMAIL_FLIPPERS',
  SET_MERCHANT_ID_FLIPPERS = 'GetFlipperActions.SET_MERCHANT_ID_FLIPPERS',
  SET_BIZ_ID_FLIPPERS = 'GetFlipperActions.SET_BIZ_ID_FLIPPERS',
  SET_ENV_FLIPPERS = 'GetFlipperActions.SET_ENV_FLIPPERS',
  SET_FLIPPER_LOADING = 'GetFlipperActions.SET_FLIPPER_LOADING',
  SET_IS_FLIPPER_INITIATED = 'GetFlipperActions.SET_IS_FLIPPER_INITIATED ',
}

type SetEmailFlippers = {
  type: typeof GetFlipperActions.SET_EMAIL_FLIPPERS
  emailIdFlippers: EmailIdFlippers
}

type SetMerchantIDflippers = {
  type: typeof GetFlipperActions.SET_MERCHANT_ID_FLIPPERS
  merchantIdFlippers: MerchantIdFlippers
}

type SetBizIDflippers = {
  type: typeof GetFlipperActions.SET_BIZ_ID_FLIPPERS
  BizIDflippers: BizIDflippers
}

type SetEnvFlippers = {
  type: typeof GetFlipperActions.SET_ENV_FLIPPERS
  Envflippers: EnvMerchantIdFlippers
}

type SetFlippersLoading = {
  type: typeof GetFlipperActions.SET_FLIPPER_LOADING
  isFlipperLoading: boolean
}

type SetIsFlipperInitiated = {
  type: typeof GetFlipperActions.SET_IS_FLIPPER_INITIATED
  isFlipperInitiated: boolean
}

export type ActionTypes =
  | SetEmailFlippers
  | SetMerchantIDflippers
  | SetBizIDflippers
  | SetEnvFlippers
  | SetFlippersLoading
  | SetIsFlipperInitiated
