import { SVGProps } from 'react'

function SvgUnlockFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.6a4 4 0 0 0-4 4v3.2H5.6a2.4 2.4 0 0 0-2.4 2.4V20a2.4 2.4 0 0 0 2.4 2.4h12.8a2.4 2.4 0 0 0 2.4-2.4v-8.8a2.4 2.4 0 0 0-2.4-2.4h-4.8V6.486l2.4.18V5.6a4 4 0 0 0-4-4Zm1.6 7.2V5.6a1.6 1.6 0 0 0-3.2 0v3.2h3.2Zm-4.4 2.4H5.6V20h12.8v-8.8H9.2Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgUnlockFilled
