import type { Reducer } from 'redux'
import type { ActionTypes, State } from './types'
import { BusinessVerificationActions } from './types'

const initialState: State = {
  initialized: false,
  currentPath: undefined,
  verificationStatus: undefined,
  managerVerificationStatus: undefined,
  isManagerDirector: undefined,
  businessInformation: {
    businessType: undefined,
    businessName: undefined,
    companyDisplayname: undefined,
    businessRegistrationNumber: undefined,
    businessLicenseExpiryDate: undefined,
    businessContactNo: undefined,
    businessDateOfIncorporation: undefined,
    countryOfIncorporation: undefined,
    businessAddressLine1: undefined,
    businessAddressLine2: undefined,
    businessPostalCode: undefined,
    country: undefined,
    city: undefined,
    tinNpwp: undefined,
  },
  businessRelation: {
    methods: [],
  },
  businessActivity: {
    countriesOfActivities: [],
    businessWebsite: undefined,
    businessCategory: undefined,
    businessSubcategory: undefined,
    businessActivitySummary: undefined,
    expectedTransactionVolume: undefined,
    expectedValuePerTransaction: undefined,
    expectedGtv: undefined,
    sourceOfWealth: undefined,
    sourceOfFunds: undefined,
    sourceOfGoods: undefined,
  },
  uploadDocument: {
    businessLicenseUrl: undefined,
    deedOfEstablishmentUrl: undefined,
    tinNpwpUrl: undefined,
    bankAccountProofUrl: undefined,
    powerOfAttorneyUrl: undefined,
    businessLicenseSignedId: undefined,
    deedOfEstablishmentSignedId: undefined,
    tinNpwpSignedId: undefined,
    bankAccountProofSignedId: undefined,
    powerOfAttorneySignedId: undefined,
  },
}

export const businessVerification: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case BusinessVerificationActions.SAVE_BUSINESS_DATA: {
      return {
        ...state,
        ...action.data,
        initialized: true,
      }
    }

    case BusinessVerificationActions.SAVE_BUSINESS_DATA_NOT_INITIALIZED: {
      return {
        ...state,
        ...action.data,
        initialized: false,
      }
    }

    case BusinessVerificationActions.SET_BUSINESS_DATA: {
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          [action.key]: action.value,
        },
      }
    }

    case BusinessVerificationActions.SET_CURRENT_PATH: {
      return {
        ...state,
        currentPath: action.path,
      }
    }

    default:
      return state
  }
}
