import type { Reducer } from 'redux'
import type { ActionTypes, TransactionDetails } from './types'
import { TransactionDetailActions } from './types'

const initialState = {
  isLoading: false,
  transactionId: '',
  transactionDetails: {
    key: '',
    transactionId: '',
    transactionType: '',
    transactionHash: undefined,
    transactionHashUrl: undefined,
    walletType: '',
    method: '',
    accountHolderName: '',
    amount: {
      prefix: '',
      postfix: '',
    },
    fee: '',
    tax: '',
    netAmount: {
      prefix: '',
      postfix: '',
    },
    createdDate: '',
    completedDate: '',
    status: '',
    note: undefined,
    sourceDestination: undefined,
    merchantDescription: undefined,
    failureReason: undefined,
    manualAssignments: undefined,
    bankAccountNo: undefined,
    bankName: undefined,
    description: undefined,
    remarks: '',
    refId: '',
    cardHolderName: '',
    cardNumber: '',
    merchant: '',
    details: {
      method: '',
      customerProfile: {
        senderName: '',
      },
      receiver: {
        accountHolderName: '',
        bankType: '',
        bankAccountNo: '',
        endToEndId: '',
      },
      sender: {
        accountHolderName: '',
        bankType: '',
        bankAccountNo: '',
      },
      instructions: {
        proxyType: '',
        proxyValue: '',
      },
      externalPayment: {
        messageId: '',
        purposeCode: '',
      },
    },
  },
}

type State = {
  isLoading: boolean
  transactionId: string
  transactionDetails: TransactionDetails
}

export const transactionDetails: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case TransactionDetailActions.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }

    case TransactionDetailActions.GET_TRANSACTION_DETAILS: {
      return {
        ...state,
        transactionDetails: action.transactionDetails,
      }
    }

    default:
      return state
  }
}
