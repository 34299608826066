import { SVGProps } from 'react'

function SvgBankFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#BankFilled_svg__a)" fill="currentColor">
        <path d="m16.936 4.242-5-2.834a5 5 0 0 0-5 0L2.052 4.242a2.192 2.192 0 0 0 .933 4.091l-.001 9.234H1.859a.625.625 0 0 0 0 1.25h9.116a.625.625 0 1 0 0-1.25h-3.8l.002-9.234h4.633v3.45a.625.625 0 1 0 1.25 0v-3.45h1.667v2.275a.625.625 0 1 0 1.25 0V8.333a2.192 2.192 0 0 0 .959-4.091ZM5.926 17.575H4.258l.001-9.242h1.667l-.002 9.242ZM16.76 6.4a.925.925 0 0 1-.833.7H3.069a.925.925 0 0 1-.834-.7.917.917 0 0 1 .434-1.075l5-2.833a3.759 3.759 0 0 1 3.717 0l5 2.833a.917.917 0 0 1 .383 1.067l-.009.008Z" />
        <path d="M10.477 5.283H8.52a.625.625 0 1 1 0-1.25h1.959a.625.625 0 1 1 0 1.25ZM15.693 12.5a3.334 3.334 0 1 0 3.374 3.333 3.375 3.375 0 0 0-3.374-3.333Zm.832 4.642a1.05 1.05 0 0 1-.625.291v.475l-.417.084V17.5c-.14-.004-.279-.02-.416-.05a2.5 2.5 0 0 1-.4-.133l.083-.475c.25.045.504.07.759.075.333 0 .5-.1.5-.284a.19.19 0 0 0-.075-.158 1.367 1.367 0 0 0-.284-.15l-.133-.075-.216-.108a1.525 1.525 0 0 1-.509-.384.833.833 0 0 1-.158-.508.835.835 0 0 1 .233-.6 1 1 0 0 1 .625-.292v-.466l.409-.084v.534c.213.019.422.075.616.166l-.083.475a4.316 4.316 0 0 0-.633-.05.542.542 0 0 0-.309.075.251.251 0 0 0-.116.217.217.217 0 0 0 .083.167c.076.063.16.116.25.158l.233.125c.16.075.314.165.459.267a.914.914 0 0 1 .258.283.733.733 0 0 1 .091.375.833.833 0 0 1-.225.55v-.008Z" />
      </g>
      <defs>
        <clipPath id="BankFilled_svg__a">
          <path fill="#fff" transform="translate(.003) skewX(-.01)" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgBankFilled
