import cookie from 'js-cookie'
import { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import styled from '@emotion/styled'
import { BannerFds3, Dialog, Typography, fdsTheme } from '@fazz/design-system'
import useCountdown from '~/hooks/useCountdown'
import useReminder from '~/hooks/useReminder'
import { usePostExtendSession } from '~/api/common/common.hooks'
import hourglass from '~/assets/hourglass.png'
import roadblock from '~/assets/roadblock.png'
import SessionUtils from '~/utils/SessionUtils'

const { spacing } = fdsTheme
const { Body } = Typography

export default function SessionExpiryDialog() {
  const { t: i18nTranslation } = useTranslation('feature/sessions/sessionsExpiry')
  const [showDialog, setShowDialog] = useState(false)
  const { counter, startTimer, pauseTimer, resetTimer } = useCountdown(60, () =>
    SessionUtils.removeSessionCookies()
  )
  const [remainingTime, setRemainingTime] = useState<number>(0)

  const [sessionExpiryTs, setSessionExpiryTs] = useState<number>(
    new Date(cookie.get('SESSIONID') as string).getTime()
  )

  const { setReminder } = useReminder(() => {
    const latestSessionExpiryTs = new Date(cookie.get('SESSIONID') as string).getTime()
    if (latestSessionExpiryTs !== sessionExpiryTs) {
      const minus60Secs = latestSessionExpiryTs - 60 * 1000
      setSessionExpiryTs(latestSessionExpiryTs)
      setReminder(minus60Secs)
    } else {
      setShowDialog(true)
      startTimer()
    }
  })

  useEffect(() => {
    reset()
  }, [])

  useEffect(() => {
    if (showDialog) {
      const ts = Math.floor((sessionExpiryTs - new Date().getTime()) / 1000)

      // /**
      //   Uncomment the following for debugging:
      // */
      //   console.log(
      //     "now:", new Date().getTime(),
      //     "expired at:", sessionExpiryTs,
      //     "in-between:", Math.floor(
      //       (sessionExpiryTs - new Date().getTime()) / 1000
      //     ),
      //     "counter:", counter)
      setRemainingTime(ts)
    }
  }, [counter, showDialog])

  const reset = () => {
    const ts = new Date(cookie.get('SESSIONID') as string).getTime()
    const minus60Secs = ts - 60 * 1000
    setSessionExpiryTs(ts)
    setReminder(minus60Secs)
    pauseTimer()
    resetTimer()
    if (showDialog) {
      setShowDialog(false)
    }
  }

  const handleSuccess = () => {
    reset()
  }

  const handleError = () => {
    SessionUtils.ssoLogout()
  }

  const { mutate: handleExtendSesion } = usePostExtendSession({
    onSuccess: handleSuccess,
    onError: handleError,
  })

  if (remainingTime <= 0) {
    return (
      <Dialog
        size="lg"
        open={showDialog}
        isClosable={false}
        okText={i18nTranslation('SESSION_EXPIRED.ON_OKAY_TEXT')}
        title={i18nTranslation('SESSION_EXPIRED.TITLE')}
        footerButton="single"
        onOkClick={() => SessionUtils.ssoLogout()}
      >
        <Container>
          <ImageContainer>
            <img src={roadblock} alt="hourglass icon" height="100%" />
          </ImageContainer>
          <Body size="md">{i18nTranslation('SESSION_EXPIRED.DESCRIPTION')}</Body>
        </Container>
      </Dialog>
    )
  }
  return (
    <Dialog
      size="lg"
      open={showDialog}
      isClosable={false}
      cancelText={i18nTranslation('SESSION_ABOUT_TO_EXPIRE.ON_CANCEL_TEXT')}
      okText={i18nTranslation('SESSION_ABOUT_TO_EXPIRE.ON_OKAY_TEXT')}
      title={i18nTranslation('SESSION_ABOUT_TO_EXPIRE.TITLE')}
      onCancelClick={() => SessionUtils.ssoLogout()}
      onOkClick={() => handleExtendSesion()}
    >
      <Container>
        <ImageContainer>
          <img src={hourglass} alt="hourglass icon" height="100%" />
        </ImageContainer>
        <Body size="md">{i18nTranslation('SESSION_ABOUT_TO_EXPIRE.DESCRIPTION')}</Body>
        <BannerFds3
          message={
            <Trans
              i18nKey="SESSION_ABOUT_TO_EXPIRE.WARNING_BANNER_TEXT"
              ns="feature/sessions/sessionsExpiry"
              values={{ TIME_REMAINING_IN_SECONDS: remainingTime }}
              components={{ bold: <b /> }}
            />
          }
          variant="warning"
          isClosable={false}
          showLeftIcon
        />
      </Container>
    </Dialog>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.md};
`

const ImageContainer = styled.div`
  height: 164px;
  display: flex;
  align-items: center;
  justify-content: center;
`
