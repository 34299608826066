import { Breakpoints } from '../../helpers/constants'

export const EXTRA_LARGE_DESKTOP_BREAKPOINT = `@media (min-width: 1200px)`
export const DESKTOP_BREAKPOINT = `@media (min-width: ${Breakpoints.DESKTOP_WIDTH}px)`
export const LAPTOP_BREAKPOINT = `@media (min-width: 768px)`
export const TABLET_BREAKPOINT = `@media (min-width: 576px)`

export const MOBILE_EXTRA_LARGE_DESKTOP_BREAKPOINT = `@media (max-width: 1200px)`
export const MOBILE_DESKTOP_BREAKPOINT = `@media (max-width: 992px)`
export const MOBILE_LAPTOP_BREAKPOINT = `@media (max-width: 768px)`
export const MOBILE_TABLET_BREAKPOINT = `@media (max-width: 576px)`
