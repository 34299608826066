import { fdsTheme } from 'fds3-src/themes/theme-fds3'
import styled from 'styled-components'
import { Table } from 'antd'

// Retrieve all theming data
const { spacing, colors, rootPrimitives, fontStyles, radius } = fdsTheme

export const StyledTable = styled(Table)`
  .ant-table-thead {
    tr > th {
      ${fontStyles.labelMedium}
    }

    .ant-table-cell {
      background-color: ${rootPrimitives.cultured};
      border: none;
    }

    tr:first-child {
      th:first-child {
        border-top-left-radius: 0;
      }

      th:last-child {
        border-top-right-radius: 0;
      }
    }
  }

  .ant-table-tbody {
    ${fontStyles.bodyMedium}
    background-color: ${colors.surfaceNeutral};

    .ant-table-cell {
      border-bottom: 1px solid ${colors.line};
    }
    .ant-table-row-selected .ant-table-cell {
      background-color: ${colors.selected};
    }
  }

  .ant-table-cell {
    padding: ${spacing.sm};
  }

  .ant-table-selection-column {
    .ant-checkbox-indeterminate {
      .ant-checkbox-inner {
        background-color: ${colors.active};

        :after {
          background-color: ${colors.onContainer};
          width: 8px;
          height: 1.5px;
          border-radius: ${radius.xl};
        }
      }
    }

    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
      border-radius: ${spacing.xs};
    }

    .ant-checkbox-inner {
      border-color: ${colors.line};
      border-radius: ${spacing.xxs};
      border-width: 1.5px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${colors.active};
      border-color: ${colors.active};
    }

    .ant-checkbox-checked .ant-checkbox-inner:after {
      border-color: ${colors.onContainer};
    }

    &:hover {
      .ant-checkbox-inner {
        border-color: ${colors.active};
        box-shadow: ${`0 0 0 ${spacing.xxs} ${colors.hoveredSurface}`};
      }
    }

    &:focus {
      .ant-checkbox-inner {
        border-color: ${colors.line};
        box-shadow: ${`0 0 0 ${spacing.xxs} ${colors.focusedSurface}`};
      }
    }

    &:active {
      .ant-checkbox-inner {
        border-color: ${colors.line};
        box-shadow: ${`0 0 0 ${spacing.xxs} ${colors.pressedSurface}`};
      }
    }
  }
  .ant-spin-nested-loading {
    .ant-spin-dot-item {
      background-color: ${colors.primary};
    }
  }
` as typeof Table

export const EmptyDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${spacing.lg} 0;
  gap: ${spacing.md};
`
