export enum SGwalletTag {
  SGD_ACCOUNT = 'sgd_cash',
  CREDIT_LINE = 'sg_credit_line_supplier_transfer',
  TERM_LOAN = 'sg_term_loan',
}
export enum TransferSourceType {
  SGD_ACCOUNT = 'SGD Cash Account',
  CREDIT_LINE = 'Credit Line',
  TERM_LOAN = 'Term Loan',
}

export enum TransferSourceTypeURLQuery {
  CREDIT_LINE = 'credit-line',
  TERM_LOAN = 'term-loan',
}

export type Recipient = {
  id: string
  attributes: {
    companyName: string
    bankShortCode: string
    bankAccountHolder: string
    accountNo: string
    companyPicEmail: string
    description: string
    serviceName: string
    status: string
  }
}

export type WalletLedger = {
  id: string
  type: string
  attributes: {
    balance: string
    purpose: WalletLedgerPurpose
    serviceName?: string
  }
}

export enum WalletLedgerPurpose {
  PendingTransfer = 'PendingTransfer',
  Available = 'Available',
}

export type Wallet = {
  id: string
  type: string
  attributes: {
    tag: SGwalletTag
    description: string
  }
  relationships: {
    ledgers: Array<WalletLedger>
  }
}

export type PayTransferWalletDetails = {
  walletID?: string
  walletBalance?: string
}

export const transferSourceDropdownValue = new Map([
  [SGwalletTag.CREDIT_LINE, TransferSourceType.CREDIT_LINE],
  [SGwalletTag.SGD_ACCOUNT, TransferSourceType.SGD_ACCOUNT],
  [SGwalletTag.TERM_LOAN, TransferSourceType.TERM_LOAN],
])

export enum PayTransferActions {
  SET_WALLET_DETAILS = 'PayTransferActions.SET_WALLET_DETAILS',
  SET_SELECTED_RECIPIENT = 'PayTransferActions.SET_SELECTED_RECIPIENT',
  SET_ATTACHMENTS = 'PayTransferActions.SET_ATTACHMENTS',
  RESET_PAYOUTFORM = 'PayTransferActions.RESET_PAYOUTFORM',
}

type SetWalletID = {
  type: typeof PayTransferActions.SET_WALLET_DETAILS
  wallet?: PayTransferWalletDetails
}

type SetSelectedRecipient = {
  type: typeof PayTransferActions.SET_SELECTED_RECIPIENT
  selectedRecipient: Recipient
}
type SetAttachments = {
  type: typeof PayTransferActions.SET_ATTACHMENTS
  attachments: any
}

type ResetPayoutForm = {
  type: typeof PayTransferActions.RESET_PAYOUTFORM
}

export type ActionTypes = SetAttachments | SetSelectedRecipient | SetWalletID | ResetPayoutForm
