export const StagingHostNames = {
  ID_STAGING: 'id-biz-staging.internal.xfers.com',
  SG_STAGING: 'business-staging.internal.xfers.com',
  NEW_STAGING: 'app-staging.internal.fazz.com',
  ALPHA_DEV: 'app-dev-alpha.internal.fazz.com',
  BETA_DEV: 'app-dev-beta.internal.fazz.com',
  DELTA_DEV: 'app-dev-delta.internal.fazz.com',
  GAMMA_DEV: 'app-dev-gamma.internal.fazz.com',
}

export const LocalHostNames = {
  LOCAL: 'localhost',
}

export const ProductionHostNames = {
  PRODUCTION: 'app.fazz.com',
  BETA_PROD: 'app-beta-testing.fazz.com',
}
