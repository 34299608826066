import * as React from 'react'

function SvgMbb(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#FFC60A" d="M0 9h60v16H0z" />
      <path
        d="M36.093 16.632c-.198 0-.328.069-.526.069v2.435c.13.068.328.068.465.068.662 0 1.118-.464 1.118-1.316 0-.853-.395-1.256-1.057-1.256zm-.198 3.69c-.723 0-1.385-.129-2.04-.327v-6.452H35.5v2.168c.266-.068.593-.129.86-.129 1.445 0 2.374.791 2.374 2.176.06 1.773-.928 2.565-2.838 2.565zM42.155 18.086c-.92.069-1.582.13-1.582.662 0 .396.395.594.989.594.198 0 .396 0 .593-.069v-1.187zm-.525 2.176c-1.712 0-2.571-.525-2.571-1.514 0-1.187 1.514-1.514 3.165-1.582v-.198c0-.396-.327-.525-.86-.525-.532 0-1.057.129-1.583.266l-.395-.92a6.24 6.24 0 012.176-.396c1.514 0 2.237.525 2.237 1.712v2.77c-.723.319-1.446.448-2.169.387zM47.43 20.193v-2.967c0-.396-.197-.662-.79-.662-.199 0-.396.068-.594.13v3.492h-1.644V15.84a6.707 6.707 0 012.306-.396c1.712 0 2.503.594 2.503 1.583v3.165h-1.78zM53.16 20.193l-1.712-2.237v2.237h-1.651v-6.65h1.651v4.078l1.514-1.97h1.91l-1.712 2.107 1.841 2.435H53.16zM30.69 22.035h-1.712l.792-1.841-2.04-4.543h1.842l1.058 3.227 1.118-3.227h1.841l-2.899 6.384zM21.998 14.068H20.02l-1.256 3.82-1.316-3.82h-1.979l-.852 6.126H16.2l.525-4.216 1.514 4.216h1.058l1.445-4.216.525 4.216h1.583l-.852-6.126zM26.214 18.086c-.92.068-1.583.13-1.583.662 0 .396.396.593.99.593.197 0 .395 0 .593-.068v-1.187zm-.533 2.176c-1.712 0-2.572-.525-2.572-1.514 0-1.187 1.515-1.514 3.166-1.583v-.198c0-.395-.327-.525-.86-.525-.533 0-1.058.13-1.583.267l-.388-.929a12.697 12.697 0 012.176-.395c1.514 0 2.237.525 2.237 1.712v2.77a4.326 4.326 0 01-2.176.395zM8.156 21.884a4.736 4.736 0 01-4.729-4.728 4.736 4.736 0 014.729-4.729 4.736 4.736 0 014.728 4.729c0 2.606-2.122 4.672-4.728 4.728zm0-9.884A5.151 5.151 0 003 17.156a5.151 5.151 0 005.156 5.155 5.151 5.151 0 005.155-5.155A5.151 5.151 0 008.157 12z"
        fill="#231F20"
      />
      <path
        d="M6.09 16.91c-.364-.063-.666.365-.848.666.063-.483.61-1.212.848-.666zM5.305 16.91c.119-.427.427-.61.546-.483-.245.119-.427.245-.546.483z"
        fill="#231F20"
      />
      <path
        d="M6.215 17.584c.182-.182.182-.364-.12-.484-.3-.119-.665.365-.728.792.182-.252.722-.672.848-.308zM9.186 18.732c.063-.547-.483-.848-.61-1.394-.118-.547.848-1.457.246-2.424 1.156.91.245 1.457.791 2.186a.942.942 0 01-.483-.365c-.308.42.785.967.056 1.997zM7.366 14.241c.119 0 .364.12.301.182-.301.302-.729.182-1.093.484.126-.358.736-.42.792-.666z"
        fill="#231F20"
      />
      <path
        d="M7 15.454c-.064 0-.064-.063-.064-.12.063.12.182.064.12-.118H7.3c-.176.056-.239.238-.302.238zm1.393-.848c.12-.12 0-.364-.546-.427.546.245-.364.3-.364.791-.302.12-.547-.063-.666.063s-.301.063-.245-.119c-.12.063-.12.182-.063.302 0 .063.063.063.12.119l.062.063c-.182.182-.245.3-.063.483.063-.12.182-.245.301-.364.12.063.302 0 .547-.245-.182.728-.729.791-.911.791.12.12.792.245.848-.063.182-.665.847-.974.3-.974.015-.112.561-.294.68-.42zM8.638 14.305c.245.119.427.301.546.483.064-.364-.119-.728-.364-.974-.063-.056-.245.309-.182.49z"
        fill="#231F20"
      />
      <path
        d="M4.395 16.547c.245-.61.728-1.093 1.274-1.395.183-.427.12-.665 1.759-1.33.063-.246.063-.547.119-.547a.942.942 0 01.483.364h.182c.063-.245.12-.483.245-.728.12-.182.365-.182.666.063.364.245.665.546.974.91.546.183 1.03.547 1.394.974-.182-.119-.428-.182-.666-.245.484.427.666 1.156.484 1.758-.12-.61-.484-1.092-.974-1.33.182.665-.61.427-.91-.064.062.063.118.063.244 0 0-.665-.245-1.275-.728-1.758-.245-.245-.427.301-.484.729 0 .119-.182.245-.3.245.062-.063.062-.12.062-.245-.427.063-.791.182-1.156.427-.728.301-.91.483-1.092 1.03-.666.23-1.15.658-1.576 1.142z"
        fill="#231F20"
      />
      <path
        d="M8.037 16.427a1.551 1.551 0 010 1.758c.12-.665-.301-2.003-.728-1.33.245.062.427.363.364.665-.12-.302-.483-.428-.792-.365a.485.485 0 01-.546-.182c-.182-.301.119-.665-.483-.665.728-.365.546.364.847.427.301.063.484-.182.61-.301a.39.39 0 01.546-.12c.12-.3-.063-.847.245-1.092.182-.12.484-.063.666.063-.245-.014-.554.294-.729 1.142z"
        fill="#231F20"
      />
      <path
        d="M6.397 19.342a.455.455 0 01-.119-.301c.063.063.182.119.301.119-.063.119-.126.182-.182.182zm.729-.792c.063 0 .119.063.182 0s0-.245-.063-.301c.063 0 .301 0 .301.12 0 .244-.182.3-.546.482.063-.056.063-.182.126-.3zm-1.457-.245c.063-.063.119-.12.245-.182 0 .182 0 .301.063.483-.07.063-.308-.119-.308-.301zm-.974-.904l-.364-.364c.063-.063.182-.182.3-.182.12 0 .183.063.12.063s-.12 0-.12.12c0 .118.064.181.12.244.07-.007.007.12-.056.12zm7.04-2.304a4.71 4.71 0 01-.119 3.278c.063-.483-.364-1.156-.182-1.94-.792.728.427 2.606-1.331 3.817.245-.609.364-1.211.301-1.877.12.063.182.12.245.245.182-.61.12-1.275-.119-1.877.12 0 .301.119.365.182.063-1.093-.729-1.156-.911-1.457 0 .301.364.546.427.974-.182-.12-.427-.12-.61-.12.428.484.61 1.093.484 1.696-.182-.12-.364-.245-.546-.245.3.546.3 1.155.063 1.695 0-.182-.063-.364-.12-.483-.118.427-.427.728-.847.791.301-.245.301-.729.063-.974-.063-.063-.182-.119-.245-.182-.483-.182-1.03.12-1.275 1.212-.301.12-1.331-.119-.729-.728.365.119 1.275-.484 1.212-.911-.119-.483-.546-.848-1.093-.792-.546 0-.91.484-1.33.729-.365-.063-.484-.12-.666-.61 0-.3.301-.483.301-.791-.245.063-.301-.364-.847.063-.063-.063 0-.245 0-.245-.12.119-.182.245-.246.427.12.182.246.364.484.483.301.792.61.911.91.911.12.182.365.364.61.427a1.194 1.194 0 010-.665c.665-.666 1.156-.245 1.212-.063-.547.119-.182 1.33-1.093.791 0 .183 0 .428.12.61.118.848 1.155.61 1.456.791-.063-.791.427-1.394.792-1.33.364.063.483.665-.182 1.274.427.063.847-.063 1.211-.3-.063.483-.245.91-.546 1.274a4.108 4.108 0 003.152-4.91c-.007-.449-.133-.87-.37-1.17z"
        fill="#231F20"
      />
    </svg>
  )
}

export default SvgMbb
