import type {
  PromptProps,
  MemoryRouterProps,
  RedirectProps,
  RouteChildrenProps,
  RouteComponentProps,
  RouteProps,
  RouterProps,
  StaticRouterProps,
  StaticRouter,
  SwitchProps,
  match,
  RouterChildContext,
} from 'react-router-dom'
import {
  Link,
  BrowserRouter,
  Switch,
  Router,
  Route,
  MemoryRouter,
  Prompt,
  Redirect,
  useHistory as useHistoryOriginal,
  generatePath,
  matchPath,
  withRouter,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import { UserAgent } from '~/types'
import { getWebOrAppAgent } from '~/managers/ConfigManager'

// Define your modified useHistory function
function useHistory() {
  const history = useHistoryOriginal() // Use the original useHistory

  // Store the original push function
  const originalPush = history.push

  // Override the push function to include 'from' property in state
  history.push = (pathname: string, state = {}) => {
    // Replacing href instead of useHistory, native app is using webview.
    // Native app doesn't detect url change when using history.push
    if (getWebOrAppAgent !== UserAgent.WEB) {
      window.location.href = pathname
    }

    originalPush(
      pathname,
      state ? { ...state, from: history.location.pathname } : { from: history.location.pathname }
    )
  }

  return history
}

// Export all named exports and types individually
export type {
  PromptProps,
  MemoryRouterProps,
  RedirectProps,
  RouteChildrenProps,
  RouteComponentProps,
  RouteProps,
  RouterProps,
  StaticRouterProps,
  SwitchProps,
  RouterChildContext,
  match,
}
export {
  generatePath,
  Prompt,
  MemoryRouter,
  Redirect,
  Route,
  Router,
  StaticRouter,
  Switch,
  matchPath,
  withRouter,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
  BrowserRouter,
  Link,
}
