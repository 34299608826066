import * as React from 'react'

function SvgLefttSlider(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 37 37" fill="none" {...props}>
    <g clip-path="url(#clip0_4425_259746)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2177 11.3818C22.5497 11.7137 22.5497 12.2519 22.2177 12.5839L16.3021 18.4995L22.2177 24.4151C22.5497 24.7471 22.5497 25.2852 22.2177 25.6172C21.8858 25.9491 21.3476 25.9491 21.0156 25.6172L14.8997 19.5012C14.3464 18.948 14.3464 18.051 14.8997 17.4977L21.0156 11.3818C21.3476 11.0498 21.8858 11.0498 22.2177 11.3818Z" fill="#1B2736"/>
    </g>
    <defs>
    <clipPath id="clip0_4425_259746">
    <rect width="17" height="17" fill="white" transform="translate(27 27) rotate(-180)"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default SvgLefttSlider
