import styled from '@emotion/styled'
import { PRIMITIVES } from '../../../themes/deprecated/colors'
import { useTheme } from '../../../themes/deprecated/themeContext'
import { Body } from '../Typography'

type NoteProps = {
  children: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

function Note({ children, ...props }: NoteProps) {
  const { spacing, button } = useTheme()

  const StyledNote = styled.div`
    position: relative;
    width: 100%;
    background: ${button.secondary};
    padding: ${spacing.sm};
    border-radius: 5px;
  `
  const StyledContainer = styled(Body)`
    position: relative;

    ::before {
      content: '';
      height: 100%;
      width: ${spacing.xs};
      background: ${PRIMITIVES.ROYAL_BLUE};
      border-radius: 5px;
      position: absolute;
    }
  `
  const TextContainer = styled.div`
    margin-left: ${spacing.md};
  `
  return (
    <StyledNote {...props}>
      <StyledContainer>
        <TextContainer>{children}</TextContainer>
      </StyledContainer>
    </StyledNote>
  )
}

export default Note
