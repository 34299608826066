import styled from '@emotion/styled'
import { fdsTheme } from '@fazz/design-system'

const { spacing, colors, rootPrimitives } = fdsTheme

export const LogoContainer = styled.div`
  text-align: center;
  padding: ${spacing.md};
`
export const SidebarContainer = styled.div<{ colorScheme: 'sandbox' | 'default' }>`
  border-right: 1px solid ${colors.line};
  background-color: ${({ colorScheme }) =>
    colorScheme === 'sandbox' ? rootPrimitives.russianViolet : colors.surfaceNeutral};
  height: 100vh;
`
