import { get } from '../fazzbizBaseApi'
import { SGpayEndpoints } from '../sgPay/sgPay.api'

export const TermLoanEndpoints = {
  Bills: '/dashboard/sg-loan/termloan/bills',
  Disbursements: '/dashboard/sg-loan/termloan/disbursements',
  LoanDetails: '/dashboard/sg-loan/termloan/loan-detail',
  OutstandingRepayment: '/dashboard/sg-loan/termloan/outstanding-repayment',
  Repayments: '/dashboard/sg-loan/termloan/repayments',
  UserDetails: '/dashboard/sg-loan/termloan/user',
}

export const TermLoanQueryKeys = {
  getUserDetails: 'getUserDetails',
  getOutstandingRepayments: 'getOutstandingRepayments',
}

export const getUserDetails = () => get(TermLoanEndpoints.UserDetails)

export const getBills = () => get(`${TermLoanEndpoints.Bills}?limit=36`)

export const getBillDetails = (billId: string) => get(`${TermLoanEndpoints.Bills}/${billId}`)

export const getDisbursements = () => get(TermLoanEndpoints.Disbursements)

export const getLoanDetails = () => get(TermLoanEndpoints.LoanDetails)

export const getOutstandingRepayment = () => get(TermLoanEndpoints.OutstandingRepayment)

export const getRepayments = () => get(TermLoanEndpoints.Repayments)

export const getRepaymentDetails = (repaymentId: string) =>
  get(`${TermLoanEndpoints.Repayments}/${repaymentId}`)

export const getDisbursementOrder = (walletId: string, externalId: string) =>
  get(`${SGpayEndpoints.Wallets}${walletId}/orders?${externalId}`)

export const getDisbursementsWithFilter = (walletId: string, filters: string) =>
  get(`${SGpayEndpoints.Wallets}${walletId}/orders?${filters}`)
