import { toLocaleBalance } from '~/biz/helpers/localeHelper'
import type { Wallet } from '~/rootStore/wallets/types'
import { WalletTag, WalletLedgerPurpose } from '~/rootStore/wallets/types'

export default function hasIDRCashAccount(wallets: Map<WalletTag, Wallet | null>): boolean {
  const idrCashAccountWallet = wallets.get(WalletTag.IDR_CASH_ACCOUNT)
  const activeWallet = idrCashAccountWallet?.relationships.ledgers.find(
    (wallet) => wallet.attributes.purpose === WalletLedgerPurpose.Available
  )
  const balance = activeWallet
    ? parseFloat(toLocaleBalance({ balance: activeWallet.attributes.balance, withCurrency: false }))
    : 0

  return balance !== 0
}
