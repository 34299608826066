import styled from '@emotion/styled'
import { Tick, CrossMini, Pending } from '@fazz/design-system-icons'
import { BACKGROUND_COLORS, NEUTRAL_COLORS } from '../../../themes/deprecated/ColorStyles'
import { MAIN_FONT, FONT_SIZE } from '../../../themes/deprecated/TypographyStyles'
import { PRIMITIVES } from '../../../themes/deprecated/colors'
import { fazzbizTheme } from '../../../themes/deprecated/theme'

const BASE_COLORS = {
  success: fazzbizTheme.status.subduePositive,
  warning: fazzbizTheme.status.subdueWarning,
  error: fazzbizTheme.status.subdueCritical,
  info: BACKGROUND_COLORS.INFO,
  neutral: BACKGROUND_COLORS.NEUTRAL,
}

const PAYLOAD_COLORS = {
  success: fazzbizTheme.status.positive,
  error: fazzbizTheme.status.critical,
  warning: fazzbizTheme.status.warning,
  info: PRIMITIVES.MAJESTIC_BLUE,
  neutral: NEUTRAL_COLORS.G500,
}

const BORDER_COLORS = {
  success: fazzbizTheme.status.positive,
  error: fazzbizTheme.status.critical,
  warning: fazzbizTheme.status.warning,
  info: fazzbizTheme.base.primary,
  neutral: fazzbizTheme.interactive.inactive,
}

type Props = {
  type: 'success' | 'error' | 'warning' | 'info' | 'neutral'
  children: React.ReactNode
  icon?: boolean
  style?: Record<string, unknown>
}

function Label({ type, children, icon = false, ...rest }: Props) {
  let iconElement

  switch (type) {
    case 'success': {
      iconElement = <Tick style={{ width: '14px', marginRight: '4px', marginTop: '2px' }} />
      break
    }
    case 'error': {
      iconElement = <CrossMini style={{ width: '14px', marginRight: '4px', marginTop: '0px' }} />
      break
    }
    case 'warning': {
      iconElement = <Pending style={{ width: '14px', marginRight: '4px', marginTop: '0px' }} />
      break
    }
    case 'info': {
      iconElement = <Pending style={{ width: '14px', marginRight: '4px', marginTop: '0px' }} />
      break
    }
    case 'neutral':
    default: {
      iconElement = <Pending style={{ width: '14px', marginRight: '4px', marginTop: '0px' }} />
      break
    }
  }

  return (
    <Base type={type} {...rest}>
      {icon && iconElement}
      {children}
    </Base>
  )
}

const Base = styled.div<{ type: Props['type'] }>`
  -webkit-font-smoothing: antialiased;
  border-radius: 14px;
  font-family: ${MAIN_FONT};
  font-size: ${FONT_SIZE.Body};
  font-weight: 400;
  height: 28px;
  display: inline-flex;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  text-transform: capitalize;

  ${(props) => `
    background-color: ${BASE_COLORS[props.type]};
    color: ${PAYLOAD_COLORS[props.type]} !important;
    border: 1px solid ${BORDER_COLORS[props.type]};
  `}
`

export default Label
