import styled from 'styled-components'
import Button from 'antd/lib/button'
import { fdsTheme } from '../../../themes/theme-fds3'
import type {
  IconButtonShapeType,
  IconButtonSizeType,
  IconButtonVariantType,
  IconButtonProps,
  IconButtonColorSchemeType,
} from './IconButton.types'

const { radius, colors } = fdsTheme

const getBorderRadius = (shape?: IconButtonShapeType, size?: IconButtonSizeType) => {
  if (shape === 'circle') return '50%'
  switch (size) {
    case 'small':
    case 'sm':
    case 'x-small':
    case 'xs':
      return radius.sm
    case 'xl':
    case 'large':
    case 'medium':
    case 'md':
    default:
      return radius.md
  }
}

const getBackgroundColor = (
  variant?: IconButtonVariantType,
  colorScheme?: IconButtonColorSchemeType
) => {
  switch (variant) {
    case 'primary':
      return colorScheme === 'default' ? colors.primary : colors.critical
    case 'secondary':
      return colorScheme === 'default' ? colors.secondaryButton : colors.secondaryDestructiveButton
    case 'outlined':
    case 'ghost':
    default:
      return 'transparent'
  }
}

const getBorderLine = (variant?: IconButtonVariantType) => {
  switch (variant) {
    case 'outlined':
      return `1px line ${colors.onNeutralSecondary}`
    case 'primary':
    case 'secondary':
    case 'ghost':
    default:
      return 'none'
  }
}

const getHoverBorderLine = (variant?: IconButtonVariantType) => {
  switch (variant) {
    case 'outlined':
      return `1px solid ${colors.hoveredSurface}`
    case 'primary':
    case 'secondary':
    case 'ghost':
    default:
      return 'none'
  }
}

const getDisabledBackgroundColor = (variant?: IconButtonVariantType) => {
  switch (variant) {
    case 'primary':
    case 'secondary':
      return colors.disabledButton
    case 'ghost':
    case 'outlined':
    default:
      return colors.surfaceDisabledSurface
  }
}

const getIconColor = (variant?: IconButtonVariantType, colorScheme?: IconButtonColorSchemeType) => {
  switch (variant) {
    case 'primary':
      return colors.onContainer
    case 'secondary':
      return colorScheme === 'default' ? colors.primary : colors.critical
    case 'outlined':
    case 'ghost':
    default:
      return colors.onNeutralSecondary
  }
}

const getHoverIconColor = (
  variant?: IconButtonVariantType,
  shape?: IconButtonShapeType,
  colorScheme?: IconButtonColorSchemeType
) => {
  switch (variant) {
    case 'primary':
      return colors.onContainer
    case 'secondary':
      return colorScheme === 'default' ? colors.primary : colors.critical
    case 'outlined':
    case 'ghost':
    default:
      return colors.onNeutralSecondary
  }
}

const getHoverBackgroundColor = (
  variant?: IconButtonVariantType,
  shape?: IconButtonShapeType,
  colorScheme?: IconButtonColorSchemeType
) => {
  switch (variant) {
    case 'primary':
      return colorScheme === 'default' ? colors.hoveredPrimary : colors.hoveredCritical
    case 'secondary':
      return colorScheme === 'default' ? colors.hoveredSecondary : colors.hoveredDestructive
    default:
      return colors.hoveredSurface
  }
}

const getButtonSize = (size?: IconButtonSizeType) => {
  switch (size) {
    case 'xl':
      return '48px'
    case 'large':
    case 'lg':
      return '40px'
    case 'medium':
    case 'md':
      return '36px'
    case 'small':
    case 'sm':
      return '32px'
    case 'x-small':
    case 'xs':
      return '24'
    default:
      return '36px'
  }
}

const getIconSize = (size?: IconButtonSizeType) => {
  switch (size) {
    case 'xl':
    case 'large':
    case 'lg':
    case 'small':
    case 'sm':
      return '24px'
    case 'medium':
    case 'md':
    case 'x-small':
    case 'xs':
    default:
      return '18px'
  }
}

const getIconPadding = (size?: IconButtonSizeType) => {
  switch (size) {
    case 'xl':
      return '12px'
    case 'large':
    case 'lg':
      return '8px'
    case 'medium':
    case 'md':
      return '6px'
    case 'small':
    case 'sm':
      return '4px'
    case 'x-small':
    case 'xs':
      return '3px'
    default:
      return '12px'
  }
}

export const StyledIconButton = styled(Button).attrs((props: IconButtonProps) => ({
  size: props.size,
  shape: props.shape,
  variant: props.variant,
  colorScheme: props.colorScheme,
}))`
  ${({ size, shape, variant, colorScheme }) =>
    `
      &.ant-btn {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        border: ${getBorderLine(variant)};
        width: ${getButtonSize(size)};
        height: ${getButtonSize(size)};
        padding: ${getIconPadding(size)};
        border-radius: ${getBorderRadius(shape, size)};
        background-color: ${getBackgroundColor(variant, colorScheme)};

        svg {
          width: ${getIconSize(size)};
          height: ${getIconSize(size)};
          color: ${getIconColor(variant, colorScheme)};
        }
      }

      // Remove outline for both active and focus
      &:active, &:focus {
        outline: none;
      }

      // Add a border when the button is focused via tab
      &:focus-visible {
        outline: 1px solid ${colors.focusedDefault};
      }

      // hover
      &:hover {
        cursor: pointer;
        background-color: ${getHoverBackgroundColor(variant, shape, colorScheme)};
        border: ${getHoverBorderLine(variant)};

        svg {
          color: ${getHoverIconColor(variant, shape, colorScheme)};
        }
      }

      // disabled
      &:disabled {
        cursor: not-allowed;
        background-color: ${getDisabledBackgroundColor(variant)};

        svg {
          color: ${
            variant === 'ghost' || variant === 'outlined'
              ? colors.surfaceDisabledOnSurface
              : colors.onContainer
          };
        }
      }
    `}
`
