import { getFeatureMatrixFactorsStatic } from '~/bizRegional/store/route/selectors'
import type { PERMISSIONS } from '~/types'
import hasPermission from './hasPermission'

const isHasPermission = (permission: PERMISSIONS) => {
  const featureMatrix = getFeatureMatrixFactorsStatic()
  return hasPermission(permission, featureMatrix) || false
}

export default isHasPermission
