import type Rollbar from 'rollbar'
import { ConfigManager, isLocal } from '~/managers/ConfigManager'

export const rollbarConfig: Rollbar.Configuration = {
  enabled: !isLocal,
  accessToken: '0ee2733c3e3d4c79ac9476b8bea5857b',
  environment: ConfigManager.getEnvironmentMode(),
  captureUncaught: true,
  captureUnhandledRejections: true,
  timeout: 1000,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
        code_version: '1.0.1',
      },
    },
  },
}
