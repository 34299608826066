import { css } from 'emotion'
import Checkbox from 'antd/es/checkbox'
import { CheckboxGroupProps } from 'antd/es/checkbox/Group'
import 'antd/es/checkbox/style/css'
import { NEUTRAL_COLORS } from '../../../themes/deprecated/ColorStyles'
import { MAIN_FONT } from '../../../themes/deprecated/TypographyStyles'
import { useTheme } from '../../../themes/deprecated/themeContext'

type GroupProps = CheckboxGroupProps

function CheckboxGroup({ className = '', ...props }: GroupProps) {
  const { base } = useTheme()
  const style = `
    &.ant-checkbox-group {
      width: 100%;
    }

    & .ant-checkbox-group-item {
      display: flex;
      align-items: flex-start;

      border: 1px solid ${NEUTRAL_COLORS.G200};
      border-radius: 5px;

      transition: border 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        border: 1px solid ${base.primary};
      }

      &:focus-within {
        box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
        border: 1px solid ${base.primary};
      }

      &:not(:last-child) {
        margin-bottom: 10px;
        width: 100%;
      }
    }

    & .ant-checkbox-wrapper-checked {
      border: 1px solid ${base.primary};
    }

    & .ant-checkbox-wrapper {
      padding-left: 16px;
      padding-top: 11px;
      padding-bottom: 11px;
      line-height: 1.6;
      color: ${base.neutral};
      font-family: ${MAIN_FONT};
    }

    & .ant-checkbox {
      margin-top: 4px;
    }

    & .ant-checkbox + span {
      padding-left: 10px;
      padding-right: 10px;
    }

    & .ant-checkbox-input {
    }
  `

  return <Checkbox.Group className={`${css(style)} ${className}`} {...props} />
}

export default {
  Group: CheckboxGroup,
}
