export type LoanVerification = {
  companyTypeName?: string
  companyName?: string
  companyEmail?: string
  companyPhoneNumber?: string
  companyNibNumber?: string
  companyTaxCardNumber?: string
  companyIdentityCardType?: string
  companyAddress?: string
  companyProvince?: string
  companyCity?: string
  companyDistrict?: string
  companyVillage?: string
  companyPostCode?: number
  companyLinkNib?: string
  companyLinkNpwp?: string
  companyLinkAktaPendirian?: string
  companyLinkSkt?: string
  companyLinkAktaPerubahan?: string
  keypersonIdentityCardNumber?: string
  keypersonLinkKtp?: string
  keypersonLinkSelfieWithKtp?: string
  keypersonLinkNpwp?: string
  keypersonName?: string
  keypersonDob?: string
  keypersonPlaceOfBirth?: string
  keypersonPosition?: string
  keypersonAddress?: string
  kcBizId?: string
  companyTotalEmployee?: string
  companyFounded?: string
  companyBuildingStatus?: string
  companyDescription?: string
  keypersonTaxCardNumber?: string
  directorEmail?: string
  companyNpwpNumber?: string
}

export enum SCREENS {
  getStarted = 'getStarted',
  companyKey = 'companyKey',
  companyDetails = 'companyDetails',
  companyAddress = 'companyAddress',
  review = 'review',
  complete = 'complete',
}

export enum LoansVerificationActions {
  SET_LOAN_VERIFICATION = 'LoansActions.SET_LOAN_VERIFICATION',
  SET_LOAN_VERIFICATION_LOADING = 'LoansActions.SET_LOAN_VERIFICATION_LOADING',
  SET_CURRENT_SCREEN = 'LoanActions.SET_CURRENT_SCREEN',
  SET_LOAN_VERIFICATION_PROVINCE = 'LoansActions.SET_LOAN_VERIFICATION_PROVINCE',
  SET_LOAN_VERIFICATION_CITY = 'LoansActions.SET_LOAN_VERIFICATION_CITY',
  SET_LOAN_VERIFICATION_WARD = 'LoansActions.SET_LOAN_VERIFICATION_WARD',
  SET_LOAN_VERIFICATION_DISTRICT = 'LoansActions.SET_LOAN_VERIFICATION_DISTRICT',
}

export type FileData = { fileName: string; fileData: string } | undefined

export type PresignedURL = {
  getUrl: string
  putUrl: string
}

export type UploadDocument = {
  file: FileData
  url: PresignedURL
}

export type LocationDetail = {
  attributes: {
    morakReferId: number
    name: string
  }
  id: string
  type: string
}

type SetRetrievedLoanVerification = {
  type: typeof LoansVerificationActions.SET_LOAN_VERIFICATION
  loanVerification: LoanVerification
}

type SetLoanVerificationLoading = {
  type: typeof LoansVerificationActions.SET_LOAN_VERIFICATION_LOADING
  loanVerificationLoading: boolean
}

type SetCurrentScreen = {
  type: typeof LoansVerificationActions.SET_CURRENT_SCREEN
  currentScreen: string
}

type SetLoanVerificationProvince = {
  type: typeof LoansVerificationActions.SET_LOAN_VERIFICATION_PROVINCE
  loanVerificationProvince: LocationDetail[]
}

type SetLoanVerificationCity = {
  type: typeof LoansVerificationActions.SET_LOAN_VERIFICATION_CITY
  loanVerificationCity: LocationDetail[]
}

type SetLoanVerificationWard = {
  type: typeof LoansVerificationActions.SET_LOAN_VERIFICATION_WARD
  loanVerificationWard: LocationDetail[]
}

type SetLoanVerificationDistrict = {
  type: typeof LoansVerificationActions.SET_LOAN_VERIFICATION_DISTRICT
  loanVerificationDistrict: LocationDetail[]
}

export type ActionTypes =
  | SetRetrievedLoanVerification
  | SetLoanVerificationLoading
  | SetCurrentScreen
  | SetLoanVerificationProvince
  | SetLoanVerificationCity
  | SetLoanVerificationWard
  | SetLoanVerificationDistrict
