export type TransactionDetails = {
  key: string
  transactionId: string
  transactionType: string
  transactionHash?: string | null
  transactionHashUrl?: string | null
  walletType: string
  method: string
  accountHolderName?: string | null
  amount: { prefix: string; postfix: string }
  fee: string
  tax?: string | null
  netAmount: { prefix: string; postfix: string }
  createdDate: string
  completedDate: string
  status: string
  note?: string | null
  sourceDestination?: { source: string; destination: string } | null
  merchantDescription?: string | null
  failureReason?: string | null
  blockedReasons?: string | null
  manualAssignments?: Array<{
    createdAt: string
    amount: { prefix: string; postfix: string }
    status: string
    externalId: string
  }> | null
  network?: string | null
  bankAccountNo: string | null
  bankName: string | null
}

export enum TransactionDetailsActions {
  SET_IS_LOADING = 'TransactionDetailsActions.SET_IS_LOADING',
  GET_TRANSACTION_DETAILS = 'TransactionDetailsActions.GET_TRANSACTION_DETAILS',
}

type SetIsLoading = {
  type: typeof TransactionDetailsActions.SET_IS_LOADING
  isLoading: boolean
}

type GetTransactionDetails = {
  type: typeof TransactionDetailsActions.GET_TRANSACTION_DETAILS
  transactionDetails: TransactionDetails
}

export type ActionTypes = SetIsLoading | GetTransactionDetails
