/* eslint-disable @typescript-eslint/naming-convention */
/** @deprecated please use fazzBizzThemeFds3 fontFamily theme instead */
export const MAIN_FONT = 'FazzNeue'
/** @deprecated please use fazzBizzThemeFds3 fontFamily theme instead */
export const FALLBACK_FONT = 'Metropolis'

/** @deprecated please use fazzBizzThemeFds3 fontWeight theme instead */
export enum FONT_WEIGHT {
  BOLD = 700,
  MEDIUM = 500,
  REGULAR = 400,
}

/** @deprecated please use fazzBizzThemeFds3 fontSize theme instead */
export enum FONT_SIZE {
  Title = '24px',
  H1_MOBILE = '24px',
  TitleSmall = '18px',
  H2_MOBILE = '20px',
  LabelLarge = '16px',
  H3_MOBILE = '18px',
  LabelText = '14px',
  Body = '14px',
  P1_MOBILE = '16px',
  SMALL = '12px',
  SMALL_MOBILE = '14px',
}

/** @deprecated please use fazzBizzThemeFds3 lineHeight theme instead */
export enum LINE_HEIGHT {
  Title = '29px',
  H1_MOBILE = '28px',
  TitleSmall = '22px',
  H2_MOBILE = '23px',
  LabelLarge = '19px',
  H3_MOBILE = '21px',
  LabelText = '17px',
  Body = '17px',
  P1_MOBILE = '19px',
  SMALL = '15px',
  SMALL_MOBILE = '17px',
}
