import type { Reducer } from 'redux'
import type { ActionTypes, FlexPayBillingSummary } from './types'
import { FlexpayActions } from './types'

type State = {
  flexpayBillingSummary?: FlexPayBillingSummary | null
}

const initialState: State = {
  flexpayBillingSummary: null,
}

export const flexpay: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case FlexpayActions.SET_FLEXPAY_BILLING_SUMMARY: {
      return {
        ...state,
        flexpayBillingSummary: action.flexpayBillingSummary,
      }
    }
    default:
      return state
  }
}
