import moment from 'moment'
import { Notification } from '@fazz/design-system'
import type { Dispatch } from '~/bizRegional/store/types'
import {
  getBills,
  getDisbursements,
  getOutstandingRepayment,
  getRepayments,
  getUserDetails,
} from '~/api/termLoan'
import type {
  ActionTypes,
  BillDetails,
  GetTermLoanUserDetailsProps,
  RepaymentDetails,
  DisbursementDetails,
} from './types'
import { TermLoanPageActions } from './types'

export const getTermLoanUserDetails =
  ({ initialLoad = false, callback }: GetTermLoanUserDetailsProps) =>
  (dispatch: Dispatch<ActionTypes>) => {
    getUserDetails()
      .then((resp) => {
        dispatch({
          type: TermLoanPageActions.SET_TERM_LOAN_USER_DETAILS,
          userDetails: resp.data,
        })
      })
      .catch(() => {
        if (!initialLoad) {
          Notification({
            type: 'error',
            message: 'Failed to fetch term loan user details',
          })
        }
      })
      .finally(() => {
        dispatch({
          type: TermLoanPageActions.SET_TERM_LOAN_INITIALISED,
          isTermLoanInitialised: true,
        })
        if (callback) callback()
      })
  }

export const getTermLoanBills = (callback?: () => void) => (dispatch: Dispatch<ActionTypes>) => {
  getBills()
    .then((resp) => {
      dispatch({
        type: TermLoanPageActions.SET_TERM_LOAN_BILLS,
        bills: resp.data,
      })
    })
    .catch(() => {
      Notification({
        type: 'error',
        message: 'Failed to fetch term loan bills',
      })
    })
    .finally(() => {
      if (callback) callback()
    })
}

export const getTermLoanOutstandingRepayments =
  (callback?: () => void) => (dispatch: Dispatch<ActionTypes>) => {
    getOutstandingRepayment()
      .then((resp) => {
        dispatch({
          type: TermLoanPageActions.SET_TERM_LOAN_OUTSTANDING_REPAYMENT,
          outstandingRepayment: resp.data,
        })
      })
      .catch(() => {
        Notification({
          type: 'error',
          message: 'Failed to fetch term loan outstanding bills',
        })
      })
      .finally(() => {
        if (callback) callback()
      })
  }

export const getTermLoanRepayments =
  (callback?: () => void) => (dispatch: Dispatch<ActionTypes>) => {
    getRepayments()
      .then((resp) => {
        dispatch({
          type: TermLoanPageActions.SET_TERM_LOAN_REPAYMENTS,
          repayments: resp.data,
        })
      })
      .catch(() => {
        Notification({
          type: 'error',
          message: 'Failed to fetch term loan repayments',
        })
      })
      .finally(() => {
        if (callback) callback()
      })
  }

export const getTermLoanDisbursements =
  (callback?: () => void) => (dispatch: Dispatch<ActionTypes>) => {
    getDisbursements()
      .then((resp) => {
        dispatch({
          type: TermLoanPageActions.SET_TERM_LOAN_DISBURSEMENTS,
          disbursements: resp.data,
        })
      })
      .catch(() => {
        Notification({
          type: 'error',
          message: 'Failed to fetch term loan disbursements',
        })
      })
      .finally(() => {
        if (callback) callback()
      })
  }

export const dispatchAllTermLoanPageActions =
  (callback?: () => void) => (dispatch: Dispatch<ActionTypes>) => {
    Promise.all([
      getUserDetails(),
      getOutstandingRepayment(),
      getBills(),
      getRepayments(),
      getDisbursements(),
    ])
      .then(([userDetails, outstandingRepayment, bills, repayments, disbursements]) => {
        dispatch({
          type: TermLoanPageActions.SET_TERM_LOAN_USER_DETAILS,
          userDetails: userDetails.data,
        })

        dispatch({
          type: TermLoanPageActions.SET_TERM_LOAN_OUTSTANDING_REPAYMENT,
          outstandingRepayment: outstandingRepayment.data,
        })

        dispatch({
          type: TermLoanPageActions.SET_TERM_LOAN_BILLS,
          bills: bills.data.sort((a: BillDetails, b: BillDetails) => a.billCounter - b.billCounter),
        })

        dispatch({
          type: TermLoanPageActions.SET_TERM_LOAN_REPAYMENTS,
          repayments: repayments.data.sort((a: RepaymentDetails, b: RepaymentDetails) =>
            moment(b.transactionDate).diff(moment(a.transactionDate))
          ),
        })

        dispatch({
          type: TermLoanPageActions.SET_TERM_LOAN_DISBURSEMENTS,
          disbursements: disbursements.data.sort((a: DisbursementDetails, b: DisbursementDetails) =>
            moment(b.transactionDate).diff(moment(a.transactionDate))
          ),
        })
      })
      .catch(() => {
        Notification({
          type: 'error',
          message: 'Failed to fetch Loan details',
        })
      })
      .finally(() => {
        if (callback) {
          callback()
        }
      })
  }
