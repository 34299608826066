import type { Reducer } from 'redux'
import type {
  Wallet,
  BankAccount,
  TransferInfo,
  ActionTypes,
  Account,
  DepositMethod,
} from './types'
import { DepositActions } from './types'

const initialState = {
  isLoading: false,
  wallets: [],
  bankAccounts: [],
  destination: undefined,
  destinationAmount: '',
  transferInfo: {
    accountHolderName: '',
    recipientName: 'Xfers Pte Ltd',
    recipientAccountNo: '',
    referenceNumber: '',
    expiredDate: '',
    depositAmount: '',
    userBankAccount: '',
    bankNameFull: '',
  },
  accounts: {},
  depositMethod: undefined,
}

type State = {
  isLoading: boolean
  wallets: Wallet[]
  bankAccounts: BankAccount[]
  destination: number | undefined
  destinationAmount: string
  transferInfo: TransferInfo
  accounts: { [key: string]: Account }
  depositMethod: DepositMethod
}

export const deposit: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case DepositActions.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }

    case DepositActions.SET_WALLETS: {
      return {
        ...state,
        wallets: action.wallets,
        isLoading: false,
      }
    }

    case DepositActions.SET_BANK_ACCOUNTS: {
      return {
        ...state,
        bankAccounts: action.bankAccounts,
        isLoading: false,
      }
    }

    case DepositActions.SET_DESTINATION: {
      return {
        ...state,
        destination: action.destination,
        destinationAmount: '',
      }
    }

    case DepositActions.SET_DESTINATION_AMOUNT: {
      return {
        ...state,
        destinationAmount: action.destinationAmount,
      }
    }

    case DepositActions.SET_TRANSFER_INFO: {
      return {
        ...state,
        transferInfo: action.transferInfo,
        isLoading: false,
      }
    }

    case DepositActions.SET_ACCOUNTS: {
      return {
        ...state,
        accounts: action.accounts,
        isLoading: false,
      }
    }

    case DepositActions.SET_DEPOSIT_METHOD: {
      return {
        ...state,
        depositMethod: action.depositMethod,
      }
    }

    default:
      return state
  }
}
