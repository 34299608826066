import { SVGProps } from 'react'

function NavHyperLink(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_301_4985)">
        <path
          d="M9.99998 2.10813C11.5608 2.10813 13.0866 2.57097 14.3844 3.43812C15.6821 4.30527 16.6936 5.53778 17.2909 6.97979C17.8882 8.4218 18.0445 10.0086 17.74 11.5394C17.4355 13.0702 16.6839 14.4764 15.5802 15.5801C14.4766 16.6837 13.0704 17.4353 11.5396 17.7398C10.0087 18.0443 8.42199 17.8881 6.97997 17.2908C5.53796 16.6934 4.30545 15.682 3.4383 14.3842C2.57116 13.0864 2.10832 11.5606 2.10832 9.9998C2.11052 7.90748 2.94267 5.90148 4.42217 4.42199C5.90167 2.94249 7.90766 2.11034 9.99998 2.10813ZM9.99998 0.833134C8.18699 0.833134 6.41471 1.37075 4.90726 2.378C3.39981 3.38524 2.22489 4.81688 1.53109 6.49187C0.837285 8.16686 0.655754 10.01 1.00945 11.7881C1.36315 13.5663 2.23619 15.1996 3.51817 16.4816C4.80015 17.7636 6.4335 18.6366 8.21166 18.9903C9.98981 19.344 11.8329 19.1625 13.5079 18.4687C15.1829 17.7749 16.6145 16.6 17.6218 15.0925C18.629 13.5851 19.1667 11.8128 19.1667 9.9998C19.1667 7.56865 18.2009 5.23707 16.4818 3.51799C14.7627 1.7989 12.4311 0.833134 9.99998 0.833134Z"
          fill="currentColor"
        />
        <path
          d="M8.7417 11.8831C8.58387 11.8626 8.43881 11.7857 8.33337 11.6665C8.23088 11.5519 8.17421 11.4035 8.17421 11.2498C8.17421 11.096 8.23088 10.9477 8.33337 10.8331L10.8334 8.33312C10.95 8.27742 11.081 8.25924 11.2084 8.2811C11.3358 8.30296 11.4533 8.36377 11.5447 8.45516C11.6361 8.54655 11.6969 8.66403 11.7187 8.79141C11.7406 8.9188 11.7224 9.04982 11.6667 9.16645L9.1667 11.6665C9.05925 11.792 8.90644 11.8699 8.7417 11.8831Z"
          fill="currentColor"
        />
        <path
          d="M14.0583 5.72462C13.4957 5.2216 12.7621 4.95264 12.0076 4.97278C11.2532 4.99292 10.535 5.30063 9.99999 5.83295L9.02499 6.79962C8.96603 6.85707 8.91916 6.92575 8.88717 7.00161C8.85517 7.07746 8.83868 7.15895 8.83868 7.24128C8.83868 7.32361 8.85517 7.40511 8.88717 7.48096C8.91916 7.55682 8.96603 7.6255 9.02499 7.68295C9.14218 7.79999 9.30103 7.86573 9.46666 7.86573C9.63228 7.86573 9.79114 7.79999 9.90833 7.68295L10.875 6.71628C11.0359 6.55815 11.2263 6.43325 11.4354 6.34872C11.6446 6.26418 11.8683 6.22167 12.0939 6.2236C12.3195 6.22554 12.5424 6.27188 12.7501 6.35999C12.9578 6.4481 13.146 6.57625 13.3042 6.73712C13.4623 6.89799 13.5872 7.08843 13.6717 7.29757C13.7563 7.5067 13.7988 7.73044 13.7968 7.95601C13.7949 8.18158 13.7486 8.40456 13.6605 8.61222C13.5723 8.81988 13.4442 9.00815 13.2833 9.16628L12.3167 10.1329C12.1996 10.2501 12.1339 10.409 12.1339 10.5746C12.1339 10.7402 12.1996 10.8991 12.3167 11.0163C12.3741 11.0752 12.4428 11.1221 12.5186 11.1541C12.5945 11.1861 12.676 11.2026 12.7583 11.2026C12.8407 11.2026 12.9221 11.1861 12.998 11.1541C13.0739 11.1221 13.1425 11.0752 13.2 11.0163L14.0333 10.183C14.3373 9.89739 14.5799 9.55298 14.7466 9.17068C14.9132 8.78839 15.0004 8.37618 15.0027 7.95915C15.0051 7.54211 14.9225 7.12896 14.7602 6.74482C14.5978 6.36067 14.359 6.01357 14.0583 5.72462Z"
          fill="currentColor"
        />
        <path
          d="M11.0085 12.2916C10.8913 12.1745 10.7325 12.1088 10.5668 12.1088C10.4012 12.1088 10.2424 12.1745 10.1252 12.2916L9.16684 13.2833C9.01125 13.4458 8.82434 13.5752 8.61739 13.6637C8.41044 13.7521 8.18772 13.7976 7.96268 13.7976C7.73763 13.7976 7.51491 13.7521 7.30796 13.6637C7.10101 13.5752 6.9141 13.4458 6.75851 13.2833C6.59801 13.126 6.47097 12.9378 6.38505 12.7302C6.29912 12.5225 6.25608 12.2996 6.25851 12.0749C6.2572 11.8516 6.30079 11.6303 6.38669 11.4242C6.47258 11.218 6.59902 11.0312 6.75851 10.8749L7.75017 9.87492C7.86722 9.75773 7.93296 9.59888 7.93296 9.43325C7.93296 9.26762 7.86722 9.10877 7.75017 8.99158C7.63299 8.87454 7.47413 8.8088 7.30851 8.8088C7.14288 8.8088 6.98403 8.87454 6.86684 8.99158L5.83351 9.99992C5.55937 10.2732 5.34186 10.5979 5.19345 10.9554C5.04504 11.3129 4.96865 11.6962 4.96865 12.0833C4.96865 12.4703 5.04504 12.8536 5.19345 13.2111C5.34186 13.5686 5.55937 13.8933 5.83351 14.1666C6.38653 14.7182 7.13574 15.028 7.91684 15.028C8.69794 15.028 9.44716 14.7182 10.0002 14.1666L11.0002 13.1749C11.1172 13.0577 11.183 12.8989 11.183 12.7333C11.183 12.5676 11.1172 12.4088 11.0002 12.2916H11.0085Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_301_4985">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default NavHyperLink
