import { SVGProps } from 'react'

function SvgSendInvitation(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.967.1c-.246-.001-.49.047-.717.141L1.325 5.666a1.95 1.95 0 0 0 0 3.625l4.542 1.817a1.91 1.91 0 0 1 1.05 1.108l1.775 4.459a1.959 1.959 0 0 0 3.633 0l5.45-13.909A1.958 1.958 0 0 0 15.967.1Zm.641 2.233-5.45 13.875a.699.699 0 0 1-1.3 0l-1.775-4.45a3.804 3.804 0 0 0-.25-.483l4.217-4.309a.628.628 0 0 0-.875-.9l-4.167 4.292a2.979 2.979 0 0 0-.675-.375L1.775 8.166a.7.7 0 0 1 0-1.3L15.667 1.4a.692.692 0 0 1 .842.267.65.65 0 0 1 .1.666Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSendInvitation
