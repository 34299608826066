export enum PATHS {
  TRANSACTIONS = '/transactions',
  DISBURSEMENTS = '/disbursements',
  DISBURSEMENTS_RECIPIENTS_LIST = '/disbursements/recipients_list',
  DISBURSEMENTS_PAYMENT_METHOD = '/disbursements/payment_method',
  DISBURSEMENTS_COMPLETE = '/disbursements/complete',
  TRANSACTION_DETAILS = '/transactions/:transactionId',
  DEPOSIT = '/deposit',
  DEPOSIT_REQUESTS = '/deposit/requests',
  SIMULATE_DEPOSIT = '/simulate_deposit',
  WITHDRAW = '/sgd_account/withdraw',
  TRANSFER_OUT = '/sgd_account/transfer_out',
  TRANSFER_OUT_INTERNATIONAL = '/sgd_account/transfer_out/international',
  MANAGE_ACCOUNT = '/manage_account',
  INVOICING = '/payment_link',
  INVOICING_DETAILS = '/payment_link/:uniqueId',
  CUSTOMER_CARDS = '/customer-card/:tab?',
  CUSTOMER_CARDS_TRANSACTION_DETAILS = '/customer-card/transaction/details/:cbsTransactionId/:type?',
  CUSTOMER_CARDS_CARD_DETAILS = '/customer-card/detail/users/:userOpaqueId/card/:cardOpaqueId/card-number/:cardNumberOpaqueId',
  CUSTOMER_CARDS_FRAUD_DETAILS = '/customer-card/fraud/details/:fraudEventOpaqueId/users/:userOpaqueId/card/:cardOpaqueId',
  CUSTOMER_CARDS_CUSTOMERS_DETAILS = '/customer-card/customers/details/user/:userOpaqueId',
  CREDIT_CARDS = '/credit_cards',
  CREDIT_CARDS_PAYMENTS_DETAILS = '/credit_cards/payment/:referenceId',
  CREDIT_CARDS_DISPUTES_DETAILS = '/credit_cards/dispute/:referenceId',
  DEBIT_CARDS = '/cards',
  DEBIT_CARDS_ALL = '/cards/all',
  DEBIT_CARDS_CREATE_CARD = '/cards/all/create_card',
  DEBIT_CARDS_MYCARD = '/cards/mycard',
  DEBIT_CARDS_DETAIL = '/cards/details/:cardId/:name/:cardLastFourDigits',
  DEBIT_CARDS_MYCARD_TRANSACTION_DETAILS = '/cards/mycard/transaction/:transactionId',
  EARN = '/earn',
  EARN_ADD_FUNDS = '/earn/add_funds',
  EARN_WITHDRAW = '/earn/withdraw',
  EARN_TRANSACTIONS = '/earn/transactions',
  USD_EARN = '/usd_earn',
  USD_EARN_DEPOSIT = '/usd_earn/add_funds',
  USD_EARN_WITHDRAW = '/usd_earn/withdraw',
  ACCOUNTS = '/accounts',
  USD_ACCOUNT = '/usd_account',
  USD_ACCOUNT_WITHDRAW = '/usd_account/withdraw',
  USD_ACCOUNT_ADD_RECIPIENT = '/usd_account/withdraw/add_recipient',
  SGD_ACCOUNT_ADD_RECIPIENT = '/sgd_account/withdraw/add_recipient',
  SGD_ACCOUNT = '/sgd_account',
  SGD_ACCOUNT_DETAIL = '/sgd_account/details/:transactionId',
  VERIFICATION = '/verification',
  VERIFICATION_MY_INFO_CALLBACK = '/callback',
  INTERNAL_TOOLS = '/internal_tools',
  BIZ_KYC_INFORMATION = '/verification/business/information',
  BIZ_KYC_INTENDED_RELATIONS = '/verification/business/intended_relations',
  BIZ_KYC_BUSINESS_ACTIVITIES = '/verification/business/business_activities',
  BIZ_KYC_TAX_RESIDENCY = '/verification/business/tax_residency',
  BIZ_KYC_INDIVIDUAL_OFFICERS = '/verification/business/individual_officers',
  BIZ_KYC_UPLOAD_DOCUMENTS = '/verification/business/upload_documents',
  BIZ_KYC_DIRECTORS_AND_BO = '/verification/business/directors_and_bo',
  BIZ_KYC_REVIEW = '/verification/business/review',
  BIZ_KYC_COMPLETE = '/verification/business/complete',
  USERS = '/users', // TODO: remove once user_details page is deleted
  DEVELOPER_TOOLS = '/developer_tools',
  TEAM = '/team',
  ERROR = '/error/:code',
  ERROR_403 = '/error/403',
  XFERS_SEND = '/xfers_send',
  XFERS_SEND_DETAIL = '/xfers_send/:transferId',
  LANDING_PAGE = '/',
  GET_STARTED = '/get_started',
  RECEIVE_TRANSACTIONS = '/receive/transactions/:tab?',
  RECEIVE_TRANSACTIONS_DETAIL = '/receive/transactions/:tab/:id',
  PAYOUT_FORM = '/payout_forms',
  PAYOUT_FORM_DETAILS = '/payout_forms/details/:id',
  PAYMENT_LINK = '/payment_link',
  RECIPIENTS = '/recipients',
  LOANS = '/loans',
  CREDITLINE = '/credit-line',
  CREDITLINE_DETAILS = '/credit-line/:id',
  TERM_LOAN = '/term_loan',
  TERM_LOAN_REPAY = '/term_loan/repay',
  TERM_LOAN_DETAILS = '/term_loan/details',
  TERM_LOAN_BILL_DETAILS = '/term_loan/bill/:id',
  TERM_LOAN_PAYMENT_DETAILS = '/term_loan/payment/:id',
  TERM_LOAN_DISBURSEMENT_DETAILS = '/term_loan/disbursement/:id',
  TERM_LOAN_TRANSACTION_DETAILS = '/term_loan/transaction_details/:id/:expenseId',
  PAYOUT_FORM_TRANSACTIONS = '/payout_forms/transactions',
  PAYOUT_FORM_PENDING_APPROVALS = '/payout_forms/pending_approvals',
  PAYOUT_FORM_PENDING_APPROVALS_DETAILS = '/payout_forms/pending_approvals/details/:id',
  PAYOUT_FORM_APPROVAL_SETTINGS = '/payout_forms/approval_settings',
  // not real path intended for route, just for menu with dropdown, fake def as needed in current config usage
  MENU_PAY = 'menu/pay',
  MENU_PAYMENTS_API = 'menu/payments_api',
  REGISTER_MERCHANT = '/register_merchant',
  ACCOUNT_SELECTOR = '/account_select',
  PHYSICAL_CARDHOLDER_KYC = '/physical_cardholder_kyc',
  PHYSICAL_CARD_MY_INFO_CALLBACK = '/callback/card',
  INTEGRATION = '/integrations',
  BLANK = '/blank',
}
