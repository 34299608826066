import * as React from 'react'
import { SVGProps } from 'react'

function SvgFileUpload(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.117 12.317 24.55 2.75a3.332 3.332 0 0 0-2.383-.983h-6.25a10 10 0 0 0-10 10v16.466a10 10 0 0 0 10 10h9.167a10 10 0 0 0 10-10V14.667c0-.88-.347-1.725-.967-2.35Zm-2.75.8H29.95a5.833 5.833 0 0 1-5.833-5.834V5.867l7.25 7.25Zm-6.283 22.616h-9.167a7.5 7.5 0 0 1-7.5-7.5V11.767a7.5 7.5 0 0 1 7.5-7.5h5.7v3.016a8.333 8.333 0 0 0 8.333 8.334h2.634v12.616a7.5 7.5 0 0 1-7.5 7.5Z"
        fill="#121212"
      />
      <path
        d="m22.167 16.133 4.483 4.483a1.215 1.215 0 0 1 .367.884 1.234 1.234 0 0 1-.367.883 1.25 1.25 0 0 1-1.767 0l-3.066-3.067v9.25a1.25 1.25 0 1 1-2.5 0v-9.3l-3.15 3.1A1.25 1.25 0 0 1 14.4 20.6l4.433-4.467a2.299 2.299 0 0 1 1.667-.667 2.25 2.25 0 0 1 1.667.667Z"
        fill="#121212"
      />
    </svg>
  )
}

export default SvgFileUpload
