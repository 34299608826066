import * as React from 'react'

function SvgHodlnaut(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#hodlnaut_svg__clip0)" fill="#2799FB">
        <path d="M23.174 7.205c-.104-.15-.397-.178-.615-.236-2.636-.713-5.275-.752-7.906.004-2.958.848-5.355 2.526-7.186 4.988-1.686 2.264-2.56 4.822-2.666 7.641v.848c.022.827.121 1.65.296 2.46.728 3.344 2.444 6.067 5.2 8.115 1.888 1.404 4.022 2.212 6.355 2.496.649.078 1.303.1 1.956.07 0-.034.006-.047 0-.05-.046-.048-.096-.091-.139-.14-.587-.679-.963-1.431-.782-2.366.277-1.427.547-2.854.83-4.28.309-1.554.649-3.102 1.02-4.644.027-.113.095-.25-.143-.198-.82.182-1.658.221-2.493.296-.889.08-1.518.513-1.773 1.402-.051.18-.113.359-.15.542-.175.883-.427 1.75-.754 2.588-.209.525-.384 1.056-.439 1.623-.03.327-.146.639-.275.943-.137.322-.386.473-.721.502-1.458.12-2.777-1.342-2.52-2.78.138-.76.409-1.482.619-2.22.867-3.04 1.749-6.076 2.195-9.213.12-.835.36-1.655.355-2.504 0-.396.193-.628.51-.804.34-.188.72-.208 1.096-.211.752-.007 1.509-.041 2.237.198.584.191.8.527.809 1.138.006.458-.214.851-.321 1.276a44.13 44.13 0 01-1.138 3.855c-.164.467-.158.468.348.453.669-.023 1.32-.195 1.99-.22.4-.013.794-.123 1.193-.191.127-.022.218-.06.25-.217.334-1.645.72-3.281 1.01-4.933.34-1.93.836-3.81 1.553-5.632.08-.204.315-.433.199-.6zM31.85 17.715c-.5-3.221-2.107-5.887-4.573-8.033-.137-.119-.201-.13-.309.042-.531.852-.74 1.809-1.003 2.762-.632 2.295-1.178 4.612-1.798 6.91-.98 3.63-1.937 7.266-2.55 10.982-.158.968-.22 1.948-.186 2.928 2.166-.5 4.107-1.42 5.762-2.867 3.875-3.386 5.444-7.66 4.657-12.724zM24.112 11.136c.119-.173.43-.639.65-.948.017.068.052.275.073.36.745-.54 1.198-1.483.97-2.034-.034.113-.06.222-.098.325-.112.33-.307.627-.566.86-.098.085-.107.064-.107.064s-.047-.244-.063-.291c-.154.24-.305.508-.494.765.025-.3.088-.596.113-.9-.08.065-.162.152-.218.204-.189-.538-.13-1.045.204-1.54-.685.329-.922 1.658-.547 2.228.024-.033.265-.3.3-.325-.041.226-.076.426-.11.626-.035.2-.072.402-.107.606z" />
        <path d="M24.488 7.801c.16-.04.226 0 .294.147a.796.796 0 001.025.426c.167-.066.251-.035.34.108.18.28.375.55.59.863.266-.69.71-1.287.392-2.034-.063-.146.014-.266.075-.396.692-1.45.85-2.954.432-4.512h-.056c-.009.016-.013.036-.026.045-1.36.849-2.284 2.036-2.773 3.56a.495.495 0 01-.207.283c-.209.143-.491.226-.607.444-.207.389-.344.815-.51 1.23.028.012.058.019.088.022.317-.06.633-.108.943-.186zm1.34-2.26a.517.517 0 01.566-.491.534.534 0 01.49.547c-.02.295-.277.533-.56.515a.533.533 0 01-.497-.571z" />
      </g>
      <defs>
        <clipPath id="hodlnaut_svg__clip0">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgHodlnaut
