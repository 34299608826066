import { get, put } from '../fazzbizBaseApi'
import type { ProductTourStatusResponse, UpdateProductToursStatusPayload } from './onboarding.types'

export const IdOnboardingFlowQueryKey = {
  IdProductTourGetStatus: 'IdProductTourGetStatus',
}

export const IdOnboardingFlowEndpoints = {
  GetProductTourStatus: '/dashboard/product-tours',
  UpdateProductTourStatus: '/dashboard/product-tours/',
} as const

export const getProductTourStatus = () =>
  get<ProductTourStatusResponse>(IdOnboardingFlowEndpoints.GetProductTourStatus)

export const putUpdateProductTour = ({ id, payload }: UpdateProductToursStatusPayload) =>
  put(`${IdOnboardingFlowEndpoints.UpdateProductTourStatus}${id}`, payload)
