import { cx, css } from 'emotion'
import Timeline, { TimelineProps } from 'antd/es/timeline'
import 'antd/es/timeline/style/css'

function CustomTimeline({ className, ...rest }: TimelineProps) {
  const style = `
    // for reverse === true
    &.ant-timeline.ant-timeline-reverse
      > .ant-timeline-item
      > .ant-timeline-item-head-custom {
      top: 40px;
      margin-right: 50px;
    }

    &.ant-timeline.ant-timeline-reverse
      > .ant-timeline-item
      > .ant-timeline-item-tail {
      top: 40px;
    }

    &.ant-timeline.ant-timeline-reverse
      > .ant-timeline-item
      > .ant-timeline-item-content {
      margin-left: 45px;
    }

    // for reverse === false
    &.ant-timeline > .ant-timeline-item > .ant-timeline-item-head-custom {
      top: 40px;
      margin-right: 50px;
    }

    &.ant-timeline > .ant-timeline-item > .ant-timeline-item-tail {
      top: 40px;
    }

    &.ant-timeline > .ant-timeline-item > .ant-timeline-item-content {
      margin-left: 45px;
    }
  `

  return <Timeline {...rest} className={cx(css(style), className)} />
}

CustomTimeline.Item = Timeline.Item

export default CustomTimeline
