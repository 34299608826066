import { Tabs } from 'antd'
import { StyledTab } from './Tab.styles'
import { TabProps } from './Tab.types'

export function Tab({ defaultActiveKey, items, style, className, onChange }: TabProps) {
  return (
    <StyledTab
      onChange={onChange}
      className={className}
      style={style}
      defaultActiveKey={defaultActiveKey}
    >
      {items.map((item) => (
        <Tabs.TabPane tab={item.title} key={item.key} disabled={item.disabled}>
          {item.content}
        </Tabs.TabPane>
      ))}
    </StyledTab>
  )
}

export default Tab
