import { ReactNode } from 'react'
import { Alert } from 'antd'
import styled from '@emotion/styled'
import { FONT_WEIGHT } from '../../../themes/deprecated/TypographyStyles'
import { fazzbizTheme } from '../../../themes/deprecated/theme'

type CustomAlertBannerProps = {
  message: ReactNode
}

function CustomAlertBanner({ message }: CustomAlertBannerProps) {
  return <StyledAlertBanner message={message} banner closable showIcon={false} />
}

const StyledAlertBanner = styled(Alert)`
  z-index: 9999;
  background: linear-gradient(
    269.89deg,
    #00a0ff -12.21%,
    ${fazzbizTheme.base.primary} 32.53%,
    #00c1d5 86.53%
  );

  .anticon-close {
    color: white;

    &:hover {
      color: ${fazzbizTheme.interactive.hoverOnPrimary};
      font-weight: ${FONT_WEIGHT.BOLD};
    }
  }
`

export default CustomAlertBanner
