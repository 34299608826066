/* eslint-disable @typescript-eslint/naming-convention */
// deprecated, please put any new colors on the /constants/colors/*.ts
// then use the theme.ts to give meaningful names
// please import the colors from theme.ts

/** @deprecated please use fazzBizzThemeFds3 colors theme instead */
export enum BLUE_COLORS {
  B400 = '#1863B0',
  B300 = '#2F8DEE',
  B200 = '#C3DCF6',
  B100 = '#EBF5FF',
}

/** @deprecated please use fazzBizzThemeFds3 colors theme instead */
export enum GREEN_COLORS {
  G400 = '#189C08',
  G300 = '#43CC32',
  G200 = '#C8FAC0',
  G100 = '#F0FFF3',
}

/** @deprecated please use fazzBizzThemeFds3 colors theme instead */
export enum RED_COLORS {
  R400 = '#B71515',
  R300 = '#E22726',
  R200 = '#FFC9C9',
  R100 = '#FFF0F0',
}

/** @deprecated please use fazzBizzThemeFds3 colors theme instead */
export enum YELLOW_COLORS {
  Y400 = '#8D7700',
  Y300 = '#E5C412',
  Y200 = '#FFEB7E',
  Y100 = '#FFFBE9',
}

/** @deprecated please use fazzBizzThemeFds3 colors theme instead */
export enum NEUTRAL_COLORS {
  BLACK = '#1B2736',
  G500 = '#777777',
  G400 = '#959595',
  G300 = '#CACACA',
  G200 = '#E8E8E8',
  G100 = '#F6F7F9',
  WHITE = '#FFFFFF',
}

/** @deprecated please use fazzBizzThemeFds3 colors theme instead */
export enum MAIN_COLORS {
  PRIMARY_BLUE = BLUE_COLORS.B300,
  PRIMARY_BLACK = NEUTRAL_COLORS.BLACK,
  PRIMARY_WHITE = NEUTRAL_COLORS.WHITE,
  SECONDARY_GREEN = GREEN_COLORS.G300,
  SECONDARY_RED = RED_COLORS.R300,
  SECONDARY_YELLOW = YELLOW_COLORS.Y300,
}

/** @deprecated please use fazzBizzThemeFds3 colors theme instead */
export enum ICON_COLORS {
  SUCCESS = GREEN_COLORS.G300,
  INFO = BLUE_COLORS.B300,
  WARNING = YELLOW_COLORS.Y300,
  ERROR = RED_COLORS.R300,
  NEUTRAL = NEUTRAL_COLORS.G300,
}

/** @deprecated please use fazzBizzThemeFds3 colors theme instead */
export enum TEXT_COLORS {
  SUCCESS = GREEN_COLORS.G400,
  INFO = BLUE_COLORS.B400,
  WARNING = YELLOW_COLORS.Y400,
  ERROR = RED_COLORS.R400,
  NEUTRAL = NEUTRAL_COLORS.G400,
}

/** @deprecated please use fazzBizzThemeFds3 colors theme instead */
export enum BACKGROUND_COLORS {
  SUCCESS = GREEN_COLORS.G100,
  INFO = BLUE_COLORS.B100,
  WARNING = YELLOW_COLORS.Y100,
  ERROR = RED_COLORS.R100,
  NEUTRAL = NEUTRAL_COLORS.G100,
}
