import { get, post, put } from './fazzbizBaseApi'

enum CustomerCardsQueryKey {
  CustomerCardsTransactions = 'CustomerCardTransaction',
  CustomerCardsCardTransactions = 'CustomerCardsCardTransactions',
  CustomerCardsCardDetails = 'CustomerCardsCardDetail',
  CustomerCardsCardSpendings = 'CustomerCardsCardSpendings',
  CustomerCardsCardStatusLogs = 'CustomerCardsCardStatusLogs',
  CustomerCardsEnrollCardNumber = 'CustomerCardEnrollCardNumber',
  CustomerCardsGetEnrollmentStatus = 'CustomerCardGetEnrollmentStatus',
  CustomerCardsUpdateStatus = 'CustomerCardUpdateStatus',
  CustomerCardsFraudDetails = 'CustomerCardFraudDetails',
  CustomerCardsUserDetails = 'CustomerCardUserDetails',
}

enum CustomerCardsEndpoints {
  CustomerCardIssuing = '/dashboard/consumer-card-issuing',
}

const getCustomerCardsConfig = () => get(`${CustomerCardsEndpoints.CustomerCardIssuing}/status`)
const getCustomerCards = (queryParams: string) =>
  get(`${CustomerCardsEndpoints.CustomerCardIssuing}/${queryParams}`)
const getCustomerCardsTransactionDetails = ({ cbsTransactionId }: { cbsTransactionId: string }) =>
  get(`${CustomerCardsEndpoints.CustomerCardIssuing}/transactions/${cbsTransactionId}`)
const getCustomerCardsTransactionList = (queryString: string) =>
  get(`${CustomerCardsEndpoints.CustomerCardIssuing}${queryString}`)
const getCustomerCardsCardDetail = ({
  cardOpaqueId,
  userOpaqueId,
}: {
  cardOpaqueId: string
  userOpaqueId: string
}) =>
  get(`${CustomerCardsEndpoints.CustomerCardIssuing}/users/${userOpaqueId}/cards/${cardOpaqueId}`)
const getCustomerCardsCardSpendings = ({
  userOpaqueId,
  cardOpaqueId,
}: {
  userOpaqueId: string
  cardOpaqueId: string
}) =>
  get(
    `${CustomerCardsEndpoints.CustomerCardIssuing}/users/${userOpaqueId}/cards/${cardOpaqueId}/spendings`
  )
const getCustomerCardsCardStatusLog = (queryString: string) =>
  get(`${CustomerCardsEndpoints.CustomerCardIssuing}${queryString}`)

// TODO create proper types
const postCustomerCardEnrollCardNumber = ({
  userOpaqueId,
  cardOpaqueId,
  cardNumberOpaqueId,
  requestBody,
}: Record<string, unknown>) =>
  post(
    `${CustomerCardsEndpoints.CustomerCardIssuing}/users/${userOpaqueId}/cards/${cardOpaqueId}/card-numbers/${cardNumberOpaqueId}/acs-enrollment`,
    requestBody
  )

// TODO create proper types
const getCustomerCardEnrollmentStatus = ({
  userOpaqueId,
  cardOpaqueId,
  cardNumberOpaqueId,
}: Record<string, string>) =>
  get(
    `${CustomerCardsEndpoints.CustomerCardIssuing}/users/${userOpaqueId}/cards/${cardOpaqueId}/card-numbers/${cardNumberOpaqueId}/acs-enrollment`
  )

// TODO create proper types
const updateCustomerCardStatus = ({
  userOpaqueId,
  cardOpaqueId,
  requestBody,
}: {
  userOpaqueId: string
  cardOpaqueId: string
  requestBody: Record<string, string>
}) =>
  put(
    `${CustomerCardsEndpoints.CustomerCardIssuing}/users/${userOpaqueId}/cards/${cardOpaqueId}/status`,
    requestBody
  )
const getFraudDetails = (fraudEventOpaqueId: string) =>
  get(`${CustomerCardsEndpoints.CustomerCardIssuing}/fraud-events/${fraudEventOpaqueId}`)
const getUserDetails = (userOpaqueId: string) =>
  get(`${CustomerCardsEndpoints.CustomerCardIssuing}/users/${userOpaqueId}`)
const getUserCardList = (userOpaqueId: string, queryString: string) =>
  get(`${CustomerCardsEndpoints.CustomerCardIssuing}/users/${userOpaqueId}/cards${queryString}`)
const getUserList = (queryString: string) =>
  get(`${CustomerCardsEndpoints.CustomerCardIssuing}/users${queryString}`)
const getCustomerCardResetPinIframe = ({
  userOpaqueId,
  cardOpaqueId,
}: {
  userOpaqueId: string
  cardOpaqueId: string
}) =>
  get(
    `${CustomerCardsEndpoints.CustomerCardIssuing}/users/${userOpaqueId}/cards/${cardOpaqueId}/iframe/pin-reset`
  )

export {
  CustomerCardsQueryKey,
  /* By feature */
  /* customer cards */
  getCustomerCards,
  getCustomerCardsConfig,
  getCustomerCardsTransactionDetails,
  getCustomerCardsTransactionList,
  getCustomerCardsCardDetail,
  getCustomerCardsCardSpendings,
  getCustomerCardsCardStatusLog,
  getCustomerCardEnrollmentStatus,
  postCustomerCardEnrollCardNumber,
  updateCustomerCardStatus,
  getFraudDetails,
  getUserDetails,
  getUserCardList,
  getUserList,
  getCustomerCardResetPinIframe,
}
