import styled from '@emotion/styled'
import { Cross as CrossIcon } from '@fazz/design-system-icons'
import {
  EXTRA_LARGE_SPACING,
  LARGE_SPACING,
  SMALL_SPACING,
} from '../../../themes/deprecated/spacing'
import { useTheme } from '../../../themes/deprecated/themeContext'
import Divider from '../Divider'
import { Body, LabelLarge } from '../Typography'

type ModalContentProps = {
  onClose: () => void
  icon?: React.ReactNode | false
  title?: React.ReactNode
  children: React.ReactNode
  footer?: React.ReactNode
  disabledCloseIcon?: boolean
  className?: string
  maxWidth?: string
}

export default function ModalContent({
  onClose,
  icon,
  title,
  children,
  footer,
  disabledCloseIcon,
  className,
  maxWidth = '400px',
}: ModalContentProps) {
  const { neutral } = useTheme()

  return (
    <ModalContentWrapper className={className || ''} maxWidth={maxWidth}>
      {(icon || title || !disabledCloseIcon) && (
        <ModalHeader hasIcon={Boolean(icon)}>
          <TitleContainer>
            <Title>
              {icon}
              {title && <LabelLarge>{title}</LabelLarge>}
            </Title>
            {!disabledCloseIcon && (
              <Close onClick={() => onClose()}>
                <CrossIcon color={neutral.text} width={18} />
              </Close>
            )}
          </TitleContainer>
          <Divider />
        </ModalHeader>
      )}
      <ModalBody>{children}</ModalBody>
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </ModalContentWrapper>
  )
}

const ModalHeader = styled.div<{ hasIcon: boolean }>`
  ${(props) =>
    props.hasIcon
      ? `
      height: 86px;
    `
      : `
      height: 73px;
    `}
  padding-left: ${EXTRA_LARGE_SPACING};
  padding-right: ${EXTRA_LARGE_SPACING};
`

const ModalBody = styled(Body)`
  padding-top: ${LARGE_SPACING};
  padding-left: ${EXTRA_LARGE_SPACING};
  padding-right: ${EXTRA_LARGE_SPACING};
  padding-bottom: ${EXTRA_LARGE_SPACING};
`

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: ${EXTRA_LARGE_SPACING};
  padding-top: ${SMALL_SPACING};
  padding-bottom: ${SMALL_SPACING};
`

const TitleContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  & :not(:last-child) {
    margin-right: 10px;
  }
`

const Close = styled.div`
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: -5px;
`

const ModalContentWrapper = styled.div<{ className: string; maxWidth: string }>`
  ${({ className, maxWidth }) => `
    width: calc(100vw - 32px);
    max-width: ${maxWidth};
    background: white;
    box-shadow: 0px 4px 10px rgba(170, 170, 170, 0.25);
    border-radius: 5px;
    max-height: 95%;
    overflow-y: auto;
    -webkit-font-smoothing: antialiased;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    ${className}
  `}
`
