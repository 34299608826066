import { SVGProps } from 'react'

function SvgFilterNew(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.73 1.58a1.86 1.86 0 0 1 1.73 1.11 1.86 1.86 0 0 1-.27 2l-5.43 6.39a1.54 1.54 0 0 0-.36 1v7.82a.51.51 0 0 1-.5.51.441.441 0 0 1-.24-.07l-3.74-2.05a.5.5 0 0 1-.26-.44v-5.73a1.5 1.5 0 0 0-.5-1.1l-5.35-6.3a1.86 1.86 0 0 1-.27-2 1.86 1.86 0 0 1 1.73-1.14h13.46Zm0-1.5H4.27a3.41 3.41 0 0 0-2.6 5.61l5.46 6.43v5.73a2 2 0 0 0 1 1.76l3.74 2.06a2 2 0 0 0 3-1.76v-7.82l5.43-6.4A3.41 3.41 0 0 0 17.7.08h.03Z"
        fill="currentColor"
      />
      <path d="M11.43 13H8.14a.75.75 0 1 1 0-1.5h3.29a.75.75 0 1 1 0 1.5Z" fill="currentColor" />
    </svg>
  )
}

export default SvgFilterNew
