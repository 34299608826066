import { Global, css } from '@emotion/core'
import { fdsTheme } from '../theme-fds3'
import './index.css'

const { colors } = fdsTheme

export default function Normalize() {
  return (
    <Global
      styles={css`
        body {
          color: #555555;
          padding: 0;
          margin: 0;
          -webkit-font-smoothing: auto;
          font-family: 'FazzNeue';
          font-size: 14px;
        }

        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
          body {
            -webkit-font-smoothing: antialiased;
          }
        }

        p {
          padding: 0;
          margin: 0;
        }

        a {
          text-decoration: none;
          color: ${colors.primary};

          :hover {
            color: ${colors.hoveredPrimary};
          }
        }

        img {
          vertical-align: middle;
        }

        * {
          padding: 0;
          margin: 0;
          font-family: 'FazzNeue';
          box-sizing: border-box;
        }

        select {
          font-family: 'Roboto';
        }

        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
          color: #000;
          background: ${fdsTheme.colors.surfaceDisabledSurface};
          border-color: ${fdsTheme.colors.primary};
          cursor: pointer;
          border-radius: 100px;
          padding: 5px 10px;
        }

        .ant-picker-range .ant-picker-active-bar {
          background: ${fdsTheme.colors.primary};
        }

        .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
          border-color: ${fdsTheme.colors.primary};
        }
      `}
    />
  )
}
