import type { Moment } from '~/types'

export type Invoice = {
  id: number
  uniqueId: string
  status: string
  customerName: string
  customerEmail?: string
  customerPhoneNumber?: string
  createdDate: string
  dueDate: string
  paidDate?: string
  settledDate?: string
  expiredDate?: string
  customerReference: string
  description?: string
  amount: string
  currency: string
  invoiceURL?: string
}

export type FilterValuesObject = {
  [filterKey: string]:
    | Moment
    | string[]
    | number[]
    | string
    | number
    | Invoice[]
    | boolean
    | undefined
}

export type FilterValues = {
  startDate: Moment | string
  endDate: Moment | string
  invoiceStatuses: string[]
  orderBy: string
  order: string
  currentPage: number
  pageLimit: number
  searchString: string
  isInvoiceLoading: boolean
  invoices: Invoice[]
  totalCount: number
  [key: string]: FilterValuesObject[keyof FilterValuesObject]
}

export type FilterOptions = {
  invoiceStatuses: Array<{ label: string; value: string }>
  sortTypes: Array<{ label: string; value: string }>
}

export enum InvoicingActions {
  GET_INVOICES = 'INVOICING_ACTIONS.GET_INVOICES',
  SET_IS_INVOICE_LOADING = 'INVOICING_ACTIONS.SET_IS_INVOICE_LOADING',
  SET_FILTERS = 'INVOICING_ACTIONS.SET_FILTERS',
  SET_INVOICES_FILTER_OPTIONS = 'INVOICING_ACTIONS.SET_INVOICES_FILTER_OPTIONS',
}

type GetInvoices = {
  type: typeof InvoicingActions.GET_INVOICES
  invoices: Invoice[]
  totalCount: number
}

type SetIsInvoiceLoading = {
  type: typeof InvoicingActions.SET_IS_INVOICE_LOADING
  isInvoiceLoading: boolean
}

type SetFilters = {
  type: typeof InvoicingActions.SET_FILTERS
  filterValuesObject: FilterValuesObject
}

type SetInvoiceFilterOptions = {
  type: typeof InvoicingActions.SET_INVOICES_FILTER_OPTIONS
  filterOptions: FilterOptions
}

export type ActionTypes =
  | GetInvoices
  | SetIsInvoiceLoading
  | SetFilters
  | SetInvoiceFilterOptions
  | SetInvoiceFilterOptions
