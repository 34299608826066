import { cx, css } from 'emotion'
import styled from '@emotion/styled'
import { Tick, WarningFilled, CrossMini, InfoFilled } from '@fazz/design-system-icons'
import { useTheme } from '../../../themes/deprecated/themeContext'

type BannerProps = {
  type?: 'primary' | 'success' | 'warning' | 'error'
  withIcon?: boolean
  children: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

export default function DeprecatedBanner({
  withIcon = false,
  children,
  type = 'primary',
  ...rest
}: BannerProps) {
  const { base, brand, success, spacing, status } = useTheme()

  const primaryStyle = `
    color: ${base.neutral};
    background: ${brand.primary.background};
    `
  const successStyle = `
    color: ${status.positive};
    background: ${success.background};
    `
  const warningStyle = `
    color: ${status.warning};
    background: ${status.subdueWarning};
    `
  const errorStyle = `
    color: ${status.critical};
    background: ${status.subdueCritical};
    `

  const getTypeStyle = () => {
    switch (type) {
      case 'success':
        return successStyle
      case 'warning':
        return warningStyle
      case 'error':
        return errorStyle
      case 'primary':
      default:
        return primaryStyle
    }
  }

  const StyledBanner = styled.div`
    padding: ${spacing.sm};
    border-radius: 5px;
    display: flex;
    vertical-align: middle;
    ${getTypeStyle()}
  `
  const svgStyle = css`
    display: block;
    margin: auto 6px auto 0;
  `
  const icon = () => {
    switch (type) {
      case 'primary':
        return (
          <InfoFilled
            style={{ minHeight: '18px', minWidth: '18px' }}
            color={brand.primary.emphasis}
            className={cx(svgStyle)}
          />
        )
      case 'success':
        return (
          <Tick
            style={{ minHeight: '18px', minWidth: '18px' }}
            color={status.positive}
            className={cx(svgStyle)}
          />
        )
      case 'warning':
        return (
          <WarningFilled
            style={{ minHeight: '18px', minWidth: '18px' }}
            color={status.warning}
            className={cx(svgStyle)}
          />
        )
      case 'error':
        return (
          <CrossMini
            style={{ minHeight: '18px', minWidth: '18px' }}
            color={status.critical}
            className={cx(svgStyle)}
          />
        )
      default:
        return null
    }
  }

  return (
    <StyledBanner {...rest}>
      {withIcon && icon()}
      {children}
    </StyledBanner>
  )
}
