import { SVGProps } from 'react'

function SvgReceive(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#receiveNew_svg__a)" fill="currentColor">
        <path d="M18.858 9.767a2.041 2.041 0 0 0-3.45-.717l-.225.25a3.917 3.917 0 0 1-2.2 1.233l-.6.109h-3.4a7.216 7.216 0 0 0-6.625 2.066l-.225-.208a.617.617 0 0 0-.833-.075.625.625 0 0 0-.075.883l5 5.834a.632.632 0 0 0 .475.216.625.625 0 0 0 .442-1.025l-.117-.141.325-.334a.198.198 0 0 1 .15-.075h2.108a9.366 9.366 0 0 0 8.159-4.741l.925-1.609a2.051 2.051 0 0 0 .166-1.666Zm-1.25 1.066-.941 1.592a8.001 8.001 0 0 1-1.242 1.667 8.217 8.217 0 0 1-5.833 2.458H7.5a1.45 1.45 0 0 0-1.033.425l-.259.258-3.041-3.575a5.966 5.966 0 0 1 5.566-1.8h3.575a.658.658 0 1 1 0 1.317h-3.65c-.362 0-.712.133-.983.375l-.5.458a.625.625 0 1 0 .842.925l.5-.45a.217.217 0 0 1 .141-.058h3.675a1.89 1.89 0 0 0 1.625-2.892 5.21 5.21 0 0 0 2.159-1.425l.225-.241a.791.791 0 0 1 1.333.275.758.758 0 0 1-.067.691ZM10.142 4.692c-2.117 0-4.4-.634-4.4-2.017S8.025.65 10.142.65c2.116 0 4.4.633 4.4 2.025s-2.284 2.017-4.4 2.017ZM7.008 2.675c.15.242 1.217.767 3.134.767 1.916 0 3.066-.567 3.158-.792-.092-.175-1.167-.75-3.158-.75-1.992 0-2.984.533-3.134.775Z" />
        <path d="M10.142 9.558c-1.925 0-4.017-.533-4.342-1.725a.633.633 0 0 1 .442-.766.625.625 0 0 1 .766.433c.092.225 1.15.833 3.134.833 1.983 0 3.05-.575 3.133-.833a.626.626 0 0 1 1.208.325c-.316 1.2-2.408 1.733-4.341 1.733Z" />
        <path d="M10.142 7.092c-1.925 0-4.017-.525-4.309-1.717a.633.633 0 0 1 .442-.767.625.625 0 0 1 .767.442c.091.225 1.15.833 3.133.833s3.05-.566 3.133-.833a.626.626 0 0 1 1.209.325c-.35 1.192-2.442 1.717-4.375 1.717Z" />
      </g>
      <defs>
        <clipPath id="receiveNew_svg__a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgReceive
