export type MyInstallments = {
  data: InstallmentDetails[]
}

export type InstallmentDetails = {
  billCounter: number
  billId: string
  dueDate: string
  externalStatus: string
  fixedPayment: number
  installmentBillId: number
  interest: number
  paidFee: number
  paidInterest: number
  paidPrincipal: number
  principal: number
  status: string
}

export type VirtualBankAccount = {
  paymentMethodId: string
  vANumber: string
  displayName: string
  bankShortCode: string
}

export type UserBillDetail = {
  outstandingPrincipal: number
  totalOutstandingRepayment: number
}

export type LoanDetails = {
  loanPrincipal: number
  disbursementDate: string
  interestPa: number
  interestAmount: number
  totalFixedRepayment: number
  disbursedAmount: number
  loanTenure: number
  tenureUnit: string
  processingFee: number
}

export type LoanProfile = {
  id: string
  userId: string
  limit: number
  adminFee: number
  adminFeeUnit: string
  dueType: string
  cutOff: number
  loanType: string
  entityName: string
  status: string
  minimumPaymentPercentage: number
  loanTenor: number
  tenorUnit: string
  loanScheme: string
  subLoanScheme: string
  dueDate: number
  gracePeriod: number
  period: number
  cashAdvanceTransferFee: number
  cashAdvanceTransferFeeMin: number
  cashAdvanceTransferFeeMax: number
  supplierTransferFee: number
  supplierTransferFeeMin: number
  supplierTransferFeeMax: number
  lateFee: number
  lateFeeUnit: string
  lateFeeMinimum: number
  lateFeeMaximum: number
  loanApplicationId: string
  provisionFee: number
  provisionFeeMin: number
  provisionFeeMax: number
  tax: number
  interestServicingPeriod: number
  sourceOfFund: string
}

export type UserDetails = {
  id: string
  status: string
  balance: number
  keycloakId: string
  businessName: string
  merchantId: string
  ownerName: string
  phoneNumber: string
  cashAdvanceEnabled: boolean
  virtualBankAccounts: VirtualBankAccount[]
  billDetail: UserBillDetail
  loanProfile: LoanProfile
  principal?: number
  creditLimit?: number
}

export type BillDetails = {
  billId: string
  installmentBillId: string
  billCounter: number
  fixedPayment: number
  principal: number
  interest: number
  dueDate: string
  status: string
  paidPrincipal: number
  paidFee: number
  paidInterest: number
  externalStatus: string
}

export type DisbursementDetails = {
  disbursementId: string
  expenseId: string
  transactionDate: string
  description: string
  amount: number
  paymentStatus: string
}

export type DueRepaymentDetails = {
  billCounter: number
  principal: number
  interest: number
  outstanding: number
  lateFee: number
  otherFee: number
}

export type OutstandingRepayment = {
  dueRepayments: DueRepaymentDetails[]
  accumulativeLateFee: number
  accumulativeOtherFee: number
  totalOutstandingRepayment: number
}

export type RepaymentDetails = {
  repaymentId: string
  status: string
  transactionDate: string
  description: string
  amount: number
}

export type RepaymentHistoryDetails = {
  id?: string
  userId?: string
  billId?: string
  billNumber?: string
  paymentMethod: string
  repaymentLink?: string
  repaymentNumber?: string
  businessName?: string
  paidAmount: number
  paidDate: string
  status: string
  detail?: string
  createdById?: string
  createdByName?: string
  createdTime: string
  updatedTime?: string
  sourceOfFund?: string
}

export type InstallmentBillDetails = {
  billId: string
  status: string
  dueDate: string
  daysPastDue: number
  repaymentAmount: number
  remainingOutstandingPrincipal: number
  paymentDate: string
  paidInterest: number
  paidPrincipal: number
  lateFee: number
  otherFee: number
  externalStatus: string
  fixedPayment: number
  amountPaid: number
  amountToBePaid: number
  remainingTotalOutstanding: number
}

export type PaymentDetails = {
  amount: number
  paymentId: string
  status: string
}

export type TransferDetails = {  
  id: string
  type: string
  attributes: TransferDetailsAttributes
}

export type Meta = {
  pages: number
  total: number
}

export type TransferDetailsAttributesDetails = {  
  note: string
  amount: number
  status: string
  userId: string
  outletId: string
  vendorId: string
  adminNote: string
  invoiceNo: string
  categoryId: number
  vendorName: string
  vendorType: string
  invoiceDate: string
  invoiceLink: string
  transferFee: number
  updatedTime: string
  businessName: string
  categoryName: string
  merchantName: string
  transferDone: string
  merchantEmail: string
  merchantPhone: string
  vendorBankName: string
  fpInvoiceNumber: string
  vendorBankNumber: string
  transferRequestTime: string
  vendorBankOwnerName: string
  ownerRejectNote?: string
  expenseList?: FlexpayExpense[]
  paymentStatus?: string
}

export type TransferDetailsAttributes = {  
  status: string
  details: TransferDetailsAttributesDetails
  createdAt: string
  externalId: string
  category: string
  searchable: object
  orderType: string
}

export type DisbursementList = {
  data: TransferDetails[]
  meta: Meta
}

export type FlexpayExpense = {
  invoiceAmount: number
  invoiceDate: string
  invoiceId: string
  invoiceNumber: string
}

export const OrderType = {
  cashTransfer: '"SgTermLoanCashAdvanceDisbursementOrder"',
  supplierTransfer: 'SgTermLoanDisbursementOrder',
}

export const DisbursementType = {
  cashTransfer: "Cash Advance",
  supplierTransfer: "Supplier Transfer"
}

export const VendorType = {
  cashTransfer: 'cash_advance',
  supplierTransfer: 'supplier_transfer'
}

export const TermLoanPageActions = {
  SET_TERM_LOAN_USER_DETAILS: 'TermLoanPageActions.SET_TERM_LOAN_USER_DETAILS',
  SET_TERM_LOAN_BILLS: 'TermLoanPageActions.SET_TERM_LOAN_BILLS',
  SET_TERM_LOAN_OUTSTANDING_REPAYMENT: 'TermLoanPageActions.SET_TERM_LOAN_OUTSTANDING_REPAYMENT',
  SET_TERM_LOAN_REPAYMENTS: 'TermLoanPageActions.SET_TERM_LOAN_REPAYMENTS',
  SET_TERM_LOAN_DISBURSEMENTS: 'TermLoanPageActions.SET_TERM_LOAN_DISBURSEMENTS',
  SET_TERM_LOAN_DETAILS: 'TermLoanPageActions.SET_TERM_LOAN_DETAILS',
  SET_TERM_LOAN_INITIALISED: 'TermLoanPageActions.SET_TERM_LOAN_INITIALISED',
}

export type SetTermLoanUserDetails = {
  type: typeof TermLoanPageActions.SET_TERM_LOAN_USER_DETAILS
  userDetails: UserDetails
}

export type SetTermLoanBills = {
  type: typeof TermLoanPageActions.SET_TERM_LOAN_BILLS
  bills: BillDetails[]
}

export type SetTermLoanDisbursements = {
  type: typeof TermLoanPageActions.SET_TERM_LOAN_DISBURSEMENTS
  disbursements: DisbursementDetails[]
}

export type SetTermLoanOutstandingRepayments = {
  type: typeof TermLoanPageActions.SET_TERM_LOAN_OUTSTANDING_REPAYMENT
  outstandingRepayment: OutstandingRepayment
}

export type SetTermLoanRepayments = {
  type: typeof TermLoanPageActions.SET_TERM_LOAN_REPAYMENTS
  repayments: RepaymentDetails[]
}

export type SetTermLoanInitialised = {
  type: typeof TermLoanPageActions.SET_TERM_LOAN_INITIALISED
  isTermLoanInitialised: boolean
}

export type ActionTypes =
  | SetTermLoanUserDetails
  | SetTermLoanBills
  | SetTermLoanDisbursements
  | SetTermLoanOutstandingRepayments
  | SetTermLoanRepayments
  | SetTermLoanInitialised

export type GetTermLoanUserDetailsProps = {
  initialLoad?: boolean
  callback?: () => void
}