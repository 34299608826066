import moment from 'moment'
import type { State, WalletType } from './types'
import { DisbursementActions } from './types'

// IMPORTANT! Read when you change this file
// Run `date +%s` on your terminal and paste that here every time you change the schema
// of the disbursement subtree
// this will reset the storage and throw away old schema data
const DB_VERSION = '1629268491'

export const loadDisbursementState = (initialState: State, selectedWalletType?: WalletType) => {
  try {
    const dbVersion = localStorage.getItem('disbursement-state-version')
    if (dbVersion !== DB_VERSION) {
      localStorage.removeItem('disbursement-state')
      return initialState
    }
    const serializedState = localStorage.getItem('disbursement-state')
    if (serializedState === null) {
      return initialState
    }
    const disbursementState = JSON.parse(serializedState)
    const recipients = new Map(JSON.parse(disbursementState.recipients))
    const banks = new Map(JSON.parse(disbursementState.banks))
    const uploadedCsv = disbursementState.uploadedCsv
      ? JSON.parse(disbursementState.uploadedCsv)
      : undefined
    if (disbursementState.lastUpdated) {
      // if >24hrs ago return undefined
      const hour = 1000 * 60 * 60 * 24
      const oneDayAgo = Date.now() - hour
      if (moment(disbursementState.lastUpdated) < moment(oneDayAgo)) {
        return initialState
      }
    }
    return {
      ...disbursementState,
      banks,
      recipients,
      uploadedCsv,
      selectedWalletType,
    }
  } catch (err) {
    console.error(err) // eslint-disable-line no-console
    return initialState
  }
}

export const saveDisbursementMiddleware =
  ({ dispatch, getState }: any) =>
  (next: any) =>
  (action: any) => {
    if (typeof action === 'function') {
      next(action(dispatch, getState))
    }
    next(action)
    if (Object.values(DisbursementActions).includes(action.type)) {
      try {
        const disbursement = getState().disbursements
        const recipientsJSON = JSON.stringify(Array.from(disbursement.recipients.entries()))
        const banksJSON = JSON.stringify(Array.from(disbursement.banks.entries()))
        const uploadedCsvJSON = JSON.stringify(disbursement.uploadedCsv) // this is a lossy serialization.
        const disbursementState = {
          recipients: recipientsJSON,
          banks: banksJSON,
          uploadMethod: disbursement.uploadMethod,
          paymentMethod: disbursement.paymentMethod,
          confirmedDisbursementStatement: disbursement.confirmedDisbursementStatement,
          uploadedCsv: uploadedCsvJSON,
          lastUpdated: disbursement.lastUpdated,
          errorMessage: disbursement.errorMessage,
        }
        localStorage.setItem('disbursement-state-version', DB_VERSION)
        localStorage.setItem('disbursement-state', JSON.stringify(disbursementState))
      } catch {
        // ignore write errors
      }
    }
  }
