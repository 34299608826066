import * as React from 'react'

function SvgXfersLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 101 33" fill="none" {...props}>
      <path
        d="M9.324 21.286L3.466 10.725h6.849l3.093 6.559 5.157-6.56h7.014l-9.364 11.593 5.692 10.315h-6.684L12.13 26.32l-4.991 6.312H0l9.324-11.346zM26.816 11.467l3.63-.742.454-2.93c.909-5.652 3.095-7.59 7.962-7.59 1.857 0 3.92.453 5.034.989l-.536 3.465-3.713-.04c-2.435-.042-2.64 1.03-3.177 4.538l-.249 1.568h5.282l-.7 4.413H35.48l-2.848 17.494h-5.775l2.847-17.494h-3.466l.578-3.671zM56.147 17.407c.373-2.022-.74-2.476-3.052-2.435-3.919.04-4.29 2.558-4.827 5.28 0 0 7.18 1.198 7.88-2.845zm-2.64-7.097c4.703-.041 8.91 1.278 8.582 6.313-.66 9.983-12.749 8.045-14.275 7.425-.578 3.343.249 4.209 3.177 4.25 2.435.083 6.395-.288 8.953-.702l-.703 4.125c-3.298.992-6.848 1.487-11.427 1.197-5.239-.246-6.931-4.661-5.447-12.665 1.28-6.726 3.097-9.902 11.14-9.943zM66.212 10.725h4.867l.456 2.763c1.772-1.485 4.042-2.97 6.764-2.97.95 0 1.858 0 2.394.248l-.867 5.57a47.328 47.328 0 00-3.135-.125c-2.394 0-4.208.414-5.983 2.435l-2.186 13.986h-5.776l3.466-21.907zM80.94 28.135h10.316c.37 0 1.444-.042 1.692-1.402l.205-1.115c.124-.907-.082-1.402-1.278-1.402h-4.91c-3.879 0-6.065-2.145-5.405-6.354l.331-1.98c.536-3.466 2.392-5.446 6.642-5.446 4.207 0 9.942.123 11.469 1.114l-.578 3.63h-9.901c-1.486 0-1.815.744-1.938 1.527l-.166 1.073c-.289 1.609.702 1.402 1.485 1.402h5.034c4.207 0 5.61 2.93 5.074 6.107l-.371 2.516c-.702 4.496-3.755 5.115-6.271 5.115-4.166 0-10.645-.163-12.007-1.197l.577-3.588z"
        fill="#2F8DEE"
      />
    </svg>
  )
}

export default SvgXfersLogo
