import type { Reducer } from 'redux'
import type { FilterOptions, FilterValues, ActionTypes, CsvFormatVersion } from './types'
import { TransactionsActions, TransactionsTabKey } from './types'

const initialState = {
  filterOptions: null,
  filterValues: {
    [TransactionsTabKey.XFERS_ACCOUNT]: {
      startDate: '',
      endDate: '',
      transactionStatuses: [],
      transactionTypes: [],
      sortType: '',
      accountIds: [],
      currentPage: 1,
      pageLimit: 10,
      totalCount: 0,
      searchString: '',
      contractsExist: false,
      transactions: undefined,
      isTxnLoading: false,
      isPaginationLoading: false,
    },
    [TransactionsTabKey.XFERS_WALLET]: {
      startDate: '',
      endDate: '',
      transactionStatuses: [],
      transactionTypes: [],
      sortType: '',
      accountIds: [],
      currentPage: 1,
      pageLimit: 10,
      totalCount: 0,
      searchString: '',
      contractsExist: false,
      transactions: undefined,
      isTxnLoading: false,
      isPaginationLoading: false,
    },
    [TransactionsTabKey.MANAGED_ACCOUNT]: {
      startDate: '',
      endDate: '',
      transactionStatuses: [],
      transactionTypes: [],
      sortType: '',
      accountIds: [],
      currentPage: 1,
      pageLimit: 10,
      totalCount: 0,
      searchString: '',
      contractsExist: false,
      transactions: undefined,
      isTxnLoading: false,
      isPaginationLoading: false,
    },
    [TransactionsTabKey.CREDIT_LINE]: {
      startDate: '',
      endDate: '',
      transactionStatuses: [],
      transactionTypes: [],
      sortType: '',
      accountIds: [],
      currentPage: 1,
      pageLimit: 10,
      totalCount: 0,
      searchString: '',
      contractsExist: false,
      transactions: undefined,
      isTxnLoading: false,
      isPaginationLoading: false,
    },
  },
  csvFormatVersion: null,
  transactionsTabKey: null,
  transactionTabOptions: [],
  viewType: '',
}

type State = {
  filterOptions: null | {
    [TransactionsTabKey.XFERS_ACCOUNT]: FilterOptions
    [TransactionsTabKey.XFERS_WALLET]: FilterOptions
    [TransactionsTabKey.MANAGED_ACCOUNT]: FilterOptions
    [TransactionsTabKey.CREDIT_LINE]: FilterOptions
  }
  filterValues: {
    [TransactionsTabKey.XFERS_ACCOUNT]: FilterValues
    [TransactionsTabKey.XFERS_WALLET]: FilterValues
    [TransactionsTabKey.MANAGED_ACCOUNT]: FilterValues
    [TransactionsTabKey.CREDIT_LINE]: FilterValues
  }
  csvFormatVersion: null | CsvFormatVersion
  transactionsTabKey: null | TransactionsTabKey
  transactionTabOptions: TransactionsTabKey[]
  viewType: string
}

export const transactions: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case TransactionsActions.SET_IS_TXN_LOADING: {
      const { transactionsTabKey } = state

      if (transactionsTabKey === null) throw new Error('transactionsTabKey cannot be null!')

      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [transactionsTabKey]: {
            ...state.filterValues[transactionsTabKey],
            isTxnLoading: action.isTxnLoading,
          },
        },
      }
    }

    case TransactionsActions.SET_IS_FILTER_LOADING: {
      return {
        ...state,
        isFilterLoading: action.isFilterLoading,
      }
    }

    case TransactionsActions.SET_IS_PAGINATION_LOADING: {
      return {
        ...state,
        isPaginationLoading: action.isPaginationLoading,
      }
    }

    case TransactionsActions.GET_TRANSACTIONS: {
      const { transactionsTabKey } = state

      if (transactionsTabKey === null) throw new Error('transactionsTabKey cannot be null!')

      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [transactionsTabKey]: {
            ...state.filterValues[transactionsTabKey],
            transactions: action.transactions,
            contractsExist: action.contractsExist,
            isTxnLoading: false,
          },
        },
      }
    }

    case TransactionsActions.GET_TRANSACTIONS_TOTAL_COUNT: {
      const { transactionsTabKey } = state

      if (transactionsTabKey === null) throw new Error('transactionsTabKey cannot be null!')

      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [transactionsTabKey]: {
            ...state.filterValues[transactionsTabKey],
            totalCount: action.totalCount,
            isPaginationLoading: false,
          },
        },
      }
    }

    case TransactionsActions.GET_TRANSACTION_FILTER_OPTIONS: {
      return {
        ...state,
        filterOptions: action.filterOptions,
        viewType: action.viewType,
      }
    }

    case TransactionsActions.SET_FILTERS: {
      const { transactionsTabKey } = state

      if (transactionsTabKey === null) throw new Error('transactionsTabKey cannot be null!')

      const { filterValuesObject } = action

      const newTabFilterValues: FilterValues = {
        ...state.filterValues[transactionsTabKey],
      }

      Object.keys(filterValuesObject).forEach((key: string) => {
        const values = filterValuesObject[key]
        newTabFilterValues[key] = values
      })

      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [transactionsTabKey]: {
            ...state.filterValues[transactionsTabKey],
            ...newTabFilterValues,
          },
        },
      } as State
    }

    case TransactionsActions.RESET_CURRENT_PAGE: {
      const { transactionsTabKey } = state

      if (transactionsTabKey === null) throw new Error('transactionsTabKey cannot be null!')

      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [transactionsTabKey]: {
            ...state.filterValues[transactionsTabKey],
            currentPage: 1,
          },
        },
      }
    }

    case TransactionsActions.SET_CsvFormatVersion: {
      return {
        ...state,
        csvFormatVersion: action.csvFormatVersion,
      }
    }

    case TransactionsActions.SWITCH_TRANSACTIONS_TAB: {
      return {
        ...state,
        transactionsTabKey: action.transactionsTabKey,
      }
    }

    case TransactionsActions.GET_TRANSACTION_TAB_OPTIONS: {
      return {
        ...state,
        transactionTabOptions: action.transactionTabOptions,
      }
    }

    default:
      return state
  }
}
