import { fdsTheme } from 'fds3-src/themes/theme-fds3'
import styled, { css } from 'styled-components'
import { Menu } from 'antd'

// Retrieve all theming data
const { spacing, colors, fontStyles, rootPrimitives, radius } = fdsTheme
const iconSize = 18

export const sandboxTheme = {
  baseTextColor: colors.onContainer,
  hoveredTextColor: rootPrimitives.russianViolet,
  hoveredBackgroundColor: colors.hoveredSurface,
  activeBackgroundColor: colors.surfaceSubdueInformation,
  activeTextColor: colors.active,
}

export const defaultTheme = {
  baseTextColor: colors.onNeutralSecondary,
  hoveredTextColor: colors.onNeutralSecondary,
  hoveredBackgroundColor: colors.hoveredSurface,
  activeBackgroundColor: colors.surfaceSubdueInformation,
  activeTextColor: colors.active,
}

export const menuThemes = {
  sandbox: sandboxTheme,
  default: defaultTheme,
}

const baseStyles = {
  bg: css`
    border-radius: ${radius.sm};
  `,
  font: css`
    ${fontStyles.bodyMedium};
    color: ${({ theme }) => theme.baseTextColor};
  `,
  layout: css`
    display: flex;
    align-items: center;

    svg {
      min-width: ${iconSize}px;
    }
    span {
      display: flex;
      flex-shrink: 1;
      min-width: 0;
    }
  `,
}
const hoverStyles = {
  bg: css`
    background-color: ${({ theme }) => theme.hoveredBackgroundColor};
  `,
  font: css`
    color: ${({ theme }) => theme.hoveredTextColor};
  `,
}

const activeStyles = {
  bg: css`
    background-color: ${({ theme }) => theme.activeBackgroundColor};
  `,
  font: css`
    color: ${({ theme }) => theme.activeTextColor};
  `,
}

export const iconStyles = {
  base: {
    width: iconSize,
    height: iconSize,
    style: {
      verticalAlign: 'text-bottom',
      marginRight: spacing.xs,
    },
  },
}
const StyledMenu = styled(Menu)`
  width: 232px;
  padding: 0 ${spacing.sm};
  border: none;
  background-color: inherit;
`

const StyledMenuItem = styled(StyledMenu.Item)`
  ${baseStyles.font};
  ${baseStyles.bg};
  ${baseStyles.layout};

  :hover {
    ${hoverStyles.font};
    ${hoverStyles.bg};
  }

  a {
    ${baseStyles.font};

    :hover {
      ${hoverStyles.font};
    }
  }

  .ant-menu:not(.ant-menu-horizontal) &.ant-menu-item-selected {
    ${activeStyles.font}
    ${activeStyles.bg}

    a {
      ${activeStyles.font}
    }
    :after {
      display: none;
    }
  }
`

const StyledSubMenu = styled(StyledMenu.SubMenu)`
  .ant-menu-sub {
    background-color: inherit;
    padding-left: ${spacing.lg};
  }
  .ant-menu-submenu-title {
    ${baseStyles.font};
    ${baseStyles.bg};
    ${baseStyles.layout};

    a {
      ${baseStyles.font};

      :hover {
        ${hoverStyles.font};
      }
    }

    .ant-menu-submenu-arrow {
      :before,
      :after {
        background: ${({ theme }) => theme.baseTextColor};
      }
    }

    :hover {
      ${hoverStyles.font};
      ${hoverStyles.bg};

      .ant-menu-submenu-arrow {
        :before,
        :after {
          background: ${({ theme }) => theme.baseTextColor};
        }
      }
    }
  }

  &.ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      ${activeStyles.font};

      .ant-menu-submenu-arrow {
        :before,
        :after {
          background: ${({ theme }) => theme.activeTextColor};
        }
      }

      :hover {
        ${hoverStyles.font};

        .ant-menu-submenu-arrow {
          :before,
          :after {
            background: ${({ theme }) => theme.activeTextColor};
          }
        }
      }
    }
  }
`

const StyledDivider = styled(StyledMenu.Divider)`
  && {
    background-color: ${colors.line};
  }
`

StyledMenu.Item = StyledMenuItem as unknown as typeof Menu.Item
StyledMenu.SubMenu = StyledSubMenu as unknown as typeof Menu.SubMenu
StyledMenu.Divider = StyledDivider as unknown as typeof Menu.Divider

const MenuItemContainer = styled.div`
  display: flex;
  gap: ${spacing.xs};
  min-width: 0;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const MenuItemLabel = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export { StyledMenu, MenuItemContainer, MenuItemLabel }
