import styled from 'styled-components'
import { Alert } from 'antd'
import { fdsTheme } from '../../../themes/theme-fds3'
import type { BannerProps, BannerVariantType } from './Banner.types'

// Retrieve all theming data
const { colors, spacing } = fdsTheme

type BannerExtendedProps = BannerProps & {
  breakLine: boolean
}

const getPrimaryColor = (variant?: BannerVariantType) => {
  switch (variant) {
    case 'positive':
      return colors.positive
    case 'information':
      return colors.information
    case 'warning':
      return colors.warning
    case 'critical':
      return colors.critical
    default:
      return colors.information
  }
}

const getBackgroundColor = (variant?: BannerVariantType) => {
  switch (variant) {
    case 'positive':
      return colors.surfaceSubduePositive
    case 'information':
      return colors.surfaceSubdueInformation
    case 'warning':
      return colors.surfaceSubdueWarning
    case 'critical':
      return colors.surfaceSubdueCritical
    default:
      return colors.surfaceSubdueInformation
  }
}

export const StyledBanner = styled(Alert).attrs((props: BannerExtendedProps) => ({
  variant: props.variant,
  breakLine: props.breakLine,
}))`
  ${({ variant, breakLine }) =>
    `
    &.ant-alert {
      display: flex;
      border: 1px solid ${getPrimaryColor(variant)};
      background-color: ${getBackgroundColor(variant)};
      min-height: 48px;
      padding: ${spacing.sm};
      gap: ${spacing.xs};
      align-items: center;
      border-radius: ${spacing.xxs};

      .ant-alert-icon {
        width: 24px;
        height: 24px;
      }

      .ant-alert-message {
        flex-grow: 1;

        div {
          display: flex;
          flex-direction: row;
        }
      }

      .ant-alert-close-icon {
        background-color: transparent;
        border: none;
        color: ${getPrimaryColor(variant)};
        cursor: pointer;
        height: 24px;
        width: 24px;
        align-self: ${breakLine ? 'flex-start' : 'center'};
        padding: 0;

        .ant-alert-close-text {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .ant-alert-icon {
        align-self: ${breakLine ? 'flex-start' : 'center'};
        min-width: 24px!important;
        min-height: 24px!important;
        color: ${getPrimaryColor(variant)};
      }
    }
  `}
`
