import { createGlobalStyle } from 'styled-components'
import { fdsTheme } from '../../../themes/theme-fds3'

const { spacing, colors, radius, fontStyles } = fdsTheme

export const TooltipGlobalStyle = createGlobalStyle`
  body {
    .ant-tooltip {
      ${fontStyles.bodyMedium}
    }

    .ant-tooltip-inner {
      padding: ${spacing.xs} ${spacing.sm};
      border-radius: ${radius.xs};
      background-color: ${colors.onNeutral}
    }
  }
`
