/* eslint-disable max-lines */
import * as React from 'react'

function SvgQcpCapital(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#qcp-capital_svg__clip0)">
        <path
          d="M17.971 33.596c8.6 0 15.573-6.972 15.573-15.572 0-8.6-6.973-15.573-15.573-15.573-8.6 0-15.573 6.972-15.573 15.573 0 8.6 6.972 15.572 15.573 15.572z"
          fill="#255D7E"
        />
        <path
          d="M20.77 33.284a6.068 6.068 0 01-1.248.216c-4.613.372-8.66-.95-12.097-4.074-.144-.129-.282-.266-.421-.4.065-.17.17-.066.248-.026.759.396 1.557.712 2.382.941 1.275.348 2.595.5 3.915.454.395-.019.788.05 1.153.202.774.319 1.588.501 2.405.68.088.013.175.033.259.06-.403-.1-.75-.367-1.134-.567a.299.299 0 01-.19-.206c.083-.113.196-.067.294-.044.73.166 1.471.28 2.217.34.505.042 1.011.046 1.516.012 1.292-.079 2.567-.34 3.787-.775a13.454 13.454 0 004.706-2.848 14.376 14.376 0 002.026-2.365c-.844.426-1.7.652-2.565.825a.805.805 0 01-.375.037c-.128-.176.066-.22.139-.273.52-.39.96-.865 1.422-1.316.242-.236.466-.49.67-.758.136-.17.28-.334.434-.489.151-.117.285-.256.396-.413.068-.087.136-.189.27-.192.027.012.05.035.062.062.122.485.209.977.26 1.474a.568.568 0 01-.056.278c-.061.114-.13.198.039.304.104.066 0 .235.034.355a.428.428 0 01-.06.199c-.24.34-.454.703-.665 1.06a.555.555 0 01-.227.253c-.145.062-.205.206-.297.314-1.815 2.142-4.042 3.65-6.727 4.474-.866.261-1.758.428-2.66.497-.792.07-1.59.07-2.381 0 1.467.495 2.34.656 3.674.647a7.449 7.449 0 001.614-.218c.114-.026.227-.077.34-.013.048.148-.082.175-.163.218-.94.506-1.95.868-2.996 1.075z"
          fill="#84A2AC"
        />
        <path
          d="M4.897 9.88c.083-.197.24-.34.354-.52.804-1.012 1.822-1.754 2.961-2.341a.475.475 0 01.246-.079c.17.147-.01.217-.076.29-.488.552-.97 1.111-1.402 1.717-.079.113-.151.227-.226.34-.485.778-.98 1.552-1.274 2.462a.436.436 0 00.32-.132c.954-.667 1.914-1.323 2.987-1.789.138-.06.294-.207.424.018.041.217-.063.409-.098.612a6.429 6.429 0 01-.995 1.32.364.364 0 00-.328.066 14.106 14.106 0 00-2.485 1.86c-.064.06-.12.128-.19.181-.091.07-.189.166-.31.1s-.113-.199-.104-.314c.024-.231.071-.459.14-.68.067-.214.1-.437.1-.66-.464.566-.929 1.074-1.262 1.682-.073.132-.125.297-.33.28-.137-.131-.132-.3-.13-.47.265-.793.488-1.587.828-2.351a10.82 10.82 0 01.85-1.592z"
          fill="#939A9E"
        />
        <path
          d="M9.574 30.007a11.038 11.038 0 01-2.485-1.022.078.078 0 00-.09.04 13.093 13.093 0 01-1.05-1.165c.156-.127.335-.08.508-.067.227.08.359.263.496.434.241.28.499.546.771.795.072.072.157.211.278.048a.816.816 0 00.066-.143c.068-.149.12-.308.276-.397a.141.141 0 01.139.011c.24.227.358.04.506-.129.368-.423.72-.86 1.1-1.274a.16.16 0 01.083 0c.506.34.907.818 1.412 1.163.215.147.43.298.637.46.082.063.192.107.18.24-.048.137-.173.173-.287.228-.67.274-1.364.486-2.073.635a1.211 1.211 0 00-.467.143z"
          fill="#A6CEDB"
        />
        <path
          d="M20.77 33.283c.714-.236 1.438-.447 2.126-.755a7.645 7.645 0 001.037-.522c.094-.14.25-.178.393-.237 1.719-.575 3.114-1.573 4.098-3.112a21.118 21.118 0 001.804-2.31c.03-.049.075-.088.128-.113.216-.34.43-.688.652-1.027.062-.096.102-.212.21-.272.114.113.073.253.061.386a4.518 4.518 0 01-.243 1.073c-.41.766-.972 1.416-1.543 2.061a15.455 15.455 0 01-8.434 4.817 1.626 1.626 0 01-.29.011z"
          fill="#9FE2F8"
        />
        <path
          d="M26.507 4.977a15.004 15.004 0 014.13 3.97c.67.944 1.27 1.928 1.639 3.036.042.193.146.368.172.567a.245.245 0 01-.028.172c-.17.074-.186-.088-.227-.17a9.808 9.808 0 00-.517-.897.575.575 0 00-.206-.227.437.437 0 01-.143-.216c-.633-1.485-1.61-2.734-2.664-3.938-.64-.731-1.388-1.325-2.176-1.877-.045-.03-.09-.06-.135-.093-.108-.106-.177-.245-.287-.35a.103.103 0 01-.03-.13c.04-.062.097-.04.15-.014.1.068.209.124.322.167z"
          fill="#1D445B"
        />
        <path
          d="M8.462 6.967c-1.11.534-3.1 2.117-4.196 4.203.153-.856.66-1.502.54-1.45.673-1.038 1.038-1.491 1.331-1.814.184-.236.405-.365.613-.579a12.363 12.363 0 013.446-2.248c.16-.07.321-.138.478-.215.264-.07.498-.266.794-.202.093.172-.08.19-.157.236-.645.389-1.256.833-1.826 1.327-.227.193-.474.364-.65.61-.096.121-.246.094-.373.132z"
          fill="#BCBEBF"
        />
        <path
          d="M31.035 26.393c-.103-.093-.007-.18.014-.266a5.25 5.25 0 00.169-1.188l.061-.168c0-.227.137-.4.238-.58.68-1.204 1.091-2.496 1.377-3.846.022-.103.052-.206.068-.312.049-.127.022-.287.165-.373a.218.218 0 01.253.093 14.922 14.922 0 01-.987 3.954 16.58 16.58 0 01-1.358 2.686z"
          fill="#58BDD6"
        />
        <path
          d="M26.507 4.977c-.175.049-.265-.14-.44-.15.113.157.21.302.313.447.105.088.062.147-.042.174a.364.364 0 01-.366-.089c.09-.214-.024-.31-.201-.414a15.02 15.02 0 00-5.772-1.915c-.21-.027-.43.009-.623-.114-.09-.132-.244-.24-.175-.437 1.22.07 2.426.302 3.585.69 1.228.394 2.4.944 3.49 1.636.08.05.154.113.231.172z"
          fill="#527089"
        />
        <path
          d="M11.494 4.686c-.29.017-.538.172-.812.241a.659.659 0 01-.431-.113c-.102-.062-.187-.144-.093-.278a12.771 12.771 0 012.559-1.201c.118.15.275.264.453.33.068.027.147.053.157.146-.121.187-.34.209-.516.294a5.469 5.469 0 00-.87.454c-.145.064-.27.191-.447.127z"
          fill="#6595AE"
        />
        <path
          d="M2.527 19.633a14.798 14.798 0 01.34-4.954.927.927 0 01.054-.13c.174.24 0 .489.018.732a.922.922 0 000 .438c.026.782.092 1.562.064 2.345-.022.648.068 1.29.152 1.928a.55.55 0 010 .202c-.059.148-.165.134-.243.04-.154-.18-.333-.352-.385-.6z"
          fill="#525252"
        />
        <path
          d="M13.309 3.813c-.135-.066-.268-.136-.405-.194a.264.264 0 01-.187-.284c.977-.379 2-.627 3.041-.74.218.114.477.167.528.463-.114.114-.269.097-.408.12-.774.128-1.534.33-2.269.603-.093.036-.191.088-.3.032z"
          fill="#5F8AA4"
        />
        <path
          d="M2.525 19.633c.21.194.29.502.567.64a.385.385 0 01.134.279c.164.864.395 1.714.69 2.542.047.135.12.268.083.42-.113.124-.205.017-.301-.027a1.63 1.63 0 01-.353-.242 15.921 15.921 0 01-.824-3.468.77.77 0 01.004-.144z"
          fill="#555758"
        />
        <path
          d="M10.156 4.531c-.02.16.09.216.209.265l.315.131c-.944.425-1.875.876-2.707 1.5-.409.307-.803.634-1.204.951-.133-.06-.012-.113 0-.173a14.345 14.345 0 013.387-2.674z"
          fill="#6CA3B8"
        />
        <path
          d="M16.283 3.001c-.162-.152-.38-.226-.525-.407.44-.138.9-.138 1.35-.157.696-.031 1.392-.068 2.092.045.015.154.177.244.176.405a.34.34 0 01-.254.083c-.776.026-1.554-.029-2.328.067a.917.917 0 00-.2.025.324.324 0 01-.311-.06z"
          fill="#587C95"
        />
        <path
          d="M33.38 19.748l-.237-.06c-.124-.141-.093-.311-.075-.472a16.97 16.97 0 00.107-2.063c-.012-.581-.059-1.16-.14-1.736-.13-.97-.387-1.912-.645-2.853 0-.201-.155-.37-.113-.578.34.68.546 1.408.735 2.14.458 1.781.6 3.629.417 5.458a.86.86 0 01-.05.164z"
          fill="#4190A1"
        />
        <path
          d="M3.344 23.246c.245.02.393.246.626.294.123-.013.135.095.165.168.252.617.542 1.218.868 1.799.055.095.136.197.066.323-.253.072-.444-.057-.633-.194a12.748 12.748 0 01-1.092-2.39z"
          fill="#585D5E"
        />
        <path
          d="M4.438 25.64c.218.038.412.166.638.175.104-.024.133.063.17.123.369.6.777 1.175 1.222 1.723.044.054.113.113.059.2h-.575a13.295 13.295 0 01-1.514-2.221z"
          fill="#5B6B71"
        />
        <path
          d="M5.177 9.174c-.144.292-.558 1.296-.709 1.588a15.28 15.28 0 00-1.08 2.835.356.356 0 01-.114.199c-.085-.058-.093-.157-.13-.24.167-.877.613-2 1.04-2.776.236-.43.614-1.135.993-1.606z"
          fill="#B7BABC"
        />
        <path
          d="M3.147 13.552c.143.027.113.145.13.24.005.164.04.325.1.478a.18.18 0 01.047.215 1.397 1.397 0 00-.146.466.403.403 0 01-.415.362l.051-.764c.079-.332.156-.665.233-.997z"
          fill="#5A5B5B"
        />
        <path
          d="M6.773 7.205c.022.058-.07.114 0 .174l-.648.584c.18-.28.399-.536.648-.758z"
          fill="#88C1D8"
        />
        <path
          d="M27.6 25.694a12.41 12.41 0 001.61-.362 5.908 5.908 0 001.488-.627l.055.055c-.212.3-.412.609-.637.898a13.44 13.44 0 01-3.41 3.121 13.862 13.862 0 01-5.283 2.005 12.737 12.737 0 01-5.207-.218c-.057-.014-.114-.017-.172-.025-.191.069-.311-.08-.454-.148-.227-.181-.48-.331-.706-.517-.058-.049-.14-.101-.113-.186.034-.105.142-.097.227-.082.34.064.68.018 1.02.08.389.072.773-.048 1.162-.046.922 0 1.843.026 2.76-.146a11.91 11.91 0 004.743-2.004c.317-.227.595-.5.929-.701.44-.114.786-.4 1.172-.61.264-.142.485-.35.744-.5.025-.005.05 0 .072.013z"
          fill="#C1C0C0"
        />
        <path
          d="M24.336 31.827l-.403.179c-.487.063-.958.21-1.454.245-.52.038-1.042.038-1.563 0-.966-.075-1.882-.335-2.788-.653-.052-.02-.103-.045-.187-.081.28-.09.517.01.756.02 2.628.113 5.104-.433 7.4-1.73 1.44-.811 2.721-1.875 3.783-3.141.329-.392.327-.395.476-.429-.521.894-1.178 1.684-1.877 2.442-.892 1.076-1.997 1.894-3.175 2.624-.307.202-.63.377-.968.524z"
          fill="#6C8389"
        />
        <path
          d="M15.664 30.368l.38.173c.474.34 1 .584 1.522.847-.448-.006-.878-.12-1.307-.226a11.628 11.628 0 01-1.904-.617 1.217 1.217 0 00-.584-.084 13.46 13.46 0 01-2.147-.074c-.689-.082-1.37-.21-2.042-.385.007-.146.113-.151.227-.174.792-.159 1.568-.39 2.317-.692.098-.042.205-.068.25-.182.103-.06.189 0 .27.048.939.604 1.982.972 3.018 1.366z"
          fill="#A0DDF1"
        />
        <path
          d="M30.327 22.957l-.391.454c-.247.178-.395.484-.716.567a.27.27 0 01-.258-.152 4.24 4.24 0 01-.454-.794.794.794 0 01.097-.33c.45-.994.75-2.048.896-3.13.018-.123.016-.252.114-.347.127-.05.184.046.227.128.43.87.865 1.736 1.08 2.691.001.03-.002.06-.01.088-.129.32-.368.566-.585.825z"
          fill="#28C5F1"
        />
        <path
          d="M31.223 23.892l-.244-1.582c-.036-.17.094-.268.18-.378a8.874 8.874 0 001.192-2.03c.114-.27.083-.552.125-.828.024-.158-.157-.208-.227-.32a.206.206 0 01-.009-.091c.132-.556.231-1.12.297-1.688.028-.113-.05-.287.157-.313.06.09.083.198.064.304-.016.492.036.985-.046 1.474a.743.743 0 00.104.608c.114.149.05.34-.015.5-.178.348-.127.739-.215 1.104-.088.365-.153.74-.25 1.106-.189.655-.514 1.248-.816 1.856-.06.121-.109.282-.297.277z"
          fill="#598B96"
        />
        <path
          d="M31.221 23.891c.13-.115.23-.26.294-.42.284-.545.534-1.106.748-1.682.198-.483.227-1.008.37-1.504.103-.122.208-.244.388-.248a13.408 13.408 0 01-1.742 4.729.126.126 0 01-.052-.151c.1-.264-.047-.227-.227-.15.03-.234.248-.357.221-.574z"
          fill="#418A99"
        />
        <path
          d="M32.283 18.759c.275.056.296.237.27.48-.12 1.12-.785 1.965-1.41 2.835-.058.078-.114.16-.163.24h-.048c-.064-.041-.057-.107-.06-.17v-.065c.137-.447.429-.817.61-1.248.263-.592.487-1.2.673-1.822.027-.088.035-.195.128-.25z"
          fill="#5CC1D8"
        />
        <path
          d="M29.227 23.947l.707-.532a13.47 13.47 0 01-2.336 2.28l-.052.01c-.265.12-.528.255-.827.113 0-.313.25-.453.442-.631a9.622 9.622 0 001.646-.99c.123-.11.265-.194.42-.25z"
          fill="#3E8597"
        />
        <path
          d="M30.87 22.142c.07.04.056.113.061.17-.192.228-.34.49-.603.645l.542-.815z"
          fill="#96DDF4"
        />
        <path
          d="M27.186 25.23l-.454.528a2.67 2.67 0 01-1.055 1.048 12.19 12.19 0 01-2.81 1.84c-1.15.538-2.38.886-3.642 1.03-.505.058-1.006.04-1.51.051-.397.008-.794.01-1.19.043-.511.044-1.021-.035-1.533-.09-.057-.006-.113 0-.227 0l.907.689c-.396-.016-.739-.211-1.1-.34-.7-.259-1.37-.59-2-.988a1.283 1.283 0 00-.185-.08 15.199 15.199 0 01-2.223-1.8c.113-.301-.073-.448-.275-.635a11.52 11.52 0 01-1.615-1.903 3.3 3.3 0 00-.376-.516c-.172-.288-.296-.599-.454-.89a2.497 2.497 0 00-.09-.149c-.437-.803-.667-1.677-.874-2.558-.065-.274-.114-.552-.17-.829a3.221 3.221 0 00-.054-.478 13.042 13.042 0 010-2.269c.081-.733.223-1.459.423-2.17.242-.8.567-1.573.97-2.306.06-.114.103-.227.166-.34.08-.142.136-.307.316-.361l.94-1.286c.16-.266.357-.508.585-.72a13.004 13.004 0 013.22-2.314c.841-.402 1.72-.719 2.624-.944.929-.2 1.878-.283 2.828-.25.782.01 1.56.111 2.32.3.914.202 1.76.644 2.696.773a.28.28 0 01.195.114c-.053.167.027.253.17.331.372.206.732.434 1.08.68a17.1 17.1 0 011.64 1.389c.38.437.764.869 1.102 1.34a12.889 12.889 0 011.528 2.882c.101.333.234.657.318.996.234.86.37 1.742.408 2.632 0 .426.037.852-.043 1.277a.437.437 0 01-.094.268c-.155 1.075-.382 2.13-.806 3.135-.1.24-.202.48-.304.72-.203.632-.567 1.172-.95 1.708a1.49 1.49 0 01-.402.442z"
          fill="#FEFEFE"
        />
        <path
          d="M8.114 11.756c-.94 1.544-1.588 3.19-1.763 5.006a.708.708 0 01-.04.135 1.039 1.039 0 00-.288-.143c-.555-.143-1.105-.302-1.66-.446a1.276 1.276 0 01-.427-.18 3.304 3.304 0 01-.553-1.631c0-.076-.006-.15-.008-.227.135-.061.184-.192.25-.31.36-.654.864-1.197 1.36-1.8.098.375-.046.67-.104.977-.038.198-.09.395-.113.595-.007.063-.016.173.066.208.081.035.15-.03.205-.083.237-.227.473-.453.727-.668a14.59 14.59 0 012.081-1.474c.085-.046.21-.177.267.04z"
          fill="#8C9092"
        />
        <path
          d="M15.409 6.546c-.155.156-.371.148-.56.203a11.991 11.991 0 00-4.752 2.68c-.367.331-.651.737-1.042 1.04.04-.211.078-.42.113-.632a6.42 6.42 0 01.508-1.768c.255-.543.454-1.114.775-1.627a2 2 0 01.207-.316.33.33 0 01.081-.056c.662-.206 1.347-.28 2.034-.356 1.306-.147 2.597 0 3.885.19.133.02.274.027.378.132-.015.167-.162.137-.257.154-.377.071-.755.14-1.124.242-.083.025-.192.01-.246.114z"
          fill="#E4F2FB"
        />
        <path
          d="M9.746 8.075c-.25.567-.35 1.186-.574 1.762-.113-.137-.212-.015-.308.027-1.164.502-2.19 1.238-3.227 1.947-.052.035-.092.128-.167.08-.075-.047-.052-.147-.034-.215.044-.158.1-.311.167-.46.304-.691.676-1.35 1.109-1.968a.197.197 0 00.262.026A12.055 12.055 0 019 8.34a.555.555 0 00.325-.274.354.354 0 01.245-.19c.166-.036.249.019.175.199z"
          fill="#9DA7AC"
        />
        <path
          d="M9.746 8.075c0-.065.015-.155-.08-.16-.096-.005-.245.052-.266.144-.063.288-.313.34-.515.406-.703.208-1.327.576-1.963.919-.227.125-.213.016-.21-.14.356-.561.757-1.092 1.2-1.588.19-.226.435-.409.55-.694.08-.132.256-.071.352-.171.35-.318.804-.41 1.234-.543.2-.063.4-.227.634-.101-.348.63-.643 1.277-.936 1.928z"
          fill="#A4B0B6"
        />
        <path
          d="M7.471 23.189l.463.882c.48.828 1.028 1.615 1.639 2.352.132.156.281.294.417.444.076.082.182.159.114.3-.377.53-.827 1-1.248 1.49-.1.114-.177.114-.284.015-.059-.055-.113-.142-.226-.107a.093.093 0 01-.02-.079c.069-.14.157-.269.262-.383.195-.208.17-.397.043-.63-.162-.299-.279-.621-.423-.93-.442-.973-.643-2.01-.81-3.056a.312.312 0 01.073-.298z"
          fill="#596568"
        />
        <path
          d="M8.348 28.5v.064l-.242.4c-.135.048-.214-.047-.298-.12a.884.884 0 00-.258-.18c-.463-.333-.783-.8-1.134-1.232a15.813 15.813 0 01-1.88-2.962c-.088-.177-.21-.352-.174-.567.185-.066.227.103.3.197.184.24.33.514.598.687.06-.088-.02-.186.047-.258.169-.019.204.128.26.226a14.96 14.96 0 001.674 2.416c.29.34.58.68.899.993.094.093.209.178.208.335z"
          fill="#3C4647"
        />
        <path
          d="M4.378 23.89c.428 1.118 1.063 2.118 1.77 3.074.435.588.88 1.172 1.44 1.653.18.127.335.284.463.463l-.085.226c-.554-.427-.981-.954-1.44-1.448a21.801 21.801 0 01-1.447-2.041c-.41-.74-.768-1.503-1.108-2.277-.257-.828-.558-1.644-.725-2.495-.05-.256-.103-.51-.154-.766-.097-.898-.22-1.785-.172-2.684.034-.648-.096-1.29-.051-1.936.275-.028.256.186.25.34-.019.382-.062.763-.056 1.146-.07 2.099.352 4.109 1.088 6.064.084.223.26.419.227.68z"
          fill="#5C6A6D"
        />
        <path
          d="M10.106 27.166c-.17-.347-.494-.567-.727-.861a12.91 12.91 0 01-1.417-2.123.5.5 0 01-.028-.114c.315.328.518.739.805 1.09.406.494.81.992 1.3 1.403.226.19.34.34.113.595l-.046.01z"
          fill="#959394"
        />
        <path
          d="M8.049 29.08a2.322 2.322 0 01-.463-.463c.227.038.305.297.522.347a.119.119 0 01-.06.116z"
          fill="#414E50"
        />
        <path
          d="M24.336 31.827c.81-.542 1.679-.998 2.433-1.627.455-.36.891-.744 1.306-1.15.128-.133.27-.25.404-.375-.097.303-.316.528-.515.76a8.123 8.123 0 01-3.485 2.374.655.655 0 01-.143.018z"
          fill="#84A3AD"
        />
        <path
          d="M26.492 5.335c.777.49 1.484 1.085 2.1 1.767a21.3 21.3 0 011.823 2.291c.394.589.728 1.215.998 1.87.027.05.059.098.095.142.162.244.305.495.316.794-.162.087-.234-.034-.29-.14-.397-.75-.951-1.386-1.474-2.04-.135-.17-.26-.374-.538-.308-.033.009-.072-.024-.102-.05-.59-.515-1.337-.72-2.041-1.01-.104-.114-.087-.254-.106-.385a9.436 9.436 0 00-.752-2.524c-.061-.13-.137-.263-.03-.407z"
          fill="#255D7E"
        />
        <path
          d="M26.492 5.334c-.037.227.113.41.194.6.364.823.596 1.697.687 2.592a.554.554 0 00.052.135.393.393 0 01.017.454c-.136.076-.202-.039-.254-.114-.653-.98-1.474-1.805-2.356-2.577a.248.248 0 01-.05-.071.171.171 0 01-.006-.083c.33-.362.704-.682 1.112-.953a.088.088 0 01.076.022c.117.038.238.058.36.06.1-.011.062-.07.053-.121a.126.126 0 01.115.056z"
          fill="#326582"
        />
        <path
          d="M32.392 12.565c.214.38.301.805.404 1.222.145.52.243 1.051.293 1.588.107 1.092.14 2.181-.144 3.256a.55.55 0 01-.057.164.133.133 0 01-.113.078c-.076 0-.085-.07-.09-.126a1.039 1.039 0 010-.29c.087-.53.03-1.062.028-1.588a3.094 3.094 0 00-.093-.567.78.78 0 01-.042-.518c.123-.196.085-.412.064-.62-.074-.728-.076-1.464-.269-2.178a.376.376 0 01.017-.227l.002-.194z"
          fill="#37585E"
        />
        <path
          d="M32.387 12.745c.167.53.26 1.08.279 1.635.02.365.072.728.068 1.094 0 .114.025.24-.114.303-.165-.227-.144-.495-.178-.748a13.22 13.22 0 00-.577-2.391c-.044-.134-.125-.267-.07-.419-.096-.27-.19-.543-.287-.814.103-.065.099.043.133.086.304.386.48.846.746 1.255z"
          fill="#19404F"
        />
        <path
          d="M10.68 6.151a10.45 10.45 0 00-1.867.641c.12-.294.407-.43.624-.625.631-.567 1.345-1.02 2.055-1.484l.408-.173c.204-.097.416-.177.634-.24 1.3-.333 2.622-.413 3.957-.364.233.003.465.038.689.103.072.227-.124.16-.212.171-.509.06-1.02.07-1.534.114-1.384.132-2.697.525-3.97 1.058a.794.794 0 00-.372.312c-.113.177-.218.37-.41.482l-.002.005z"
          fill="#E3F4FB"
        />
        <path
          d="M32.676 16.247c.02.043.055.085.055.127.009.436.009.87.054 1.306.031.303-.084.612-.05.923.008.072-.029.153.048.234.373-1.143.296-2.309.304-3.468.113.182.084.396.101.588.094 1.08.126 2.155-.036 3.238-.014.164-.017.33-.01.494-.104.093-.027.251-.122.349-.1.094-.215.173-.34.232a1.336 1.336 0 01.049-.69c.023-.22.14-.43-.082-.647-.084-.082-.05-.319-.024-.478.1-.599.034-1.2.051-1.8a.303.303 0 01.002-.408z"
          fill="#376A72"
        />
        <path
          d="M25.965 5.334a.45.45 0 00-.056 0c-.255.12-.533.082-.8.106-.357.032-.706.113-1.065.113-1.06-.853-2.269-1.468-3.459-2.112-.4-.217-.842-.27-1.268-.39-.094-.027-.226 0-.178-.17l.235-.007c1.327.14 2.635.367 3.895.823.929.335 1.825.754 2.677 1.254.272.168.225.246.019.383z"
          fill="#6C9EC8"
        />
        <path
          d="M12.498 4.35a2.13 2.13 0 01-.596.163c.37-.302.827-.432 1.248-.634.051-.024.105-.045.158-.066.923-.375 1.89-.631 2.877-.764a.333.333 0 00.099-.052h.289c.253.09.494.207.72.35.09.052.258.114.114.277-.18.048-.366.06-.55.038-1.474-.042-2.89.255-4.3.63a.08.08 0 00-.06.058z"
          fill="#75B3DA"
        />
        <path
          d="M2.977 17.18c-.032-.415.025-.824.054-1.236.009-.13.007-.258-.166-.284a.293.293 0 010-.346c.227-.114.379-.263.376-.547 0-.08-.023-.247.146-.27.172.535.293 1.089.576 1.588a.303.303 0 01.099.277 3.23 3.23 0 01-.771.755c-.092.057-.188.148-.314.064z"
          fill="#393737"
        />
        <path
          d="M17.449 3.583c-.114-.207-.34-.235-.52-.347-.114-.077-.28-.092-.355-.235.264-.105.55-.093.821-.107.58-.028 1.164 0 1.746 0 .018.032.034.09.056.092C20.6 3.137 21.68 4 22.855 4.65c.304.17.586.376.878.567.114.074.233.144.25.297a.287.287 0 01-.325.084c-1.642-1.032-3.443-1.603-5.359-1.84-.187-.047-.38 0-.567-.045a.446.446 0 01-.283-.13z"
          fill="#6EA6CF"
        />
        <path
          d="M25.672 26.806c.37-.33.718-.68 1.055-1.048.281.114.545-.043.819-.053-.524.469-1.153.775-1.752 1.129a.081.081 0 01-.067.016.079.079 0 01-.055-.044z"
          fill="#7F9DA8"
        />
        <path
          d="M29.648 19.22l.06-1.567a.227.227 0 01.196 0c.715.21 1.41.485 2.074.821a.65.65 0 01.295.227c0 .019.003.037.008.056a8.622 8.622 0 01-.605 1.814 6.995 6.995 0 01-.806 1.505c-.244-.95-.666-1.829-1.095-2.704a.307.307 0 00-.127-.152z"
          fill="#1C99BF"
        />
        <path
          d="M27.188 25.23c.503-.684.955-1.405 1.352-2.156.208.19.289.466.453.686.065.085.114.178.236.186a8.188 8.188 0 01-2.041 1.283z"
          fill="#32BFE6"
        />
        <path
          d="M32.678 16.247v.409c-.101.102-.035.234-.065.349-.145.104-.235 0-.32-.094a2.41 2.41 0 01-.268-.38.32.32 0 00-.311-.17.303.303 0 01-.28-.175c-.636-1.032-1.34-2.019-2.01-3.028a1.267 1.267 0 01-.107-.173.227.227 0 01-.016-.171c.148-.324-.014-.575-.178-.834-.227-.352-.475-.68-.68-1.001-.045-.063-.063.011-.04-.02.007-.008.018-.006.026.01.19.377.407.746.423 1.186-.15.103-.227-.027-.314-.105-.254-.236-.454-.521-.701-.766a.262.262 0 00-.34-.059c-.135-.271-.082-.567-.089-.846 0-.2 0-.402-.014-.601.057-.22-.036-.434-.016-.651.113-.143.033-.312.059-.466.053-.02.102-.066.163-.033.513.273 1.09.408 1.572.745.086.06.166.133.252.194.06.043.105.198.202.012.042-.08.113.022.16.06.393.32.67.743.978 1.135.309.39.638.805.85 1.278a.354.354 0 00.194.164c.436 1.143.714 2.34.824 3.558-.017.16-.002.32.046.473z"
          fill="#206380"
        />
        <path
          d="M27.492 11.224c.072-.226.167-.226.321-.053.244.281.504.55.76.821.07.074.133.17.255.17.177.086.156.274.211.422.056.149.082.317.142.466.078.013.09-.113.172-.071.12.061.164.187.227.29.647.971 1.333 1.916 1.928 2.92.053.086.125.143.214.106.188-.077.245.05.34.166.163.197.21.52.547.543-.1.567-.106 1.157-.34 1.701a15.867 15.867 0 00-2.566-1.05 14.237 14.237 0 00-.407-2.631c.113-.177-.013-.347-.016-.52-.006-.26-.074-.511-.085-.793-.114.113-.088.27-.207.34a14.727 14.727 0 00-1.496-2.827z"
          fill="#1A6C84"
        />
        <path
          d="M32.733 19.58c.05.234-.052.453-.049.69l-.368 1.524s-.018.01-.025.01a.09.09 0 01-.025-.014c.127-.68.325-1.353.357-2.053a.17.17 0 01.11-.158z"
          fill="#58B0C5"
        />
        <path
          d="M12.644 12.98c.919.03 1.85.518 2.393 1.587.567 1.116.505 2.235-.197 3.29-.125.186-.114.27.061.377.087.063.17.13.248.203.332.272.318.262.045.602-.144.177-.227.168-.38.019-.175-.18-.353-.44-.566-.48-.184-.034-.41.204-.626.302a3.093 3.093 0 01-3.383-.562c-.885-.857-1.162-2.268-.68-3.45.482-1.182 1.536-1.89 3.085-1.889z"
          fill="#050708"
        />
        <path
          d="M6.366 19.686c.15.767.344 1.525.583 2.268.127.386.319.742.465 1.119.007.194.01.415-.18.505a.55.55 0 01-.603-.114c-.32-.323-.673-.616-.96-.97-.475-.592-.71-1.29-.895-2.01-.101-.394-.106-.393-.466-.227-.082.033-.16.073-.234.12-.049.032-.097.066-.145.099a.616.616 0 00-.136.15c-.075.102-.149.07-.214-.008a.88.88 0 01-.165-.34c-.141-.545-.237-1.1-.286-1.661-.03-.252.086-.498.18-.73.156-.378.364-.732.617-1.052a.34.34 0 01.268-.153.454.454 0 01.287.453c-.057.838.086 1.659.238 2.476.036.201.149.218.318.137.297-.161.603-.308.915-.438a.264.264 0 01.09 0c.186.054.225.241.323.376z"
          fill="#393737"
        />
        <path
          d="M24.162 13.65c.383.01.832-.036 1.276.04.885.147 1.45.76 1.474 1.587.025.926-.488 1.574-1.418 1.789-.567.13-1.143.059-1.713.063-.211 0-.287.042-.278.27.02.475 0 .953.01 1.428.01.35-.24.183-.384.212-.164.032-.259 0-.258-.218.01-1.652.008-3.305 0-4.957 0-.162.04-.22.209-.213.338.008.68-.002 1.082-.002zM19.64 19.123c-1.638.05-2.883-1.152-2.894-2.647-.01-1.405.71-2.47 1.94-2.81 1.012-.278 1.94-.085 2.749.61.113.096.142.168.02.27a.753.753 0 00-.16.17c-.105.166-.194.124-.316.012a2.11 2.11 0 00-1.248-.58c-1.22-.114-2.176.654-2.336 1.88-.146 1.121.488 2.155 1.523 2.445.783.215 1.475 0 2.08-.526.066-.058.134-.19.227-.127.135.095.25.214.34.352.042.064-.077.122-.129.17-.548.509-1.186.798-1.796.78z"
          fill="#050708"
        />
        <path
          d="M4.027 20.331c.156-.155.378-.186.558-.3.156-.098.17.065.197.158.119.398.205.809.355 1.196.204.52.47 1.001.914 1.384.206.159.388.347.538.56.137.215.385.187.604.205a.086.086 0 00.09-.05.085.085 0 00.007-.036c-.018-.15.114-.244.127-.379l.058.12c.013.582.16 1.141.286 1.7.133.575.31 1.137.53 1.685-.114.094-.114.1-.199.03a1.712 1.712 0 01-.306-.333 1.152 1.152 0 00-.27-.352 19.343 19.343 0 01-1.485-2.222 3.75 3.75 0 01-.181-.33c-.407-.948-1.002-1.794-1.459-2.713-.085-.16-.264-.192-.364-.323z"
          fill="#3B3E3F"
        />
        <path
          d="M20.653 6.61c-.218.033-.411-.077-.618-.113a14.105 14.105 0 00-1.718-.184c-.227-.025-.454-.019-.68-.059-.155-.028-.332-.024-.434-.182.038-.101.13-.108.215-.114a11.352 11.352 0 012.458.02c.155.002.309.018.46.045a2.846 2.846 0 002.004-.143.36.36 0 01.186.025A7.05 7.05 0 0124 7.011c.01.028.01.059 0 .087a.213.213 0 01-.217.13 15.034 15.034 0 00-1.923-.713 1.152 1.152 0 00-.87.025.968.968 0 01-.337.07z"
          fill="#5F9EB1"
        />
        <path
          d="M27.422 9.713c.123.277.043.567.06.852.012.22 0 .44 0 .66l-1.107-1.34c-.274-.32-.503-.681-.762-1.008-.258-.327-.532-.64-.806-.954-.066-.076-.177-.135-.126-.268.14-.057.243.04.357.089.768.51 1.522 1.039 2.178 1.694.085.076.187.149.206.275z"
          fill="#41728D"
        />
        <path
          d="M15.476 21.65v-1.105c0-.151.019-.233.2-.227.32.015.641 0 .96.015.514.032.836.352.847.817.011.465-.29.809-.794.876-.201.03-.406.037-.609.021-.253-.024-.323.076-.296.311.018.153 0 .31 0 .465 0 .106 0 .184-.15.187-.15.004-.166-.08-.165-.2.009-.387.007-.775.007-1.16z"
          fill="#080A0B"
        />
        <path
          d="M14.812 22.978c-.25.084-.362-.012-.421-.212-.024-.084-.075-.158-.101-.24a.31.31 0 00-.363-.245c-.32.019-.641.019-.962 0a.29.29 0 00-.34.227c-.032.1-.096.191-.127.292a.246.246 0 01-.35.19c-.036-.06 0-.112.028-.158l1.052-2.327c.047-.105.074-.213.235-.209.142 0 .176.09.22.19l1.129 2.492zM21.75 22.98c.391-.861.776-1.717 1.17-2.567a.203.203 0 01.283-.099c.034.019.061.046.08.08.396.86.782 1.724 1.172 2.587-.25.083-.372-.02-.424-.227-.093-.378-.318-.546-.712-.465a.805.805 0 01-.29 0c-.474-.082-.806.047-.908.567-.043.188-.196.168-.371.125z"
          fill="#07090A"
        />
        <path
          d="M6.065 19.34h-.053c-.173-.068-.25-.233-.357-.365a15.816 15.816 0 01-.587-.784c-.066-.091-.146-.182-.122-.311.356-.303.597-.707.924-1.035.056-.055.085-.162.2-.13a.195.195 0 01.22.1c.013.026.02.054.021.083a17.104 17.104 0 000 2.326.276.276 0 01-.246.116z"
          fill="#5B5959"
        />
        <path
          d="M10.657 20.272c.313 0 .617.104.863.297.062.05.19.084.084.216-.09.114-.154.114-.273.023-.502-.36-1.097-.324-1.451.072a1.195 1.195 0 00-.022 1.524c.364.436.973.488 1.463.126.06-.044.101-.14.188-.114a.282.282 0 01.146.133c.042.085-.058.114-.101.149a1.37 1.37 0 01-2.212-1.47 1.35 1.35 0 011.315-.956z"
          fill="#070809"
        />
        <path
          d="M20.656 6.61c.38-.197.751-.34 1.204-.177.659.24 1.353.388 1.957.768.027.113-.067.163-.133.226-.053.033-.093.1-.172.066-.187-.165-.43-.142-.652-.185-.63-.121-1.199-.43-1.82-.575-.13-.03-.256-.081-.384-.122z"
          fill="#A8B3BA"
        />
        <path
          d="M25.102 21.65V20.54c0-.12-.02-.227.17-.227.187 0 .144.114.144.218 0 .662.007 1.323 0 1.984 0 .167.046.214.211.209.369-.012.739 0 1.108 0 .098 0 .19-.02.186.138-.004.158-.081.147-.187.147-.477 0-.954-.009-1.43 0-.183 0-.204-.074-.2-.226.004-.38-.002-.758-.002-1.135z"
          fill="#0E0F11"
        />
        <path
          d="M20.075 21.798c0-.32-.009-.64 0-.96.007-.172-.027-.25-.219-.227-.172.02-.349 0-.523.005-.113 0-.146-.046-.15-.154-.003-.107.054-.142.158-.14h1.775c.088 0 .174-.013.174.127s-.043.172-.17.166c-.173-.006-.348.01-.522 0-.174-.009-.232.041-.227.227.012.64.006 1.28 0 1.922 0 .113.06.263-.163.252-.223-.01-.13-.155-.134-.25-.003-.322.001-.648.001-.968z"
          fill="#060809"
        />
        <path
          d="M23.512 7.493l.122-.113c.325.092.666.113.984.234a.158.158 0 01.059.065c.34.381.698.742.985 1.164-.093.058-.17 0-.239-.045a2.678 2.678 0 00-.685-.304c-.41-.252-.813-.514-1.232-.751-.188-.114-.129-.17.006-.25z"
          fill="#9EA3A5"
        />
        <path
          d="M18.49 21.667V22.8c0 .114.013.209-.156.209s-.162-.09-.162-.206v-2.3c0-.13.024-.194.173-.193.15.002.147.084.147.189-.004.391-.003.779-.003 1.167z"
          fill="#131516"
        />
        <path
          d="M17.217 6.09l1.104.228a11.504 11.504 0 00-2.656.237 1.007 1.007 0 01-.26-.01c-.137-.1-.029-.126.05-.146.516-.14 1.041-.244 1.571-.311a.13.13 0 01.19.003z"
          fill="#7ACAE4"
        />
        <path
          d="M24.738 8.493c.383-.08.614.238.922.353.284.315.526.666.72 1.043l-1.642-1.396z"
          fill="#C1C0C0"
        />
        <path
          d="M28.992 14.05c.025-.185.082-.366.168-.532.196.178.113.408.15.605.05.266.069.536.1.803.01.083-.007.14-.113.095l-.305-.97z"
          fill="#206380"
        />
        <path
          d="M6.066 19.341a.366.366 0 01.245-.114c.054.15.073.308.056.466-.144-.084-.203-.234-.3-.352z"
          fill="#858585"
        />
        <path
          d="M6.07 16.713c-.285.334-.568.67-.857 1-.067.077-.134.168-.255.17-.297-.172-.416-.463-.516-.767a1.02 1.02 0 00-.234-.406.42.42 0 01-.174-.349l-.073-.28c.643.25 1.325.376 1.984.573a.73.73 0 01.124.059z"
          fill="#656667"
        />
        <path
          d="M17.214 6.092h-.186c-.577-.17-1.175-.215-1.767-.271a13.33 13.33 0 00-1.361-.075c-1.088.012-2.171.15-3.228.408.283-.68.86-.96 1.514-1.18 1.344-.454 2.709-.775 4.135-.82.292-.01.598.012.886-.087.255-.128.494.026.74.042.162.051.335.048.498.099 1.288.281 2.525.762 3.665 1.425.114.07.284.1.29.285-.28.102-.56.205-.842.306-.363.13-.7-.059-1.05-.09-.861-.076-1.719-.235-2.589-.105-.23.035-.476-.05-.705.063z"
          fill="#A9B6BD"
        />
        <path
          d="M7.82 26.224c.094.104.186.21.285.31.048.05.098.14.182.04.177.375.353.75.533 1.123a.165.165 0 010 .19c-.163.202-.26.454-.478.612-.67-.694-1.299-1.429-1.881-2.2-.372-.508-.68-1.056-1.005-1.594-.04-.067-.065-.143-.145-.173-.26-.204-.331-.506-.417-.801 0-.254-.169-.454-.226-.689-.02-.09-.092-.204.013-.266.125-.073.193.05.25.135.285.43.648.803 1.072 1.098.222.16.413.36.563.59.296.444.617.87.962 1.277.082.131.211.216.292.348z"
          fill="#414E50"
        />
        <path
          d="M4.963 23.715c.058.298.227.548.35.817.01.12.105.227.022.385a1.486 1.486 0 01-.567-.543c-.113-.174-.212-.361-.391-.484-.575-1.168-.921-2.411-1.163-3.685a12.886 12.886 0 01-.235-3.025c.319-.075.515-.32.735-.53.104-.1.214-.193.32-.289.087.1.147.22.176.348-.278.091-.37.368-.505.573a3.719 3.719 0 00-.49 1.134c-.066.253.041.48.068.713.063.518.104 1.053.4 1.541.078-.121.114-.24.228-.282.132.361.176.747.289 1.115.21.752.465 1.49.763 2.212z"
          fill="#3B3E3F"
        />
        <path
          d="M27.424 9.713c-.725-.73-1.564-1.32-2.392-1.928.142-.114.278-.01.413.014.2.035.389.114.624.167a8.81 8.81 0 00-1.124-1.039c-.137-.105-.296-.187-.39-.34-.01-.027-.014-.056-.01-.084.056-.101.113-.202.256-.178A11.6 11.6 0 0127.13 8.8c.076.113.158.218.237.326.092.189.199.376.056.586z"
          fill="#255D7E"
        />
        <path
          d="M23.807 5.568c.042-.068.113-.054.175-.061.64-.093 1.277-.204 1.928-.168l-1.107.934c-.024.017-.057.038-.08.026a4.867 4.867 0 01-.907-.65c-.027-.017-.02-.052-.01-.08z"
          fill="#3D8AB9"
        />
        <path
          d="M23.808 5.568c.31.264.643.5.995.704v.055l-.227.178c-.088.066-.168.021-.24-.024-.34-.217-.681-.442-1.021-.654-.056-.034-.114-.075-.084-.16a.46.46 0 01.47-.103l.107.004z"
          fill="#1C7BAE"
        />
        <path
          d="M17.97 4.169l-.758-.102a13.532 13.532 0 00-4.601.272.785.785 0 01-.113.011c-.173-.091.018-.074.03-.079a12.72 12.72 0 012.042-.513c.954-.179 1.917-.183 2.882-.174.286.078.593.008.875.113.043.212.236.221.386.294.15.072.298.143.049.283a.397.397 0 01-.22-.029c-.187-.061-.384-.023-.572-.076z"
          fill="#4CA2CE"
        />
        <path
          d="M18.79 4.226c.09-.14-.016-.158-.113-.173a.916.916 0 01-.363-.164c-.09-.06-.114-.131.009-.188a8.46 8.46 0 011.984.355c1.15.298 2.25.768 3.26 1.393a.996.996 0 01.126.113l-.468.125a.154.154 0 01-.134.03c-.868-.449-1.78-.807-2.722-1.069-.454-.123-.924-.208-1.388-.306-.075-.015-.189.022-.19-.116z"
          fill="#4B94C4"
        />
        <path
          d="M29.35 12.98c-.09.064-.052.21-.188.27l-.34-1.088c-.114-.453-.333-.872-.466-1.323.424.469.784.992 1.071 1.555.104.197-.122.379-.077.585z"
          fill="#316F8B"
        />
        <path
          d="M10.036 16.04c.047-.992.428-1.797 1.37-2.22.846-.38 1.674-.278 2.404.313.95.77 1.161 2.236.506 3.275-.114.183-.183.161-.318.027-.24-.239-.51-.453-.749-.69-.131-.132-.2-.114-.314.018-.315.354-.322.348.04.658.185.157.358.33.557.47.227.157.125.226-.046.327-1.345.768-3.069-.043-3.386-1.598a2.07 2.07 0 01-.064-.58z"
          fill="#FEFEFE"
        />
        <path
          d="M4.96 23.715c-.368-.59-.556-1.255-.753-1.913-.137-.46-.317-.917-.3-1.413l.114-.058a.461.461 0 01.434.298c.13.381.372.704.567 1.043.67 1.134 1.169 2.361 1.958 3.426.187.253.443.463.542.778-.454-.34-.703-.844-1.042-1.275a2.363 2.363 0 00-.505-.52 4.03 4.03 0 01-1.155-1.207.194.194 0 00-.062-.059c-.014-.008-.035 0-.062 0-.006.327.279.572.264.9z"
          fill="#3C4647"
        />
        <path
          d="M4.44 17.113l.517.767c.298.524.718.96 1.054 1.456-.328.165-.66.322-.985.496-.206.113-.32.121-.376-.162a13.363 13.363 0 01-.272-2.315c-.002-.083-.021-.175.063-.242z"
          fill="#2A2A2A"
        />
        <path
          d="M23.508 15.372v-.962c0-.113 0-.207.164-.199.513.024 1.029-.036 1.542.039.68.1 1.07.506 1.076 1.122.006.567-.403 1.048-1.029 1.154-.538.091-1.085.024-1.628.041-.141 0-.123-.09-.123-.176l-.002-1.02z"
          fill="#FEFEFE"
        />
        <path
          d="M7.823 26.224c-.124-.094-.265-.175-.292-.35.124.094.265.176.292.35z"
          fill="#3C4647"
        />
        <path
          d="M18.79 4.226c.382.079.77.145 1.148.238 1.108.272 2.189.619 3.17 1.22-.011.159-.152.145-.25.181-.1.037-.227.063-.33.106l-.122-.053c-.825-.568-1.736-.961-2.682-1.276-.42-.14-.852-.244-1.279-.364 0-.1.05-.089.114-.053l.231.001z"
          fill="#3B7B9F"
        />
        <path
          d="M24.566 6.565a9.95 9.95 0 011.702 1.521l-1.238-.292-.355-.114a.3.3 0 00-.045-.013c-.238-.168-.512-.296-.653-.575v-.06a.843.843 0 01.59-.467z"
          fill="#1B4B65"
        />
        <path
          d="M24.57 6.565l-.586.469c-.464-.38-.95-.732-1.453-1.057.038-.162.186-.132.295-.17a.62.62 0 00.285-.113h.113c.454.259.857.608 1.343.817.002.012.002.03.002.054z"
          fill="#1D678D"
        />
        <path
          d="M23.977 7.088c.217.192.472.34.653.575-.272-.056-.543-.113-.816-.167a.21.21 0 01-.181-.119.464.464 0 00.182-.177l.162-.112z"
          fill="#717D83"
        />
        <path
          d="M15.786 21.182c0-.567 0-.567.575-.567.117-.007.233 0 .348.018.257.06.434.203.454.491a.537.537 0 01-.368.593c-.29.114-.597.051-.896.063-.114 0-.114-.079-.114-.16l.001-.438z"
          fill="#FEFEFE"
        />
        <path
          d="M13.456 20.656l.604 1.355h-1.208l.604-1.355zM23.098 20.655c.217.486.406.908.605 1.361h-1.207c.2-.456.39-.882.602-1.36z"
          fill="#FDFDFD"
        />
        <path
          d="M18.557 4.225l-.113.053c-.155-.048-.329-.02-.475-.114.197.029.402-.055.588.061z"
          fill="#4B94C4"
        />
      </g>
      <defs>
        <clipPath id="qcp-capital_svg__clip0">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgQcpCapital
