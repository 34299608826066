import { cx, css } from 'emotion'
import Form, { FormProps, FormItemProps } from 'antd/es/form'
import 'antd/es/form/style/css'
import { RED_COLORS } from '../../../themes/deprecated/ColorStyles'
import { MAIN_FONT, FONT_SIZE } from '../../../themes/deprecated/TypographyStyles'
import { fazzbizTheme } from '../../../themes/deprecated/theme'

const baseStyle = css`
  & .ant-form-item {
    margin-bottom: 0;
  }

  & .ant-form-item-has-error {
    font-family: ${MAIN_FONT};
    font-size: ${FONT_SIZE.Body};
    line-height: 17px;

    & .ant-form-item-explain {
      color: ${fazzbizTheme.status.critical};
      padding-top: 5px;
      padding-bottom: 5px;
    }

    & .ant-input,
    .ant-picker-input,
    .ant-select-selector {
      color: ${fazzbizTheme.status.critical};

      &::placeholder,
      input::placeholder,
      .ant-select-selection-placeholder {
        color: ${fazzbizTheme.status.critical};
        font-weight: 400;
      }
    }

    & .ant-input,
    .ant-picker,
    .ant-select,
    .ant-select-selector {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-color: ${fazzbizTheme.status.critical} !important;
      background-color: ${fazzbizTheme.status.subdueCritical};

      &:hover {
        border-color: ${fazzbizTheme.status.critical};
        background-color: ${fazzbizTheme.status.subdueCritical};
      }

      &:focus {
        border-color: ${fazzbizTheme.status.critical};
        -webkit-box-shadow: 0 0 0 3px ${RED_COLORS.R200};
        box-shadow: 0 0 0 3px ${RED_COLORS.R200};
        background-color: ${fazzbizTheme.status.subdueCritical};
      }
    }

    & .ant-input-group-addon:not(:last-child) {
      color: ${fazzbizTheme.status.critical};
      background-color: ${fazzbizTheme.status.subdueCritical};
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-color: ${fazzbizTheme.status.critical};
      border-right: 0;
    }
  }
`

type Props = FormProps & {
  className?: string
  children: React.ReactNode
}

const MainForm = function MainForm({ className = '', children, ...rest }: Props) {
  return (
    <Form className={cx(baseStyle, className)} {...rest}>
      {children}
    </Form>
  )
}

MainForm.Item = function MainFormItem({ className = '', ...rest }: FormItemProps) {
  return <Form.Item className={cx(baseStyle, className)} {...rest} />
}

MainForm.useForm = Form.useForm

export default MainForm
