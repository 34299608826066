import type { PATHS } from '~/biz/routes/paths'
import type {
  UserRoles,
  WALLET_TYPES,
  FundPoolTypes,
  ACCOUNT_TYPES,
  VerificationStatusType,
  BusinessRegistrationCountry,
} from '~/types'

export type User = {
  kcId?: string
  bizId?: string
  merchantId: string // [TODO] type should be number
  fullName: string
  email: string
  countryCode: string
  mobileNumber: string
  companyId: string
  userRole?: UserRoles
  displayname: string
}

export type BusinessEntity = {
  attributes: {
    bizId: string
    companyId?: string
    companyName?: string
    displayName?: string
    enableUploadReceiptFeature?: string
    ownerContactNo?: string
    ownerEmail?: string
    ownerFullName?: string
    ownerKcId: string
    registrationCountry?: BusinessRegistrationCountry
    roles?: string[]
  }
  id: string
}

export type Employers = Pick<BusinessEntity, 'id'> & {
  attributes: Pick<
    BusinessEntity['attributes'],
    'bizId' | 'companyId' | 'companyName' | 'displayName' | 'registrationCountry' | 'roles'
  >
}

export type Account = {
  id: WALLET_TYPES
  balance: string
  type: ACCOUNT_TYPES.BUSINESS | ACCOUNT_TYPES.PERSONAL | ACCOUNT_TYPES.CORPORATE
  name: 'General Wallet' | 'Digital Goods'
  formattedBalance: string
  settlementBalance?: string
  totalBalance?: string
  formattedAvailableBalance?: string
  formattedSettlementBalance?: string
  formattedTotalBalance?: string
  uniqueId: string
  accountId: number
  fundPoolId: number
}

export type WalletIdType = WALLET_TYPES.GENERAL | undefined

export type Wallet = {
  accountConfiguration: { minimumBalance: string } | undefined
  type: WalletIdType
  balance: string
  formattedBalance: string
  label: string
  accountId: number
  accountType: string
  fundPoolId: number
}

export type Notification = {
  id: number
  title: string
  message: string
  created_at: string
  is_read: number
  external_link: string
}

export type FeatureMatrix = {
  userRole: UserRoles | null
  country: BusinessRegistrationCountry
  businessVerificationStatus: VerificationStatusType
  accountManagerVerificationStatus: VerificationStatusType
  availableFundPools: FundPoolTypes[]
  isHighRiskMerchant: boolean
}

export enum RouteActions {
  SET_REDIRECT = 'ROUTE_ACTIONS.SET_REDIRECT',
  SET_USER_PROFILE = 'ROUTE_ACTIONS.SET_USER_PROFILE',
  SET_TRANSACTION_ACCOUNTS = 'ROUTE_ACTIONS.SET_TRANSACTION_ACCOUNTS',
  SET_NOTIFICATIONS = 'ROUTE_ACTIONS.SET_NOTIFICATIONS',
  TOGGLE_NOTIFICATIONS_LOADING = 'ROUTE_ACTIONS.TOGGLE_NOTIFICATIONS_LOADING',
  SET_FEATURE_MATRIX = 'ROUTE_ACTIONS.SET_FEATURE_MATRIX',
  INITIATE_GLOBAL_STORE = 'ROUTE_ACTIONS.INITIATE_GLOBAL_STORE',
  INVALIDATE_GLOBAL_STORE = 'ROUTE_ACTIONS.INVALIDATE_GLOBAL_STORE',
  SET_BUSINESS_ENTITIES = 'ROUTE_ACTIONS.SET_BUSINESS_ENTITIES',
  SET_EMPLOYERS = 'ROUTE_ACTIONS.SET_EMPLOYERS',
  SET_INITIATE_CLEVERTAP = 'ROUTE_ACTIONS.SET_INITIATE_CLEVERTAP',
}

type SetBusinessEntities = {
  type: typeof RouteActions.SET_BUSINESS_ENTITIES
  businessEntities: BusinessEntity[]
}

type SetEmployers = {
  type: typeof RouteActions.SET_EMPLOYERS
  employers: Employers[]
}

type SetRedirect = {
  type: typeof RouteActions.SET_REDIRECT
  to: PATHS | null
}

type SetUserProfile = {
  type: typeof RouteActions.SET_USER_PROFILE
  user: User
}

type SetTransactionAccounts = {
  type: typeof RouteActions.SET_TRANSACTION_ACCOUNTS
  accounts: Account[]
}

type SetNotifications = {
  type: typeof RouteActions.SET_NOTIFICATIONS
  data: Notification[]
  unreadCount: number
  isLoading: boolean
}

type ToggleNotificationsLoading = {
  type: typeof RouteActions.TOGGLE_NOTIFICATIONS_LOADING
  isLoading: boolean
}

type SetFeatureMatrix = {
  type: typeof RouteActions.SET_FEATURE_MATRIX
  featureMatrix: FeatureMatrix
}

type InitiateGlobalStore = {
  type: typeof RouteActions.INITIATE_GLOBAL_STORE
  isInitiated: boolean
}

type InvalidateGlobalStore = {
  type: typeof RouteActions.INVALIDATE_GLOBAL_STORE
  isInitiated: boolean
}

type SetInitiateClevertap = {
  type: typeof RouteActions.SET_INITIATE_CLEVERTAP
}

export type ActionTypes =
  | SetRedirect
  | SetUserProfile
  | SetTransactionAccounts
  | SetNotifications
  | ToggleNotificationsLoading
  | SetFeatureMatrix
  | InitiateGlobalStore
  | InvalidateGlobalStore
  | SetBusinessEntities
  | SetEmployers
  | SetInitiateClevertap
