import {
  StyledBody,
  StyledDisplay,
  StyledHeadline,
  StyledLabel,
  StyledTitle,
} from './Typography.styles'
import { TypographyProps } from './Typography.types'

/**
 * Display are reserved for short, important text or numerals. They work best on large screens. (Landing Page, Hero Banner)
 * @param {'large'|'medium'|'small'} size - the size of the Display typography
 */
function Display({
  size = 'lg',
  style,
  children,
  display = 'block',
  color,
  inline,
  className,
}: TypographyProps) {
  return (
    <StyledDisplay
      size={size}
      display={display}
      style={style}
      color={color}
      inline={inline}
      className={className}
    >
      {children}
    </StyledDisplay>
  )
}

/**
 * Headline are best-suited for short, high-emphasis text on smaller screens. These styles can be good for marking primary passages of text or important regions of content.
 * @param {'large'|'medium'|'small'} size - the size of the Headline typography
 */
function Headline({
  size = 'lg',
  style,
  children,
  display = 'block',
  color,
  inline,
  className,
}: TypographyProps) {
  return (
    <StyledHeadline
      size={size}
      display={display}
      style={style}
      color={color}
      inline={inline}
      className={className}
    >
      {children}
    </StyledHeadline>
  )
}

/**
 * Title are used for shorter, high-emphasis text. For example, consider using title styles to divide secondary passages of text or secondary regions of content (Highest Text for Mobile, Tab and Desktop)
 * @param {'large'|'medium'|'small'} size - the size of the Title typography
 */
function Title({
  size = 'lg',
  style,
  children,
  display = 'block',
  color,
  inline,
  className,
}: TypographyProps) {
  return (
    <StyledTitle
      size={size}
      display={display}
      style={style}
      color={color}
      inline={inline}
      className={className}
    >
      {children}
    </StyledTitle>
  )
}

/**
 * Label are used for areas of the UI such as text inside of components or very small supporting text in the content body, like captions.
 * @param {'large'|'medium'|'small'} size - the size of the Label typography
 */
function Label({
  size = 'lg',
  style,
  children,
  display = 'block',
  color,
  inline,
  className,
}: TypographyProps) {
  return (
    <StyledLabel
      size={size}
      display={display}
      style={style}
      color={color}
      inline={inline}
      className={className}
    >
      {children}
    </StyledLabel>
  )
}

/**
 * Body are used for longer passages of text. It uses typefaces intended for body text. This helps ensure readability at smaller sizes and in longer passages. Avoid expressive or decorative fonts for body text because these can be harder to read at small sizes.
 * @param {'large'|'medium'|'small'} size - the size of the Body typography
 */
function Body({
  size = 'lg',
  style,
  children,
  display = 'block',
  color,
  inline,
  className,
}: TypographyProps) {
  return (
    <StyledBody
      size={size}
      display={display}
      style={style}
      color={color}
      inline={inline}
      className={className}
    >
      {children}
    </StyledBody>
  )
}

export { Display, Headline, Title, Label, Body }
