const commonSidebarLabels = {
  GetStarted: '[non-prod] Get Started',
  InternalTools: '[non-prod] Internal Tools',
  Home: 'Home',

  // Accounts
  Accounts: 'Accounts',

  // Pay
  Pay: 'Pay',
  PayoutForm: 'Payout Form',
  Recipients: 'Recipients',

  // Receive
  Receive: 'Receive',
  PaymentLink: 'Payment Link',

  // Payments API
  PaymentsAPI: 'Payments API',
  SendAPI: 'Send API',
  AcceptAPI: 'Accept API',

  // Loans
  Loans: 'Loans',

  // Cards
  Cards: 'Cards',
  CustomerCards: 'Customer Cards',

  // Earn
  Earn: 'Earn',

  // Developer Tools
  DeveloperTools: 'Developer Tools',

  // Settings
  Settings: 'Settings',
  Team: 'Team',
  MyAccount: 'My Account',

  FAQ: 'FAQ',
  RequestFeature: 'Request a Feature',
} as const

const idSidebarLabels = {
  ...commonSidebarLabels,
  // Accounts
  IDRCashAccount: 'IDR Cash Account',

  // Loans
  ApplyLoan: 'Apply Loan',
  LoanAccount: 'Loan Account',
  FlexPay: 'FlexPay',
} as const

const sgSidebarLabels = {
  ...commonSidebarLabels,
  // Accounts
  SGDCashAccount: 'SGD Cash Account',
  USDCashAccount: 'USD Cash Account',

  // Pay
  PendingApprovals: 'Pending My Approval',

  // Loans
  CreditLine: 'Credit Line',
  TermLoan: 'Term Loan',

  // Cards
  AllCards: 'All Cards',
  MyCard: 'My Card',

  // Earn
  SGDEarnAccount: 'SGD Earn Account',
  USDEarnAccount: 'USD Earn Account',

  // Integrations
  Integrations: 'Integrations',
} as const

export { commonSidebarLabels, idSidebarLabels, sgSidebarLabels }
