import * as React from 'react'

function SvgArrowRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.44 2.44a1.5 1.5 0 000 2.12L18.878 15 8.439 25.44a1.5 1.5 0 002.122 2.12l10.793-10.792a2.5 2.5 0 000-3.536L10.56 2.44a1.5 1.5 0 00-2.122 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgArrowRight
