import styled from '@emotion/styled'
import { DESKTOP_BREAKPOINT } from '../../../themes/deprecated/breakPoints'
import { fazzbizTheme } from '../../../themes/deprecated/theme'
import { Body, LabelLarge } from '../Typography'

const Section = {
  Title: styled(LabelLarge)`
    color: ${fazzbizTheme.base.neutral};
    margin-bottom: 5px;
  `,
  Subtitle: styled(Body)`
    color: ${fazzbizTheme.base.neutralSecondary};
    margin-bottom: 5px;
  `,
  Row: styled.div`
    display: block;
    ${DESKTOP_BREAKPOINT} {
      display: flex;
      flex-wrap: wrap;
    }
  `,
  Left: styled.div`
    flex-basis: 0px;
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: 20px;
    margin-bottom: 20px;
    min-width: unset;

    ${DESKTOP_BREAKPOINT} {
      min-width: 290px;
    }
  `,
  Right: styled.div`
    flex-basis: 0px;
    flex-grow: 1;
    flex-shrink: 0;
    margin-bottom: 20px;
    min-width: unset;

    ${DESKTOP_BREAKPOINT} {
      min-width: 290px;
    }
  `,
}

export default Section
