import { Reducer } from 'redux'
import { featureGateFlippers, envFlippers } from './helpers'
import {
  ActionTypes,
  GetFlipperActions,
  MerchantIdFlippers,
  EmailIdFlippers,
  BizIDflippers,
  EnvMerchantIdFlippers,
} from './types'

export type State = {
  /** @deprecated use featureGate instead */
  merchantId: MerchantIdFlippers
  /** @deprecated use featureGate instead */
  emailId: EmailIdFlippers
  /** @deprecated use featureGate instead */
  bizId: BizIDflippers
  /** @deprecated use featureGate instead */
  envFlipperId: {
    merchantId: EnvMerchantIdFlippers
  }
  /** @deprecated use isFeatureGateLoading instead */
  isFlipperLoading: boolean
  isFlipperInitiated: boolean
}

export const initialState = {
  merchantId: featureGateFlippers.merchantId,
  emailId: featureGateFlippers.emailId,
  bizId: featureGateFlippers.bizId,
  envFlipperId: {
    merchantId: envFlippers.merchantId,
  },
  isFlipperLoading: false,
  isFlipperInitiated: false,
}

export const flippers: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case GetFlipperActions.SET_EMAIL_FLIPPERS: {
      return {
        ...state,
        emailId: action.emailIdFlippers,
      }
    }
    case GetFlipperActions.SET_MERCHANT_ID_FLIPPERS: {
      return {
        ...state,
        merchantId: action.merchantIdFlippers,
      }
    }
    case GetFlipperActions.SET_BIZ_ID_FLIPPERS: {
      return {
        ...state,
        bizId: action.BizIDflippers,
      }
    }
    case GetFlipperActions.SET_ENV_FLIPPERS: {
      return {
        ...state,
        envFlipperId: {
          merchantId: action.Envflippers,
        },
      }
    }
    case GetFlipperActions.SET_FLIPPER_LOADING: {
      return {
        ...state,
        isFlipperLoading: action.isFlipperLoading,
      }
    }
    case GetFlipperActions.SET_IS_FLIPPER_INITIATED: {
      return {
        ...state,
        isFlipperInitiated: action.isFlipperInitiated,
      }
    }
    default:
      return state
  }
}
