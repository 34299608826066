import * as React from 'react'

function SvgPending(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 15c0 5.523-4.477 10-10 10S5 20.523 5 15 9.477 5 15 5s10 4.477 10 10zm3 0c0 7.18-5.82 13-13 13S2 22.18 2 15 7.82 2 15 2s13 5.82 13 13zM16.5 9.5a1.5 1.5 0 00-3 0v6.121l3.94 3.94a1.5 1.5 0 002.12-2.122l-3.06-3.06V9.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPending
