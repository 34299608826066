import { createContext, useContext } from 'react'
import { Header } from '~/types'

type HeaderContextValue = {
  /**
   * The current value of header
   */
  header: Header
  /**
   * IMPORTANT: Use `useHeader()` if you want to override the current header of your route.
   *
   * This is the setState function for header and you only should use this if you need to setState conditionally or manually.
   */
  setHeader: (header: Header) => void
  /**
   * Use this when you want to override the current header of your route.
   * Example usage:
   *    useHeader({ title: 'Payout Form', subtitle: 'For all your transfer needs' })
   * }
   */
  useHeader: (header: Header) => void
}

export const HeaderContext = createContext<HeaderContextValue | null>(null)
export const useHeaderContext = () => useContext(HeaderContext) as HeaderContextValue
