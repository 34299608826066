import { DESKTOP_BREAKPOINT, fdsTheme } from 'fds3-src/index'
import styled from 'styled-components'

const { spacing } = fdsTheme

const ResponsiveTwoCol = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.md};

  > * {
    width: 100%;
  }

  ${DESKTOP_BREAKPOINT} {
    > * {
      width: calc(50% - ${spacing.xs}); // 50% - half of gap
    }
  }
`

export default ResponsiveTwoCol
