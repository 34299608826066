import { CheckCircleFilled, CloseOutlined } from '@ant-design/icons'
import { notification } from 'antd'
import { fazzbizTheme } from '../../../themes/deprecated/theme'

type Props = {
  type: 'success' | 'info' | 'error' | 'warning'
  message: React.ReactNode
}

export default function Notification({ message, type }: Props) {
  if (type === 'success') {
    return notification.success({
      message: <div style={{ color: fazzbizTheme.neutral.white }}>{message}</div>,
      style: { backgroundColor: '#00B583' },
      icon: <CheckCircleFilled style={{ color: fazzbizTheme.neutral.white }} />,
      closeIcon: <CloseOutlined style={{ color: fazzbizTheme.neutral.white }} />,
    })
  }
  return notification[`${type}`]({ message })
}
