/** @deprecated please use fazzBizzThemeFds3 colors theme instead */
export enum GREY {
  S500 = '#777777',
  S400 = '#959595',
  T10 = '#ADADAD',
  S300 = '#CACACA',
  S200 = '#E8E8E8',
  S100 = '#F6F7F9',
  S50 = '#FFFFFF',
  BLUISH = '#D3D7DE',
}
