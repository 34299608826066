import * as React from 'react'

function TypeNewPIN(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<circle cx="18" cy="18" r="18" fill="#ECF5FD" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.8077 13C16.8518 13 16.0769 13.7749 16.0769 14.7308V16.1154H15.0385C14.763 16.1154 14.4989 16.2248 14.3042 16.4195C14.1094 16.6143 14 16.8784 14 17.1538V20.9615C14 21.5351 14.4649 22 15.0385 22H20.5769C21.1504 22 21.6154 21.5351 21.6154 20.9615V17.1538C21.6154 16.5803 21.1504 16.1154 20.5769 16.1154H19.5385V14.7308C19.5385 13.7749 18.7636 13 17.8077 13ZM18.2595 16.1154V14.9C18.2595 14.5176 18.19 14.3 17.8077 14.3C17.4253 14.3 17.3061 14.5176 17.3061 14.9V16.1154H18.2595ZM16.5 17.5H15.5V20.5H20.3V17.5H19H16.5Z"
				fill="currentColor"
			/>
			<path
				d="M25.1523 12.2022L24.0935 13.0301C22.6498 11.1846 20.4045 10 17.8831 10C13.5295 10 10.0056 13.5201 10 17.8756C9.99437 22.2349 13.5257 25.77 17.8831 25.77C21.2868 25.77 24.1874 23.611 25.2913 20.5866C25.3194 20.5077 25.2781 20.4195 25.1993 20.3932L24.1348 20.0271C24.0977 20.0144 24.0571 20.0167 24.0216 20.0335C23.9862 20.0504 23.9588 20.0804 23.9452 20.1172C23.9114 20.2111 23.8739 20.3049 23.8344 20.3969C23.5096 21.1667 23.0441 21.8575 22.4508 22.4508C21.8623 23.0404 21.1656 23.5108 20.3988 23.8363C19.6047 24.1723 18.758 24.3432 17.8869 24.3432C17.0139 24.3432 16.1691 24.1723 15.3749 23.8363C14.6074 23.5122 13.9104 23.0416 13.323 22.4508C12.7329 21.8624 12.263 21.1648 11.9393 20.3969C11.6033 19.6009 11.4324 18.7561 11.4324 17.8831C11.4324 17.0101 11.6033 16.1653 11.9393 15.3693C12.2641 14.5996 12.7297 13.9087 13.323 13.3155C13.9162 12.7222 14.6071 12.2566 15.3749 11.9299C16.1691 11.5939 17.0158 11.4231 17.8869 11.4231C18.7599 11.4231 19.6047 11.5939 20.3988 11.9299C21.1663 12.2541 21.8633 12.7247 22.4508 13.3155C22.6367 13.5013 22.8385 13.7935 23 14L21.8425 14.7873C21.8202 14.8046 21.8031 14.8279 21.7934 14.8544C21.7837 14.881 21.7816 14.9097 21.7875 14.9374C21.7934 14.965 21.807 14.9905 21.8267 15.0107C21.8464 15.031 21.8714 15.0453 21.8988 15.052L25.1955 15.8593C25.2894 15.8818 25.3814 15.8105 25.3814 15.7147L25.3964 12.3186C25.3945 12.1947 25.25 12.1252 25.1523 12.2022Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export default TypeNewPIN
