export const KITCHEN_SINK_PARAMETERS = {
  layout: 'fullscreen',
  options: { showPanel: false },
}

export const MDX_PARAMETERS = {
  viewMode: 'docs',
  options: {
    isToolshown: false,
  },
}

export enum StoryChapters {
  FOUNDATIONS = 'foundations',
  TEMPLATES = 'templates & guides',
  FDS3_ATOMS = 'FDS3 atoms',
  ATOMS = 'atoms',
  MOLECULES = 'molecules',
  LAYOUTS = 'layouts',
  DEPRECATED = 'deprecated',
}

export const Breakpoints = {
  DESKTOP_WIDTH: 1281,
  TABLET_WIDTH: 640,
} as const
