import { StyledBadge } from './Badge.styles'
import type { BadgeProps } from './Badge.types'

export function Badge({
  style,
  className,
  variant,
  children,
  icon,
  size = 'sm',
}: // DO NOT USE SPREAD OPERATOR i.e. {...props}
BadgeProps) {
  return (
    <StyledBadge
      className={className}
      style={style}
      variant={variant}
      icon={icon}
      size={size}
      // DO NOT USE SPREAD OPERATOR i.e. {...props}
    >
      {children}
    </StyledBadge>
  )
}

export default Badge
