/* eslint-disable @typescript-eslint/naming-convention */
import type { VerificationStatusType } from '~/api/idVerification/idVerification.types'
import type { WALLET_TYPES } from '~/types'

export type WalletIdType = WALLET_TYPES.GENERAL | WALLET_TYPES.TEMPORARY_HOLDING_BALANCE | undefined

export type UserDetails = {
  fullName: string
  bankAccounts: BankAccount[]
  accountType?: string
}

export type BankAccount = {
  id: number
  bankName: string
  accountNumber: string
  status: 'unverified' | 'verified' | 'pending' | 'rejected'
}

export type Wallet = {
  type: WalletIdType
  balance: string
  formattedBalance: string
  label: string
  accountId: number
  accountType: string
  fundPoolId: number
}

export enum MyAccountActions {
  GET_USER_DETAILS = 'MY_ACCOUNT_ACTIONS.GET_USER_DETAILS',
  SET_VERIFICATION_STATUS = 'MY_ACCOUNT_ACTIONS.SET_VERIFICATION_STATUS',
  SET_IS_USER_DETAIL_LOADING = 'MY_ACCOUNT_ACTIONS.SET_IS_USER_DETAIL_LOADING',
  DELETE_BANK_ACCOUNTS = 'MY_ACCOUNT_ACTIONS.DELETE_BANK_ACCOUNT',
  GET_WALLETS = 'MY_ACCOUNT_ACTIONS.GET_WALLETS',
}

type GetUserDetails = {
  type: typeof MyAccountActions.GET_USER_DETAILS
  userDetails: UserDetails
}

type SetVerificationStatus = {
  type: typeof MyAccountActions.SET_VERIFICATION_STATUS
  amVerificationStatus: VerificationStatusType
  businessVerificationStatus: VerificationStatusType
}

type DeleteBankAccount = {
  type: typeof MyAccountActions.DELETE_BANK_ACCOUNTS
  bankAccounts: BankAccount[]
}

type SetIsUserDetailLoading = {
  type: typeof MyAccountActions.SET_IS_USER_DETAIL_LOADING
  isUserDetailLoading: boolean
}

type GetWallets = {
  type: typeof MyAccountActions.GET_WALLETS
  wallets: Wallet[]
}

export type ActionTypes =
  | GetUserDetails
  | SetVerificationStatus
  | DeleteBankAccount
  | SetIsUserDetailLoading
  | GetWallets
