import * as React from 'react'

function SvgVecEmptyPlain(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.987 33.008c15.457 0 27.987-2.9 27.987-6.478s-12.53-6.478-27.987-6.478C14.53 20.052 2 22.952 2 26.53s12.53 6.478 27.987 6.478z"
        fill="url(#vec_empty_plain_svg__paint0_linear)"
      />
      <path
        d="M51.396 14.052L41.825 2.86A2.433 2.433 0 0039.988 2h-20.58c-.706 0-1.377.324-1.837.86L8 14.052v6.144h43.396v-6.144z"
        fill="url(#vec_empty_plain_svg__paint1_linear)"
      />
      <path
        d="M48.683 20.422l-8.377-9.748a2.135 2.135 0 00-1.622-.725H20.71c-.617 0-1.22.256-1.622.725l-8.377 9.748v1.354h37.972v-1.354z"
        fill="url(#vec_empty_plain_svg__paint2_linear)"
      />
      <path
        d="M51.396 27.237c0 .853-.384 1.62-.99 2.134l-.123.1c-.48.364-1.067.561-1.67.56H10.784c-.342 0-.669-.062-.971-.176l-.15-.06A2.801 2.801 0 018 27.236V14.12h10.529c1.163 0 2.1.957 2.1 2.118v.015c0 1.162.948 2.1 2.111 2.1h13.916a2.113 2.113 0 002.111-2.108c0-1.166.938-2.125 2.1-2.125h10.53l-.001 13.118z"
        fill="url(#vec_empty_plain_svg__paint3_linear)"
      />
      <defs>
        <linearGradient
          id="vec_empty_plain_svg__paint0_linear"
          x1={27.071}
          y1={33.008}
          x2={27.071}
          y2={20.052}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#96A1C5" stopOpacity={0.373} />
        </linearGradient>
        <linearGradient
          id="vec_empty_plain_svg__paint1_linear"
          x1={29.997}
          y1={7.436}
          x2={29.997}
          y2={20.196}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F8DEE" />
          <stop offset={1} stopColor="#5FA8F2" />
        </linearGradient>
        <linearGradient
          id="vec_empty_plain_svg__paint2_linear"
          x1={29.959}
          y1={9.949}
          x2={29.959}
          y2={25.776}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7E9F9" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="vec_empty_plain_svg__paint3_linear"
          x1={35.49}
          y1={30.031}
          x2={35.49}
          y2={13.273}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECF5FD" />
          <stop offset={1} stopColor="#2F8DEE" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgVecEmptyPlain
