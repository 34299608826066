import { SVGProps } from 'react'

function SvgDeveloperTool(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.983 2.167h-7.95a4.197 4.197 0 0 0-2.95 1.216 4.161 4.161 0 0 0-1.216 2.95v4.859c0 1.108.441 2.166 1.216 2.95a4.161 4.161 0 0 0 2.95 1.216h3.334v1.225H7.575a.625.625 0 0 0 0 1.25h4.867a.625.625 0 0 0 0-1.25h-1.809v-1.225h3.334a4.14 4.14 0 0 0 1.591-.316 4.122 4.122 0 0 0 2.25-2.25c.209-.5.317-1.05.317-1.592V6.333c0-1.1-.433-2.158-1.217-2.941a4.13 4.13 0 0 0-2.933-1.225h.008Zm-7.95 1.25h7.95c.775 0 1.517.308 2.059.858.55.55.858 1.292.858 2.058v4.592H3.117V6.333c0-.775.308-1.516.858-2.058a2.916 2.916 0 0 1 2.058-.858Zm7.95 10.691h-7.95a2.89 2.89 0 0 1-2.692-1.933h13.417a2.881 2.881 0 0 1-2.775 1.933Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.834 5.25a.51.51 0 0 1 .758 0l1.425 1.516a.59.59 0 0 1 0 .8l-1.425 1.517a.51.51 0 0 1-.758 0 .59.59 0 0 1 0-.8l1.05-1.117-1.05-1.116a.59.59 0 0 1 0-.8ZM8.183 9.083a.51.51 0 0 1-.758 0L6 7.566a.59.59 0 0 1 0-.8L7.425 5.25a.51.51 0 0 1 .758 0 .59.59 0 0 1 0 .8l-1.05 1.116 1.05 1.117a.59.59 0 0 1 0 .8Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgDeveloperTool
