import * as React from 'react'

function SvgAccessApiTwoTone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.56 13.94a1.5 1.5 0 010 2.12l-4 4a1.5 1.5 0 01-2.12-2.12L24.378 15l-2.94-2.94a1.5 1.5 0 012.122-2.12l4 4zM2.44 13.94a1.5 1.5 0 000 2.12l4 4a1.5 1.5 0 002.12-2.12L5.622 15l2.94-2.94a1.5 1.5 0 00-2.122-2.12l-4 4z"
        fill="#1B2736"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.622 27.951a1.5 1.5 0 001.83-1.073l6-23a1.5 1.5 0 00-2.903-.757l-6 23a1.5 1.5 0 001.073 1.83z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgAccessApiTwoTone
