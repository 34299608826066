import type { PERMISSIONS } from '~/types'
import rules from './rules'
import type { FeatureMatrixSelectorsType } from './types'

const hasPermission = (permission: PERMISSIONS, featureMatrix: FeatureMatrixSelectorsType) => {
  const { role } = featureMatrix

  const hasStaticPermission = role && rules[role]?.static.includes(permission)
  const hasDynamicPermission = role && rules[role]?.dynamic[permission]?.(featureMatrix)

  return hasStaticPermission || hasDynamicPermission
}

export default hasPermission
