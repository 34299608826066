import type { Reducer } from 'redux'
import type { UsdAccountTransaction, ActionTypes, FilterValues, FilterOptions } from './types'
import { UsdAccountTransactionActions } from './types'

const initialState = {
  transactions: [],
  isTransactionsLoading: false,
  totalCount: 0,
  filterValues: {
    startDate: '',
    endDate: '',
    transactionType: undefined,
    sortType: undefined,
    currentPage: 1,
    pageSize: 10,
  },
  filterOptions: null,
}

type State = {
  transactions: UsdAccountTransaction[]
  isTransactionsLoading: boolean
  totalCount: number
  filterValues: FilterValues
  filterOptions: null | FilterOptions
}

export const usdAccountTransactions: Reducer<State, ActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UsdAccountTransactionActions.GET_DATA:
      return {
        ...state,
        transactions: action.transactions,
        totalCount: action.totalCount,
      }

    case UsdAccountTransactionActions.GET_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: action.filterOptions,
      }

    case UsdAccountTransactionActions.SET_IS_LOADING:
      return {
        ...state,
        isTransactionsLoading: action.isTransactionsLoading,
      }

    case UsdAccountTransactionActions.SET_FILTERS: {
      const { filterValuesObject } = action

      const newTabFilterValues: FilterValues = {
        ...state.filterValues,
      }

      Object.keys(filterValuesObject).forEach((key: string) => {
        newTabFilterValues[key] = filterValuesObject[key]
      })

      return {
        ...state,
        filterValues: {
          ...newTabFilterValues,
        },
      }
    }

    default:
      return state
  }
}
