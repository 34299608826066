import * as React from 'react'

function SvgHsbc(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8.09 9h16.144v16.012H8.09V9z" fill="#fff" />
      <path
        d="M32.306 17.025L24.234 9v16.03l8.072-8.005zm-16.144 0L24.234 9H8.09l8.072 8.025z"
        fill="#DB0011"
      />
      <path
        d="M0 17.025l8.091 8.006V9L0 17.025zm16.163 0L8.091 25.03h16.145l-8.073-8.006z"
        fill="#DB0011"
      />
      <path
        d="M39.391 17.495h-2.925v2.863h-1.462v-6.706h1.462v2.75h2.925v-2.75h1.463v6.707H39.39v-2.864zm4.996 2.995c-1.463 0-2.66-.584-2.678-2.185h1.462c.019.716.437 1.15 1.235 1.15.588 0 1.272-.302 1.272-.961 0-.528-.456-.679-1.215-.905l-.494-.131c-1.064-.302-2.127-.716-2.127-1.922 0-1.488 1.405-1.997 2.678-1.997 1.31 0 2.45.452 2.469 1.94h-1.463c-.057-.602-.418-.96-1.101-.96-.551 0-1.083.283-1.083.885 0 .49.456.64 1.406.942l.57.17c1.158.358 1.899.753 1.899 1.883-.02 1.507-1.5 2.091-2.83 2.091zm3.646-6.819h2.355c.437-.019.893 0 1.33.076.817.188 1.443.715 1.443 1.62 0 .866-.55 1.3-1.348 1.507.912.17 1.595.621 1.595 1.62 0 1.525-1.519 1.864-2.697 1.864h-2.659l-.019-6.687zm2.355 2.788c.646 0 1.311-.132 1.311-.904 0-.697-.608-.885-1.215-.885h-1.026v1.79h.93zm.134 2.901c.683 0 1.348-.15 1.348-.98 0-.828-.57-.979-1.273-.979H49.44v1.96h1.083zm6.685 1.13c-2.184 0-3.153-1.375-3.153-3.428s1.083-3.542 3.23-3.542c1.348 0 2.658.603 2.696 2.11h-1.52c-.075-.678-.531-1.017-1.177-1.017-1.33 0-1.728 1.413-1.728 2.487 0 1.073.398 2.317 1.671 2.317.665 0 1.159-.358 1.254-1.036H60c-.153 1.544-1.387 2.11-2.793 2.11z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgHsbc
