import Spin from 'antd/es/spin'
import 'antd/es/spin/style/css'

export default function PageLoader() {
  return (
    <div
      data-test-id="xf-lazy-loading-icon"
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'white',
        position: 'fixed',
        top: '0px',
        left: '0px',
      }}
    >
      <Spin size="large" />
    </div>
  )
}
