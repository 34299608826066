import type { AxiosRequestHeaders } from 'axios'
import { getApiMethods } from '~/api'
import type ENDPOINTS from '~/api/paths'
import { AppType } from '~/types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ResponsePromise = Promise<any>

function get(endpoint: ENDPOINTS, subRoute = ''): ResponsePromise {
  return getApiMethods(AppType.INDONESIA).get(endpoint, subRoute)
}

function post(
  endpoint: ENDPOINTS,
  requestBody: object,
  additionalHeaders: AxiosRequestHeaders = {},
  subRoute = ''
): ResponsePromise {
  return getApiMethods(AppType.INDONESIA).post(endpoint, requestBody, additionalHeaders, subRoute)
}

function del(
  endpoint: ENDPOINTS,
  additionalHeaders: AxiosRequestHeaders = {},
  subRoute = ''
): ResponsePromise {
  return getApiMethods(AppType.INDONESIA).del(endpoint, additionalHeaders, subRoute)
}

function put(
  endpoint: ENDPOINTS,
  requestBody: object,
  additionalHeaders: AxiosRequestHeaders = {},
  subRoute = ''
): ResponsePromise {
  return getApiMethods(AppType.INDONESIA).put(endpoint, requestBody, additionalHeaders, subRoute)
}

export { get, post, del, put }
