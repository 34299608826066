import { ConfigManager, ENVIRONMENT } from '~/managers/ConfigManager'

export const featureGateFlippers = {
  merchantId: {
    xfpay_disable_withdraw: false,
    xfpay_disable_disbursements: false,
    xfpay_disable_xfers_accept: false,
    xfpay_enable_xfers_send: false,
    xfpay_disable_payment_link_by_merchants: false,
    fazzbiz_enable_loan_account: false,
    fazzbiz_debitcard_phase3_enabled: false,
    fazzbiz_id_cash_account_pdf_export_enabled: false,
  },
  emailId: {
    xfregional_enable_credit_cards_production: false,
    fazzbiz_enable_usd_account: false,
    fazzbiz_enable_test_financing_management_by_email: false,
    fazzbiz_enable_bss_va_fp3: false,
    direct_fast_va_adaption: false,
    fazzbiz_enable_new_loan_dashboard: false,
    fazzbiz_id_test_whitelist_email_loan_self_serve: false,
  },
  bizId: {
    fazzbiz_enable_flex_pay_account: false,
    fazzbiz_whitelist_rbac133_id_legacy: false,
    fazzbiz_enable_api_menu: false,
    fazzbiz_is_wallex_account_verified: false,
    fazzbiz_sg_enable_usd_transfer_out: false,
    fazzbiz_whitelist_disable_sgd_account_details: false,
    fazzbiz_whitelist_enable_sandbox_pay_menu: false,
  },
}

// env flippers (unique flipper id based on staging / production environment)

export const envFlippers = {
  merchantId: {
    enable_merchants_with_high_nesting_risk: false,
    enable_v3_for_morak_merchants: false,
  },
}

/** @deprecated use generateFeatureGateUrlParams from featureGate helpers instead */
export const generateFlipperURLparams = () => {
  const queryParams = {
    emailParams: Array.from(Object.keys(featureGateFlippers.emailId)).join('%22,%22'),
    merchantIdParams: Array.from(Object.keys(featureGateFlippers.merchantId)).join('%22,%22'),
    bizIdParams: Array.from(Object.keys(featureGateFlippers.bizId)).join('%22,%22'),
    envMerchantIdParams: Array.from(Object.keys(envFlippers.merchantId)).join('%22,%22'),
  }
  return queryParams
}

// concat staging or production string to the flipperId based on current environmentEN
/** @deprecated use generateFeatureGateID from featureGate helpers instead */
export const generateEnvFlipperID = (flipperId: string) => {
  if (ConfigManager.getEnvironmentMode() === ENVIRONMENT.PRODUCTION) {
    return `production_Merchant_${flipperId}`
  }
  return `staging_Merchant_${flipperId}`
}
