import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import BlankStepUpAuth from './components/BlankStepUpAuth'
import PageLoader from './components/PageLoader'
import './i18n'
import reportWebVitals from './reportWebVitals'

if (process.env.NODE_ENV === 'development') {
  const { worker } = await import('./mocks/browser')
  const { weakStart } = await import('./mocks/weakStart')
  weakStart(worker)
}

const AuthenticationPath = '/sso_step_up_authentication'

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<PageLoader />}>
      {window.location.pathname === AuthenticationPath ? <BlankStepUpAuth /> : <App />}
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
