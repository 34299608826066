import { SVGProps } from 'react'

function SvgEmployee(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.15 12.05h-.57a7.822 7.822 0 0 1 1.66 1.64 4.74 4.74 0 0 1 3.65 4.6 2.1 2.1 0 0 1-2.09 2.09h-.49a5.001 5.001 0 0 1-1 1.5h1.49a3.59 3.59 0 0 0 3.59-3.59 6.241 6.241 0 0 0-6.24-6.24ZM14.51 3.63h.05a2.79 2.79 0 0 1 0 5.57h-.06a5.641 5.641 0 0 1-1 1.35c.352.1.715.154 1.08.16A4.29 4.29 0 1 0 13.5 2.3c.404.389.744.837 1.01 1.33Z"
        fill="currentColor"
      />
      <path
        d="M11 13.55a4.74 4.74 0 0 1 4.73 4.74 2.09 2.09 0 0 1-2.09 2.09H5.2a2.1 2.1 0 0 1-2.09-2.09 4.75 4.75 0 0 1 4.74-4.74H11Zm0-1.5H7.85a6.24 6.24 0 0 0-6.24 6.24 3.59 3.59 0 0 0 3.59 3.59h8.48a3.59 3.59 0 0 0 3.59-3.59A6.231 6.231 0 0 0 11 12.05ZM9.44 3.62a2.79 2.79 0 1 1 .02 5.58 2.79 2.79 0 0 1-.02-5.58Zm0-1.5a4.29 4.29 0 1 0 0 8.58 4.29 4.29 0 0 0 0-8.58Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgEmployee
