/* eslint-disable no-underscore-dangle */
import clevertap from 'clevertap-web-sdk'
import cookie from 'js-cookie'
import TagManager from 'react-gtm-module'
import { UserAgent } from '~/types'
import { clevertapProjectIds } from '~/common/Clevertap/clevertapConfig'
import { LocalHostNames, StagingHostNames, ProductionHostNames } from '~/common/hostnames'

const stagingAndLocalHostNames = { ...LocalHostNames, ...StagingHostNames }
const fazzHostNames = { ...stagingAndLocalHostNames, ...ProductionHostNames }

type RedirectionLinkType = {
  redirectPath: string
  fromFazzbiz: boolean
}

type DataLayer = {
  campaign_id: string
  campaign_name: string
  campaign_source: string
  [key: string]: string
}

function setGoogleTagFromSso(campaignUrl: string): DataLayer {
  const dataLayer: DataLayer = {
    campaign_id: '',
    campaign_name: '',
    campaign_source: '',
  }

  const dataLayerKeyMap: Record<string, string> = {
    utm_id: 'campaign_id',
    utm_campaign: 'campaign_name',
    utm_source: 'campaign_source',
    utm_medium: 'campaign_medium',
    utm_term: 'campaign_term',
    utm_content: 'campaign_content',
  }

  const sanitisedURL = decodeURIComponent(campaignUrl)
  const parsedUrl = new URL(sanitisedURL)
  const searchParams = new URLSearchParams(parsedUrl.searchParams)

  searchParams.forEach((item, key) => {
    if (key in dataLayerKeyMap) {
      dataLayer[dataLayerKeyMap[key]] = item
    } else {
      dataLayer[key] = item
    }
  })

  return { ...dataLayer }
}

/* Note:
  1. EnvironmentType: development/staging/production
*/
export enum ENVIRONMENT {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}

type TagManagerArgs = {
  gtmId: string
  dataLayer?: DataLayer
}

const DEFAULT = {
  ENVIRONMENT: ENVIRONMENT.DEVELOPMENT,
}

// Override here for dynamic changes to these values
// which will only require browser refresh to take effect
const OVERRIDE = {
  ENVIRONMENT: undefined,
}

function initGetWebOrAppAgent(): UserAgent {
  const { userAgent } = window.navigator

  if (userAgent.includes('Mobile/Android') || userAgent.includes('Mobile/IOS')) {
    return UserAgent.MOBILE_APP
  }
  return UserAgent.WEB
}

export const getWebOrAppAgent = initGetWebOrAppAgent()

export const getIsStagingOrLocal = () =>
  Object.values(stagingAndLocalHostNames).includes(window.location.hostname)

const isUrlWithinFazzBiz = (host: string) => Object.values(fazzHostNames).includes(host)

export const getRedirectionLink = (url: string) => {
  const payload: RedirectionLinkType = { redirectPath: url, fromFazzbiz: false }
  try {
    const link = new URL(payload.redirectPath)

    if (isUrlWithinFazzBiz(link.hostname)) {
      payload.redirectPath = link.pathname
      payload.fromFazzbiz = true
    }

    return payload
  } catch {
    payload.fromFazzbiz = true
    return payload
  }
}

class Config {
  private readonly environmentMode: ENVIRONMENT

  public constructor() {
    const envEnv = process.env.REACT_APP_MODE as ENVIRONMENT
    this.environmentMode = OVERRIDE.ENVIRONMENT || envEnv || DEFAULT.ENVIRONMENT
  }

  public setupGTM() {
    const gtmCampaignUrl = cookie.get('__gtm_campaign_url')

    const tagManagerArgs: TagManagerArgs = {
      gtmId: '',
    }

    // TODO: by env instead of hostname, remove unused biz&regional
    if (this.getEnvironmentMode() === ENVIRONMENT.PRODUCTION) {
      tagManagerArgs.gtmId = 'GTM-WKRZ2DP'
    } else if (
      this.getEnvironmentMode() === ENVIRONMENT.STAGING &&
      window.location.hostname === StagingHostNames.NEW_STAGING
    ) {
      tagManagerArgs.gtmId = 'GTM-NQTQGPL'
    } else if (
      this.getEnvironmentMode() === ENVIRONMENT.STAGING &&
      window.location.hostname !== StagingHostNames.NEW_STAGING
    ) {
      tagManagerArgs.gtmId = 'GTM-MWZQB4N'
    } else if (this.getEnvironmentMode() === ENVIRONMENT.DEVELOPMENT) {
      tagManagerArgs.gtmId = 'GTM-5QJX6KX'
    } else {
      // throw error if it doesn't fit into any of the four cases
      throw new Error('Does not match any existing site type and country in Prod')
    }

    if (gtmCampaignUrl) {
      tagManagerArgs.dataLayer = setGoogleTagFromSso(gtmCampaignUrl)
    }

    TagManager.initialize(tagManagerArgs)
  }

  public setupClevertap() {
    const clevertapManagerArgs = {
      accountId: '',
    }

    if (this.getEnvironmentMode() === ENVIRONMENT.PRODUCTION) {
      clevertapManagerArgs.accountId = clevertapProjectIds.production
    } else if (this.getEnvironmentMode() === ENVIRONMENT.STAGING) {
      clevertapManagerArgs.accountId = clevertapProjectIds.staging
    } else {
      clevertapManagerArgs.accountId = clevertapProjectIds.development
    }

    clevertap.privacy.push({ optOut: false })
    clevertap.privacy.push({ useIP: false })
    clevertap.init(clevertapManagerArgs.accountId, 'sg1')

    // when in dev or staging env attach debugger
    // log level 3 to display all logs
    if (
      this.getEnvironmentMode() === ENVIRONMENT.DEVELOPMENT ||
      this.getEnvironmentMode() === ENVIRONMENT.STAGING
    ) {
      clevertap.setLogLevel(3)
    }
  }

  public getEnvironmentMode() {
    return this.environmentMode
  }
}

export const ConfigManager = new Config()

export const isProduction = ConfigManager.getEnvironmentMode() === ENVIRONMENT.PRODUCTION
export const isStaging = ConfigManager.getEnvironmentMode() === ENVIRONMENT.STAGING
export const isDevelopment = ConfigManager.getEnvironmentMode() === ENVIRONMENT.DEVELOPMENT
export const isLocal =
  window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
