import { fdsTheme } from 'fds3-src/themes/theme-fds3'
import styled from 'styled-components'
import { Drawer } from 'antd'
import type { DrawerPadding, DrawerProps } from './Drawer.types'

const { colors, radius, spacing } = fdsTheme

const getPadding = (padding?: DrawerPadding) => {
  switch (padding) {
    case 'none':
      return 0
    case 'sm':
      return spacing.sm
    case 'md':
      return spacing.md
    case 'lg':
    default:
      return spacing.lg
  }
}

export const StyledDrawer = styled(Drawer).attrs((props: DrawerProps) => ({
  isBottomsheet: props.isBottomsheet,
  padding: props.padding,
  overflow: props.overflow,
}))`
  .ant-drawer-header {
    display: flex;
    justify-content: space-between;
    padding: ${({ padding }) => getPadding(padding)};
    border: none;
  }
  .ant-drawer-close {
    position: relative;
    padding: 0;
  }
  .ant-drawer-body {
    overflow: ${({ overflow }) => overflow};
    padding: 0 ${({ padding }) => getPadding(padding)};
  }

  .ant-drawer-content {
    border-top-left-radius: ${({ isBottomsheet }) => (isBottomsheet ? radius.lg : 0)};
    border-top-right-radius: ${({ isBottomsheet }) => (isBottomsheet ? radius.lg : 0)};
  }

  .ant-drawer-mask {
    background-color: ${colors.colorOverlay};
  }
`
