import type { Reducer } from 'redux'
import type { ActionTypes, FilterValues, FilterOptions } from './types'
import { InvoicingActions } from './types'

const initialState = {
  filterOptions: null,
  filterValues: {
    startDate: '',
    endDate: '',
    invoiceStatuses: [],
    orderBy: '',
    order: 'desc',
    currentPage: 1,
    pageLimit: 10,
    searchString: '',
    isInvoiceLoading: false,
    invoices: [],
    totalCount: 0,
  },
}

type State = {
  filterOptions: null | FilterOptions
  filterValues: FilterValues
}

export const invoicing: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case InvoicingActions.SET_INVOICES_FILTER_OPTIONS: {
      return { ...state, filterOptions: action.filterOptions }
    }

    case InvoicingActions.GET_INVOICES: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          invoices: action.invoices,
          totalCount: action.totalCount,
        },
      }
    }

    case InvoicingActions.SET_IS_INVOICE_LOADING: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          isInvoiceLoading: action.isInvoiceLoading,
        },
      }
    }

    case InvoicingActions.SET_FILTERS: {
      const { filterValuesObject } = action

      const newTabFilterValues: FilterValues = {
        ...state.filterValues,
      }

      Object.keys(filterValuesObject).forEach((key: string) => {
        newTabFilterValues[key] = filterValuesObject[key]
      })

      return {
        ...state,
        filterValues: {
          ...newTabFilterValues,
        },
      }
    }

    default:
      return state
  }
}
