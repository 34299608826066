import * as React from 'react'

function SvgReceipt(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 20C21 20.552 20.553 21 20 21H4C3.447 21 3 20.552 3 20V15C3 14.448 3.447 14 4 14H5V15C5 16.104 5.896 17 7 17H17C18.104 17 19 16.104 19 15V14H20C20.552 14 21 14.449 21 15V20ZM22.982 14C22.988 13.81 22.947 13.617 22.832 13.445L18.832 7.445C18.646 7.167 18.334 7 18 7C17.447 7 17 7.448 17 8C17 8.207 17.078 8.388 17.186 8.547L17.465 9L19.465 12H19C17.896 12 17 12.896 17 14V15H7V14C7 12.896 6.104 12 5 12H4.535L6.535 9L6.884 8.445C6.952 8.31 7 8.162 7 8C7 7.448 6.553 7 6 7C5.666 7 5.354 7.167 5.168 7.445L1.168 13.445C1.054 13.616 1.018 13.809 1.019 14H1V15V20C1 21.657 2.343 23 4 23H20C21.657 23 23 21.657 23 20V15V14H22.982Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 8.98523L12.918 10.0095L12 11L11.082 10.0095L9.99998 8.98523V4.07368C9.99998 3.48127 10.485 3 11.082 3H12.918C13.515 3 14 3.48127 14 4.07368V8.98523ZM16.735 9.549L15.9722 10.009V3.082C15.9722 1.932 15.0429 1 13.8963 1H10.0715C8.92485 1 7.99557 1.932 7.99557 3.082V10.003L7.24577 9.549C7.01544 9.408 6.70535 9.482 6.56177 9.719C6.42019 9.956 6.49497 10.263 6.73128 10.406L11.9839 14L17.2485 10.406C17.4848 10.264 17.5605 9.957 17.419 9.72C17.2774 9.483 16.9713 9.408 16.735 9.549Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgReceipt
