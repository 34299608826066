import { SVGProps } from 'react'

function DownloadFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.6275 5.54257L9.3225 1.23757C9.18207 1.09629 9.0149 0.984403 8.83076 0.908429C8.64662 0.832455 8.44919 0.793918 8.25 0.795069H5.4375C4.24403 0.795069 3.09943 1.26917 2.25552 2.11309C1.41161 2.957 0.9375 4.10159 0.9375 5.29507V12.7051C0.9375 13.8985 1.41161 15.0431 2.25552 15.887C3.09943 16.731 4.24403 17.2051 5.4375 17.2051H9.5625C10.756 17.2051 11.9006 16.731 12.7445 15.887C13.5884 15.0431 14.0625 13.8985 14.0625 12.7051V6.60007C14.0628 6.20396 13.9064 5.82381 13.6275 5.54257ZM12.39 5.90257H11.7525C11.0563 5.90257 10.3886 5.62601 9.89634 5.13372C9.40406 4.64144 9.1275 3.97376 9.1275 3.27757V2.64007L12.39 5.90257ZM6.75 13.1101L4.755 11.0926C4.70135 11.0414 4.65896 10.9797 4.63055 10.9112C4.60214 10.8428 4.58833 10.7692 4.59 10.6951C4.58965 10.6212 4.60407 10.5479 4.6324 10.4797C4.66073 10.4114 4.70242 10.3495 4.755 10.2976C4.86047 10.1922 5.00344 10.1331 5.1525 10.1331C5.30156 10.1331 5.44453 10.1922 5.55 10.2976L6.93 11.6776V7.50007C6.93 7.35088 6.98926 7.20781 7.09475 7.10232C7.20024 6.99683 7.34331 6.93757 7.4925 6.93757C7.64168 6.93757 7.78476 6.99683 7.89025 7.10232C7.99574 7.20781 8.055 7.35088 8.055 7.50007V11.6851L9.45 10.2826C9.55663 10.1832 9.69766 10.1291 9.84339 10.1317C9.98912 10.1343 10.1282 10.1933 10.2312 10.2964C10.3343 10.3994 10.3933 10.5384 10.3959 10.6842C10.3985 10.8299 10.3444 10.9709 10.245 11.0776L8.25 13.1101C8.15185 13.2088 8.03492 13.2869 7.90611 13.3397C7.7773 13.3925 7.63921 13.419 7.5 13.4176C7.36061 13.4203 7.22214 13.3944 7.09314 13.3415C6.96414 13.2886 6.84736 13.2099 6.75 13.1101Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default DownloadFilled
