import { SVGProps } from 'react'

function SvgLoan(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#loanNew_svg__a)" fill="currentColor">
        <path d="M18.1 13.117H17.09v-.834a1.083 1.083 0 0 0-1.083-1.083h-1.841a1.084 1.084 0 0 0-1.084 1.083v.834H11.9a.833.833 0 0 0-.834.833v4.333a.833.833 0 0 0 .834.834h6.2a.833.833 0 0 0 .833-.834V13.95a.833.833 0 0 0-.833-.833Zm-3.8-.667h1.533v.667H14.3v-.667Zm3.383 1.917v.833h-5.367v-.833h5.367Zm-5.367 3.5v-1.459h5.367v1.459h-5.367ZM8.741 13.508H5.358a.625.625 0 0 1 0-1.25h3.383a.625.625 0 1 1 0 1.25ZM8.741 16.083H5.358a.625.625 0 0 1 0-1.25h3.383a.625.625 0 1 1 0 1.25Z" />
        <path d="M9.625 17.867H6.067a3.75 3.75 0 0 1-3.75-3.75V5.883a3.75 3.75 0 0 1 3.75-3.75h2.85v1.509a4.167 4.167 0 0 0 4.166 4.166h1.325v1.967a.625.625 0 0 0 .625.625.633.633 0 0 0 .625-.625V7.333a1.667 1.667 0 0 0-.492-1.175l-4.783-4.783A1.667 1.667 0 0 0 9.208.883H6.067a5 5 0 0 0-5 5v8.234a5 5 0 0 0 5 5h3.558a.633.633 0 0 0 .625-.625.633.633 0 0 0-.625-.625Zm.541-14.934 3.625 3.625h-.708a2.917 2.917 0 0 1-2.917-2.916v-.709Z" />
        <path d="M7.766 9.35c-.255.254-.591.41-.95.442v.716l-.625.125V9.8a2.983 2.983 0 0 1-.633-.083 4.74 4.74 0 0 1-.617-.192l.125-.733c.38.068.765.107 1.15.116.517 0 .767-.15.767-.433a.267.267 0 0 0-.117-.233 2.716 2.716 0 0 0-.425-.234l-.208-.091L5.9 7.75a2.333 2.333 0 0 1-.759-.575A1.242 1.242 0 0 1 4.9 6.4a1.283 1.283 0 0 1 .341-.917c.258-.25.593-.406.95-.441v-.709l.625-.125V5c.326.029.645.113.942.25l-.125.733a5.758 5.758 0 0 0-.967-.066.833.833 0 0 0-.475.116.375.375 0 0 0-.166.325.333.333 0 0 0 .125.25 1.6 1.6 0 0 0 .383.25l.35.175c.24.122.472.261.692.417.16.111.293.256.391.425.097.173.146.368.142.567a1.267 1.267 0 0 1-.342.908Z" />
      </g>
      <defs>
        <clipPath id="loanNew_svg__a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgLoan
