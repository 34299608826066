import { css } from 'emotion'
import { Radio } from 'formik-antd'
import { NEUTRAL_COLORS } from '../../../../themes/deprecated/ColorStyles'
import { MAIN_FONT } from '../../../../themes/deprecated/TypographyStyles'
import { useTheme } from '../../../../themes/deprecated/themeContext'

type Props = {
  children: React.ReactNode
  disabled?: boolean
  value?: string
  style?: React.CSSProperties
  alignItems?: string
}

function FormikRadioButton({ children, disabled, alignItems, ...rest }: Props) {
  const { base } = useTheme()

  const style = `
    &.ant-radio-button-wrapper {
      ${!disabled ? `color: ${base.neutral};` : ''}
      -webkit-font-smoothing: antialiased;
      display: block;
      width: 100%;
      height: 100%;
      font-family: ${MAIN_FONT};
      font-weight: 400;
      border-radius: 5px;
      border: 1px solid ${NEUTRAL_COLORS.G200};
      user-select: none;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }

    .ant-radio-group-large &.ant-radio-button-wrapper {
      height: 44px;
    }

    &.ant-radio-button-wrapper:first-child, &.ant-radio-button-wrapper:last-child {
      border-radius: 5px;
    }
    
    &.ant-radio-button-wrapper:first-child:last-child {
      border-radius: 5px;
    }

    .ant-radio-group &.ant-radio-button-wrapper {
      font-size: 14px;
    }

    &.ant-radio-button-wrapper:not(:first-child)::before {
      display: none;
    }

    &.ant-radio-button-wrapper:hover {
      ${
        !disabled
          ? `
        border-color: ${base.primary};
        color: ${base.neutral};
      `
          : ''
      }
    }

    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
      border-color: ${base.primary};
      color: ${base.primary};

      circle {
        transition: all 0.3s;
      }

      circle.center {
        fill: ${base.primary};
      }

      circle.border {
        stroke: ${base.primary};
      }
    }

    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
      border-color: ${base.primary};
      color: ${base.primary};
    }
  `

  return (
    <Radio.Button className={css(style)} disabled={disabled} {...rest}>
      <div
        style={{
          display: 'flex',
          height: '100%',
          alignItems: alignItems || 'center',
        }}
      >
        <div
          style={{
            width: '20px',
            height: '20px',
            lineHeight: '20px',
            marginRight: '11px',
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle className="border" cx="10" cy="10" r="9.5" stroke="#E8E8E8" />
            <circle className="center" cx="10" cy="10" r="7" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </div>
        {children}
      </div>
    </Radio.Button>
  )
}

export default FormikRadioButton
