import { Button } from 'fds3-src/components/atoms/Button'
import Divider from 'fds3-src/components/deprecated/Divider'
import { useState, useEffect } from 'react'
import type { UploadChangeParam } from 'antd/lib/upload/interface'
import {
  UploadFilled,
  CloseCircle,
  IcDocumentSuccess,
  PendingFilled,
  WarningFilled,
  IcImageSuccess,
} from '@fazz/design-system-icons'
import { Body, Label } from '../../../themes/Typography'
import { fdsTheme } from '../../../themes/theme-fds3'
import {
  StyledUploadBox,
  UploadBoxContainer,
  UploadInfo,
  UploadedFileList,
} from './UploadBox.styles'
import type { FileList, UploadBoxProps, UploadBoxStatus } from './UploadBox.types'

const { colors, spacing } = fdsTheme

const UploadStatus = {
  empty: 'empty',
  uploading: 'uploading',
  success: 'success',
  error: 'error',
}

const uploadBoxMessage = (errMsg: string) =>
  new Map([
    [
      UploadStatus.uploading,
      <UploadedFileList>
        <div style={{ display: 'flex', gap: spacing.xs, alignItems: 'flex-start' }}>
          <PendingFilled width={20} height={20} color={colors.primary} />
          <Label size="md">Uploading...</Label>
        </div>
      </UploadedFileList>,
    ],
    [
      UploadStatus.error,
      <div>
        <UploadedFileList>
          <div style={{ display: 'flex', gap: spacing.xs, alignItems: 'flex-start' }}>
            <WarningFilled width={20} height={20} color={colors.critical} />
            <Body size="md" color={colors.critical}>
              {errMsg.length === 0
                ? 'Failed to upload. Please check file size and file type and try again.'
                : errMsg}
            </Body>
          </div>
        </UploadedFileList>
      </div>,
    ],
  ])

export function UploadBox({
  isDisabled = false,
  maxFileSize = 5,
  style,
  className,
  downloadableLink,
  label,
  onUpload,
  onRemove,
  isMultiple,
  defaultFileList,
  disableStatusUpdate = false,
  variant = 'image',
  customErrorMessage = '',
}: UploadBoxProps) {
  const [uploadStatus, setUploadStatus] = useState(UploadStatus.empty)
  const [uploadedAttachments, setUploadAttachments] = useState<FileList>([])

  const allowedFileTypes =
    variant === 'image'
      ? ['image/jpeg', 'image/png', 'application/pdf']
      : [
          'text/csv',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ]

  useEffect(() => {
    if (defaultFileList) {
      if (!isMultiple && defaultFileList.length > 1) {
        throw new Error('Please set isMultiple to true for handling multiple files.')
      }
      setUploadAttachments(defaultFileList)
    }
  }, [])

  const handleOnChange = (info: UploadChangeParam) => {
    if (!disableStatusUpdate) {
      setUploadStatus(UploadStatus.uploading)
    }

    const file = info.fileList.slice(-1)
    const uploadedFile = file[0]

    /** File Validation */
    let validFile = true
    const exceedMaxFileSize = uploadedFile.size / 1024 / 1024 > maxFileSize
    if (!allowedFileTypes.includes(uploadedFile.type) || exceedMaxFileSize) {
      setUploadStatus(UploadStatus.error)
      validFile = false
    }
    if (!validFile) return
    /** End of File Validation */

    const originFileObject = uploadedFile.originFileObj as File

    const getUrl = URL.createObjectURL(originFileObject)
    const fileName = originFileObject ? originFileObject.name : 'attachment'
    const fileObj = { getUrl, fileName }
    const newAttachmentList = isMultiple ? [...uploadedAttachments, fileObj] : [fileObj]
    setUploadAttachments(newAttachmentList)

    if (!disableStatusUpdate) {
      setUploadStatus(UploadStatus.success)
    }

    if (onUpload) {
      onUpload({
        file: originFileObject,
        fileList: newAttachmentList,
        updateStatus: (status: UploadBoxStatus) => {
          setUploadStatus(status)
        },
      })
    }
  }

  return (
    <StyledUploadBox
      disabled={isDisabled}
      className={className}
      style={style}
      fileList={[]}
      accept={allowedFileTypes!.join(',')}
      onChange={handleOnChange}
    >
      <UploadBoxContainer>
        <Body>{label}</Body>
        <UploadInfo>
          <div>
            <Body size="sm" color={colors.onNeutralSecondary}>
              {variant === 'image'
                ? 'Supported files JPG, PNG, or PDF'
                : 'Supported files CSV, XLS, XLSX'}
            </Body>
            <Body size="sm" color={colors.onNeutral}>
              (Max file size: {maxFileSize} MB)
            </Body>
          </div>
          <Button isDisabled={isDisabled} variant="secondary" size="sm">
            <UploadFilled
              style={{
                marginRight: spacing.xs,
              }}
            />
            Upload
          </Button>
        </UploadInfo>
      </UploadBoxContainer>

      {downloadableLink && (
        <>
          <Divider />
          <div
            style={{
              display: 'flex',
              padding: spacing.md,
              gap: spacing.xs,
              alignItems: 'flex-start',
            }}
          >
            <Body size="md" color={colors.onNeutralSecondary} display="inline">
              <Label size="md" color={colors.onNeutralSecondary} display="inline">
                Note:{' '}
              </Label>
              Please use our template to fill in the correct format,{' '}
              <Label size="md" display="inline">
                <a
                  style={{ color: colors.active }}
                  href={downloadableLink}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  Download Template
                </a>
              </Label>
            </Body>
          </div>
        </>
      )}
      {uploadStatus !== UploadStatus.empty &&
        uploadBoxMessage(customErrorMessage).get(uploadStatus)}
      {uploadedAttachments.map((item: { getUrl: string; fileName: string }, index: number) => {
        const fileExtension = item.fileName.split('.').pop()

        return (
          <UploadedFileList key={item.getUrl} onClick={(e) => e.stopPropagation()}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {variant === 'csv' || fileExtension === 'pdf' ? (
                <IcDocumentSuccess
                  style={{ marginRight: spacing.xs }}
                  width={20}
                  height={20}
                  color={colors.primary}
                />
              ) : (
                <IcImageSuccess
                  style={{ marginRight: spacing.xs }}
                  width={20}
                  height={20}
                  color={colors.primary}
                />
              )}
              <a href={item.getUrl} target="_blank" rel="noreferrer">
                <Body color={colors.primary} size="md">
                  {item.fileName || `Attachment (${index + 1})`}
                </Body>
              </a>
            </div>
            {!isDisabled && (
              <CloseCircle
                onClick={(e) => {
                  e.stopPropagation()
                  const newAttachmentList = uploadedAttachments.filter(
                    (_, i: number) => i !== index
                  )
                  setUploadAttachments(newAttachmentList)
                  if (newAttachmentList.length === 0 && !disableStatusUpdate) {
                    setUploadStatus(UploadStatus.empty)
                  }

                  if (onRemove) {
                    onRemove({
                      fileList: newAttachmentList,
                      updateStatus: (status: UploadBoxStatus) => setUploadStatus(status),
                    })
                  }
                }}
                width={20}
                height={20}
                color={colors.critical}
              />
            )}
          </UploadedFileList>
        )
      })}
    </StyledUploadBox>
  )
}

export default UploadBox
