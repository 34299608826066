import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Spin, Typography, fdsTheme } from '@fazz/design-system'
import { post as postFromExpress } from '~/api/express.api'

const { spacing, colors } = fdsTheme
const { Body } = Typography

export default function BlankStepUpAuth() {
  const [verifying, setVerifying] = useState(true)

  function handleStepUpAuthCallback() {
    const code = window.location.hash.split('code=')[1]
    return postFromExpress('/step_up_code_to_token', {
      redirectUri: `${window.location.origin}/sso_step_up_authentication`,
      code,
    })
  }

  useEffect(() => {
    const verifyStepUpAuth = async () => {
      try {
        const response = await handleStepUpAuthCallback()
        const messagePayload = {
          TwoFaVerifiedAccessToken: response.data.accessToken,
        }
        window.parent.postMessage(JSON.stringify(messagePayload), '*')
      } catch (error) {
        window.parent.postMessage('2fa_verified_failed', '*')
      } finally {
        setVerifying(false)
      }
    }

    verifyStepUpAuth()
  }, [])

  return (
    <Container>
      <Container>
        {verifying ? (
          <>
            <Body>Verifying OTP...</Body>
            <Body>Please do not navigate away</Body>
          </>
        ) : (
          <Body>2FA verification completed, redirecting ...</Body>
        )}
        <Spin />
      </Container>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacing.md};
  position: absolute;
  background-color: ${colors.backgroundNeutral};
  top: 0;
  left: 0;
  z-index: 9999;
`
