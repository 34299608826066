import type { Reducer } from 'redux'
import type { PATHS } from '~/bizRegional/routes/paths'
import { BusinessRegistrationCountry } from '~/types'
import { VerificationStatus } from '~/constants/verificationStatus'
import type {
  ActionTypes,
  User,
  Account,
  Notification,
  FeatureMatrix,
  BusinessEntity,
  Employers,
} from './types'
import { RouteActions } from './types'

type State = {
  isInitiated: boolean
  isClevertapInitiated: boolean
  currentRedirect: PATHS | null
  user: User
  accounts: Account[]
  businessEntities: BusinessEntity[]
  employers: Employers[]
  notifications: {
    data: Notification[]
    unreadCount: number
    isLoading: boolean
  }
  featureMatrix: FeatureMatrix
  dashboardRevampEnabled: boolean
}

const initialState: State = {
  currentRedirect: null,
  isInitiated: false,
  isClevertapInitiated: false,
  user: {
    kcId: undefined,
    merchantId: '',
    fullName: '',
    email: '',
    countryCode: '',
    mobileNumber: '',
    companyId: '',
    userRole: undefined,
    displayname: '',
  },
  accounts: [],
  notifications: {
    data: [],
    unreadCount: 0,
    isLoading: false,
  },
  businessEntities: [],
  employers: [],
  featureMatrix: {
    userRole: null,
    country: BusinessRegistrationCountry.SINGAPORE,
    businessVerificationStatus: VerificationStatus.Initial,
    accountManagerVerificationStatus: VerificationStatus.Initial,
    isHighRiskMerchant: false,
  },
  dashboardRevampEnabled: false,
}

export const route: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case RouteActions.SET_REDIRECT: {
      return {
        ...state,
        currentRedirect: action.to,
      }
    }
    case RouteActions.SET_BUSINESS_ENTITIES: {
      return {
        ...state,
        businessEntities: action.businessEntities,
      }
    }
    case RouteActions.SET_EMPLOYERS: {
      return {
        ...state,
        employers: action.employers,
      }
    }
    case RouteActions.SET_USER_PROFILE: {
      return {
        ...state,
        user: {
          ...action.user,
        },
      }
    }
    case RouteActions.SET_TRANSACTION_ACCOUNTS: {
      return {
        ...state,
        accounts: action.accounts,
      }
    }
    case RouteActions.SET_NOTIFICATIONS: {
      const { data, unreadCount, isLoading } = action
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data,
          unreadCount,
          isLoading,
        },
      }
    }
    case RouteActions.TOGGLE_NOTIFICATIONS_LOADING: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: action.isLoading,
        },
      }
    }
    case RouteActions.SET_FEATURE_MATRIX: {
      return {
        ...state,
        featureMatrix: {
          ...action.featureMatrix,
        },
      }
    }
    case RouteActions.INITIATE_GLOBAL_STORE: {
      return {
        ...state,
        isInitiated: true,
      }
    }
    case RouteActions.INVALIDATE_GLOBAL_STORE: {
      return {
        ...state,
        isInitiated: false,
      }
    }
    case RouteActions.SET_DASHBOARD_REVAMP_ENABLED: {
      return {
        ...state,
        dashboardRevampEnabled: action.enabled,
      }
    }
    case RouteActions.SET_INITIATE_CLEVERTAP: {
      return {
        ...state,
        isClevertapInitiated: true,
      }
    }
    default:
      return state
  }
}
