/**
 * Example Usage:
 *  function Main() {
 *    const [doSomething, setDoSomething] = useState('')
 *
 *    const handleTimeUp = () => {
 *     // Handle the reminder times up (e.g., show a message, trigger an action).
 *      setDoSomething('2 mins has passed!')
 *    }
 *
 *    const { setReminder } = useReminder(() => handleTimeUp())
 *    useEffect(() => {
 *      const remindAt = new Date().getTime() + 2 * 60 * 1000 // 2 mins later
 *      setReminder(remindAt)
 *    }, [])
 *
 *    return (
 *      <div>{doSomething}</div>
 *    )
 *  }
 */
import { useEffect, useState, useRef } from 'react'

export default function useReminder<T extends () => void>(handleTimeUp: T) {
  const [reminderAt, setReminderAt] = useState<number | undefined>()
  const handleTimeUpRef = useRef<T>(handleTimeUp)
  const timerRef = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    handleTimeUpRef.current = handleTimeUp
  }, [handleTimeUp])

  useEffect(() => {
    if (reminderAt !== undefined) {
      timerRef.current = setTimeout(() => handleTimeUpRef.current(), reminderAt)
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [reminderAt])

  const setReminder = (reminderTs: number) => {
    const currentTs = new Date().getTime()
    if (reminderTs < currentTs) {
      throw Error('Reminder timing must be later than now!')
    }
    const timeDifference = reminderTs - currentTs
    setReminderAt(timeDifference)

    // Reset timer if there's an active timer
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
  }

  return {
    reminderAt,
    setReminder,
  }
}
