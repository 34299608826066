import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import type { ReactElement } from 'react'
import { useGetEmployees, useGetKeycloakProfile } from '~/api/initialisation/initialisation.hooks'
import type { Employee } from '~/api/initialisation/initialisation.types'
import type { AppType } from '~/types'
import { appTypeMap } from '~/constants'

const AppTypeContext = createContext<{
  appType: AppType | undefined
  appTypeFetched: boolean
  setAppType: (newAppType: AppType) => void
}>({
  appType: undefined,
  appTypeFetched: false,
  setAppType: () => { },
})

function AppTypeProvider({ children }: { children: ReactElement }) {
  const [appType, setAppType] = useState<AppType | undefined>()
  const [appTypeFetched, setAppTypeFetched] = useState(false)

  // Need to check for the current logined profiles='s bizId
  const { data: keycloakProfile, isFetched: isFetchedKeycloakProfile } = useGetKeycloakProfile()
  const bizId = keycloakProfile?.bizId

  // All the business profiles that the logined user has
  const { data: employeeProfilesData, isFetched: isFetchedEmployees } = useGetEmployees()
  const employeeProfiles = employeeProfilesData?.data.data

  useEffect(() => {
    if (bizId && employeeProfiles) {
      // Retrieve the currency logined business profile by matching bizId
      const selectedProfile = employeeProfiles.find(
        (entity: Employee) => entity.attributes.bizId === bizId
      )
      const registrationCountry = selectedProfile?.attributes.registrationCountry
      if (registrationCountry) {
        setAppType(appTypeMap[registrationCountry])
      }
    }
  }, [bizId, employeeProfiles])

  useEffect(() => {
    if (isFetchedKeycloakProfile && isFetchedEmployees) {
      // if registrationCountry is not setup yet, redirect user to selecting a country
      setAppTypeFetched(true)
    }
  }, [isFetchedKeycloakProfile, isFetchedEmployees])

  // Use useMemo to memoize the context value
  const contextValue = useMemo(
    () => ({ appType, appTypeFetched, setAppType }),
    [appType, appTypeFetched, setAppType]
  )

  return <AppTypeContext.Provider value={contextValue}>{children}</AppTypeContext.Provider>
}

const useAppTypeContext = () => {
  const context = useContext(AppTypeContext)
  if (context === undefined) {
    throw new Error('useAppTypeContext must be used within a AppTypeProvider')
  }
  return context
}

export { AppTypeProvider, useAppTypeContext }
