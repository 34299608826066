import * as React from 'react'

function SvgVecEmpty(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 130 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#vec_empty_svg__clip0)">
        <path
          opacity={0.8}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.003 15.228c1.326-4.192 2.56-6.802 3.7-7.83 3.157-2.849 7.522-1.299 8.45-1.077 3.26.782 2.2-4.364 4.997-5.41 1.864-.697 3.397.155 4.6 2.556 1.065-2.24 2.688-3.266 4.87-3.083 3.271.277 4.416 11.328 8.912 8.909 4.497-2.42 10.01-2.973 12.365.623.51.778.704-.429 4.166-4.55 3.462-4.122 6.914-5.938 13.996-3.538 3.22 1.09 5.868 4.045 7.947 8.864 0 6.878 5.06 10.95 15.178 12.213 15.179 1.895 3.397 18.214-15.178 22.993-18.576 4.78-61.343 7.36-84.55-4.716-15.473-8.049-11.957-16.7 10.546-25.954h.001z"
          fill="url(#vec_empty_svg__paint0_linear)"
        />
        <path
          d="M64.987 76.008c15.457 0 27.987-2.9 27.987-6.478s-12.53-6.478-27.987-6.478C49.53 63.052 37 65.952 37 69.53s12.53 6.478 27.987 6.478z"
          fill="url(#vec_empty_svg__paint1_linear)"
        />
        <path
          opacity={0.675}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.201 77.613c-21.043 5.278-92.87-.76-100.515-3.516C6.965 72.754 3.61 70.229.625 66.52a2.822 2.822 0 012.198-4.593h125.514c2.605 6.938-3.107 12.166-17.136 15.685z"
          fill="url(#vec_empty_svg__paint2_linear)"
        />
        <path
          d="M86.396 57.052L76.825 45.86a2.434 2.434 0 00-1.837-.86h-20.58c-.706 0-1.377.324-1.837.86L43 57.052v6.144h43.396v-6.144z"
          fill="url(#vec_empty_svg__paint3_linear)"
        />
        <path
          d="M83.683 63.422l-8.377-9.748a2.135 2.135 0 00-1.622-.725H55.71c-.617 0-1.22.256-1.622.725l-8.377 9.748v1.354h37.972v-1.354z"
          fill="url(#vec_empty_svg__paint4_linear)"
        />
        <path
          d="M86.396 70.237c0 .853-.384 1.62-.99 2.134l-.123.1c-.48.364-1.067.561-1.67.56H45.784c-.342 0-.669-.062-.971-.176l-.15-.06A2.801 2.801 0 0143 70.236V57.12h10.529c1.163 0 2.1.957 2.1 2.118v.015c0 1.162.948 2.1 2.111 2.1h13.916a2.113 2.113 0 002.111-2.108c0-1.166.938-2.125 2.1-2.125h10.53l-.001 13.118z"
          fill="url(#vec_empty_svg__paint5_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="vec_empty_svg__paint0_linear"
          x1={67.794}
          y1={37.313}
          x2={67.794}
          y2={-8.37}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DEDEDE" stopOpacity={0} />
          <stop offset={1} stopColor="#A9A9A9" stopOpacity={0.3} />
        </linearGradient>
        <linearGradient
          id="vec_empty_svg__paint1_linear"
          x1={62.071}
          y1={76.008}
          x2={62.071}
          y2={63.052}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#96A1C5" stopOpacity={0.373} />
        </linearGradient>
        <linearGradient
          id="vec_empty_svg__paint2_linear"
          x1={64.474}
          y1={79.706}
          x2={64.474}
          y2={58.43}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#919191" stopOpacity={0.15} />
        </linearGradient>
        <linearGradient
          id="vec_empty_svg__paint3_linear"
          x1={64.997}
          y1={50.435}
          x2={64.997}
          y2={63.196}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F8DEE" />
          <stop offset={1} stopColor="#5FA8F2" />
        </linearGradient>
        <linearGradient
          id="vec_empty_svg__paint4_linear"
          x1={64.959}
          y1={52.949}
          x2={64.959}
          y2={68.776}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7E9F9" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="vec_empty_svg__paint5_linear"
          x1={70.49}
          y1={73.031}
          x2={70.49}
          y2={56.273}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECF5FD" />
          <stop offset={1} stopColor="#2F8DEE" />
        </linearGradient>
        <clipPath id="vec_empty_svg__clip0">
          <path fill="#fff" d="M0 0h130v80H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgVecEmpty
