import { createSelector } from 'reselect'
import type { RootState } from '~/biz/store/types'
import { WALLET_TYPES } from '~/types'
import { ModeManager } from '~/managers/ModeManager'
import { VerificationStatus } from '~/constants/verificationStatus'
import { store } from '../store'
import type { Account, Wallet } from './types'

const selectAccounts = (accounts: Account[]) => accounts
const selectFeatureMatrix = (state: RootState) => state.route.featureMatrix
const selectSandbox = () => ModeManager.isSandboxMode()

export const getFeatureMatrixFactors = createSelector(
  [selectFeatureMatrix, selectSandbox],
  (featureMatrix, isSandbox) => {
    const bizVerificationStatus = featureMatrix.businessVerificationStatus
    const amVerificationStatus = featureMatrix.accountManagerVerificationStatus

    return {
      isSandbox,
      role: featureMatrix.userRole,
      isId: true,
      isSg: false,
      isBizVerified: bizVerificationStatus === VerificationStatus.Verified,
      isAccountManagerVerified: amVerificationStatus === VerificationStatus.Verified,
      hasFundPoolForIdDisbursement:
        featureMatrix.availableFundPools?.includes(1) ||
        featureMatrix.availableFundPools?.includes(3),
      hasFundPoolForIdWithdraw:
        featureMatrix.availableFundPools?.includes(2) ||
        featureMatrix.availableFundPools?.includes(1) ||
        featureMatrix.availableFundPools?.includes(3),
      isHighRiskMerchant: featureMatrix.isHighRiskMerchant,
    }
  }
)

export const getFeatureMatrixFactorsStatic = () => {
  const featureMatrixState = store.getState().route.featureMatrix

  const {
    accountManagerVerificationStatus,
    businessVerificationStatus,
    availableFundPools,
    isHighRiskMerchant,
    userRole,
  } = featureMatrixState

  return {
    isSandbox: ModeManager.isSandboxMode(),
    role: userRole,
    isId: true,
    isSg: false,
    isBizVerified: businessVerificationStatus === VerificationStatus.Verified,
    isAccountManagerVerified: accountManagerVerificationStatus === VerificationStatus.Verified,
    hasFundPoolForIdDisbursement:
      availableFundPools?.includes(1) || availableFundPools?.includes(3),
    hasFundPoolForIdWithdraw:
      availableFundPools?.includes(2) ||
      availableFundPools?.includes(1) ||
      availableFundPools?.includes(3),
    isHighRiskMerchant,
  }
}

export const selectUserWallets = createSelector([selectAccounts], (accounts) => {
  const wallets: Wallet[] = accounts.map((acc: any) => {
    let label: string

    if (acc.id === WALLET_TYPES.GENERAL) {
      label = `General Funds`
    } else {
      label = 'Account withdraw not supported yet, its probably a bug'
    }

    return {
      label,
      type: acc.id,
      balance: acc.balance,
      formattedBalance: acc.formattedBalance,
      accountId: acc.accountId,
      accountType: acc.type,
      fundPoolId: acc.fundPoolId,
      accountConfiguration: acc?.accountConfiguration,
    }
  })

  return wallets
})
