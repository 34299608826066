import { Typography } from 'fds3-src/index'
import { fdsTheme } from 'fds3-src/themes/theme-fds3'

const { Label } = Typography
const { colors, spacing } = fdsTheme

type FormikInputLabelProps = {
  label: string
}

/**
 * Reusable label component for Input field
 */
export default function FormikInputLabel({ label }: FormikInputLabelProps) {
  return (
    <Label
      size="md"
      color={colors.labelInput}
      style={{ display: 'flex', marginBottom: spacing.xxs }}
    >
      {label}
    </Label>
  )
}
