import * as React from 'react'

function SvgBulletPointArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.94 4.44a1.5 1.5 0 012.12 0l9.49 9.489.01.01a1.494 1.494 0 01.334 1.616 1.495 1.495 0 01-.333.506l-.01.01-9.49 9.49a1.5 1.5 0 01-2.122-2.122l6.94-6.939H3.5a1.5 1.5 0 010-3h19.379l-6.94-6.94a1.5 1.5 0 010-2.12z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgBulletPointArrow
