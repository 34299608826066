import * as React from 'react'

function SvgIcDocumentSuccess(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0247 1.37451L16.808 6.15784C17.1179 6.47033 17.2917 6.89272 17.2913 7.33284V14.1162C17.2913 15.4423 16.7646 16.714 15.8269 17.6517C14.8892 18.5894 13.6174 19.1162 12.2913 19.1162H7.70801C6.38193 19.1162 5.11016 18.5894 4.17247 17.6517C3.23479 16.714 2.70801 15.4423 2.70801 14.1162V5.88284C2.70801 4.55676 3.23479 3.28499 4.17247 2.34731C5.11016 1.40962 6.38193 0.88284 7.70801 0.88284H10.833C11.0543 0.881562 11.2737 0.92438 11.4783 1.0088C11.6829 1.09321 11.8686 1.21753 12.0247 1.37451ZM14.7247 6.55784H15.433L11.808 2.93284V3.64117C11.808 4.41472 12.1153 5.15659 12.6623 5.70357C13.2093 6.25055 13.9511 6.55784 14.7247 6.55784ZM7.70801 17.8662H12.2913C13.2859 17.8662 14.2397 17.4711 14.943 16.7678C15.6463 16.0646 16.0413 15.1107 16.0413 14.1162V7.80784H14.7247C13.6196 7.80784 12.5598 7.36885 11.7784 6.58745C10.997 5.80605 10.558 4.74624 10.558 3.64117V2.13284H7.70801C6.71345 2.13284 5.75962 2.52793 5.05636 3.23119C4.3531 3.93445 3.95801 4.88828 3.95801 5.88284V14.1162C3.95801 15.1107 4.3531 16.0646 5.05636 16.7678C5.75962 17.4711 6.71345 17.8662 7.70801 17.8662ZM9.04961 12.5413C9.16865 12.6557 9.32628 12.7211 9.49128 12.7247C9.65917 12.7232 9.82014 12.6576 9.94128 12.5413L12.5496 9.92468C12.6086 9.86722 12.6554 9.79854 12.6874 9.72269C12.7194 9.64683 12.7359 9.56534 12.7359 9.48301C12.7359 9.40068 12.7194 9.31919 12.6874 9.24333C12.6554 9.16748 12.6086 9.0988 12.5496 9.04134C12.4324 8.9243 12.2736 8.85856 12.1079 8.85856C11.9423 8.85856 11.7835 8.9243 11.6663 9.04134L9.49128 11.2163L8.38294 10.0997C8.26447 9.98928 8.10776 9.92918 7.94584 9.93203C7.78393 9.93489 7.62944 10.0005 7.51493 10.115C7.40042 10.2295 7.33482 10.384 7.33197 10.5459C7.32911 10.7078 7.38921 10.8645 7.49961 10.983L9.04961 12.5413Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgIcDocumentSuccess
