import * as React from 'react'

function SvgMandiri(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.062 21.027c0-.869-.015-1.597-.062-2.248h1.642l.078 1.117h.045c.372-.59 1.053-1.288 2.323-1.288.992 0 1.765.56 2.09 1.396h.032c.264-.418.573-.73.929-.945.418-.296.9-.451 1.518-.451 1.255 0 2.524.853 2.524 3.273v4.45H9.322v-4.172c0-1.255-.433-2.001-1.347-2.001-.65 0-1.13.467-1.332 1.01-.055.207-.087.42-.093.634v4.53H4.692v-4.375c0-1.053-.418-1.798-1.301-1.798-.71 0-1.19.559-1.363 1.085a1.657 1.657 0 00-.108.62v4.468H.062v-5.305zm18.359 3.49c0 .682.031 1.349.108 1.815h-1.72l-.123-.837h-.047c-.464.59-1.255 1.006-2.228 1.006-1.518 0-2.37-1.1-2.37-2.248 0-1.909 1.686-2.869 4.476-2.854v-.123c0-.497-.202-1.319-1.535-1.319-.744 0-1.517.233-2.028.558l-.372-1.24c.558-.343 1.534-.668 2.725-.668 2.417 0 3.115 1.537 3.115 3.18l-.001 2.73zm-1.859-1.875c-1.348-.032-2.633.263-2.633 1.41 0 .745.482 1.087 1.085 1.087.758 0 1.316-.498 1.487-1.04.041-.136.062-.277.062-.419v-1.038zm3.175-1.615c0-.869-.016-1.597-.061-2.248h1.67l.093 1.134h.047c.325-.59 1.146-1.305 2.4-1.305 1.317 0 2.679.853 2.679 3.242v4.481H24.66v-4.264c0-1.085-.403-1.91-1.44-1.91-.758 0-1.286.544-1.487 1.118-.062.17-.077.404-.077.619v4.436h-1.92v-5.303zm15.087-5.282v8.462c0 .776.032 1.614.062 2.125h-1.703l-.077-1.194h-.032c-.448.837-1.363 1.364-2.462 1.364-1.797 0-3.22-1.535-3.22-3.86-.017-2.528 1.562-4.033 3.375-4.033 1.038 0 1.78.434 2.121.995h.033v-3.858h1.904-.001zm-1.904 6.182c0-.154-.015-.34-.047-.496-.17-.743-.773-1.349-1.64-1.349-1.224 0-1.907 1.086-1.907 2.497 0 1.379.682 2.388 1.891 2.388.773 0 1.455-.529 1.64-1.35.047-.17.064-.357.064-.558v-1.132zm3.251 4.405v-7.553h1.922v7.553H36.17zm3.268-5.117c0-1.024-.015-1.754-.06-2.435h1.656l.06 1.44h.063c.372-1.069 1.254-1.44 2.061-1.44.186 0 .293-.032.448 0v1.676a2.624 2.624 0 00-.557-.063c-.915 0-1.534.59-1.704 1.441a3.43 3.43 0 00-.061.592v3.906h-1.906v-5.117zm5.112 5.117v-7.553h1.919v7.553h-1.92z"
        fill="#013066"
      />
      <path
        d="M58.423 10.791c-.95-1.086-1.962-.594-2.765-.195-.338.168-2.776 1.552-2.776 1.552-1.337.552-2.377-.05-2.785-.557a1.211 1.211 0 01-.106-.153l-.095-.165a7.417 7.417 0 00-.535-.802 4.504 4.504 0 00-.385-.44c-.914-.924-2.375-1.571-4.817-.396-1.157.671-4.924 2.826-4.924 2.826-1.415.584-2.498-.127-2.85-.648a.38.38 0 01-.04-.063l-.047-.079-.238-.39a6.57 6.57 0 00-.347-.498c-.12-.154-.248-.3-.385-.44-.908-.918-2.362-1.564-4.783-.41l-4.868 2.785-.002.002-2.491 1.425 1.289 1.586c.543.518 1.562 1.367 3.14.651 0 0 4.354-2.525 4.372-2.533 3.722-2.046 5.56-.09 6.371 1.212.029.04.051.085.077.124l.001.002.026.046.091.15c.396.601 1.02.99 1.764.971.41-.008.689-.142 1.095-.368l4.212-2.447.01-.004c3.769-2.07 5.637.012 6.435 1.315.062.112.132.22.204.32.395.537.94.888 1.632.87.458-.008.97-.283 1.046-.333L60 12.787c.002.002-.514-.78-1.577-1.996z"
        fill="url(#Mandiri_svg__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="Mandiri_svg__paint0_linear"
          x1={23.252}
          y1={9}
          x2={59.967}
          y2={9}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAB10B" />
          <stop offset={0.255} stopColor="#FBC607" />
          <stop offset={0.482} stopColor="#F89F0E" />
          <stop offset={0.645} stopColor="#FBC607" />
          <stop offset={0.807} stopColor="#F89F0E" />
          <stop offset={1} stopColor="#FBC707" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgMandiri
