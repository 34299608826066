export enum PATHS {
  ACCOUNT_BALANCE = '/account_balance',
  TRANSACTIONS = '/transactions',
  DISBURSEMENTS = '/disbursements',
  DISBURSEMENTS_RECIPIENTS_LIST = '/disbursements/recipients_list',
  DISBURSEMENTS_PAYMENT_METHOD = '/disbursements/payment_method',
  DISBURSEMENTS_COMPLETE = '/disbursements/complete',
  TRANSACTION_DETAILS = '/account_statement/:transactionId',
  DEPOSIT = '/deposit',
  TRANSFER_IN = '/transfer_in',
  DEPOSIT_REQUESTS = '/deposit/requests',
  SIMULATE_DEPOSIT = '/simulate_deposit',
  WITHDRAW = '/withdraw',
  TRANSFER_OUT = '/transfer_out',
  MANAGE_ACCOUNT = '/manage_account',
  INVOICING = '/payment_link',
  INVOICING_DETAILS = '/payment_link/:uniqueId',
  CUSTOMER_CARDS = '/customer-card/:tab?',
  CUSTOMER_CARDS_TRANSACTION_DETAILS = '/customer-card/transaction/details/:cbsTransactionId',
  CUSTOMER_CARDS_CARD_DETAILS = '/customer-card/detail/users/:userOpaqueId/card/:cardOpaqueId/card-number/:cardNumberOpaqueId',
  CUSTOMER_CARDS_FRAUD_DETAILS = '/customer-card/fraud/details/:fraudEventOpaqueId/users/:userOpaqueId/card/:cardOpaqueId',
  CUSTOMER_CARDS_CUSTOMERS_DETAILS = '/customer-card/customers/details/user/:userOpaqueId',
  DEBIT_CARDS = '/debit_cards',
  DEBIT_CARDS_ALL = '/debit_cards/all',
  DEBIT_CARDS_CREATE_CARD = '/debit_cards/all/create_card',
  DEBIT_CARDS_MYCARD = '/debit_cards/mycard',
  DEBIT_CARDS_DETAIL = '/debit_cards/details/:cardId/:name/:cardLastFourDigits',
  DEBIT_CARDS_MYCARD_TRANSACTION_DETAILS = '/debit_cards/mycard/transaction/:transactionId',
  VERIFICATION = '/verification',
  CLAIM_KYC = '/claim_kyc',
  VERIFICATION_MY_INFO_CALLBACK = '/callback/:myInfoParams',
  INTERNAL_TOOLS = '/internal_tools',
  BIZ_KYC_INFORMATION = '/verification/business/information',
  BIZ_KYC_INTENDED_RELATIONS = '/verification/business/intended_relations',
  BIZ_KYC_BUSINESS_ACTIVITIES = '/verification/business/business_activities',
  BIZ_KYC_UPLOAD_DOCUMENTS = '/verification/business/upload_documents',
  BIZ_KYC_ACCOUNT_MANAGER = '/verification/business/account_manager',
  BIZ_KYC_DIRECTORS_AND_BO = '/verification/business/directors_and_bo',
  BIZ_KYC_REVIEW = '/verification/business/review',
  BIZ_KYC_COMPLETE = '/verification/business/complete',
  DEVELOPER_TOOLS = '/developer_tools',
  TEAM = '/team',
  DASHBOARD = '/dashboard',
  EARN = '/earn',
  EARN_DEPOSIT = '/earn/deposit',
  EARN_DEPOSIT_DETAILS = '/earn/deposit/:orderId',
  EARN_LIST_DETAILS = '/earn/:uniqueId',
  ERROR = '/error/:code',
  ERROR_403 = '/error/403',
  ACCOUNT_STATEMENT = '/account_statement',
  XFERS_ACCEPT = '/xfers_accept',
  XFERS_ACCEPT_DETAILS = '/xfers_accept/:transactionId',
  XFERS_SEND = '/xfers_send',
  XFERS_SEND_DETAILS = '/xfers_send/:transactionId',
  XFERS_DASH = '/xfers_dash',
  XFERS_DASH_DETAILS = '/xfers_dash/:transactionId',
  LANDING_PAGE = '/',
  GET_STARTED = '/get_started',
  APPLY_LOAN = '/loan/apply',
  LOAN_ACCOUNT_APPLICATION_HOME = '/loan/application/',
  LOAN_ACCOUNT_APPLICATION_FORM = '/loan/application/form',
  LOAN_ACCOUNT_APPLICATION = '/loan/loan_account/application',
  LOAN_ACCOUNT_REPAYMENT = '/loan/repayment',
  LOAN_ACCOUNT_REPAYMENT_INFO = '/loan/repayment/:id',
  LOAN_ACCOUNT_REPAYMENT_INSTRUCTION = '/loan/repayment/:id/:method',
  LOAN_ACCOUNT = '/loan/loan_account/:tab?',
  LOAN_ACCOUNT_DETAILS = '/loan/loan_account/:tab/:id',
  LOAN_USAGE = '/loan/loan_usage/:tab?',
  LOAN_USAGE_DETAILS = '/loan/loan_usage/:tab/:id',
  LOAN_ACCOUNT_NEW_NO_TAB = '/loan/loan_account_new',
  LOAN_ACCOUNT_NEW = '/loan/loan_account_new/:tab?',
  LOAN_ACCOUNT_DETAILS_NEW = '/loan/loan_account_new/:tab/:id',
  LOAN_ACCOUNT_STATUS_DETAILS = '/loan/loan_account/status',
  FLEX_PAY_ACCOUNT = '/loan/flex_pay_account',
  FLEX_PAY_ACCOUNT_REPAYMENT = '/loan/flex_pay_account/repayment',
  FLEX_PAY_ACCOUNT_DETAILS = '/loan/flex_pay_account/:id',
  PAY_TRANSACTIONS = '/pay/transactions/:tab?',
  PAY_TRANSFER = '/pay/disbursement',
  PAY_TRANSACTION_DETAILS = '/pay/transactions/:tab/:id',
  IDR_TRANSACTION_DETAILS = '/pay/idr/:id',
  PAY_RECIPIENTS = '/pay/recipients',
  // not real path intended for route, just for menu with dropdown, fake def as needed in current config usage
  MENU_PAYMENTS_API = 'menu/payments_api',
  REGISTER_MERCHANT = '/register_merchant',
  ACCOUNT_SELECTOR = '/account_select',

  // LOAN VERIFiCATION
  LOAN_VERIFICATION = '/loan_verification',
  FLEXPAY_DASHBOARD = '/flexpay-dashboard',
  BLANK = '/blank',
  KYB_SOLE_PROP = '/verification/business/sole-prop',
  LOAN_ACCOUNT_WALLET_BALANCE_REDIRECT = '/loan/loan_account_new/pending_approval',
}
