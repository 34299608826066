import { SVGProps } from 'react'

function SvgInfo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 1.875A7.125 7.125 0 1 1 1.875 9 7.132 7.132 0 0 1 9 1.875ZM9 .75a8.25 8.25 0 1 0 0 16.5A8.25 8.25 0 0 0 9 .75Z"
        fill="currentColor"
      />
      <path
        d="M9 12.428a.57.57 0 0 1-.563-.563v-3.45a.562.562 0 1 1 1.126 0v3.45a.57.57 0 0 1-.563.563ZM9 6.72a.57.57 0 1 0 0-1.14.57.57 0 0 0 0 1.14Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgInfo
