/* eslint-disable max-lines */
import type { Reducer } from 'redux'
import type { ActionTypes, WithdrawWalletIdType, BankAccount } from './types'
import { WithdrawActions, WithdrawSteps } from './types'

const initialState = {
  isId: true,
  withdrawWalletId: undefined,
  withdrawAccountId: undefined,
  withdrawWalletName: undefined,
  withdrawToBankId: undefined,
  withdrawAmount: '',
  withdrawBankAccount: '',
  isConfirmOpen: false,
  has2FaEnabled: false,
  withdrawStep: WithdrawSteps.DETAILS,
  is2FaOpen: false,
  isLoading: true,
  bankAccounts: [],
  withdrawAmountError: '',
  withdrawRequestError: '',
  fullyVerified: undefined,
  idMinWithdrawal: '',
  idMaxWithdrawal: '',
  externalId: '',
}

type State = {
  isId: boolean
  withdrawWalletId: WithdrawWalletIdType
  withdrawAccountId: string | undefined
  withdrawWalletName: string | number | undefined
  withdrawToBankId: number | undefined
  withdrawAmount: string
  withdrawBankAccount: string
  isConfirmOpen: boolean
  withdrawStep: WithdrawSteps
  is2FaOpen: boolean
  has2FaEnabled: boolean
  isLoading: boolean
  bankAccounts: BankAccount[]
  withdrawAmountError: string
  withdrawRequestError: string
  fullyVerified: boolean | undefined
  idMinWithdrawal: string
  idMaxWithdrawal: string
  externalId: string | undefined
}

export const withdraw: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case WithdrawActions.SET_WITHDRAW_WALLET: {
      return {
        ...state,
        withdrawWalletId: action.walletId,
        withdrawAccountId: action.accountId,
        withdrawWalletName: action.walletName,
      }
    }

    case WithdrawActions.SET_WITHDRAW_AMOUNT: {
      return {
        ...state,
        withdrawAmount: action.amount,
        withdrawAmountError: action.withdrawAmountError,
      }
    }

    case WithdrawActions.SET_WITHDRAW_TO_BANK_ID: {
      return {
        ...state,
        withdrawToBankId: action.bankId,
      }
    }

    case WithdrawActions.SET_CONFIRM_MODAL_OPEN: {
      return {
        ...state,
        isConfirmOpen: action.open,
      }
    }

    case WithdrawActions.SET_WITHDRAW_STEP: {
      return {
        ...state,
        withdrawStep: action.step,
      }
    }

    case WithdrawActions.SET_2_FA_MODAL_OPEN: {
      return {
        ...state,
        is2FaOpen: action.open,
      }
    }

    case WithdrawActions.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }

    case WithdrawActions.GET_ACCOUNT_LIMITS: {
      return {
        ...state,
        accountLimits: action.accountLimits,
        isLoading: false,
      }
    }

    case WithdrawActions.GET_USER_DETAILS: {
      return {
        ...state,
        bankAccounts: action.bankAccounts,
        has2FaEnabled: action.has2FaEnabled,
        fullyVerified: action.fullyVerified,
        isLoading: false,
      }
    }

    case WithdrawActions.CREATE_WITHDRAWAL_REQUEST: {
      return {
        ...state,
        withdrawRequestError: action.error,
        withdrawBankAccount: action.withdrawBankAccount,
        isLoading: false,
      }
    }

    case WithdrawActions.GET_ID_WITHDRAWAL_LIMITS: {
      return {
        ...state,
        idMinWithdrawal: action.idMinWithdrawal,
        idMaxWithdrawal: action.idMaxWithdrawal,
        isLoading: false,
      }
    }

    case WithdrawActions.SET_EXTERNAL_ID: {
      return {
        ...state,
        externalId: action.externalId,
      }
    }

    default:
      return state
  }
}
