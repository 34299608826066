import { StyledFormikCheckbox, StyledFormItem } from './FormikCheckbox.styles'
import { FormikCheckboxProps } from './FormikCheckbox.types'

export function FormikCheckbox({
  style,
  className,
  children,
  onChange,
  name,
  isDisabled,
  labelPlacement = 'right',
}: FormikCheckboxProps) {
  return (
    <StyledFormItem name={name} labelPlacement={labelPlacement}>
      <StyledFormikCheckbox
        name={name}
        className={className}
        style={style}
        onChange={onChange}
        disabled={isDisabled}
      />
      <span className={`${isDisabled ? 'label-container-disabled' : 'label-container-active'}`}>
        {children}
      </span>
    </StyledFormItem>
  )
}

export default FormikCheckbox
