import { store as bizStore } from '~/biz/store/store'

type Props = {
  balance: string | number | null | undefined
  withCurrency?: boolean
  currencySign?: string
}

function toLocaleBalance({ balance, withCurrency = true, currencySign }: Props) {
  if (!(typeof balance === 'string' || typeof balance === 'number')) {
    return ''
  }

  const balanceNumber = typeof balance === 'string' ? parseFloat(balance) : balance
  // TODO: @henghong can help to move this maxSupportedAmountDash() logic out
  // as this will affect wallet balances that are above maxSupportedAmountDash
  // if (balanceNumber > maxSupportedAmountDash())
  //   throw new Error('Number is too large and not supported')

  let result

  if (withCurrency === false) {
    result = balanceNumber.toLocaleString(localeString(), localeStringOptions())
  } else {
    result =
      (currencySign || localeCurrency()) +
      balanceNumber.toLocaleString(localeString(), localeStringOptions())
  }
  
  return result
}

function localeString() {
  return 'id-ID'
}

function maxSupportedAmountDash() {
  return bizStore.getState().landingPage.disbursementLimitAmount
}

function localeCurrency() {
  return 'Rp'
}

function localeStringOptions() {
  return {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
}

export {
  toLocaleBalance,
  localeString,
  maxSupportedAmountDash,
  localeCurrency,
  localeStringOptions,
}
