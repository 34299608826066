import { SVGProps } from 'react'

function SvgCrossMini(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m9.795 9 3.127-3.128a.563.563 0 1 0-.795-.795L9 8.205 5.872 5.077a.563.563 0 0 0-.795.795L8.205 9l-3.128 3.127a.563.563 0 1 0 .795.795L9 9.796l3.127 3.127a.563.563 0 1 0 .795-.795L9.796 9Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCrossMini
