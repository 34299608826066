import type { Reducer } from 'redux'
import type { Wallet, ActionTypes } from './types'
import { DepositActions } from './types'

const initialState = {
  isLoading: false,
  wallets: [],
}

type State = {
  isLoading: boolean
  wallets: Wallet[]
}

export const simulateDeposit: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case DepositActions.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }

    case DepositActions.SET_WALLETS: {
      return {
        ...state,
        wallets: action.wallets,
        isLoading: false,
      }
    }

    default:
      return state
  }
}
