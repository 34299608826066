import { css } from 'emotion'
import type { Moment } from 'moment'
import type { RangePickerProps } from 'antd/es/date-picker'
import DatePicker from 'antd/es/date-picker'
import 'antd/es/date-picker/style/css'
import { fdsTheme } from '../../../themes/theme-fds3'

const { colors, radius, fontStyles } = fdsTheme

type Props = Pick<RangePickerProps, 'placeholder' | 'allowClear' | 'disabled'> & {
  format?: string
  style?: Record<string, unknown>
  inputReadOnly?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ranges?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disabledDate?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: [Moment | string, Moment | string]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  picker?: any
  allowEmpty?: Array<boolean>
  onCalendarChange?: (dates: Moment[]) => void
}

export default function CustomRangePicker({ format = 'DD/MM/YYYY', ...rest }: Props) {
  const style = `
    -webkit-font-smoothing: antialiased;
    height: 36px;

    &.ant-picker {
      height: 56px;
      border-radius: ${radius.lg};
      border-color:${colors.line};

      &:hover {
        border-color: ${colors.primary};
      }

      &.ant-picker-focused {
        border-color: ${colors.primary};
      }
    }


    &.ant-picker > .ant-picker-input {
      ${fontStyles.bodyLarge}
      color: ${colors.onNeutral};
    }

    & .ant-picker-input > input {
      ${fontStyles.bodyLarge}
      color: ${colors.onNeutral};
    }

    & .ant-picker-input > input::placeholder {
      ${fontStyles.bodyLarge}
      color: ${colors.placeholderInput};
    }
  `

  return <DatePicker.RangePicker className={`${css(style)}`} format={format} {...rest} />
}
