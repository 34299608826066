import Button, { ButtonProps } from 'antd/es/button'
import 'antd/es/button/style/css'
import styled from '@emotion/styled'
import {
  baseStyle,
  newButtonStyle,
  newSmallButtonStyle,
} from '../../../themes/deprecated/fontStyles'
import { useTheme } from '../../../themes/deprecated/themeContext'

export type CustomButtonProps = {
  type?: 'primary' | 'secondary' | 'text' | 'plain'
  size?: 'large' | 'small'
  iconColor?: 'primary'
  isDisabled?: boolean
  children: React.ReactNode
  className?: string
} & Omit<ButtonProps, 'type' | 'disabled'>

/**
 * @deprecated - Use the new FDS3 version: Button.
 */
export default function CustomButton({
  type = 'primary',
  size,
  iconColor,
  isDisabled = false,
  className = '',
  children,
  ...rest
}: CustomButtonProps) {
  const { base, interactive, brand, button, actionButton, spacing } = useTheme()

  let newButtonPadding
  if (type === 'plain') {
    newButtonPadding = 0
  } else {
    newButtonPadding = `0 ${spacing.sm}`
  }

  const newPrimaryStyle = `
    background-color: ${base.primary};
    color: ${brand.primary.contrast};
    border: none;

    &:focus {
      background-color: ${interactive.active};
      color: ${brand.primary.contrast};
      border: none;
    }
    
    &:active {
      background-color: ${interactive.active};
      color: ${brand.primary.contrast};
      border: none;
    }
    &:hover {
      opacity: ${brand.action.hoverOpacity};
      color: ${brand.primary.contrast};
      border: none;
    }
  `

  const newSecondaryStyle = `
    background-color: ${button.background};
    color: ${base.primary};
    border: none;

    &:focus {
      background-color: ${button.background};
      color: ${base.primary};
      border: none;
    }

    &:hover {
      opacity: ${brand.action.hoverOpacity};
      background-color: ${button.background};
      color: ${base.primary};
      border: none;
    }

    &:active {
      background-color: ${button.background};
      color: ${base.primary};
      border: none;
    }
  `

  const newDisabledStyle = `
    cursor: auto;
    pointer-events: none;
    background: ${type === 'plain' || type === 'text' ? 'transparent' : button.disabled};
    color: ${
      type === 'plain' || type === 'text' ? button.disabled : brand.primary.contrast
    } !important;
    opacity: ${type === 'plain' || type === 'text' ? 1 : brand.action.disabledOpacity};
  `

  const newDefaultIconStyle = `
    color: ${actionButton.color};

    &:focus {
      color: ${actionButton.color};
    }

    &:hover {
      opacity: ${brand.action.hoverOpacity};
    }

    &:active {
      color: ${actionButton.color};
    }
  `

  const newPrimaryIconStyle = `
    color: ${base.primary};

    &:focus {
      color: ${interactive.active};
    }

    &:hover {
      opacity: ${brand.action.hoverOpacity};
    }

    &:active {
      color: ${interactive.active};
    }
  `

  const plainStyle = `
    background-color: transparent;
    color: ${base.primary};
    border: none;

    &:focus {
      background-color: transparent;
      color: ${base.primary};
      border: none;
    }

    &:hover {
      background-color: transparent;
      color: ${base.primary};
      opacity: ${brand.action.hoverOpacity}
      border: none;
    }

    &:active {
      background-color: transparent;
      color: ${interactive.active};
      border: none;
    }
  `

  const StyledButton = styled(Button)`
    &.ant-btn {
      text-shadow: none;
      -webkit-font-smoothing: antialiased;
      border-radius: 16px;
      box-shadow: none;
      line-height: '20px';

      ${baseStyle};
      ${size === 'small' ? newSmallButtonStyle : newButtonStyle};
      ${type === 'primary' && newPrimaryStyle}
      ${type === 'secondary' && newSecondaryStyle}
      ${type === 'plain' || type === 'text' ? plainStyle : ''}
      ${isDisabled === true && newDisabledStyle}
      padding: ${newButtonPadding}

      & > svg {
        vertical-align: middle;
        font-size: 14px;
        margin-right: ${spacing.sm} !important;
        ${iconColor === 'primary' ? newPrimaryIconStyle : newDefaultIconStyle}
      }

      & > span {
        font-size: 14px;
      }
    }
  `

  return (
    <StyledButton className={className} {...rest}>
      {children}
    </StyledButton>
  )
}
