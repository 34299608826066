export type InvoicingDetails = {
  accountHolderName: string
  walletName: string
  bankAccountNumber: string
  remarks: string
  uniqueId: string
  status: string
  fee: number | string
  amount: string
  netAmount: string
  customerName: string
  customerEmail: string
  customerPhoneNumber: string
  createdDate: string
  dueDate: string
  paidDate?: string
  settledDate?: string
  expiredDate?: string
  customerReference: string
  description: string
  currency: string
  paymentMethod?: string
  invoiceURL?: string
  endToEnd?: string
  messageId?: string
  receivingBankBic?: string
  creditorAccountNumber?: string
  selectedPaymentDetails: {
    method: string
    receiver: {
      bankShortCode: string
      accountHolderName: string
      accountNo: string
    }
    sender: {
      bankShortCode: string
      accountHolderName: string
      accountNo: string
    }
  }
}

export enum InvoicingDetailsActions {
  SET_IS_LOADING = 'InvoicingDetailsActions.SET_IS_LOADING',
  GET_INVOICING_DETAILS = 'InvoicingDetailsActions.GET_INVOICING_DETAILS',
}

type SetIsLoading = {
  type: typeof InvoicingDetailsActions.SET_IS_LOADING
  isLoading: boolean
}

type GetInvoicingDetails = {
  type: typeof InvoicingDetailsActions.GET_INVOICING_DETAILS
  invoicingDetails: InvoicingDetails
}

export type ActionTypes = SetIsLoading | GetInvoicingDetails
