import * as React from 'react'

function SvgArrowDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <g clipPath="url(#arrow-down_svg__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.44 9.44a1.5 1.5 0 012.12 0L15 18.878l9.44-9.44a1.5 1.5 0 012.12 2.122l-9.792 9.793a2.5 2.5 0 01-3.536 0L3.44 11.56a1.5 1.5 0 010-2.122z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="arrow-down_svg__clip0">
          <path fill="#fff" d="M0 0h30v30H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgArrowDown
