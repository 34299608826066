import { Label } from 'fds3-src/themes/Typography'
import { fdsTheme } from 'fds3-src/themes/theme-fds3'
import {
  StyledAddonButton,
  StyledAddonSelect,
  StyledAddonText,
  StyledFormItem,
  StyledInput,
} from './FormikInput.styles'
import type {
  FormikInputAddonButtonProps,
  FormikInputAddonSelectProps,
  FormikInputAddonTextProps,
  FormikInputProps,
} from './FormikInput.types'

const { colors, spacing } = fdsTheme

// TODO: add clear button to clear input
// TODO: update label to be into FormikInput
export function FormikInput({
  inputClassName,
  name,
  isDisabled = false,
  type = 'text',
  placeholder,
  label,
  helperText,
  inputStyles,
  addonBefore,
  addonAfter,
  onPressEnter,
  onChange,
  onBlur,
  onFocus,
  onKeyPress,
  autofocus = false,
  numberOnly = false,
}: FormikInputProps) {
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (numberOnly) {
      const { key } = e
      if (!/^[0-9]$/.test(key)) {
        e.preventDefault()
      }
    } else if (onKeyPress) {
      onKeyPress(e)
    }
  }

  return (
    <StyledFormItem name={name} extra={helperText}>
      {label && (
        <Label
          size="md"
          color={colors.labelInput}
          style={{ display: 'flex', marginBottom: spacing.xxs }}
        >
          {label}
        </Label>
      )}
      <StyledInput
        className={inputClassName}
        name={name}
        placeholder={placeholder}
        type={type}
        addonBefore={addonBefore}
        addonAfter={addonAfter}
        onPressEnter={onPressEnter}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={isDisabled}
        style={inputStyles}
        autoFocus={autofocus}
        onKeyPress={handleKeyPress}
      />
    </StyledFormItem>
  )
}

export function FormikAddonSelect({
  name,
  isAfter = false,
  children,
}: FormikInputAddonSelectProps) {
  return (
    <StyledAddonSelect name={name} isAfter={isAfter}>
      {children}
    </StyledAddonSelect>
  )
}

export function FormikAddonButton({
  onClick,
  isAfter = false,
  children,
}: FormikInputAddonButtonProps) {
  return (
    <StyledAddonButton onClick={onClick} isAfter={isAfter}>
      {children}
    </StyledAddonButton>
  )
}

export function FormikAddonText({ isAfter = false, color, children }: FormikInputAddonTextProps) {
  return (
    <StyledAddonText color={color} isAfter={isAfter}>
      {children}
    </StyledAddonText>
  )
}
