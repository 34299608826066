import * as React from 'react'
import { SVGProps } from 'react'

function SvgSpend(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.335 2.29a10.21 10.21 0 1 0 10.21 10.21 10.22 10.22 0 0 0-10.21-10.21Zm0 18.92a8.72 8.72 0 0 1-8.67-8h1.1a.75.75 0 0 0 0-1.5h-1.1a8.72 8.72 0 0 1 2-4.85l.78.78a.77.77 0 0 0 .53.22.79.79 0 0 0 .53-.22.75.75 0 0 0 0-1.06l-.77-.74a8.72 8.72 0 0 1 4.85-2v1.1a.75.75 0 1 0 1.5 0V3.83a8.72 8.72 0 0 1 4.85 2l-.78.78a.75.75 0 0 0 0 1.06.79.79 0 0 0 .53.22.77.77 0 0 0 .53-.22l.78-.78a8.72 8.72 0 0 1 2 4.85h-1.1a.75.75 0 1 0 0 1.5h1.1a8.72 8.72 0 0 1-8.66 7.97Z"
        fill="currentColor"
      />
      <path
        d="M16.334 8.62a.76.76 0 0 0-1.06 0l-1.6 1.47a2.79 2.79 0 1 0 1.46 2.41 2.69 2.69 0 0 0-.4-1.4l1.53-1.42a.75.75 0 0 0 .07-1.06Zm-4 5.18a1.3 1.3 0 1 1 1.3-1.3 1.31 1.31 0 0 1-1.3 1.3Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSpend
