import type { Reducer } from 'redux'
import type { EarnTransaction, ActionTypes, FilterValues, FilterOptions } from './types'
import { EarnTransactionsActions } from './types'

const initialState = {
  transactions: [],
  isTransactionLoading: false,
  totalCount: 0,
  filterValues: {
    startDate: '',
    endDate: '',
    currentPage: 1,
    pageLimit: 10,
    searchString: '',
    transactionTypes: [],
    transactionStatuses: [],
    sortType: '',
  },
  filterOptions: null,
}

type State = {
  transactions: EarnTransaction[]
  isTransactionLoading: boolean
  totalCount: number
  filterValues: FilterValues
  filterOptions: null | FilterOptions
}

export const earnTransaction: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case EarnTransactionsActions.GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.transactions,
        totalCount: action.totalCount,
      }

    case EarnTransactionsActions.SET_IS_TRANSACTION_LOADING:
      return {
        ...state,
        isTransactionLoading: action.isTransactionLoading,
      }

    case EarnTransactionsActions.SET_FILTERS: {
      const { filterValuesObject } = action

      const newTabFilterValues: FilterValues = {
        ...state.filterValues,
      }

      Object.keys(filterValuesObject).forEach((key: string) => {
        newTabFilterValues[key] = filterValuesObject[key]
      })

      return {
        ...state,
        filterValues: {
          ...newTabFilterValues,
        },
      }
    }

    case EarnTransactionsActions.GET_TRANSACTION_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: action.filterOptions,
      }

    default:
      return state
  }
}
