import type { Dispatch } from '~/biz/store/types'
import { ENDPOINTS, getApiMethods } from '~/api'
import type { AppType } from '~/types'
import { generateFlipperURLparams, generateEnvFlipperID } from './helpers'
import { GetFlipperActions } from './types'
import type { ActionTypes } from './types'

/**
 * @deprecated The method should not be used, use getFeatureGateStatus
 */
export const getFlipperStatus =
  (appType: AppType, email: string, merchantId: string, bizId?: string) =>
    (dispatch: Dispatch<ActionTypes>) => {
      dispatch({ type: GetFlipperActions.SET_FLIPPER_LOADING, isFlipperLoading: true })
      const { emailParams, merchantIdParams, bizIdParams, envMerchantIdParams } =
        generateFlipperURLparams()
      Promise.all([
        getApiMethods(appType).get(
          ENDPOINTS.API_XFERS_CHECK_FEATURE,
          `/?features=[%22${emailParams}%22]&flipper_id=${encodeURIComponent(email)}`
        ),
        getApiMethods(appType).get(
          ENDPOINTS.API_XFERS_CHECK_FEATURE,
          `/?features=[%22${merchantIdParams}%22]&flipper_id=${merchantId}`
        ),
        getApiMethods(appType).get(
          ENDPOINTS.API_XFERS_CHECK_FEATURE,
          `/?features=[%22${bizIdParams}%22]&flipper_id=${bizId}`
        ),
        // created seperate endpoint for environment flipper
        getApiMethods(appType).get(
          ENDPOINTS.API_XFERS_CHECK_FEATURE,
          `/?features=[%22${envMerchantIdParams}%22]&flipper_id=${generateEnvFlipperID(merchantId)}`
        ),
      ])
        .then(([emailIDresp, merchantIDresp, bizIDresp, envMerchantIdResp]) => {
          dispatch({
            type: GetFlipperActions.SET_EMAIL_FLIPPERS,
            emailIdFlippers: emailIDresp.data,
          })
          dispatch({
            type: GetFlipperActions.SET_MERCHANT_ID_FLIPPERS,
            merchantIdFlippers: merchantIDresp.data,
          })
          dispatch({
            type: GetFlipperActions.SET_BIZ_ID_FLIPPERS,
            BizIDflippers: bizIDresp.data,
          })
          dispatch({
            type: GetFlipperActions.SET_ENV_FLIPPERS,
            Envflippers: envMerchantIdResp.data,
          })
          dispatch({ type: GetFlipperActions.SET_FLIPPER_LOADING, isFlipperLoading: false })
          dispatch({ type: GetFlipperActions.SET_IS_FLIPPER_INITIATED, isFlipperInitiated: true })
        })
        .catch(() => {
          dispatch({ type: GetFlipperActions.SET_FLIPPER_LOADING, isFlipperLoading: false })
        })
    }
