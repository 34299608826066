import type { DropdownButtonProps } from 'antd/es/dropdown'
import Dropdown from 'antd/es/dropdown'
import 'antd/es/dropdown/style/css'
import 'antd/es/menu/style/css'
import styled from '@emotion/styled'
import { FONT_SIZE } from '../../../themes/deprecated/TypographyStyles'
import { baseStyle, buttonStyle } from '../../../themes/deprecated/fontStyles'
import { fdsTheme } from '../../../themes/theme-fds3'

type Placement =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight'

type Props = {
  overlay: React.ReactElement
  children: React.ReactNode
  type?: 'split' | 'default'
  icon?: React.ReactNode
  placement?: Placement
  className?: string
  disabled?: boolean
  trigger?: Array<'click' | 'hover' | 'contextMenu'>
} & Omit<DropdownButtonProps, 'type' | 'disabled'>

export default function CustomDropdown({
  overlay,
  children,
  type = 'default',
  placement = 'bottomLeft',
  className = '',
  trigger = ['click'],
  disabled = false,
  ...rest
}: Props) {
  const { spacing, colors, radius } = fdsTheme

  const primaryStyle = `
    background-color: ${colors.primary};
    color: ${colors.onContainer};
    border: 1px solid ${colors.onContainer};

    &:focus {
      background-color: ${colors.pressedPrimrary};
      color: ${colors.onContainer};
      border: 1px solid ${colors.onContainer};
    }
    
    &:active {
      background-color: ${colors.pressedPrimrary};
      color: ${colors.onContainer};
      border: 1px solid ${colors.onContainer};
    }

    &:hover {
      background-color: ${colors.hoveredPrimary};
      color: ${colors.onContainer};
      border: 1px solid ${colors.onContainer};
    }
  `

  const disabledStyle = `
    cursor: auto;
    pointer-events: none;
    background-color: ${colors.disabledButton};
    color: ${colors.surfaceDisabledOnSurface};
  `

  const DropdownButton = styled(Dropdown.Button)`
    &.ant-btn-group > .ant-btn:first-child:not(:last-child) {
      text-shadow: none;
      -webkit-font-smoothing: antialiased;
      border-top-left-radius: ${radius.lg};
      border-bottom-left-radius: ${radius.lg};
      min-height: 48px;
      box-shadow: none;
      line-height: 0.75;
      font-size: ${FONT_SIZE.LabelText} !important;
      ${baseStyle};
      ${buttonStyle};
      ${primaryStyle};
      ${disabled === true && disabledStyle}
      padding: ${spacing.md};
    }

    &.ant-btn-group > .ant-btn:last-child:not(:first-child) {
      text-shadow: none;
      -webkit-font-smoothing: antialiased;
      border-top-right-radius: ${radius.lg};
      border-bottom-right-radius: ${radius.lg};
      min-height: 48px;
      min-width: 45px;
      line-height: 0.75;
      box-shadow: none;
      ${baseStyle};
      ${buttonStyle};
      ${primaryStyle};
      ${disabled === true && disabledStyle}
      padding: ${spacing.sm};
    }
  `

  if (type === 'split') {
    return (
      <DropdownButton
        className={className}
        overlay={overlay}
        placement={placement}
        trigger={trigger}
        {...rest}
      >
        {children}
      </DropdownButton>
    )
  }
  return (
    <Dropdown overlay={overlay} placement={placement} trigger={trigger} {...rest}>
      {children}
    </Dropdown>
  )
}
