import * as React from 'react'

function PreviewExpandIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.38906 0.290039H1.99906C1.54874 0.30022 1.11969 0.483648 0.801178 0.802155C0.482671 1.12066 0.299243 1.54972 0.289062 2.00004V6.35004C0.289063 6.54895 0.36808 6.73972 0.508732 6.88037C0.649385 7.02102 0.84015 7.10004 1.03906 7.10004C1.23797 7.10004 1.42874 7.02102 1.56939 6.88037C1.71004 6.73972 1.78906 6.54895 1.78906 6.35004V2.88004L5.56906 6.67004C5.71139 6.8111 5.90367 6.89025 6.10406 6.89025C6.30445 6.89025 6.49674 6.8111 6.63906 6.67004C6.77951 6.52941 6.8584 6.33879 6.8584 6.14004C6.8584 5.94129 6.77951 5.75066 6.63906 5.61004L2.81906 1.79004H6.38906C6.58798 1.79004 6.77874 1.71102 6.91939 1.57037C7.06004 1.42972 7.13906 1.23895 7.13906 1.04004C7.13906 0.841127 7.06004 0.650361 6.91939 0.509709C6.77874 0.369057 6.58798 0.290039 6.38906 0.290039Z"
        fill="white"
      />
      <path d="M14.9994 8.85986C14.8013 8.86245 14.612 8.9423 14.4719 9.0824C14.3318 9.22249 14.252 9.41176 14.2494 9.60986V13.1699L10.9994 9.99986C10.8584 9.90847 10.6909 9.86705 10.5236 9.88224C10.3563 9.89743 10.1989 9.96834 10.0767 10.0836C9.9545 10.1989 9.87454 10.3519 9.84964 10.518C9.82475 10.6841 9.85634 10.8538 9.93937 10.9999L13.1294 14.1799H9.60938C9.41046 14.1799 9.2197 14.2589 9.07905 14.3995C8.93839 14.5402 8.85938 14.731 8.85938 14.9299C8.85938 15.1288 8.93839 15.3195 9.07905 15.4602C9.2197 15.6008 9.41046 15.6799 9.60938 15.6799H13.9994C14.4447 15.67 14.8695 15.4908 15.1872 15.1786C15.5049 14.8665 15.6917 14.4449 15.7094 13.9999V9.60986C15.7073 9.4184 15.633 9.23479 15.5013 9.09574C15.3697 8.95669 15.1904 8.87245 14.9994 8.85986Z"
        fill="white"
      />
      <path d="M8.89183 7.86015L8.75183 7.72015C8.68317 7.64647 8.60037 7.58736 8.50837 7.54637C8.41637 7.50538 8.31706 7.48334 8.21636 7.48156C8.11565 7.47979 8.01562 7.49831 7.92224 7.53603C7.82885 7.57375 7.74401 7.6299 7.67279 7.70112C7.60158 7.77233 7.54543 7.85717 7.50771 7.95056C7.46999 8.04394 7.45146 8.14397 7.45324 8.24468C7.45502 8.34538 7.47706 8.44469 7.51805 8.53669C7.55904 8.62869 7.61815 8.71149 7.69183 8.78015L7.83183 8.92015C7.97246 9.0606 8.16308 9.13949 8.36183 9.13949C8.56058 9.13949 8.75121 9.0606 8.89183 8.92015C9.03228 8.77953 9.11117 8.5889 9.11117 8.39015C9.11117 8.1914 9.03228 8.00078 8.89183 7.86015Z"
        fill="white"
      />
    </svg>
  )
}

export default PreviewExpandIcon