import * as React from 'react'

function SvgDbs(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.577 15.04s5.17-4.4 5.094-5.172c0 0 .618-1.62-1.312-1.852 0 0-.386-.155-1.852.617 0 0-3.242 1.698-6.098.232L4.866 8.17s-.54-.231-.85-.154c-1.93.231-1.158 1.93-1.158 1.93-.077.848 5.095 5.17 5.095 5.17s.54.387 1.08.463c0 .001.926.155 1.544-.54zm0 4.168s5.17 4.399 5.094 5.17c0 0 .618 1.622-1.312 1.853 0 0-.386.155-1.852-.617 0 0-3.242-1.698-6.098-.233l-1.543.696s-.54.232-.85.154c-1.93-.231-1.158-1.929-1.158-1.929-.077-.85 5.095-5.17 5.095-5.17s.54-.387 1.08-.465c0 0 .926-.077 1.544.541zm-3.551-.695s-4.322 5.094-5.094 5.094c0 0-1.699.54-1.93-1.312 0 0-.077-.386.618-1.852 0 0 1.776-3.32.232-6.175l-.618-1.543s-.231-.463-.154-.85c.231-1.853 1.93-1.158 1.93-1.158.771 0 5.094 5.095 5.094 5.095s.463.54.463 1.157c0 0 .154.849-.541 1.544zm4.245 0s4.322 5.094 5.095 5.094c0 0 1.698.54 1.93-1.312 0 0 .077-.386-.619-1.852 0 0-1.698-3.32-.231-6.175l.618-1.543s.231-.463.154-.85c-.154-1.853-1.929-1.158-1.929-1.158-.772 0-5.095 5.095-5.095 5.095s-.463.54-.463 1.157c0 0-.154.849.54 1.544z"
        fill="#CC2131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.477 22.912V10.177s0-.386-.618-.85h6.175c2.393.078 7.024 1.544 7.024 7.332 0 5.867-4.554 7.256-7.179 7.256h-6.02c.695-.54.617-1.003.617-1.003zm2.624-1.234c0 1.157 0 1.62 1.235 1.62 3.474 0 5.788-2.161 5.788-6.715 0-4.09-2.238-6.56-5.402-6.56-.926 0-1.312 0-1.467.386-.154.309-.154.926-.154 2.16v9.109zm19.605-5.636s1.776-1.003 1.776-3.01c.077-2.701-2.007-3.627-3.937-3.627h-6.638s.464.154.386 1.157v12.349c0 .85-.386.927-.386.927h6.947c2.239 0 4.786-1.08 4.786-3.937 0-2.314-1.39-3.163-2.934-3.859zm-3.55 7.255h-.927c-1.543 0-1.158-.77-1.158-1.312V10.408c.077-.463.463-.463.463-.463h.772c1.776 0 3.397.386 3.474 2.779.077 1.774-1.467 2.933-1.699 3.01-.348.138-.71.241-1.08.308v.154c1.235.155 3.551 1.39 3.551 3.628 0 2.394-1.621 3.629-3.396 3.473zm10.96-5.864c-3.937-1.16-3.628-3.937-3.628-3.937 0-3.628 4.168-4.014 4.168-4.014 1.698-.308 3.705.231 3.705.231 1.235.464 1.775.31 1.775.31v1.929h-.154s-1.081-1.621-2.47-1.775c0 0-3.165-.85-4.245 1.467 0 0-.927 2.392 1.929 3.087l2.238.694s3.55 1.004 3.55 3.936c0 0 .233 2.007-1.079 3.242 0 0-1.621 1.622-5.017 1.622 0 0-1.39 0-2.316-.31 0 0-1.39-.462-1.466-.462 0 0-.387 0-.618.076v-2.316h.231s1.235 2.007 3.782 2.007c0 0 2.316.309 3.242-1.466 0 0 .85-1.39-.231-2.933 0 0-.54-.695-2.007-1.158l-1.39-.23z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgDbs
