import type { Moment } from '~/types'
import i18n from '~/i18n'

export enum PayTransactionsTabKey {
  IDR_CASH_ACCOUNT = 'idr_cash_account',
  CASHOUT = 'cashout',
  LOAN_ACCOUNT = 'loan_account',
  FLEXPAY_ACCOUNT = 'flexpay_account',
}

export const PayTransactionsTabTitle = new Map<PayTransactionsTabKey, string>([
  [
    PayTransactionsTabKey.IDR_CASH_ACCOUNT,
    `${i18n.t('BUSINESS_ACCOUNT', { ns: 'feature/payment/payoutForm' })}`,
  ],
  [PayTransactionsTabKey.CASHOUT, 'Cashout'],
  [
    PayTransactionsTabKey.LOAN_ACCOUNT,
    i18n.t('LOAN_ACCOUNT', { ns: 'feature/payment/payoutForm' }),
  ],
  [PayTransactionsTabKey.FLEXPAY_ACCOUNT, 'FlexPay'],
])

export enum WalletTag {
  LOAN = 'loan',
  FLEXPAY = 'flexpay',
  IDR_CASH_ACCOUNT = 'idr_cash',
}

export const PayTransactionsTabWalletMapping = new Map<PayTransactionsTabKey, WalletTag>([
  [PayTransactionsTabKey.LOAN_ACCOUNT, WalletTag.LOAN],
  [PayTransactionsTabKey.FLEXPAY_ACCOUNT, WalletTag.FLEXPAY],
  [PayTransactionsTabKey.IDR_CASH_ACCOUNT, WalletTag.IDR_CASH_ACCOUNT],
])

export type TransactionFilters = {
  startDate?: Moment
  endDate?: Moment
  transactionStatuses?: string
  searchType?: string
  sortBy?: string
  searchTerm?: string
  currentPage: number
  pageLimit: number
}

export enum WalletTransactionType {
  MorakPaymentOrder = 'MorakPaymentOrder',
  MorakDisbursementOrder = 'MorakDisbursementOrder',
  FlexPayDisbursementOrder = 'FlexPayDisbursementOrder',
  IdPaymentServiceDashboardDisbursementOrder = 'IdPaymentServiceDashboardDisbursementOrder',
  IdPaymentServiceAPIDisbursementOrder = 'IdPaymentServiceAPIDisbursementOrder',
}

export enum LoanIncomingTransactionStatus {
  Pending = 'pending',
  Success = 'success',
}

export enum LoanOutgoingTransactionStatus {
  Pending = 'pending',
  Success = 'success',
  Rejected = 'rejected',
}

export enum FlexPayTransactionStatus {
  Pending = 'pending',
  Success = 'success',
  Rejected = 'rejected',
  Approved = 'approved',
  InProgress = 'in progress',
  InProcess = 'in_process',
}

export enum TransactionTableStatus {
  Pending = 'pending',
  Success = 'success',
  Rejected = 'rejected',
  Approved = 'approved',
  InProgress = 'in progress',
  NeedsApproval = 'needs approval',
  InProcess = 'in_process',
}

export enum IDRtableStatus {
  pending = 'pending',
  processing = 'in process',
  completed = 'completed',
  rejected = 'rejected',
  needsApproval = 'needs approval',
  failed = 'failed',
}

export type WalletTransaction = {
  id: string
  type: string
  attributes: {
    orderType: WalletTransactionType
    status: LoanIncomingTransactionStatus | LoanOutgoingTransactionStatus | FlexPayTransactionStatus
    details: {
      fee: number
      tax: number
      code: string
      note?: string
      amount: number
      destinationName: string
      transferReceipt?: string
      bankName: string
      destinationPicEmail: string
      merchantEmail: string
      destinationBankAccountName: string
      destinationBankAccountNumber: string
      destinationBankName: string
      bankAccountNumber: string
      merchantName: string
      document?: WalletTransactionDocument[]
      detail?: WalletTransferDetails[]
      categoryName?: string
      vendorBankName?: string
      vendorBankNumber?: string
      vendorBankOwnerName?: string
      fpInvoiceNumber?: string
      businessName?: string
      vendorName?: string
      paymentMethod?: string
      updatedTime?: string
      ownerRejectNote?: string
      rejectReason?: string
      settledAt?: string
      paymentAmount?: number
      paymentDate?: string
      paymentStatus?: string
      paymentId?: string
      invoiceLink?: string
      expenseList?: FlexpayExpense[]
    }
    category?: string
    createdAt: string
    completedAt?: string
    externalId: string
    tags: Array<string>
  }
}

export type IDRwalletTransaction = {
  id: string
  type: string
  attributes: {
    searchable: {
      recipientBankAccountHolder: string
      recipientCompanyName: string
      transferId: string
    }
    orderType: WalletTransactionType
    status: LoanIncomingTransactionStatus | LoanOutgoingTransactionStatus | FlexPayTransactionStatus
    details: {
      id?: number
      fee: string
      key: string
      tax: string
      amount: {
        prefix: string
        postfix: string
      }
      method: string
      network: string
      uniqueId: string
      netAmount: {
        prefix: string
        postfix: string
      }
      description: {
        data: string
        type: string
        direction: string
      }
      walletType: string
      completedAt: string
      failureReason: string
      blockedReasons: string
      transactionType: string
      sourceDestination: {
        source: string
        destination: string
      }
      transactionStatus: string
      accountHolderName: string
      merchantDescription: string
      transactionId: string
    }
    category?: string
    createdAt: string
    completedAt?: string
    externalId: string
    tags: Array<string>
  }
}

export type IDRwalletTransactionNew = {
  id: string
  type: string
  attributes: {
    searchable: {
      recipientBankAccountHolder: string
      recipientCompanyName: string
      transferId: string
    }
    orderType: WalletTransactionType
    status: LoanIncomingTransactionStatus | LoanOutgoingTransactionStatus | FlexPayTransactionStatus
    details: {
      id?: number
      fee: string
      key: string
      tax: string
      externalId: string
      bankShortCode: string
      accountNo: string
      amount: string
      method: string
      network: string
      uniqueId: string
      netAmount: string
      description: {
        data: string
        type: string
        direction: string
      }
      walletType: string
      completedAt: string
      failureReason: string
      blockedReasons: string
      transactionType: string
      sourceDestination: {
        source: string
        destination: string
      }
      transactionStatus: string
      accountHolderName: string
      merchantDescription: string
      transactionId: string
      note: string
    }
    category?: string
    createdAt: string
    completedAt?: string
    externalId: string
    tags: Array<string>
  }
}

export type UnionIDRwalletTransaction = IDRwalletTransaction | IDRwalletTransactionNew

export type WalletTransferDetails = {
  code: string
  amount: number
  status: string
  fee: number
  tax: number
}

export type FlexpayExpense = {
  invoiceAmount: number
  invoiceDate: string
  invoiceId: string
  invoiceNumber: string
}

export type WalletTransactionDocument = {
  docType: string
  docUrl: string
}

export enum WalletLedgerPurpose {
  PendingTransfer = 'PendingTransfer',
  Available = 'Available',
}

export const WalletLedgerPurposeTitle = new Map<WalletLedgerPurpose, string>([
  [WalletLedgerPurpose.PendingTransfer, 'Transfer in Process'],
  [WalletLedgerPurpose.Available, 'Available Balance'],
])

export const WalletLedgerPurposeDescription = new Map<WalletLedgerPurpose, string>([
  [
    WalletLedgerPurpose.PendingTransfer,
    'Funds are currently in pending transfer approval or in progress transferred to destination bank',
  ],
  [WalletLedgerPurpose.Available, 'Available funds for loan transfer'],
])

export const FlexPayWalletLedgerPurposeTitle = new Map<WalletLedgerPurpose, string>([
  [WalletLedgerPurpose.Available, 'Available Credit'],
])

export const FlexPayWalletLedgerPurposeDescription = new Map<WalletLedgerPurpose, string>([
  [WalletLedgerPurpose.Available, 'Remaining credit balance to be used'],
])

export const LoanIncomingTransactionStatusDescription = new Map<string, string>([
  [LoanIncomingTransactionStatus.Pending, 'Funds are currently being processed for clearance'],
  [LoanIncomingTransactionStatus.Success, 'Funds are settled and available to be used'],
])

export const LoanOutgoingTransactionStatusDescription = new Map<string, string>([
  [
    LoanOutgoingTransactionStatus.Pending,
    'The transfer is in pending approval from the loan administrator',
  ],
  [LoanOutgoingTransactionStatus.Success, 'The transfer has been sent to the recipient'],
  [
    LoanOutgoingTransactionStatus.Rejected,
    'The transfer has been rejected by the loan administrator',
  ],
])

export const FlexPayTransactionStatusDescription = new Map<string, string>([
  [
    FlexPayTransactionStatus.Pending,
    'The transfer is in pending approval from the loan administrator',
  ],
  [FlexPayTransactionStatus.Success, 'The transfer has been sent to the recipient'],
  [FlexPayTransactionStatus.Rejected, 'The transfer has been rejected by the loan administrator'],
  [
    FlexPayTransactionStatus.Approved,
    'The transfer is approved and waiting to be disbursed by loan administrator',
  ],
  [FlexPayTransactionStatus.InProgress, 'The transfer is being processed'],
  [FlexPayTransactionStatus.InProcess, 'The transfer is in process'],
])

export const TransactionTableStatusDescription = new Map<string, string>([
  [TransactionTableStatus.Pending, 'This recipient is being verified by our team'],
  [TransactionTableStatus.Success, 'The transfer has been sent to the recipient'],
  [TransactionTableStatus.Rejected, 'Transfer is not approved. Please contact our team.'],
  [TransactionTableStatus.Approved, 'Transfer is approved and is on process'],
  [TransactionTableStatus.InProgress, 'The transfer is being processed'],
  [TransactionTableStatus.NeedsApproval, 'The transfer needs approval from the manager'],
  [TransactionTableStatus.InProcess, 'The transfer is in process'],
])

export const IDRtableStatusDescription = new Map<string, string>([
  [IDRtableStatus.processing, 'The transfer is processing'],
  [IDRtableStatus.pending, 'The transfer is on progress'],
  [IDRtableStatus.rejected, ' The transfer has been rejected'],
  [IDRtableStatus.failed, 'The transfer is failed. Contact our customer support.'],
  [IDRtableStatus.needsApproval, 'The transfer needs approval from the manager'],
  [IDRtableStatus.completed, 'The transfer has been sent to the recipient'],
])

export type WalletLedger = {
  id: string
  type: string
  attributes: {
    balance: string
    purpose: WalletLedgerPurpose
  }
}

export type Wallet = {
  id: string
  type: string
  attributes: {
    tag: WalletTag
    description: string
  }
  relationships: {
    ledgers: Array<WalletLedger>
  }
}

export type TransactionStatus = {
  label: string
  value: string
}

export type IDRtransaction = {
  key: number
  transaction_id: string
  created_at: string
  completed_at: string
  description: {
    type: string
    direction: string
    data: string
  }
  method: string
  amount: {
    prefix: string
    postfix: string
  }
  transaction_status: string
  fee: string
  tax: string
  net_amount: {
    prefix: string
    postfix: string
  }
  transaction_type: string
  wallet_type: string
  blocked_reasons: string
  unique_id: string
  source_destination: {
    source: string
    destination: string
  }
  merchant_description: string
  network: string
  account_holder_name: string
  failure_reason: string
}
