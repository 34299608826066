import { FormItem, Checkbox } from 'formik-antd'
import styled from 'styled-components'
import { fdsTheme } from '../../../../themes/theme-fds3'
import { type FormikCheckboxProps } from './FormikCheckbox.types'

// Retrieve all theming data
const { spacing, colors, fontStyles } = fdsTheme

type CheckboxExtendedProps = FormikCheckboxProps & {
  disabled: boolean
}

export const StyledFormikCheckbox = styled(Checkbox).attrs((props: CheckboxExtendedProps) => ({
  isDisabled: props.disabled,
}))`
  ${({ isDisabled }) => `

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    border-radius: ${spacing.xs};
  }
  
  .ant-checkbox-inner {
    border-color: ${colors.line};
    border-radius: ${spacing.xxs};
  }
  
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${isDisabled ? colors.onNeutralSecondary : colors.active};
    border-color: ${isDisabled ? colors.onNeutralSecondary : colors.active};
  }
  
  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: ${isDisabled ? colors.inactive : colors.onContainer};
  }

  &:hover {
    .ant-checkbox-inner {
      border-color: ${isDisabled ? colors.line : colors.active};
      box-shadow: ${isDisabled ? 'none' : `0 0 0 ${spacing.xxs} ${colors.hoveredSurface}`};
    }
  }
  
  &:focus {
    .ant-checkbox-inner {
      border-color: ${colors.line};
      box-shadow: ${isDisabled ? 'none' : `0 0 0 ${spacing.xxs} ${colors.focusedSurface}`};
    }
  }
  
  &:active {
    .ant-checkbox-inner {
      border-color: ${colors.line};
      box-shadow: ${isDisabled ? 'none' : `0 0 0 ${spacing.xxs} ${colors.pressedSurface}`};
    }
  }
  
`}
`

export const StyledFormItem = styled(FormItem).attrs((props: FormikCheckboxProps) => ({
  labelPlacement: props.labelPlacement,
}))`
  ${({ labelPlacement }) => `
  .ant-form-item-control-input-content {
    display: flex;
    width: 100%;
    flex-direction: ${labelPlacement === 'right' ? 'row' : 'row-reverse'};
    justify-content: ${labelPlacement === 'right' ? 'flex-start' : 'flex-end'};
  }

  .label-container-active {
    color: ${colors.onNeutral};
    margin-left: ${labelPlacement === 'right' ? spacing.xs : 0};
    margin-right: ${labelPlacement === 'left' ? spacing.xs : 0};
  }

  .label-container-disabled {
    color: ${colors.inactive};
    margin-left: ${labelPlacement === 'right' ? spacing.xs : 0};
    margin-right: ${labelPlacement === 'left' ? spacing.xs : 0};
  }

  &.ant-form-item {
    margin-bottom: 0;
  }

  &.ant-form-item-has-error {
    .ant-input {
      border-color: ${colors.critical};
    }

    .ant-form-item-explain-error {
      margin-top: ${spacing.xs};
      margin-left: ${spacing.md};
      ${fontStyles.bodyMedium}
      color: ${colors.critical};
      text-align: left;
    }
  }

  .ant-form-item-extra {
    margin-top: ${spacing.xs};
    margin-left: ${spacing.md};
    ${fontStyles.bodySmall}
    text-align: left;
    color: ${colors.helperInput};
  }

  .ant-form-item-control div:nth-of-type(3) {
    display: none;
  }

`}
`
