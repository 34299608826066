import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { DESKTOP_BREAKPOINT, Typography, fdsTheme } from '@fazz/design-system'
import { LeftArrow } from '@fazz/design-system-icons'
import { useHeaderContext } from '~/hooks/useHeaderContext'
import { useHistory } from '~/hooks/useReactRouter'
import { isHeaderTranslation, isHeaderWithSubtitle } from '~/types'

const { colors, spacing } = fdsTheme
const { Title, Body } = Typography

export default function BreadcrumbText() {
  const { t: i18nTranslation } = useTranslation()
  const history = useHistory()
  const { header } = useHeaderContext()

  return (
    <Container>
      <LeftArrow onClick={history.goBack} style={{ cursor: 'pointer', marginRight: spacing.sm }} />
      {isHeaderWithSubtitle(header) && (
        <div>
          <Title size="md" display="inline">
            {isHeaderTranslation(header.title)
              ? i18nTranslation(`${header.title.namespace}:${header.title.i18nkey}`)
              : header.title}
          </Title>
          <Body size="md" color={colors.onNeutralSecondary}>
            {isHeaderTranslation(header.subtitle)
              ? i18nTranslation(`${header.subtitle.namespace}:${header.subtitle.i18nkey}`)
              : header.subtitle}
          </Body>
        </div>
      )}
      {!isHeaderWithSubtitle(header) && (
        <Title size="md" display="inline">
          {isHeaderTranslation(header)
            ? i18nTranslation(`${header.namespace}:${header.i18nkey}`)
            : header}
        </Title>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 62px;
  padding: ${spacing.md};

  ${DESKTOP_BREAKPOINT} {
  }
`
