import { fdsTheme } from 'fds3-src/themes/theme-fds3'
import { DatePicker, FormItem } from 'formik-antd'
import styled from 'styled-components'
import { css } from '@emotion/core'

// Retrieve all theming data
const { spacing, colors, fontStyles, fontFamily, radius } = fdsTheme

const { RangePicker } = DatePicker

export const StyledDatePickerDropdown = css`
  .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
    color: #000;
    background: ${colors.surfaceDisabledSurface};
    border-color: ${colors.primary};
    cursor: pointer;
    border-radius: 100px;
    padding: 5px 10px;
  }
`

export const StyledFormikDatePicker = styled(DatePicker)`
  .ant-picker-clear {
    right: 15% !important;
  }

  &.ant-picker {
    width: 100%;
    font-family: ${fontFamily}
    height: 56px;
    border: 1px solid ${colors.line};
    color: ${colors.onNeutral};
    border-radius: ${radius.lg}!important;
    padding: 0px ${spacing.md};
    background: ${colors.surfaceNeutral};

    ::-webkit-input-placeholder {
      ${fontStyles.bodyLarge}
      color: ${colors.placeholderInput};
    }

    &:focus {
      border: 1px solid ${colors.active};
      box-shadow: none;
    }

    &:disabled {
      background-color: ${colors.surfaceDisabledSurface};
    }
  }
`

export const StyledFormikRangePicker = styled(RangePicker)`
  .ant-picker-clear {
    right: 15% !important;
  }

  background-color: red;

  &.ant-picker-dropdown .antd-date-range-picker {
  background-color: red !important;

    &.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
      color: #000;
      background: ${fdsTheme.colors.surfaceDisabledSurface};
      border-color: ${fdsTheme.colors.primary};
      cursor: pointer;
      border-radius: 100px;
      padding: 5px 10px;
    }
  }

  &.ant-picker {
    width: 100%;
    font-family: ${fontFamily}
    height: 56px;
    border: 1px solid ${colors.line};
    color: ${colors.onNeutral};
    border-radius: ${radius.lg}!important;
    padding: 0px ${spacing.md};
    background: ${colors.surfaceNeutral};

    ::-webkit-input-placeholder {
      ${fontStyles.bodyLarge}
      color: ${colors.placeholderInput};
    }

    &:focus {
      border: 1px solid ${colors.active};
      box-shadow: none;
    }

    &:disabled {
      background-color: ${colors.surfaceDisabledSurface};
    }
  }
`

export const StyledFormItem = styled(FormItem)`
  &.ant-form-item {
    margin-bottom: 0;
  }

  // error state + error message
  &.ant-form-item-has-error {
    .ant-select-selector {
      border-color: ${colors.critical} !important;
    }

    .ant-form-item-explain-error {
      margin-top: ${spacing.xs};
      margin-left: ${spacing.md};
      ${fontStyles.bodyMedium}
      color: ${colors.critical};
      text-align: left;
    }
  }

  // helper text
  .ant-form-item-extra {
    margin-top: ${spacing.xs};
    margin-left: ${spacing.md};
    ${fontStyles.bodyMedium}
    text-align: left;
    color: ${colors.helperInput};
  }
`
