import { Title } from 'fds3-src/themes/Typography'
import { CloseRounded } from '@fazz/design-system-icons'
import { IconButton } from '../IconButton'
import { StyledDrawer } from './Drawer.styles'
import type { DrawerProps } from './Drawer.types'

export function Drawer({
  style,
  className,
  open,
  children,
  onClose,
  height,
  footer,
  isMaskClosable,
  destroyOnClose,
  showMask,
  title,
  width = 392,
  isBottomsheet = false,
  placement = isBottomsheet ? 'bottom' : 'right',
  isClosable = !!title,
  padding = 'lg',
  overflow = 'scroll',
}: // DO NOT USE SPREAD OPERATOR i.e. {...props}
DrawerProps) {
  return (
    <StyledDrawer
      visible={open}
      className={className}
      style={style}
      mask={showMask}
      placement={placement}
      maskClosable={isMaskClosable}
      destroyOnClose={destroyOnClose}
      closable={isClosable}
      title={title && <Title size="sm">{title}</Title>}
      closeIcon={<IconButton size="sm" variant="ghost" icon={<CloseRounded />} />}
      height={height}
      width={width}
      footer={footer}
      onClose={onClose}
      isBottomsheet={isBottomsheet}
      padding={padding}
      overflow={overflow}
    >
      {children}
    </StyledDrawer>
  )
}

export default Drawer
