import styled from '@emotion/styled'
import { DESKTOP_BREAKPOINT } from '../../../themes/deprecated/breakPoints'

type HiddenProps = {
  only: 'sm' | 'lg'
  children: React.ReactNode
}

function Hidden({ only, children }: HiddenProps) {
  const StyledHidden = styled.div`
    display: ${(props: HiddenProps) => (props.only === 'lg' ? 'block' : 'none')};
    ${DESKTOP_BREAKPOINT} {
      display: ${(props: HiddenProps) => (props.only === 'lg' ? 'none' : 'block')};
    }
  `

  return <StyledHidden only={only}>{children}</StyledHidden>
}

export default Hidden
