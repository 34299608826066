import type { WALLET_TYPES } from '~/types'

export type WithdrawWalletIdType = WALLET_TYPES.GENERAL | undefined

export type Wallet = {
  accountConfiguration: { minimumBalance: string } | undefined
  type: WithdrawWalletIdType
  balance: string
  formattedBalance: string
  label: string
  accountId: number
  accountType: string
  fundPoolId: number
}

export type Account = {
  attributes: {
    wallet_id: number
    limitations: {
      withdrawal_daily: {
        remaining: string
      }
    }
    type: string
  }
}

export type AccountLimits = {
  withdrawDailyRemaining: string
  holdingMax: string
}

export type BankAccount = {
  id: number
  bankName: string
  accountNumber: string
  status: 'unverified' | 'verified' | 'pending' | 'rejected'
}

export enum WithdrawActions {
  SET_WITHDRAW_WALLET = 'WithdrawActions.SET_WITHDRAW_WALLET',
  SET_WITHDRAW_AMOUNT = 'WithdrawActions.SET_WITHDRAW_AMOUNT',
  SET_WITHDRAW_TO_BANK_ID = 'WithdrawActions.SET_WITHDRAW_TO_BANK_ID',
  SET_CONFIRM_MODAL_OPEN = 'WithdrawActions.SET_CONFIRM_MODAL_OPEN',
  SET_WITHDRAW_STEP = 'WithdrawActions.SET_WITHDRAW_STEP',
  SET_2_FA_MODAL_OPEN = 'WithdrawActions.SET_2_FA_MODAL_OPEN',
  SET_IS_LOADING = 'WithdrawActions.SET_IS_LOADING',
  GET_ACCOUNT_LIMITS = 'WithdrawActions.GET_ACCOUNT_LIMITS',
  GET_USER_DETAILS = 'WithdrawActions.GET_USER_DETAILS',
  CREATE_WITHDRAWAL_REQUEST = 'WithdrawActions.CREATE_WITHDRAWAL_REQUEST',
  GET_ID_WITHDRAWAL_LIMITS = 'WithdrawActions.GET_ID_WITHDRAWAL_LIMITS',
  SET_IS_MERCHANT_FIAT_WITHDRAWAL_ENABLED = 'WithdrawActions.SET_IS_MERCHANT_FIAT_WITHDRAWAL_ENABLED',
  SET_EXTERNAL_ID = 'SET_EXTERNAL_ID',
}

export enum WithdrawSteps {
  DETAILS = 0,
  COMPLETE = 1,
}

type SetWithdrawWallet = {
  type: typeof WithdrawActions.SET_WITHDRAW_WALLET
  walletId: WithdrawWalletIdType
  accountId: string
  walletName: string | number | undefined
}

type SetWithdrawAmount = {
  type: typeof WithdrawActions.SET_WITHDRAW_AMOUNT
  amount: string
  withdrawAmountError: string
}

type SetWithdrawToBankId = {
  type: typeof WithdrawActions.SET_WITHDRAW_TO_BANK_ID
  bankId: number | undefined
}

type SetExternalId = {
  type: typeof WithdrawActions.SET_EXTERNAL_ID
  externalId: string | undefined
}

type SetConfirmModalOpen = {
  type: typeof WithdrawActions.SET_CONFIRM_MODAL_OPEN
  open: boolean
}

type SetWithdrawStep = {
  type: typeof WithdrawActions.SET_WITHDRAW_STEP
  step: WithdrawSteps
}

type Set2FaModalOpen = {
  type: typeof WithdrawActions.SET_2_FA_MODAL_OPEN
  open: boolean
}

type SetIsLoading = {
  type: typeof WithdrawActions.SET_IS_LOADING
  isLoading: boolean
}

type GetAccountLimits = {
  type: typeof WithdrawActions.GET_ACCOUNT_LIMITS
  accountLimits: AccountLimits
}

type GetUserDetails = {
  type: typeof WithdrawActions.GET_USER_DETAILS
  bankAccounts: BankAccount[]
  has2FaEnabled: boolean
  fullyVerified: boolean
}

type CreateWithdrawalRequest = {
  type: typeof WithdrawActions.CREATE_WITHDRAWAL_REQUEST
  error: string
  withdrawBankAccount: string
}

type GetIdWithdrawalLimits = {
  type: typeof WithdrawActions.GET_ID_WITHDRAWAL_LIMITS
  idMinWithdrawal: string
  idMaxWithdrawal: string
}

export type ActionTypes =
  | SetWithdrawWallet
  | SetWithdrawAmount
  | SetWithdrawToBankId
  | SetConfirmModalOpen
  | SetWithdrawStep
  | Set2FaModalOpen
  | SetIsLoading
  | GetAccountLimits
  | GetUserDetails
  | CreateWithdrawalRequest
  | GetIdWithdrawalLimits
  | SetExternalId
