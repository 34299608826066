import type { ENVIRONMENT } from '~/managers/ConfigManager'

// START NOTE: DO NOT EDIT THIS MANUALLY , run yarn create-feature-gate <feature_gate_id_name>
export const featureGateIDs = {
  fazzbiz_enable_api_menu: false,
  fazzbiz_enable_earn: false,
  fazzbiz_usd_account_disburse_interests_whitelisted_merchants: false,
  fazzbiz_enable_payout_form_whitelist: false,
  fazzbiz_enable_clevertap: false,
  fazzbiz_enable_new_flexpay_recipient_form: false,
  fazzbiz_pay_menu_disabled: false,
  fazzbiz_enable_usd_earn: false,
  fazzbiz_disable_withdraw: false,
  fazzbiz_enable_usd_account_maintenance_page: false,
  fazzbiz_is_wallex_account_verified: false,
  fazzbiz_enable_sg_recipients: false,
  fazzbiz_enable_sgd_earn_maintenance_page: false,
  fazzbiz_kyb_ubo_enabled: false,
  fazzbiz_enable_singpass_kyc_verification: false,
  fazzbiz_enable_usd_credit_card: false,
}
// END NOTE: DO NOT EDIT THIS MANUALLY , run yarn create-feature-gate <feature_gate_id_name>

// Exclude Development from type because on localhost we're connected to staging
type EnvironmentPrefix = Exclude<ENVIRONMENT, ENVIRONMENT.DEVELOPMENT>

export const buildFeatureGateQueryParams = (
  bizId: string,
  environmentPrefix: EnvironmentPrefix
) => {
  const featureGateUrlParams = Array.from(Object.keys(featureGateIDs)).join('%22,%22')
  const featureGateQueryParams = `/?features=["${featureGateUrlParams}"]&flipper_id=${environmentPrefix}_${bizId}`

  return featureGateQueryParams
}
