import styled from 'styled-components'
import { fdsTheme } from '../../../themes/theme-fds3'

// Retrieve all theming data
const { spacing } = fdsTheme

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.md};
`

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};
`
