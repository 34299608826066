import type { Moment, TransactionType } from '~/types'

// TODO: Change to PascalCase
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum CSV_FORMAT_VERSION {
  V3 = 'V3',
  V4 = 'V4',
}

// TODO: Change to PascalCase
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum TRANSACTIONS_TAB_KEY {
  XFERS_ACCOUNT = 'xfers_account',
  XFERS_WALLET = 'xfers_wallet',
  MANAGED_ACCOUNT = 'managed_account',
}

export type CardInfo = {
  isRecurring: boolean
  cardNumber: string
  expiryDate: string
  cardType: string
  type?: string
}

export type Transactions = {
  key: string
  transaction_id: string
  created_at: string
  description: {
    account_holder_name: string
    description_detail: {
      type: string
      direction: string
      data: string | { type: string }
      sender_name: string
    }
    data: string
    direction: string
    type: string
    internal_transaction_type?: string
    payment_id?: string
    bank_account?: {
      bank_name: string
      account_number: string
      account_name: string
    }
    sender_name?: string
  }
  method: string
  amount: { prefix: string; postfix: string }
  fee?: string
  net_amount?: { prefix: string; postfix: string }
  transaction_status: string
  tax?: string
  account_holder_name?: string
  transaction_type?: TransactionType
  transaction_hash?: string | null
  transaction_hash_url?: string | null
  manual_assignments?: Array<{
    created_at: string
    amount: { prefix: string; postfix: string }
    status: string
    external_id: string
  }>
}

export type PaginationCursor = {
  lastRecordId: string
  lastRecordDatetime: string
}

export type FilterOptions = {
  transactionTypes: Array<{ label: string; value: string }>
  transactionStatuses: Array<{ label: string; value: string }>
  sortTypes: Array<{ label: string; value: string }>
  accountTypes: Array<{ label: string; value: string }> | undefined
}

export type FilterValues = {
  startDate: Moment | string
  endDate: Moment | string
  transactionStatuses: string[]
  transactionTypes: string[]
  sortType: string
  accountIds: number[]
  currentPage: number
  pageLimit: number
  totalCount: number
  searchString: string
  contractsExist: boolean
  transactions: Transactions[] | undefined
  isTxnLoading: boolean
  isPaginationLoading: boolean
  [key: string]: FilterValuesObject[keyof FilterValuesObject]
}

export type FilterValuesObject = {
  [filterKey: string]:
    | Moment
    | string[]
    | number[]
    | string
    | number
    | Transactions[]
    | boolean
    | undefined
}

// TODO: Change to PascalCase
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum TRANSACTIONS_ACTIONS {
  SET_IS_TXN_LOADING = 'TRANSACTIONS_ACTIONS.SET_IS_TXN_LOADING',
  SET_IS_FILTER_LOADING = 'TRANSACTIONS_ACTIONS.SET_IS_FILTER_LOADING',
  SET_IS_PAGINATION_LOADING = 'TRANSACTIONS_ACTIONS.SET_IS_PAGINATION_LOADING',
  SET_CSV_DOWNLOAD_COMPLETE = 'TRANSACTIONS_ACTIONS.SET_CSV_DOWNLOAD_COMPLETE',
  GET_TRANSACTIONS = 'TRANSACTIONS_ACTIONS.GET_TRANSACTIONS',
  GET_TRANSACTIONS_TOTAL_COUNT = 'TRANSACTIONS_ACTIONS.GET_TRANSACTIONS_TOTAL_COUNT',
  GET_TRANSACTION_FILTER_OPTIONS = 'TRANSACTIONS_ACTIONS.GET_TRANSACTION_FILTER_OPTIONS',
  SET_FILTERS = 'TRANSACTIONS_ACTIONS.SET_FILTERS',
  RESET_CURRENT_PAGE = 'TRANSACTIONS_ACTIONS.RESET_CURRENT_PAGE',
  SET_CSV_FORMAT_VERSION = 'TRANSACTIONS_ACTIONS.SET_CSV_FORMAT_VERSION',
  SWITCH_TRANSACTIONS_TAB = 'TRANSACTIONS_ACTIONS.SWITCH_TRANSACTIONS_TAB',
  GET_TRANSACTION_TAB_OPTIONS = 'TRANSACTIONS_ACTIONS.GET_TRANSACTION_TAB_OPTIONS',
  SET_USE_NEW_PAGINATION = 'TRANSACTIONS_ACTIONS.SET_USE_NEW_PAGINATION',
  SET_PAGINATION_CURSORS = 'TRANSACTIONS_ACTIONS.SET_PAGINATION_CURSORS',
}

type SetIsTxnLoading = {
  type: typeof TRANSACTIONS_ACTIONS.SET_IS_TXN_LOADING
  isTxnLoading: boolean
}

type SetIsFilterLoading = {
  type: typeof TRANSACTIONS_ACTIONS.SET_IS_FILTER_LOADING
  isFilterLoading: boolean
}

type SetIsPaginationLoading = {
  type: typeof TRANSACTIONS_ACTIONS.SET_IS_PAGINATION_LOADING
  isPaginationLoading: boolean
}

type GetTransactions = {
  type: typeof TRANSACTIONS_ACTIONS.GET_TRANSACTIONS
  transactions: Transactions[]
  contractsExist: boolean
}

type GetTransactionsTotalCount = {
  type: typeof TRANSACTIONS_ACTIONS.GET_TRANSACTIONS_TOTAL_COUNT
  totalCount: number
}

type GetTransactionFilterOptions = {
  type: typeof TRANSACTIONS_ACTIONS.GET_TRANSACTION_FILTER_OPTIONS
  viewType: string
  filterOptions: {
    [TRANSACTIONS_TAB_KEY.XFERS_ACCOUNT]: FilterOptions
    [TRANSACTIONS_TAB_KEY.XFERS_WALLET]: FilterOptions
    [TRANSACTIONS_TAB_KEY.MANAGED_ACCOUNT]: FilterOptions
  }
}

type SetFilters = {
  type: typeof TRANSACTIONS_ACTIONS.SET_FILTERS
  filterValuesObject: FilterValuesObject
}

type SetUseNewPagination = {
  type: typeof TRANSACTIONS_ACTIONS.SET_USE_NEW_PAGINATION
  useNewPagination: boolean
}

type ResetCurrentPage = {
  type: typeof TRANSACTIONS_ACTIONS.RESET_CURRENT_PAGE
}

type SetCsvFormatVersion = {
  type: typeof TRANSACTIONS_ACTIONS.SET_CSV_FORMAT_VERSION
  csvFormatVersion: CSV_FORMAT_VERSION
}

type SwitchTransactionsTab = {
  type: typeof TRANSACTIONS_ACTIONS.SWITCH_TRANSACTIONS_TAB
  transactionsTabKey: TRANSACTIONS_TAB_KEY
}

type GetTransactionTabOptions = {
  type: typeof TRANSACTIONS_ACTIONS.GET_TRANSACTION_TAB_OPTIONS
  transactionTabOptions: TRANSACTIONS_TAB_KEY[]
}

type SetPaginationCursors = {
  type: typeof TRANSACTIONS_ACTIONS.SET_PAGINATION_CURSORS
  paginationCursors: Array<PaginationCursor>
}

export type ActionTypes =
  | SetIsTxnLoading
  | SetIsFilterLoading
  | SetIsPaginationLoading
  | GetTransactions
  | GetTransactionsTotalCount
  | GetTransactionFilterOptions
  | SetFilters
  | ResetCurrentPage
  | SetCsvFormatVersion
  | SwitchTransactionsTab
  | GetTransactionTabOptions
  | SetUseNewPagination
  | SetPaginationCursors
