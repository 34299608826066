import * as React from 'react'

function SvgNotes(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#drawer_svg__a)" fill="currentColor">
        <path d="M19.4 7.65h-14a.75.75 0 0 1 0-1.5h14a.75.75 0 1 1 0 1.5ZM19.4 12.75h-14a.75.75 0 1 1 0-1.5h14a.75.75 0 1 1 0 1.5ZM19.4 17.85h-14a.75.75 0 1 1 0-1.5h14a.75.75 0 1 1 0 1.5Z" />
      </g>
      <defs>
        <clipPath id="drawer_svg__a">
          <path fill="#fff" transform="translate(.4)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgNotes
