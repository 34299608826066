import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from '../../../themes/deprecated/themeContext'

type BadgeIconProps = {
  type?: 'default' | 'primary' | 'success' | 'warning' | 'error' | 'progress'
  icon: React.ReactElement
  iconWidth?: number
} & React.HTMLAttributes<HTMLDivElement>

function BadgeIcon({ icon, type = 'default', iconWidth = 18, ...rest }: BadgeIconProps) {
  const { base, brand, neutral, success, warning, status, progress } = useTheme()

  const defaultStyle = `
    color: ${neutral.text};
    background: ${neutral.background};
    `
  const primaryStyle = `
    color: ${base.primary};
    background: ${brand.primary.background};
    `
  const successStyle = `
    color: ${success.border};
    background: ${success.background};
    `
  const warningStyle = `
    color: ${warning.border};
    background: ${status.subdueWarning};
    `
  const errorStyle = `
    color: ${status.critical};
    background: ${status.subdueCritical};
    `

  const progressStyle = `
    color: ${progress.active};
    background: ${progress.primary};
    `

  const getTypeStyle = () => {
    switch (type) {
      case 'primary':
        return primaryStyle
      case 'success':
        return successStyle
      case 'warning':
        return warningStyle
      case 'error':
        return errorStyle
      case 'progress':
        return progressStyle
      case 'default':
      default:
        return defaultStyle
    }
  }

  const StyledBadge = styled.span`
    line-height: 0.75;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${getTypeStyle()}
  `
  const ResizedIcon = () =>
    React.cloneElement(icon, {
      width: iconWidth,
      height: iconWidth,
    })

  return (
    <StyledBadge className="badge-icon" {...rest}>
      <ResizedIcon />
    </StyledBadge>
  )
}

export default BadgeIcon
