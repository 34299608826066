import cookie from 'js-cookie'
import { resetStore as resetBizStore } from '~/biz/store/store'
import { resetStore as resetBizRegionalStore } from '~/bizRegional/store/store'
import { SSOLinks } from '~/common/commonLinks'
import { isProduction } from '~/managers/ConfigManager'

type RedirectQuery = {
  client_id: string
  redirect_uri: string
  response_type: string
  scope: string
  kc_action: string
  selected_merchant_id: string
}

interface SessionUtilsInterface {
  isLoggedIn: () => boolean
  getRedirectMerchantAccountURL: (
    kcEnvironmentURL: string,
    redirectURI: string,
    merchantId: string
  ) => string
  ssoLogin: () => void
  ssoLogout: () => void
  ssoSwitchMerchantAccount: (merchantId: string) => void
  removeSessionCookies: () => void
}

const SessionUtils: SessionUtilsInterface = {
  isLoggedIn: () => cookie.get('SESSIONID') !== undefined,
  getRedirectMerchantAccountURL: (
    kcEnvironmentURL: string,
    redirectURI: string,
    merchantId: string
  ) => {
    const authURL = `${kcEnvironmentURL}/auth/realms/xfers-id/protocol/openid-connect/auth`

    const redirectParams: RedirectQuery = {
      client_id: 'fazzbiz',
      redirect_uri: redirectURI,
      response_type: 'code',
      scope: 'openid',
      kc_action: 'select_group_required_action',
      selected_merchant_id: merchantId,
    }

    const redirectSearchParams = new URLSearchParams([...Object.entries(redirectParams)]).toString()

    return encodeURIComponent(`${authURL}?${redirectSearchParams}`)
  },
  ssoLogin: () => {
    sessionStorage.clear()

    resetBizRegionalStore()
    resetBizStore()

    const currentPath = window.location.pathname + window.location.search || '/'
    window.location.replace(`/sso_login?redirect_url=${encodeURIComponent(currentPath)}`)
  },
  ssoLogout: () => {
    // TODO Kerk(fazzbiz-rollbar): Set user to null
    sessionStorage.clear()

    // reset redux store
    resetBizRegionalStore()
    resetBizStore()

    // Note: FE development still use non-SSO way to login,
    // so only when we are deprecating all the cookies.set and
    // cookies.remove way of login, then deprecate all at the same
    // time once we completely switch to SSO-only login
    // https://github.com/Xfers/xfers/pull/20455

    cookie.remove('SESSIONID')
    window.location.replace('/sso_logout?redirect_url=/')
  },

  ssoSwitchMerchantAccount: (merchantId: string) => {
    if (!merchantId) window.location.replace('/')

    const kcEnvironmentURL = isProduction ? SSOLinks.SsoProdFazz : SSOLinks.SsoStagingFazz
    const redirectURI = window.location.origin || '/'
    const ssoRedirectURL = SessionUtils.getRedirectMerchantAccountURL(
      kcEnvironmentURL,
      redirectURI,
      merchantId
    )

    cookie.remove('SESSIONID')

    // reset redux store
    resetBizRegionalStore()
    // resetBizStore()
    window.location.replace(`/sso_change_account?redirect_url=${ssoRedirectURL}`)
  },
  removeSessionCookies: () => {
    // TODO Kerk(fazzbiz-rollbar): Set user to null
    sessionStorage.clear()

    // reset redux store
    resetBizRegionalStore()
    resetBizStore()

    cookie.remove('SESSIONID')
  },
}

export default SessionUtils
