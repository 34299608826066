import { SVGProps } from 'react'

export default function SvgWarningCircleFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 1a11 11 0 1 0 0 22 11 11 0 0 0 0-22Zm-.79 15.93a1.12 1.12 0 0 1-.35-.84 1.08 1.08 0 0 1 .35-.83A1.16 1.16 0 0 1 12 15a1.2 1.2 0 0 1 .84.31 1.07 1.07 0 0 1 .34.83 1.11 1.11 0 0 1-.34.84 1.2 1.2 0 0 1-.84.32 1.16 1.16 0 0 1-.79-.37ZM11 10.32V6.85h2.05v3.47l-.18 3.47H11.2l-.2-3.47Z"
        fill="currentColor"
      />
    </svg>
  )
}
