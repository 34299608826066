import type { UploadFile } from 'antd/lib/upload/interface'

export enum DisbursementActions {
  CREATE_RECIPIENT = 'CREATE_RECIPIENT',
  BULK_CREATE_RECIPIENTS = 'BULK_CREATE_RECIPIENTS',
  UPDATE_RECIPIENT = 'UPDATE_RECIPIENT',
  DELETE_RECIPIENT = 'DELETE_RECIPIENT',
  CLEAR_ALL_RECIPIENTS = 'CLEAR_ALL_RECIPIENTS',
  CLEAR_DISBURSEMENTS = 'CLEAR_DISBURSEMENTS',
  SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD',
  SET_UPLOAD_METHOD = 'SET_UPLOAD_METHOD',
  SET_UPLOADED_CSV = 'SET_UPLOADED_CSV',
  SET_AVAILABLE_BANKS = 'SET_AVAILABLE_BANKS',
  CONFIRM_DISBURSEMENT_STATEMENT = 'CONFIRM_DISBURSEMENT_STATEMENT',
  SET_DISBURSEMENT_ERROR_MESSAGE = 'SET_DISBURSEMENT_ERROR_MESSAGE',
}

export type State = {
  uploadMethod: UploadMethod
  paymentMethod: PaymentMethod
  recipients: Map<string, Recipient>
  banks: Map<string, Bank>
  confirmedDisbursementStatement: boolean
  uploadedCsv: UploadFile | undefined
  lastUpdated: number
  errorMessage: string | null
}

export enum DisbursementLoadActions {
  LOAD_DISBURSEMENTS = 'LOAD_DISBURSEMENTS',
}

export type Bank = {
  name: string
  abbreviation: string
  imgSrc: string | undefined
  bankCode: string
  swiftCode: string
}

export enum UploadMethod {
  unknown = 'Unknown',
  manual = 'Manual',
  csv = 'CSV',
}

export enum PaymentMethod {
  unknown = 'Unknown',
  deposit = 'Deposit',
  bankTransfer = 'Bank Transfer',
}

export type Amount = {
  currency: string
  value: string
}

export enum RecipientStatus {
  pending = 'Pending',
  success = 'Success',
  failed = 'Failed',
}

export type Recipient = {
  id: string
  status: RecipientStatus
  bank: Bank
  accountNumber: string
  displayName: string
  amount: Amount
  fees: string
  description: string
  errors: Record<string, string>
}

type LoadDisbursement = {
  type: typeof DisbursementLoadActions.LOAD_DISBURSEMENTS
}

type CreateRecipient = {
  type: typeof DisbursementActions.CREATE_RECIPIENT
  recipient: Recipient
}
type BulkCreateRecipients = {
  type: typeof DisbursementActions.BULK_CREATE_RECIPIENTS
  recipients: Recipient[]
}

type UpdateRecipient = {
  type: typeof DisbursementActions.UPDATE_RECIPIENT
  recipient: Recipient
}

type DeleteRecipient = {
  type: typeof DisbursementActions.DELETE_RECIPIENT
  recipientId: string
}

type ClearAllRecipients = {
  type: typeof DisbursementActions.CLEAR_ALL_RECIPIENTS
}

type ClearDisbursements = {
  type: typeof DisbursementActions.CLEAR_DISBURSEMENTS
}

type SetPaymentMethod = {
  type: typeof DisbursementActions.SET_PAYMENT_METHOD
  paymentMethod: PaymentMethod
}

type SetUploadMethod = {
  type: typeof DisbursementActions.SET_UPLOAD_METHOD
  uploadMethod: UploadMethod
}

type SetAvailableBanks = {
  type: typeof DisbursementActions.SET_AVAILABLE_BANKS
  banks: Bank[]
}

type ConfirmDisbursementStatement = {
  type: typeof DisbursementActions.CONFIRM_DISBURSEMENT_STATEMENT
  confirm: boolean
}

type SetUploadedCsv = {
  type: typeof DisbursementActions.SET_UPLOADED_CSV
  value: UploadFile | undefined
}

type SetDisbursementErrorMessage = {
  type: typeof DisbursementActions.SET_DISBURSEMENT_ERROR_MESSAGE
  errorMessage: string | null
}

export type ActionTypes =
  | LoadDisbursement
  | CreateRecipient
  | BulkCreateRecipients
  | DeleteRecipient
  | ClearAllRecipients
  | UpdateRecipient
  | ClearDisbursements
  | SetPaymentMethod
  | SetUploadMethod
  | SetAvailableBanks
  | ConfirmDisbursementStatement
  | SetUploadedCsv
  | SetDisbursementErrorMessage
