export type InvoicingDetails = {
  uniqueId: string
  state: string
  customerName: string
  customerEmail: string
  customerPhoneNumber: string
  createdAt: string
  dueAt: string
  paidAt?: string
  settledAt?: string | null
  expiredAt?: string | null
  customerReference: string
  description: string
  amount: string
  currency: string
  invoiceLink?: string
  paidPaymentMethod: {
    createdAt: string
    paidAt: string
    paymentMethod: string
    settledAt: string
  }
}

export enum InvoicingDetailsActions {
  SET_IS_LOADING = 'InvoicingDetailsActions.SET_IS_LOADING',
  GET_INVOICING_DETAILS = 'InvoicingDetailsActions.GET_INVOICING_DETAILS',
}

type SetIsLoading = {
  type: typeof InvoicingDetailsActions.SET_IS_LOADING
  isLoading: boolean
}

type GetInvoicingDetails = {
  type: typeof InvoicingDetailsActions.GET_INVOICING_DETAILS
  invoicingDetails: InvoicingDetails
}

export type ActionTypes = SetIsLoading | GetInvoicingDetails
