import type { Reducer } from 'redux'
import type {
  ActionTypes,
  BillDetails,
  DisbursementDetails,
  OutstandingRepayment,
  RepaymentDetails,
  SetTermLoanBills,
  SetTermLoanDisbursements,
  SetTermLoanInitialised,
  SetTermLoanOutstandingRepayments,
  SetTermLoanRepayments,
  SetTermLoanUserDetails,
  UserDetails,
} from './types'
import { TermLoanPageActions } from './types'

const initialState = {
  userDetails: {
    id: '',
    status: '',
    balance: 0,
    keycloakId: '',
    businessName: '',
    merchantId: '',
    ownerName: '',
    phoneNumber: '',
    principal: 0,
    cashAdvanceEnabled: false,
    virtualBankAccounts: [
      {
        paymentMethodId: '',
        vANumber: '',
        displayName: '',
        bankShortCode: '',
      },
    ],
    billDetail: {
      outstandingPrincipal: 0,
      totalOutstandingRepayment: 0,
    },
    loanProfile: {
      id: '',
      userId: '',
      limit: 0,
      adminFee: 0,
      adminFeeUnit: '',
      dueType: '',
      cutOff: 0,
      loanType: '',
      entityName: '',
      status: '',
      minimumPaymentPercentage: 0,
      loanTenor: 0,
      tenorUnit: '',
      loanScheme: '',
      subLoanScheme: '',
      dueDate: 0,
      gracePeriod: 0,
      period: 0,
      cashAdvanceTransferFee: 0,
      cashAdvanceTransferFeeMin: 0,
      cashAdvanceTransferFeeMax: 0,
      supplierTransferFee: 0,
      supplierTransferFeeMin: 0,
      supplierTransferFeeMax: 0,
      lateFee: 0,
      lateFeeUnit: '',
      lateFeeMinimum: 0,
      lateFeeMaximum: 0,
      loanApplicationId: '',
      provisionFee: 0,
      provisionFeeMin: 0,
      provisionFeeMax: 0,
      tax: 0,
      interestServicingPeriod: 0,
      sourceOfFund: '',
    },
  },
  bills: [],
  disbursements: [],
  outstandingRepayment: {
    dueRepayments: [],
    accumulativeLateFee: 0,
    accumulativeOtherFee: 0,
    totalOutstandingRepayment: 0,
  },
  repayments: [],
  isTermLoanInitialised: false,
}

type State = {
  userDetails: UserDetails
  bills: BillDetails[]
  disbursements: DisbursementDetails[]
  outstandingRepayment: OutstandingRepayment
  repayments: RepaymentDetails[]
  isTermLoanInitialised: boolean
}

export const termLoan: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case TermLoanPageActions.SET_TERM_LOAN_USER_DETAILS: {
      return {
        ...state,
        userDetails: (action as SetTermLoanUserDetails).userDetails,
      }
    }

    case TermLoanPageActions.SET_TERM_LOAN_BILLS: {
      return {
        ...state,
        bills: (action as SetTermLoanBills).bills,
      }
    }

    case TermLoanPageActions.SET_TERM_LOAN_OUTSTANDING_REPAYMENT: {
      return {
        ...state,
        outstandingRepayment: (action as SetTermLoanOutstandingRepayments).outstandingRepayment,
      }
    }

    case TermLoanPageActions.SET_TERM_LOAN_DISBURSEMENTS: {
      return {
        ...state,
        disbursements: (action as SetTermLoanDisbursements).disbursements,
      }
    }

    case TermLoanPageActions.SET_TERM_LOAN_REPAYMENTS: {
      return {
        ...state,
        repayments: (action as SetTermLoanRepayments).repayments,
      }
    }

    case TermLoanPageActions.SET_TERM_LOAN_INITIALISED: {
      return {
        ...state,
        isTermLoanInitialised: (action as SetTermLoanInitialised).isTermLoanInitialised,
      }
    }

    default: {
      return state
    }
  }
}
