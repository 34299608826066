import styled from 'styled-components'
import { Button } from 'antd'
import { fdsTheme } from '../../../themes/theme-fds3'
import type {
  ButtonColorSchemeType,
  ButtonProps,
  ButtonSizeType,
  ButtonVariantType,
} from './Button.types'

// Retrieve all theming data
const { spacing, colors, radius, fontStyles } = fdsTheme

const getHeight = (size?: ButtonSizeType, variant?: ButtonVariantType) => {
  if (variant === 'plain') return 'initial'
  return size === 'lg' ? '48px' : '36px'
}
const getFontStyle = (size?: ButtonSizeType) =>
  size === 'lg' ? `${fontStyles.labelMedium}` : `${fontStyles.labelSmall}`

const getPadding = (hasIcon: boolean, size?: ButtonSizeType, variant?: ButtonVariantType) => {
  if (variant === 'plain') return `0 0`
  if (size === 'lg' && hasIcon) return `12px ${spacing.md}`
  if (size === 'lg' && !hasIcon) return `14px ${spacing.md}`
  return `9px ${spacing.md}`
}

const getIconSize = (size?: ButtonSizeType) => {
  if (size === 'lg') return `24px`
  return `18px`
}

const getSvgAlignmentSize = (size?: ButtonSizeType) => {
  if (size === 'lg') return `-0.275em`
  return `-0.225em`
}

const getHoverBackgroundColor = (
  variant?: ButtonVariantType,
  colorScheme?: ButtonColorSchemeType
) => {
  switch (variant) {
    case 'primary':
      return colorScheme === 'default' ? colors.hoveredPrimary : colors.hoveredCritical
    case 'secondary':
      return colorScheme === 'default' ? colors.hoveredSecondary : colors.hoveredDestructive
    case 'text':
      return colors.hoveredSurface
    case 'plain':
      return 'transparent'
    default:
      return ''
  }
}

const getHoverTextColor = (variant?: ButtonVariantType, colorScheme?: ButtonColorSchemeType) => {
  switch (variant) {
    case 'primary':
      return colors.onContainer
    case 'secondary':
    case 'text':
      return colorScheme === 'default' ? colors.primary : colors.critical
    case 'plain':
      return colorScheme === 'default' ? colors.hoveredPrimary : colors.hoveredCritical
    default:
      return ''
  }
}

const getBackgroundColor = (variant?: ButtonVariantType, colorScheme?: ButtonColorSchemeType) => {
  switch (variant) {
    case 'primary':
      return colorScheme === 'default' ? colors.primary : colors.critical
    case 'secondary':
      return colorScheme === 'default' ? colors.secondaryButton : colors.secondaryDestructiveButton
    case 'text':
    case 'plain':
      return 'transparent'
    default:
      return ''
  }
}
const getPressedBackgroundColor = (
  variant?: ButtonVariantType,
  colorScheme?: ButtonColorSchemeType
) => {
  switch (variant) {
    case 'primary':
      return colorScheme === 'default' ? colors.pressedPrimrary : colors.pressedCritical
    case 'secondary':
      return colorScheme === 'default' ? colors.pressedSecondary : colors.pressedDestructive
    case 'text':
      return colors.pressedSurface
    default:
      return ''
  }
}

const getDisabledBackgroundColor = (variant?: ButtonVariantType) => {
  switch (variant) {
    case 'primary':
    case 'secondary':
      return colors.disabledButton
    case 'text':
    case 'plain':
      return 'transparent'
    default:
      return ''
  }
}

const getColor = (variant?: ButtonVariantType, colorScheme?: ButtonColorSchemeType) => {
  switch (variant) {
    case 'primary':
      return colors.onContainer
    case 'secondary':
    case 'text':
    case 'plain':
      return colorScheme === 'default' ? colors.primary : colors.critical
    default:
      return ''
  }
}

const getDisabledColor = (variant?: ButtonVariantType) => {
  switch (variant) {
    case 'primary':
    case 'secondary':
      return colors.onContainer
    case 'text':
    case 'plain':
      return colors.disabledButton
    default:
      return ''
  }
}

export const StyledButton = styled(Button).attrs((props: ButtonProps) => ({
  size: props.size,
  variant: props.variant,
  colorScheme: props.colorScheme,
  fullWidth: props.fullWidth,
  hasIcon: !!props.icon,
}))`
  ${({ size, variant, colorScheme, fullWidth, hasIcon }) =>
    `
    &.ant-btn {
      border: none;
      padding: ${getPadding(hasIcon, size, variant)};
      background-color: ${getBackgroundColor(variant, colorScheme)};
      border-radius: ${radius.md};
      box-shadow: none;
      height: ${getHeight(size, variant)};
      width: ${fullWidth ? '100%' : 'initial'};
      min-width: ${variant !== 'plain' ? `80px` : 0};
      color: ${getColor(variant, colorScheme)};
      ${getFontStyle(size)}
      
      // hover
      &:hover {
        color: ${getHoverTextColor(variant, colorScheme)};
        background-color: ${getHoverBackgroundColor(variant, colorScheme)};
      }

      // pressed
      &:active {
        background-color: ${getPressedBackgroundColor(variant, colorScheme)};
      }

      // disabled
      &:disabled {
        background-color: ${getDisabledBackgroundColor(variant)};
        color: ${getDisabledColor(variant)};
      }
      
      // Remove outline for both active and focus
      &:active, &:focus {
        outline: none;
      }

      // Add a border when the button is focused via tab
      &:focus-visible {
        outline: 2px solid ${colors.focusedDefault};
      }

      > svg {
        margin-right: ${spacing.xs};
        font-size: ${getIconSize(size)};
        width:${getIconSize(size)};
        height:${getIconSize(size)};
        color: inherit;
        vertical-align: ${getSvgAlignmentSize(size)};
      }
  `}
`
