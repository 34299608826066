import type { Reducer } from 'redux'
import type {
  ActionTypes,
  SCREENS,
  ImmigrationStatus,
  ResidencyStatus,
  VerificationFlows,
  MyInfoOnlyDetails,
  FormOptions,
  PhysicalCardDetails,
} from './types'
import { VerificationActions } from './types'

const initialState = {
  userDetails: {
    fullName: undefined,
    dateOfBirth: undefined,
    countryOfBirth: undefined,
    nationality: undefined,
    gender: undefined,
    addressLine1: undefined,
    addressLine2: undefined,
    country: undefined,
    city: undefined,
    postalCode: undefined,
    idNumber: undefined,
    dateOfIssue: undefined,
    expiryDate: undefined,
    occupation: undefined,
    employmentSector: undefined,
    employer: undefined,
    expectedTransactionAmount: undefined,
    expectedTotalTransaction: undefined,
    expectedTransactionFrequency: undefined,
  },
  myInfoOnlyDetails: {
    race: undefined,
    residentialStatus: undefined,
    aliasName: undefined,
    passStatusLastUpdated: undefined,
    error: undefined,
  },
  immigrationStatus: undefined,
  residencyStatus: undefined,
  flow: undefined,
  isIdTypeSgPr: true,
  isResidencyNonSg: false,
  isInternationalOnboarding: false,
  error: '',
  screen: undefined,
  kycRejectedReason: '',
  retrievedDetails: undefined,
  formOptions: {
    countries: [],
    nationalities: [],
    races: [],
    genders: [],
    occupations: [],
    employmentSectors: [],
    annualIncomes: [],
    expectedTransactionAmounts: [],
    expectedTotalTransactions: [],
    expectedTransactionFrequencies: [],
  },
  physicalCardLoading: false,
  physicalCardData: {
    citizenship: undefined,
    residency: undefined,
    verificationMethod: undefined,
    fullName: undefined,
    dateOfBirth: undefined,
    gender: undefined,
    nationality: undefined,
    addressLine1: undefined,
    addressLine2: undefined,
    country: undefined,
    city: undefined,
    kycState: undefined,
    postalCode: undefined,
    nricIdNumber: undefined,
    nricIssueDate: undefined,
    nricExpiryDate: undefined,
    passportNumber: undefined,
    passportIssueDate: undefined,
    passportExpiryDate: undefined,
    externalId: undefined,
    idBack: undefined,
    idFront: undefined,
    passport: undefined,
    proofOfAddress: undefined,
    selfie: undefined,
  },
  physicalCardKYCState: '',
}

type State = {
  immigrationStatus: ImmigrationStatus
  residencyStatus: ResidencyStatus
  flow: VerificationFlows
  isIdTypeSgPr: boolean
  isResidencyNonSg: boolean
  isInternationalOnboarding: boolean
  error: string
  screen?: SCREENS
  myInfoOnlyDetails: MyInfoOnlyDetails
  formOptions: FormOptions
  physicalCardLoading?: boolean
  physicalCardData?: PhysicalCardDetails
  physicalCardKYCState?: string
}

export const physicalCardholder: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case VerificationActions.SET_IMMIGRATION_STATUS: {
      return {
        ...state,
        immigrationStatus: action.status,
        isInternationalOnboarding:
          action.status === 'singapore_foreigner' && state.isResidencyNonSg,
      }
    }

    case VerificationActions.SET_RESIDENCY_STATUS: {
      return {
        ...state,
        residencyStatus: action.status,
        isResidencyNonSg: action.status === 'living_outside_singapore',
        isInternationalOnboarding:
          state.immigrationStatus === 'singapore_foreigner' &&
          action.status === 'living_outside_singapore',
      }
    }

    case VerificationActions.SET_FLOW: {
      return {
        ...state,
        flow: action.flow,
      }
    }

    case VerificationActions.SET_IS_ID_TYPE_SG_PR: {
      return {
        ...state,
        isIdTypeSgPr: action.isIdTypeSgPr,
      }
    }

    case VerificationActions.SET_SCREEN: {
      return {
        ...state,
        screen: action.screen,
      }
    }

    case VerificationActions.SET_ERROR: {
      const errorMsg =
        action.error === 'Same request is still processing'
          ? 'Please try again in a minute as we are still processing.'
          : action.error
      return {
        ...state,
        error: errorMsg,
      }
    }

    case VerificationActions.SET_MY_INFO_ONLY_DETAILS: {
      return {
        ...state,
        myInfoOnlyDetails: action.myInfoOnlyDetails,
      }
    }

    case VerificationActions.SET_FORM_OPTIONS: {
      return {
        ...state,
        formOptions: action.formOptions,
      }
    }

    case VerificationActions.SET_PHYSICAL_CARD_LOADING: {
      return {
        ...state,
        physicalCardLoading: action.physicalCardLoading,
      }
    }

    case VerificationActions.SET_PHYSICAL_CARD_DATA: {
      return {
        ...state,
        physicalCardData: action.physicalCardData,
      }
    }

    case VerificationActions.SET_PHYSICAL_CARD_KYC_STATE: {
      return {
        ...state,
        physicalCardKYCState: action.physicalCardKYCState,
      }
    }

    default:
      return state
  }
}
