import { SVGProps } from 'react'

function SvgInfoFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 15c0 7.18-5.82 13-13 13S2 22.18 2 15 7.82 2 15 2s13 5.82 13 13zm-11-5a2 2 0 11-4 0 2 2 0 014 0zm-4 6v5a2 2 0 104 0v-5a2 2 0 10-4 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgInfoFilled
