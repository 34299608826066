import { SVGProps } from 'react'

function SvgSendInvitationFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.36.32a2.23 2.23 0 0 0-.86.17L1.79 7a2.34 2.34 0 0 0 0 4.35l5.45 2.18.11.06 6.28-6.15a.753.753 0 1 1 1.05 1.08l-6.27 6.15c.034.061.064.125.09.19l2.13 5.35a2.35 2.35 0 0 0 4.36 0l6.54-16.69a2.35 2.35 0 0 0-2.17-3.2Z"
      fill="#fff"
    />
  </svg>
  )
}

export default SvgSendInvitationFilled
