import * as React from 'react'
import { SVGProps } from 'react'

function SvgDana(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 64 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.13326 3.05716C6.16183 3.28573 3.76183 4.88573 2.27611 7.34288C1.30468 8.94288 0.904684 10.7714 1.01897 12.7143C1.36183 17.4 5.41897 21.4 10.5618 21C15.1904 20.6572 19.019 16.4857 18.619 11.3429C18.4475 9.45716 17.819 7.91431 16.7904 6.54288C16.3333 5.9143 16.219 5.9143 15.7047 5.40002C15.0761 4.77145 13.819 4.02859 12.9618 3.68573C11.9333 3.17145 10.5047 2.88573 9.13326 3.05716Z"
        fill="#2A84C6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.73305 9.3999C4.61876 9.74276 4.67591 13.3428 4.67591 14.0285C4.67591 14.7713 4.50448 14.9999 5.64734 15.3428C8.67591 16.3713 11.6473 12.8856 14.6188 14.5999C14.733 14.657 14.9045 14.8285 14.9616 14.5999V9.91419C14.9616 9.3999 15.0188 9.22848 14.6759 8.9999C11.9902 7.11419 9.07591 10.3142 6.39019 9.97133C5.41876 9.85705 5.01876 9.34276 4.73305 9.3999Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4193 16.543C26.6193 16.6001 27.9335 15.743 28.4478 15.1716C30.105 13.343 30.1621 10.7144 28.505 8.8287C27.8764 8.14298 26.3907 7.34298 25.4764 7.45727H22.105V16.4858L25.4193 16.543ZM23.9335 14.6001V9.45727C24.1621 9.40013 25.2478 9.45727 25.5336 9.45727C25.9907 9.51441 26.3907 9.68584 26.6764 9.91441C28.6193 11.2287 27.8193 14.3716 25.4764 14.6001C25.2478 14.6001 24.2193 14.6573 23.9335 14.6001Z"
        fill="#2A84C6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.019 16.5999H56.9619V14.657H60.7905V16.5999H62.7333C62.7333 15.5142 62.7333 14.4284 62.7333 13.3427C62.7333 12.3713 62.7905 11.1713 62.5619 10.3142C62.1619 8.82845 60.7333 7.51416 58.9048 7.51416C57.019 7.51416 55.6476 8.82845 55.2476 10.257C54.9619 11.1142 55.0762 12.3713 55.0762 13.3427C55.019 14.3713 55.019 15.5142 55.019 16.5999ZM56.9619 12.657C56.9619 11.857 56.8476 10.9999 57.2476 10.3713C57.5333 9.91416 58.1048 9.45702 58.9048 9.45702C59.7048 9.45702 60.2762 9.91416 60.5619 10.3713C60.9619 10.9999 60.8476 11.7999 60.8476 12.657H56.9619Z"
        fill="#2A84C6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.019 16.5997H34.9619C34.9619 16.1997 34.9048 14.9425 34.9619 14.6568H38.7905V16.5997H40.7333C40.7333 15.514 40.7333 14.4282 40.7333 13.3425C40.7333 12.4282 40.7905 11.114 40.5619 10.314C39.4762 6.59968 34.3333 6.54253 33.1905 10.2568C32.9619 11.114 33.019 12.3711 33.019 13.2854C33.019 14.3711 33.019 15.514 33.019 16.5997ZM34.9619 12.6568C34.9619 11.8568 34.8476 10.9997 35.2476 10.3711C36.1048 9.05682 37.819 9.22825 38.5048 10.3711C38.9048 10.9997 38.7905 11.8568 38.7905 12.6568H34.9619Z"
        fill="#2A84C6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.105 11.0571V12.4286C44.105 13.5714 44.0478 15.6857 44.105 16.6H45.9907C46.0478 15.9714 45.9336 11.3428 46.0478 11.2286C46.0478 10.3143 46.9621 9.51427 47.9336 9.51427C48.505 9.51427 48.905 9.74284 49.2478 10.0286C49.4764 10.2571 49.8764 10.7714 49.8193 11.2286C49.9336 11.2857 49.8193 16.0857 49.8764 16.6H51.7621V11.1143C51.8193 10.6571 51.5336 10.0286 51.3621 9.6857C49.9907 6.82856 45.9907 6.82856 44.5621 9.62856C44.3336 9.91427 44.0478 10.6 44.105 11.0571Z"
        fill="#2A84C6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4192 16.5428H22.0477V7.51418H25.4192C25.362 7.39989 23.9335 7.45704 23.762 7.45704C23.2477 7.45704 22.5049 7.39989 22.0477 7.45704C21.9906 7.62847 21.9906 16.3142 22.0477 16.5428C22.3335 16.5999 24.5049 16.5428 25.1906 16.5428H25.4192Z"
        fill="#74B9E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.7618 11.1714C49.7618 11.9143 49.7047 16.3143 49.819 16.6H51.7618C51.819 16.3714 51.7618 12.9428 51.7618 12.4286C51.7618 12.2 51.819 11.2286 51.7047 11.0571V16.5428H49.819C49.7618 16.0286 49.8761 11.2286 49.7618 11.1714Z"
        fill="#74B9E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9907 11.1714C45.8764 11.3429 45.9907 15.9143 45.9336 16.5429H44.0479C43.9907 15.6286 44.0479 13.5143 44.0479 12.3714V11C43.9336 11.0571 43.9907 13.4571 43.9907 13.7429C43.9907 14.2 43.9336 16.2571 43.9907 16.5429H45.9336L45.9907 11.1714Z"
        fill="#74B9E6"
      />
    </svg>
  )
}

export default SvgDana
