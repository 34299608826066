/** @deprecated please use fazzBizzThemeFds3 colors theme instead */
export enum BLUE {
  DEFAULT = '#2F8DEE',
  T10 = '#5FA8F2',
  T20 = '#8EC2F6',
  T40 = '#ECF5FD',
  S400 = '#1863B0',
  S300 = '#3585E6',
  S100 = '#EBF5FF',
}
