import * as React from 'react'
import { SVGProps } from 'react'

function SvgSendInvitationTwoTone(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.411 12.47a.9.9 0 0 1 .106-1.269l1.5-1.269a.9.9 0 1 1 1.162 1.374l-1.5 1.27a.9.9 0 0 1-1.268-.106Z"
        fill="#2F8DEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.546 1.475a.9.9 0 0 1 .186.964l-4.94 12.137a.9.9 0 0 1-1.468.299l-1.073-1.066-4.697.29a.9.9 0 0 1-.692-1.535l2.061-2.061L2.71 7.339a.9.9 0 0 1 .322-1.487l12.557-4.597a.9.9 0 0 1 .957.22ZM7.196 9.23l4.073-4.073A.9.9 0 0 1 12.54 6.43l-5.725 5.726 1.308-.081a.9.9 0 0 1 1.508-.424l1.002.995 3.664-9.002-9.312 3.41 2.21 2.176Z"
        fill="#1B2736"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.496 14.701a.9.9 0 0 1-.074 1.271l-.673.6a.9.9 0 1 1-1.197-1.344l.673-.6a.9.9 0 0 1 1.27.073Z"
        fill="#2F8DEE"
      />
    </svg>
  )
}

export default SvgSendInvitationTwoTone
