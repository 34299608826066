import type { CallBackProps } from 'react-joyride'
import Joyride from 'react-joyride'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, fdsTheme } from '@fazz/design-system'
import { generatePath, useHistory } from '~/hooks/useReactRouter'
import { PATHS } from '~/biz/routes/paths'
import {
  toogleProductTourVisibility,
  setStepIndex,
  toogleProductTourActive,
} from '~/biz/store/onboardingFlow/actions'
import type { RootState } from '~/biz/store/types'
import { putUpdateProductTour } from '~/api/onboardingFlow/onboarding.api'
import type { AvailableProductTourStepsType } from '~/api/onboardingFlow/onboarding.types'
import TooltipComponent from './TooltipComponent'

const { Body } = Typography
const { colors } = fdsTheme

type StepsOptionType = {
  name: AvailableProductTourStepsType
  path: PATHS | string
}

export const stepsOption: StepsOptionType[] = [
  {
    name: 'idr_cash_account',
    path: PATHS.ACCOUNT_STATEMENT,
  },
  {
    name: 'payout_form',
    path: generatePath(PATHS.PAY_TRANSACTIONS, { tab: 'idr_cash_account' }),
  },
  { name: 'recipient', path: PATHS.PAY_RECIPIENTS },
  { name: 'payment_link', path: PATHS.INVOICING },
  { name: 'loan_dashboard', path: PATHS.LOAN_ACCOUNT_NEW_NO_TAB },
  { name: 'main_dashboard_ending', path: PATHS.LANDING_PAGE },
]

function ProductTour() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { stepIndex, isProductTourShowing, isProductTourActive, productTourId } = useSelector(
    (state: RootState) => state.onboardingFlow
  )

  const { mutate: updateProductTourStatus } = useMutation(
    ({
      skip,
      currentStep,
      nextStep,
    }: {
      currentStep: AvailableProductTourStepsType
      nextStep: AvailableProductTourStepsType
      skip: boolean
    }) =>
      putUpdateProductTour({
        id: productTourId,
        payload: {
          skipProductTour: skip,
          currentStep,
          nextStep,
        },
      })
  )

  const steps = [
    {
      target: '#product-tour-steps-business-account',
      disableBeacon: true,
      title: 'Penjelasan Fitur Akun Bisnis',
      content: (
        <Body size="sm" color={colors.backgroundNeutral}>
          Anda dapat melakukan penambahan dana Akun Bisnis dan melakukan penarikan dana pada halaman
          ini.
        </Body>
      ),
    },
    {
      target: '#product-tour-steps-transfer',
      disableBeacon: true,
      title: 'Penjelasan Fitur Transfer',
      content: (
        <Body size="sm" color={colors.backgroundNeutral}>
          Melalui Payout Form, Anda dapat melakukan transfer dana ke seluruh Bank di Indonesia dan
          melihat status & riwayat transfer.
        </Body>
      ),
    },
    {
      target: '#product-tour-steps-recipient',
      disableBeacon: true,
      title: 'Penjelasan Fitur Penerima',
      content: (
        <Body size="sm" color={colors.backgroundNeutral}>
          Anda dapat menyimpan informasi terkait nama dan nomor rekening tujuan melalui fitur
          Penerima.
        </Body>
      ),
    },
    {
      target: '#product-tour-steps-invoice',
      disableBeacon: true,
      title: 'Penjelasan Fitur Payment Link',
      content: (
        <Body size="sm" color={colors.backgroundNeutral}>
          Kirim tagihan ke pelanggan Anda dengan mudah. Pelanggan Anda dapat melakukan pembayaran
          melalui VA atau E-Wallet.
        </Body>
      ),
    },
    {
      target: '#product-tour-steps-loan-account',
      disableBeacon: true,
      title: 'Penjelasan Fitur Pinjaman',
      content: (
        <Body size="sm" color={colors.backgroundNeutral}>
          Ajukan pinjaman hingga IDR 2 milyar untuk bisnis Anda. Proses pencairan hanya dengan
          menyertakan Invoice atau Purchase Order.
        </Body>
      ),
    },
    {
      target: '#product-tour-steps-landing-page',
      disableBeacon: true,
      title: 'Verifikasi Akun Anda',
      content: (
        <Body size="sm" color={colors.backgroundNeutral}>
          Selesaikan proses verifikasi segera untuk mendapatkan kemudahan pengelolaan bisnis Anda
          bersama Fazz Business!
        </Body>
      ),
    },
  ]

  const handleCallback = (data: CallBackProps) => {
    const { action, type } = data

    if (type === 'tour:end') {
      updateProductTourStatus({
        currentStep: stepsOption[stepIndex].name,
        nextStep: stepsOption[stepIndex].name,
        skip: true,
      })
      dispatch(toogleProductTourActive(false))
      history.push(PATHS.LANDING_PAGE)
    }

    if (type === 'step:after') {
      if (action === 'prev') {
        updateProductTourStatus({
          currentStep: stepsOption[stepIndex].name,
          nextStep: stepsOption[stepIndex - 1].name,
          skip: false,
        })
        dispatch(setStepIndex(stepIndex - 1))
        history.push(stepsOption[stepIndex - 1].path)
        dispatch(toogleProductTourVisibility(false))
      }

      if (action === 'next') {
        updateProductTourStatus({
          currentStep: stepsOption[stepIndex].name,
          nextStep:
            stepIndex === stepsOption.length - 1
              ? stepsOption[stepIndex].name
              : stepsOption[stepIndex + 1].name,
          skip: false,
        })
        if (stepIndex !== stepsOption.length - 1) {
          history.push(stepsOption[stepIndex + 1].path)
          dispatch(setStepIndex(stepIndex + 1))
        } else {
          dispatch(toogleProductTourActive(false))
        }
        dispatch(toogleProductTourVisibility(false))
      }
    }
  }

  return (
    <Joyride
      disableCloseOnEsc
      disableScrolling
      disableScrollParentFix
      disableOverlayClose
      run={isProductTourShowing && isProductTourActive}
      continuous
      spotlightPadding={0}
      stepIndex={stepIndex}
      callback={handleCallback}
      steps={steps}
      tooltipComponent={TooltipComponent}
      styles={{
        options: {
          arrowColor: colors.onNeutral,
        },
      }}
    />
  )
}

export default ProductTour
