export type TransactionDetails = {
  key: string
  transactionId: string
  transactionType: string
  transactionHash?: string
  transactionHashUrl?: string
  walletType: string
  method: string
  accountHolderName?: string
  amount: { prefix: string; postfix: string }
  fee: string
  tax?: string
  netAmount: { prefix: string; postfix: string }
  createdDate: string
  completedDate: string
  status: string
  note?: string
  sourceDestination?: { source: string; destination: string } | null
  merchantDescription?: string
  recipientNotes?: string
  failureReason?: string
  blockedReasons?: string
  manualAssignments?: Array<{
    createdAt: string
    amount: { prefix: string; postfix: string }
    status: string
    externalId: string
  }>
  network?: string
  bankAccountNo?: string
  bankName?: string
  description?: Record<string, string>
  refId: string
  remarks: string
  cardHolderName?: string
  cardNumber?: string
  merchant?: string
  details: {
    method: string
    customerProfile: {
      senderName: string
    }
    receiver?: {
      accountHolderName: string
      bankType: string
      bankAccountNo: string
      endToEndId: string
    }
    sender?: {
      accountHolderName: string
      bankType: string
      bankAccountNo: string
    }
    instructions: {
      proxyType: string
      proxyValue: string
    }
    externalPayment: {
      messageId: string
      purposeCode: string
    }
  }
}

// TODO: Need to figure out all the union types so that can have intellisense. Currently generically typed as string.
export type TransactionDetailsNew = {
  key: number
  transactionId: string
  createdAt: Date
  completedAt: null
  description: {
    type: string
    direction: string
    data: string
  }
  amount: AmountPrefixPostfix
  netAmount: AmountPrefixPostfix
  transactionStatus: string
  fee: string
  feeAmount: string
  tax: string
  walletType: string
  blockedReasons: string
  uniqueId: string
  currency: {
    source: string
    destination: string
  }
  sourceDestination: {
    source: string
    destination: string
  }
  merchantDescription: string
  network: string
  accountHolderName: string
  failureReason: string
  sender: SenderReceiver
  receiver: SenderReceiver
  chargeType: string
  isXfersDash: boolean
  details: GeneralTransactionDetails & Partial<PayNowTransactionDetails>
  method: string
  paymentId: string
  transactionType: string
  remarks: string
  cardHolderName: string
  cardNumber: string
  merchant: string
}

type GeneralTransactionDetails = {
  sender: DetailsSenderReceiver
  receiver: DetailsSenderReceiver
  method: string
  error: string | null
}

type PayNowTransactionDetails = {
  instructions: {
    proxyType: string
    proxyValue: string
  }
  externalPayment: {
    messageId: string
    purposeCode: string
  }
}

type SenderReceiver = {
  accountHolderName: string
  bankType: string
  bankAccountNo: string
}

type DetailsSenderReceiver = {
  accountHolderName: string
  bankShortCode: string
  accountNo: string
  endToEndId?: string
}

type AmountPrefixPostfix = {
  prefix: string
  postfix: string
}

export enum TransactionDetailActions {
  SET_IS_LOADING = 'TransactionDetailActions.SET_IS_LOADING',
  GET_TRANSACTION_DETAILS = 'TransactionDetailActions.GET_TRANSACTION_DETAILS',
}

type SetIsLoading = {
  type: typeof TransactionDetailActions.SET_IS_LOADING
  isLoading: boolean
}

type GetTransactionDetails = {
  type: typeof TransactionDetailActions.GET_TRANSACTION_DETAILS
  transactionDetails: TransactionDetails
}

export type ActionTypes = SetIsLoading | GetTransactionDetails
