import React, { useState } from 'react'
import styled from '@emotion/styled'
import { ArrowUp } from '@fazz/design-system-icons'
import { DESKTOP_BREAKPOINT } from '../../../themes/deprecated/breakPoints'
import Divider from '../../deprecated/Divider'
import { Body } from '../../deprecated/Typography'

type AccordionProps = {
  header: React.ReactNode
  body: React.ReactNode
  defaultOpen?: boolean
  headerHeightDesktop?: number
  headerHeightMobile?: number
  toggleText?: string
  onCloseToggleText?: string
  additionalHeaderItem?: React.ReactNode
  withBoxShadow?: boolean
  isV2?: boolean
  style?: React.CSSProperties
}

export default function Accordion({
  header,
  body,
  defaultOpen = false,
  headerHeightDesktop = 95,
  headerHeightMobile = 95,
  toggleText,
  onCloseToggleText = toggleText,
  additionalHeaderItem,
  withBoxShadow = false,
  isV2 = false,
  style,
}: AccordionProps) {
  // eslint-disable-next-line @typescript-eslint/typedef
  const [open, setOpen] = useState<boolean>(defaultOpen)
  const handleClick = () => setOpen((prev) => !prev)

  return (
    <Container
      open={open}
      headerHeightDesktop={headerHeightDesktop}
      headerHeightMobile={headerHeightMobile}
      withBoxShadow={withBoxShadow}
      isV2={isV2}
      style={style}
    >
      <HeaderGroup
        onClick={handleClick}
        headerHeightDesktop={headerHeightDesktop}
        headerHeightMobile={headerHeightMobile}
        isV2={isV2}
      >
        <Header isV2={isV2}>
          {header}
          <ToggleGroup>
            {toggleText && (
              <ToggleText isV2={isV2}>{open ? onCloseToggleText : toggleText}</ToggleText>
            )}
            <ToggleArrow open={open} />
          </ToggleGroup>
        </Header>
        {additionalHeaderItem && additionalHeaderItem}
      </HeaderGroup>
      {!isV2 && <Divider />}
      <div>{body}</div>
    </Container>
  )
}

const Container = styled.div<{
  open: boolean
  headerHeightDesktop: number
  headerHeightMobile: number
  withBoxShadow: boolean
  isV2: boolean
}>`
  background: #ffffff;
  border: ${(props) => !props.withBoxShadow && `1px solid #e8e8e8`};
  border-radius: 5px;

  box-shadow: ${(props) => props.withBoxShadow && `0px 4px 15px rgba(149, 149, 149, 0.25)`};

  transition: max-height 0.3s ease;

  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  padding: ${(props) => (props.isV2 ? `0px` : `0px 30px 0px 30px`)};

  max-height: ${(props) => props.headerHeightMobile}px;

  ${DESKTOP_BREAKPOINT} {
    max-height: ${(props) => props.headerHeightDesktop}px;
  }

  ${(props) =>
    props.open &&
    `
      max-height: 2000px !important;
      ${DESKTOP_BREAKPOINT} {
        max-height: 1200px !important;
      }
    `}
`

const HeaderGroup = styled.div<{
  headerHeightDesktop: number
  headerHeightMobile: number
  isV2: boolean
}>`
  cursor: pointer;
  padding: 16px;
  padding: ${(props) => (props.isV2 ? `16px` : `30px 0px`)};
  height: ${(props) => props.headerHeightMobile}px;

  ${DESKTOP_BREAKPOINT} {
    height: ${(props) => props.headerHeightDesktop}px;
  }
`

const Header = styled.div<{ isV2: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${(props) => props.isV2 && `100%`};
`

const ToggleText = styled(Body)<{ isV2: boolean }>`
  display: none;
  color: ${(props) => props.isV2 && `#757575`};
  font-size: ${(props) => props.isV2 && `14px`};
  ${DESKTOP_BREAKPOINT} {
    display: inline;
    margin: 0px 12px 0px 0px;
  }
`

const ToggleArrow = styled(ArrowUp)<{ open: boolean }>`
  width: 25px;
  height: 25px;
  transform: rotate(180deg);
  transition: transform 0.3s ease;
  transform: ${(props) => props.open && `rotate(0deg)`};
`

const ToggleGroup = styled.div`
  display: flex;
  align-items: center;
`
