import type { Reducer } from 'redux'
import type {
  ActionTypes,
  IndoTransactionDetails,
  FilterValues,
  DisbursementDashDetails,
  DisbursementDashContract,
  DisbursementDashFilterOptions,
} from './types'
import { IndoTransactionDetailsActions } from './types'

const initialState = {
  isLoading: false,
  transactionId: '',
  indoTransactionDetails: {
    key: '',
    transactionId: '',
    transactionType: '',
    walletType: '',
    method: '',
    accountHolderName: '',
    amount: '',
    fee: '',
    tax: '',
    netAmount: '',
    description: {
      data: {
        isRecurring: false,
        cardNumber: '',
        expiryDate: '',
        cardType: '',
        type: '',
      },
      direction: '',
      paymentId: '',
    },
    createdDate: '',
    completedDate: '',
    status: '',
    note: null,
    sourceDestination: null,
    merchantDescription: null,
    manualAssignment: null,
    accountNo: null,
    bankName: null,
  },
  filterValues: {
    statuses: [],
    searchString: '',
    currentPage: 1,
    pageLimit: 10,
  },
  disbursementDashFilterOptions: null,
  disbursementDashDetails: {
    totalCount: 0,
    transactions: [],
    filteredTransactions: [],
    contractsExist: false,
    transactionType: 'Disbursement Dash',
    method: 'Bank Transfer',
  },
  selectedDisbursementDashContract: {
    key: '',
    refId: '',
    amount: '',
    createdAt: '',
    contractCount: '',
    requester: '',
    batchStatus: '',
    approvedAt: null,
    rejectedAt: null,
  },
}

type State = {
  isLoading: boolean
  transactionId: string
  indoTransactionDetails: IndoTransactionDetails
  filterValues: FilterValues
  disbursementDashDetails: DisbursementDashDetails
  selectedDisbursementDashContract: DisbursementDashContract
  disbursementDashFilterOptions: DisbursementDashFilterOptions | null
}

export const indoTransactionDetails: Reducer<State, ActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case IndoTransactionDetailsActions.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }

    case IndoTransactionDetailsActions.GET_INDO_TRANSACTION_DETAILS: {
      return {
        ...state,
        indoTransactionDetails: action.indoTransactionDetails,
      }
    }

    case IndoTransactionDetailsActions.SET_SELECTED_DISBURSEMENT_DASH_CONTRACT: {
      return {
        ...state,
        selectedDisbursementDashContract: action.selectedDisbursementDashContract,
      }
    }

    case IndoTransactionDetailsActions.GET_INDO_TRANSACTION_DETAILS_DISBURSEMENT_DASH: {
      return {
        ...state,
        disbursementDashDetails: action.disbursementDashDetails,
      }
    }

    case IndoTransactionDetailsActions.GET_DISBURSEMENT_DASH_FILTER_OPTIONS: {
      return {
        ...state,
        disbursementDashFilterOptions: action.disbursementDashFilterOptions,
      }
    }

    case IndoTransactionDetailsActions.SET_FILTERS: {
      const { filterValuesObject } = action

      const newTabFilterValues: FilterValues = {
        ...state.filterValues,
      }

      Object.keys(filterValuesObject).forEach((key: string) => {
        newTabFilterValues[key] = filterValuesObject[key]
      })

      return {
        ...state,
        filterValues: {
          ...newTabFilterValues,
        },
      }
    }

    case IndoTransactionDetailsActions.GET_FILTERED_DISBURSEMENT_DASH_TRANSACTIONS: {
      return {
        ...state,
        disbursementDashDetails: {
          ...state.disbursementDashDetails,
          filteredTransactions: action.filteredTransactions,
        },
      }
    }

    default:
      return state
  }
}
