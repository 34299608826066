import { Provider as RollbarProvider, ErrorBoundary, LEVEL_CRITICAL } from '@rollbar/react'
import { useEffect } from 'react'
import { clarity } from 'react-microsoft-clarity'
import { QueryClientProvider, QueryClient, QueryCache } from 'react-query'
import 'antd/dist/antd.css'
import { Toast } from '@fazz/design-system'
import { ConfigManager, isProduction } from '~/managers/ConfigManager'
import SessionUtils from '~/utils/SessionUtils'
import Root from './Root'
import { rollbarConfig } from './common/Rollbar/rollbarConfig'
import ErrorPage from './components/ErrorPage'
import PageLoader from './components/PageLoader'
import { AppTypeProvider } from './hooks/useAppTypeContext'

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onSuccess: (_, query) => {
      if (query?.meta?.successMessage) {
        Toast({ type: 'positive', description: query.meta.successMessage })
      }
    },
    onError: (_, query) => {
      if (query?.meta?.errorMessage) {
        Toast({ type: 'critical', description: query.meta.errorMessage })
      }
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

const App = function App() {
  useEffect(() => {
    if (!SessionUtils.isLoggedIn()) {
      SessionUtils.ssoLogin()
    }
  }, [])

  if (!SessionUtils.isLoggedIn()) {
    return <PageLoader />
  }

  if (isProduction) {
    clarity.init('i9vo1wozai')
  }

  ConfigManager.setupGTM()

  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary
        errorMessage="Error in React render"
        level={LEVEL_CRITICAL}
        fallbackUI={() => <ErrorPage />}
      >
        <QueryClientProvider client={queryClient}>
          <AppTypeProvider>
            <Root />
          </AppTypeProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </RollbarProvider>
  )
}

export default App