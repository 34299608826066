import type { VerificationStatusType } from '~/types'

export type Tasks = { title: string; description: string; type: TaskType; action: string }

export enum TaskType {
  BALANCE_NOTIFICATION = 'balance_notification',
  TRANSFER_OUT_APPROVAL = 'transfer_out_approval',
  CARD_APPROVAL = 'card_approval',
}

export type PendingTasks = {
  tasks: Tasks[]
  isLoading: boolean
}

export type UsdAccountNumberInfo = {
  accountNumber: string
  isLoading: boolean
}

export type AccountInfo = {
  balance: string | null
  isLoading: boolean
}

export enum NewLandingPageActions {
  SET_SGD_ACCOUNT = 'NEW_LANDING_PAGE_ACTIONS.SET_SGD_ACCOUNT',
  SET_IS_SGD_ACCOUNT_LOADING = 'NEW_LANDING_PAGE_ACTIONS.SET_IS_SGD_ACCOUNT_LOADING',
  SET_USD_ACCOUNT = 'NEW_LANDING_PAGE_ACTIONS.SET_USD_ACCOUNT',
  SET_IS_USD_ACCOUNT_LOADING = 'NEW_LANDING_PAGE_ACTIONS.SET_IS_USD_ACCOUNT_LOADING',
  SET_EARN_ACCOUNT = 'NEW_LANDING_PAGE_ACTIONS.SET_EARN_ACCOUNT',
  SET_IS_EARN_ACCOUNT_LOADING = 'NEW_LANDING_PAGE_ACTIONS.SET_IS_EARN_ACCOUNT_LOADING',
  SET_PENDING_TASKS = 'NEW_LANDING_PAGE_ACTIONS.SET_PENDING_TASKS',
  SET_IS_PENDING_TASKS_LOADING = 'NEW_LANDING_PAGE_ACTIONS.SET_IS_PENDING_TASKS_LOADING',
  SET_USD_ACCOUNT_NUMBER = 'NEW_LANDING_PAGE_ACTIONS.SET_USD_ACCOUNT_NUMBER',
  SET_USD_ACCOUNT_NUMBER_LOADING = 'NEW_LANDING_PAGE_ACTIONS.SET_USD_ACCOUNT_NUMBER_LOADING',
  SET_SGD_ACCOUNT_IS_INITIATED = 'NEW_LANDING_PAGE_ACTIONS.SET_SGD_ACCOUNT_IS_INITIATED',
  SET_EARN_ACCOUNT_IS_INITIATED = 'NEW_LANDING_PAGE_ACTIONS.SET_EARN_ACCOUNT_IS_INITIATED',
  SET_DISMISS_HOMEPAGE_BANNER = 'SET_DISMISS_HOMEPAGE_BANNER',
  SET_OWNER_KYC_STATUS = 'LandingPageActions.SET_OWNER_KYC_STATUS',
  SET_BUSINESS_KYB_STATUS = 'LandingPageActions.SET_BUSINESS_KYB_STATUS',
}

type SetSGDAccount = {
  type: typeof NewLandingPageActions.SET_SGD_ACCOUNT
  balance: string | null
}

type SetIsSGDAccountLoading = {
  type: typeof NewLandingPageActions.SET_IS_SGD_ACCOUNT_LOADING
  isLoading: boolean
}

type SetUSDAccount = {
  type: typeof NewLandingPageActions.SET_USD_ACCOUNT
  balance: string | null
}

type SetIsUSDAccountLoading = {
  type: typeof NewLandingPageActions.SET_IS_USD_ACCOUNT_LOADING
  isLoading: boolean
}

type SetUSDAccountNumber = {
  type: typeof NewLandingPageActions.SET_USD_ACCOUNT_NUMBER
  accountNumber: string
}

type SetUSDAccountNumberLoading = {
  type: typeof NewLandingPageActions.SET_USD_ACCOUNT_NUMBER_LOADING
  isLoading: boolean
}

type SetEarnAccount = {
  type: typeof NewLandingPageActions.SET_EARN_ACCOUNT
  balance: string | null
}

type SetIsEarnAccountLoading = {
  type: typeof NewLandingPageActions.SET_IS_EARN_ACCOUNT_LOADING
  isLoading: boolean
}

type SetPendingTasks = {
  type: typeof NewLandingPageActions.SET_PENDING_TASKS
  pendingTasks: Tasks[]
}

type SetIsPendingTasksLoading = {
  type: typeof NewLandingPageActions.SET_IS_PENDING_TASKS_LOADING
  isLoading: boolean
}

type SetSGDAccountIsInitiated = {
  type: typeof NewLandingPageActions.SET_SGD_ACCOUNT_IS_INITIATED
  isSGDAccountInitiated: boolean
}

type SetEarnAccountIsInitiated = {
  type: typeof NewLandingPageActions.SET_EARN_ACCOUNT_IS_INITIATED
  isEarnAccountInitiated: boolean
}

type SetDismissHomepageBanner = {
  type: typeof NewLandingPageActions.SET_DISMISS_HOMEPAGE_BANNER
  isHomepageBannerDismissed: boolean
}

type SetOwnerKycStatus = {
  type: typeof NewLandingPageActions.SET_OWNER_KYC_STATUS
  ownerKycStatus: VerificationStatusType
}

type SetBusinessKybStatus = {
  type: typeof NewLandingPageActions.SET_BUSINESS_KYB_STATUS
  businessKybStatus: VerificationStatusType
}

export type ActionTypes =
  | SetSGDAccount
  | SetIsSGDAccountLoading
  | SetUSDAccount
  | SetIsUSDAccountLoading
  | SetEarnAccount
  | SetIsEarnAccountLoading
  | SetPendingTasks
  | SetIsPendingTasksLoading
  | SetUSDAccountNumber
  | SetUSDAccountNumberLoading
  | SetSGDAccountIsInitiated
  | SetEarnAccountIsInitiated
  | SetDismissHomepageBanner
  | SetOwnerKycStatus
  | SetBusinessKybStatus
