/* eslint-disable max-lines */
import { generatePath } from '~/hooks/useReactRouter'
import { PayTransactionsTabKey } from '~/biz/pages/Pay/Transactions/types'
import type { Route } from '~/types'
import { PERMISSIONS } from '~/types'
import { getIsStagingOrLocal } from '~/managers/ConfigManager'
import { idSidebarLabels } from '~/constants/sidebarFeaturesLabel.config'
import AppUtils from '~/utils/AppUtils'
import { PATHS } from './paths'

const { lazyWithRetry } = AppUtils

const LandingPage = lazyWithRetry(
  () => import(/* webpackChunkName: "LandingPage" */ '~/biz/pages/LandingPage')
)

const NotFound = lazyWithRetry(
  () => import(/* webpackChunkName: "NotFound" */ '~/biz/pages/NotFound')
)

const Pay = lazyWithRetry(
  () => import(/* webpackChunkName: "Pay" */ '~/biz/pages/Pay/Transactions')
)

const PayTransfer = lazyWithRetry(
  () => import(/* webpackChunkName: "PayTransfer" */ '~/biz/pages/Pay/Transfer')
)

const PayRecipients = lazyWithRetry(
  () => import(/* webpackChunkName: "Pay" */ '~/biz/pages/Pay/Recipients')
)

const PayTransactionDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PayTransactionDetails" */ '~/biz/pages/Pay/Transactions/TransactionDetails'
    )
)

const Error = lazyWithRetry(() => import(/* webpackChunkName: "Error" */ '~/sharedModules/Error'))

const Transactions = lazyWithRetry(
  () => import(/* webpackChunkName: "Transactions" */ '~/biz/pages/Transactions')
)

const Disbursements = lazyWithRetry(
  () => import(/* webpackChunkName: "Disbursements" */ '~/biz/pages/Disbursements')
)

const TransactionDetails = lazyWithRetry(
  () => import(/* webpackChunkName: "TransactionDetails" */ '~/biz/pages/TransactionDetails')
)

const SimulateDeposit = lazyWithRetry(
  () => import(/* webpackChunkName: "SimulateDeposit" */ '~/biz/pages/SimulateDeposit')
)

const Deposit = lazyWithRetry(() => import(/* webpackChunkName: "Deposit" */ '~/biz/pages/Deposit'))

const DepositRequests = lazyWithRetry(
  () => import(/* webpackChunkName: "DepositRequests" */ '~/biz/pages/DepositRequests')
)

const MyAccount = lazyWithRetry(
  () => import(/* webpackChunkName: "MyAccount" */ '~/biz/pages/MyAccount')
)

const Withdraw = lazyWithRetry(
  () => import(/* webpackChunkName: "Withdraw" */ '~/biz/pages/Withdraw')
)

const DeveloperTools = lazyWithRetry(
  () => import(/* webpackChunkName: "DeveloperTools" */ '~/biz/pages/DeveloperTools')
)

const Earn = lazyWithRetry(() => import(/* webpackChunkName: "Earn" */ '~/biz/pages/Earn'))

const DepositEarn = lazyWithRetry(
  () => import(/* webpackChunkName: "Earn" */ '~/biz/pages/Earn/Deposit/CreateForm')
)

const DepositEarnDetails = lazyWithRetry(
  () => import(/* webpackChunkName: "Earn" */ '~/biz/pages/Earn/Deposit/Details')
)

const DepositEarnListDetails = lazyWithRetry(
  () => import(/* webpackChunkName: "Earn" */ '~/biz/pages/Earn/List/Details')
)

const IDRTransactionDetails = lazyWithRetry(
  () => import(/* webpackChunkName: "IDRTransactionDetails" */ '~/biz/pages/IDRtransactionDetails')
)

const Team = lazyWithRetry(() => import(/* webpackChunkName: "Team" */ '~/biz/pages/Team'))

const IndoInvoicing = lazyWithRetry(
  () => import(/* webpackChunkName: "IndoInvoicing" */ '~/biz/pages/IndoInvoicing')
)

const IndoInvoicingDetails = lazyWithRetry(
  () => import(/* webpackChunkName: "IndoInvoicingDetails" */ '~/biz/pages/IndoInvoicingDetails')
)

// TODO: will remove the env switch after new KYC form is tested
const VerificationIndo = lazyWithRetry(
  () => import(/* webpackChunkName: "VerificationIndo" */ '~/biz/pages/NewVerificationIndo')
)

const InternalTools = lazyWithRetry(
  () => import(/* webpackChunkName: "InternalTools" */ '~/sharedModules/InternalTools')
)

const BizKycInformation = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "BizKycInformation" */ '~/biz/pages/BusinessVerification/BusinessInformations'
    )
)
const BizKycIntendedRelations = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "BizKycIntendedRelations" */ '~/biz/pages/BusinessVerification/IntendedRelations'
    )
)
const BizKycBusinessActivities = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "BizKycBusinessActivities" */ '~/biz/pages/BusinessVerification/BusinessActivities'
    )
)
const BizKycUploadDocuments = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "BizKycUploadDocuments" */ '~/biz/pages/BusinessVerification/UploadDocuments'
    )
)
const BizKycReview = lazyWithRetry(
  () => import(/* webpackChunkName: "BizKycReview" */ '~/biz/pages/BusinessVerification/Review')
)

const BizKycComplete = lazyWithRetry(
  () => import(/* webpackChunkName: "BizKycComplete" */ '~/biz/pages/BusinessVerification/Complete')
)

const IndoTransactions = lazyWithRetry(
  () => import(/* webpackChunkName: "IndoTransactions" */ '~/biz/pages/IndoTransactions')
)

const XfersAcceptTransactionDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "XfersAcceptTransactionDetails" */ '~/biz/pages/IndoTransactionDetails/XfersAccept'
    )
)

const XfersSendTransactionDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "XfersSendTransactionDetails" */ '~/biz/pages/IndoTransactionDetails/XfersSend'
    )
)

const LoanVerification = lazyWithRetry(
  () => import(/* webpackChunkName: "LoanVerification" */ '~/biz/pages/LoanVerification')
)

const ApplyLoan = lazyWithRetry(
  () => import(/* webpackChunkName: "ApplyLoan" */ '~/biz/pages/ApplyLoan')
)

const LoanAccountNew = lazyWithRetry(
  () => import(/* webpackChunkName: "LoanAccountNew" */ '~/biz/pages/LoanAccountNew')
)

const LoanAccountDetailsNew = lazyWithRetry(
  () => import(/* webpackChunkName: "LoanAccountDetailsNew" */ '~/biz/pages/LoanAccountDetailsNew')
)

const LoanAccount = lazyWithRetry(
  () => import(/* webpackChunkName: "LoanAccount" */ '~/biz/pages/LoanAccount')
)

const LoanAccountDetails = lazyWithRetry(
  () => import(/* webpackChunkName: "LoanAccountDetails" */ '~/biz/pages/LoanAccountDetails')
)

const LoanAccountApplication = lazyWithRetry(
  () => import(/* webpackChunkName: "LoanAccountDetails" */ '~/biz/pages/LoanAccountApplication')
)

const LoanApplicationHomepage = lazyWithRetry(
  () => import(/* webpackChunkName: "LoanAccountDetails" */ '~/biz/pages/LoanApplicationHomepage')
)
const LoanAccountRepayment = lazyWithRetry(
  () => import(/* webpackChunkName: "LoanAccountDetails" */ '~/biz/pages/LoanRepayment')
)

const LoanAccountRepaymentInfo = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "LoanAccountDetails" */ '~/biz/pages/LoanRepayment/RepaymentInfo')
)

const LoanAccountRepaymentInstruction = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "LoanAccountDetails" */ '~/biz/pages/LoanRepayment/RepaymentInstruction'
    )
)

const LoanAccountStatusDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "LoanAccountDetails" */ '~/biz/pages/LoanAccountNew/Status/DetailPage'
    )
)

const FlexPayDashboard = lazyWithRetry(
  () => import(/* webpackChunkName: "FlexpayDashboard" */ '~/biz/pages/FlexpayDashboard')
)

const FlexPayAccountDetails = lazyWithRetry(
  () => import(/* webpackChunkName: "FlexPayAccountDetails" */ '~/biz/pages/FlexPayAccountDetails')
)

const FlexPayRepayment = lazyWithRetry(
  () => import(/* webpackChunkName: "FlexPayAccountDetails" */ '~/biz/pages/FlexPayRepayment')
)
const RegisterMerchant = lazyWithRetry(
  () => import(/* webpackChunkName: "RegisterMerchant" */ '~/biz/pages/RegisterMerchant')
)
const AccountSelector = lazyWithRetry(
  () => import(/* webpackChunkName: "AccountSelector" */ '~/components/AccountSelector')
)
const Blank = lazyWithRetry(() => import(/* webpackChunkName: "Blank" */ '~/components/Blank'))

const KYBSoleProp = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "OfflinePaymentDetails" */ '~/biz/pages/Verification/KYB/SoleProp/KYBSolePropForm'
    )
)
const CustomerCards = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "CustomerCards" */ '../../sharedModules/DebitCards/CustomerCards')
)
const CustomerCardsDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "CustomerCardsDetails" */ '../../sharedModules/DebitCards/CustomerCards/Transactions/TransactionsDetails'
    )
)
const CustomerCardsFraudDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "CustomerCardsFraudDetails" */ '../../sharedModules/DebitCards/CustomerCards/Fraud/FraudDetails'
    )
)
const CustomerCardsCustomersDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "CustomerCardsCustomersDetails" */ '../../sharedModules/DebitCards/CustomerCards/Customers/CustomersDetails'
    )
)
const CustomerCardsCardsDetails = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "CustomerCardsCardsDetails" */ '../../sharedModules/DebitCards/CustomerCards/Cards/CardDetails'
    )
)
const idSpecificRoutes: Route[] = [
  {
    path: PATHS.XFERS_ACCEPT,
    component: IndoTransactions,
    permission: PERMISSIONS.XFERS_ACCEPT,
    breadcrumbName: 'Accept',
    exact: true,
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_PAYMENT_API,
  },
  {
    path: PATHS.XFERS_ACCEPT_DETAILS,
    component: XfersAcceptTransactionDetails,
    exact: true,
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_PAYMENT_API,
    breadcrumbName: 'Accept Details',
  },
  {
    path: PATHS.XFERS_SEND,
    component: IndoTransactions,
    permission: PERMISSIONS.API_MENU,
    breadcrumbName: 'Send',
    exact: true,
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_PAYMENT_API,
  },
  {
    path: PATHS.XFERS_SEND_DETAILS,
    component: XfersSendTransactionDetails,
    exact: true,
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_PAYMENT_API,
    breadcrumbName: 'Send Details',
  },
]

const baseRoutes: Route[] = [
  {
    path: PATHS.LANDING_PAGE,
    component: LandingPage,
    exact: true,
    sideBar: idSidebarLabels.Home,
    breadcrumbName: 'Home',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_LANDING_PAGE,
  },
  {
    path: PATHS.ACCOUNT_STATEMENT,
    component: IndoTransactions,
    permission: PERMISSIONS.ACCOUNT_STATEMENT,
    exact: true,
    sideBar: idSidebarLabels.Accounts,
    breadcrumbName: 'IDR Cash Account',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_WITHDRAWAL,
    subRoutes: [
      {
        sideBar: idSidebarLabels.IDRCashAccount,
        path: PATHS.ACCOUNT_STATEMENT,
        permission: PERMISSIONS.ACCOUNT_STATEMENT,
        hasSandboxMode: true,
        sandboxPermission: PERMISSIONS.SB_WITHDRAWAL,
      },
    ],
  },
  {
    path: PATHS.TRANSACTIONS,
    component: Transactions,
    permission: PERMISSIONS.TRANSACTION,
    exact: true,
    breadcrumbName: 'Transactions',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_WITHDRAWAL,
  },
  {
    path: PATHS.TRANSACTION_DETAILS,
    component: TransactionDetails,
    permission: PERMISSIONS.TRANSACTION,
    exact: true,
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_WITHDRAWAL,
    breadcrumbName: 'Transaction Details',
  },
  {
    path: PATHS.PAY_TRANSFER,
    exact: true,
    component: PayTransfer,
    permission: PERMISSIONS.DISBURSEMENTS,
    breadcrumbName: 'Transfer',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_PAY_MENU,
  },
  {
    path: PATHS.PAY_TRANSACTIONS,
    permission: PERMISSIONS.ID_PAY,
    exact: true,
    component: Pay,
    sideBar: idSidebarLabels.Pay,
    breadcrumbName: {
      namespace: 'feature/payment/payoutForm',
      i18nkey: 'TRANSACTION_LIST',
    },
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_PAY_MENU,
    subRoutes: [
      {
        sideBar: idSidebarLabels.PayoutForm,
        permission: PERMISSIONS.ID_PAY,
        path: generatePath(PATHS.PAY_TRANSACTIONS, {
          tab: PayTransactionsTabKey.IDR_CASH_ACCOUNT,
        }) as PATHS,
        hasSandboxMode: true,
        sandboxPermission: PERMISSIONS.SB_PAY_MENU,
      },
      {
        sideBar: idSidebarLabels.Recipients,
        permission: PERMISSIONS.ID_PAY,
        path: PATHS.PAY_RECIPIENTS,
        hasSandboxMode: true,
        sandboxPermission: PERMISSIONS.SB_PAY_MENU,
      },
    ],
  },
  {
    path: PATHS.PAY_RECIPIENTS,
    exact: true,
    component: PayRecipients,
    breadcrumbName: {
      namespace: 'feature/payment/payoutForm',
      i18nkey: 'RECIPIENTS',
    },
    permission: PERMISSIONS.ID_PAY,
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_PAY_MENU,
  },
  {
    path: PATHS.IDR_TRANSACTION_DETAILS,
    exact: true,
    permission: PERMISSIONS.ID_PAY,
    component: IDRTransactionDetails,
  },
  {
    path: PATHS.PAY_TRANSACTION_DETAILS,
    exact: true,
    permission: PERMISSIONS.ID_PAY,
    component: PayTransactionDetails,
    breadcrumbName: {
      namespace: 'common/tableHeaders',
      i18nkey: 'TRANSACTION_DETAILS',
    },
  },
  {
    path: PATHS.INVOICING,
    exact: true,
    component: IndoInvoicing,
    sideBar: idSidebarLabels.Receive,
    breadcrumbName: 'Receive',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_INVOICING,
    subRoutes: [
      {
        path: PATHS.INVOICING,
        permission: PERMISSIONS.INVOICING,
        sideBar: idSidebarLabels.PaymentLink,
        breadcrumbName: 'Payment Link',
        hasSandboxMode: true,
        sandboxPermission: PERMISSIONS.SB_INVOICING,
      },
    ],
  },
  {
    path: PATHS.MENU_PAYMENTS_API,
    exact: true,
    component: NotFound,
    // TODO: use flexible permission that covers all items in subRoutes
    permission: PERMISSIONS.API_MENU,
    sideBar: idSidebarLabels.PaymentsAPI,
    breadcrumbName: 'Payments API',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_PAYMENT_API,
    subRoutes: [
      {
        sideBar: idSidebarLabels.SendAPI,
        breadcrumbName: 'Xfers Send',
        path: PATHS.XFERS_SEND,
        permission: PERMISSIONS.API_MENU,
        hasSandboxMode: true,
        sandboxPermission: PERMISSIONS.SB_PAYMENT_API,
      },
      {
        sideBar: idSidebarLabels.AcceptAPI,
        breadcrumbName: 'Xfers Accept',
        path: PATHS.XFERS_ACCEPT,
        permission: PERMISSIONS.XFERS_ACCEPT,
        hasSandboxMode: true,
        sandboxPermission: PERMISSIONS.SB_PAYMENT_API,
      },
    ],
  },
  {
    path: PATHS.LOAN_USAGE,
    component: LoanAccount,
    permission: PERMISSIONS.NEW_LOAN,
    breadcrumbName: 'Loan Account',
  },
  {
    path: PATHS.LOAN_USAGE_DETAILS,
    component: LoanAccountDetails,
    permission: PERMISSIONS.NEW_LOAN,
  },
  {
    path: PATHS.LOAN_ACCOUNT_DETAILS,
    component: LoanAccountDetails,
    permission: PERMISSIONS.LOAN_ACCOUNT,
  },
  {
    path: PATHS.LOAN_ACCOUNT,
    component: LoanAccount,
    permission: PERMISSIONS.LOAN_ACCOUNT,
    breadcrumbName: 'Loan Account',
  },
  {
    path: PATHS.LOAN_ACCOUNT_STATUS_DETAILS,
    component: LoanAccountStatusDetails,
    permission: PERMISSIONS.LOAN_ACCOUNT,
  },
  {
    path: PATHS.FLEX_PAY_ACCOUNT_REPAYMENT,
    component: FlexPayRepayment,
    exact: true,
    permission: PERMISSIONS.FLEX_PAY_ACCOUNT,
  },
  {
    path: PATHS.LOAN_ACCOUNT_DETAILS_NEW,
    component: LoanAccountDetailsNew,
    permission: PERMISSIONS.NEW_LOAN,
  },
  {
    path: PATHS.LOAN_ACCOUNT_NEW,
    component: LoanAccountNew,
    permission: PERMISSIONS.NEW_LOAN,
    breadcrumbName: { i18nkey: 'LOAN_ACCOUNT', namespace: 'feature/loan/loan_account' },
  },

  {
    path: PATHS.LOAN_ACCOUNT_REPAYMENT,
    component: LoanAccountRepayment,
    exact: true,
    breadcrumbName: 'Loan Account Repayment',
    permission: PERMISSIONS.NEW_LOAN,
  },
  {
    path: PATHS.LOAN_ACCOUNT_REPAYMENT_INFO,
    component: LoanAccountRepaymentInfo,
    exact: true,
    permission: PERMISSIONS.NEW_LOAN,
  },
  {
    path: PATHS.LOAN_ACCOUNT_REPAYMENT_INSTRUCTION,
    component: LoanAccountRepaymentInstruction,
    exact: true,
    permission: PERMISSIONS.NEW_LOAN,
  },
  {
    path: PATHS.FLEX_PAY_ACCOUNT_DETAILS,
    component: FlexPayAccountDetails,
    permission: PERMISSIONS.FLEX_PAY_ACCOUNT,
    breadcrumbName: 'Transaction Details',
  },
  {
    path: PATHS.FLEX_PAY_ACCOUNT,
    component: FlexPayDashboard,
    permission: PERMISSIONS.FLEX_PAY_ACCOUNT,
    breadcrumbName: 'FlexPay',
  },
  {
    path: PATHS.APPLY_LOAN,
    exact: true,
    sideBar: idSidebarLabels.Loans,
    component: ApplyLoan,
    // TODO: change to PERMISSIONS.APPLY_LOAN if ready to launch
    // TODO2: will open apply loan to all users for now, might be change later
    // permission: PERMISSIONS.ID_PAY,
    breadcrumbName: 'Loan',
    subRoutes: [
      {
        sideBar: idSidebarLabels.ApplyLoan,
        path: PATHS.APPLY_LOAN,
        permission: PERMISSIONS.OLD_APPLY_LOAN,
        // TODO: change to PERMISSIONS.APPLY_LOAN if ready to launch
        // TODO2: will open apply loan to all users for now, might be change later
        // permission: PERMISSIONS.APPLY_LOAN,
      },
      {
        sideBar: idSidebarLabels.ApplyLoan,
        path: PATHS.LOAN_ACCOUNT_APPLICATION_HOME,
        permission: PERMISSIONS.LOAN_SELF_SERVE,
      },
      {
        sideBar: idSidebarLabels.LoanAccount,
        path: generatePath(PATHS.LOAN_ACCOUNT) as PATHS,
        permission: PERMISSIONS.LOAN_ACCOUNT,
      },
      // TODO: update after approved to production
      {
        sideBar: idSidebarLabels.LoanAccount,
        path: generatePath(PATHS.LOAN_ACCOUNT_NEW) as PATHS,
        permission: PERMISSIONS.NEW_LOAN,
      },
      {
        sideBar: idSidebarLabels.FlexPay,
        path: PATHS.FLEX_PAY_ACCOUNT,
        permission: PERMISSIONS.FLEX_PAY_ACCOUNT,
      },
    ],
  },
  {
    path: PATHS.DEBIT_CARDS_MYCARD,
    component: CustomerCards,
    exact: true,
    breadcrumbName: 'Cards',
    sideBar: idSidebarLabels.Cards,
    hasSandboxMode: false,
    permission: PERMISSIONS.CUSTOMERS_CARDS,
    subRoutes: [
      {
        sideBar: idSidebarLabels.CustomerCards,
        path: PATHS.CUSTOMER_CARDS,
        permission: PERMISSIONS.CUSTOMERS_CARDS,
      },
    ],
  },
  {
    path: PATHS.CUSTOMER_CARDS,
    component: CustomerCards,
    permission: PERMISSIONS.CUSTOMERS_CARDS,
    breadcrumbName: 'Customer Cards',
    pageTitle: 'Customer Cards',
    exact: true,
  },
  {
    path: PATHS.CUSTOMER_CARDS_TRANSACTION_DETAILS,
    component: CustomerCardsDetails,
    permission: PERMISSIONS.CUSTOMERS_CARDS,
    breadcrumbName: 'Transaction Details',
  },
  {
    path: PATHS.CUSTOMER_CARDS_FRAUD_DETAILS,
    component: CustomerCardsFraudDetails,
    breadcrumbName: 'Fraud Details',
    permission: PERMISSIONS.CUSTOMERS_CARDS,
  },
  {
    path: PATHS.CUSTOMER_CARDS_CARD_DETAILS,
    component: CustomerCardsCardsDetails,
    breadcrumbName: 'Card Details',
    permission: PERMISSIONS.CUSTOMERS_CARDS,
  },
  {
    path: PATHS.CUSTOMER_CARDS_CUSTOMERS_DETAILS,
    component: CustomerCardsCustomersDetails,
    breadcrumbName: 'Customer Details',
    permission: PERMISSIONS.CUSTOMERS_CARDS,
  },
  {
    path: PATHS.INVOICING_DETAILS,
    component: IndoInvoicingDetails,
    permission: PERMISSIONS.INVOICING,
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_INVOICING,
    exact: true,
    breadcrumbName: 'Payment Link Details',
  },
  {
    path: PATHS.DISBURSEMENTS,
    component: Disbursements,
    permission: PERMISSIONS.DISBURSEMENTS,
    breadcrumbName: 'Payout Form',
    pageTitle: 'Payout Form',
  },
  {
    path: PATHS.DEPOSIT,
    component: Deposit,
    permission: PERMISSIONS.DEPOSIT,
    exact: true,
    breadcrumbName: 'Deposit',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_DEPOSIT,
  },
  {
    path: PATHS.DEPOSIT_REQUESTS,
    component: DepositRequests,
    permission: PERMISSIONS.DEPOSIT,
    exact: true,
    breadcrumbName: 'Deposit Requests',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_DEPOSIT,
  },
  {
    path: PATHS.SIMULATE_DEPOSIT,
    component: SimulateDeposit,
    exact: true,
    breadcrumbName: 'Simulate Deposit',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_DEPOSIT,
  },
  {
    path: PATHS.WITHDRAW,
    component: Withdraw,
    permission: PERMISSIONS.WITHDRAW,
    exact: true,
    breadcrumbName: 'Withdraw',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.SB_WITHDRAWAL,
  },
  {
    path: PATHS.MANAGE_ACCOUNT,
    component: MyAccount,
    exact: true,
    breadcrumbName: 'My Account',
  },
  {
    path: PATHS.EARN,
    component: Earn,
    sideBar: idSidebarLabels.Earn,
    breadcrumbName: 'Earn',
    exact: true,
    permission: PERMISSIONS.ID_EARN,
  },
  {
    path: PATHS.EARN_DEPOSIT,
    component: DepositEarn,
    exact: true,
    breadcrumbName: 'Buat Simpanan Berjangka Baru',
    permission: PERMISSIONS.ID_EARN,
  },
  {
    path: PATHS.EARN_DEPOSIT_DETAILS,
    component: DepositEarnDetails,
    exact: true,
    breadcrumbName: 'Rincian Simpanan Berjangka',
    permission: PERMISSIONS.ID_EARN,
  },
  {
    path: PATHS.EARN_LIST_DETAILS,
    component: DepositEarnListDetails,
    exact: true,
    breadcrumbName: 'Rincian Simpanan Berjangka',
    permission: PERMISSIONS.ID_EARN,
  },
  {
    path: PATHS.DEVELOPER_TOOLS,
    component: DeveloperTools,
    permission: PERMISSIONS.DEVELOPER_TOOLS,
    sideBar: idSidebarLabels.DeveloperTools,
    breadcrumbName: 'Developer Tools',
    hasSandboxMode: true,
    sandboxPermission: PERMISSIONS.DEVELOPER_TOOLS,
  },
  {
    path: PATHS.TEAM,
    component: Team,
    permission: PERMISSIONS.TEAM_READ,
    breadcrumbName: 'Team',
  },
  {
    path: PATHS.VERIFICATION,
    component: VerificationIndo,
    permission: PERMISSIONS.VERIFICATION,
    exact: true,
    breadcrumbName: 'Verification',
    pageTitle: 'Account Manager Verification',
  },
  {
    path: PATHS.BIZ_KYC_INFORMATION,
    component: BizKycInformation,
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
  },
  {
    path: PATHS.BIZ_KYC_INTENDED_RELATIONS,
    component: BizKycIntendedRelations,
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
  },
  {
    path: PATHS.BIZ_KYC_BUSINESS_ACTIVITIES,
    component: BizKycBusinessActivities,
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
  },
  {
    path: PATHS.BIZ_KYC_UPLOAD_DOCUMENTS,
    component: BizKycUploadDocuments,
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
  },
  {
    path: PATHS.BIZ_KYC_REVIEW,
    component: BizKycReview,
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
  },
  {
    path: PATHS.BIZ_KYC_COMPLETE,
    component: BizKycComplete,
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
  },
  {
    path: PATHS.LOAN_VERIFICATION,
    component: LoanVerification,
    permission: PERMISSIONS.LOAN_SELF_SERVE,
    exact: true,
    breadcrumbName: {
      i18nkey: 'LOAN_ACCOUNT_VERIFICATION',
      namespace: 'feature/loan/loan_verification',
    },
  },
  {
    path: PATHS.LOAN_ACCOUNT_APPLICATION_FORM,
    component: LoanAccountApplication,
    permission: PERMISSIONS.LOAN_SELF_SERVE,
    breadcrumbName: { i18nkey: 'LOAN_APPLICATION', namespace: 'feature/loan/loan_application' },
    exact: true,
  },
  {
    path: PATHS.LOAN_ACCOUNT_APPLICATION_HOME,
    component: LoanApplicationHomepage,
    permission: PERMISSIONS.LOAN_SELF_SERVE,
    breadcrumbName: { i18nkey: 'APPLY_LOAN', namespace: 'feature/loan/loan_application' },
  },
  {
    path: PATHS.BLANK,
    component: Blank,
    exact: true,
    pageTitle: 'Blank',
  },
  {
    path: PATHS.KYB_SOLE_PROP,
    component: KYBSoleProp,
    breadcrumbName: 'Business Verification',
  },
]

const customLayoutRoutes: Route[] = [
  {
    path: PATHS.REGISTER_MERCHANT,
    component: RegisterMerchant,
    exact: true,
    breadcrumbName: 'Register Merchant',
    pageTitle: 'Register a new Merchant Account',
  },
  {
    path: PATHS.ACCOUNT_SELECTOR,
    component: AccountSelector,
    exact: true,
    pageTitle: 'Select your account',
  },
]

const errorRoutes: Route[] = [
  {
    path: PATHS.ERROR,
    component: Error,
  },
  {
    component: NotFound,
  },
]

const nonProductionRoutes: Route[] = [
  {
    path: PATHS.INTERNAL_TOOLS,
    component: InternalTools,
    exact: true,
    breadcrumbName: 'Internal Tools',
    sideBar: idSidebarLabels.InternalTools,
  },
]

function getRoutes() {
  const idBizRoutes = [...idSpecificRoutes, ...baseRoutes]

  if (getIsStagingOrLocal()) return [...nonProductionRoutes, ...idBizRoutes, ...errorRoutes]
  return [...idBizRoutes, ...errorRoutes]
}

export function getCustomLayoutRoutes() {
  return [...customLayoutRoutes]
}

export default getRoutes()
