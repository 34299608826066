import * as React from 'react'

function SvgFlag(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.173 17.999a5.758 5.758 0 012.827 0v-18H2l2.003 4.672L2 9.343h11.173V18z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFlag
