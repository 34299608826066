import axios, { type AxiosRequestHeaders } from 'axios'
import { AppType } from '~/types'
import { Notification } from '~/components/Notification'
import { ModeManager } from '~/managers/ModeManager'
import ENDPOINTS from './paths'
import SessionUtils from '~/utils/SessionUtils'

function makeRequest(
  url: string,
  method: 'get' | 'post' | 'delete' | 'put',
  appType: AppType,
  additionalHeaders: object = {},
  requestBody: object = {},
  queryStringParams: object = {},
  options?: { signal?: AbortSignal }
) {
  const countryNamespace = appType === AppType.SINGAPORE ? 'sg' : 'id'

  let domain = `/${countryNamespace}/`
  domain += ModeManager.isSandboxMode() ? 'sandbox/fe/' : 'fe/'

  return axios({
    method,
    url: domain + url,
    headers: {
      ...additionalHeaders,
    },
    data: requestBody,
    params: queryStringParams,
    withCredentials: true,
    signal: options?.signal,
    validateStatus: (status) => status >= 200 && status < 400, // NOTE: adding this since axios is handling 304 as error responses, ref: https://github.com/axios/axios/issues/703
  })
    .then((response) => response.data)
    .catch((err) => {
      if (err.response?.status) {
        const { status } = err.response
        switch (status) {
          case 401:
            SessionUtils.ssoLogout()
            break
          case 403:
            Notification.error({
              message: 'Error 403: Forbidden',
              description: 'An error occurred when we tried to process your request.',
            })
            break
          case 413:
            Notification.error({
              message: 'Error 413: Payload Too Large',
              description:
                'The file that you tried to upload is too large. Please try again with a smaller file.',
            })
            break
          case 500:
            Notification.error({
              message: 'Error 500: Internal Server Error',
              description:
                'The server encountered an unexpected condition which prevented it from fulfilling the request. Please contact our support team if this problem persists.',
            })
            break
          default:
            break
        }
      } else {
        // temp monitor: err.response is undefined if axios request fails
        // TODO Kerk(fazzbiz-rollbar): Capture error in rollbar in separate ticket
      }

      throw err
    })
}

/**
 * This function doesn't take in appType params.
 * To be deprecated in post-MVP of Fazzbiz launch
 */
function oldMakeRequest(
  url: string,
  method: 'get' | 'post' | 'delete' | 'put',
  additionalHeaders: AxiosRequestHeaders = {},
  requestBody: object = {}
) {
  const domain = ModeManager.isSandboxMode() ? '/sandbox/fe/' : '/fe/'
  return axios({
    method,
    url: domain + url,
    headers: {
      ...additionalHeaders,
    },
    data: requestBody,
    withCredentials: true,
    validateStatus: (status) => status >= 200 && status < 400, // NOTE: adding this since axios is handling 304 as error responses, ref: https://github.com/axios/axios/issues/703
  })
    .then((response) => response.data)
    .catch((err) => {
      if (err.response?.status) {
        const { status } = err.response
        switch (status) {
          case 401:
            SessionUtils.ssoLogout()
            break
          case 403:
            Notification.error({
              message: 'Error 403: Forbidden',
              description: 'An error occurred when we tried to process your request.',
            })
            break
          case 413:
            Notification.error({
              message: 'Error 413: Payload Too Large',
              description:
                'The file that you tried to upload is too large. Please try again with a smaller file.',
            })
            break
          case 500:
            Notification.error({
              message: 'Error 500: Internal Server Error',
              description:
                'The server encountered an unexpected condition which prevented it from fulfilling the request. Please contact our support team if this problem persists.',
            })
            break
          default:
            break
        }
      } else {
        // temp monitor: err.response is undefined if axios request fails
        // TODO Kerk(fazzbiz-rollbar): Capture error in rollbar in separate ticket
      }

      throw err
    })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ResponsePromise = Promise<any>

function get(endpoint: ENDPOINTS, subRoute = ''): ResponsePromise {
  return oldMakeRequest(endpoint + subRoute, 'get')
}

function post(
  endpoint: ENDPOINTS,
  requestBody: object,
  additionalHeaders: AxiosRequestHeaders = {},
  subRoute = ''
): ResponsePromise {
  return oldMakeRequest(endpoint + subRoute, 'post', additionalHeaders, requestBody)
}

function del(
  endpoint: ENDPOINTS,
  additionalHeaders: AxiosRequestHeaders = {},
  subRoute = ''
): ResponsePromise {
  return oldMakeRequest(endpoint + subRoute, 'delete', additionalHeaders)
}

function put(
  endpoint: ENDPOINTS,
  requestBody: object,
  additionalHeaders: AxiosRequestHeaders = {},
  subRoute = ''
): ResponsePromise {
  return oldMakeRequest(endpoint + subRoute, 'put', additionalHeaders, requestBody)
}

export { get, post, del, put, makeRequest }
