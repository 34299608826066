import type { Reducer } from 'redux'
import type { VerificationStatusType } from '~/api/idVerification/idVerification.types'
import type { ActionTypes, LoansHistory, LoanSummary, LoanData, LoanTimeline } from './types'
import { LoansActions } from './types'

const initialState = {
  businessVerificationStatus: undefined,
  amVerificationStatus: undefined,
  loansHistory: {
    data: [],
    meta: {
      page: 1,
      limit: 1,
      totalPage: 1,
      totalData: 1,
    },
  },
  loansHistoryLoading: false,
  loanSummary: undefined,
  loanSummaryLoading: false,
  loanDetailLoading: false,
  loanDetail: undefined,
  loanTimeline: {
    data: [],
  },
  loanTimelineLoading: false,
  loanVerificationStatus: false,
}
type State = {
  businessVerificationStatus?: VerificationStatusType
  loansHistory: LoansHistory
  loansHistoryLoading: boolean
  loanSummary: LoanSummary | undefined
  loanSummaryLoading: boolean
  loanDetailLoading: boolean
  loanDetail: LoanData | undefined
  loanTimelineLoading: boolean
  loanTimeline: LoanTimeline
  loanVerificationStatus: boolean
  amVerificationStatus?: VerificationStatusType
}

export const loan: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case LoansActions.SET_BUSINESS_VERIFICATION_STATUS: {
      return {
        ...state,
        businessVerificationStatus: action.businessVerificationStatus,
        amVerificationStatus: action.amVerificationStatus,
      }
    }
    case LoansActions.SET_LOAN_VERIFICATION_STATUS: {
      return {
        ...state,
        loanVerificationStatus: action.loanVerificationStatus,
      }
    }
    case LoansActions.SET_LOANS_HISTORY_LOADING: {
      return {
        ...state,
        loansHistoryLoading: action.loansHistoryLoading,
      }
    }

    case LoansActions.SET_LOANS_HISTORY: {
      return {
        ...state,
        loansHistory: action.loansHistory,
      }
    }

    case LoansActions.SET_LOAN_SUMMARY_LOADING: {
      return {
        ...state,
        loanSummaryLoading: action.loanSummaryLoading,
      }
    }

    case LoansActions.SET_LOAN_SUMMARY: {
      return {
        ...state,
        loanSummary: action.loanSummary,
      }
    }

    case LoansActions.SET_LOAN_DETAIL_LOADING: {
      return {
        ...state,
        loanDetailLoading: action.loanDetailLoading,
      }
    }

    case LoansActions.SET_LOAN_DETAIL: {
      return {
        ...state,
        loanDetail: action.loanDetail,
      }
    }

    case LoansActions.SET_LOAN_TIMELINE_LOADING: {
      return {
        ...state,
        loanTimelineLoading: action.loanTimelineLoading,
      }
    }

    case LoansActions.SET_LOAN_TIMELINE: {
      return {
        ...state,
        loanTimeline: action.loanTimeline,
      }
    }

    default:
      return state
  }
}
