import { patch } from './fazzbizBaseApi'

enum RegionSelectorEndpoints {
  userRegion = 'dashboard/business_entities',
}

export const patchUserRegion = (payload: string) =>
  patch(RegionSelectorEndpoints.userRegion, {
    data: {
      attributes: {
        registration_country: payload,
      },
    },
  })
