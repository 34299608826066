import Tooltip from 'antd/lib/tooltip'
import { StyledIconButton } from './IconButton.styles'
import type { IconButtonProps } from './IconButton.types'

export function IconButton({
  icon,
  size = 'xl',
  shape = 'square',
  isDisabled = false,
  variant = 'primary',
  colorScheme = 'default',
  tooltip,
  onClick,
  href,
  target,
}: IconButtonProps) {
  return (
    <Tooltip title={tooltip}>
      <StyledIconButton
        size={size}
        shape={shape}
        variant={variant}
        colorScheme={colorScheme}
        // TODO: update Icon to use types from design-system-icons
        icon={icon}
        disabled={isDisabled}
        onClick={onClick}
        htmlType="button"
        href={href}
        target={target}
      />
    </Tooltip>
  )
}

export default IconButton
