export type TeamMember = {
  id: string
  email: string
  roles: Array<{ name: string }>
  lastLoginAt: number
  firstName?: string
  lastName?: string
}

export enum TeamActions {
  SET_TEAM = 'TeamActions.SET_TEAM',
}

type SetTeam = {
  type: typeof TeamActions.SET_TEAM
  team: TeamMember[]
}

export type ActionTypes = SetTeam
