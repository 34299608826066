import * as React from 'react'

function SvgUpArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#upArrow_svg__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.643 1.47a.897.897 0 00-1.286 0L3.264 6.564a.9.9 0 101.272 1.273L8.1 4.273V15.9a.9.9 0 101.8 0V4.273l3.564 3.564a.9.9 0 001.272-1.273L9.643 1.47z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="upArrow_svg__clip0">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgUpArrow
