import { SVGProps } from 'react'

function SvgRightArrowRounded(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.258 10A7.758 7.758 0 1 1 9.5 2.242 7.775 7.775 0 0 1 17.258 10Zm1.25 0A9.009 9.009 0 1 0 .491 10a9.009 9.009 0 0 0 18.017 0Z"
      fill="#00A0FF"
    />
    <path
      d="m10.083 13.625 2.45-2.45a1.667 1.667 0 0 0 0-2.35l-2.45-2.45a.625.625 0 1 0-.883.883l2.133 2.133H6.6a.625.625 0 0 0 0 1.25h4.7l-2.1 2.1a.658.658 0 0 0-.183.442.625.625 0 0 0 1.067.442Z"
      fill="#00A0FF"
    />
  </svg>
  )
}

export default SvgRightArrowRounded
