import * as React from 'react'

function SvgMufg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#MUFG_svg__clip0)">
        <path
          d="M30.511 21.718V15.63l-2.604 3.977h-.07l-2.64-3.906v6.088h-1.97v-9.431h2.181l2.464 3.94 2.428-3.94h2.111v9.395h-1.9v-.035zm7.496.106c-2.499 0-3.977-1.408-3.977-4.118v-5.348H36v5.313c0 1.514.81 2.288 2.042 2.288 1.232 0 2.041-.74 2.041-2.217v-5.385h1.936v5.28c0 2.814-1.514 4.187-4.012 4.187zm7.53-7.531v1.97h4.118v1.971h-4.117v3.52h-1.971v-9.396h7.249v1.97h-5.279v-.035zm10.523 7.566c-2.886 0-4.857-2.041-4.857-4.786v-.035c0-2.64 2.041-4.821 4.857-4.821 1.689 0 2.674.457 3.624 1.267l-1.302 1.548c-.704-.598-1.302-.95-2.393-.95-1.478 0-2.64 1.337-2.64 2.886v.07c0 1.724 1.127 2.956 2.78 2.956.74 0 1.303-.176 1.901-.563v-1.232h-2.146v-1.97H60v4.117c-.95.844-2.217 1.513-3.941 1.513z"
          fill="#5A5A5A"
        />
        <path
          d="M12.774 10c-1.02 0-2.006.211-2.885.598A6.89 6.89 0 007.039 10 7.036 7.036 0 000 17.038c0 3.871 3.167 7.038 7.038 7.038 1.02 0 2.006-.21 2.886-.598.88.387 1.83.598 2.885.598 3.871 0 7.038-3.167 7.038-7.038C19.812 13.132 16.68 10 12.774 10zm-2.85 13.408c-3.52 0-6.37-2.85-6.37-6.37s2.85-6.37 6.37-6.37c3.519 0 6.37 2.85 6.37 6.37s-2.886 6.37-6.37 6.37zm2.92-6.37a2.911 2.911 0 01-2.92 2.92 2.914 2.914 0 01-2.921-2.92 2.914 2.914 0 012.92-2.92 2.915 2.915 0 012.922 2.92z"
          fill="#E60000"
        />
      </g>
      <defs>
        <clipPath id="MUFG_svg__clip0">
          <path fill="#fff" d="M0 0h60v35H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgMufg
