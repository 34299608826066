import * as React from 'react'

function SvgArrowUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.56 20.56a1.5 1.5 0 01-2.12 0L15 11.122l-9.44 9.44a1.5 1.5 0 01-2.12-2.122l9.792-9.793a2.5 2.5 0 013.536 0l9.793 9.793a1.5 1.5 0 010 2.122z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgArrowUp
