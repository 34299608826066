import { BusinessRegistrationCountry, AppType } from '~/types'

export const HighValueTransfer = 150000
export const RtgsHighValueTransfer = 100000000
export const IndoMaxBankAccountsPerUser = 4
export const SgMaxBankAccountsPerUser = 5

export const appTypeMap = {
  [BusinessRegistrationCountry.SINGAPORE]: AppType.SINGAPORE,
  [BusinessRegistrationCountry.INDONESIA]: AppType.INDONESIA,
}
