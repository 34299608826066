import { SVGProps } from 'react'

function CsvFile(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.17 6.39009L11.43 0.650092C11.2428 0.461724 11.0199 0.312537 10.7744 0.211238C10.5288 0.10994 10.2656 0.0585578 10 0.060092H6.25C4.6587 0.060092 3.13258 0.692233 2.00736 1.81745C0.882141 2.94267 0.25 4.46879 0.25 6.06009V15.9401C0.25 17.5314 0.882141 19.0575 2.00736 20.1827C3.13258 21.3079 4.6587 21.9401 6.25 21.9401H11.75C13.3413 21.9401 14.8674 21.3079 15.9926 20.1827C17.1179 19.0575 17.75 17.5314 17.75 15.9401V7.80009C17.7504 7.27195 17.5419 6.76507 17.17 6.39009Z"
        fill="#121212"
      />
      <path
        d="M14.67 6.87006H15.52L11.17 2.52006V3.37006C11.17 4.29832 11.5387 5.18856 12.1951 5.84493C12.8515 6.50131 13.7417 6.87006 14.67 6.87006Z"
        fill="white"
      />
      <path
        d="M6.25 20.4401H11.75C12.9435 20.4401 14.0881 19.966 14.932 19.122C15.7759 18.2781 16.25 17.1335 16.25 15.9401V8.37006H14.67C13.3439 8.37006 12.0721 7.84327 11.1345 6.90559C10.1968 5.96791 9.67 4.69614 9.67 3.37006V1.56006H6.25C5.05653 1.56006 3.91193 2.03416 3.06802 2.87808C2.22411 3.72199 1.75 4.86658 1.75 6.06006V15.9401C1.75 17.1335 2.22411 18.2781 3.06802 19.122C3.91193 19.966 5.05653 20.4401 6.25 20.4401Z"
        fill="white"
      />
      <path
        d="M5.35938 15.3789C5.80469 15.3789 6.27734 15.2695 6.65625 15.0938L6.52344 14.3711C6.19531 14.457 5.91016 14.4922 5.67188 14.4922C4.875 14.4922 4.57031 14.1328 4.57031 13.3789V12.9961C4.57031 12.2422 4.875 11.8906 5.67188 11.8906C5.91797 11.8906 6.20312 11.9258 6.52344 12.0078L6.65625 11.2852C6.28125 11.1133 5.80859 11 5.35938 11C4.15625 11 3.35156 11.7539 3.35156 12.9961V13.3789C3.35156 14.625 4.16406 15.3789 5.35938 15.3789Z"
        fill="#00B583"
      />
      <path
        d="M8.55281 15.3789C9.58406 15.3789 10.2091 14.9297 10.2091 14.1641C10.2091 13.5352 9.84188 13.1719 9.18563 12.8594L8.86141 12.7031C8.53719 12.5547 8.37703 12.4414 8.37703 12.1914C8.37703 11.9609 8.55281 11.8281 8.96688 11.8281C9.20125 11.8281 9.49813 11.8633 9.83406 11.9531L9.96297 11.2617C9.63484 11.0938 9.14656 11 8.77938 11C7.79891 11 7.24031 11.4297 7.24031 12.1602C7.24031 12.7148 7.54109 13.1172 8.08797 13.3789L8.52156 13.5977C8.89656 13.7656 9.07234 13.9023 9.07234 14.1523C9.07234 14.4141 8.795 14.5508 8.33797 14.5508C8.09969 14.5508 7.67391 14.5039 7.40438 14.3945L7.27547 15.0781C7.69344 15.2891 8.14656 15.3789 8.55281 15.3789Z"
        fill="#00B583"
      />
      <path
        d="M13.4416 11.0742L12.5666 14.1562L11.6877 11.0742H10.465L11.7853 15.3047H13.3439L14.6681 11.0742H13.4416Z"
        fill="#00B583"
      />
    </svg>
  )
}

export default CsvFile
