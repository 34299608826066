import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from '~/hooks/useReactRouter'
import {
  fetchAccounts as BizFetchAccounts,
  resetRedirect as BizResetRedirect,
} from '~/biz/store/route/actions'
import type { RootState as BizStore } from '~/biz/store/types'
import {
  fetchAccounts as RegionalFetchAccounts,
  resetRedirect as RegionalResetRedirect,
} from '~/bizRegional/store/route/actions'
import type { RootState as RegionalStore } from '~/bizRegional/store/types'
import { AppType } from '~/types'
import SessionUtils from '~/utils/SessionUtils'

type Props = {
  appType: AppType | undefined
}

export default function RouteHooks({ appType }: Props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { currentRedirect } = useSelector((state: RegionalStore | BizStore) => state.route)

  useEffect(() => {
    if (appType === AppType.SINGAPORE) {
      dispatch(RegionalResetRedirect())

      if (SessionUtils.isLoggedIn()) {
        dispatch(RegionalFetchAccounts())
      }
    } else {
      dispatch(BizResetRedirect())

      if (SessionUtils.isLoggedIn()) {
        dispatch(BizFetchAccounts())
      }
    }
  }, [location, dispatch, appType])

  useEffect(() => {
    if (currentRedirect) {
      history.push(currentRedirect)
    }
  }, [currentRedirect, history])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    })
    // Didn't include [] intentionally
    // so that this useEffect is ran when child components are done rendering
  })

  return null
}
