import { StyledButtonGroup } from './ButtonGroup.styles'
import { ButtonGroupProps } from './ButtonGroup.types'

export function ButtonGroup({
  variant = 'horizontal',
  style,
  className,
  children,
}: // DO NOT USE SPREAD OPERATOR i.e. {...props}
ButtonGroupProps) {
  return (
    <StyledButtonGroup
      variant={variant}
      className={className}
      style={style}
      // DO NOT USE SPREAD OPERATOR i.e. {...props}
    >
      {children}
    </StyledButtonGroup>
  )
}

export default ButtonGroup
