import { useState, useEffect } from 'react'

type TimerProps = {
  initialSeconds: number
  blinkActivationSeconds?: number
  shouldRepeat?: boolean
  onTimesUp?: () => void
  children(isBlinkActivated: boolean, remainingSeconds: number): React.ReactElement
}

const Timer = ({
  initialSeconds,
  blinkActivationSeconds,
  shouldRepeat,
  onTimesUp,
  children,
}: TimerProps) => {
  const [isBlinkActivated, setIsBlinkActivated] = useState(false)
  const [remainingSeconds, setRemainingSeconds] = useState(initialSeconds)
  const [intervalId, setIntervalId] = useState<undefined | ReturnType<typeof setTimeout>>(undefined)
  const [shouldStartTimer, setShouldStartTimer] = useState(true)
  const deadlineInMilliseconds = Date.now() + remainingSeconds * 1000

  useEffect(() => {
    if (shouldStartTimer) {
      setIntervalId(
        setInterval(() => {
          const nowInMilliseconds = new Date().getTime()
          const currentRemainingMilliseconds = deadlineInMilliseconds - nowInMilliseconds
          const currentRemainingSeconds = Math.ceil(currentRemainingMilliseconds / 1000)
          if (currentRemainingSeconds >= 0) {
            setRemainingSeconds(currentRemainingSeconds)
          }
          if (blinkActivationSeconds && currentRemainingSeconds <= blinkActivationSeconds) {
            setIsBlinkActivated(true)
          }
        }, 1000)
      )
      setShouldStartTimer(false)
    }
  }, [shouldStartTimer])

  useEffect(() => {
    if (remainingSeconds <= 0 && intervalId !== undefined) {
      clearInterval(intervalId)
      setIntervalId(undefined)
      setIsBlinkActivated(false)
      if (onTimesUp) onTimesUp()
      if (shouldRepeat) {
        setRemainingSeconds(initialSeconds)
        setShouldStartTimer(true)
      }
    }
  }, [intervalId, remainingSeconds])

  return children(isBlinkActivated, remainingSeconds)
}
export default Timer
