import type { Reducer } from 'redux'
import type { ActionTypes, LoanAccountTransaction } from '~/biz/store/pay/types'
import { PayTransactionsActions } from '~/biz/store/pay/types'

export type State = {
  // Refactor below for generalized transactions state when working on filters
  loanAccount: {
    isLoading: boolean
    transaction: LoanAccountTransaction | null
  }
}

const initialState: State = {
  loanAccount: {
    isLoading: false,
    transaction: null,
  },
}

export const pay: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case PayTransactionsActions.SET_TRANSACTION:
      return {
        ...state,
        loanAccount: {
          ...state.loanAccount,
          transaction: action.transaction,
        },
      }
    default:
      return state
  }
}
