import { SVGProps } from 'react'

function SvgCloseRounded(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#closeRounded_svg__a)" fill="currentColor">
        <path d="M10 2.242A7.758 7.758 0 1 1 2.24 10 7.775 7.775 0 0 1 10 2.242Zm0-1.25a9.008 9.008 0 1 0 0 18.016A9.008 9.008 0 0 0 10 .992Z" />
        <path d="m10.883 10 1.85-1.85a.625.625 0 0 0-.883-.884L10 9.116l-1.85-1.85a.625.625 0 0 0-.884.884L9.116 10l-1.85 1.85a.625.625 0 0 0 .884.883l1.85-1.85 1.85 1.85a.625.625 0 0 0 .883-.883L10.883 10Z" />
      </g>
      <defs>
        <clipPath id="closeRounded_svg__a">
          <path fill="currentColor" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgCloseRounded
