import * as React from 'react'

function SvgVecDesktop(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 130 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.8}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.003 15.228c1.326-4.192 2.56-6.802 3.7-7.83 3.157-2.849 7.522-1.299 8.45-1.077 3.26.782 2.2-4.364 4.997-5.41 1.864-.697 3.397.155 4.6 2.556 1.065-2.24 2.688-3.266 4.87-3.083 3.271.277 4.416 11.328 8.912 8.909 4.497-2.42 10.01-2.973 12.365.623.51.778.704-.429 4.166-4.55 3.462-4.122 6.914-5.938 13.996-3.538 3.22 1.09 5.868 4.045 7.947 8.864 0 6.878 5.06 10.95 15.178 12.213 15.179 1.895 3.397 18.214-15.178 22.993-18.576 4.78-61.343 7.36-84.55-4.716-15.473-8.049-11.957-16.7 10.546-25.954h.001z"
        fill="url(#vec_desktop_svg__paint0_linear)"
      />
      <path
        d="M64.987 76.008c15.457 0 27.987-2.9 27.987-6.478s-12.53-6.478-27.987-6.478C49.53 63.052 37 65.952 37 69.53s12.53 6.478 27.987 6.478z"
        fill="url(#vec_desktop_svg__paint1_linear)"
      />
      <path
        opacity={0.675}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.201 77.613c-21.043 5.278-92.87-.76-100.515-3.516-3.721-1.343-7.075-3.868-10.061-7.576a2.822 2.822 0 012.198-4.593h125.514c2.605 6.938-3.107 12.166-17.136 15.685z"
        fill="url(#vec_desktop_svg__paint2_linear)"
      />
      <path
        d="M35.047 18.238a4.19 4.19 0 014.19-4.19h52.381a4.19 4.19 0 014.19 4.19v33.524a4.19 4.19 0 01-4.19 4.19h-52.38a4.19 4.19 0 01-4.191-4.19V18.238z"
        fill="url(#vec_desktop_svg__paint3_linear)"
      />
      <path
        d="M58.094 55.952h14.142l5.239 11h-24.62l5.239-11z"
        fill="url(#vec_desktop_svg__paint4_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.435 54.904h15.466L79.137 68H51.199l6.236-13.095zM58.758 57l-4.24 8.905h21.3L71.578 57h-12.82z"
        fill="url(#vec_desktop_svg__paint5_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 18.238A5.238 5.238 0 0139.238 13H91.62a5.238 5.238 0 015.238 5.238v33.524A5.238 5.238 0 0191.62 57h-52.38A5.238 5.238 0 0134 51.762V18.238zm5.238-3.143a3.143 3.143 0 00-3.143 3.143v33.524a3.143 3.143 0 003.143 3.143H91.62a3.143 3.143 0 003.143-3.143V18.238a3.143 3.143 0 00-3.143-3.143h-52.38z"
        fill="url(#vec_desktop_svg__paint6_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.617 66.952c0-.579.47-1.048 1.048-1.048h33.524a1.048 1.048 0 110 2.096H48.665a1.048 1.048 0 01-1.048-1.048z"
        fill="#2F8DEE"
      />
      <path fill="url(#vec_desktop_svg__paint7_linear)" d="M39.238 17.715h52.381v31.952H39.238z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.477 25.096a1 1 0 011-1h24.19a1 1 0 110 2h-24.19a1 1 0 01-1-1zM55.477 29.285a1 1 0 011-1h17.38a1 1 0 110 2h-17.38a1 1 0 01-1-1z"
        fill="#2F8DEE"
      />
      <path
        d="M61.58 34.477H57a1 1 0 00-1 1v7.212a1 1 0 001 1h4.58a1 1 0 001-1v-7.212a1 1 0 00-1-1z"
        fill="url(#vec_desktop_svg__paint8_linear)"
      />
      <path
        d="M71.01 34.477h-4.58a1 1 0 00-1 1v7.212a1 1 0 001 1h4.58a1 1 0 001-1v-7.212a1 1 0 00-1-1z"
        fill="url(#vec_desktop_svg__paint9_linear)"
      />
      <circle cx={87.954} cy={52.286} r={1.048} fill="#fff" />
      <path
        d="M85.334 52.286a1.048 1.048 0 11-2.096 0 1.048 1.048 0 012.096 0zM81.666 52.286a1.048 1.048 0 11-2.096 0 1.048 1.048 0 012.096 0z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.047 24.548a.5.5 0 01.5-.5h4.238a.5.5 0 110 1h-4.238a.5.5 0 01-.5-.5zM46.047 27.166a.5.5 0 01.5-.5h4.238a.5.5 0 010 1h-4.238a.5.5 0 01-.5-.5zM46.047 29.785a.5.5 0 01.5-.5h4.238a.5.5 0 110 1h-4.238a.5.5 0 01-.5-.5z"
        fill="#2F8DEE"
      />
      <circle cx={44.477} cy={24.524} r={0.524} fill="#2F8DEE" />
      <circle cx={44.477} cy={27.143} r={0.524} fill="#2F8DEE" />
      <circle cx={44.477} cy={29.762} r={0.524} fill="#2F8DEE" />
      <defs>
        <linearGradient
          id="vec_desktop_svg__paint0_linear"
          x1={67.794}
          y1={37.313}
          x2={67.794}
          y2={-8.37}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DEDEDE" stopOpacity={0} />
          <stop offset={1} stopColor="#A9A9A9" stopOpacity={0.3} />
        </linearGradient>
        <linearGradient
          id="vec_desktop_svg__paint1_linear"
          x1={62.071}
          y1={76.008}
          x2={62.071}
          y2={63.052}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#96A1C5" stopOpacity={0.373} />
        </linearGradient>
        <linearGradient
          id="vec_desktop_svg__paint2_linear"
          x1={65.474}
          y1={79.706}
          x2={65.474}
          y2={58.43}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#919191" stopOpacity={0.15} />
        </linearGradient>
        <linearGradient
          id="vec_desktop_svg__paint3_linear"
          x1={65.847}
          y1={14.048}
          x2={65.847}
          y2={55.953}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#93C7FB" />
          <stop offset={1} stopColor="#4F9AE7" />
        </linearGradient>
        <linearGradient
          id="vec_desktop_svg__paint4_linear"
          x1={68.45}
          y1={66.952}
          x2={68.45}
          y2={55.367}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECF5FD" />
          <stop offset={1} stopColor="#5FA8F2" />
        </linearGradient>
        <linearGradient
          id="vec_desktop_svg__paint5_linear"
          x1={65.36}
          y1={58.816}
          x2={65.36}
          y2={67.999}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F8DEE" />
          <stop offset={1} stopColor="#ECF5FD" />
        </linearGradient>
        <linearGradient
          id="vec_desktop_svg__paint6_linear"
          x1={65.862}
          y1={26.144}
          x2={65.862}
          y2={57}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F8DEE" />
          <stop offset={1} stopColor="#5FA8F2" />
        </linearGradient>
        <linearGradient
          id="vec_desktop_svg__paint7_linear"
          x1={65.79}
          y1={17.715}
          x2={65.79}
          y2={49.667}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECF5FD" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="vec_desktop_svg__paint8_linear"
          x1={59.336}
          y1={34.477}
          x2={59.336}
          y2={43.689}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5FA8F2" />
          <stop offset={1} stopColor="#2F8DEE" />
        </linearGradient>
        <linearGradient
          id="vec_desktop_svg__paint9_linear"
          x1={68.765}
          y1={34.477}
          x2={68.765}
          y2={43.689}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5FA8F2" />
          <stop offset={1} stopColor="#2F8DEE" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgVecDesktop
