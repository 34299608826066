import { SVGProps } from 'react'

function SvgShop(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.336 3.189V1.687a.375.375 0 0 0-.375-.375H2.039a.375.375 0 0 0-.375.375V3.19a.375.375 0 0 0-.352.373v1.544a2.074 2.074 0 0 0 .352 1.164v4.043c0 .206.167.374.375.374H9.96a.375.375 0 0 0 .375-.374V6.27a2.095 2.095 0 0 0 .351-1.164V3.563c0-.2-.156-.363-.351-.374ZM2.507 2.156h6.985v1.031H2.507v-1.03ZM6.75 9.845h-1.5v-1.22h1.5v1.22Zm2.742 0H7.5V8.25a.375.375 0 0 0-.375-.375h-2.25a.375.375 0 0 0-.375.375v1.595H2.507V7.007l.106.047c.261.11.539.165.825.165a2.117 2.117 0 0 0 1.278-.424.007.007 0 0 1 .007 0 2.115 2.115 0 0 0 1.279.424 2.117 2.117 0 0 0 1.279-.424.007.007 0 0 1 .007 0 2.114 2.114 0 0 0 1.278.424c.286 0 .563-.055.825-.165l.106-.047v2.838h-.005Zm.351-4.74c0 .702-.574 1.27-1.28 1.27-.479 0-.896-.259-1.116-.643a.188.188 0 0 0-.163-.095h-.007a.19.19 0 0 0-.163.095A1.28 1.28 0 0 1 6 6.375c-.477 0-.893-.258-1.114-.641a.194.194 0 0 0-.335 0 1.285 1.285 0 0 1-1.114.641c-.707 0-1.281-.568-1.281-1.27V4.038c0-.003.002-.006.006-.006h7.676c.003 0 .005.003.005.006v1.069Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgShop
