import * as React from 'react'

function SvgDownloadBox(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.582 5.766 8.35 7.998V.984a.85.85 0 0 0-1.7 0v7.014L4.418 5.766a.85.85 0 0 0-1.202 1.202l3.683 3.684a.846.846 0 0 0 1.206-.005l3.68-3.679a.85.85 0 1 0-1.203-1.202Zm2.585 4.851a.85.85 0 0 1 1.7 0v3.117c0 .626-.508 1.133-1.134 1.133H1.267a1.133 1.133 0 0 1-1.134-1.133v-3.117a.85.85 0 0 1 1.7 0v2.55h11.334v-2.55Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgDownloadBox
