import { css } from 'emotion'
import AntTabs, { TabsProps } from 'antd/es/tabs'
import 'antd/es/tabs/style/css'
import { MAIN_FONT, FONT_SIZE } from '../../../themes/deprecated/TypographyStyles'
import { useTheme } from '../../../themes/deprecated/themeContext'

function Tabs({ children, ...rest }: TabsProps) {
  const { base, interactive } = useTheme()
  const style = `
    font-family: ${MAIN_FONT};
    font-size: ${FONT_SIZE.Body};

    &.ant-tabs {
      width: 100%;
    }

    .ant-tabs-ink-bar {
      z-index: 0;
    }

    .ant-tabs-tab-btn {
      color: ${interactive.inactive};
      font-weight: bold;
    }

    .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${base.neutral}!important;
      font-weight:bold;
    }

    .ant-tabs-nav .ant-tabs-tab {
      margin-right: 20px;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-nav .ant-tabs-tab:hover,
    .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${base.primary};
    }
    
    .ant-tabs-ink-bar {
      background-color: ${base.primary}; 
    }
    `

  return (
    <AntTabs className={`${css(style)}`} {...rest}>
      {children}
    </AntTabs>
  )
}

Tabs.TabPane = AntTabs.TabPane

export default Tabs
