import * as React from 'react'

function SvgToolbar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3724_5503)">
        <path
          d="M17.0406 2.65483C17.014 2.35812 16.9239 2.07061 16.7763 1.8118C16.6288 1.55299 16.4274 1.32893 16.1856 1.15483C15.7761 0.883301 15.2855 0.761163 14.7965 0.808974C14.3074 0.856785 13.8498 1.07162 13.5006 1.41733L12.2781 2.60983H3.95312C3.15748 2.60983 2.39441 2.9259 1.8318 3.48851C1.2692 4.05111 0.953125 4.81418 0.953125 5.60983V14.2498C0.953125 15.0455 1.2692 15.8085 1.8318 16.3711C2.39441 16.9338 3.15748 17.2498 3.95312 17.2498H12.2031C12.9988 17.2498 13.7618 16.9338 14.3244 16.3711C14.8871 15.8085 15.2031 15.0455 15.2031 14.2498V5.60983C15.2031 5.57233 15.2031 5.53483 15.2031 5.49733L16.4256 4.28233C16.6414 4.07285 16.8083 3.81843 16.9146 3.53715C17.0209 3.25586 17.0639 2.95462 17.0406 2.65483V2.65483ZM9.29312 9.83983C9.18704 9.94805 9.05554 10.028 8.91062 10.0723L7.19312 10.6123L7.74062 8.89483C7.7842 8.75127 7.86133 8.62016 7.96562 8.51233L13.1481 3.33733L14.4756 4.65733L9.29312 9.83983ZM15.6531 3.48733L15.2706 3.86233L13.9431 2.54233L14.2506 2.24983C14.4496 2.05041 14.719 1.93726 15.0006 1.93483C15.1829 1.93259 15.3613 1.98768 15.5106 2.09233C15.6238 2.17073 15.7182 2.2733 15.787 2.39263C15.8557 2.51196 15.8971 2.64506 15.9081 2.78233C15.9147 2.91089 15.8955 3.03947 15.8517 3.16053C15.8079 3.28159 15.7404 3.39269 15.6531 3.48733V3.48733Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3724_5503">
          <rect width="18" height="18" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgToolbar
