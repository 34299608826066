import { useEffect, useMemo, useState } from 'react'
import { Provider } from 'react-redux'
import styled from '@emotion/styled'
import {
  ModalProvider,
  ThemeProvider,
  Typography,
  fdsTheme,
  fazzbizTheme,
} from '@fazz/design-system'
import { FazzBusinessLogo } from '@fazz/design-system-logos'
import { BrowserRouter } from '~/hooks/useReactRouter'
import BizRoot from '~/biz/Root'
import { store as bizStore } from '~/biz/store/store'
import RegionalRoot from '~/bizRegional/Root'
import { store as regionalStore } from '~/bizRegional/store/store'
import {
  useGetExternalIdentities,
  usePostExternalIdentity,
} from './api/initialisation/initialisation.hooks'
import type { ExternalIdentity } from './api/initialisation/initialisation.types'
import PageLoader from './components/PageLoader'
import RegionSelector from './components/RegionSelector'
import { useAppTypeContext } from './hooks/useAppTypeContext'
import { HeaderContext } from './hooks/useHeaderContext'
import { AppType, BusinessRegistrationCountry } from './types'
import type { Header } from './types'
import SessionExpiryDialog from './components/SessionExpiry'

const { spacing } = fdsTheme
const { Body } = Typography

export default function Root() {
  // Global context for setting page header
  const [header, setHeader] = useState<Header>('')
  const useHeader = (value: Header) => useEffect(() => setHeader(value), [])
  const headerProviderValue = useMemo(
    () => ({ header, setHeader, useHeader }),
    [header, setHeader, useHeader]
  )
  const { appType, appTypeFetched } = useAppTypeContext()
  const [isPaymentClientSetup, setIsPaymentClientSetup] = useState(false)

  const { data: externalIdentitiesData } = useGetExternalIdentities()
  const externalIdentities: ExternalIdentity[] | undefined = externalIdentitiesData?.data.data

  const { mutateAsync: createPaymentClient } = usePostExternalIdentity({
    onSuccess: (resp) => {
      if (resp?.data.data.length > 0) {
        setIsPaymentClientSetup(true)
      }
    },
  })
  useEffect(() => {
    /**
     * The following function checks if the business has "Cash Account" created. Eg: SGD / IDR
     * If it's not created, proceed to create
     *
     * Note that appType needs to be determined first before executing the following function.
     * Hence appType and appTypeFetched are both required for the green light to go ahead.
     *
     * If appTypeFetched is true, and appType is undefined, send User to select a country first.
     */

    if (appType && externalIdentities) {
      const paymentServiceLabel = `${appType === AppType.INDONESIA ? 'ID' : 'SG'}_PAYMENT_SERVICE`

      const paymentClientExist = externalIdentities.some((extId) =>
        extId.attributes.serviceName.includes(paymentServiceLabel)
      )

      if (paymentClientExist) {
        setIsPaymentClientSetup(true)
        return
      }

      const registrationCountry =
        appType === AppType.INDONESIA
          ? BusinessRegistrationCountry.INDONESIA
          : BusinessRegistrationCountry.SINGAPORE

      createPaymentClient({
        data: {
          attributes: {
            country: registrationCountry,
          },
        },
      })
    }
  }, [appType, appTypeFetched, externalIdentities])

  if (!appTypeFetched) {
    return <PageLoader />
  }

  if (!appType) {
    return (
      <ThemeProvider theme={fazzbizTheme}>
        <RegionSelector />
      </ThemeProvider>
    )
  }

  if (!isPaymentClientSetup) {
    return (
      <Container>
        <FazzBusinessLogo width={120} height={56} />
        <Body size="lg">Setting Up Cash Accounts...</Body>
      </Container>
    )
  }

  const isIdAppType = appType === AppType.INDONESIA
  const store = isIdAppType ? bizStore : regionalStore
  const rootComponent = isIdAppType ? <BizRoot /> : <RegionalRoot />

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={fazzbizTheme}>
          <ModalProvider>
            <HeaderContext.Provider value={headerProviderValue}>
              {rootComponent}
              <SessionExpiryDialog />
            </HeaderContext.Provider>
          </ModalProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.lg};
  align-items: center;
  justify-content: center;
  height: 100vh;
`
