import { SVGProps } from 'react'

function SvgCreditCard(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.475 11.2H3.9a.625.625 0 0 1 0-1.25h2.575a.625.625 0 1 1 0 1.25Z"
        fill="currentColor"
      />
      <path
        d="M14.625 3.025h-9.25A4.583 4.583 0 0 0 .833 7.608v4.784a4.584 4.584 0 0 0 4.584 4.583h9.25a4.583 4.583 0 0 0 4.583-4.583V7.608a4.584 4.584 0 0 0-4.625-4.583Zm-9.25 1.25h9.25a3.334 3.334 0 0 1 3.333 3.092H2.125a3.333 3.333 0 0 1 3.25-3.092Zm9.25 11.45h-9.25a3.333 3.333 0 0 1-3.333-3.333V8.617h15.916v3.775a3.333 3.333 0 0 1-3.333 3.333Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCreditCard
