/** 

      * @description Do not import this file directly unless you are editing `theme-fds3.ts` 
*/
import type { TokensFds3Types } from './_tokens-fds3-dist-types'

export const tokensFds3: TokensFds3Types = {
  radius: {
    xs: '4px',
    sm: '8px',
    md: '12px',
    lg: '16px',
    xl: '999px',
  },
  fazzbizPrimitives: {
    subdueTurquoise: '#E5F9FB',
    purple: '#6A56EB',
    subduePurple: '#F0EEFD',
    yellow: '#E5C412',
    subdueYellow: '#FFFBE9',
    subdueCerulean: '#E5F5FF',
  },
  colors: {
    white: '#FFFFFF',
    gray100: '#F6F7F9',
    gray200: '#F2F4F5',
    gray300: '#ECECEC',
    gray400: '#E3E3E3',
    gray500: '#E0E0E0',
    gray600: '#BDBDBD',
    gray700: '#B6B6B6',
    gray800: '#9D9E9F',
    gray900: '#757575',
    gradientSkeleton: 'linear-gradient(90deg, #E0E0E0 0%, #F2F4F5 88.71%)',
    overlay: '#00000080',
    black: '#121212',
    blue300: '#E7ECFC',
    blue500: '#0C45E1',
    blue600: '#0D3DC0',
    blue700: '#0D39AF',
    blueSecondary500: '#E2E9FB',
    blueSecondary600: '#D1D8E8',
    blueSecondary700: '#C9CFDF',
    red300: '#FBEAE9',
    red500: '#D93025',
    red600: '#B92B22',
    red700: '#A92920',
    redSecondary500: '#FBEAE9',
    redSecondary600: '#E8D9D8',
    redSecondary700: '#DFD0CF',
    green300: '#E5F8F3',
    green500: '#00B583',
    orange300: '#FEF3E7',
    orange500: '#F18813',
  },
  rootPrimitives: {
    richDark: '#121212',
    doctor: '#FFFFFF',
    cultured: '#F2F4F5',
    russianViolet: '#1A1336',
    turquoiseSurf: '#00C1D5',
    vividCerulean: '#00A0FF',
    royalBlue: '#3C64E1',
    majesticBlue: '#0925D9',
    purpleNavy: '#574E86',
  },
  shadow: {
    raised: '0px 2px 4px 0px rgba(51, 51, 51, 0.16)',
    action: '0px 4px 8px 0px rgba(51, 51, 51, 0.16)',
    overlay: '0px 8px 16px 0px rgba(51, 51, 51, 0.16)',
    reverseRaised: '0px -1px 4px 0px rgba(51, 51, 51, 0.08)',
  },
  spacing: {
    xxs: '4px',
    xs: '8px',
    sm: '12px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    xxl: '40px',
  },
  fontFamily: {
    fazzNeue: 'FazzNeue',
  },
  fontSize: {
    displayLarge: '64px',
    displayMedium: '45px',
    displaySmall: '36px',
    headlineLarge: '28px',
    headlineMedium: '24px',
    headlineSmall: '20px',
    titleLarge: '28px',
    titleMedium: '24px',
    titleSmall: '20px',
    labelLarge: '16px',
    labelMedium: '14px',
    labelSmall: '12px',
    bodyLarge: '16px',
    bodyMedium: '14px',
    bodySmall: '12px',
  },
  fontWeight: {
    regular: 400,
    bold: 700,
  },
  letterSpacing: {
    display: '-0.03em',
    headline: '0em',
    title: '-0.02em',
    label: '-0.01em',
    body: '-0.01em',
  },
  lineHeight: {
    displayLarge: '78px',
    displayMedium: '54px',
    displaySmall: '44px',
    headlineLarge: '48px',
    headlineMedium: '42px',
    headlineSmall: '36px',
    titleLarge: '42px',
    titleMedium: '36px',
    titleSmall: '30px',
    labelLarge: '24px',
    labelMedium: '20px',
    labelSmall: '18px',
    bodyLarge: '24px',
    bodyMedium: '20px',
    bodySmall: '18px',
  },
}
