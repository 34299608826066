import { FormItem, Radio } from 'formik-antd'
import styled from 'styled-components'
import { fdsTheme } from '../../../../themes/theme-fds3'

// Retrieve all theming data
const { spacing, colors, fontStyles } = fdsTheme

export const StyledFormItem = styled(FormItem)`
  &.ant-form-item {
    margin-bottom: 0px;

    .ant-form-item-control-input {
      min-height: 24px;
    }
  }

  // error state + error message
  &.ant-form-item-has-error {
    .ant-input {
      border-color: ${colors.critical};
    }

    .ant-form-item-explain-error {
      margin-top: ${spacing.xs};
      color: ${colors.critical};
      text-align: left;
    }
  }

  // helper text
  .ant-form-item-extra {
    ${fontStyles.bodySmall};
    margin-top: ${spacing.xs};
    text-align: left;
    color: ${colors.helperInput};
  }

  // we want to hide if there is an error message - May be problematic if we want to show more errors in the future.
  .ant-form-item-control div:nth-of-type(3) {
    display: none;
  }
`

export const StyledRadio = styled(Radio.Group)`
  &.ant-radio-group {
    .ant-radio-checked .ant-radio-inner {
      border-color: ${colors.active}; // radio outer border
    }

    .ant-radio-inner {
      border-color: ${colors.inactive}; // radio outer border (inactive)

      &::after {
        background-color: ${colors.active}; // radio inner circle
      }

      &:focus-visible {
        outline: 1px solid ${colors.active}; // radio active/focus state
      }
    }

    // radio label style
    .ant-radio-wrapper {
      margin-right: ${spacing.sm};

      &span:nth-of-type(2) {
        color: ${colors.onNeutral};
        ${fontStyles.bodyMedium}
        display: inline;
      }
    }
  }
`
