import { type FixMeLater } from '~/types'
import { type CreditLineTermLoanPayoutFormRequestPayload } from '~/types/disbursement'
import type { AddRecipientRequestPayload } from '~/types/recipients'
import { ModeManager } from '~/managers/ModeManager'
import { get, post, del } from '../fazzbizBaseApi'
import { downloadFileFromBase64 } from '../helpers'
import type { LedgerDetailsResponse, SgWalletTag, Wallet, WalletLedger } from './sgPay.api.types'

enum SGpayQueryKey {
  Wallets = 'Wallets',
  Recipients = 'Recipients',
  WalletOrders = 'WalletOrders',
  BillingSummary = 'BillingSummary',
  Ledgers = 'Ledgers',
  LedgerDetails = 'LedgerDetails',
}

enum SGpayEndpoints {
  Wallets = 'dashboard/wallets/',
  WalletOrders = 'WalletOrders',
  Recipients = 'dashboard/loans/recipients',
  BillingSummary = 'dashboard/sg-credit-line/supplier-transfer/billing-summary',
  CreditLineBillingInvoicePDF = 'dashboard/sg-credit-line/supplier-transfer/bills',
  Disbursement = 'dashboard/payout_form/batches',
  DisbursementWhitelist = 'dashboard/payout_form/whitelist',
  Ledgers = 'dashboard/payments/sg/ledgers',
}

const getWalletTags = (isSandbox: boolean) =>
  get(SGpayEndpoints.Wallets, { headers: { sandbox: String(isSandbox) } })

const getWallets = () =>
  new Promise((resolve, reject) => {
    get(SGpayEndpoints.Wallets, {
      headers: {
        sandbox: String(ModeManager.isSandboxMode()),
      },
    })
      .then((response) => {
        const { data, included } = response.data
        const wallets: Map<SgWalletTag, Wallet | null> = new Map([])

        const ledgersMap = included.reduce(
          (mapAccumulator: Map<string, WalletLedger>, obj: WalletLedger) => {
            mapAccumulator.set(obj.id, obj)
            return mapAccumulator
          },
          new Map()
        )
        data.forEach((_: FixMeLater) => {
          const wallet = { ..._ }
          const ledgerIds = _.relationships.ledgers.data.map((ledger: FixMeLater) => ledger.id)
          wallet.relationships.ledgers = ledgerIds.map((ledgerId: string) =>
            ledgersMap.get(ledgerId)
          )
          wallets.set(_.attributes.tag, wallet)
        })
        resolve(wallets)
      })
      .catch((e) => {
        reject(e)
      })
  })

const getRecipients = (queryParams: string) => get(`${SGpayEndpoints.Recipients}?${queryParams}`)

const getWalletOrders = (walletId: string, queryString: string) =>
  get(`${SGpayEndpoints.Wallets}${walletId}/orders?${queryString}`, {
    headers: {
      sandbox: String(ModeManager.isSandboxMode()),
    },
  })

const addPayDisbursement = (
  requestBody: CreditLineTermLoanPayoutFormRequestPayload,
  walletId: string
) => post(`${SGpayEndpoints.Wallets}${walletId}/disbursements`, requestBody)

const addRecipient = (requestBody: AddRecipientRequestPayload) =>
  post(SGpayEndpoints.Recipients, requestBody)

const getWalletOrder = (walletId: string, orderId: string) =>
  get(`${SGpayEndpoints.Wallets}${walletId}/orders/${orderId}`)

const deleteRecipient = (recipientId: string) => del(`${SGpayEndpoints.Recipients}/${recipientId}`)

const getSGcreditLineBillingSummary = () => get(SGpayEndpoints.BillingSummary)

const getBillingInvoicePDF = async (billId: string, billNumber: string) => {
  const response = await get(`${SGpayEndpoints.CreditLineBillingInvoicePDF}/${billId}/pdf`)
  return downloadFileFromBase64(
    response.data.data.attributes.base64,
    'application/pdf',
    billNumber,
    '.pdf'
  )
}
const getLedgers = () => get(SGpayEndpoints.Ledgers)

const getLedgerDetails = (transactionId: string) =>
  get<LedgerDetailsResponse>(`${SGpayEndpoints.Ledgers}/${transactionId}`)

export {
  SGpayQueryKey,
  SGpayEndpoints,
  getWallets,
  getWalletTags,
  getRecipients,
  addPayDisbursement,
  getWalletOrders,
  addRecipient,
  getWalletOrder,
  deleteRecipient,
  getSGcreditLineBillingSummary,
  getBillingInvoicePDF,
  getLedgers,
  getLedgerDetails,
}
