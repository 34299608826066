import { SVGProps } from 'react'

function SvgUploadFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m15.127 5.543-4.305-4.305A1.5 1.5 0 0 0 9.75.795H6.937a4.5 4.5 0 0 0-4.5 4.5v7.41a4.5 4.5 0 0 0 4.5 4.5h4.125a4.5 4.5 0 0 0 4.5-4.5V6.6a1.5 1.5 0 0 0-.434-1.057Zm-1.237.36h-.638a2.625 2.625 0 0 1-2.625-2.625V2.64l3.263 3.263ZM9.75 7.26l2.018 2.018a.549.549 0 0 1 .164.397.557.557 0 0 1-.165.398.563.563 0 0 1-.794 0l-1.38-1.38v4.162a.562.562 0 1 1-1.126 0V8.67L7.05 10.065a.563.563 0 0 1-.795-.795L8.25 7.26a1.035 1.035 0 0 1 .75-.3 1.012 1.012 0 0 1 .75.3Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgUploadFilled
