import { SVGProps } from 'react'

function SvgWarningFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 1.306a2.955 2.955 0 0 0-2.573 1.5L.975 12.241a3 3 0 0 0 2.565 4.455h10.92a3 3 0 0 0 2.565-4.455l-5.453-9.45A2.955 2.955 0 0 0 9 1.291v.015Zm-.615 12.195a.832.832 0 0 1-.255-.63.788.788 0 0 1 .255-.615.9.9 0 0 1 1.237-.015.795.795 0 0 1 .263.615.84.84 0 0 1-.263.63.848.848 0 0 1-.622.247.878.878 0 0 1-.615-.232ZM8.25 8.506V5.904h1.537v2.602l-.135 2.602H8.377L8.25 8.506Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgWarningFilled
