import * as React from 'react'
import { SVGProps } from 'react'

function SvgGoPay(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 80 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <path
        d="M8.7317 4C13.5556 4 17.4634 7.76866 17.4634 12.4186C17.4634 17.0686 13.5556 20.8372 8.7317 20.8372C3.90781 20.8488 0 17.0802 0 12.4186C0 7.76866 3.90781 4 8.7317 4Z"
        fill="#00AED6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8221 12.1635C13.7525 11.0967 12.8133 10.2734 11.7001 10.3198H6.18043C5.9833 10.3198 5.82095 10.1574 5.82095 9.9719C5.82095 9.77477 5.9833 9.62402 6.18043 9.62402H11.7696C11.7349 8.89348 11.2246 8.2789 10.4941 8.08177C8.87067 7.80347 7.20086 7.80347 5.56584 8.08177C4.63817 8.30209 3.94242 9.04423 3.81487 9.9603C3.55976 11.6069 3.55976 13.2883 3.81487 14.9465C4.012 15.909 4.80052 16.6627 5.80936 16.8251C7.82704 17.0686 9.86792 17.0686 11.8856 16.8251C12.7901 16.6511 13.4626 15.9322 13.5786 15.0509C13.7757 14.0884 13.8453 13.126 13.8221 12.1635ZM12.0016 13.3463V13.6594C12.0016 13.8565 11.8392 14.0073 11.6421 14.0073C11.445 14.0073 11.2826 13.8449 11.2826 13.6594V13.3463C11.1667 13.2419 11.0971 13.1028 11.0971 12.952C11.0971 12.6621 11.3406 12.4302 11.6421 12.4302C11.9436 12.4302 12.1871 12.6621 12.1871 12.952C12.1871 13.1028 12.1175 13.2419 12.0016 13.3463Z"
        fill="white"
      />
      <path
        d="M42.3136 11.4798C42.3252 10.0071 41.0961 8.80111 39.5654 8.78952C38.0348 8.77792 36.7824 9.9607 36.7708 11.445C36.7592 12.9176 37.9884 14.1236 39.519 14.1352C39.5306 14.1352 39.5422 14.1352 39.5654 14.1352C41.0149 14.2048 42.2557 13.138 42.3368 11.7349C42.3368 11.6537 42.3368 11.5725 42.3368 11.4798H42.3136ZM34.0806 11.4798C34.0806 8.67356 36.3766 6.52832 39.5654 6.52832C42.7543 6.52832 45.0503 8.68515 45.0503 11.4798C45.0503 14.2744 42.7775 16.4312 39.5654 16.4312C36.365 16.4312 34.0806 14.2744 34.0806 11.4798Z"
        fill="black"
      />
      <path
        d="M29.7203 11.9899C29.7203 13.1494 28.5027 14.1003 26.9489 14.1003C25.5226 14.2395 24.2587 13.2422 24.1079 11.8623C24.0963 11.7231 24.0963 11.584 24.0963 11.4448C24.0963 9.91419 25.2559 8.85897 27.0533 8.85897C28.5028 8.85897 29.7203 9.82143 29.7203 10.9114V11.9899ZM29.8247 6.5166V7.62981C28.9434 6.85288 27.7722 6.45862 26.5778 6.5282C23.76 6.55139 21.4872 8.7778 21.5104 11.5028C21.5336 14.2279 23.8412 16.4079 26.6706 16.3847C27.8302 16.4311 28.9666 16.0136 29.7899 15.2251V16.176C29.7899 17.4631 28.6419 18.4024 27.1228 18.4024C25.9633 18.4835 24.8269 18.1009 23.9804 17.3356L22.2178 18.9822C23.5397 20.2345 25.3487 20.9071 27.204 20.8491C30.3001 20.8491 32.4222 18.843 32.4222 16.1644V6.5166H29.8247Z"
        fill="black"
      />
      <path
        d="M46.6504 6.80648H49.2595V7.62979H49.2943C50.1524 6.87606 51.2772 6.4818 52.4252 6.53978C55.2546 6.64414 57.4694 8.94012 57.365 11.6768C57.2607 14.2626 55.1154 16.3499 52.4252 16.4427C51.3235 16.4658 50.2451 16.118 49.387 15.457V20.1417H46.6736L46.6504 6.80648ZM52.0077 8.85895C50.5582 8.85895 49.3638 9.83301 49.3638 10.8998V12.0826C49.3638 13.2074 50.535 14.1467 52.0193 14.1467C53.5268 14.2162 54.8255 13.103 54.8951 11.642C54.9763 10.1809 53.8051 8.94013 52.2976 8.85895C52.2048 8.85895 52.1121 8.85895 52.0193 8.85895H52.0077Z"
        fill="black"
      />
      <path
        d="M62.5715 10.575C64.3572 10.3431 64.8791 10.0996 64.8791 9.62418C64.8791 9.0096 64.2065 8.63853 63.1513 8.63853C62.0265 8.54576 60.9944 9.26471 60.7277 10.3315L58.165 9.82131C58.5245 7.90799 60.5654 6.55127 63.0701 6.55127C65.9111 6.55127 67.6621 7.95437 67.6621 10.2272V16.1759H65.2269V15.1322H65.1921C64.3688 16.0251 63.1513 16.5121 61.9105 16.4194C59.7653 16.4194 58.2694 15.2946 58.2694 13.6132C58.2926 11.9434 59.5218 10.9809 62.5715 10.575ZM65.111 11.5607H65.0762C64.8327 11.897 64.3456 12.0941 63.0005 12.3144C61.4119 12.6043 60.8205 12.9058 60.8205 13.4508C60.8205 13.9958 61.2959 14.2625 62.3164 14.2625C63.8818 14.2625 65.0878 13.5552 65.0878 12.6739L65.111 11.5607Z"
        fill="black"
      />
      <path
        d="M72.6019 15.6427L68.1143 6.80664H71.0944L74.0513 12.9293H74.0861L76.9967 6.80664H80L73.286 20.1419H70.2943L72.6019 15.6427Z"
        fill="black"
      />
    </svg>
  )
}

export default SvgGoPay
