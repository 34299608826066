import type { OnExportProps } from '@fazz/design-system/src/components/molecules/FileExportDialog/FileExportDialog.types'
import type { VerificationStatusType } from '~/api/idVerification/idVerification.types'

export type LoanData = {
  borrower: {
    uniqueId: string
    name: string
  }
  uniqueId: string
  code: string
  nominal: string
  fee: {
    originationFeePercentage: number
    originationFeeAmount: string
    originationTaxPercentage: number
    originationTaxAmount: string
    totalFeeAmount: string
  }
  borrowerInterestPa: number
  disbursementAmount: string
  totalInterestAmount: string
  totalInitialRepaymentAmount: string
  totalLateFee: number
  totalBillAmount: string
  totalPaidAmount: number
  loanType: string
  crowdfunding: {
    duration: number
    start: string
    stop: string
    remainingDays: number
    progress: number
    totalFund: string
  }
  repaymentMode: string
  tenor: number
  status: LoanAccountTabKey
  maturityDate: string
  nextInstallmentDuedate: string
  nextInstallmentSequence: string
  totalInstallmentSequence: number
  lastDpd: number
  maxDpd: number
  submissionAt: string
  activeAt: string
  repaymentAt: string
}

export type LoansHistory = {
  data: LoanData[]
  meta: {
    page: number
    limit: number
    totalData: number
    totalPage: number
  }
}

export type LoanSummary = {
  totalLoanNominal: number
  totalLoanActiveNominal: number
  totalLoanCompleteNominal: number
  totalLateLoanNominal: number
  totalLoanBadDebtNominal: number
  totalLoanDefaultNominal: number
}

export type LoanTimelineItem = {
  status: LoanAccountTabKey
  timestamp: string
}

export type LoanTimeline = {
  data: LoanTimelineItem[]
}

export enum LoansActions {
  SET_BUSINESS_VERIFICATION_STATUS = 'LoansActions.SET_BUSINESS_VERIFICATION_STATUS',
  SET_LOANS_HISTORY = 'LoansActions.SET_LOANS_HISTORY',
  SET_LOANS_HISTORY_LOADING = 'LoansActions.SET_LOANS_HISTORY_LOADING',
  SET_LOAN_SUMMARY = 'LoansActions.SET_LOAN_SUMMARY',
  SET_LOAN_SUMMARY_LOADING = 'LoansActions.SET_LOAN_SUMMARY_LOADING',
  SET_LOAN_DETAIL = 'LoansActions.SET_LOAN_DETAIL',
  SET_LOAN_DETAIL_LOADING = 'LoansActions.SET_LOAN_DETAIL_LOADING',
  SET_LOAN_TIMELINE = 'LoansActions.SET_LOAN_TIMELINE',
  SET_LOAN_TIMELINE_LOADING = 'LoansActions.SET_LOAN_TIMELINE_LOADING',
  SET_LOAN_VERIFICATION_STATUS = 'LoansActions.SET_LOAN_VERIFICATION_STATUS',
}

export enum LoanAccountTabKey {
  INCOMING_TRANSACTION = 'incoming_transaction',
  OUTGOING_TRANSACTION = 'outgoing_transaction',
  PENDING_APPROVAL = 'pending_approval',
  AWAITING_CONFIRMATION = 'waiting_borrower_confirmation',
  CROWDFUNDING = 'crowdfunding',
  ACTIVE = 'active',
  DUE_DATE = 'due',
  PAID = 'paid',
}

export type LoanFileExport = {
  format: OnExportProps['format']
  startDate: string
  endDate: string
  uuid: string
  type: 'MorakPaymentOrder' | 'MorakDisbursementOrder'
}

type SetBusinessVerificationStatus = {
  type: typeof LoansActions.SET_BUSINESS_VERIFICATION_STATUS
  businessVerificationStatus: VerificationStatusType
  amVerificationStatus: VerificationStatusType
}

type SetLoansHistory = {
  type: typeof LoansActions.SET_LOANS_HISTORY
  loansHistory: LoansHistory
}

type SetLoansHistoryLoading = {
  type: typeof LoansActions.SET_LOANS_HISTORY_LOADING
  loansHistoryLoading: boolean
}

type SetLoanSummary = {
  type: typeof LoansActions.SET_LOAN_SUMMARY
  loanSummary: LoanSummary
}

type SetLoanSummaryLoading = {
  type: typeof LoansActions.SET_LOAN_SUMMARY_LOADING
  loanSummaryLoading: boolean
}

type SetLoanDetail = {
  type: typeof LoansActions.SET_LOAN_DETAIL
  loanDetail: LoanData
}

type SetLoanDetailLoading = {
  type: typeof LoansActions.SET_LOAN_DETAIL_LOADING
  loanDetailLoading: boolean
}

type SetLoanTimeline = {
  type: typeof LoansActions.SET_LOAN_TIMELINE
  loanTimeline: LoanTimeline
}

type SetLoanTimelineLoading = {
  type: typeof LoansActions.SET_LOAN_TIMELINE_LOADING
  loanTimelineLoading: boolean
}

type SetLoanVerificationStatus = {
  type: typeof LoansActions.SET_LOAN_VERIFICATION_STATUS
  loanVerificationStatus: boolean
}

export type ActionTypes =
  | SetBusinessVerificationStatus
  | SetLoansHistory
  | SetLoansHistoryLoading
  | SetLoanSummary
  | SetLoanSummaryLoading
  | SetLoanDetail
  | SetLoanDetailLoading
  | SetLoanTimeline
  | SetLoanTimelineLoading
  | SetLoanVerificationStatus
