import { Dispatch } from 'react'
import { CustomerCardTabKeyType } from '~/sharedModules/DebitCards/CustomerCards/type'
import { ActionTypes, CustomerCardActions } from './types'

export const setCustomerCardsConfig = (res: any) => (dispatch: Dispatch<ActionTypes>) => {
  const { data } = res.data
  dispatch({
    type: CustomerCardActions.SET_CUSTOMER_CARDS_CONFIG,
    isCustomerCardAdminPanelEnabled: data.attributes.enableCardAdminPanel,
  })
}

export const setCardActiveTab =
  (cardActiveTab: CustomerCardTabKeyType) => (dispatch: Dispatch<ActionTypes>) => {
    dispatch({
      type: CustomerCardActions.SET_ACTIVE_TAB,
      cardActiveTab,
    })
  }
