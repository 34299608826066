import { fdsTheme } from 'fds3-src/themes/theme-fds3'
import styled from '@emotion/styled'
import { bodyStyle, bodySmallStyle } from '../../../themes/deprecated/fontStyles'

type LinkProps = {
  size?: 'small'
  color?: string
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

const { colors } = fdsTheme

function Link({ size, children, ...props }: LinkProps) {
  return (
    <StyledLink size={size} color={colors.primary} {...props}>
      {children}
    </StyledLink>
  )
}

const StyledLink = styled.a<LinkProps>`
  ${({ size }) => (size === 'small' ? bodySmallStyle : bodyStyle)};
  color: ${({ color }) => color};
  user-select: none;

  &:hover {
    color: ${({ color }) => color};
    text-decoration: underline;
  }
`

export default Link
