import { SVGProps } from 'react'

function SvgCreditCardFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.75 8.256v3.397a4.125 4.125 0 0 0 4.125 4.125H13.2a4.125 4.125 0 0 0 4.125-4.125V8.256H.75Zm5.115 2.325H3.51a.563.563 0 0 1 0-1.125h2.317a.563.563 0 1 1 0 1.125h.038ZM17.25 7.13a4.133 4.133 0 0 0-4.117-3.907H4.838A4.118 4.118 0 0 0 .75 7.13h16.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCreditCardFilled
