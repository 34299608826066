import * as React from 'react'

function SvgDocumentUpload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1rem" height="1rem" viewBox="0 0 40 40" fill="none" {...props}>
      <rect width="40" height="40" fill="#E5E5E5" />
      <path d="M5510 -4753H-4781V5989" stroke="#BDBDBD" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
      <g clipPath="url(#clip0_4296_167746)">
        <rect width="1440" height="900" transform="translate(-700 -200)" fill="#F6F7F9" />
        <rect x="-36" y="-96" width="752" height="172" rx="16" fill="white" />
        <rect x="-15.5" y="-15.5" width="711" height="71" rx="11.5" fill="white" />
        <rect width="40" height="40" rx="16" fill="#F4F5FA" />
        <path d="M28.17 15.3906L22.43 9.65058C22.2428 9.46221 22.0199 9.31303 21.7744 9.21173C21.5288 9.11043 21.2656 9.05905 21 9.06058H17.25C15.6587 9.06058 14.1326 9.69272 13.0074 10.8179C11.8821 11.9432 11.25 13.4693 11.25 15.0606V24.9406C11.25 26.5319 11.8821 28.058 13.0074 29.1832C14.1326 30.3084 15.6587 30.9406 17.25 30.9406H22.75C24.3413 30.9406 25.8674 30.3084 26.9926 29.1832C28.1179 28.058 28.75 26.5319 28.75 24.9406V16.8006C28.7504 16.2724 28.5419 15.7656 28.17 15.3906V15.3906ZM26.52 15.8706H25.67C24.7417 15.8706 23.8515 15.5018 23.1951 14.8455C22.5387 14.1891 22.17 13.2988 22.17 12.3706V11.5206L26.52 15.8706ZM21 17.6806L23.69 20.3706C23.7606 20.4395 23.8165 20.522 23.8543 20.6131C23.8921 20.7041 23.911 20.802 23.91 20.9006C23.9105 20.9991 23.8912 21.0968 23.8535 21.1878C23.8157 21.2788 23.7601 21.3613 23.69 21.4306C23.5494 21.571 23.3588 21.6499 23.16 21.6499C22.9612 21.6499 22.7706 21.571 22.63 21.4306L20.79 19.5906V25.1406C20.79 25.3395 20.711 25.5303 20.5703 25.6709C20.4297 25.8116 20.2389 25.8906 20.04 25.8906C19.8411 25.8906 19.6503 25.8116 19.5097 25.6709C19.369 25.5303 19.29 25.3395 19.29 25.1406V19.5606L17.4 21.4206C17.2578 21.5531 17.0698 21.6252 16.8755 21.6218C16.6812 21.6183 16.4958 21.5396 16.3584 21.4022C16.221 21.2648 16.1423 21.0794 16.1388 20.8851C16.1354 20.6908 16.2075 20.5028 16.34 20.3606L19 17.6806C19.1314 17.5503 19.2876 17.4477 19.4594 17.379C19.6312 17.3103 19.815 17.2768 20 17.2806C20.1852 17.2751 20.3696 17.3077 20.5416 17.3765C20.7137 17.4454 20.8697 17.5488 21 17.6806V17.6806Z" fill="currentColor" />
        <rect x="-15.5" y="-15.5" width="711" height="71" rx="11.5" stroke="#E0E0E0" />
      </g>
      <defs>
        <clipPath id="clip0_4296_167746">
          <rect width="1440" height="900" fill="white" transform="translate(-700 -200)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgDocumentUpload
