import { AppType } from '~/types'
import { get, post } from '../fazzbizBaseApi'
import ENDPOINTS from '../paths'
import getApiMethods from '../requestWrapper'
import type {
  EmployeeResponse,
  ExternalIdentitiesResponse,
  ExternalIdentityPayload,
  BannerInfoResponse,
  PreformattedKeycloakProfileResponse,
  VerificationStatusSummaryResponse,
} from './initialisation.types'

export const InitialisationEndpoints = {
  SSOProfileResponse: '/sso/profile',
  VerificationStatusSummary: '/dashboard/verification-status-summary',
  ActiveBanner: '/dashboard/banners',
  TncStatus: '/dashboard/core-identity/account-managers/id/tnc',
  Employees: '/dashboard/employers',
  ExternalIdentities: '/dashboard/external-identities',
}

export const getVerificationStatusSummary = () =>
  get<VerificationStatusSummaryResponse>(InitialisationEndpoints.VerificationStatusSummary)

export const getActiveBanner = () => get(InitialisationEndpoints.ActiveBanner)
export const getIdTncStatus = () => get(InitialisationEndpoints.TncStatus)
export const agreeIdTnc = () => post(InitialisationEndpoints.TncStatus)

export const getKeycloakProfile = () =>
  getApiMethods(AppType.SINGAPORE)
    .get(ENDPOINTS.API_SSO_PROFILE)
    .then((res: PreformattedKeycloakProfileResponse) => ({
      kcId: res.kc_id,
      fullName: res.full_name,
      email: res.email,
      countryCode: res.country_code,
      mobileNumber: res.mobile_number,
      companyId: res.company_id,
      bizId: res.merchant_id,
      userRole: res.user_role,
      sessionState: res.session_state,
    }))
export const getEmployees = () => get<EmployeeResponse>(InitialisationEndpoints.Employees)
export const getExternalIdentities = () =>
  get<ExternalIdentitiesResponse>(InitialisationEndpoints.ExternalIdentities)
export const postExternalIdentity = (payload: ExternalIdentityPayload) =>
  post(InitialisationEndpoints.ExternalIdentities, payload)
export const getBannerById = (id: string) =>
  get<BannerInfoResponse>(`${InitialisationEndpoints.ActiveBanner}/${id}`)
