import type { Reducer } from 'redux'
import type { ActionTypes, TransactionDetails } from './types'
import { TransactionDetailsActions } from './types'

const initialState = {
  isLoading: false,
  transactionId: '',
  transactionDetails: {
    key: '',
    transactionId: '',
    transactionType: '',
    transactionHash: null,
    transactionHashUrl: null,
    walletType: '',
    method: '',
    accountHolderName: '',
    amount: {
      prefix: '',
      postfix: '',
    },
    fee: '',
    tax: '',
    netAmount: {
      prefix: '',
      postfix: '',
    },
    createdDate: '',
    completedDate: '',
    status: '',
    note: null,
    sourceDestination: null,
    merchantDescription: null,
    failureReason: null,
    manualAssignments: null,
    bankAccountNo: null,
    bankName: null,
  },
}

type State = {
  isLoading: boolean
  transactionId: string
  transactionDetails: TransactionDetails
}

export const transactionDetails: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case TransactionDetailsActions.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }

    case TransactionDetailsActions.GET_TRANSACTION_DETAILS: {
      return {
        ...state,
        transactionDetails: action.transactionDetails,
      }
    }

    default:
      return state
  }
}
