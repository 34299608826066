import * as React from 'react'

function SvgDocument(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5v20h16V13h-2a2 2 0 01-2-2V5H7zM4 26V4a2 2 0 012-2h13.805a2 2 0 011.761 1.052l4.195 7.79a2 2 0 01.239.948V26a2 2 0 01-2 2H6a2 2 0 01-2-2zm11.5-10a1.5 1.5 0 000-3h-4a1.5 1.5 0 000 3h4zm4.5 3.5a1.5 1.5 0 01-1.5 1.5h-7a1.5 1.5 0 010-3h7a1.5 1.5 0 011.5 1.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgDocument
