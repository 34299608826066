import type { Reducer } from 'redux'
import { loadDisbursementState } from './storage'
import type { ActionTypes, Recipient, Bank, State } from './types'
import { DisbursementActions, DisbursementLoadActions, PaymentMethod, UploadMethod } from './types'

// IMPT: if you change the schema here you need to change DB_VERSION in ./storage.ts
const initialState = {
  uploadMethod: UploadMethod.unknown,
  paymentMethod: PaymentMethod.unknown,
  recipients: new Map<string, Recipient>(),
  banks: new Map<string, Bank>(),
  confirmedDisbursementStatement: false,
  uploadedCsv: undefined,
  lastUpdated: 0,
  errorMessage: null,
}

export const disbursements: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case DisbursementLoadActions.LOAD_DISBURSEMENTS: {
      return loadDisbursementState(initialState)
    }
    case DisbursementActions.CREATE_RECIPIENT: {
      state.recipients.set(action.recipient.id, action.recipient)
      return {
        ...state,
        lastUpdated: Date.now(),
      }
    }
    case DisbursementActions.BULK_CREATE_RECIPIENTS: {
      action.recipients.forEach((el) => {
        state.recipients.set(el.id, el)
      })
      return {
        ...state,
        lastUpdated: Date.now(),
        recipients: new Map(state.recipients),
      }
    }
    case DisbursementActions.UPDATE_RECIPIENT: {
      if (state.recipients.get(action.recipient.id) !== undefined) {
        state.recipients.set(action.recipient.id, action.recipient)
      }
      return {
        ...state,
        lastUpdated: Date.now(),
        recipients: new Map(state.recipients),
      }
    }
    case DisbursementActions.DELETE_RECIPIENT: {
      state.recipients.delete(action.recipientId)
      return {
        ...state,
        lastUpdated: Date.now(),
        recipients: new Map(state.recipients),
      }
    }
    case DisbursementActions.CLEAR_ALL_RECIPIENTS: {
      return {
        ...state,
        lastUpdated: Date.now(),
        recipients: new Map<string, Recipient>(),
        uploadedCsv: undefined,
      }
    }
    case DisbursementActions.CLEAR_DISBURSEMENTS: {
      return initialState
    }
    case DisbursementActions.SET_PAYMENT_METHOD: {
      return {
        ...state,
        lastUpdated: Date.now(),
        paymentMethod: action.paymentMethod,
      }
    }
    case DisbursementActions.SET_UPLOAD_METHOD: {
      return {
        ...state,
        lastUpdated: Date.now(),
        uploadMethod: action.uploadMethod,
      }
    }
    case DisbursementActions.SET_AVAILABLE_BANKS: {
      const bankMap = new Map<string, Bank>()
      action.banks.forEach((bank) => {
        bankMap.set(bank.abbreviation, bank)
      })
      return {
        ...state,
        lastUpdated: Date.now(),
        banks: bankMap,
      }
    }
    case DisbursementActions.CONFIRM_DISBURSEMENT_STATEMENT: {
      return {
        ...state,
        lastUpdated: Date.now(),
        confirmedDisbursementStatement: action.confirm,
      }
    }
    case DisbursementActions.SET_UPLOADED_CSV: {
      return {
        ...state,
        lastUpdated: Date.now(),
        uploadedCsv: action.value,
      }
    }
    case DisbursementActions.SET_DISBURSEMENT_ERROR_MESSAGE: {
      return {
        ...state,
        lastUpdated: Date.now(),
        errorMessage: action.errorMessage,
      }
    }
    default:
      return state
  }
}
