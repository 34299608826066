import { RadioOptionType } from 'fds3-src/components/atoms/Formik/FormikRadio/FormikRadio.types'
import { dateRangeHelper, disabledDate } from 'fds3-src/helpers/dateRange'
import { Dialog, FormikRadio, RangePicker } from 'fds3-src/index'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import { Body, Label } from '../../../themes/Typography'
import { fdsTheme } from '../../../themes/theme-fds3'
import { Container, ItemContainer } from './FileExportDialog.styles'
import {
  FileExportDialogProps,
  FileFormatPickerProps,
  AllowableFileExportFormat,
  RangePickerProps,
} from './FileExportDialog.types'

const { colors, spacing } = fdsTheme

function DateRangePicker({
  dateFormat = 'DD-MM-YYYY',
  startDate,
  endDate,
  setDatePickerStartDate,
  setDatePickerEndDate,
}: RangePickerProps) {
  return (
    <ItemContainer>
      <Label size="md">Date Range</Label>
      <RangePicker
        inputReadOnly
        format={dateFormat}
        disabledDate={disabledDate}
        ranges={dateRangeHelper()}
        picker="date"
        value={[startDate, endDate]}
        onCalendarChange={(dates: Array<Moment>) => {
          if (!dates) {
            setDatePickerStartDate('')
            setDatePickerEndDate('')
          } else {
            const [newStartDate, newEndDate] = dates
            setDatePickerStartDate(newStartDate)
            setDatePickerEndDate(newEndDate)
          }
        }}
      />
      <Body size="md" color={colors.onNeutralSecondary} style={{ paddingLeft: spacing.md }}>
        Up to 2 years
      </Body>
    </ItemContainer>
  )
}

function FileFormatPicker({
  fileExportFormatOptions = ['csv'],
  handleOnChange,
  selectedFileFormat,
}: FileFormatPickerProps) {
  const optionsDefault = mapFileTypeToRadioOptions(fileExportFormatOptions)
  return (
    <Formik enableReinitialize initialValues={{ selectedFileFormat }} onSubmit={() => {}}>
      <Form>
        <FormikRadio
          name="selectedFileFormat"
          options={optionsDefault}
          label="File Format"
          onChange={(event) => handleOnChange(event.target.value)}
          style={{ display: 'flex', flexDirection: 'column', gap: spacing.xs }}
        />
      </Form>
    </Formik>
  )
}

function mapFileTypeToRadioOptions(
  fileExportFormatOptions: AllowableFileExportFormat[]
): RadioOptionType[] {
  const options = fileExportFormatOptions.map((fileExportFormat) => {
    let label
    let value

    switch (fileExportFormat) {
      case 'pdf':
        label = 'PDF'
        value = fileExportFormat
        break
      case 'xeroCsv':
        label = 'Xero CSV'
        value = 'xero_csv'
        break
      case 'csv':
      default:
        label = 'CSV'
        value = fileExportFormat
        break
    }

    return {
      label,
      value,
    }
  })

  return options
}

export function FileExportDialog({
  title = '',
  style,
  className,
  onExportClick,
  onCancelClick,
  emailMessageHelper,
  isOpen = false,
  rangePickerProps,
  isLoading = false,
  fileExportFormatOptions,
}: // DO NOT USE SPREAD OPERATOR i.e. {...props}
FileExportDialogProps) {
  const { dateFormat, startDate, endDate } = rangePickerProps || {}
  const [datePickerStartDate, setDatePickerStartDate] = useState<Moment | string>('')
  const [datePickerEndDate, setDatePickerEndDate] = useState<Moment | string>('')
  const [selectedFileFormat, setSelectedFileFormat] = useState<AllowableFileExportFormat>('csv')

  const handleOnOkClick = () => {
    if (moment.isMoment(datePickerStartDate) && moment.isMoment(datePickerEndDate)) {
      onExportClick({
        startDate: datePickerStartDate,
        endDate: datePickerEndDate,
        format: selectedFileFormat,
      })
      setDatePickerStartDate('')
      setDatePickerEndDate('')
      setSelectedFileFormat('csv')
    }
  }

  const handleOnCancelClick = () => {
    onCancelClick(!isOpen)
  }

  useEffect(() => {
    if (startDate && moment.isMoment(startDate)) {
      setDatePickerStartDate(startDate)
    }
    if (endDate && moment.isMoment(endDate)) {
      setDatePickerEndDate(endDate)
    }
  }, [startDate, endDate])

  return (
    <Dialog
      title={title}
      style={style}
      className={className}
      isClosable
      open={isOpen}
      onOkClick={handleOnOkClick}
      onCancelClick={handleOnCancelClick}
      okText="Export"
      isDisabled={!datePickerStartDate || !datePickerEndDate || !selectedFileFormat}
      okButtonProps={{ isLoading }}
    >
      <Container>
        <DateRangePicker
          setDatePickerStartDate={setDatePickerStartDate}
          setDatePickerEndDate={setDatePickerEndDate}
          startDate={datePickerStartDate}
          endDate={datePickerEndDate}
          dateFormat={dateFormat}
        />
        {fileExportFormatOptions && (
          <FileFormatPicker
            fileExportFormatOptions={fileExportFormatOptions}
            handleOnChange={setSelectedFileFormat}
            selectedFileFormat={selectedFileFormat}
          />
        )}
        {emailMessageHelper}
      </Container>
    </Dialog>
  )
}

export default FileExportDialog
