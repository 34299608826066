import styled from '@emotion/styled/macro'
import { DESKTOP_BREAKPOINT, fdsTheme, Typography } from '@fazz/design-system'
import CornerSupergraphic from '../SplashPagesShared/CornerSupergraphic'
import MaintenancePageLayout from '../SplashPagesShared/SplashPageLayout'
import ErrorSVG from './ErrorSVG'

const { spacing } = fdsTheme
const { Title, Headline } = Typography

function ErrorPage() {
  return (
    <MaintenancePageLayout>
      <CornerDecoratorTopLeft>
        <CornerSupergraphic />
      </CornerDecoratorTopLeft>
      <MainContainer>
        <ErrorSVG />
        <Title size="lg">Sorry, an error has occurred</Title>
        <Headline size="sm">
          Please try refreshing the page, back to previous page, or come back later. We apologize
          for any inconvenience this may have caused and thank you for your patience.
        </Headline>
      </MainContainer>
      <CornerDecoratorBottomRight>
        <CornerSupergraphic />
      </CornerDecoratorBottomRight>
    </MaintenancePageLayout>
  )
}

const CornerDecoratorTopLeft = styled.div`
  position: absolute;
  width: 25vw;
  height: 25vh;
  top: 0;
  left: 0;

  & > svg {
    position: absolute;

    transform-origin: center;
    transform: rotate(180deg) scale(30%) translate(130%, 130%);
    top: 0;
    left: 0;

    ${DESKTOP_BREAKPOINT} {
      transform: rotate(180deg) scale(100%);
    }
  }
`

const CornerDecoratorBottomRight = styled.div`
  position: absolute;
  width: 25vw;
  height: 25vh;
  bottom: 0;
  right: 0;

  & > svg {
    position: absolute;

    bottom: 0;
    right: 0;
    transform: scale(30%) translate(130%, 130%);
    ${DESKTOP_BREAKPOINT} {
      transform: scale(100%);
    }
  }
`

const MainContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: ${spacing.xs};
  padding: 0px ${spacing.lg};
  max-width: 612px;
`

export default ErrorPage
