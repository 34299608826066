import { fdsTheme } from 'fds3-src/themes/theme-fds3'
import styled from 'styled-components'
import { Layout } from 'antd'

const { colors } = fdsTheme
const { Footer, Header, Sider } = Layout

const StyledSider = styled(Sider)`
  background-color: ${colors.surfaceNeutral};
  overflow-y: hidden;
`
const ContentFooter = styled.div`
  overflow-y: scroll;
`

const StyledHeader = styled(Header)`
  padding: 0;
  margin: 0;
  background: ${colors.surfaceNeutral};
  height: auto;
  line-height: normal;
`

const StyledFooter = styled(Footer)`
  padding: 0;
  margin: 0;
  background: ${colors.surfaceSecondaryNeutral};
  height: auto;
`
export const StyledLayout = {
  Sider: StyledSider,
  ContentFooter,
  Header: StyledHeader,
  Footer: StyledFooter,
}
