import { SVGProps } from 'react'

function SvgCircleCheck(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.035 9a5.964 5.964 0 0 1 8.31-5.485.9.9 0 0 0 .709-1.655 7.764 7.764 0 1 0 4.644 6.123.9.9 0 0 0-1.786.234A5.964 5.964 0 1 1 3.035 9Zm12.6-4.163a.9.9 0 1 0-1.272-1.273L8.399 9.528l-.833-.834-1.23-1.23a.9.9 0 1 0-1.273 1.273l1.23 1.23 1.258 1.258a1.2 1.2 0 0 0 1.697 0l6.388-6.388Z"
        fill="#E5C412"
      />
    </svg>
  )
}
export default SvgCircleCheck
