import { SVGProps } from 'react'

function CircleSync(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.4572 10.0576C15.3121 10.021 15.1583 10.043 15.0292 10.1187C14.9001 10.1945 14.806 10.318 14.7672 10.4626C14.4628 11.6741 13.7832 12.7584 12.8256 13.5606C11.868 14.3627 10.6813 14.8417 9.4352 14.929C8.18907 15.0164 6.94722 14.7076 5.88708 14.0469C4.82693 13.3862 4.00273 12.4073 3.53223 11.2501L3.73473 11.3776C3.86131 11.4457 4.0089 11.4634 4.14797 11.4272C4.28704 11.391 4.40732 11.3037 4.48473 11.1826C4.55457 11.0565 4.57328 10.9083 4.53699 10.7687C4.5007 10.6291 4.41219 10.5088 4.28973 10.4326L2.84973 9.54764C2.72316 9.47962 2.57557 9.46192 2.4365 9.49808C2.29743 9.53424 2.17715 9.62158 2.09974 9.74264L1.19974 11.2051C1.13172 11.3317 1.11402 11.4793 1.15018 11.6184C1.18634 11.7574 1.27368 11.8777 1.39474 11.9551C1.4834 12.0076 1.58422 12.036 1.68724 12.0376C1.78455 12.0375 1.88012 12.0118 1.96437 11.9631C2.04862 11.9144 2.11858 11.8444 2.16724 11.7601L2.38474 11.4001C2.89434 12.829 3.8508 14.0557 5.11235 14.8983C6.37389 15.7408 7.87336 16.1545 9.38847 16.0779C10.9036 16.0013 12.3537 15.4385 13.5238 14.473C14.694 13.5075 15.5218 12.1906 15.8847 10.7176C15.9138 10.5736 15.8854 10.4239 15.8055 10.3005C15.7256 10.1772 15.6006 10.09 15.4572 10.0576Z"
        fill="currentColor"
      />
      <path
        d="M16.6049 5.99999C16.4796 5.92947 16.3321 5.90961 16.1926 5.94448C16.0531 5.97935 15.9323 6.06631 15.8549 6.18749L15.6524 6.53249C15.1272 5.11983 14.1636 3.9124 12.9026 3.08695C11.6416 2.26151 10.1495 1.86142 8.64472 1.94526C7.13992 2.02909 5.70148 2.59244 4.53999 3.55284C3.37849 4.51324 2.55495 5.82024 2.18992 7.28249C2.15312 7.4277 2.17551 7.58157 2.25216 7.71027C2.32882 7.83897 2.45346 7.93194 2.59867 7.96874C2.74387 8.00554 2.89775 7.98315 3.02645 7.90649C3.15514 7.82984 3.24812 7.7052 3.28492 7.55999C3.59616 6.35839 4.27646 5.28467 5.23003 4.49005C6.1836 3.69542 7.3624 3.21991 8.60043 3.13047C9.83846 3.04104 11.0734 3.34218 12.1312 3.9915C13.1891 4.64082 14.0167 5.6056 14.4974 6.74999L14.2499 6.58499C14.1216 6.50841 13.9682 6.48593 13.8233 6.5225C13.6784 6.55907 13.554 6.65169 13.4774 6.77999C13.4008 6.90829 13.3784 7.06175 13.4149 7.20663C13.4515 7.3515 13.5441 7.47591 13.6724 7.55249L15.1349 8.42249C15.221 8.47467 15.3193 8.50313 15.4199 8.50499H15.5624C15.7062 8.46816 15.83 8.37668 15.9074 8.24999L16.7999 6.74999C16.8698 6.62381 16.8885 6.47563 16.8522 6.33605C16.8159 6.19648 16.7274 6.07617 16.6049 5.99999Z"
        fill="currentColor"
      />
      <path
        d="M8.99966 6.15015C8.43598 6.15015 7.88496 6.3173 7.41628 6.63046C6.9476 6.94362 6.58231 7.38873 6.3666 7.9095C6.15089 8.43027 6.09445 9.00331 6.20442 9.55615C6.31439 10.109 6.58582 10.6168 6.9844 11.0154C7.38298 11.414 7.8908 11.6854 8.44365 11.7954C8.9965 11.9054 9.56954 11.8489 10.0903 11.6332C10.6111 11.4175 11.0562 11.0522 11.3693 10.5835C11.6825 10.1148 11.8497 9.56382 11.8497 9.00015C11.8497 8.24428 11.5494 7.51937 11.0149 6.98489C10.4804 6.45041 9.75552 6.15015 8.99966 6.15015Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CircleSync
