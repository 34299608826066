import * as React from 'react'

function SvgHsbc(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 110 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path d="M19.9922 28.7852H23.6814L26.5985 17.932H22.9092L19.9922 28.7852Z" fill="#005B75" />
        <path d="M39.0396 28.7852H42.7288L45.6458 17.932H41.9566L39.0396 28.7852Z" fill="#005B75" />
        <path d="M58.0874 28.7852H61.7766L64.6937 17.932H61.0045L58.0874 28.7852Z" fill="#005B75" />
        <path
          d="M35.6075 21.6641H39.3396C39.8115 19.2189 38.2671 17.9749 34.6208 17.9749C30.4168 17.9749 27.5427 20.034 26.5989 23.5945C25.741 26.8119 27.4569 28.7852 31.4035 28.7852C35.393 28.7852 37.7524 27.5411 38.7819 24.7528H35.0498C34.535 25.9968 33.5484 26.6403 32.2185 26.6403C30.4168 26.6403 29.7305 25.482 30.2881 23.4658C30.8458 21.3209 32.2185 20.1198 34.0202 20.1198C35.1356 20.1198 35.6933 20.6346 35.6075 21.6641Z"
          fill="#005B75"
        />
        <path
          d="M54.6553 21.6641H58.3874C58.8593 19.2189 57.315 17.9749 53.6687 17.9749C49.4647 17.9749 46.5905 20.034 45.6468 23.5945C44.7888 26.8119 46.5047 28.7852 50.4513 28.7852C54.4408 28.7852 56.8002 27.5411 57.8298 24.7528H54.0977C53.5829 25.9968 52.5962 26.6403 51.2664 26.6403C49.4647 26.6403 48.7783 25.482 49.336 23.4658C49.8937 21.3209 51.2664 20.1198 53.0681 20.1198C54.1835 20.1198 54.7411 20.6346 54.6553 21.6641Z"
          fill="#005B75"
        />
        <path
          d="M5.66578 11.9266C10.6419 6.99336 16.6476 5.74933 19.0499 9.09536C21.4522 12.4414 19.3502 19.2193 14.3741 24.1096C9.3979 29.0429 3.39219 30.2869 0.989911 26.9409C-1.41237 23.5948 0.689626 16.817 5.66578 11.9266Z"
          fill="#F99D27"
        />
        <path
          d="M8.96689 9.30963C8.45212 9.69571 7.85155 10.1676 7.33677 10.7253C2.96119 15.015 1.15948 20.892 3.30437 23.852C5.53506 26.812 10.8544 25.7395 15.3158 21.3639C17.6752 19.0474 19.3053 16.3449 19.9917 13.8568C20.2061 12.0122 19.9059 10.3821 19.0908 9.13803C17.1175 6.47836 13.0422 6.69286 8.96689 9.30963Z"
          fill="#B02A30"
        />
        <path
          d="M14.9288 9.13853C15.5294 9.69621 15.1433 10.9831 14.1566 11.9698C13.17 12.9564 11.883 13.2996 11.3254 12.742C10.7248 12.1843 11.068 10.8974 12.0975 9.8678C13.0413 8.88115 14.3711 8.58086 14.9288 9.13853ZM11.4112 26.5551C9.09468 28.3139 6.69239 29.1718 4.33301 28.7858C5.31966 28.8287 6.09182 27.7562 6.77819 26.2977C7.42166 24.7963 7.85064 23.4235 8.15092 22.0937C8.6228 20.0346 8.6657 18.5761 8.40831 18.2329C8.02223 17.7181 7.12138 17.8468 6.09183 18.4474C5.61995 18.7048 4.93358 18.5332 5.70574 17.5894C6.47791 16.6457 9.56656 14.3721 10.6819 14.0289C11.883 13.6857 13.2987 14.1576 12.8268 15.4874C12.4836 16.4741 8.23672 27.7133 11.4112 26.5551Z"
          fill="white"
        />
        <path
          d="M67.4805 28.7852H73.0572C74.6444 28.7852 75.8027 28.6565 76.9609 27.8843C77.7331 27.3695 78.2907 26.5974 78.5052 25.7394C78.8913 24.238 78.2049 23.2513 76.5319 22.9939C78.1191 22.6937 79.1487 21.8357 79.4919 20.5917C79.7493 19.605 79.5348 18.8757 78.8055 18.4039C78.2049 18.0178 77.2612 17.932 75.9313 17.932H70.3975L67.4805 28.7852ZM72.285 19.605H74.6015C76.3174 19.605 77.1325 19.8195 76.8322 20.892C76.5319 21.9644 75.5882 22.2647 73.8294 22.2647H71.5987L72.285 19.605ZM70.2688 27.0692L71.1268 23.8948H73.572C75.4595 23.8948 76.2745 24.238 75.9313 25.5249C75.6311 26.6832 74.6873 27.0692 73.0143 27.0692H70.2688Z"
          fill="#005B75"
        />
        <path
          d="M84.5993 28.7855H86.83C86.8729 28.2707 87.0015 27.7131 87.1731 27.1125L87.9882 24.0667C88.5459 22.0505 87.5163 21.021 85.0282 21.021C82.583 21.021 81.0387 21.879 80.5239 23.2946H82.6688C82.9691 22.6082 83.5697 22.265 84.5993 22.265C85.7575 22.265 86.1436 22.7798 85.8433 23.8952L85.8004 24.0239H84.6422C81.339 24.0239 79.5373 24.6244 79.0225 26.5548C78.6364 28.0133 79.5373 29 81.5535 29C82.8404 29 83.9558 28.571 84.8566 27.7989L84.5993 28.7855ZM85.4572 25.3108L85.4143 25.4395C85.0282 26.8551 83.9129 27.6702 82.5402 27.6702C81.5106 27.6702 81.1245 27.2841 81.2961 26.5548C81.5535 25.5682 82.4544 25.3108 84.0416 25.3108H85.4572Z"
          fill="#005B75"
        />
        <path
          d="M88.7573 28.7852H90.988L92.0176 24.9244C92.4036 23.5088 93.6906 22.565 95.0204 22.565C96.3502 22.565 96.7792 23.3372 96.3931 24.8815L95.3207 28.8281H97.5085L98.7525 24.1951C99.2673 22.2218 98.2806 21.1065 96.0929 21.1065C94.6772 21.1065 93.519 21.5784 92.5323 22.6079L92.7897 21.2781H90.559C90.3445 22.4792 90.173 23.2085 90.0872 23.5088L88.7573 28.7852Z"
          fill="#005B75"
        />
        <path
          d="M99.5283 28.7852H101.716L102.831 24.7099L105.791 28.7852H108.794L105.491 24.4525L109.995 21.2351H107.164L102.96 24.2809L104.676 17.9749H102.488L99.5283 28.7852Z"
          fill="#005B75"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="110" height="35" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgHsbc
