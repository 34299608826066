import type { Moment } from '~/types'

export enum ReceiveTransactionsTabKey {
  VIRTUAL_ACCOUNT = 'virtual_account',
  PAYNOW = 'pay_now',
  CARD_PAYMENT = 'card_payment',
}

type FilterOption = {
  label: string
  value: string
}

export type ReceiveTransactionFilterOption = {
  transactionStatuses: Array<FilterOption>
  sortTypes: Array<FilterOption>
}

type ReceiveTransaction = {
  key: number
  transactionId: string
  createdAt: string
  completedAt?: string
  paidAt?: string
  description: {
    type: string
    direction: string
    data: string | CardInfo
    paymentId: string
    internalTransactionType: string
  }
  method: string
  amount: string
  transactionStatus: string
  fee: string
  tax: string
  netAmount: string
  transactionType: string
  walletType: string
  blockedReasons: string
  sourceDestination: {
    source: string
    destination: string
  }
  merchantDescription: string
  network: string
  accountHolderName: string
  endToEndId: string
  references: {
    description: string
    referenceId: string
    remarks: string
  }
  messageId: string
  details: {
    customerProfile: {
      senderName: string
    }
    references?: {
      remarks: string
      reference_id: string
    }
    receiver: {
      accountNo: string
      bankType: string
      accountHolderName: string
    }
    sender: {
      accountHolderName: string
      accountNo: string
    }
  }
}

export type ReceiveTransactionFilterValues = {
  sortType?: string
  startDate?: Moment
  endDate?: Moment
  transactionStatuses?: string
  referenceId?: string
  currentPage: number
  pageLimit: number
}

export type PaginationCursor = {
  last_record_id: string
  last_record_datetime: string
}

export enum ReceiveActions {
  SET_TRANSACTIONS_ACTIVE_TAB = 'ReceiveActions.SET_TRANSACTIONS_ACTIVE_TAB',
  SET_TRANSACTIONS_FILTER_OPTIONS = 'ReceiveActions.SET_TRANSACTIONS_FILTER_OPTIONS',
  SET_TRANSACTIONS_PAGINATION_CURSORS = 'ReceiveActions.SET_TRANSACTIONS_PAGINATION_CURSORS',
}

type SetReceiveTransactionsActiveTab = {
  type: typeof ReceiveActions.SET_TRANSACTIONS_ACTIVE_TAB
  transactionsActiveTab: ReceiveTransactionsTabKey
}

type SetReceiveTransactionsFilterOptions = {
  type: typeof ReceiveActions.SET_TRANSACTIONS_FILTER_OPTIONS
  filterOptions: ReceiveTransactionFilterOption
}

type SetReceiveTransactionsPaginationCursors = {
  type: typeof ReceiveActions.SET_TRANSACTIONS_PAGINATION_CURSORS
  paginationCursors: Map<number, PaginationCursor>
}

export type VirtualAccountTransaction = ReceiveTransaction

export type VirtualAccountFilterValues = ReceiveTransactionFilterValues

export enum ReceiveVirtualAccountTransactionsActions {
  SHOW_ADVANCED_FILTER = 'ReceiveVirtualAccountTransactionsActions.SHOW_ADVANCED_FILTER',
  SET_TRANSACTIONS = 'ReceiveVirtualAccountTransactionsActions.SET_TRANSACTIONS',
  SET_TRANSACTION = 'ReceiveVirtualAccountTransactionsActions.SET_TRANSACTION',
  SET_IS_LOADING = 'ReceiveVirtualAccountTransactionsActions.SET_IS_LOADING',
  SET_FILTER_VALUES = 'ReceiveVirtualAccountTransactionsActions.SET_FILTER_VALUES',
  SET_TOTAL_TRANSACTIONS = 'ReceiveVirtualAccountTransactionsActions.SET_TOTAL_TRANSACTIONS',
  RESET_STATE = 'ReceiveVirtualAccountTransactionsActions.RESET_STATE',
}

type ShowVirtualAccountAdvancedFilter = {
  type: typeof ReceiveVirtualAccountTransactionsActions.SHOW_ADVANCED_FILTER
  showAdvancedFilter: boolean
}

type SetVirtualAccountTransactions = {
  type: typeof ReceiveVirtualAccountTransactionsActions.SET_TRANSACTIONS
  transactions: Array<VirtualAccountTransaction>
}

type SetVirtualAccountTransaction = {
  type: typeof ReceiveVirtualAccountTransactionsActions.SET_TRANSACTION
  transaction: VirtualAccountTransaction
}

type SetVirtualAccountIsLoading = {
  type: typeof ReceiveVirtualAccountTransactionsActions.SET_IS_LOADING
  isLoading: boolean
}

type SetVirtualAccountFilterValues = {
  type: typeof ReceiveVirtualAccountTransactionsActions.SET_FILTER_VALUES
  filterValues: VirtualAccountFilterValues
}

type SetVirtualAccountTotalTransactions = {
  type: typeof ReceiveVirtualAccountTransactionsActions.SET_TOTAL_TRANSACTIONS
  totalTransactions: number
}

type ResetVirtualAccountState = {
  type: typeof ReceiveVirtualAccountTransactionsActions.RESET_STATE
}

export type PayNowTransaction = ReceiveTransaction

export type PayNowFilterValues = ReceiveTransactionFilterValues

export enum ReceivePayNowTransactionsActions {
  SHOW_ADVANCED_FILTER = 'ReceivePayNowTransactionsActions.SHOW_ADVANCED_FILTER',
  SET_TRANSACTIONS = 'ReceivePayNowTransactionsActions.SET_TRANSACTIONS',
  SET_TRANSACTION = 'ReceivePayNowTransactionsActions.SET_TRANSACTION',
  SET_FILTER_VALUES = 'ReceivePayNowTransactionsActions.SET_FILTER_VALUES',
  SET_TOTAL_TRANSACTIONS = 'ReceivePayNowTransactionsActions.SET_TOTAL_TRANSACTIONS',
  SET_IS_LOADING = 'ReceivePayNowTransactionsActions.SET_IS_LOADING',
  RESET_STATE = 'ReceivePayNowTransactionsActions.RESET_STATE',
}

type ShowPayNowAdvancedFilter = {
  type: typeof ReceivePayNowTransactionsActions.SHOW_ADVANCED_FILTER
  showAdvancedFilter: boolean
}

type SetPayNowTransactions = {
  type: typeof ReceivePayNowTransactionsActions.SET_TRANSACTIONS
  transactions: Array<PayNowTransaction>
}

type SetPayNowTransaction = {
  type: typeof ReceivePayNowTransactionsActions.SET_TRANSACTION
  transaction: PayNowTransaction
}

type SetPayNowIsLoading = {
  type: typeof ReceivePayNowTransactionsActions.SET_IS_LOADING
  isLoading: boolean
}

type SetPayNowFilterValues = {
  type: typeof ReceivePayNowTransactionsActions.SET_FILTER_VALUES
  filterValues: PayNowFilterValues
}

type SetPayNowTotalTransactions = {
  type: typeof ReceivePayNowTransactionsActions.SET_TOTAL_TRANSACTIONS
  totalTransactions: number
}

type ResetPayNowState = {
  type: typeof ReceivePayNowTransactionsActions.RESET_STATE
}

export type CardInfo = {
  cardDetails: {
    cardType: string
    expiryMonth: number
    expiryYear: number
    issuerCountry: null
    last4: string
    token: string
    issuingCountry: string
  }
}

export type CardPaymentTransaction = ReceiveTransaction & {
  description: {
    type: string
    direction: string
    data: null | CardInfo
    paymentId: string
    internalTransactionType: string
  }
}

export type CardPaymentFilterValues = ReceiveTransactionFilterValues & {
  cardType: string
}

export enum ReceiveCardPaymentTransactionsActions {
  SHOW_ADVANCED_FILTER = 'ReceiveCardPaymentTransactionsActions.SHOW_ADVANCED_FILTER',
  SET_TRANSACTIONS = 'ReceiveCardPaymentTransactionsActions.SET_TRANSACTIONS',
  SET_TRANSACTION = 'ReceiveCardPaymentTransactionsActions.SET_TRANSACTION',
  SET_FILTER_VALUES = 'ReceiveCardPaymentTransactionsActions.SET_FILTER_VALUES',
  SET_IS_LOADING = 'ReceiveCardPaymentTransactionsActions.SET_IS_LOADING',
  SET_TOTAL_TRANSACTIONS = 'ReceiveCardPaymentTransactionsActions.SET_TOTAL_TRANSACTIONS',
  RESET_STATE = 'ReceiveCardPaymentTransactionsActions.RESET_STATE',
}

type ShowCardPaymentAdvancedFilter = {
  type: typeof ReceiveCardPaymentTransactionsActions.SHOW_ADVANCED_FILTER
  showAdvancedFilter: boolean
}

type SetCardPaymentTransactions = {
  type: typeof ReceiveCardPaymentTransactionsActions.SET_TRANSACTIONS
  transactions: Array<CardPaymentTransaction>
}

type SetCardPaymentIsLoading = {
  type: typeof ReceiveCardPaymentTransactionsActions.SET_IS_LOADING
  isLoading: boolean
}

type SetCardPaymentTotalTransactions = {
  type: typeof ReceiveCardPaymentTransactionsActions.SET_TOTAL_TRANSACTIONS
  totalTransactions: number
}

type SetCardPaymentFilterValues = {
  type: typeof ReceiveCardPaymentTransactionsActions.SET_FILTER_VALUES
  filterValues: CardPaymentFilterValues
}

type SetCardPaymentTransaction = {
  type: typeof ReceiveCardPaymentTransactionsActions.SET_TRANSACTION
  transaction: CardPaymentTransaction
}

type ResetCardPaymentState = {
  type: typeof ReceiveCardPaymentTransactionsActions.RESET_STATE
}

export type ActionTypes =
  | SetReceiveTransactionsFilterOptions
  | SetReceiveTransactionsActiveTab
  | ShowVirtualAccountAdvancedFilter
  | SetVirtualAccountTransactions
  | SetVirtualAccountIsLoading
  | SetVirtualAccountFilterValues
  | ResetVirtualAccountState
  | ShowPayNowAdvancedFilter
  | SetPayNowTransactions
  | SetPayNowTransaction
  | SetPayNowIsLoading
  | ResetPayNowState
  | ShowCardPaymentAdvancedFilter
  | SetCardPaymentTransactions
  | SetCardPaymentIsLoading
  | SetCardPaymentTotalTransactions
  | ResetCardPaymentState
  | SetReceiveTransactionsPaginationCursors
  | SetVirtualAccountTotalTransactions
  | SetPayNowTotalTransactions
  | SetPayNowFilterValues
  | SetVirtualAccountTransaction
  | SetCardPaymentFilterValues
  | SetCardPaymentTransaction
