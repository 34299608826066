import { type PayloadInterface } from './common/common.type'

export function generateRequestBody<T>(payload: T): PayloadInterface<T> {
  return {
    data: {
      attributes: {
        ...payload,
      },
    },
  }
}

export const downloadFileFromBase64 = (
  base64String: string,
  fileType: string,
  fileName: string,
  fileExtension: string
) => {
  const linkSource = `data:${fileType};base64,${base64String}`
  const downloadLink = document.createElement('a')
  const file = `${fileName || 'file'}.${fileExtension}`
  downloadLink.href = linkSource
  downloadLink.download = file
  return downloadLink.click()
}
