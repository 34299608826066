import * as React from 'react'

function SvgVerificationBackground(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 109 102" {...props}>
      <path
        d="M56.224 64.236 21.423.4h40.686l18.377 39.646L111.118.4h41.67L97.156 70.47l33.819 62.347H91.267L72.885 94.664l-29.65 38.153H.835l55.39-68.581Z"
        fill="url(#verification-background_svg__a)"
      />
      <defs>
        <linearGradient
          id="verification-background_svg__a"
          x1={76.682}
          y1={33.815}
          x2={124.261}
          y2={178.55}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgVerificationBackground
