import { ChevronLeft, ChevronRight } from '@fazz/design-system-icons'
import { IconButton } from '../IconButton'
import { StyledPagination } from './Pagination.styles'
import type { PaginationProps } from './Pagination.types'

export function Pagination({
  isDisabled = false,
  style,
  setCurrentPage,
  className,
}: // currentPage = 1,
// total,
// pageSize,
// DO NOT USE SPREAD OPERATOR i.e. {...props}
PaginationProps) {
  const renderButtons = (type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next') => {
    switch (type) {
      case 'prev':
        return (
          <IconButton
            icon={<ChevronLeft />}
            size="sm"
            variant="outlined"
            isDisabled={isDisabled}
            onClick={() => setCurrentPage((prev) => Math.max(1, prev - 1))}
          />
        )
      case 'next':
        return (
          <IconButton
            icon={<ChevronRight />}
            size="sm"
            variant="outlined"
            isDisabled={isDisabled}
            onClick={() => setCurrentPage((prev) => prev + 1)}
          />
        )
      default:
        return null
    }
  }
  return (
    <StyledPagination
      itemRender={(_, type) => renderButtons(type)}
      disabled={isDisabled}
      className={className}
      style={style}
      // total={total}
      // pageSize={pageSize}
      // current={currentPage}
      // DO NOT USE SPREAD OPERATOR i.e. {...props}
    />
  )
}

export default Pagination
