import { SVGProps } from 'react'

function SvgWithdrawalTwoTone(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.73 8.33a.75.75 0 0 1-.34-1.42 1.49 1.49 0 0 0 .84-1.33v-1.8a1.51 1.51 0 0 0-1.5-1.5H4.27a1.51 1.51 0 0 0-1.5 1.5v1.8a1.49 1.49 0 0 0 .84 1.33.751.751 0 1 1-.68 1.34 3 3 0 0 1-1.66-2.67v-1.8a3 3 0 0 1 3-3h16.46a3 3 0 0 1 3 3v1.8a3 3 0 0 1-1.66 2.67.71.71 0 0 1-.34.08ZM11.5 17.38l-2.66-2.69a.71.71 0 0 1-.22-.53.74.74 0 0 1 .22-.53.75.75 0 0 1 1.06 0l1.84 1.84V9.93a.75.75 0 1 1 1.5 0v5.58l1.86-1.87a.75.75 0 0 1 1.06 1.06l-2.66 2.68a1.391 1.391 0 0 1-1 .41 1.361 1.361 0 0 1-1-.41Z"
        fill="currentColor"
      />
      <path
        d="M20.93 5.17a.76.76 0 0 0-.75-.75H4.8a.75.75 0 0 0 0 1.5v13.3a4 4 0 0 0 4 4h7.4a4 4 0 0 0 4-4V5.92a.75.75 0 0 0 .73-.75ZM18.7 19.22a2.51 2.51 0 0 1-2.5 2.5H8.78a2.51 2.51 0 0 1-2.5-2.5V5.92H18.7v13.3Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgWithdrawalTwoTone
