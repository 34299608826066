/* eslint-disable react/no-array-index-key */
import Tabs from 'antd/es/tabs'
import 'antd/es/tabs/style/css'
import styled from '@emotion/styled'
import { fazzbizTheme } from '../../../themes/deprecated/theme'

type Props = {
  activeKey: number
  children: React.ReactNode[]
  width?: number
  animated?: boolean
}

function Carousel({ children, activeKey, width = 250, animated = false, ...rest }: Props) {
  const panels = children.map((child, index) => (
    <Tabs.TabPane key={index} forceRender>
      {child}
    </Tabs.TabPane>
  ))

  const dots = children.map((child, index) => <Dot isActive={index === activeKey} />)

  return (
    <Container>
      <AnimatedTabs
        renderTabBar={() => <div />} // using a hack to hide the tab bar, returning empty div
        width={width}
        activeKey={activeKey.toString()}
        animated={animated}
        {...rest}
      >
        {panels}
      </AnimatedTabs>
      <Footer>{dots}</Footer>
    </Container>
  )
}

export default Carousel

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const AnimatedTabs = styled(Tabs)<{ width: number }>`
  width: ${(props) => `${props.width}px`};

  & .ant-tabs-content-animated {
    transition: margin 1s ease-out;
  }
`

const Footer = styled.span`
  display: flex;
  gap: 0.25rem;
  margin-top: 10px;
`

const Dot = styled.span<{ isActive: boolean }>`
  height: 10px;
  width: 10px;
  background-color: ${(props) =>
    props.isActive ? fazzbizTheme.base.primary : fazzbizTheme.interactive.hoverOnNeutral};
  border-radius: 50%;
  display: inline-block;
`
