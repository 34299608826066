// withPermission HOC- mostly related to url accessible permission
import React from 'react'
import { useSelector } from 'react-redux'
import { getFeatureMatrixFactors } from '~/biz/store/route/selectors'
import type { PERMISSIONS } from '~/types'
import hasPermission from './hasPermission'

const withPermission = <P extends object>(
  BaseComponent: React.ComponentType<P>,
  permission: PERMISSIONS,
  DeniedComponent?: React.ComponentType<P>
) =>
  function WithPermission({ ...props }: P) {
    const featureMatrix = useSelector(getFeatureMatrixFactors)
    const userPermission = hasPermission(permission, featureMatrix)
    if (userPermission) {
      return <BaseComponent {...props} />
    }
    if (DeniedComponent) {
      return <DeniedComponent {...props} />
    }
    return null
  }

export default withPermission
