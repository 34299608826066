import * as React from 'react'
import { SVGProps } from 'react'

function SvgCreditCardTwoTone(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 3V1.8h12V3H2Zm0 1.8v5.4h12V4.8H2ZM.2 1.2A1.2 1.2 0 0 1 1.4 0h13.2a1.2 1.2 0 0 1 1.2 1.2v9.6a1.2 1.2 0 0 1-1.2 1.2H1.4a1.2 1.2 0 0 1-1.2-1.2V1.2Z"
        fill="#1B2736"
      />
      <path fill="#2F8DEE" d="M10.398 6.6h2.4V9h-2.4z" />
    </svg>
  )
}

export default SvgCreditCardTwoTone
