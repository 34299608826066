import styled from 'styled-components'
import { Tabs } from 'antd'
import { fdsTheme } from '../../../themes/theme-fds3'

const { colors, spacing, fontStyles, fontWeight } = fdsTheme

export const StyledTab = styled(Tabs).attrs(() => {})`
  .ant-tabs-nav-list {
    position: relative;
    ${fontStyles.labelMedium}
  }

  .ant-tabs-nav::before {
    border-bottom: 1px solid ${colors.line};
  }

  .ant-tabs-tab {
    color: ${colors.inactive};
    min-width: 80px;
    margin: 0;
    padding: 0;
  }

  .ant-tabs-tab-btn {
    text-align: center;
    width: 100%;
    padding: ${spacing.sm} ${spacing.xs};
    border-bottom: 1px solid ${colors.line};

    :hover {
      background-color: ${colors.hoveredSurface};
      color: ${colors.onNeutral};
    }

    :active {
      background-color: ${colors.pressedSurface};
      color: ${colors.onNeutral};
    }

    :focus-visible {
      background-color: ${colors.surfaceNeutral};
      border: 1px solid ${colors.focusedDefault};
      color: ${colors.onNeutral};
    }
  }

  .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn {
    font-weight: ${fontWeight.bold};
    color: ${colors.inactive};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: ${fontWeight.bold};
    color: ${colors.onNeutral};
  }

  .ant-tabs-ink-bar {
    background: ${colors.active};
    height: 2px;
  }
`
