import { Notification } from '@fazz/design-system'
import { Dispatch } from '~/biz/store/types'
import { ENDPOINTS, getApiMethods } from '~/api'
import { AppType } from '~/types'
import { ConfigManager, ENVIRONMENT } from '~/managers/ConfigManager'
import { buildFeatureGateQueryParams, featureGateIDs } from './helpers'
import { ActionTypes, FeatureGateIDs, GetFeatureGateActions } from './types'

type PromiseAllResponseType = {
  data: FeatureGateIDs
}

export const getFeatureGateStatus =
  (appType: AppType, bizId: string) => (dispatch: Dispatch<ActionTypes>) => {
    // When we're on localhost, we're connected to staging. Hence why the need to process this to either production or staging
    const environmentPrefix =
      ConfigManager.getEnvironmentMode() === ENVIRONMENT.PRODUCTION
        ? ENVIRONMENT.PRODUCTION
        : ENVIRONMENT.STAGING

    dispatch({
      type: GetFeatureGateActions.SET_FEATURE_GATE_IS_LOADING,
      isFeatureGateLoading: true,
    })

    Promise.all([
      // api to check if the feature is enabled for specific environments
      getApiMethods(appType).get(
        ENDPOINTS.API_XFERS_CHECK_FEATURE,
        buildFeatureGateQueryParams('all', environmentPrefix)
      ),
      // api to check if the feature is enabled for specific bizIDs
      getApiMethods(appType).get(
        ENDPOINTS.API_XFERS_CHECK_FEATURE,
        buildFeatureGateQueryParams(bizId, environmentPrefix)
      ),
    ])
      .then(([enabledAll, bizIdEnabled]: PromiseAllResponseType[]) => {
        /**
         * Method to combine both the api responses from feature gate for environment and biz specific into one object
         * If either is true, enable the feature gate ID
         * If both is false, disable the feature gate ID
         */
        const combinedFeatureGateIDs: FeatureGateIDs = Object.entries(enabledAll.data).reduce(
          (result, [key, value]) => {
            const bizIdValue = bizIdEnabled.data[key as keyof FeatureGateIDs]
            return {
              ...result,
              [key as keyof FeatureGateIDs]: value || bizIdValue,
            }
          },
          { ...featureGateIDs }
        )

        dispatch({
          type: GetFeatureGateActions.SET_FEATURE_GATE_IDS,
          featureGateIDs: { ...(combinedFeatureGateIDs as FeatureGateIDs) },
        })
      })
      .catch(() => {
        Notification({
          type: 'error',
          message: 'Failed to initialize, please contact support',
        })
      })
      .finally(() =>
        dispatch({
          type: GetFeatureGateActions.SET_FEATURE_GATE_IS_LOADING,
          isFeatureGateLoading: false,
        })
      )
  }
