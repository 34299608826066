import * as React from 'react'

function SvgLockFilled(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 2a5 5 0 00-5 5v4H7a3 3 0 00-3 3v11a3 3 0 003 3h16a3 3 0 003-3V14a3 3 0 00-3-3h-3V7a5 5 0 00-5-5zm2 9V7a2 2 0 10-4 0v4h4zm-5.5 3H7v11h16V14H11.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgLockFilled
