import * as React from 'react'

function SvgEthereum(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17.975 2.4l-.21.711v20.63l.21.21 9.576-5.661L17.975 2.4z" fill="#343434" />
      <path d="M17.975 2.4L8.398 18.29l9.577 5.66V2.4z" fill="#8C8C8C" />
      <path d="M17.973 25.763l-.117.144v7.348l.117.345 9.582-13.495-9.582 5.658z" fill="#3C3C3B" />
      <path d="M17.975 33.6v-7.837l-9.577-5.658L17.975 33.6z" fill="#8C8C8C" />
      <path d="M17.973 23.95l9.576-5.66-9.576-4.352V23.95z" fill="#141414" />
      <path d="M8.398 18.29l9.577 5.66V13.939L8.398 18.29z" fill="#393939" />
    </svg>
  )
}

export default SvgEthereum
