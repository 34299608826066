import { Label } from 'fds3-src/themes/Typography'
import { fdsTheme } from 'fds3-src/themes/theme-fds3'
import { StyledFormItem, StyledRadio } from './FormikRadio.styles'
import { FormikRadioGroupProps } from './FormikRadio.types'

const { colors, spacing } = fdsTheme

// TODO: labelPosition prop - add this only once we have a requirement.
// TODO: isVertical prop - add this only when there is a requirement.
export function FormikRadio({
  name,
  isDisabled = false,
  style,
  options,
  onChange,
  label,
  helperText,
}: FormikRadioGroupProps) {
  return (
    <StyledFormItem name={name} extra={helperText} style={style}>
      {label && (
        <Label
          size="medium"
          style={{ display: 'flex', color: colors.onNeutral, marginBottom: spacing.xs }}
        >
          {label}
        </Label>
      )}
      <StyledRadio
        name={name}
        options={options}
        disabled={isDisabled}
        style={style}
        onChange={onChange}
      />
    </StyledFormItem>
  )
}
