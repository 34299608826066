import type { valueof } from 'react-joyride'
import type {
  sourceOfFundsLoanFromDirectorShareholdersList,
  sourceOfFundsList,
  purposeOfBusinessList,
} from '~/bizRegional/constants'
import type { PATHS } from '~/bizRegional/routes/paths'
import type { ENDPOINTS } from '~/api'
import type {
  IdentityType,
  ManagerDetailsAttributes,
} from '~/api/sgVerification/sgVerification.types'
import type { Moment, VerificationStatusType } from '~/types'
import { type Attachment as UploadBoxWrapperAttachment } from '~/fdsMolecules/UploadBoxWrapper'

export type Attachment = UploadBoxWrapperAttachment

export enum BusinessVerificationActions {
  INIT_BUSINESS_DATA = 'BusinessVerificationActions.INIT_BUSINESS_DATA',
  SET_BUSINESS_DATA = 'BusinessVerificationActions.SET_BUSINESS_DATA',
  SET_DIRECT_URL = 'BusinessVerificationActions.SET_DIRECT_URL',
  SET_ADD_FLOW_STEP = 'BusinessVerificationActions.SET_ADD_FLOW_STEP',
  SET_ADD_FLOW_META = 'BusinessVerificationActions.SET_ADD_FLOW_META',
  SET_CURRENT_PATH = 'BusinessVerificationActions.SET_CURRENT_PATH',
  SET_ACRA_BUSINESS_INFO = 'BusinessVerificationActions.SET_ACRA_BUSINESS_INFO',
  SET_ACRA_BUSINESS_API_TRIES = 'BusinessVerificationActions.SET_ACRA_BUSINESS_API_TRIES',
  SET_ACRA_BENEFICIAL_OWNER = 'BusinessVerificationActions.SET_ACRA_BENEFICIAL_OWNER',
  SET_BENEFICIARIES = 'BusinessVerificationActions.SET_BENEFICIARIES',
  SET_ACRA_BENEFICIAL_OWNER_FETCHED = 'BusinessVerificationActions.SET_ACRA_BENEFICIAL_OWNER_FETCHED',
  SET_ACRABOLIST = 'BusinessVerificationActions.SET_ACRABOLIST',
}

export type BusinessInformation = {
  // Old fields to be deprecated after Self Serve 3.1
  businessContactNo?: number
  businessLicenseExpiryDate?: Moment | null
  tinNpwp?: string
  merchantsFormerNames: Array<string>
  businessDateOfIncorporation?: Moment | null
  companyDisplayname?: string

  // New fields after Self Serve 3.1 and migration from core to Fazz Biz
  businessAddressLine1?: string
  businessAddressLine2?: string
  businessDisplayName?: string
  businessName?: string
  businessPostalCode?: string
  businessRegistrationNumber?: string
  businessType?: BusinessType | string
  city?: string
  country?: string
  countryOfIncorporation?: string
  dateOfIncorporation?: Moment
  formerNames?: string[]
  prefilledByAcra: boolean
}

export type AcraBusinessInformation = {
  businessType: BusinessType
  ssicCode?: string
  ssicDescription?: string
  ssicOtherDescription?: string
  businessName?: string
  businessRegistrationNumber?: string
  dateOfIncorporation?: string
  countryOfIncorporation?: string
  businessAddressLine_1?: string
  businessAddressLine_2?: string
  businessPostalCode?: string
  city?: string
  country?: string
  formerNames?: [BusinessFormerName] | []
  beneficial_owners_information: Array<AcraBeneficialOwner> | []
}

export type AcraBeneficialOwner = {
  shareholderId: string
  fullName: string
  ownerType: 'CORPORATE' | 'INDIVIDUAL'
  ownerRole: AcraRole
  ownershipPercentage: number
  shareType: string
  shareAmountDetails: {
    preference: number
    others: number
    ordinary: number
  }
}

export type BusinessFormerName = {
  effectiveDate: string
  endDate: string
  formerName: string
}

export type BusinessType =
  | 'private_company'
  | 'public_company'
  | 'sole_proprietor'
  | 'sole_proprietorship'
  | 'partnership'
  | 'limited_partnership'
  | 'limited_liability_partnership'
  | 'others'
  | undefined

export type BusinessRelation = {
  // Old fields to be deprecated after Self Serve 3.1
  methods?: Array<'xfers_accept' | 'xfers_send' | 'xfers_wallet'>

  // New fields after Self Serve 3.1 and migration from core to Fazz Biz
  productsToBeUsed?: string[]
  purposeOfSettingUpXfersAccount?: Array<typeof purposeOfBusinessList[number]>
  otherPurposeOfSettingUpXfersAccount?: string
}

type SourceOfFunds = typeof sourceOfFundsList[number]
type SourceOfFundsLoanFromDirectorShareholders =
  typeof sourceOfFundsLoanFromDirectorShareholdersList[number]

export type BusinessActivity = {
  // Old fields to be deprecated after Self Serve 3.1
  expectedValuePerTransaction?: string
  expectedGtv?: string
  sourceOfWealth?: string
  sourceOfGoods?: string
  businessSubCategory?: string
  ssic_description?: string
  ssic_other_description?: string
  sourceOfFunds?: string

  // New fields after Self Serve 3.1 and migration from core to Fazz Biz
  businessActivitySummary?: string
  businessWebsite?: string
  countriesOfActivities?: string[]
  expectedTransactionCount?: string
  expectedTransactionVolume?: string
  sourceOfFundsLoanFromDirectorShareholders?: SourceOfFundsLoanFromDirectorShareholders[]
  newSourceOfFunds?: SourceOfFunds[] // rename this once implement new api
  otherSourceOfFunds?: string
  otherSourceOfFundsLoanFromDirectorShareholders?: string
  otherInvestmentReturns?: string
  ssicCode?: string
  ssicDescription?: string
  ssicOtherDescription?: string
  secondarySsicCode?: string
  secondarySsicDescription?: string
  secondarySsicOtherDescription?: string
}

export type MerchantTinItemType = {
  country: string
  tax_identification_number: string
}

export type BusinessTaxResidency = {
  taxInformations?: {
    details?: {
      countryOfTaxResidence?: string
    }[]
  }
}

export type IndividualOfficer = {
  declarations: {
    pepDeclaration: boolean
    companyOwner: boolean
    companyController: boolean
  }
}

export type DirectorOrBoPage = {
  pepDeclaration: boolean
  declarations: {
    pepDeclaration: boolean
    companyOwner: boolean
    companyController: boolean
  }
}

export type UploadDocument = {
  // Old fields to be deprecated after Self Serve 3.1
  putIdFrontUrl?: string
  getIdFrontUrl?: string
  putBizfileUrl?: string
  getBizfileUrl?: string
  putMemorandumUrl?: string
  getMemorandumUrl?: string
  putCertificateOfIncorporationUrl?: string
  getCertificateOfIncorporationUrl?: string
  putRegisterOfMembersUrl?: string
  getRegisterOfMembersUrl?: string
  putRegisterOfDirectorsUrl?: string
  getRegisterOfDirectorsUrl?: string
  putBoardResolutionUrl?: string
  getBoardResolutionUrl?: string
  putBusinessProofOfAddressUrl?: string
  getBusinessProofOfAddressUrl?: string
  putShareholdingChartUrl?: string
  getShareholdingChartUrl?: string
  putMolhrUrl?: string
  getMolhrUrl?: string
  putBankAccountProofUrl?: string
  getBankAccountProofUrl?: string
  idFrontUrl?: string
  isIdFrontPdf?: boolean
  bizfileUrl?: string
  isBizfilePdf?: boolean
  memorandumUrl?: string
  isMemorandumPdf?: boolean
  certificateOfIncorporationUrl?: string
  isCertificateOfIncorporationPdf?: boolean
  registerOfMembersUrl?: string
  isRegisterOfMembersPdf?: boolean
  registerOfDirectorsUrl?: string
  isRegisterOfDirectorsPdf?: boolean
  molhrUrl?: string
  isMolhrPdf?: boolean
  bankAccountProofUrl?: string
  isBankAccountProofPdf?: boolean
  boardResolutionUrl?: string
  isBoardResolutionPdf?: boolean
  shareholdingChartUrl?: string
  isShareholdingChartPdf?: boolean
  businessProofOfAddressUrl?: string
  isBusinessProofOfAddressPdf?: boolean

  // 2nd phase removal (deprecated fields)
  certificateOfIncorporation?: Attachment[]
  certificateOfIncorporationSignedId?: string
  registerOfDirectors?: Attachment[]
  registerOfDirectorsSignedId?: string
  registerOfMembers?: Attachment[]
  registerOfMembersSignedId?: string

  // New fields after Self Serve 3.1 and migration from core to Fazz Biz
  bizfile: Attachment[]
  boardResolution: Attachment[]
  memorandum: Attachment[]
  shareholdingChart: Attachment[]
  businessProofOfAddress: Attachment[]
  corporateOwnership?: boolean
}

export type AccountManager = {
  fullName?: string
  dateOfBirth?: Moment | null
  countryOfBirth?: string
  nationality?: string
  nricType?: string
  NRIC?: string
  nricIssueDate?: Moment | null
  nricExpiryDate?: Moment | null
  addressLine1?: string
  addressLine2?: string
  village?: string
  subDistrict?: string
  country?: string
  city?: string
  postalCode?: string
  rtRw?: string
  ownershipPercentage?: number
  verificationStatus?: 'initial' | 'verified' | 'pending' | 'rejected' | 'permanently rejected'
  idFrontUrl?: string
  isIdFrontPdf?: boolean
  idBackUrl?: string
  isIdBackPdf?: boolean
  proofOfAddressUrl?: string
  isProofOfAddressPdf?: boolean

  // New fields after self server 3.1
  attributes?: ManagerDetailsAttributes
}

export type Role =
  | 'bo'
  | 'director'
  | 'Beneficial Owner'
  | 'Director & Beneficial Owner'
  | 'Corporate Owner'
  | valueof<typeof NewRoleMap>

export const NewRoleMap = {
  Director: 'Director',
  DirectorAndOwner: 'Director & Owner',
  Owner: 'Owner',
  Controller: 'Controller',
  SeniorManagement: 'Senior Management',
}

export type AcraRole =
  | 'director'
  | 'beneficial_owner'
  | 'director_beneficial_owner'
  | 'corporate_beneficial_owner'

export type IndividualAddFlowMeta = {
  // individual role_info step
  type: 'individual'
  isDirectorOrBo: boolean
  role?: Role[] | 'account_manager' | string
  fullName?: string
  dateOfBirth?: Moment
  countryOfBirth?: string
  nationality?: string
  nricType?: string
  NRIC?: string
  nricIssueDate?: Moment | null
  nricExpiryDate?: Moment | null
  ownershipPercentage?: number
  awareOfPoaSubmission: boolean

  // individual residential_address step
  addressLine1?: string
  addressLine2?: string
  country?: string
  city?: string
  postalCode?: string

  // upload document step
  putIdFrontUrl?: string
  getIdFrontUrl?: string
  putIdBackUrl?: string
  getIdBackUrl?: string
  putProofOfAddressUrl?: string
  getProofOfAddressUrl?: string

  // fetch from database
  id?: string | number
  name?: string
  idFrontUrl?: string
  isIdFrontPdf?: boolean
  idBackUrl?: string
  isIdBackPdf?: boolean
  proofOfAddressUrl?: string
  isProofOfAddressPdf?: boolean
  idFrontDisabled?: boolean
  idBackDisabled?: boolean
  proofOfAddressDisabled?: boolean
  deedOfAppointmentUrl?: string
  isDeedOfAppointmentPdf?: boolean
  is_shadow_account_manager?: boolean

  // Info from acra API
  boInfoFromAcra?: AcraBeneficialOwner
  keyId?: number

  // New fields after self serve 3.1
  countryOfResidence?: string
  gender?: 'MALE' | 'FEMALE'
  identityType?: IdentityType
  identityExpiryDate?: Moment
  identityNo?: string
  idFront?: Attachment
  idBack?: Attachment
  proofOfAddress?: Attachment
  passport?: Attachment
  shareType?: string
}

export type CompanyAddFlowMeta = {
  type: 'company'
  businessType?: BusinessType
  businessName?: string
  businessRegistrationNumber?: string
  dateOfIncorporation?: Moment
  countryOfIncorporation?: string
  role: Role[] | [] | string
  ownershipPercentage?: number

  // upload document step
  putBizfileUrl?: string
  getBizfileUrl?: string
  putMemorandumUrl?: string
  getMemorandumUrl?: string

  // fetch from database
  id?: string | number
  name?: string
  bizfileUrl?: string
  isBizfilePdf?: boolean
  memorandumUrl?: string
  isMemorandumPdf?: boolean

  // Info from acra API
  boInfoFromAcra?: AcraBeneficialOwner
  keyId?: number
}

export type AddFlowMeta = IndividualAddFlowMeta | CompanyAddFlowMeta

export type State = {
  acraBusinessInformation: AcraBusinessInformation
  businessInformation: BusinessInformation
  businessRelation: BusinessRelation
  businessActivity: BusinessActivity
  businessTaxResidency: BusinessTaxResidency
  individualOfficer: IndividualOfficer
  uploadDocument: UploadDocument
  directorOrBoPage: DirectorOrBoPage
  accountManager: AccountManager
  addFlowStep: AddFlowSteps
  addFlowMeta: AddFlowMeta
  directorAndBoList: AddFlowMeta[]
  verificationStatus?: 'unverified' | 'rejected' | 'verified' | 'pending' | VerificationStatusType
  currentPath?: PATHS
  review?: undefined
  isIndo: boolean
  isSg: boolean
  initialized: boolean
  acraApiTries: number
  acraBOFetched: boolean
  managerVerificationStatus?: VerificationStatusType
  beneficiaries: AddFlowMeta[]
  acraBoList: AcraBeneficialOwner[]
}

export type AddFlowSteps =
  | null
  | 'role_info'
  | 'residential_address'
  | 'choose_role'
  | 'upload_documents'
  | 'upload_proof_of_address'
  | 'upload_biz_document'

export type Pages =
  | 'businessInformation'
  | 'businessRelation'
  | 'businessActivity'
  | 'businessTaxResidency'
  | 'individualOfficer'
  | 'uploadDocument'
  | 'directorOrBoPage'
  | 'addFlowMeta'
  | 'review'
  | 'editBusinessAddress'

export type DocumentTypes =
  | 'bizfile'
  | 'memorandum'
  | 'certificateOfIncorporation'
  | 'registerOfMembers'
  | 'registerOfDirectors'
  | 'molhr'
  | 'bankAccountProof'
  | 'idFront'
  | 'idBack'
  | 'passport'
  | 'proofOfAddress'
  | 'beneficialOwnerDeclaration'
  | 'deedOfAppointment'
  | 'boardResolution'
  | 'shareholdingChart'
  | 'businessProofOfAddress'

export type EndpointMap = { [key in Pages]?: ENDPOINTS }

type InitBusinessData = {
  type: typeof BusinessVerificationActions.INIT_BUSINESS_DATA
  data: object
}

type SetBusinessData = {
  type: typeof BusinessVerificationActions.SET_BUSINESS_DATA
  page: Pages
  key: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
}

type SetDirectUrl = {
  type: typeof BusinessVerificationActions.SET_DIRECT_URL
  page: Pages
  urls: object
}

type SetAddFlowStep = {
  type: typeof BusinessVerificationActions.SET_ADD_FLOW_STEP
  step: AddFlowSteps
}

type SetAddFlowMeta = {
  type: typeof BusinessVerificationActions.SET_ADD_FLOW_META
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: any
}

type SetCurrentPath = {
  type: typeof BusinessVerificationActions.SET_CURRENT_PATH
  path: PATHS
}

type SetAcraBusinessInfo = {
  type: typeof BusinessVerificationActions.SET_ACRA_BUSINESS_INFO
  data: AcraBusinessInformation
}

type SetAcraBusinessApiTries = {
  type: typeof BusinessVerificationActions.SET_ACRA_BUSINESS_API_TRIES
  data: number
}

type SetAcraBeneficialOwner = {
  type: typeof BusinessVerificationActions.SET_ACRA_BENEFICIAL_OWNER
  data: AddFlowMeta[]
}

type SetBeneficiaries = {
  type: typeof BusinessVerificationActions.SET_BENEFICIARIES
  data: AddFlowMeta[]
}

type SetAcroBoList = {
  type: typeof BusinessVerificationActions.SET_ACRABOLIST
  data: AcraBeneficialOwner[]
}

type SetAcraBeneficialOwnerFetched = {
  type: typeof BusinessVerificationActions.SET_ACRA_BENEFICIAL_OWNER_FETCHED
  data: boolean
}

export type ActionTypes =
  | InitBusinessData
  | SetBusinessData
  | SetDirectUrl
  | SetAddFlowStep
  | SetAddFlowMeta
  | SetCurrentPath
  | SetAcraBusinessInfo
  | SetAcraBusinessApiTries
  | SetAcraBeneficialOwner
  | SetBeneficiaries
  | SetAcroBoList
  | SetAcraBeneficialOwnerFetched
