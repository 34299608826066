import { Dispatch } from 'react'
import { FazzBizApiPaths } from '~/api/fazzbizApiPaths'
import { get } from '~/api/fazzbizBaseApi'
import { FixMeLater, MORAK_LOAN_VERIFICATION_STATUS, AppType } from '~/types'
import { ModeManager } from '~/managers/ModeManager'
import { ActionTypes, Wallet, WalletActions, WalletLedger, WalletTag } from './types'

export const fetchWallets = (appType: AppType) => (dispatch: Dispatch<ActionTypes>) => {
  get(FazzBizApiPaths.Wallets, {
    headers: {
      sandbox: String(ModeManager.isSandboxMode()),
    },
  }).then((response) => {
    const { data, included } = response.data
    const wallets: Map<WalletTag, Wallet | null> = new Map([])

    const ledgersMap = included.reduce(
      (mapAccumulator: Map<string, WalletLedger>, obj: WalletLedger) => {
        mapAccumulator.set(obj.id, obj)
        return mapAccumulator
      },
      new Map()
    )
    data.forEach((_: FixMeLater) => {
      const wallet = { ..._ }
      const ledgerIds = _.relationships.ledgers.data.map((ledger: WalletLedger) => ledger.id)
      wallet.relationships.ledgers = ledgerIds.map((ledgerId: string) => ledgersMap.get(ledgerId))
      wallets.set(_.attributes.tag, wallet)
    })
    dispatch({
      type: WalletActions.SET_WALLETS,
      wallets,
    })
    dispatch({
      type: WalletActions.SET_WALLET_IS_INITIATED,
      isWalletInitiated: true,
    })
    if (appType === AppType.INDONESIA) {
      get(FazzBizApiPaths.MorakLoanVerificationStatus).then((morakResponse) => {
        dispatch({
          type: WalletActions.SET_MORAK_LOAN_VERIFICATION_STATUS_LOADING,
          morakLoanVerificationStatusLoading: true,
        })
        const isMorakVerified =
          morakResponse.data.data.status === MORAK_LOAN_VERIFICATION_STATUS.VERIFIED
        dispatch({
          type: WalletActions.SET_MORAK_LOAN_VERIFICATION_STATUS,
          morakLoanVerificationStatus: morakResponse.data.data.status,
        })
        dispatch({
          type: WalletActions.SET_IS_MORAK_VERIFIED,
          isMorakVerified,
        })
        dispatch({
          type: WalletActions.SET_MORAK_LOAN_VERIFICATION_STATUS_LOADING,
          morakLoanVerificationStatusLoading: false,
        })
      })
    }
  })
}
