import Empty from 'antd/es/empty'
import styled from '@emotion/styled'
import { EmptyIcon } from '@fazz/design-system-icons'
import { EmptyTransaction } from '@fazz/design-system-logos'
import { BLACK, GREY } from '../../../themes/deprecated/colors'
import { LabelText, Body } from '../Typography'

type EmptyDataProps = {
  title?: string
  subtitle?: string
  info?: React.ReactNode
  svgUniqueId?: string
}

export default function EmptyData({
  title = 'No results found',
  subtitle,
  info,
  svgUniqueId = 'randomString',
}: EmptyDataProps) {
  const centerSvgStyle = { marginBottom: 10, display: 'block', margin: 'auto' }

  // Empty data in the Transaction History using a new Logo.
  if (title === 'No Transaction History' || title === 'No Documents Uploaded') {
    return (
      <Container>
        <Empty image={<EmptyTransaction />} imageStyle={centerSvgStyle} description={false} />
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {info && <Info>{info}</Info>}
      </Container>
    )
  }
  return (
    <Container>
      <Empty
        image={<EmptyIcon id={svgUniqueId} height={80} width={130} />}
        imageStyle={centerSvgStyle}
        description={false}
      />
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {info && <Info>{info}</Info>}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding-top: 4px;
  display: block;
  align-items: center;
  flex-direction: column;
`

const Title = styled(LabelText)`
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
`

const Subtitle = styled(Body)`
  text-align: center;
  color: ${GREY.S500};
  margin-top: 5px;
  margin-bottom: 20px;
  align-items: center;
`

const Info = styled(Body)`
  text-align: center;
  color: ${BLACK.DEFAULT};
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
`
