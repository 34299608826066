import * as React from 'react'

function SvgCross(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.586 23.586a2 2 0 002.828 2.828L15 17.828 23.17 26A2 2 0 1026 23.172L17.828 15 26 6.828A2 2 0 0023.172 4L15 12.172 6.828 4A2 2 0 004 6.828L12.172 15l-8.586 8.586z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCross
