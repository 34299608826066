import * as React from 'react'

function SvgArrowLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.56 2.44a1.5 1.5 0 010 2.12L11.122 15l10.44 10.44a1.5 1.5 0 01-2.122 2.12L8.646 16.769a2.5 2.5 0 010-3.536L19.44 2.44a1.5 1.5 0 012.122 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgArrowLeft
