import { SVGProps } from 'react'

function SvgTransactionCreditLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.27 17.47H6.41a.75.75 0 1 1 0-1.5h2.86a.75.75 0 0 1 0 1.5ZM14 13H6.41a.75.75 0 1 1 0-1.5H14a.75.75 0 0 1 0 1.5ZM9.27 8.48H6.41a.75.75 0 1 1 0-1.5h2.86a.75.75 0 0 1 0 1.5Z"
        fill="currentColor"
      />
      <path
        d="M10.6 21.43H7.68a4.49 4.49 0 0 1-4.5-4.5V7.06a4.49 4.49 0 0 1 4.5-4.5h3.42v1.81a5 5 0 0 0 5 5h1.58v3a.75.75 0 0 0 .75.75.741.741 0 0 0 .75-.75V8.8a2.001 2.001 0 0 0-.58-1.42l-5.74-5.74a2 2 0 0 0-1.41-.58H7.68a6 6 0 0 0-6 6v9.87a6 6 0 0 0 6 6h2.92a.75.75 0 0 0 .75-.75.76.76 0 0 0-.75-.75Zm2-17.91L17 7.87h-.9a3.5 3.5 0 0 1-3.5-3.5v-.85Z"
        fill="currentColor"
      />
      <path
        d="M19.32 14.4h-3.91a3 3 0 0 0-3 3v2.54a3 3 0 0 0 3 3h3.91a3 3 0 0 0 3-3V17.4a3 3 0 0 0-3-3Zm-3.91 1.5h3.91a1.51 1.51 0 0 1 1.49 1.34h-6.89a1.51 1.51 0 0 1 1.49-1.34Zm3.91 5.54h-3.91a1.5 1.5 0 0 1-1.5-1.5v-1.2h6.91v1.2a1.5 1.5 0 0 1-1.5 1.5Z"
        fill="currentColor"
      />
      <path d="M15.79 20.71h-.43a.75.75 0 0 1 0-1.5h.43a.75.75 0 0 1 0 1.5Z" fill="currentColor" />
    </svg>
  )
}

export default SvgTransactionCreditLine
