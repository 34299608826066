export enum SCREENS {
  STATUS = 'STATUS',
  ABOUT = 'ABOUT',
  PERSONAL_DETAILS = 'PERSONAL_DETAILS',
  DECLARATIONS = 'DECLARATIONS',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  ID_FRONT_AND_BACK = 'ID_FRONT_AND_BACK',
  PASSPORT = 'PASSPORT',
  SELFIE = 'SELFIE',
  MY_INFO = 'MY_INFO',
  MY_INFO_DETAILS = 'MY_INFO_DETAILS',
  REVIEW = 'REVIEW',
}

export enum VerificationActions {
  SET_IMMIGRATION_STATUS = 'VERIFICATION_ACTIONS.SET_IMMIGRATION_STATUS',
  SET_RESIDENCY_STATUS = 'VERIFICATION_ACTIONS.SET_RESIDENCY_STATUS',
  SET_FLOW = 'VERIFICATION_ACTIONS.SET_FLOW',
  SET_IS_ID_TYPE_SG_PR = 'VERIFICATION_ACTIONS.SET_IS_ID_TYPE_SG_PR',
  SET_SCREEN = 'VERIFICATION_ACTIONS.SET_SCREEN',
  SET_ERROR = 'VERIFICATION_ACTIONS.SET_ERROR',
  SET_MY_INFO_ONLY_DETAILS = 'VERIFICATION_ACTIONS.SET_MY_INFO_ONLY_DETAILS',
  SET_FORM_OPTIONS = 'SET_FORM_OPTIONS',
  SET_PHYSICAL_CARD_DATA = 'VERIFICATION_ACTIONS.SET_PHYSICAL_CARD_DATA',
  SET_PHYSICAL_CARD_KYC_STATE = 'VERIFICATION_ACTIONS.SET_PHYSICAL_CARD_KYC_STATE',
  SET_PHYSICAL_CARD_LOADING = 'VERIFICATION_ACTIONS.SET_PHYSICAL_CARD_LOADING',
}

export enum ResidencyStatusType {
  LIVING_IN_SG = 'LIVING_IN_SG',
  NOT_LIVING_IN_SG = 'NOT_LIVING_IN_SG',
}

export type ImmigrationStatus =
  | 'singaporean_or_permanent_residence'
  | 'singapore_foreigner'
  | undefined
export type ResidencyStatus = 'living_in_singapore' | 'living_outside_singapore' | undefined
export type VerificationFlows = 'singpass_verification' | 'manual_verification' | undefined

export type FileInfo = { fileName?: string; fileData?: string }

export type DocumentDetail = {
  contentType?: string
  documentType?: string
  fileSize?: number
  filename?: string
  id?: number
  url?: string
}

export type PhysicalCardDetails = {
  citizenship?: ImmigrationStatus
  residency?: ResidencyStatus
  verificationMethod?: VerificationFlows
  fullName?: string | undefined
  dateOfBirth?: string | undefined
  gender?: string | undefined
  nationality?: string | undefined
  addressLine1?: string | undefined
  addressLine2?: string | undefined
  country?: string | undefined
  city?: string | undefined
  kycState?: string | undefined
  postalCode?: string | undefined
  nricIdNumber?: string | undefined
  nricIssueDate?: string | undefined
  nricExpiryDate?: string | undefined
  passportNumber?: string | undefined
  passportIssueDate?: string | undefined
  passportExpiryDate?: string | undefined
  externalId?: string | undefined
  idBack?: DocumentDetail
  idFront?: DocumentDetail
  passport?: DocumentDetail
  proofOfAddress?: DocumentDetail
  selfie?: DocumentDetail
  myinfoState?: string | undefined
}

export type MyInfoOnlyDetails = {
  race?: string
  residentialStatus?: string
  aliasName?: string
  passStatusLastUpdated?: string
  error?: string
}

export type FormOptions = {
  countries: Array<{ label: string; value: string }>
  nationalities: Array<string>
  races: Array<{ label: string; value: string }>
  genders: Array<{ label: string; value: string }>
  occupations: Array<{ label: string; value: string }>
  employmentSectors: Array<string>
  annualIncomes: Array<string>
  expectedTransactionAmounts: Array<string>
  expectedTotalTransactions: Array<string>
  expectedTransactionFrequencies: Array<string>
}

export type OptionProps = {
  label: string
  value: string
}

type SetImmigrationStatus = {
  type: typeof VerificationActions.SET_IMMIGRATION_STATUS
  status: ImmigrationStatus
}

type SetResidencyStatus = {
  type: typeof VerificationActions.SET_RESIDENCY_STATUS
  status: ResidencyStatus
}

type SetFlow = {
  type: typeof VerificationActions.SET_FLOW
  flow: VerificationFlows
}

type SetIsIdTypeSgPr = {
  type: typeof VerificationActions.SET_IS_ID_TYPE_SG_PR
  isIdTypeSgPr: boolean
}

type SetScreen = {
  type: typeof VerificationActions.SET_SCREEN
  screen: SCREENS
}

type SetError = {
  type: typeof VerificationActions.SET_ERROR
  error: string
}

type SetMyInfoOnlyDetails = {
  type: typeof VerificationActions.SET_MY_INFO_ONLY_DETAILS
  myInfoOnlyDetails: MyInfoOnlyDetails
}

type SetFormOptions = {
  type: typeof VerificationActions.SET_FORM_OPTIONS
  formOptions: FormOptions
}

type SetPhysicalCardLoading = {
  type: typeof VerificationActions.SET_PHYSICAL_CARD_LOADING
  physicalCardLoading: boolean
}

type SetPhysicalCardData = {
  type: typeof VerificationActions.SET_PHYSICAL_CARD_DATA
  physicalCardData: PhysicalCardDetails
}

type SetPhysicalCardKYCState = {
  type: typeof VerificationActions.SET_PHYSICAL_CARD_KYC_STATE
  physicalCardKYCState: string
}

export type ActionTypes =
  | SetImmigrationStatus
  | SetResidencyStatus
  | SetFlow
  | SetIsIdTypeSgPr
  | SetScreen
  | SetError
  | SetMyInfoOnlyDetails
  | SetFormOptions
  | SetPhysicalCardLoading
  | SetPhysicalCardData
  | SetPhysicalCardKYCState
