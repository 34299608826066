import {
  Chart as ChartJS,
  ChartData,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import { useRef, useEffect, useState } from 'react'
import { Chart } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Tooltip,
  Legend,
  Filler
)

type CustomObject = { [key: string]: any }
type ChartProps = {
  xValues: number[] | string[]
  yValues: number[]
  dataPrefix?: string
  dataPostfix?: string
  height?: number
}

function CustomChart({ xValues, yValues, dataPrefix, dataPostfix, height = 40 }: ChartProps) {
  const chartRef = useRef<ChartJS>(null)
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    datasets: [],
  })

  const data = {
    labels: xValues,
    datasets: [
      {
        data: yValues,
      },
    ],
  }

  const prefix = dataPrefix || ''
  const postfix = dataPostfix || ''

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const context = chart.ctx

    const gradient = context.createLinearGradient(0, 0, 0, 280)
    gradient.addColorStop(0.3, 'rgba(47, 141, 238, 0.08)')
    gradient.addColorStop(0.6, 'rgba(47, 141, 238, 0.0002)')
    gradient.addColorStop(1, 'rgba(47, 141, 238, 0')

    setChartData({
      ...data,
      datasets: data.datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: gradient,
        fill: true,
        borderColor: '#2E8DEE',
        pointRadius: 0,
      })),
    })
  }, [])

  type Alignment = 'left' | 'right' | 'center'

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          font: {
            family: "'Inter', 'Arial', sans-serif",
          },
        },
      },
      tooltip: {
        intersect: false,
        padding: 15,
        displayColors: false,
        titleAlign: 'center' as Alignment,
        bodyAlign: 'center' as Alignment,
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 14,
        },
        callbacks: {
          label: (tooltipData: CustomObject) => {
            let label = tooltipData.dataset.label || ''
            if (tooltipData.parsed.y !== null) {
              label += `${prefix}${tooltipData.parsed.y}${postfix}`
            }
            return label
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: 'rgba(0, 0, 0, 0.4)',
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          maxTicksLimit: 3,
          color: 'rgba(0, 0, 0, 0.4)',
          callback: (label: string | number) => `${prefix}${label}${postfix}`,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
  }

  return (
    // how to resize chart
    // https://www.chartjs.org/docs/latest/configuration/responsive.html
    <div
      style={{
        position: 'relative',
        height: `${height}vh`,
        width: '99%', // workaround for responsive width (https://stackoverflow.com/a/70191511)
      }}
    >
      <Chart id="chart" ref={chartRef} type="line" data={chartData} options={options} />
    </div>
  )
}

export default CustomChart
