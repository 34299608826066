import FormikInputLabel from 'fds3-src/storybook/components/FormikInputLabel'
import { Global } from '@emotion/core'
import { NewCalendar } from '@fazz/design-system-icons'
import {
  StyledFormikDatePicker,
  StyledFormikRangePicker,
  StyledFormItem,
  StyledDatePickerDropdown,
} from './FormikDatePicker.styles'
import { FormikDatePickerSingleProps, FormikDatePickerRangeProps } from './FormikDatePicker.types'

export function FormikDatePicker({
  isDisabled = false,
  style,
  className,
  defaultValue,
  name,
  label,
  helperText,
  format,
  disabledDate,
  placeholder,
}: // DO NOT USE SPREAD OPERATOR i.e. {...props}
FormikDatePickerSingleProps) {
  return (
    <div style={style} className={className}>
      {label && <FormikInputLabel label={label} />}
      <StyledFormItem name={name} extra={helperText}>
        <StyledFormikDatePicker
          suffixIcon={<NewCalendar width={24} height={24} />}
          disabled={isDisabled}
          name={name}
          disabledDate={disabledDate}
          placeholder={placeholder}
          defaultValue={defaultValue}
          format={format}
          // DO NOT USE SPREAD OPERATOR i.e. {...props}
        />
      </StyledFormItem>
    </div>
  )
}

FormikDatePicker.Range = function FormikDateRangePicker({
  isDisabled = false,
  style,
  className,
  name,
  label,
  helperText,
  format,
  disabledDate,
  placeholder,
  ranges,
  defaultValue,
}: // DO NOT USE SPREAD OPERATOR i.e. {...props}
FormikDatePickerRangeProps) {
  return (
    <div style={style} className={className}>
      {label && <FormikInputLabel label={label} />}

      <StyledFormItem name={name} extra={helperText}>
        <Global styles={StyledDatePickerDropdown} />
        <StyledFormikRangePicker
          suffixIcon={<NewCalendar width={24} height={24} />}
          disabled={isDisabled}
          name={name}
          defaultValue={defaultValue}
          format={format}
          placeholder={placeholder}
          ranges={ranges}
          disabledDate={disabledDate}
          // DO NOT USE SPREAD OPERATOR i.e. {...props}
        />
      </StyledFormItem>
    </div>
  )
}

// FormikDatePicker.displayName = 'FormikDatePicker'
export default FormikDatePicker
