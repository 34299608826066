import { Label } from 'fds3-src/themes/Typography'
import { fdsTheme } from 'fds3-src/themes/theme-fds3'
import { StyledFormItem, StyledTextArea } from './FormikTextArea.styles'
import { type FormikTextAreaProps } from './FormikTextArea.types'

const { colors, spacing } = fdsTheme

export function FormikTextArea({
  inputClassName,
  inputStyles,
  name,
  placeholder,
  counter,
  isDisabled = false,
  label,
  helperText,
  resize = 'none',
  showCount = false,
  onBlur,
  onFocus,
  onResize,
  onChange,
  onPressEnter,
}: FormikTextAreaProps) {
  return (
    <StyledFormItem name={name} extra={helperText}>
      <div style={{ textAlign: 'left' }}>
        {label && (
          <Label size="md" color={colors.labelInput} style={{ marginBottom: spacing.xxs }}>
            {label}
          </Label>
        )}
        <StyledTextArea
          className={inputClassName}
          style={inputStyles}
          resize={resize}
          name={name}
          placeholder={placeholder}
          disabled={isDisabled}
          maxLength={counter}
          showCount={showCount}
          onResize={onResize}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          onPressEnter={onPressEnter}
        />
      </div>
    </StyledFormItem>
  )
}

export default FormikTextArea
