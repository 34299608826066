import { BLACK, BLUE, GREEN, GREY, RED, YELLOW, WHITE } from './colors'
import { LAYOUT_SPACING_DESKTOP, LAYOUT_SPACING_MOBILE } from './spacing'

// root theme: define function level & component level colors
/** @deprecated please use fazzBizzThemeFds3 theme instead */
const rootTheme = {
  spacing: {
    xxs: '4px',
    xs: '8px',
    sm: '12px',
    md: '16px',
    lg: '24px',
    xl: '32px',
  },
  layout: {
    desktop: LAYOUT_SPACING_DESKTOP,
    mobile: LAYOUT_SPACING_MOBILE,
  },
  text: {
    default: BLACK.DEFAULT,
    white: WHITE.DEFAULT,
  },
  button: {
    background: '#F4F5FA', // TODO: deprecated, do not use this value. should change to button.secondaryButton
    color: BLACK.DEFAULT,
    border: GREY.S200,
    hover: BLACK.T10,
    secondary: '#F4F5FA',
    secondaryButton: '#E2E9FB',
    // TODO: delete this after new domain launch, change with interactive.inactive
    disabled: '#BDBDBD',
  },
  actionButton: {
    color: GREY.S400,
    hover: GREY.T10,
  },
  neutral: {
    text: GREY.S400,
    emphasis: GREY.S300,
    border: GREY.S200,
    background: GREY.S100,
    white: WHITE.DEFAULT,
    bluish: GREY.BLUISH,
  },
  success: {
    text: GREEN.S400,
    emphasis: GREEN.S300,
    border: GREEN.S300,
    background: GREEN.S100,
  },
  warning: {
    text: YELLOW.S400,
    emphasis: YELLOW.S300,
    border: YELLOW.S300,
    background: YELLOW.S100,
  },
  error: {
    text: RED.S400,
    emphasis: RED.S300,
    border: RED.S300,
    background: RED.S100,
  },
  card: {
    background: {
      default: WHITE.DEFAULT,
      dark: BLACK.T10,
    },
  },
  divider: {
    background: GREY.S200,
  },
}

// define brand level colors
/** @deprecated please use fazzBizzThemeFds3 theme instead */
export const fazzbizTheme = {
  ...rootTheme,
  brand: {
    primary: {
      default: BLUE.DEFAULT as string,
      emphasis: BLUE.S300 as string,
      background: BLUE.S100 as string,
      contrast: WHITE.DEFAULT as string,
    },
    action: {
      hover: BLUE.T10 as string,
      disabledOpacity: 0.7,
      hoverOpacity: 0.88, // TODO: TBD deprecated, hover color effect changed to overlay
    },
  },
  base: {
    primary: '#0C45E1',
    onContainer: '#FFFFFF',
    neutralSecondary: '#757575',
    neutral: '#121212',
    line: '#E0E0E0',
  },
  interactive: {
    active: '#0C45E1',
    inactive: '#BDBDBD',
    hoverOnNeutral: '#21409a1f',
    hoverOnPrimary:
      'linear-gradient(0deg, rgba(18, 18, 18, 0.24), rgba(18, 18, 18, 0.24)), #0C45E1;',
    neutralSecondary: '#777777',
  },
  status: {
    positive: '#00B583',
    subduePositive: '#E5F8F3',
    warning: '#F18813',
    subdueWarning: '#FEF3E7',
    critical: '#D93025',
    subdueCritical: '#FBEAE9',
  },
  input: {
    placeholder: '#B6B6B6',
  },
  progress: {
    primary: '#00B583',
    active: '#fff',
  },
}

export const straitsXTheme = {
  ...rootTheme,
  brand: {
    primary: {
      default: GREEN.DEFAULT as string,
      emphasis: GREEN.DEFAULT as string,
      background: GREEN.G100 as string,
      contrast: WHITE.DEFAULT as string,
    },
    action: {
      default: GREEN.DEFAULT as string,
      hover: GREEN.HOVER as string,
      disabledOpacity: 0.7,
    },
  },
}
