import { Reducer } from 'redux'
import { featureGateIDs } from './helpers'
import { ActionTypes, GetFeatureGateActions, State } from './types'

export const initialState: State = {
  featureGateIDs: { ...featureGateIDs },
  isFeatureGateLoading: false,
}

export const featureGate: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case GetFeatureGateActions.SET_FEATURE_GATE_IDS: {
      return {
        ...state,
        featureGateIDs: { ...action.featureGateIDs },
      }
    }
    case GetFeatureGateActions.SET_FEATURE_GATE_IS_LOADING: {
      return {
        ...state,
        isFeatureGateLoading: action.isFeatureGateLoading,
      }
    }
    default:
      return state
  }
}
