import type {
  TransactionStatus,
  TransactionType,
  BusinessVerificationStatus,
  PersonalVerificationStatus,
} from '~/types'

export type BusinessAccount = {
  generalWallet: {
    availableBalance: string
    holdingBalance?: string
    fundPoolId?: number
  } | null
}

export type CorporateAccount = {
  generalWallet: {
    availableBalance: string
  } | null
}

export type Transaction = {
  amount: {
    prefix: string
    postfix: string
  }
  created_at: string
  transaction_status: TransactionStatus
  transaction_type: TransactionType
  description: {
    data: string
    direction: 'from' | 'to'
    type: TransactionType
  }
}

export type ManagedAccount = {
  account_title: string
  availableBalance: string
}

export type TransferInfo = {
  recipientName: string
  recipientBankName: string
  recipientAccountNo: string
  referenceNumber?: string
  expiredDate?: string
}

export type DashboardBanner = {
  title: string
  tag: string
  content: string
}

export type MerchantTransactionLimits = {
  maxDepositLimit: number
  maxWithdrawalLimit: number
  maxXfersDashLimit: number
  maxXfersDashRequestRowsLimit: number
}

export enum LandingPageActions {
  SET_USER_INFO = 'LandingPageActions.SET_USER_INFO',
  SET_TEMP_HOLDING_BALANCE = 'LandingPageActions.SET_TEMP_HOLDING_BALANCE',
  SET_BUSINESS_ACCOUNT = 'LandingPageActions.SET_BUSINESS_ACCOUNT',
  SET_IS_LOADING_BUSINESS_ACCOUNT = 'LandingPageActions.SET_IS_LOADING_BUSINESS_ACCOUNT',
  SET_LATEST_BUSINESS_TRANSACTIONS = 'LandingPageActions.SET_LATEST_BUSINESS_TRANSACTIONS',
  SET_CORPORATE_ACCOUNT = 'LandingPageActions.SET_CORPORATE_ACCOUNT',
  SET_MANAGED_ACCOUNTS = 'LandingPageActions.SET_MANAGED_ACCOUNTS',
  SET_BUSINESS_VERIFICATION_STATUS = 'LandingPageActions.SET_BUSINESS_VERIFICATION_STATUS',
  SET_TRANSFER_INFO = 'LandingPageActions.SET_TRANSFER_INFO',
  SET_IS_DASHBOARD_BANNER_MANAGEMENT_ENABLED = 'LandingPageActions.SET_IS_DASHBOARD_BANNER_MANAGEMENT_ENABLED',
  SET_DASHBOARD_BANNERS = 'ROUTE_ACTIONS.SET_DASHBOARD_BANNERS',
  SET_MERCHANT_TRANSACTION_LIMITS = 'SET_MERCHANT_TRANSACTION_LIMITS',
  SET_DISBURSEMENT_LIMIT_AMOUNT = 'SET_DISBURSEMENT_LIMIT_AMOUNT',
  SET_LEBARAN_BANNER_HIDDEN = 'SET_LEBARAN_BANNER_HIDDEN',
}

type SetUserInfo = {
  type: typeof LandingPageActions.SET_USER_INFO
  name: string
  verificationStatus: PersonalVerificationStatus
}

type SetBusinessAccount = {
  type: typeof LandingPageActions.SET_BUSINESS_ACCOUNT
  businessAccount: BusinessAccount
}

type SetLatestBusinessTransactions = {
  type: typeof LandingPageActions.SET_LATEST_BUSINESS_TRANSACTIONS
  latestBusinessTransactions: Transaction[]
}

type SetCorporateAccount = {
  type: typeof LandingPageActions.SET_CORPORATE_ACCOUNT
  corporateAccount: CorporateAccount
}

type SetManagedAccounts = {
  type: typeof LandingPageActions.SET_MANAGED_ACCOUNTS
  managedAccounts: ManagedAccount[]
}

type SetBusinessVerificationStatus = {
  type: typeof LandingPageActions.SET_BUSINESS_VERIFICATION_STATUS
  businessVerificationStatus: BusinessVerificationStatus
}

type SetTransferInfo = {
  type: typeof LandingPageActions.SET_TRANSFER_INFO
  transferInfo: TransferInfo
}

type SetDashboardBanners = {
  type: typeof LandingPageActions.SET_DASHBOARD_BANNERS
  dashboardBanners: DashboardBanner[]
}

type SetIsDashboardBannerManagementEnabled = {
  type: typeof LandingPageActions.SET_IS_DASHBOARD_BANNER_MANAGEMENT_ENABLED
  isDashboardBannerManagementEnabled: boolean
}

type SetMerchantsTransactionsLimits = {
  type: typeof LandingPageActions.SET_MERCHANT_TRANSACTION_LIMITS
  merchantTransactionLimits: MerchantTransactionLimits
}

type SetDisbursementLimitAmount = {
  type: typeof LandingPageActions.SET_DISBURSEMENT_LIMIT_AMOUNT
  disbursementLimitAmount: number
}

type SetIsLoadingBusinessAccount = {
  type: typeof LandingPageActions.SET_IS_LOADING_BUSINESS_ACCOUNT
  isLoading: boolean
}

export type ActionTypes =
  | SetUserInfo
  | SetBusinessAccount
  | SetLatestBusinessTransactions
  | SetCorporateAccount
  | SetManagedAccounts
  | SetBusinessVerificationStatus
  | SetTransferInfo
  | SetIsDashboardBannerManagementEnabled
  | SetDashboardBanners
  | SetMerchantsTransactionsLimits
  | SetDisbursementLimitAmount
  | SetIsLoadingBusinessAccount
