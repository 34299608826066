import styled from 'styled-components'
import { fdsTheme } from '../../../themes/theme-fds3'
import { ButtonGroupProps, ButtonGroupVariant } from './ButtonGroup.types'

// Retrieve all theming data
const { spacing } = fdsTheme

// Functions when there are more than 2 conditions
const getFlexDirection = (variant?: ButtonGroupVariant) => {
  switch (variant) {
    case 'vertical':
      return 'column'
    case 'horizontal':
    default:
      return 'row'
  }
}

export const StyledButtonGroup = styled.div.attrs((props: ButtonGroupProps) => ({
  variant: props.variant,
}))`
  display: flex;
  gap: ${spacing.md};

  ${({ variant }) => `
    flex-direction: ${getFlexDirection(variant)};
  `}
`
