import styled from '@emotion/styled'
import {
  baseStyle,
  titleStyle,
  titleSmallStyle,
  labelLargeStyle,
  bodyStyle,
  labelStyle,
  bodySmallStyle,
} from '../../../themes/deprecated/fontStyles'

/**
 * @deprecated - use new FDS3 Typography. <Typography.Title>
 */
export const Title = styled.h1`
  ${baseStyle};
  ${titleStyle};
`

/**
 * @deprecated - use new FDS3 Typography. <Typography.Title>
 */
export const TitleSmall = styled.h2`
  ${baseStyle};
  ${titleSmallStyle};
`

/**
 * @deprecated - use new FDS3 Typography. <Typography.Label>
 */
export const LabelLarge = styled.h3`
  ${baseStyle};
  ${labelLargeStyle};
`

/**
 * @deprecated - use new FDS3 Typography. <Typography.Label>
 */
export const LabelText = styled.h4`
  ${baseStyle};
  ${labelStyle};
`

/**
 * @deprecated - use new FDS3 Typography. <Typography.Body>
 */
export const Body = styled.div`
  ${baseStyle};
  ${bodyStyle};
`

/**
 * @deprecated - use new FDS3 Typography. <Typography.Body>
 */
export const BodySmall = styled.div`
  ${baseStyle};
  ${bodySmallStyle}
`
