import * as React from 'react'

function SvgVecRecipients(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 130 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#vec_recipients_svg__clip0)">
        <path
          opacity={0.8}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.003 15.228c1.326-4.192 2.56-6.802 3.7-7.83 3.157-2.849 7.522-1.299 8.45-1.077 3.26.782 2.2-4.364 4.997-5.41 1.864-.697 3.397.155 4.6 2.556 1.065-2.24 2.688-3.266 4.87-3.083 3.271.277 4.416 11.328 8.912 8.909 4.497-2.42 10.01-2.973 12.365.623.51.778.704-.429 4.166-4.55 3.462-4.122 6.914-5.938 13.996-3.538 3.22 1.09 5.868 4.045 7.947 8.864 0 6.878 5.06 10.95 15.178 12.213 15.179 1.895 3.397 18.214-15.178 22.993-18.576 4.78-61.343 7.36-84.55-4.716-15.473-8.049-11.957-16.7 10.546-25.954h.001z"
          fill="url(#vec_recipients_svg__paint0_linear)"
        />
        <path
          d="M64.987 76.008c15.457 0 27.987-2.9 27.987-6.478s-12.53-6.478-27.987-6.478C49.53 63.052 37 65.952 37 69.53s12.53 6.478 27.987 6.478z"
          fill="url(#vec_recipients_svg__paint1_linear)"
        />
        <path
          opacity={0.675}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.201 77.613c-21.043 5.278-92.87-.76-100.515-3.516C6.965 72.754 3.61 70.229.625 66.52a2.822 2.822 0 012.198-4.593h125.514c2.605 6.938-3.107 12.166-17.136 15.685z"
          fill="url(#vec_recipients_svg__paint2_linear)"
        />
        <path
          d="M86.396 57.052L76.825 45.86a2.434 2.434 0 00-1.837-.86h-20.58c-.706 0-1.377.324-1.837.86L43 57.052v6.144h43.396v-6.144z"
          fill="url(#vec_recipients_svg__paint3_linear)"
        />
        <path
          d="M69.602 50.42c.007-1.505.014-3.008-.007-4.513-.012-.902-.59-1.502-1.488-1.51a401.877 401.877 0 00-7.217 0c-.898.01-1.476.61-1.488 1.508-.022 1.587-.022 3.17-.003 4.757.01.936.584 1.505 1.532 1.572.266.019.535.002.9.002 0 1.387-.012 2.695.004 4.001.015 1.15.567 1.697 1.721 1.728.547.014 1.095.007 1.64.005 1.456-.007 1.963-.514 1.97-1.978.007-1.224.002-2.448.002-3.696.279-.024.466-.043.653-.05 1.262-.05 1.778-.574 1.78-1.827zm-11.107 1.072c-.116-2.114-.034-4.24-.034-6.477-1 0-2.172-.044-3.338.017-.74.038-1.097.57-1.107 1.286-.021 1.286-.021 2.57 0 3.854.012.797.447 1.241 1.253 1.354.238.033.478.05.708.074.036.123.077.2.077.276.01.847.017 1.695.017 2.542.002 1.581.336 1.86 1.927 1.927 2.467.103 2.697-.514 2.505-2.755-1.039-.322-1.946-.958-2.008-2.098zm16.49-5.208c-.01-.605-.305-1.159-.9-1.202-1.207-.094-2.426-.026-3.61-.026.06.559.147 1.041.156 1.526.022 1.313.01 2.625.01 3.94-.002 1.808-.406 2.403-2.143 3.113-.178 2.42.086 2.705 2.45 2.698h.082c1.572-.005 1.903-.346 1.9-1.944v-2.784c.38-.04.651-.05.917-.098.716-.128 1.126-.562 1.138-1.287.024-1.31.017-2.623 0-3.936zM64.511 43.4c1.486-.008 2.638-1.16 2.652-2.648.01-1.517-1.183-2.733-2.673-2.729-1.49.005-2.671 1.234-2.65 2.753a2.628 2.628 0 002.671 2.623zm-6.187.77a2.279 2.279 0 002.223-2.275c-.01-1.217-1.052-2.24-2.264-2.228-1.238.01-2.21 1.018-2.2 2.273.01 1.246 1.017 2.252 2.241 2.23zm12.389.002a2.233 2.233 0 002.208-2.27c-.007-1.253-1.013-2.251-2.242-2.235-1.221.02-2.236 1.052-2.227 2.266.012 1.22 1.056 2.254 2.261 2.24z"
          fill="url(#vec_recipients_svg__paint4_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="vec_recipients_svg__paint0_linear"
          x1={67.794}
          y1={37.313}
          x2={67.794}
          y2={-8.37}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DEDEDE" stopOpacity={0} />
          <stop offset={1} stopColor="#A9A9A9" stopOpacity={0.3} />
        </linearGradient>
        <linearGradient
          id="vec_recipients_svg__paint1_linear"
          x1={62.071}
          y1={76.008}
          x2={62.071}
          y2={63.052}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#96A1C5" stopOpacity={0.373} />
        </linearGradient>
        <linearGradient
          id="vec_recipients_svg__paint2_linear"
          x1={64.474}
          y1={79.706}
          x2={64.474}
          y2={58.43}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#919191" stopOpacity={0.15} />
        </linearGradient>
        <linearGradient
          id="vec_recipients_svg__paint3_linear"
          x1={64.997}
          y1={50.435}
          x2={64.997}
          y2={63.196}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F8DEE" />
          <stop offset={1} stopColor="#5FA8F2" />
        </linearGradient>
        <linearGradient
          id="vec_recipients_svg__paint4_linear"
          x1={64.645}
          y1={38.023}
          x2={64.645}
          y2={57.974}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5FA8F2" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <clipPath id="vec_recipients_svg__clip0">
          <path fill="#fff" d="M0 0h130v80H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgVecRecipients
