import { SVGProps } from 'react'

function SvgEarn(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#Earn_svg__a)" fill="currentColor">
        <path d="M10.003 1.875a3.375 3.375 0 1 0 0 6.75 3.375 3.375 0 0 0 0-6.75Zm.832 4.642a1.059 1.059 0 0 1-.625.3v.466l-.416.084v-.542c-.14 0-.28-.016-.417-.05a2.5 2.5 0 0 1-.4-.108l.084-.475c.25.045.503.07.758.075.333 0 .5-.1.5-.284a.191.191 0 0 0-.075-.15 1.943 1.943 0 0 0-.242-.183l-.133-.067-.217-.116a1.425 1.425 0 0 1-.508-.384.833.833 0 0 1-.158-.5.833.833 0 0 1 .183-.608 1.1 1.1 0 0 1 .634-.292v-.466l.408-.084v.534c.214.019.422.075.617.166l-.084.484a3.443 3.443 0 0 0-.633-.059.542.542 0 0 0-.308.075.233.233 0 0 0-.117.217.217.217 0 0 0 .083.167c.073.067.158.12.25.158l.233.125c.159.079.312.168.459.267a.917.917 0 0 1 .258.283.733.733 0 0 1 .092.375.834.834 0 0 1-.226.592Z" />
        <path d="M17.226 14.65a9.427 9.427 0 0 0 2.226-6.95 9.742 9.742 0 0 0-.516-2.333l-.6-1.75a2.05 2.05 0 0 0-2.85-1.117 2.025 2.025 0 0 0-1.117 2.092l.042.333a4 4 0 0 1-.492 2.5l-.309.525-1.058 1.217h-.05a26.944 26.944 0 0 1-2.5 2.708 30.94 30.94 0 0 1-2.5-2.708L6.394 7.9l-.309-.525a4 4 0 0 1-.49-2.5v-.333a2.033 2.033 0 0 0-2.742-2.125 2.059 2.059 0 0 0-1.184 1.2l-.625 1.75A9.743 9.743 0 0 0 .527 7.7a9.425 9.425 0 0 0 2.224 6.95l1.416 1.608a.234.234 0 0 1 .05.15v.467h-.533a.617.617 0 0 0-.625.625.583.583 0 0 0 .15.4.592.592 0 0 0 .475.225h12.633a.592.592 0 0 0 .475-.225.583.583 0 0 0 .15-.4.616.616 0 0 0-.616-.625h-.492v-.467a.234.234 0 0 1 .05-.15l1.342-1.608Zm-2.317.833a1.5 1.5 0 0 0-.35 1.067v.358H5.392v-.358a1.5 1.5 0 0 0-.35-1.067l-1.316-1.641a8.157 8.157 0 0 1-1.924-6.034 8.01 8.01 0 0 1 .442-1.975l.625-1.75a.833.833 0 0 1 .467-.508.725.725 0 0 1 .642 0 .833.833 0 0 1 .441.833l-.05.334A5.25 5.25 0 0 0 4.694 7.3a1.884 1.884 0 0 0-.992.483 2.009 2.009 0 0 0-.067 2.742l2.283 2.683c.032.04.05.09.05.142v.683a.624.624 0 0 0 1.25.033v-.674a1.433 1.433 0 0 0-.35-.992L4.485 9.608a.658.658 0 1 1 1-.833l1.183 1.392c.25.308.517.616.783.916l.092.109c.6.65 1.175 1.233 1.55 1.608.154.155.24.365.241.583v1.775a.625.625 0 1 0 1.25 0v-1.766a.834.834 0 0 1 .25-.592 27.466 27.466 0 0 0 2.634-2.85l1.025-1.2a.667.667 0 0 1 .934-.075.657.657 0 0 1 .066.933L13.11 12.4a1.433 1.433 0 0 0-.35.992v.675a.624.624 0 0 0 1.1.383.608.608 0 0 0 .15-.417v-.683c0-.051.017-.101.05-.142l2.3-2.683a2.009 2.009 0 0 0-.066-2.742 1.883 1.883 0 0 0-.992-.483 5.25 5.25 0 0 0 .325-2.558l-.05-.334a.833.833 0 0 1 .442-.833.725.725 0 0 1 .642 0 .832.832 0 0 1 .45.467l.65 1.791c.233.65.381 1.329.441 2.017a8.158 8.158 0 0 1-1.926 6.033l-1.367 1.6Z" />
      </g>
      <defs>
        <clipPath id="Earn_svg__a">
          <path fill="#fff" transform="translate(.003) skewX(-.01)" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgEarn
