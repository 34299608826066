import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import { Notification } from '~/components/Notification'
import SessionUtils from '~/utils/SessionUtils'

const axiosClient = applyCaseMiddleware(
  axios.create({
    baseURL: '/',
  })
)

axiosClient.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response?.status) {
      handleResponseError(err.response.status)
    } else {
      // TODO Kerk(fazzbiz-rollbar): Capture error in rollbar in separate ticket
    }
    throw err
  }
)

const handleResponseError = (status: number) => {
  if (status === 401) {
    SessionUtils.ssoLogout()
    return
  }
  const errorMessages: { [key: string]: { message: string; description: string } } = {
    '403': {
      message: 'Error 403: Forbidden',
      description: 'An error occurred when we tried to process your request.',
    },
    '413': {
      message: 'Error 413: Payload Too Large',
      description:
        'The file that you tried to upload is too large. Please try again with a smaller file.',
    },
    '500': {
      message: 'Error 500: Internal Server Error',
      description:
        'The server encountered an unexpected condition which prevented it from fulfilling the request. Please contact our support team if this problem persists.',
    },
    '504': {
      message: 'Error 504: Gateway Timeout',
      description:
        'The server encountered an unexpected condition which prevented it from fulfilling the request. Please contact our support team if this problem persists.',
    },
  }
  const { message = '', description = '' } = errorMessages[status.toString()] || {}
  Notification.error({
    message,
    description,
  })
}

export const { get, post, put, patch, delete: del } = axiosClient
