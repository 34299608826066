import { CustomerCardTabKey } from '~/sharedModules/DebitCards/CustomerCards/type'

export enum CustomerCardActions {
  SET_CUSTOMER_CARDS_CONFIG = 'CustomerCardActions.SET_CUSTOMER_CARD_CONFIG',
  SET_ACTIVE_TAB = 'CustomerCardActions.SET_ACTIVE_TAB',
}

type SetCustomerCardConfig = {
  type: typeof CustomerCardActions.SET_CUSTOMER_CARDS_CONFIG
  isCustomerCardAdminPanelEnabled: boolean
}

type SetActiveTab = {
  type: typeof CustomerCardActions.SET_ACTIVE_TAB
  cardActiveTab: string
}

export type ActionTypes = SetCustomerCardConfig | SetActiveTab

export type CustomerCardTabKeyType = typeof CustomerCardTabKey[keyof typeof CustomerCardTabKey]
