import { getApiMethods } from '~/api'
import type ENDPOINTS from '~/api/paths'
import { AppType } from '~/types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ResponsePromise = Promise<any>

function get(
  endpoint: ENDPOINTS,
  subRoute = '',
  queryStringParams?: object,
  options?: { signal?: AbortSignal },
  additionalHeaders?: object
): ResponsePromise {
  return getApiMethods(AppType.SINGAPORE).get(
    endpoint,
    subRoute,
    queryStringParams,
    options,
    additionalHeaders
  )
}

function post(
  endpoint: ENDPOINTS,
  requestBody: object,
  additionalHeaders: object = {},
  subRoute = '',
  options?: { signal?: AbortSignal }
): ResponsePromise {
  return getApiMethods(AppType.SINGAPORE).post(
    endpoint,
    requestBody,
    additionalHeaders,
    subRoute,
    options
  )
}

function del(
  endpoint: ENDPOINTS,
  additionalHeaders: object = {},
  subRoute = '',
  options?: { signal?: AbortSignal }
): ResponsePromise {
  return getApiMethods(AppType.SINGAPORE).del(endpoint, additionalHeaders, subRoute, options)
}

function put(
  endpoint: ENDPOINTS,
  requestBody: object,
  additionalHeaders: object = {},
  subRoute = '',
  options?: { signal?: AbortSignal }
): ResponsePromise {
  return getApiMethods(AppType.SINGAPORE).put(
    endpoint,
    requestBody,
    additionalHeaders,
    subRoute,
    options
  )
}

export { get, post, del, put }
