import { SVGProps } from 'react'

export default function SvgFingerPrint(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 42 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.62 6.198c-.31 0-.516-.104-.826-.207 0 0-4.958-2.892-13.635-2.892-8.574 0-12.499 2.789-12.499 2.789-.723.516-1.653.31-2.17-.31-.516-.723-.412-1.653.31-2.17C6.008 3.306 10.45 0 20.16 0c9.607 0 15.081 3.202 15.288 3.305.723.414.93 1.343.516 2.17-.31.413-.826.723-1.343.723ZM14.995 45.967c-.413 0-.826-.206-1.136-.516-.207-.31-5.785-6.715-5.785-14.978 0-5.268 2.583-11.466 9.71-13.429 3.202-.826 6.198-.516 8.78 1.033C31.832 21.176 33.59 28.2 33.59 28.51c.206.826-.31 1.653-1.137 1.86-.826.206-1.652-.31-1.86-1.137 0-.103-1.549-5.991-5.68-8.47-1.86-1.137-3.926-1.343-6.405-.723-6.817 1.756-7.334 8.367-7.334 10.33 0 7.024 4.958 12.911 5.062 12.911.516.62.516 1.653-.104 2.17-.413.413-.826.516-1.136.516Z"
        fill="#121212"
      />
      <path
        d="M27.39 47h-.31c-9.297-2.273-13.636-10.846-13.636-16.011 0-5.681 2.79-8.78 7.954-8.78 1.86 0 5.372 1.343 6.508 6.404.62 2.582 1.653 4.958 5.165 4.958 1.24 0 2.272-.413 2.892-1.343 1.24-1.652 1.24-4.235.723-5.99v-.104c-.103-.517-2.789-12.19-16.527-12.19-5.268 0-9.297 1.963-11.88 5.786-3.718 5.37-3.408 12.808-1.652 17.457.31.826-.103 1.652-.93 1.962-.826.31-1.653-.103-1.962-.93-2.066-5.37-2.376-13.945 1.962-20.349 2.17-3.202 6.508-7.127 14.462-7.127 15.804 0 19.316 13.428 19.626 14.565.723 2.582.62 6.197-1.24 8.676-1.239 1.653-3.098 2.583-5.37 2.583-4.34 0-7.128-2.48-8.265-7.438-.826-3.925-3.305-3.925-3.512-3.925-2.272 0-4.855.62-4.855 5.681 0 3.616 3.306 11.053 11.156 12.913.827.206 1.343 1.033 1.137 1.859-.104.826-.723 1.343-1.446 1.343Z"
        fill="#121212"
      />
      <path
        d="M33.382 42.352c-1.86 0-6.405-.414-9.607-3.616-3.925-3.925-4.545-8.47-4.545-8.677-.103-.826.517-1.652 1.343-1.756.826-.103 1.653.517 1.756 1.343 0 0 .516 3.719 3.719 6.818 2.995 2.995 8.057 2.685 8.057 2.685.826-.103 1.653.517 1.653 1.447.103.826-.517 1.652-1.447 1.652-.103.104-.413.104-.93.104ZM39.786 18.077c-.517 0-1.033-.31-1.343-.723-.207-.413-5.682-9.09-18.284-9.09-12.602 0-17.044 8.574-17.25 8.987-.414.723-1.343 1.033-2.066.723C.12 17.56-.19 16.63.12 15.908.43 15.39 5.594 5.165 20.16 5.165c14.46 0 20.659 10.123 20.968 10.536.414.723.207 1.653-.516 2.17-.31.103-.517.206-.826.206Z"
        fill="#0C45E1"
      />
    </svg>
  )
}
