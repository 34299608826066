// This wrapper is created because sharedModules need a way to call APIs
// To the right servers respectively.
// Which server to call is determined by the AppType itself.
// This will be deprecated when all frontend APIs are redirected to Fazzbiz Backend
import { makeRequest } from '~/api'
import ENDPOINTS from '~/api/paths'
import { AppType } from '~/types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ResponsePromise = Promise<any>

export default function getApiMethods(appType: AppType) {
  function get(
    endpoint: ENDPOINTS,
    subRoute = '',
    queryStringParams?: object,
    options?: { signal?: AbortSignal },
    additionalHeaders?: object
  ): ResponsePromise {
    return makeRequest(
      endpoint + subRoute,
      'get',
      appType,
      additionalHeaders,
      {},
      queryStringParams,
      options
    )
  }

  function post(
    endpoint: ENDPOINTS,
    requestBody: object,
    additionalHeaders: object = {},
    subRoute = '',
    options?: { signal?: AbortSignal }
  ): ResponsePromise {
    return makeRequest(
      endpoint + subRoute,
      'post',
      appType,
      additionalHeaders,
      requestBody,
      options
    )
  }

  function del(
    endpoint: ENDPOINTS,
    additionalHeaders: object = {},
    subRoute = '',
    options?: { signal?: AbortSignal }
  ): ResponsePromise {
    return makeRequest(endpoint + subRoute, 'delete', appType, additionalHeaders, {}, options)
  }

  function put(
    endpoint: ENDPOINTS,
    requestBody: object,
    additionalHeaders: object = {},
    subRoute = '',
    options?: { signal?: AbortSignal }
  ): ResponsePromise {
    return makeRequest(endpoint + subRoute, 'put', appType, additionalHeaders, requestBody, options)
  }

  return { get, post, del, put }
}
