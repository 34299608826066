import * as React from 'react'

function SvgRightSlider(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 37 37" fill="none" {...props}>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7823 11.3818C14.4503 11.7137 14.4503 12.2519 14.7823 12.5839L20.6979 18.4995L14.7823 24.4151C14.4503 24.7471 14.4503 25.2852 14.7823 25.6172C15.1142 25.9491 15.6524 25.9491 15.9844 25.6172L22.1003 19.5012C22.6536 18.948 22.6536 18.051 22.1003 17.4977L15.9844 11.3818C15.6524 11.0498 15.1142 11.0498 14.7823 11.3818Z" fill="#1B2736"/>
    </svg>
  )
}

export default SvgRightSlider
