import type { Reducer } from 'redux'
import type { ActionTypes } from './types'
import { OnboardingFlowActions } from './types'

const initialState = {
  isProductTourShowing: false,
  stepIndex: 0,
  isProductTourActive: false,
  productTourId: '',
}

type State = {
  isProductTourShowing: boolean
  isProductTourActive: boolean
  productTourId: string
  stepIndex: number
}

export const onboardingFlow: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case OnboardingFlowActions.SET_PRODUCT_TOUR_VISIBILITY: {
      return {
        ...state,
        isProductTourShowing: action.isProductTourShowing,
      }
    }
    case OnboardingFlowActions.SET_PRODUCT_TOUR_ID: {
      return {
        ...state,
        productTourId: action.productTourId,
      }
    }
    case OnboardingFlowActions.SET_PRODUCT_TOUR_ACTIVE_STATUS: {
      return {
        ...state,
        isProductTourActive: action.isProductTourActive,
      }
    }
    case OnboardingFlowActions.SET_PRODUCT_TOUR_STEP_INDEX: {
      return {
        ...state,
        stepIndex: action.stepIndex,
      }
    }
    default:
      return state
  }
}
