import { useState, useEffect } from 'react'
import { Breakpoints } from '../helpers/constants'

interface ResponsiveViews {
  isMobileView: boolean
  isTabletView: boolean
}
export function useResponsive() {
  useEffect(() => {
    function handleResize() {
      setCurrentWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const [currentWidth, setCurrentWidth] = useState<number | undefined>(undefined)

  const responsiveView: ResponsiveViews = {
    isMobileView: false,
    isTabletView: false,
  }

  const desktopBreakpointWidth = Breakpoints.DESKTOP_WIDTH
  const tabletBreakpointWidth = Breakpoints.TABLET_WIDTH

  if (!desktopBreakpointWidth || currentWidth === undefined) {
    return { ...responsiveView, isMobileView: false }
  }

  const isMobileView = currentWidth < desktopBreakpointWidth

  const isTabletView = isMobileView && currentWidth >= tabletBreakpointWidth

  return { ...responsiveView, isMobileView, isTabletView }
}
