import type { FixMeLater } from '~/types'

export enum USDLoanAccountAgreementActions {
  GET_AGREEMENT = 'USD_LOAN_ACCOUNT_GET_AGREEMENT',
  SHOW_AGREEMENT_MODAL = 'USD_LOAN_ACCOUNT_SHOW_AGREEMENT_MODAL',
}

type GetAgreement = {
  type: typeof USDLoanAccountAgreementActions.GET_AGREEMENT
  agreement: FixMeLater
}

type ShowAgreementModal = {
  type: typeof USDLoanAccountAgreementActions.SHOW_AGREEMENT_MODAL
  show: boolean
}

export type ActionTypes = GetAgreement | ShowAgreementModal
