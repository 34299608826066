import { useResponsive } from 'fds3-src/hooks'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import 'overlayscrollbars/overlayscrollbars.css'
import type { OverlayScrollbarProps } from './OverlayScrollbar.types'

export function OverlayScrollbar({
  children,
  className,
  style,
}: // DO NOT USE SPREAD OPERATOR i.e. {...props}
OverlayScrollbarProps) {
  const { isMobileView } = useResponsive()

  return (
    <OverlayScrollbarsComponent
      defer
      element="div"
      style={style}
      className={className}
      options={{
        scrollbars: {
          autoHideSuspend: true,
          autoHide: isMobileView ? 'never' : 'scroll',
          theme: 'os-theme-dark',
        },
        paddingAbsolute: false,
      }}
    >
      {children}
    </OverlayScrollbarsComponent>
  )
}

export default OverlayScrollbar
