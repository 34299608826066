import type { Reducer } from 'redux'
import type { AccountDetails, ActionTypes } from './types'
import { EarnAccountDetailsActions } from './types'

const initialState = {
  isLoading: false,
  accountDetails: {
    accountBalance: {
      currentAccountBalance: '0',
      remainingDeposit: '0',
      depositLimit: '100000',
      availableBalance: '0',
    },
    interestEarned: {
      totalInterestEarned: '0',
      fixedAPY: '2%',
    },
  },
}

type State = {
  isLoading: boolean
  accountDetails: AccountDetails
}

export const earnAccountDetails: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case EarnAccountDetailsActions.GET_ACCOUNT_DETAILS: {
      return {
        ...state,
        accountDetails: action.accountDetails,
      }
    }

    case EarnAccountDetailsActions.SET_IS_ACCOUNT_DETAILS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }

    default:
      return state
  }
}
