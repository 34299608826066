import type { Dispatch, SetStateAction } from 'react'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Typography, fdsTheme, Dialog } from '@fazz/design-system'
import { useHistory } from '~/hooks/useReactRouter'
import { toogleProductTourActive } from '~/biz/store/onboardingFlow/actions'
import type { RootState } from '~/biz/store/types'
import { putUpdateProductTour } from '~/api/onboardingFlow/onboarding.api'
import productTourWelcomeImage from '~/assets/product-tour-welcome.png'
import { stepsOption } from '.'

const { spacing } = fdsTheme
const { Label, Body } = Typography

type Props = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

function ProductTourStartModal({ open, setOpen }: Props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { productTourId } = useSelector((state: RootState) => state.onboardingFlow)

  const updateProductTourStatus = useMutation(
    () =>
      putUpdateProductTour({
        id: productTourId,
        payload: {
          skipProductTour: false,
          currentStep: 'main_dashboard_introduction',
          nextStep: 'idr_cash_account',
        },
      }),
    {
      onSuccess() {
        setOpen(false)
        history.push(stepsOption[0].path)
        dispatch(toogleProductTourActive(true))
      },
    }
  )
  return (
    <CustomDialog
      isClosable={false}
      open={open}
      onOkClick={() => updateProductTourStatus.mutate()}
      title=""
      footerButton="single"
      okText="Pelajari Sekarang"
    >
      <Container>
        <img width="100%" src={productTourWelcomeImage} alt="product tour welcome" />
        <TextContainer>
          <Label>Selamat Datang di Fazz Business!</Label>
          <Body size="md">
            Kami menyediakan berbagai macam layanan finansial mulai dari penerimaan pembayaran,
            transfer dana hingga pinjaman modal usaha. Panduan ini membantu Anda untuk memahami
            bagaimana Fazz Business dapat mempermudah bisnis Anda
          </Body>
        </TextContainer>
      </Container>
    </CustomDialog>
  )
}

const CustomDialog = styled(Dialog)`
  width: 600px !important;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const TextContainer = styled.div`
  text-align: center;
  margin-bottom: ${spacing.sm};
`

export default ProductTourStartModal
