import { SVGProps } from 'react'

function CloseCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9999 1.18994C9.86193 1.18994 7.77193 1.82394 5.99423 3.01175C4.21654 4.19957 2.83099 5.88786 2.01281 7.86313C1.19463 9.8384 0.980551 12.0119 1.39766 14.1089C1.81476 16.2058 2.84432 18.132 4.35612 19.6438C5.86793 21.1556 7.79409 22.1851 9.89102 22.6022C11.988 23.0193 14.1615 22.8053 16.1368 21.9871C18.112 21.1689 19.8003 19.7833 20.9881 18.0057C22.176 16.228 22.8099 14.138 22.8099 11.9999C22.8099 9.13295 21.671 6.38339 19.6438 4.35612C17.6165 2.32885 14.8669 1.18994 11.9999 1.18994Z"
        fill="currentColor"
      />
      <path
        d="M11.9999 2.68994C13.8413 2.68994 15.6413 3.23596 17.1723 4.25896C18.7033 5.28196 19.8966 6.73598 20.6013 8.43716C21.3059 10.1383 21.4903 12.0103 21.1311 13.8162C20.7718 15.6222 19.8851 17.2811 18.5831 18.5831C17.2811 19.8851 15.6222 20.7718 13.8162 21.1311C12.0103 21.4903 10.1383 21.3059 8.43716 20.6013C6.73598 19.8966 5.28196 18.7033 4.25896 17.1723C3.23596 15.6413 2.68994 13.8413 2.68994 11.9999C2.69523 9.5324 3.67779 7.16743 5.42261 5.42261C7.16743 3.67779 9.5324 2.69523 11.9999 2.68994Z"
        fill="currentColor"
      />
      <path
        d="M13.0598 11.9998L15.2798 9.77985C15.4123 9.63767 15.4845 9.44963 15.481 9.25532C15.4776 9.06102 15.3989 8.87564 15.2615 8.73823C15.1241 8.60081 14.9387 8.5221 14.7444 8.51867C14.5501 8.51524 14.362 8.58737 14.2198 8.71985L11.9998 10.9398L9.77985 8.71985C9.63767 8.58737 9.44963 8.51524 9.25532 8.51867C9.06102 8.5221 8.87564 8.60081 8.73823 8.73823C8.60081 8.87564 8.5221 9.06102 8.51867 9.25532C8.51524 9.44963 8.58737 9.63767 8.71985 9.77985L10.9398 11.9998L8.71985 14.2198C8.58737 14.362 8.51524 14.5501 8.51867 14.7444C8.5221 14.9387 8.60081 15.1241 8.73823 15.2615C8.87564 15.3989 9.06102 15.4776 9.25532 15.481C9.44963 15.4845 9.63767 15.4123 9.77985 15.2798L11.9998 13.0598L14.2198 15.2798C14.362 15.4123 14.5501 15.4845 14.7444 15.481C14.9387 15.4776 15.1241 15.3989 15.2615 15.2615C15.3989 15.1241 15.4776 14.9387 15.481 14.7444C15.4845 14.5501 15.4123 14.362 15.2798 14.2198L13.0598 11.9998Z"
        fill="white"
      />
    </svg>
  )
}

export default CloseCircle
