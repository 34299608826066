import { SVGProps } from 'react'

function SvgCheckedFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m15.127 5.542-4.305-4.305A1.5 1.5 0 0 0 9.75.795H6.937a4.5 4.5 0 0 0-4.5 4.5v7.41a4.5 4.5 0 0 0 4.5 4.5h4.125a4.5 4.5 0 0 0 4.5-4.5V6.6a1.5 1.5 0 0 0-.434-1.058Zm-3.832 3.39L8.94 11.287a.563.563 0 0 1-.397.165.593.593 0 0 1-.398-.165L6.75 9.886a.563.563 0 0 1 .795-.795l.998 1.005L10.5 8.137a.562.562 0 0 1 .919.182.555.555 0 0 1-.124.613Zm1.957-3a2.625 2.625 0 0 1-2.625-2.625V2.64l3.263 3.262-.638.03Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCheckedFilled
