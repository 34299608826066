import * as React from 'react'

function SvgBitgo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#bitgo_svg__clip0)">
        <path
          d="M18.154 33.6s13.354-4.534 13.354-21.21V6.391c0-.648-.502-1.191-1.15-1.233-2.424-.105-8.17-.606-12.204-2.759C14.121 4.553 8.374 5.054 5.95 5.16A1.234 1.234 0 004.8 6.392v5.997C4.8 29.066 18.155 33.6 18.155 33.6z"
          fill="#009FE8"
        />
        <path
          d="M23.547 14.583c-.167-1.818-1.713-2.445-3.699-2.633V9.4h-1.546v2.467h-1.254V9.38h-1.546l.02 2.528c-.334 0-3.092.021-3.092.021v1.61h1.964v8.129H12.66l-.021 1.86s2.445-.021 2.8 0v2.549h1.525v-2.529c.418.021.836.021 1.233.021v2.508h1.547v-2.55c2.591-.125 4.388-.773 4.64-3.197.187-1.943-.711-2.842-2.174-3.197.898-.418 1.463-1.212 1.337-2.55zm-2.194 5.434c-.021 1.901-3.26 1.672-4.284 1.672v-3.386c1.024 0 4.305-.271 4.284 1.714zm-.69-4.765c-.02 1.735-2.716 1.526-3.573 1.505v-3.072c.857 0 3.594-.251 3.573 1.567z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="bitgo_svg__clip0">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgBitgo
