import { SVGProps } from 'react'

function SvgKey(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.57 2.877a4.5 4.5 0 0 0-7.133 5.25L1.5 15.087a1.313 1.313 0 1 0 1.86 1.852l.39-.352.465.465a1.313 1.313 0 0 0 1.882-1.83l.113-.113a1.312 1.312 0 0 0 1.8-1.86l-.473-.465 2.76-2.76a4.38 4.38 0 0 0 2.115.533 4.433 4.433 0 0 0 3.158-1.313 4.499 4.499 0 0 0 0-6.367Zm-2.175 4.17a1.425 1.425 0 0 1-2.003 0 1.41 1.41 0 0 1 1.005-2.408 1.395 1.395 0 0 1 .998.413 1.412 1.412 0 0 1 0 1.995Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgKey
