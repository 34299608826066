import styled from '@emotion/styled/macro'
import { DESKTOP_BREAKPOINT, fdsTheme } from '@fazz/design-system'

type Props = {
  children?: React.ReactNode
  backgroundColor?: string
}

const { colors, spacing } = fdsTheme

export default function UserDashboardLayout({ children, backgroundColor }: Props) {
  return <PageContainer backgroundColor={backgroundColor}>{children}</PageContainer>
}

const PageContainer = styled.div<{ backgroundColor?: string }>`
  background: ${({ backgroundColor }) => backgroundColor || colors.backgroundSecondaryNeutral};
  flex-grow: 1;
  padding-top: ${spacing.md};

  ${DESKTOP_BREAKPOINT} {
    padding-top: 0;
  }
`
