import type { MenuItem } from '@fazz/design-system/src/components/atoms/Menu/Menu.types'
import { PATHS as SgPaths } from '~/biz/routes/paths'
import { PATHS as IdPaths } from '~/bizRegional/routes/paths'
import { SupportLinks } from '~/common/commonLinks'
import { commonSidebarLabels } from '~/constants/sidebarFeaturesLabel.config'
import { getMenuItemIcon, renderSidebarLink, showBadge } from '../helpers/uiHelpers'

export const getFooterMenuItems = (isSg: boolean): MenuItem[] => {
  const paths = isSg ? SgPaths : IdPaths
  const countrySupportLink = isSg ? SupportLinks.SupportSG : SupportLinks.SupportID

  return [
    {
      key: '/',
      label: commonSidebarLabels.Settings,
      subItems: [
        {
          key: paths.TEAM,
          label: commonSidebarLabels.Team,
          render() {
            return renderSidebarLink({ externalLink: false, label: this.label, link: this.key })
          },
        },
        {
          key: paths.MANAGE_ACCOUNT,
          label: commonSidebarLabels.MyAccount,
          render() {
            return renderSidebarLink({ externalLink: false, label: this.label, link: this.key })
          },
        },
      ],
    },
    {
      key: countrySupportLink,
      label: commonSidebarLabels.FAQ,
      render() {
        return renderSidebarLink({ externalLink: true, label: this.label, link: this.key })
      },
    },
  ].map((item) => ({
    ...item,
    icon: getMenuItemIcon(item.label) || undefined,
    showBadge: showBadge(item.label),
  }))
}
