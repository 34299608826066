/**
 * Example Usage:
 *  function Main() {
 *    const handleTimeUp = () => {
 *     // Handle the timer reaching -1 (e.g., show a message, trigger an action).
 *      console.log("Time's up!");
 *    }
 *
 *    const { counter, startTimer, pauseTimer, resetTimer } = useCountdown(20, () => handleTimeUp())
 *
 *    return (
 *      <div>
 *        <div>Counter: {counter}</div>
 *        <button onClick={() => startTimer(20)}>Start Timer</button>
 *        <button onClick={() => pauseTimer()}>Stop Timer</button>
 *        <button onClick={() => resetTimer(10)}>Reset Timer</button>
 *      </div>
 *    )
 *  }
 */
import { useState, useEffect, useRef } from 'react'

export default function useCountdown<T extends () => void>(initialCount: number, handleTimeUp: T) {
  const [enabled, setEnabled] = useState(false)
  const [counter, setCounter] = useState(initialCount)
  const intervalRef = useRef<ReturnType<typeof setInterval>>()
  const handleTimeUpRef = useRef<T>(handleTimeUp)

  useEffect(() => {
    handleTimeUpRef.current = handleTimeUp
  }, [handleTimeUp])

  const clearIntervalRef = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
  }

  useEffect(() => {
    if (counter <= -1) {
      handleTimeUpRef.current()
      clearIntervalRef()
      setEnabled(false)
    }

    if (!enabled) {
      clearIntervalRef()
    }

    if (enabled) {
      intervalRef.current = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1)
      }, 1000)
    }

    return () => clearIntervalRef()
  }, [counter, enabled])

  const startTimer = () => setEnabled(true)
  const pauseTimer = () => setEnabled(false)
  const resetTimer = () => setCounter(initialCount)
  const restartTimer = () => {
    resetTimer()
    startTimer()
  }

  return {
    counter,
    enabled,
    startTimer,
    pauseTimer,
    resetTimer,
    restartTimer,
  }
}
