import { useState } from 'react'
import styled from '@emotion/styled/macro'
import {
  Title,
  Body,
  LabelText,
  Button,
  fazzbizTheme,
  DESKTOP_BREAKPOINT,
  FONT_WEIGHT,
  Notification,
} from '@fazz/design-system'
import { ArrowLeft } from '@fazz/design-system-icons'
import { FazzBusinessLogo } from '@fazz/design-system-logos'
import { useAppTypeContext } from '~/hooks/useAppTypeContext'
import { patchUserRegion } from '~/api/regionSelector.api'
import { AppType, BusinessRegistrationCountry } from '~/types'
import SessionUtils from '~/utils/SessionUtils'
import { countries } from './countries'
import type { Country } from './countries'

export default function RegionSelector() {
  const [selectedCountryCode, setSelectedCountryCode] = useState<Country['code']>()
  const { setAppType } = useAppTypeContext()

  return (
    <PageContainer>
      <CardContainer>
        <Backlink onClick={() => SessionUtils.ssoLogout()}>
          <ArrowLeft />
          <LabelBacklink>Back</LabelBacklink>
        </Backlink>
        <LogoContainer>
          <FazzBusinessLogo height={40} width={100} />
        </LogoContainer>
        <Title style={{ textAlign: 'center' }}>Select Your Business Region</Title>
        <StyledBodySubdued>
          Please select based on where your business is registered. It will affect the data
          requirement.
        </StyledBodySubdued>

        <CountrySelectorContainer>
          {countries.map((country, index) => (
            <StyleFlagContainer
              key={country.code}
              role="button"
              tabIndex={index + 1}
              onClick={() => setSelectedCountryCode(country.code)}
            >
              <StyledImage src={country.flag} alt={`flag for ${country.name}`} />
              <StyledLabelText>{country.name}</StyledLabelText>
            </StyleFlagContainer>
          ))}
        </CountrySelectorContainer>
        <ButtonContainer>
          <StyledButton
            onClick={async () => {
              try {
                await patchUserRegion(selectedCountryCode!)
                switch (selectedCountryCode) {
                  case BusinessRegistrationCountry.INDONESIA:
                    setAppType(AppType.INDONESIA)
                    break
                  case BusinessRegistrationCountry.SINGAPORE:
                    setAppType(AppType.SINGAPORE)
                    break
                  default:
                    break
                }
              } catch (err: unknown) {
                setSelectedCountryCode(undefined)

                Notification({
                  message: 'Failed to set region. Please contact customer support.',
                  type: 'error',
                })
              }
            }}
            isDisabled={!selectedCountryCode}
          >
            Proceed
          </StyledButton>
        </ButtonContainer>
      </CardContainer>
    </PageContainer>
  )
}

const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${fazzbizTheme.spacing.xs};
`

const CountrySelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${fazzbizTheme.spacing.md};
  width: 100%;
  align-items: center;

  ${DESKTOP_BREAKPOINT} {
    flex-wrap: none;
    flex-direction: row;
    justify-content: center;
    margin-top: ${fazzbizTheme.spacing.md};
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const StyledButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${DESKTOP_BREAKPOINT} {
    width: auto;
  }
`
const StyledImage = styled.img`
  width: 84px;
  ${DESKTOP_BREAKPOINT} {
    width: 136px;
  }
`
const Backlink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  place-self: flex-start;
  padding: 12px 0px;
  gap: ${fazzbizTheme.spacing.xs};
  color: ${fazzbizTheme.base.primary};
  font-weight: ${FONT_WEIGHT.BOLD};

  &:hover {
    cursor: pointer;
  }
`

const LabelBacklink = styled(LabelText)`
  color: ${fazzbizTheme.base.primary};
`

const StyledBodySubdued = styled(Body)`
  text-align: center;
  color: ${fazzbizTheme.base.neutralSecondary};
`

const StyledLabelText = styled(LabelText)`
  text-align: center;
`

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${fazzbizTheme.spacing.md};
  min-height: 100vh;
  background-color: ${fazzbizTheme.neutral.background};
  width: 100%;
  overflow: hidden;

  ${DESKTOP_BREAKPOINT} {
    align-items: flex-start;
  }
`
const CardContainer = styled.div`
  background-color: ${fazzbizTheme.card.background.default};
  border-radius: 16px;
  max-width: 650px;
  height: fit-content;
  width: 100%;
  padding: ${fazzbizTheme.spacing.md};
  gap: ${fazzbizTheme.spacing.md};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${DESKTOP_BREAKPOINT} {
    margin-top: ${fazzbizTheme.spacing.lg};
    padding: ${fazzbizTheme.spacing.sm} ${fazzbizTheme.spacing.lg} ${fazzbizTheme.spacing.lg};
  }
`
const StyleFlagContainer = styled.div`
  display: flex;
  gap: ${fazzbizTheme.spacing.md};
  align-items: center;
  width: 100%;
  cursor: pointer;
  border-radius: 16px;
  padding: ${fazzbizTheme.spacing.sm};
  border: 1px solid ${fazzbizTheme.base.line};
  &:focus {
    background: ${fazzbizTheme.interactive.hoverOnNeutral};
    cursor: pointer;
    border: 1px solid ${fazzbizTheme.interactive.active};
  }

  &:hover {
    background: ${fazzbizTheme.interactive.hoverOnNeutral};
    cursor: pointer;
    border: 1px solid ${fazzbizTheme.interactive.active};
  }

  ${DESKTOP_BREAKPOINT} {
    flex-direction: column;
    width: auto;
  }
`
