/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
class MocksManagerClass {
  public toggleMode(mockType: string) {
    const nextMode = this.isMockMode(mockType) ? PERMISSIONS.ACTUAL : PERMISSIONS.MOCK

    sessionStorage.setItem(mockType, nextMode)
  }

  public isMockMode(mockType: string) {
    const currentMode = sessionStorage.getItem(mockType)
    return currentMode === PERMISSIONS.MOCK
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setMocks(mockName: string, mocks: any) {
    sessionStorage.setItem(mockName, JSON.stringify(mocks))
  }

  public getMocks(mockName: string) {
    const mocks = sessionStorage.getItem(mockName)
    return mocks && JSON.parse(mocks)
  }
}

enum PERMISSIONS {
  MOCK = 'mock_data',
  ACTUAL = 'actual_data',
}

export const MocksManager = new MocksManagerClass()
