import styled from '@emotion/styled'
import {
  EXTRA_SMALL_SPACING,
  SMALL_SPACING,
  MEDIUM_SPACING,
  LARGE_SPACING,
  EXTRA_LARGE_SPACING,
} from '../../../themes/deprecated/spacing'
import { fdsTheme } from '../../../themes/theme-fds3'

const SPACING_MAP = {
  xs: EXTRA_SMALL_SPACING,
  sm: SMALL_SPACING,
  md: MEDIUM_SPACING,
  lg: LARGE_SPACING,
  xl: EXTRA_LARGE_SPACING,
}

type DividerProps = {
  spacing?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const { colors } = fdsTheme

function Divider({ spacing, ...rest }: DividerProps) {
  const StyledDivider = styled.hr`
    border: none;
    height: 1px;
    margin-top: ${spacing ? SPACING_MAP[spacing] : 0};
    margin-bottom: ${spacing ? SPACING_MAP[spacing] : 0};
    flex-shrink: 0;
    background-color: ${colors.line};
  `
  return <StyledDivider {...rest} />
}
export default Divider
