import * as React from 'react'

function SvgPersonalTwoTone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 55 55" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.498 47h37.76c-1.09-8.832-9.642-14-18.88-14-9.239 0-17.79 5.168-18.88 14zm-4.002-.214C5.776 35.004 16.926 29 27.378 29c10.452 0 21.601 6.004 22.882 17.786C50.523 49.213 48.52 51 46.378 51h-38c-2.144 0-4.146-1.787-3.882-4.214z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5 22a7 7 0 100-14 7 7 0 000 14zm11-7c0 6.075-4.925 11-11 11s-11-4.925-11-11 4.925-11 11-11 11 4.925 11 11z"
        fill="#1B2736"
      />
    </svg>
  )
}

export default SvgPersonalTwoTone
