import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import type { BackendOptions } from 'i18next-http-backend'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { isLocal, isProduction } from '~/managers/ConfigManager'

let PUBLIC_PATH = '/'
if (!isProduction && !isLocal) {
  PUBLIC_PATH = 'https://dwlrc822bt02f.cloudfront.net/frontend/'
} else if (isProduction) {
  PUBLIC_PATH = 'https://d3r7nsslvs6aaf.cloudfront.net/frontend/'
}

export enum Language {
  ENGLISH = 'en',
  INDONESIAN = 'id',
}

const backendConfig: BackendOptions = {
  loadPath: `${PUBLIC_PATH}locales/{{lng}}/{{ns}}.json?hash=${process.env.REACT_APP_COMMIT}`,
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: [Language.ENGLISH, Language.INDONESIAN],
    fallbackLng: Language.ENGLISH,
    ns: [
      'landingPage',
      'feature/loan/applyLoan',
      'feature/loan/loan_account',
      'feature/payment/payoutForm',
      'feature/recipients/addRecipientForm',
      'feature/loans/flexpay/flexpayRepayment',
      'common/commonTranslations',
      'feature/recipients/recipientTable',
      'common/tableFilters',
      'common/tableHeaders',
      'common/tableTabs',
      'common/emptyTableState',
      'common/breadCrumbs',
      'common/modalTexts',
      'common/indoCallbackInputs',
      'feature/indoVerification/index',
      'feature/accountVerification/index',
      'feature/businessVerification/businessInformations',
      'common/formValidation',
      'feature/loan/loan_application',
      'common/selectableOptions',
    ],
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
    },
    backend: backendConfig,
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    },
  })
export default i18n
