import * as React from 'react'

function SvgCiti(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.612 14.242h-3.19v15.454h3.19V14.242zm2.886 0v2.88h2.735v8.711c0 1.213.228 2.198.912 2.956.684.68 1.52 1.06 2.583 1.135 1.14 0 2.05-.228 2.81-.757l.759-2.727v-.227l-.151.075c-.835.53-1.595.833-2.355.833-.987 0-1.443-.607-1.443-1.743V17.12h3.19v-2.879h-3.19V9.47l-3.115 1.591v3.182h-2.735zm15.346 0h-3.191v15.454h3.19V14.242zm-28.716-.227c-2.28 0-4.178.607-5.698 2.044C11.76 17.5 11 19.47 11 21.97c0 2.424.76 4.47 2.43 5.91 1.52 1.439 3.419 2.12 5.698 2.12 2.127 0 3.951-.909 5.47-2.65v-.076l-1.748-2.12-.075.075c-1.14 1.135-2.355 1.741-3.797 1.741-1.37 0-2.509-.454-3.42-1.438-.912-.91-1.367-2.046-1.367-3.562 0-1.439.455-2.652 1.367-3.56.912-.986 2.05-1.364 3.42-1.364 1.442 0 2.658.53 3.797 1.666l.075.076 1.747-2.12v-.076c-1.518-1.743-3.342-2.576-5.47-2.576z"
        fill="#1C4882"
      />
      <path
        d="M28.018 11.742c2.507-2.651 5.546-3.94 9.116-3.94 3.493 0 6.534 1.289 8.964 3.94h3.721l-.075-.228c-1.443-2.045-3.343-3.56-5.621-4.772C41.919 5.53 39.564 5 37.134 5c-2.507 0-4.863.53-7.065 1.742-2.28 1.212-4.103 2.727-5.547 4.772l-.15.228h3.646z"
        fill="#CF4037"
      />
    </svg>
  )
}

export default SvgCiti
