import { fdsTheme } from 'fds3-src/themes/theme-fds3'
import { Input, FormItem } from 'formik-antd'
import styled from 'styled-components'
import type { FormikTextAreaProps } from './FormikTextArea.types'

const { TextArea } = Input

const { radius, colors, spacing, fontFamily, fontStyles } = fdsTheme

export const StyledTextArea = styled(TextArea).attrs((props: FormikTextAreaProps) => ({
  resize: props.resize,
}))`
  &.ant-input,
  textarea.ant-input {
    font-family: ${fontFamily.fazzNeue};
    min-height: 162px;
    border: 1px solid ${colors.line};
    color: ${colors.onNeutral};
    border-radius: ${radius.lg};
    padding: 0px ${spacing.md};
    background: ${colors.surfaceNeutral};
    padding: ${spacing.md};
    padding-top: ${spacing.xs};
    resize: ${(props) => props.resize};

    ::-webkit-input-placeholder {
      ${fontStyles.bodyLarge};
      color: ${colors.placeholderInput};
    }
    &:focus {
      border: 1px solid ${colors.active};
      box-shadow: none;
    }
    &:disabled {
      background-color: ${colors.surfaceDisabledSurface};
    }
  }
`

export const StyledFormItem = styled(FormItem)`
  &.ant-form-item {
    margin-bottom: 0;
  }
  // error state + error message
  &.ant-form-item-has-error {
    .ant-typography {
      color: ${colors.critical};
    }

    .ant-input {
      border-color: ${colors.critical};
    }
    .ant-form-item-explain-error {
      margin-top: ${spacing.xs};
      margin-left: ${spacing.md};
      ${fontStyles.bodyMedium}
      color: ${colors.critical};
      text-align: left;
    }
  }

  // helper text
  .ant-form-item-extra {
    margin-top: ${spacing.xs};
    ${fontStyles.bodySmall}
    color: ${colors.helperInput};
    text-align: left;
  }

  // we want to hide if there is an error message - May be problematic if we want to show more errors in the future.
  .ant-form-item-control div:nth-of-type(3) {
    display: none;
  }
`
