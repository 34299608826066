import * as React from 'react'

function SvgHelpGuide(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 2h19A3.5 3.5 0 0128 5.5v19a3.5 3.5 0 01-3.5 3.5h-19A3.5 3.5 0 012 24.5v-19A3.5 3.5 0 015.5 2zm0 3a.5.5 0 00-.5.5v19a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-19a.5.5 0 00-.5-.5h-19z"
        fill="currentColor"
      />
      <path d="M21 11a2 2 0 11-4 0 2 2 0 014 0z" fill="#2F8DEE" />
    </svg>
  )
}

export default SvgHelpGuide
