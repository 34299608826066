import { css, cx } from 'emotion'
import type { RefObject } from 'react'
import React from 'react'
import type { SelectProps } from 'antd/es/select'
import AntSelect from 'antd/es/select'
import 'antd/es/select/style/css'
import AntTag from 'antd/es/tag'
import 'antd/es/tag/style/css'
import styled from '@emotion/styled'
import { Body } from '../../../themes/Typography'
import { MAIN_FONT } from '../../../themes/deprecated/TypographyStyles'
import { fdsTheme } from '../../../themes/theme-fds3'

const { spacing, colors, radius, shadow, fontStyles, fontSize, fontWeight, fontFamily } = fdsTheme

const styles = () => css`
  padding-bottom: ${spacing.sm} !important;
  border-radius: 0px 0px ${radius.lg} ${radius.lg};
  box-shadow: ${shadow.shadowOverlay} !important;
  color: ${colors.onNeutral} !important;
  font-family: ${fontFamily};
  font-size: ${fontSize.bodyMedium};
  font-weight: 400;

  .ant-select-item-option {
    padding: ${spacing.sm} ${spacing.md};
  }

  .ant-select-item-option-content {
    ${fontStyles.bodyLarge}
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 500;
    background-color: ${colors.surfaceNeutral};
    border-left: 2px solid ${colors.active};
  }
`

export type Props = {
  disabled?: boolean
  children?: React.ReactNode
  className?: string
  type: 'dropdown' | 'filter'
  placeholder?: string
  style?: Record<string, unknown>
  dropdownstyle?: Record<string, unknown>
  open?: boolean
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  mode?: 'multiple' | 'tags'
  label?: string
  dropdownAlign?: object // ref: https://github.com/yiminghe/dom-align
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectRef?: RefObject<any>
} & Omit<SelectProps<any>, 'disabled' | 'mode' | 'className'>

const Select = function CustomSelect({
  className = '',
  children,
  disabled = false,
  type,
  mode,
  label,
  selectRef,
  ...rest
}: Props) {
  return (
    <>
      {label && (
        <Body size="md" color={colors.onNeutralSecondary} style={{ marginBottom: spacing.xs }}>
          {label}
        </Body>
      )}
      <StyledSelect
        className={className}
        dropdownClassName={cx(styles())}
        disabled={disabled}
        mode={mode}
        ref={selectRef}
        {...rest}
      >
        {children}
      </StyledSelect>
    </>
  )
}

Select.Tag = function CustomSelectTag({ className = '', ...props }) {
  const tagStyle = `
    height: 28px;
    color: #FFFFFF;
    background: ${colors.active};
    border-radius: 23px;
    border: 0;
    display: inline-flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    padding-left: 10px;
    cursor: pointer;
    font-family: ${MAIN_FONT};
    padding-right: 10px;
    margin-right: 4px;

    &:hover {
      opacity: 1;
    }

    &.ant-tag .anticon-close {
      color: white;
      margin-left: 5px;
      margin-top: 0px;
    }
  `
  return <AntTag className={`${css(tagStyle)} ${className}`} {...props} />
}

Select.OptGroup = AntSelect.OptGroup
Select.Option = AntSelect.Option

/**
 * @deprecated - Use the new FDS3 version: SelectFds3.
 */
export default Select

const StyledSelect = styled(AntSelect)`
  width: 100%;

  &.ant-select .ant-select-selector {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 56px;
    white-space: nowrap;
    border: 1px solid ${colors.line};
    border-radius: ${radius.lg};
    padding: ${spacing.xs} ${spacing.md};

    &:hover {
      border-color: ${colors.active};
    }
  }

  .ant-select-selection-placeholder {
    ${fontStyles.bodyLarge}
    color: ${colors.placeholderInput};
    padding-right: ${spacing.md};
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item {
    ${fontStyles.bodyLarge}
    color: ${colors.onNeutral};
    display: flex;
    align-items: center;
  }

  &.ant-select-multiple .ant-select-selector .ant-select-selection-item {
    background-color: ${colors.backgroundNeutral};
    border: 1px solid ${colors.primary};
    border-radius: ${radius.lg};
    padding: 0 ${spacing.sm};
    height: 32px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    .ant-select-selection-item-content {
      ${fontStyles.bodyMedium}
      color: ${colors.primary};
      background-color: ${colors.backgroundNeutral};
      font-weight: ${fontWeight.bold};
    }
  }

  // dropdown arrow
  .ant-select-arrow {
    width: 18px;
    height: 18px;
    // FIXME: Temp solution for now, should find a more permanent solution in the future.
    right: 10px;
    top: 50%;
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: ${colors.active};
    border-right-width: 1px;
    box-shadow: none;
  }

  &.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 100%;
  }
`
