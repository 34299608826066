import { useResponsive } from 'fds3-src/hooks'
import { Layout } from 'antd'
import { StyledLayout } from './DashboardLayout.styles'
import type { DashboardLayoutProps } from './DashboardLayout.types'

const { ContentFooter, Footer, Header, Sider } = StyledLayout
const { Content } = Layout
export default function DashboardLayout({
  children,
  footer,
  header,
  sidebar,
}: DashboardLayoutProps) {
  const { isMobileView } = useResponsive()

  return (
    <Layout style={{ height: '100vh' }}>
      <Sider collapsed={isMobileView} collapsedWidth={0} width="fit-content">
        {sidebar}
      </Sider>
      <Layout>
        <Header>{header}</Header>
        <ContentFooter>
          <Content>{children}</Content>
          <Footer>{footer}</Footer>
        </ContentFooter>
      </Layout>
    </Layout>
  )
}
