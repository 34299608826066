import { SVGProps } from 'react'

function SvgPercent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m10.882 1.722-.602-.603a.114.114 0 0 0-.16 0l-9.002 8.999a.114.114 0 0 0 0 .16l.602.603a.114.114 0 0 0 .16 0l9-9a.111.111 0 0 0 .002-.16ZM3.045 5.09a2.048 2.048 0 0 0 2.046-2.046A2.048 2.048 0 0 0 3.045 1 2.048 2.048 0 0 0 1 3.045c0 1.128.918 2.046 2.045 2.046Zm0-3.068a1.024 1.024 0 0 1 0 2.045 1.024 1.024 0 0 1 0-2.045Zm5.91 4.886a2.048 2.048 0 0 0-2.046 2.046c0 1.127.918 2.045 2.046 2.045A2.048 2.048 0 0 0 11 8.955a2.048 2.048 0 0 0-2.045-2.046Zm0 3.068a1.024 1.024 0 0 1 0-2.045 1.024 1.024 0 0 1 0 2.045Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPercent
