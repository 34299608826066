/** @deprecated please use fazzBizzThemeFds3 colors theme instead */
export enum GREEN {
  S400 = '#189C08',
  S300 = '#43CC32',
  S100 = '#F0FFF3',

  DEFAULT = '#00A965',
  G100 = '#F0FFF9',
  HOVER = '#008f56',
}
