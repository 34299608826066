import styled from 'styled-components'
import Dragger from 'antd/lib/upload/Dragger'
import { fdsTheme } from '../../../themes/theme-fds3'

// Retrieve all theming data
const { colors, radius, spacing } = fdsTheme

export const StyledUploadBox = styled(Dragger)`
  ${() =>
    `
  &.ant-upload-drag {
    display: block;
    flex-direction: column;
    border: 1px solid ${colors.line};
    border-radius: ${radius.md};
    text-align: left;
    background-color: ${colors.onContainer};
    min-width: 328px;
    .ant-upload-btn {
      padding: 0px;
    }
  }
  `}
`

export const UploadBoxContainer = styled.div`
  ${() =>
    `
    padding: ${spacing.md};
  `}
`

export const UploadInfo = styled.div`
  ${() =>
    `
    display: flex;
    margin-top: ${spacing.md};
    align-items: center;
    justify-content: space-between;
  `}
`

export const UploadedFileList = styled.div`
  ${() =>
    `
    display: flex;
    justify-content: space-between;
    padding: ${spacing.md};
    background-color: ${colors.backgroundSecondaryNeutral};
    border-bottom-left-radius: ${radius.md};
    border-bottom-right-radius: ${radius.md};
  `}
`
