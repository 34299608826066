import * as React from 'react'

function SvgLiquid(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#liquid_svg__clip0)" fill="#0290FF">
        <path d="M22.682 25.336h-9.587c-1.38 0-1.962-.578-1.962-1.94V13.022c0-1.227-.618-1.847-1.84-1.847-1.79 0-3.487-.013-5.275-.013-1.196 0-1.62.66-1.62 1.732v19.05c0 1.31 1.064 1.656 1.881 1.656 5.557 0 10.774-.006 16.334-.006.93 0 1.86.008 2.791 0 1.065-.012 1.36-.314 1.362-1.355v-4.864c-.003-1.534-.522-2.04-2.084-2.04zM33.597 3.628c0-.955-.278-1.226-1.235-1.228H12.469c-.946 0-1.266.288-1.283 1.213a91.874 91.874 0 000 3.648c.03 1.241.649 1.828 1.884 1.84 1.001.009 2.004-.014 3.005.006 1.228.022 1.785.606 1.785 1.825v5.294c0 1.346.613 1.946 1.977 1.948h4.58c1.15 0 1.753.604 1.767 1.77.011.978 0 1.956 0 2.934.011 1.218.647 1.87 1.84 1.88 1.431.01 2.862.01 4.293 0 .944 0 1.265-.347 1.265-1.31.013-6.607.018-13.213.015-19.82z" />
      </g>
      <defs>
        <clipPath id="liquid_svg__clip0">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgLiquid
