import { css } from 'emotion'
import { useCallback } from 'react'
import type { Moment } from 'moment'
import DatePicker from 'antd/es/date-picker'
import 'antd/es/date-picker/style/css'
import { Body } from '../../../themes/Typography'
import { fdsTheme } from '../../../themes/theme-fds3'

type Props = {
  placeholder?: string
  format?: string
  style?: Record<string, unknown>
  disabled?: boolean
  showToday?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disabledDate?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any
  label?: string
  required?: boolean
  showTime?: { format: string }
  minuteStep?: number
  disabledTime?: (date: Moment) => void
}

const { colors, radius, fontStyles, spacing } = fdsTheme

export default function DatePickerComp({
  disabled = false,
  label,
  required = false,
  ...rest
}: Props) {
  const style = `
    -webkit-font-smoothing: antialiased;
    height: 44px;
    width: 100%;

    ${
      disabled
        ? `
      pointer-events: none;
      `
        : ''
    }

    &.ant-picker {
      border-radius: ${radius.lg};
      min-height: 56px;
      border-color: #E8E8E8;
      padding-left: 15px;
      transition: 0.3s all ease;

      &:hover {
        border-color: ${colors.active};
      }

      &.ant-picker-focused {
        border-color: ${colors.active};
      }
    }

    &.ant-picker > .ant-picker-input {
      ${fontStyles.bodyLarge}
    }

    & .ant-picker-input > input {
      ${fontStyles.bodyLarge}
    }

    & .ant-picker-input > input::placeholder {
      color: ${colors.placeholderInput};
    }

    &.ant-picker.ant-picker-disabled {
      background: ${colors.surfaceDisabledSurface};
      border: 1px solid ${colors.line};
    }

    & .ant-picker-input > input[disabled] {
      color: ${colors.onNeutral};
    }

    & .ant-picker-cell-in-view{
      &.ant-picker-cell-today .ant-picker-cell-inner{
        &:before {
          border-color: ${colors.primary};
        } 
      }

      &ant-picker-cell-range-end .ant-picker-cell-inner,
      &ant-picker-cell-range-start .ant-picker-cell-inner,
      &ant-picker-cell-selected .ant-picker-cell-inner {
        background: ${colors.primary};
      }
    }
  `

  const Asterisk = useCallback(
    () => <span style={{ color: colors.critical, marginLeft: 2 }}>*</span>,
    []
  )

  return (
    <>
      {label && (
        <Body size="md" color={colors.onNeutralSecondary} style={{ marginBottom: spacing.xxs }}>
          {label}
          {required && <Asterisk />}
        </Body>
      )}
      <DatePicker className={`${css(style)}`} disabled={disabled} {...rest} />
    </>
  )
}
