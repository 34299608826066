export enum OnboardingFlowActions {
  SET_PRODUCT_TOUR_VISIBILITY = 'ONBOARDING_FLOW_ACTIONS.SET_PRODUCT_TOUR_VISIBILITY',
  SET_PRODUCT_TOUR_ACTIVE_STATUS = 'ONBOARDING_FLOW_ACTIONS.SET_PRODUCT_TOUR_ACTIVE_STATUS',
  SET_PRODUCT_TOUR_STEP_INDEX = 'ONBOARDING_FLOW_ACTIONS.SET_PRODUCT_TOUR_STEP_INDEX',
  SET_PRODUCT_TOUR_ID = 'ONBOARDING_FLOW_ACTIONS.SET_PRODUCT_TOUR_ID',
}

type SetProductTourVisibility = {
  type: typeof OnboardingFlowActions.SET_PRODUCT_TOUR_VISIBILITY
  isProductTourShowing: boolean
}

type SetProductTourId = {
  type: typeof OnboardingFlowActions.SET_PRODUCT_TOUR_ID
  productTourId: string
}

type SetProductTourActiveStatus = {
  type: typeof OnboardingFlowActions.SET_PRODUCT_TOUR_ACTIVE_STATUS
  isProductTourActive: boolean
}

type SetProductTourStepIndex = {
  type: typeof OnboardingFlowActions.SET_PRODUCT_TOUR_STEP_INDEX
  stepIndex: number
}

export type ActionTypes =
  | SetProductTourVisibility
  | SetProductTourStepIndex
  | SetProductTourActiveStatus
  | SetProductTourId
