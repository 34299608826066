import { useMutation, useQuery } from 'react-query'
import type { CustomMutateOptions } from '../common/common.type'
import {
  getEmployees,
  getExternalIdentities,
  getKeycloakProfile,
  getBannerById,
  postExternalIdentity,
} from './initialisation.api'
import type { ExternalIdentityPayload } from './initialisation.types'

export const InitialisationApiQueryKey = {
  ActiveBanner: 'ActiveBanner',
  TncStatus: 'TncStatus',
  KeycloakProfile: 'KeycloakProfile',
  EmployeeProfiles: 'EmployeeProfiles',
  ExternalIdentities: 'ExternalIdentities',
  MaintenanceInfo: ['MaintenanceInfo'],
}

export const useGetKeycloakProfile = () =>
  useQuery(InitialisationApiQueryKey.KeycloakProfile, () => getKeycloakProfile())

export const useGetEmployees = () =>
  useQuery(InitialisationApiQueryKey.EmployeeProfiles, () => getEmployees())

export const useGetExternalIdentities = () =>
  useQuery(InitialisationApiQueryKey.ExternalIdentities, () => getExternalIdentities())

export const usePostExternalIdentity = ({ onSuccess, onError }: CustomMutateOptions) =>
  useMutation(
    InitialisationApiQueryKey.ExternalIdentities,
    (payload: ExternalIdentityPayload) => postExternalIdentity(payload),
    {
      onSuccess,
      onError,
    }
  )

export const useGetBannerById = (id: string) =>
  useQuery(InitialisationApiQueryKey.MaintenanceInfo, () => getBannerById(id))

export const useGetMaintenanceInfo = (id: 'sg-maintenance-page' | 'id-maintenance-page') =>
  useQuery(InitialisationApiQueryKey.MaintenanceInfo, () => getBannerById(id))
