import { SVGProps } from 'react'

function SvgNavDashboard(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.95 1.583a3.767 3.767 0 0 1 2.267.75l4.45 3.383a2.1 2.1 0 0 1 .833 1.667v6.95a2.092 2.092 0 0 1-2.083 2.083h-1.334a.417.417 0 0 1-.416-.416v-2.1A3.675 3.675 0 0 0 9 10.233h-.2a3.75 3.75 0 0 0-3.475 3.808V16a.417.417 0 0 1-.417.416H3.575A2.091 2.091 0 0 1 1.5 14.333v-7a2.108 2.108 0 0 1 .833-1.667l4.334-3.333a3.775 3.775 0 0 1 2.283-.775v.025Zm0-1.275a5 5 0 0 0-3.042 1.058L1.542 4.7a3.333 3.333 0 0 0-1.3 2.633v7a3.333 3.333 0 0 0 3.333 3.333h1.333A1.667 1.667 0 0 0 6.575 16V14.04A2.5 2.5 0 0 1 8.867 11.5H9a2.418 2.418 0 0 1 2.425 2.416V16a1.667 1.667 0 0 0 1.667 1.666h1.333a3.334 3.334 0 0 0 3.333-3.333v-6.95a3.335 3.335 0 0 0-1.325-2.667l-4.466-3.333A5 5 0 0 0 8.95.333V.308Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgNavDashboard
