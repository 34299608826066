import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Skeleton } from 'antd'
import styled from '@emotion/styled/macro'
import {
  DESKTOP_BREAKPOINT,
  Typography,
  fdsTheme,
  useResponsive,
  Drawer,
} from '@fazz/design-system'
import { Faq } from '@fazz/design-system-icons'
import { FazzBusinessLogo, FazzBusinessSandboxLogo } from '@fazz/design-system-logos'
import { Link, useLocation } from '~/hooks/useReactRouter'
import { type UserRoles, AppType, UserAgent } from '~/types'
import { SupportLinks } from '~/common/commonLinks'
import { ELEVATIONS } from '~/common/elevation'
import { getWebOrAppAgent } from '~/managers/ConfigManager'
import Sidebar from '../Sidebar/Sidebar'
import BreadcrumbText from './BreadcrumbText'
import LanguageSwitch from './LanguageSwitch'
import UserProfile from './UserProfile'
import hamburgerIcon from './hamburger.svg'

type UserInfoBarType = {
  isSandbox: boolean
  userInfo: {
    fullName: string
    displayname: string
    companyId: string
  }
  role?: UserRoles | null
  // notifications: {
  //   data: {
  //     id: number
  //     title: string
  //     message: string
  //     created_at: string
  //     is_read: number
  //     external_link: string
  //   }[]
  //   unreadCount: number
  //   isLoading: boolean
  // }
  appType: AppType | undefined
}

// ToDo: Add Notifications again once feature gets developed for realtime data

const { colors, spacing, rootPrimitives } = fdsTheme
const { Title } = Typography

export default function UserInfoBar({
  isSandbox,
  userInfo,
  // notifications,
  role,
  appType,
}: UserInfoBarType) {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const { pathname } = useLocation()
  const { fullName } = userInfo
  const whiteOrBlue = () => (isSandbox ? rootPrimitives.russianViolet : colors.surfaceNeutral)
  const { t: i18nTranslation } = useTranslation('landingPage')

  const isRegional = appType === AppType.SINGAPORE
  const countrySupportLink = isRegional ? SupportLinks.SupportSG : SupportLinks.SupportID
  const { isMobileView } = useResponsive()

  const renderLogo = () => {
    if (isSandbox) {
      return <FazzBusinessSandboxLogo height={62} />
    }

    return <FazzBusinessLogo width={100} height={40} />
  }

  return (
    <>
      <DesktopView>
        {pathname === '/' ? (
          <PageTitle>
            <Skeleton
              loading={fullName === undefined}
              paragraph={false}
              title={{ width: '300px' }}
              active
            >
              <Title size="md" display="inline">
                {i18nTranslation('WELCOME_BACK')}
              </Title>
              <Title size="md" display="inline" color={colors.primary}>
                {`${fullName && ', '} ${fullName}`}
              </Title>
            </Skeleton>
          </PageTitle>
        ) : (
          <PageTitle>
            <BreadcrumbText />
          </PageTitle>
        )}
        <DesktopRightSection>
          <FaqContainer href={countrySupportLink} rel="noopener noreferrer" target="_blank">
            <Faq width={30} height={30} />
          </FaqContainer>
          {!isRegional && <LanguageSwitch />}
          {/* ToDo: Add Notifications again once feature gets developed for realtime data */}
          {/* <Notifications notifications={notifications} appType={appType} /> */}
          <VerticalDivider />
          <UserProfile isSandbox={isSandbox} role={role} />
        </DesktopRightSection>
      </DesktopView>
      {getWebOrAppAgent === UserAgent.WEB && (
        <>
          <WebMobileView style={{ background: whiteOrBlue() }}>
            <HamburgerButton
              onClick={() => {
                setIsMobileNavOpen(true)
              }}
            >
              <img src={hamburgerIcon} alt="hamburgerIcon" />
            </HamburgerButton>{' '}
            <Link
              to="/"
              style={{
                margin: 'auto',
              }}
            >
              <MobileLogo>{renderLogo()}</MobileLogo>
            </Link>
            <MobileRightSection>
              {/* ToDo: Add Notifications again once feature gets developed for realtime data */}
              {/* <Notifications notifications={notifications} appType={appType} /> */}
              <UserProfile isSandbox={isSandbox} role={role} />
            </MobileRightSection>
          </WebMobileView>
          {isMobileView && pathname !== '/' && <BreadcrumbText />}

          <Drawer
            open={isMobileNavOpen}
            onClose={() => setIsMobileNavOpen(false)}
            placement="left"
            width="auto"
            padding="none"
            overflow="hidden"
          >
            <Sidebar />
          </Drawer>
        </>
      )}
    </>
  )
}

const DesktopView = styled.div`
  display: none;

  ${DESKTOP_BREAKPOINT} {
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${colors.line};
    justify-content: space-between;
    align-items: center;
    height: 80px;
  }
`

const VerticalDivider = styled.div`
  border-right: 1px solid ${colors.line};
  width: 1px;
  height: 70%;
  padding: ${spacing.md} 0;
`

const WebMobileView = styled.div`
  display: flex;
  justify-content: space-around;
  height: 62px;
  border-bottom: 1px solid ${colors.line};
  align-items: center;
  width: 100%;
  top: 0;
  background: ${colors.surfaceNeutral};
  z-index: ${ELEVATIONS.FLOATING_NAVIGATION_BAR};

  ${DESKTOP_BREAKPOINT} {
    display: none;
  }
`
const DesktopRightSection = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
`

const MobileRightSection = styled.div`
  display: flex;
  height: 100%;
`

const FaqContainer = styled.a`
  color: ${colors.onNeutral};
  margin-right: ${spacing.lg};
  cursor: pointer;
  &:hover {
    color: ${colors.onNeutralSecondary};
  }
`

const HamburgerButton = styled.div`
  width: 62px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const MobileLogo = styled.div`
  cursor: pointer;
`

const PageTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100vw - 30px);
  text-overflow: ellipsis;
  padding: ${spacing.lg};
  display: flex;
  align-items: center;

  ${DESKTOP_BREAKPOINT} {
    padding: ${spacing.lg};
    max-width: calc(100vw - 350px);
  }
`
