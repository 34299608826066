import * as React from 'react'

function SvgMore(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        d="M13 15a2 2 0 114 0 2 2 0 01-4 0zM13 24a2 2 0 104 0 2 2 0 00-4 0zM13 6a2 2 0 104 0 2 2 0 00-4 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMore
