import { DeprecatedButton, ErrorPage } from '@fazz/design-system'
import { Link } from '~/hooks/useReactRouter'
import UserDashboardLayout from '~/components/UserDashboardLayout'
import ErrorUtils from '~/utils/ErrorUtils'

export default function SandboxModeNotAvailable() {
  const fetchError = ErrorUtils.getErrorObject('Sandbox Mode Not Available')

  const fallbackContentHandler = () => (
    <Link to="/">
      <DeprecatedButton type="primary">Return to Dashboard</DeprecatedButton>
    </Link>
  )

  return (
    <UserDashboardLayout>
      <ErrorPage
        title={fetchError.errorTitle}
        subtitle={fetchError.errorSubtitle}
        content={fetchError.errorContent}
        fallbackContent={fallbackContentHandler()}
      />
    </UserDashboardLayout>
  )
}
