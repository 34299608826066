import { fdsTheme } from 'fds3-src/themes/theme-fds3'
import styled from 'styled-components'

const { spacing } = fdsTheme

export default styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${spacing.lg};
  gap: ${spacing.lg};
`
