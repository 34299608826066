import * as React from 'react'

function SvgInvoice(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 5H8L8 25H22V5ZM8 2C6.34315 2 5 3.34315 5 5V25C5 26.6569 6.34315 28 8 28H22C23.6569 28 25 26.6569 25 25V5C25 3.34315 23.6569 2 22 2H8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 21C11 20.4477 11.4477 20 12 20H18C18.5523 20 19 20.4477 19 21C19 21.5523 18.5523 22 18 22H12C11.4477 22 11 21.5523 11 21Z"
        fill="currentColor"
      />
      <path
        d="M15.8025 11.7594H14.6142C14.0902 11.7594 13.6639 11.3799 13.6639 10.9135C13.6639 10.447 14.0902 10.0676 14.6142 10.0676H16.9905C17.45 10.0676 17.8225 9.73599 17.8225 9.32699C17.8225 8.918 17.45 8.58642 16.9905 8.58642H16.0402V7.74058C16.0402 7.33158 15.6677 7 15.2082 7C14.7488 7 14.3763 7.33158 14.3763 7.74058V8.59654C13.0459 8.70392 12 9.70183 12 10.9135C12 12.1967 13.1727 13.2406 14.6142 13.2406H15.8025C16.3264 13.2406 16.7527 13.6201 16.7527 14.0865C16.7527 14.5529 16.3265 14.9324 15.8025 14.9324H13.426C12.9665 14.9324 12.594 15.264 12.594 15.673C12.594 16.082 12.9665 16.4135 13.426 16.4135H14.3763V17.2594C14.3763 17.6684 14.7488 18 15.2082 18C15.6677 18 16.0402 17.6684 16.0402 17.2594V16.4035C17.3706 16.2961 18.4167 15.2982 18.4167 14.0864C18.4167 12.8033 17.244 11.7594 15.8025 11.7594Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1263 7.74058C14.1263 7.16695 14.6388 6.75 15.2082 6.75C15.7776 6.75 16.2902 7.16695 16.2902 7.74058V8.33642H16.9905C17.5599 8.33642 18.0725 8.75337 18.0725 9.32699C18.0725 9.90062 17.5599 10.3176 16.9905 10.3176H14.6142C14.2002 10.3176 13.9139 10.6117 13.9139 10.9135C13.9139 11.2153 14.2002 11.5094 14.6142 11.5094H15.8025C17.3539 11.5094 18.6667 12.6387 18.6667 14.0864C18.6667 15.3794 17.6176 16.4191 16.2902 16.6255V17.2594C16.2902 17.833 15.7776 18.25 15.2082 18.25C14.6388 18.25 14.1263 17.833 14.1263 17.2594V16.6635H13.426C12.8566 16.6635 12.344 16.2466 12.344 15.673C12.344 15.0993 12.8566 14.6824 13.426 14.6824H15.8025C16.2166 14.6824 16.5027 14.3883 16.5027 14.0865C16.5027 13.7847 16.2165 13.4906 15.8025 13.4906H14.6142C13.0628 13.4906 11.75 12.3613 11.75 10.9135C11.75 9.62066 12.799 8.58098 14.1263 8.37446V7.74058ZM15.2082 7.25C14.8587 7.25 14.6263 7.49621 14.6263 7.74058V8.82717L14.3964 8.84573C13.1697 8.94474 12.25 9.8568 12.25 10.9135C12.25 12.0321 13.2827 12.9906 14.6142 12.9906H15.8025C16.4364 12.9906 17.0027 13.4554 17.0027 14.0865C17.0027 14.7175 16.4364 15.1824 15.8025 15.1824H13.426C13.0764 15.1824 12.844 15.4286 12.844 15.673C12.844 15.9173 13.0764 16.1635 13.426 16.1635H14.6263V17.2594C14.6263 17.5038 14.8587 17.75 15.2082 17.75C15.5578 17.75 15.7902 17.5038 15.7902 17.2594V16.1728L16.0201 16.1543C17.2468 16.0553 18.1667 15.1432 18.1667 14.0864C18.1667 12.9679 17.1341 12.0094 15.8025 12.0094H14.6142C13.9803 12.0094 13.4139 11.5445 13.4139 10.9135C13.4139 10.2824 13.9803 9.81757 14.6142 9.81757H16.9905C17.34 9.81757 17.5725 9.57136 17.5725 9.32699C17.5725 9.08262 17.34 8.83642 16.9905 8.83642H15.7902V7.74058C15.7902 7.49621 15.5578 7.25 15.2082 7.25Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgInvoice
