import * as React from 'react'

function SvgSmbc(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#SMBC_svg__clip0)">
        <path
          d="M25.004 15.618c-1.724 0-2.869 1.012-2.869 2.522 0 .918.3 1.778 1.784 2.994l.595.493c1.189.978 1.517 1.493 1.517 2.358 0 .837-.622 1.682-1.825 1.682-.488 0-1.65-.122-1.887-1.292a2.171 2.171 0 01-.041-.47c0-.074-.013-.246-.206-.246-.18 0-.196.209-.206.31v.163c-.022.273-.061.726-.061 1.273 0 .365-.003.473.245.596.517.252 1.136.367 1.864.367.816 0 1.541-.19 2.09-.573.872-.623 1.188-1.606 1.188-2.317 0-1.054-.335-1.92-2.009-3.241l-.39-.307c-1.293-1.056-1.6-1.476-1.6-2.174 0-.876.626-1.476 1.56-1.476 1.111 0 1.486.478 1.599.676.071.133.152.461.164.616.012.105.032.287.206.287.193 0 .184-.265.184-.41 0-.716.049-1.15.06-1.334v-.103c0-.127-.07-.184-.225-.184-.093 0-.208-.024-.47-.08-.382-.1-.789-.13-1.267-.13zm6.045 0c-.18 0-.23.233-.245.328l-.902 8.82c-.042.47-.08.936-.41 1.004-.18.035-.23.02-.347.02-.127 0-.225.071-.225.164 0 .079.039.226.306.226.215 0 .62-.035.779-.042.154-.007.206 0 .206 0h.306c.152 0 .608.034.924.042.105.007.306-.013.306-.226 0-.093-.074-.164-.184-.164-.152 0-.304-.02-.45-.061-.148-.032-.246-.172-.246-.329 0-.23-.012-.466 0-.677 0 0 .46-5.947.47-5.947-.031 0 3.034 6.747 3.034 6.747.255.512.304.635.45.635.162 0 .207-.11.41-.574l3.01-6.931c-.004 0 .574 6.644.574 6.644.003.078.015.145.02.206.007.108.01.235-.02.245-.1.04-.144.123-.144.184 0 .174.21.213.45.226.488.031 1.82.02 2.09.02.336 0 .368-.177.368-.226 0-.05-.027-.165-.206-.165-.118 0-.431.013-.78-.102-.487-.17-.568-.915-.614-1.354l-.84-8.265c-.015-.188-.045-.45-.245-.45-.167 0-.226.164-.267.267.01.01-3.565 8.223-3.587 8.264-.022-.024-3.73-8.284-3.73-8.284-.046-.123-.107-.245-.261-.245z"
          fill="#005E44"
        />
        <path
          d="M15.903 6l-4.878 2.072v11.935l4.878-2.072V6zm-5.512 2.339l-4.878 7.257v11.935l4.878-7.26V8.34zm-5.513 7.524L0 17.935V29.87l4.878-2.072V15.863z"
          fill="#B6CF48"
        />
        <path
          d="M47.46 20.288c.416.238.771.417 1.127.656.89.715 1.248 1.85 1.01 2.982-.236 1.132-.891 1.785-2.018 2.083-.534.179-1.186.179-1.779.238-.83 0-1.72 0-2.55-.06h-.296c-.06 0-.178-.032-.178-.178 0-.087.055-.137.178-.179.178-.06.474-.06.534-.178.118-.18.178-.477.178-.775.059-1.49.059-2.98.059-4.47V17.13v-.299c-.119-.476-.237-.596-.77-.715-.158-.035-.416 0-.416-.178 0-.18.14-.18.415-.18h2.905c.356 0 .771.06 1.068.18 1.304.357 1.897 1.579 1.66 2.693-.237 1.115-.8 1.394-1.127 1.657zm-1.897.477h-.415c-.06 0-.178.119-.178.179 0 1.31 0 2.622.06 3.933 0 .298.177.477.414.596.534.179 1.127.238 1.66.119.712-.179 1.127-.656 1.246-1.37.178-1.49-.712-3.517-2.787-3.457zm-.652-2.444v1.61c0 .178.06.297.237.297.415 0 .83.06 1.245 0 .178 0 .415-.119.534-.298.474-.476.533-1.072.474-1.668-.118-1.37-.89-2.027-2.075-1.907-.297 0-.415.119-.415.417.06.477 0 1.013 0 1.55zM53.783 25.547c-1.342-.823-2.101-2.115-2.335-3.643-.233-1.528 0-2.939.876-4.232.934-1.351 2.335-1.88 3.912-1.998.992-.058 1.984.06 2.919.236.642.117.642.117.642.764v1.762c0 .177-.292.236-.35 0 0-.058 0-.176-.059-.235-.058-.822-.525-1.351-1.343-1.645-1.167-.294-2.335-.294-3.444.176-.934.412-1.518 1.176-1.752 2.175-.408 1.821-.233 3.526.876 4.995 1.168 1.528 2.86 1.939 4.612 1.469.584-.176.993-.529 1.11-1.117.058-.117.058-.235.116-.352.059-.177.059-.353.292-.353.234 0 .117.294.117.412-.059.528-.117 1.057-.234 1.527-.058.294-.233.47-.525.53-.526.117-.993.234-1.518.293-1.401.118-2.919-.117-3.912-.764z"
          fill="#005E44"
        />
      </g>
      <defs>
        <clipPath id="SMBC_svg__clip0">
          <path fill="#fff" d="M0 0h60v35H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgSmbc
