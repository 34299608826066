import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Toast } from '@fazz/design-system'
import { InfoFilled } from '@fazz/design-system-icons'
import type { AppType } from '~/types'
import { getBankList, getBusinessEntityInformation, postExtendSession } from './common.api'
import type { BankResponseType, CustomMutateOptions } from './common.type'

const CommonApiQueryKeys = {
  GetBankList: ['GetBankList'],
  BusinessEntityInfo: ['BusinessEntityInfo'],
  Session: ['ExtendSession'],
}

type BankOptionType = {
  label: string
  value: string
  name: string
}

export const useGetBankList = ({ country }: { country: AppType }) => {
  const query = useQuery(CommonApiQueryKeys.GetBankList, () => getBankList(country))
  const [bankOptions, setBankOptions] = useState<BankOptionType[]>([])

  useEffect(() => {
    if (query.isSuccess) {
      const { data } = query.data.data
      const options = data.map((bank: BankResponseType) => {
        const { attributes } = bank
        const { name, shortCode } = attributes
        return {
          label: shortCode,
          value: shortCode,
          name,
        }
      })
      setBankOptions(options)
    }
  }, [query.isSuccess, query.data])

  useEffect(() => {
    if (query.isError) {
      Toast({
        icon: <InfoFilled />,
        type: 'critical',
        description: 'Failed to get bank list',
      })
    }
  }, [query.isError])

  return { query, bankOptions }
}

export const useGetBusinessEntityInfo = () =>
  useQuery(CommonApiQueryKeys.BusinessEntityInfo, () => getBusinessEntityInformation())

export const usePostExtendSession = ({ onSuccess, onError }: CustomMutateOptions) => {
  const queryClient = useQueryClient()
  return useMutation(CommonApiQueryKeys.Session, () => postExtendSession(), {
    onSuccess,
    onError,
    onSettled: () => queryClient.invalidateQueries(),
  })
}
