// Add comments on what this function does, and reference for the solution
import { type ComponentType, lazy as originalLazy } from 'react'

type ImportComponent = () => Promise<{ default: ComponentType<any> }>

export default {
  /**
   * This function wraps around `lazy` import from React library
   * This to prevent the error when servers trying to import static files from a CDN that's already expired
   * Eg: new deployment
   */
  lazyWithRetry: (importComponent: ImportComponent) =>
    originalLazy((async () => {
      const isPageHasBeenForceRefreshed = JSON.parse(
        localStorage.getItem('page-has-been-force-refreshed') || 'false'
      )

      try {
        const component = await importComponent()

        localStorage.setItem('page-has-been-force-refreshed', 'false')

        return component
      } catch (error) {
        if (!isPageHasBeenForceRefreshed) {
          localStorage.setItem('page-has-been-force-refreshed', 'true')
          return window.location.reload()
        }

        throw error
      }
    }) as ImportComponent),
}
