import type { Reducer } from 'redux'
import moment from 'moment'
import type { BusinessVerificationStatus, PersonalVerificationStatus } from '~/types'
import type { ActionTypes, FilterOptions, FilterValues, PaginationCursor } from './types'
import { ID_TRANSACTIONS_ACTIONS, XFERS_ACCEPT_TAB_KEY } from './types'

const initialState = {
  filterOptions: null,
  isFilterOptionsLoading: false,
  filterValues: {
    startDate: moment().subtract(1, 'months'),
    endDate: moment(),
    transactionStatuses: [],
    transactionTypes: [],
    methods: [],
    paymentMethod: XFERS_ACCEPT_TAB_KEY.VIRTUAL_ACCOUNTS,
    sortType: '',
    accountIds: [],
    currentPage: 1,
    pageLimit: 10,
    searchString: '',
    contractsExist: false,
    banks: [],
    paymentChannels: [],
    cardTypes: [],
    retailStoreTypes: [],
    isTransactionLoading: false,
    transactions: [],
    totalCount: 0,
    disbursementMethod: '',
    referenceId: '',
    vaNumberOrRetailCode: '',
    cashinCode: '',
    displayName: '',
    eWalletTypes: '',
  },
  errorMessage: '',
  tabKeyName: XFERS_ACCEPT_TAB_KEY.VIRTUAL_ACCOUNTS,
  transactionType: 'xfers_accept',
  useNewPagination: false,
  paginationCursors: [],
  businessVerificationStatus: null,
  userVerificationStatus: null,
}

type State = {
  filterOptions: null | FilterOptions
  isFilterOptionsLoading: boolean
  filterValues: FilterValues
  errorMessage: string
  tabKeyName: string
  transactionType: string
  useNewPagination: boolean
  paginationCursors: Array<PaginationCursor>
  userVerificationStatus: PersonalVerificationStatus
  businessVerificationStatus: BusinessVerificationStatus | null
}

export const indoTransactions: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case ID_TRANSACTIONS_ACTIONS.GET_TRANSACTIONS_FILTER_OPTIONS: {
      return { ...state, filterOptions: action.filterOptions }
    }

    case ID_TRANSACTIONS_ACTIONS.SET_TRANSACTIONS_FILTER_OPTIONS_LOADING: {
      return { ...state, isFilterOptionsLoading: action.isFilterOptionsLoading }
    }

    case ID_TRANSACTIONS_ACTIONS.GET_TRANSACTIONS: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          transactions: action.transactions,
          contractsExist: action.contractsExist,
          totalCount: action.totalCount,
        },
      }
    }

    case ID_TRANSACTIONS_ACTIONS.SET_IS_TRANSACTION_LOADING: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          isTransactionLoading: action.isTransactionLoading,
        },
      }
    }

    case ID_TRANSACTIONS_ACTIONS.SET_FILTERS: {
      const { filterValuesObject } = action

      const newTabFilterValues: FilterValues = {
        ...state.filterValues,
      }

      Object.keys(filterValuesObject).forEach((key: string) => {
        newTabFilterValues[key] = filterValuesObject[key]
      })

      return {
        ...state,
        filterValues: {
          ...newTabFilterValues,
        },
      }
    }

    case ID_TRANSACTIONS_ACTIONS.SET_TRANSACTIONS_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.errorMessage,
      }
    }

    case ID_TRANSACTIONS_ACTIONS.SET_TAB_KEY_NAME: {
      return {
        ...state,
        tabKeyName: action.tabKeyName,
      }
    }

    case ID_TRANSACTIONS_ACTIONS.SET_TRANSACTION_TYPE: {
      return {
        ...state,
        transactionType: action.typeName,
      }
    }

    case ID_TRANSACTIONS_ACTIONS.SET_USE_NEW_PAGINATION: {
      return {
        ...state,
        useNewPagination: action.useNewPagination,
      }
    }

    case ID_TRANSACTIONS_ACTIONS.SET_PAGINATION_CURSORS: {
      return {
        ...state,
        paginationCursors: action.paginationCursors,
      }
    }

    case ID_TRANSACTIONS_ACTIONS.SET_PERSONAL_VERIFICATION_STATUS: {
      return {
        ...state,
        userVerificationStatus: action.userVerificationStatus,
      }
    }

    case ID_TRANSACTIONS_ACTIONS.SET_BUSINESS_VERIFICATION_STATUS: {
      return {
        ...state,
        businessVerificationStatus: action.businessVerificationStatus,
      }
    }

    default:
      return state
  }
}
