import { css } from 'emotion'
import styled from 'styled-components'
import type { PaginationProps } from 'antd/es/pagination'
import Pagination from 'antd/es/pagination'
import 'antd/es/pagination/style/css'
import { fdsTheme } from '../../../themes/theme-fds3'

type Props = Pick<
  PaginationProps,
  | 'current'
  | 'pageSize'
  | 'total'
  | 'onChange'
  | 'showSizeChanger'
  | 'onShowSizeChange'
  | 'pageSizeOptions'
  | 'simple'
>

const { colors, fontFamily, fontSize } = fdsTheme

/** @deprecated please use PaginationFds3 instead */
function CustomPagination({
  current,
  pageSize,
  total,
  onChange,
  showSizeChanger,
  onShowSizeChange,
  pageSizeOptions,
  simple,
}: Props) {
  const style = `
    -webkit-font-smoothing: antialiased;

    .ant-pagination-prev,
    .ant-pagination-next {
      button {
        border: none;
  
        &:hover {
          & > span {
            color: ${colors.primary};
          }
        }
      }
    }

    .ant-pagination-item {
      ${simple && `display: none;`}
      font-family: ${fontFamily.fazzNeue};
      font-size: ${fontSize.bodyMedium};
      text-align: center;
      border: none;
  
      a {
      }
      &:hover {
        & > a {
          border-radius: 999px;
          color: ${colors.primary};
        }
      }
    }

    & > .ant-pagination-jump-next {
      ${simple && `display: none;`}
    }

    .ant-pagination-item-active {
      &:hover {
        & > a {
          color: ${colors.onContainer};
        }
      }
  
      & > a {
        border-radius: 999px;
        background-color: ${colors.active};
        color: ${colors.onContainer};
      }
    }
    
    & > .ant-pagination-options .ant-select .ant-select-selection-item {
        display: flex;
        justify-content: center;
        align-items: center;
    }
  `

  return (
    <Pagination
      className={`${css(style)}`}
      current={current}
      pageSize={pageSize}
      total={total}
      onChange={onChange}
      showSizeChanger={showSizeChanger}
      onShowSizeChange={onShowSizeChange}
      pageSizeOptions={pageSizeOptions}
    />
  )
}

const StyledPagination = styled(CustomPagination)`
  .ant-pagination-prev,
  .ant-pagination-next {
    button {
      border: none;

      &:hover {
        & > span {
          color: ${colors.primary};
        }
      }
    }
  }

  .ant-pagination-item {
    font-family: ${fontFamily.fazzNeue};
    font-size: ${fontSize.bodyMedium};
    text-align: center;
    border: none;

    a {
    }
    &:hover {
      & > a {
        border-radius: 999px;
        color: ${colors.primary};
      }
    }
  }

  .ant-pagination-item-active {
    &:hover {
      & > a {
        color: ${colors.onContainer};
      }
    }

    & > a {
      border-radius: 999px;
      background-color: ${colors.active};
      color: ${colors.onContainer};
    }
  }
`

export default StyledPagination
