import * as React from 'react'

function SvgLogout(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 3.5a1.5 1.5 0 00-3 0V15a1.5 1.5 0 003 0V3.5zm7.234 2.612a1.5 1.5 0 10-1.979 2.254C23.76 10.126 25 12.642 25 15.426 25 20.66 20.577 25 15 25S5 20.66 5 15.425c0-2.782 1.24-5.298 3.243-7.057a1.5 1.5 0 00-1.98-2.254C3.655 8.404 2 11.724 2 15.425 2 22.424 7.874 28 15 28s13-5.577 13-12.575c0-3.703-1.656-7.022-4.266-9.314z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgLogout
