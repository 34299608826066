export default function TncDocViewer() {
  return (
    <>
      <p>
        Dengan menggunakan fitur Pay & Receive yang tersedia pada dashboard Fazz Business, baik
        mengakses, menggunakan dan/atau memproses layanan tersebut, maka Anda sedang menggunakan
        platform elektronik yang disediakan oleh PT Xfers Indonesia Teknologi dan/atau mitra yang
        melakukan kerja sama dengan kami (“<b>PT XIT</b>” atau “<b>Kami</b>”). Sehubungan dengan hal
        tersebut, mohon untuk membaca dan menyetujui langkah-langkah yang diterapkan untuk dapat
        menggunakan dan menikmati Layanan Fazz Business ini dengan optimal dan oleh karenanya akan
        dianggap sebagai perjanjian yang mengikat bagi Kami, Mitra kami: PT Inklusi Keuangan
        Nusantara, dan Anda, khususnya pada saat Anda menggunakan fitur Pay & Receive. <br />
        <br /> MItra dan Anda secara sendiri-sendiri disebut sebagai “Pihak” dan bersama sama
        sebagai “Para Pihak”.
      </p>
      <br />
      <ol>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <li>
            <b> Lingkup Ketentuan Penggunaan Layanan Pay & Receive</b>
            <ol>
              <br />
              <li>
                Anda dengan ini setuju untuk menunjuk mitra kami, PT Inklusi Keuangan Nusantara atau
                mitra lainnya yang bekerja sama dengan kami sebagai penyedia Layanan Pay & Receive
                yang dibutuhkan oleh Anda dalam menjalankan Bisnis Anda, dan kami dengan ini setuju
                untuk melaksanakan penyediaan layanan Pay & Receive yang dibutuhkan tersebut.
              </li>
              <li>
                Anda memahami bahwa PT XIT hanya bertindak sebagai penyedia dan pengoperasian atas
                Dashboard. Sehingga dalam hal Anda menyetujui Syarat dan Ketentuan ini maka hubungan
                hukum yang timbul adalah antara Anda dan PT Inklusi Keuangan Nusantara untuk
                selanjutnya disebut sebagai “<b>Mitra</b>”.
              </li>
              <li>
                Selain dari syarat dan ketentuan ini, Anda akan diminta untuk menyetujui syarat dan
                ketentuan yang telah diatur oleh Mitra apabila ada termasuk namun tidak terbatas
                pada perjanjian penyediaan layanan apabila relevan.
              </li>
            </ol>
          </li>
          <li>
            <b>Definisi</b> <br />
            <br />
            Kecuali secara tegas dinyatakan lain atau konteksnya menentukan lain, setiap istilah
            dalam Perjanjian ini yang diawali dengan huruf kapital di bawah ini memiliki arti
            sebagai berikut:
            <ol>
              <li>
                “<b>Aktivitas-Aktivitas Terlarang</b>” adalah aktivitas-aktivitas yang diuraikan
                dalam Pasal 7 pada syarat dan ketentuan ini.
              </li>
              <li>
                “<b>Biaya Layanan</b>” berarti sejumlah biaya yang wajib dibayarkan oleh Anda kepada
                Mitra untuk setiap Transaksi Berhasil sebagaimana disepakati di dalam Pasal 6 pada
                Syarat dan Ketentuan ini dan/atau dokumen lain seperti perjanjian (apabila relevan)
                yang disepakati oleh Para Pihak secara terpisah.
              </li>
              <li>
                “<b>Cadangan</b>” berarti persentase dana di Rekening Anda (didefinisikan di bawah
                ini) yang kami tahan untuk melindungi terhadap risiko Reversal (didefinisikan di
                bawah ini), Chargeback (didefinisikan di bawah ini), klaim atau tanggung jawab
                apapun lainnya yang terkait dengan Rekening Anda dan/atau penggunaan Pay & Receive.
              </li>
              <li>
                “<b>Chargeback</b>” berarti permohonan yang diajukan oleh pengguna secara langsung
                kepada banknya untuk membatalkan suatu pembayaran.
              </li>
              <li>
                “<b>Dana</b>” berarti sejumlah uang yang ditentukan oleh Anda untuk ditransfer
                sebagaimana tercantum dalam Perintah Pengiriman Dana.
              </li>
              <li>
                “<b>Dana Dari Pihak Ketiga</b>” berarti sejumlah uang yang diterima dari Pengirim
                sebagaimana tercantum dalam Perintah Penerimaan Dana.{' '}
              </li>
              <li>
                “<b>Hari Kalender</b>” berarti semua hari mulai dari hari Senin sampai dengan Hari
                Minggu, termasuk hari libur nasional.
              </li>
              <li>
                “<b>Hari Kerja</b>” berarti hari (tidak termasuk hari Sabtu, Minggu dan hari libur
                nasional) di mana bank-bank di DKI Jakarta menyelenggarakan kegiatan kliring
                berdasarkan peraturan atau penetapan Bank Indonesia.
              </li>
              <li>
                “<b>Layanan Pay & Receiv</b>e” berarti layanan yang terdiri dari layanan Perintah
                Pengiriman Dana dan layanan Perintah Penerimaan Dana.
              </li>
              <li>
                “<b>Penerima</b>” berarti pihak yang disebut dalam Perintah Pengiriman Dana untuk
                menerima Dana hasil Layanan Pengiriman Dana.
              </li>
              <li>
                “<b>Pengirim</b>” berarti pihak yang disebut dalam Perintah Penerimaan Dana yang
                akan mengirim sejumlah uang kepada Anda.
              </li>
              <li>
                “<b>Perintah Penerimaan Dana</b>” berarti perintah yang diberikan oleh Anda kepada
                Mitra untuk menerima Dana Dari Pihak Ketiga, melalui pentransferan atau pemindahan,
                kepada Anda baik secara manual pada dashboard yang disediakan oleh Mitra, dengan
                mengunggah excel file dalam format CSV pada dashboard yang disediakan oleh Mitra
                dengan ketentuan bahwa jumlah uang yang diinformasikan akan disetor oleh Pengirim
                sesuai dengan jumlah Dana Dari Pihak Ketiga.{' '}
              </li>
              <li>
                “<b>Perintah Pengiriman Dana</b>” berarti perintah yang diberikan oleh Anda kepada
                Mitra untuk mentransfer atau memindahkan sejumlah Dana kepada Penerima, baik secara
                manual pada dashboard yang disediakan oleh Mitra, dengan mengunggah excel file dalam
                format CSV pada dashboard yang disediakan oleh Mitra dengan ketentuan bahwa seluruh
                Dana dan Biaya Layanan telah ditransfer oleh Anda ke Rekening Mitra.
              </li>
              <li>
                “<b>Rekening Anda</b>” berarti rekening Bank yang ditentukan oleh Anda untuk
                kepentingan pelaksanaan Perjanjian ini yang dapat berubah sebagaimana diberitahukan
                secara tertulis dari waktu ke waktu oleh Anda kepada Mitra, yang pada Tanggal
                Berlaku adalah sebagaimana disampaikan pada Perjanjian.
              </li>
              <li>
                “<b>Rekening Mitra</b>” berarti rekening Bank yang ditentukan oleh Mitra untuk
                kepentingan pelaksanaan Pay & Receive yang dapat berubah dari waktu ke waktu
                sebagaimana ditampilkan pada sistem elektronik Mitra pada saat Anda membuat Perintah
                Pengiriman Dana.
              </li>
              <li>
                “<b>Reversal</b>” berarti Mitra mengembalikan pembayaran/transfer yang Anda telah
                terima karena (a) pembayaran/transfer tersebut tidak dibatalkan oleh bank pengirim,
                (b) pembayaran/transfer tersebut terkirim kepada Anda karena kesalahan Mitra, Mitra
                induk, anak Mitra, afiliasinya, atau setiap anak Mitra langsung Mitra, (c) pengirim
                pembayaran/pihak yang melakukan transfer tidak memiliki izin untuk mengirim
                pembayaran (contohnya: pengirim menggunakan rekening curian), (d) pengirim
                pembayaran/pihak yang melakukan transfer melakukan kekeliruan dengan mengirim banyak
                pembayaran/melakukan banyak transfer, atau kesalahan nyata atau jelas lainnya, (e)
                Anda menerima pembayaran/transfer untuk kegiatan-kegiatan yang melanggar Perjanjian
                ini, atau setiap kebijakan atau pengaturan lainnya, atau (f) Mitra telah menetapkan
                klaim terhadap Anda.
              </li>
              <li>
                “<b>Transaksi Berhasil</b>” berarti Transaksi Berhasil Sehubungan Dengan Layanan
                Penerimaan Dana dan/atau Transaksi Berhasil Sehubungan Dengan Layanan Pengiriman
                Dana.
              </li>
              <li>
                “<b>Transaksi Berhasil Sehubungan Dengan Layanan Penerimaan Dana</b>” berarti setiap
                Layanan Penerimaan Dana yang berhasil dilakukan oleh Mitra menurut syarat dan
                ketentuan Perjanjian ini, yang dibuktikan dengan diterimanya Dana Dari Pihak Ketiga
                pada Rekening Anda yang dapat dibuktikan dengan data mutasi rekening bank atau
                diterimanya Dana Dari Pihak Ketiga pada rekening tampungan Anda pada Rekening Mitra
                yang dapat dibuktikan dengan telah diterima Dana Dari Pihak Ketiga tersebut pada
                Rekening Mitra.
              </li>
              <li>
                “<b>Transaksi Berhasil Sehubungan Dengan Layanan Pengiriman Dana</b>” berarti setiap
                Layanan Pengiriman Dana yang telah berhasil dilakukan oleh Mitra menurut syarat dan
                ketentuan Perjanjian ini, yang dibuktikan dengan diterimanya Dana pada rekening
                Penerima yang dapat dibuktikan dengan data mutasi rekening bank Penerima atau
                Rekening Mitra.
              </li>
            </ol>
          </li>
          <li>
            <b> Ketentuan Layanan Pay & Receive</b>
            <ol>
              <br />
              <li>
                Transfer dana melalui Dashboard Fazz Business yang diaksep oleh sistem Mitra hanya
                dapat Anda lakukan sebagai pengguna terverifikasi maksimal senilai saldo Anda yang
                tersedia pada saat akan melakukan transfer, dikurangi biaya jasa yang berlaku. PT
                XIT akan menginformasikan kepada Anda biaya jasa yang berlaku tersebut.
              </li>
              <li>
                Mitra dapat menetapkan nilai minimal dan/atau maksimal transfer yang dapat Anda
                otorisasi untuk setiap transfer. Perubahan nilai minimal dan/atau maksimal transfer
                akan PT XIT beritahukan kepada Anda.
              </li>
              <li>
                Mitra akan memproses transfer dana Business sesuai dengan instruksi Anda. Anda harus
                memastikan bahwa Anda telah memasukkan tujuan atau penerima transfer yang benar.
                Transfer yang telah diproses sesuai dengan instruksi Anda tidak dapat dikembalikan,
                termasuk apabila Anda memasukkan jumlah, tujuan atau penerima transfer yang salah.
              </li>
              <li>
                Ketika Anda bermaksud untuk melakukan transfer saldo uang elektronik atau melalui
                Dashboard Fazz Business, PT XIT dapat mengungkapkan nama lengkap terdaftar Anda dan
                data lainnya kepada penerima transfer. Ketika pengirim transfer bermaksud untuk
                melakukan transfer kepada Anda, PT XIT dapat mengungkapkan nama lengkap terdaftar
                Anda dan data lainnya kepada pengirim transfer.
              </li>
              <li>
                PT XIT dapat menyediakan Anda berbagai metode untuk transfer dana melalui Dashboard
                Fazz Business, termasuk melalui tautan yang dapat Anda bagikan di luar Dashboard
                dan/atau tautan lainnya sebagaimana relevan. Anda sepenuhnya memahami dan
                bertanggung jawab untuk menjaga keamanan dan kerahasiaan tautan tersebut, termasuk
                hanya membagikan tautan ke penerima sebagaimana ditentukan oleh Anda dan/atau
                cara-cara lainnya sebagaimana relevan untuk menjaga keamanan dan kerahasiaan tautan
                tersebut. Anda setuju bahwa setiap instruksi transfer yang dibuat melalui akun Fazz
                Business Anda dari Dashboard, tautan yang dapat dibagikan di luar Dashboard atau
                Mitra Platform Resmi akan dianggap telah diotorisasi oleh Anda oleh karenanya PT XIT
                akan memproses transfer saldo Anda atau melalui akun seperti yang diinstruksikan
                oleh Anda.
              </li>
              <li>
                Jika Anda menyampaikan instruksi transfer dana atau melalui Dashboard Fazz Business,
                baik melalui Dashboard atau Mitra Platform Resmi, yang ditujukan untuk diterima oleh
                seseorang: i) dengan identifikasi digital tertentu, termasuk nomor telepon, surat
                elektronik tertentu dan/atau identifikasi digital lainnya yang dapat diterapkan
                secara wajar, yang tidak terhubung dengan atau belum membuka akun Fazz Business;
                atau ii) secara aktif melalui tindakan penerimaan tertentu oleh penerima sebagaimana
                dipersyaratkan oleh metode transfer yang Anda pilih, maka PT XIT dapat memilih untuk
                memproses atau tidak memproses instruksi tersebut. PT XIT dapat menyimpan dana
                tersebut terlebih dahulu dan mensyaratkan penerima untuk: i) melakukan registrasi
                akun Fazz Business dalam jangka waktu tertentu; atau ii) secara aktif setuju untuk
                menerima dana melalui tindakan tertentu sebagaimana dipersyaratkan oleh metode
                transfer yang Anda pilih untuk dapat menerima saldo Fazz Business yang Anda
                transfer. Dalam hal mana jika penerima tujuan tidak melakukan registrasi Fazz
                Business dalam jangka waktu yang PT XIT tentukan, maka dana tersebut akan
                dikembalikan ke Dashboard Fazz Business Anda.
              </li>
              <li>
                Setiap kendala dan/atau kerugian yang timbul terkait dengan transfer karena
                kegagalan sistem Mitra Platform Resmi atau sistem pihak ketiga lainnya tidak akan
                menjadi tanggung jawab PT XIT. Namun, jika PT XIT menerima keluhan dari Anda tentang
                masalah tersebut, maka PT XIT dapat, berdasarkan kebijaksanaan PT XIT, meneruskan
                keluhan Anda kepada Mitra Platform Resmi tersebut atau kepada pihak ketiga terkait
                untuk tindak lanjutnya.
              </li>
            </ol>
          </li>
          <li>
            <b>Hak dan Kewajiban Anda</b>
            <ol>
              <br />
              <li>
                Tanpa mengesampingkan kewajiban-kewajiban Anda lainnya berdasarkan Perjanjian dan
                Syarat dan Ketentuan ini, Anda wajib untuk:
                <ol>
                  <li>
                    {' '}
                    menaati segala ketentuan dalam Perjanjian dan Syarat dan Ketentuan ini dan
                    ketentuan lainnya yang akan ditentukan kemudian oleh Mitra sehubungan dengan Pay
                    & Receive sebagaimana diatur dalam Pendahuluan B Syarat dan Ketentuan ini;
                  </li>
                  <li>
                    melakukan pembayaran Biaya Layanan kepada Mitra untuk setiap Transaksi Berhasil
                    sesuai dengan ketentuan dan mekanisme sebagaimana diatur dalam syarat dan
                    ketentuan ini;
                  </li>
                  <li>
                    melakukan pengiriman atau penyetoran Dana kepada Mitra sesuai dengan total
                    nominal Dana dan Biaya Layanan yang tertera pada Perintah Pengiriman Dana dari
                    Anda. Dalam hal terdapat Dana yang belum diterima oleh Mitra atas Transaksi
                    Berhasil dengan batasan sebagaimana ditentukan oleh Mitra, akan selalu
                    disesuaikan pada saat Anda melakukan pengiriman atau penyetoran Dana kepada
                    Mitra atas Dana yang telah keluarkan Mitra sehubungan dengan transaksi;
                  </li>
                  <li>
                    memastikan Pengirim mengirimkan Dana Dari Pihak Ketiga sesuai dengan total
                    nominal Dana Dari Pihak Ketiga yang tertera pada Perintah Penerimaan Dana. Untuk
                    melakukan penambahan batasan sebagaimana Pasal 3 ayat (1) huruf (c), maka Anda
                    wajib memberitahukan secara tertulis kepada Mitra minimal 14 (empat belas) Hari
                    Kerja sebelum waktu penambahan batas maksimum yang diinginkan Anda. Batasan
                    sebagaimana Pasal 3 ayat (1) huruf (c) akan ditinjau secara periodik setiap 1
                    (satu) tahun oleh Mitra dengan mempertimbangkan pelaksanaan yang sudah berjalan;
                  </li>
                  <li>
                    melakukan konfirmasi kepada Mitra melalui mekanisme yang ditentukan dari waktu
                    ke waktu oleh Mitra, termasuk surat elektronik (e-mail), mengenai bank yang
                    digunakan oleh Anda dan bank tujuan Anda pada setiap transaksi yang akan
                    dilakukan;
                  </li>
                  <li>
                    memastikan dan menjamin kebenaran dan kelengkapan semua data dan informasi yang
                    diberikan atau tertera pada setiap Perintah Pengiriman Dana.
                  </li>
                  <li>
                    sebagaimana dipersyaratkan oleh Bank Indonesia, Anda wajib untuk menyediakan
                    akses kepada Bank Indonesia, institusi, atau otoritas terkait lainnya, termasuk
                    namun tidak terbatas pada institusi atau otoritas terkait aktivitas anti
                    pencucian uang dan pencegahan pendanaan terorisme, untuk melakukan pengecekan
                    terhadap data, informasi, infrastruktur dan hal lainnya yang terkait dengan
                    penyelenggaraan aktivitas sistem pembayaran oleh Mitra.
                  </li>
                  <li>
                    memastikan dan menjamin untuk mematuhi seluruh peraturan, hukum, undang-undang,
                    ordonansi, atau peraturan apapun yang berlaku, termasuk tetapi tidak terbatas
                    pada yang diterbitkan oleh Bank Indonesia, Lembaga Pusat Pelaporan dan Analisis
                    Transaksi Keuangan (PPATK) institusi, atau otoritas terkait lainnya, termasuk
                    namun tidak terbatas pada institusi atau otoritas terkait aktivitas anti
                    pencucian uang dan pencegahan pendanaan terorisme.
                  </li>
                  <li>
                    sehubungan dengan ketentuan Pasal 8 Perjanjian ini, Anda wajib untuk bekerjasama
                    dengan Mitra, dalam hal terdapat permintaan data dan informasi, baik dari Mitra,
                    ataupun dari lembaga negara atau penegak hukum, untuk menyelesaikan sengketa
                    atau klaim sehubungan dengan Aktivitas-Aktivitas Terlarang sebagaimana diatur
                    dalam Pasal 7 Perjanjian ini.
                  </li>
                </ol>
              </li>
              <li>
                Anda berhak untuk:
                <ol>
                  <li>
                    menggunakan Pay & Receive yang diselenggarakan oleh Mitra sesuai dengan syarat
                    dan ketentuan Perjanjian dan Syarat dan Ketentuan ini;
                  </li>
                  <li>
                    segera mendapatkan laporan mengenai Pay & Receive dan Transaksi Berhasil yang
                    akurat dan lengkap dari Mitra dalam jangka waktu paling lambat 20 (dua puluh)
                    menit sejak Perintah Penerimaan Dana dan Perintah Pengiriman Dana diberikan oleh
                    Anda <b>(“Laporan Transaksi”)</b>, untuk menghindari keragu-raguan laporan yang
                    dimaksud dalam Syarat dan Ketentuan ini adalah laporan mengenai status
                    transaksi, baik merupakan transaksi yang tertunda, berhasil atau ditolak, dan
                    tidak akan diartikan laporan mengenai keberhasilan diterimanya dana oleh pihak
                    yang dituju sebagaimana dimaksud dalam masing-masing Layanan Penerimaan Dana dan
                    Layanan Pengiriman Dana sebagaimana relevan.
                  </li>
                  <li>
                    dalam hal Layanan Pengiriman Dana gagal atau belum diterima pada rekening
                    Penerima sampai dengan berakhirnya hari di mana Perintah Pengiriman Dana
                    diterima oleh Mitra, memilih untuk: (i) memperoleh pengembalian secara penuh
                    atas seluruh Dana dan Biaya Layanan dalam jangka waktu paling lambat 3 (tiga)
                    Hari Kalender sejak tanggal diterimanya Perintah Pengiriman Dana dari Anda; atau
                    (ii) memperoleh penyelesaian Layanan Pengiriman Dana agar Dana diterima pada
                    rekening Penerima sesuai Perintah Pengiriman Dana dalam jangka waktu paling
                    lambat 1 (satu) Hari Kalender sejak tanggal diterimanya Perintah Pengiriman Dana
                    tersebut dari Anda.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>Hak dan Kewajiban Mitra</b>
            <ol>
              <br />
              <li>
                Tanpa mengesampingkan kewajiban-kewajiban Mitra lainnya berdasarkan Perjanjian dan
                Syarat dan Ketentuan ini, Mitra sebagaimana difasilitasi oleh PT XIT, wajib untuk:
                <ol>
                  <li>
                    memastikan ketersediaan sistem dan jaringan untuk penyediaan Pay & Receive yang
                    andal dan terjamin keamanan dan akurasinya kepada Anda sesuai dengan syarat dan
                    ketentuan Syarat dan Ketentuan ini;
                  </li>
                  <li>
                    segera menyiapkan dan menyampaikan laporan mengenai Pay & Receive dan Transaksi
                    Berhasil secara akurat dan lengkap untuk kepentingan Anda dalam jangka waktu
                    paling lambat 20 (dua puluh) menit sejak Perintah Penerimaan Dana dan Perintah
                    Pengiriman Dana diberikan oleh Anda;
                  </li>
                  <li>
                    dalam hal Layanan Pengiriman Dana gagal atau belum diterima pada rekening
                    Penerima sampai dengan berakhirnya hari di mana Perintah Pengiriman Dana
                    diterima, segera memberitahukan kepada Anda dan kemudian melaksanakan instruksi
                    yang diberikan oleh Anda;
                  </li>
                  <li>
                    bertanggung jawab atas keamanan, kelancaran, dan kelayakan Pay & Receive,
                    termasuk perlindungan data pribadi, agar selalu dapat berjalan dengan baik,
                    aman, layak, dan terkendali untuk digunakan oleh Anda sesuai dengan syarat dan
                    ketentuan Syarat dan Ketentuan ini;
                  </li>
                  <li>
                    menginformasikan kepada Anda mengenai ketersediaan proses transfer dari bank
                    yang digunakan oleh Anda ke bank tujuan yang diinformasikan oleh Anda, dengan
                    ketentuan jika proses transfer tidak dapat dilakukan, maka Perintah Pengiriman
                    Dana yang masuk ke sistem Mitra akan dibatalkan dan Dana berikut dengan Biaya
                    Layanan akan dikembalikan kepada Anda pada hari yang sama; dan
                  </li>
                  <li>
                    memberitahu Anda dalam waktu 2 (dua) Hari Kalender sebelumnya secara tertulis
                    apabila terdapat perbaikan atau pemeliharan jaringan, sistem, perangkat lunak
                    dan/atau perangkat keras Mitra yang dapat mempengaruhi kegiatan Pay & Receive.
                  </li>
                </ol>
              </li>
              <li>
                Mitra berhak untuk:
                <ol>
                  <li>
                    {' '}
                    menerima pembayaran Biaya Layanan dari Anda sesuai dengan jumlah dan perhitungan
                    yang tercantum pada Perjanjian; dan
                  </li>
                  <li>
                    menentukan batas limit transaksi harian Pay & Receive yang dapat dilakukan Anda
                    <b>(“Limit Transaksi”)</b> dari waktu ke waktu. Dalam hal Mitra menentukan
                    adanya Limit Transaksi kepada Anda, Mitra akan melakukan pemberitahuan tertulis
                    sebelumnya kepada Anda sebelum diberlakukannya Limit Transaksi tersebut{' '}
                    <b>(“Pemberitahuan Limit Transaksi”)</b>. Penggunaan Anda secara berkelanjutan
                    atas Pay & Receive setelah diberikannya Limit Transaksi, dan tidak adanya
                    pemberitahuan tertulis untuk mengakhiri Pay & Receive dalam waktu 7 (tujuh) Hari
                    Kerja setelah diberikannya Pemberitahuan Limit Transaksi merupakan persetujuan
                    Anda atas Limit Transaksi yang ditetapkan, dan oleh karenanya Anda tidak akan
                    melakukan keberatan, tuntutan, gugatan, klaim atau dengan alasan apapun meminta
                    pertanggung jawaban kepada Mitra sehubungan dengan ditetapkannya Limit
                    Transaksi.
                  </li>
                  <li>
                    Dalam hal Dana Dari Pihak Ketiga sehubungan Layanan Penerimaan Dana tidak
                    dikirimkan oleh Pengirim, dikirimkan dengan jumlah yang tidak sesuai Perintah
                    Penerimaan Dana atau belum diterima oleh Mitra sampai dengan berakhirnya hari
                    sebagaimana Perintah Penerimaan Dana diterima oleh Mitra, dan tanpa kesalahan
                    atau kekeliruan oleh Mitra pada kejadian tersebut, Mitra tidak akan memiliki
                    tanggung jawab untuk mengirimkan uang dalam jumlah berapapun kepada Anda, dan
                    Anda tidak akan meminta Mitra bertanggung jawab atas hal tersebut.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>Biaya Layanan dan Pajak</b>
            <ol>
              <br />
              <li>
                {' '}
                Biaya Layanan adalah dalam jumlah sebagaimana ditentukan pada Perjanjian. Biaya
                Layanan dapat diubah berdasarkan kesepakatan bersama PARA PIHAK. Mitra dapat
                mengusulkan untuk dilakukannya diskusi atau negosiasi untuk perubahan Biaya Layanan,
                Mitra harus memberikan pemberitahuan tertulis 20 (dua puluh) hari kalender
                sebelumnya kepada Anda.
              </li>{' '}
            </ol>
          </li>
          <li>
            <b>Larangan</b> <br />
            <br />
            Sehubungan dengan penggunaan Layanan Pay & Receive, atau selama berlangsungnya interaksi
            Anda dengan Mitra, para pengguna Anda, para pengguna Mitra lainnya, atau pihak ketiga,
            Anda tidak akan:
            <ol>
              <li>
                {' '}
                Melanggar Perjanjian ini atau setiap kebijakan atau pengaturan lainnya yang telah
                disepakati oleh Anda dengan Mitra;
              </li>
              <li>
                {' '}
                Melanggar atau berupaya melanggar hukum, undang-undang, ordonansi, atau peraturan
                apapun yang berlaku termasuk tetapi tidak terbatas pada undang-undang yang terkait
                dengan pencucian uang dan pendanaan terorisme, dan peraturan yang terkait dengan
                sanksi;
              </li>
              <li>
                {' '}
                Melanggar hak cipta, paten, merek dagang, rahasia dagang atau hak kekayaan
                intelektual lainnya, atau hak publisitas atau privasi milik Mitra atau pihak ketiga;
              </li>
              <li>
                {' '}
                Menjual barang atau menyediakan layanan yang (atas diskresi mutlak Mitra tidak
                dikehendaki, meragukan atau tidak bermoral;
              </li>
              <li>
                {' '}
                Menggunakan layanan yang terkait dengan skema piramida atau ponzi, program matriks,
                skema “menjadi kaya dengan cepat” lainnya atau program pemasaran berjenjang
                (multi-level marketing) yang berisiko merugikan masyarakat (publik) atau pihak-pihak
                tertentu berdasarkan penilaian internal Mitra;
              </li>
              <li> Memberikan Informasi palsu, tidak akurat atau menyesatkan;</li>
              <li>
                {' '}
                Melakukan aktivitas dan/atau transaksi yang berpotensi menipu atau mencurigakan;
              </li>
              <li>
                {' '}
                Menolak bekerja sama dalam suatu penyelidikan atau memberikan konfirmasi identitas
                Anda atau Informasi apapun yang Anda berikan kepada Mitra;
              </li>
              <li>
                {' '}
                Mengendalikan suatu rekening yang bertautan dengan rekening lain yang telah
                melakukan salah satu Aktivitas-Aktivitas Terlarang ini;
              </li>
              <li>
                {' '}
                Menjalankan usaha Anda atau menggunakan Layanan dengan cara yang menimbulkan atau
                dapat menimbulkan keluhan, sengketa, Klaim, Reversal, Chargeback, biaya, denda,
                penalti dan tanggung jawab lainnya kepada Mitra, para pengguna Anda, Para Pengguna
                Mitra lainnya, atau pihak ketiga atau Anda;
              </li>
              <li>
                {' '}
                Mengungkapkan atau membagikan informasi lainnya milik Pengguna Mitra kepada pihak
                ketiga atau menggunakan Informasi tersebut untuk keperluan pemasaran, kecuali jika
                Anda memiliki izin untuk melakukannya;
              </li>
              <li>
                {' '}
                Mengirim surel yang tidak diminta (unsolicited email) kepada Pengguna Mitra atau
                menggunakan Layanan untuk menagih pembayaran untuk mengirim, atau membantu mengirim,
                surel yang tidak diminta kepada pihak ketiga;
              </li>
              <li>
                {' '}
                Mengambil tindakan yang memberikan beban yang tidak wajar atau beban besar yang
                tidak proporsional terhadap infrastruktur kami;
              </li>
              <li>
                Memfasilitasi virus, trojan horse, worms atau rutinitas pemrograman komputer lainnya
                yang dapat merusak, secara merugikan mengganggu, secara diam-diam menyadap atau
                mengambil alih sistem, data atau Informasi apapun;
              </li>
              <li>Menggunakan proxy anonim;</li>
              <li>
                Menggunakan robot, spider, perangkat automatik lainnya, atau proses manual untuk
                memantau atau menyalin platform/aplikasi kami tanpa izin tertulis kami sebelumnya;
              </li>
              <li>
                Menggunakan perangkat, perangkat lunak atau rutinitas apapun untuk menghindari robot
                exclusion header kami, atau mengganggu atau berupaya mengganggu platform/aplikasi
                kami atau Layanan Pay & Receive; atau
              </li>
              <li>
                Mengambil tindakan apapun yang dapat menyebabkan kami kehilangan salah satu layanan
                dari penyedia layanan Internet, pemroses pembayaran kami, atau pemasok lainnya.
              </li>{' '}
            </ol>
          </li>
          <li>
            <b>Service Level Agreement</b>
            <ol>
              <br />
              <li>
                {' '}
                Limit per transaksi atas Pay & Receive akan ditentukan oleh PT XIT sesuai dengan
                penilaian hasil tinjauan PT XIT dan/atau Mitra.
              </li>
              <li>
                Seluruh transaksi (kecuali transaksi RTGS) yang diterima Mitra akan diproses real
                time tanpa ada pembatasan waktu aktif (24 jam active) dengan ketentuan bahwa tidak
                ada gangguan pada rekanan. Untuk transaksi RTGS tunduk pada jam operasional RTGS
                pada bank.
              </li>
              <li>
                Dana Dari Pihak Ketiga sehubungan Layanan Penerimaan Dana dan Dana sehubungan dengan
                Layanan Pengiriman Dana akan dianggap telah diproses oleh Mitra ketika Anda
                mendapatkan Laporan Transaksi.
              </li>
            </ol>
          </li>
          <li>
            <b> Pengembalian Dana (Refund)</b>
            <ol>
              <br />
              <li>
                {' '}
                Jika Anda meminta pengembalian dana atas transaksi menggunakan Dashboard Fazz
                Business, maka PT XIT akan memproses pengembalian dana ke rekening terdaftar Anda
                berdasarkan permintaan dari Anda sepanjang disebabkan oleh kesalahan dan/atau
                kelalaian dari sistem Mitra dan/atau PT XIT. Anda mengakui bahwa Mitra dan/atau PT
                XIT tidak bertanggung jawab dalam hal terjadi fraud, kesalahan dan/atau kelalaian
                yang dilakukan oleh Anda dalam melakukan transaksi.
              </li>
              <li>
                Anda dapat mengajukan pengembalian dana dengan menyampaikan klaim kepada PT XIT
                selambat-lambatnya dalam waktu 7 (tujuh) hari kalender sejak tanggal transaksi
                dengan menyampaikan bukti pendukung yang cukup. Dalam hal demikian PT XIT akan
                memproses pengembalian dana ke Dashboard Fazz Business Anda berdasarkan hasil
                tinjauan PT XIT atas klaim tersebut.
              </li>
              <li>
                Jika Anda meminta pengembalian dana atas transaksi menggunakan instrumen pembayaran
                lain melalui Dashboard Fazz Business, maka ketentuan berikut berlaku. Klaim
                pengembalian dana tersebut dapat disampaikan langsung kepada Mitra Platform Resmi.
                PT XIT akan memproses pengembalian dana ke instrumen pembayaran Anda atau ke
                Dashboard Fazz Business Anda berdasarkan permintaan Mitra Platform Resmi. Selain itu
                Anda dapat menyampaikan klaim pengembalian dana PT XIT selambat-lambatnya dalam
                waktu 7 (tujuh) hari kalender sejak tanggal transaksi dengan menyampaikan bukti
                pendukung yang cukup. Dalam hal demikian PT XIT akan memproses pengembalian dana ke
                instrumen pembayaran Anda atau ke saldo uang elektronik Fazz Business Anda
                berdasarkan hasil tinjauan PT XIT atas klaim tersebut.
              </li>
            </ol>
          </li>
          <li>
            <b>Pemblokiran Akun</b>
            <ol>
              <br />
              <li>
                {' '}
                Atas permintaan Anda, PT XIT akan memblokir akun Fazz Business Anda dan penggunaan
                layanan Fazz Business dengan akun Fazz Business Anda. Selain itu, jika menurut
                pertimbangan PT XIT, terjadi hal-hal yang merugikan dan/atau dapat merugikan PT XIT
                atau Anda atau pihak ketiga lainnya, PT XIT juga akan melakukan pemblokiran
                tersebut. Namun demikian, PT XIT menyediakan jalur pengajuan keluhan bagi Anda untuk
                menghentikan pemblokiran tersebut.
              </li>
              <li>
                {' '}
                Pemblokiran akun dapat terjadi karena kejadian-kejadian berikut ini:
                <ol>
                  <li>
                    {' '}
                    Laporan Anda bahwa telepon selular Anda hilang atau telah dicuri atau diretas;
                  </li>
                  <li>
                    Perintah pemblokiran yang diterbitkan oleh institusi pemerintah atau moneter
                    atau berdasarkan perintah pengadilan;
                  </li>
                  <li>
                    Penggunaan akun Fazz Business dan/atau layanan Fazz Business Anda dengan cara
                    yang bertentangan dengan Ketentuan ini, ketentuan penggunaan Dashboard, syarat
                    dan ketentuan lain yang berlaku untuk fitur atau layanan lain dalam Dashboard,
                    Kebijakan Privasi PT XIT dan setiap ketentuan hukum dan peraturan
                    perundang-undangan yang berlaku, termasuk namun tidak terbatas pada penipuan,
                    penggunaan dokumen palsu;
                  </li>
                  <li>
                    Alasan lain yang merugikan Anda atau PT XIT, termasuk tindakan curang dan
                    tindakan penipuan; atau
                  </li>
                  <li>
                    ketika sistem PT XIT menangkap pola transaksi keuangan yang mencurigakan;
                    dan/atau
                  </li>
                  <li>
                    Kejadian-kejadian lain yang secara wajar dapat menjadi alasan pemblokiran akun
                    Anda.
                  </li>
                </ol>
              </li>
              <li>
                {' '}
                Jika akun Fazz Business Anda diblokir dan Anda memiliki bukti yang kuat bahwa tidak
                ada hal mencurigakan yang terjadi, Anda dapat mengajukan keluhan Anda sesuai dengan
                yang tertera pada Ketentuan ini. Setelah memeriksa keluhan Anda, PT XIT dapat
                menentukan untuk mengakhiri atau melanjutkan pemblokiran akun Fazz Business Anda.
                Pemblokiran tidak akan diteruskan secara tidak wajar apabila PT XIT memutuskan bahwa
                hal-hal yang mengakibatkan terjadinya pemblokiran telah berakhir.
              </li>
              <li>
                Setiap saat PT XIT memiliki keleluasaan penuh untuk menonaktifkan sementara atau
                secara permanen akses Anda ke akun Fazz Business Anda pada Mitra Platform Resmi
                manapun dan/atau sebagian atau semua fitur dan layanan Fazz Business pada Mitra
                Platform Resmi manapun dan penonaktifan tersebut akan PT XIT beritahukan kepada Anda
                dalam hal terjadi penonaktifan akses Anda ke akun Fazz Business Anda.
              </li>
            </ol>
          </li>
          <li>
            <b>Penolakan Transaksi</b> <br />
            <br />
            PT XIT dapat menolak transaksi yang dilakukan oleh Anda jika sistem PT XIT menangkap
            pola transaksi mencurigakan, jika Anda melanggar sebagian atau seluruh Ketentuan ini
            dan/atau melanggar ketentuan hukum dan peraturan perundang-undangan yang berlaku.
          </li>
          <li>
            <b> Biaya</b>
            <ol>
              <br />
              <li>
                {' '}
                Dalam penggunaan Layanan Fazz Business di Dashboard atau di Mitra Platform Resmi,
                maka Anda dapat dikenakan biaya, termasuk biaya pengisian (top-up), penarikan
                (withdrawal), transfer dan/atau biaya transaksi yang dilakukan melalui saluran Mitra
                Platform Resmi, pihak ketiga, sebagaimana yang dapat ditetapkan oleh PT XIT, Mitra
                Platform Resmi dan/atau pihak ketiga tersebut lainnya yang terkait, biaya atas
                pengaktifan kembali akun Fazz Business yang telah dinonaktifkan dan/atau biaya
                lainnya sebagaimana yang dapat dikenakan secara wajar. PT XIT akan memberitahukan
                kepada Anda apabila ada perbedaan jumlah biaya yang dikenakan oleh Fazz Business.
              </li>
              <li>
                Dalam penggunaan Layanan Dashboard Fazz Business di Dashboard atau di Mitra Platform
                Resmi, maka Anda dapat dikenakan biaya, termasuk namun tidak terbatas pada biaya
                transfer dan/atau biaya transaksi menggunakan instrumen pembayaran selain uang
                elektronik Fazz Business, sebagaimana yang dapat ditetapkan oleh PT XIT. PT XIT akan
                menginformasikan kepada Anda apabila ada perbedaan jumlah biaya yang dikenakan.
              </li>
              <li>
                Biaya lain yang dikenakan pihak ketiga selain PT XIT secara langsung kepada Anda
                tanpa melalui PT XIT, seperti biaya terkait instrumen pembayaran yang dikenakan oleh
                penerbit instrumen pembayaran Anda, merupakan tanggung jawab pihak ketiga tersebut
                dan berada di luar kendali PT XIT.
              </li>
              <li>
                Biaya layanan yang akan dibebankan kepada Anda adalah: <br />
                <table>
                  <tr>
                    <th style={{ width: '30%' }}>
                      Jenis Layanan <br /> Type of Services
                    </th>
                    <th scope="col" colSpan={2}>
                      Biaya per-Transaksi <br /> Fee per-Transaction
                    </th>
                    <th>Keterangan Remarks</th>
                  </tr>
                  <tr>
                    <td>
                      Layanan Pengiriman Dana (Pay & Receive) Disbursement Services (Pay & Receive)
                    </td>

                    <td>Transfer ke semua bank</td>
                    <td>Rp4.500,- / transaksi</td>
                  </tr>
                  <tr>
                    <td>
                      Layanan Penerimaan Dana (Pay & Receive) Accepting Payment Service (Pay &
                      Receive)
                    </td>
                    <td colSpan={2} style={{ padding: 0 }}>
                      <table>
                        <tr>
                          <td style={{ width: '52%' }}>Virtual Account semua bank</td>
                          <td>Rp4.500,- / transaksi</td>
                        </tr>
                        <tr>
                          <td>Retail (Alfamart, Alfamidi, Alfa Express, Lawson, DANDAN)</td>
                          <td>Rp6.000,- / transaksi</td>
                        </tr>
                        <tr>
                          <td>Retail (Indomaret)</td>
                          <td>Rp7.500,- / transaksi</td>
                        </tr>
                        <tr>
                          <td>QRIS </td>
                          <td>0,7% / transaksi</td>
                        </tr>
                        <tr>
                          <td>E-Money (ShopeePay) </td>
                          <td>1,5% / transaksi</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <p>*seluruh harga diluar PPN 11% / all fees exclude 11% VAT</p> <br />
                <p>
                  <b>Catatan / Notes:</b>
                </p>
              </li>
              <li>
                Biaya akan dikenakan langsung di setiap transaksi di akun Fazz Business. Di akhir
                bulan, PT XIT akan menerbitkan dan menyampaikan invoice berisikan detail pajak dan
                biaya. Anda wajib melakukan pembayaran selambat-lambatnya 14 (empat belas) hari
                kerja untuk melakukan pembayaran kepada PT XIT.
              </li>
            </ol>
          </li>
          <li>
            <b> Tanggung Jawab Anda</b>
            <br />
            <br />
            Sebagai tambahan dari tanggung jawab Anda yang diatur dalam bagian lain dari Ketentuan
            ini, Anda bertanggung jawab atas:
            <ol>
              <li>
                {' '}
                Keamanan dan kepemilikan telepon seluler atau perangkat lainnya dari Anda yang
                digunakan untuk mengakses akun Fazz Business Anda termasuk dengan secara wajar
                menjaga dan menyediakan sumber penyimpanan yang cukup untuk mencegah kegagalan atau
                gangguan atas setiap proses layanan Fazz Business yang disebabkan oleh kegagalan
                fungsi telepon seluler atau perangkat lainnya dari Anda;
              </li>
              <li>
                Kemanan dan validitas nomor telepon seluler Anda yang Anda daftarkan untuk mengakses
                akun Fazz Business, karenanya PT XIT tidak bertanggung jawab atas setiap klaim yang
                timbul jika nomor telepon seluler yang Anda daftarkan dikuasai oleh pihak lain,
                menjadi tidak aktif berdasarkan kebijakan Mitra telekomunikasi dan/atau menjadi
                dormant;
              </li>
              <li>
                Kerahasiaan Kode Sandi Sekali Pakai (OTP) yang dikirim kepada Anda, Nomor
                Identifikasi Pribadi (PIN) yang ditetapkan oleh Anda dan/atau setiap kode verifikasi
                lainnya yang sepatutnya dikuasai hanya oleh Anda;
              </li>
              <li>
                Setiap transaksi melalui akun Fazz Business Anda, Anda wajib memastikan bahwa
                transaksi tersebut adalah sah dan tidak melanggar ketentuan pada Syarat dan
                Ketentuan Pay & Receive ini. Dalam hal ditemukan adanya pelanggaran, maka PT XIT
                berhak untuk melakukan tindakan lanjut termasuk namun tidak terbatas pada melakukan
                pemblokiran akun untuk sementara dan/atau tindakan lain yang dianggap perlu oleh PT
                XIT;
              </li>
              <li>
                Anda wajib berhati-hati ketika menggunakan akun Fazz Business Anda dan/atau layanan
                Fazz Business, termasuk ketika memilih menu transaksi, tujuan pembayaran, memasukkan
                nilai pembayaran dan lain sebagainya. Anda akan memastikan bahwa Anda hanya akan
                mengikuti instruksi transaksi yang diterbitkan oleh Fazz Business sesuai transaksi
                yang Anda setujui; dan
              </li>
              <li>
                Setiap kerugian, klaim, tuntutan dan/atau biaya yang timbul, termasuk dari pihak
                lain kepada PT XIT, yang timbul karena pelanggaran terhadap Ketentuan ini, kelalaian
                Anda dalam menggunakan akun Fazz Business, layanan Fazz Business, fitur Fazz
                Business dalam Dashboard dan/atau Mitra Platform Resmi manapun, termasuk yang
                dikarenakan oleh ketidakhati-hatian dalam bertindak, kecerobohan atau penyalahgunaan
                akun Fazz Business dan/atau layanan Fazz Business oleh Anda atau pihak lain yang
                menggunakan akun Fazz Business Anda.
              </li>
            </ol>
          </li>
          <li>
            <b>Tanggung Jawab PT XIT</b>
            <ol>
              <br />
              <li>
                {' '}
                PT XIT tidak bertanggung jawab atas kerugian tidak langsung dan immateril, termasuk
                biaya, bunga, kerusakan atau kerugian dalam bentuk atau jenis apapun (termasuk
                cidera pribadi, tekanan emosional dan hilangnya data, barang, pendapatan, laba,
                penggunaan atau keuntungan ekonomis lainnya) yang Anda atau pihak ketiga alami.
              </li>
              <li>
                PT XIT tidak bertanggung jawab atas setiap kerugian, termasuk biaya, bunga,
                kerusakan atau kerugian dalam bentuk atau jenis apapun (termasuk cidera pribadi,
                tekanan emosional dan hilangnya data, barang, pendapatan, laba, penggunaan atau
                keuntungan ekonomis lainnya) yang Anda atau pihak ketiga alami karena kesalahan Anda
                atau pihak ketiga selain PT XIT, termasuk pelaku usaha dan mitra yang menyediakan
                kanal pengisian (top-up) dan penarikan (withdraw).
              </li>
              <li>
                PT XIT tidak bertanggung jawab atas setiap kerugian, termasuk biaya, bunga,
                kerusakan atau kerugian dalam bentuk atau jenis apapun (termasuk cidera pribadi,
                tekanan emosional dan hilangnya data, barang, pendapatan, laba, penggunaan atau
                keuntungan ekonomis lainnya) yang terjadi karena kesalahan, kelalaian, dan/atau
                kegagalan sistem dari Mitra Platform Resmi, legalitas barang, fraud baik yang
                dilakukan oleh Pengguna atas Anda atau Anda, dan/atau layanan yang disediakan dalam
                Mitra Platform Resmi, juga keselamatan dan keamanan transaksi Anda yang terjadi di
                Mitra Platform Resmi.
              </li>
              <li>
                PT XIT hanya bertanggung jawab atas kerugian langsung, yakni jumlah yang senyatanya
                dibayar, yang timbul atau sehubungan dengan penggunaan akun Fazz Business Anda, yang
                dapat dibuktikan baik oleh Anda atau PT XIT bahwa pelanggaran atau kesalahan atas
                Ketentuan ini telah terjadi oleh PT XIT. Dalam setiap hal apapun, maksimal tanggung
                jawab PT XIT hanya terbatas pada jumlah saldo terakhir Fazz Business Anda yang ada
                di sistem PT XIT atau Rp. 10.000.000 (sepuluh juta Rupiah), mana yang lebih rendah.
              </li>
            </ol>
          </li>
          <li>
            <b>Ganti Rugi</b>
            <br /> <br />
            <ol>
              <li>
                Dengan mendaftar dan menggunakan akun Fazz Business dan layanan Fazz Business, Anda
                setuju untuk membebaskan PT XIT, pihak yang memberi lisensi kepada PT XIT, afiliasi
                PT XIT dan setiap pegawai, direktur, komisaris, karyawan, wakil dan agen PT XIT,
                dari dan terhadap setiap dan seluruh klaim, biaya, kerusakan, kerugian, tanggung
                jawab dan pengeluaran (termasuk biaya advokat) yang timbul atau sehubungan dengan:
                <ol>
                  <li>
                    {' '}
                    Pelanggaran Anda atas Ketentuan ini atau ketentuan hukum dan peraturan
                    perundang-undangan yang berlaku, baik yang direferensikan atau yang tidak
                    direferensikan dalam Ketentuan ini;
                  </li>
                  <li>
                    Pelanggaran Anda atas hak pihak ketiga lainnya sehubungan dengan Fazz Business
                    dan/atau layanan Fazz Business;
                  </li>
                  <li>
                    Setiap penggunaan atau penyalahgunaan Fazz Business dan/atau layanan Fazz
                    Business oleh Anda atau pihak lain menggunakan akun Anda;
                  </li>
                  <li>
                    Setiap kesalahan yang dilakukan oleh pihak selain PT XIT, baik yang disengaja
                    maupun berupa kelalaian; atau
                  </li>
                  <li>
                    Pemblokiran akun Fazz Business Anda berdasarkan hal-hal yang diatur dalam
                    Ketentuan ini.
                  </li>
                </ol>
              </li>
              <li>
                Ketentuan ganti rugi ini akan tetap berlaku walaupun akun Fazz Business Anda
                ditutup.
              </li>
            </ol>
          </li>
          <li>
            <b> Hukum yang Berlaku dan Penyelesaian Perselisihan</b>
            <br /> <br />
            <ol>
              <li>
                {' '}
                Ketentuan Khusus Layanan Pay & Receive ini tunduk pada dan ditafsirkan menurut hukum
                Negara Republik Indonesia.
              </li>
              <li>
                Para Pihak sepakat bahwa setiap sengketa atau perselisihan yang timbul sehubungan
                dengan perjanjian dan/atau Ketentuan Khusus Layanan Pay & Receive ini, antara lain
                perihal keberadaan, keabsahan, penafsiran, atau pelaksanaannya (“Perselisihan”) akan
                diselesaikan secara musyawarah mufakat terlebih dahulu antara PARA PIHAK dalam
                jangka waktu 30 (tiga puluh) Hari Kalender sejak tanggal diterimanya pemberitahuan
                tertulis oleh salah satu Pihak dari Pihak lainnya mengenai timbulnya Perselisih an.
              </li>
              <li>
                Para Pihak sepakat bahwa dalam hal Perselisihan tidak dapat diselesaikan melalui
                musyawarah mufakat dalam jangka waktu sebagaimana disebutkan pada ayat (2) di atas,
                maka Perselisihan akan diselesaikan dan diputus melalui Pengadilan Negeri Jakarta
                Selatan.
              </li>
            </ol>
          </li>
        </div>
      </ol>
      <br />
    </>
  )
}
