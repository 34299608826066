import camelCase from 'camel-case'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

type OptionsType = {
  type?: 'alphanumeric'
  characters?: string
}

const StringMap = {
  alphanumeric: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
}

export default {
  convertKeysToCamelCase,

  capitalize: (val: string) => `${val.charAt(0).toUpperCase()}${val.slice(1)}`,

  truncate: (string: string, stringLength: number) => {
    // Kgobp94xUnTL6FsmI3XInFB73e -> Kgobp94xUnTL6Fsm...
    if (string.length > stringLength) {
      return `${string.substring(0, stringLength)}...`
    }
    return string
  },

  generateRandomString: (length: number, options?: OptionsType) => {
    const allowableCharacters = options?.characters || StringMap[options?.type || 'alphanumeric']

    let result = ''
    for (let i = 0; i < length; i += 1) {
      result += allowableCharacters.charAt(Math.floor(Math.random() * allowableCharacters.length))
    }
    return result
  },

  isInputSecure: (value: string): boolean => {
    const regex = /^[a-zA-Z0-9!#$%&'()*+,\-./:;=?@[\]^_`{|}~ ]*$/
    return regex.test(value)
  },

  formatAccountNumberWithDashes: (value: string) => {
    // 10000100000010176 -> 1000-0100-0000-1017-6
    if (value.length === 17) {
      const splitAry = value.match(/.{1,4}/g) // split every 4 character into array ['1000', '0100', '0000', '1017', '6']
      if (splitAry === null) return value
      return splitAry.join('-') // join first 5 element with '-'
    }
    return value
  },

  formatBankAccountName: (account: {
    bankAbbrev: string
    accountNo?: string
    virtualAccountNumber?: string
  }) => {
    const formattedAccountName = get(account, 'bankAbbrev', '-').toUpperCase()
    const isVirtualAccountExists = !isEmpty(account.virtualAccountNumber)
    const isCommonAccountExists = !isEmpty(account.accountNo)
    let accountNumber
    let accountName = formattedAccountName

    if (isVirtualAccountExists) {
      accountNumber = account.virtualAccountNumber
      accountName = `VA ${formattedAccountName}`
    } else if (isCommonAccountExists) {
      accountNumber = account.accountNo
    } else {
      accountNumber = '-'
    }

    return { accountName, accountNumber }
  },
}

function isObject<T>(obj: T): obj is T & Record<string, unknown> {
  return !!obj && typeof obj === 'object' && !Array.isArray(obj)
}

function isArray<T>(obj: T): obj is T & Array<unknown> {
  return Array.isArray(obj)
}

function convertKeysToCamelCase<T>(obj: T): T {
  // Handing for objects
  if (isObject(obj)) {
    const result: Record<string, unknown> = {}

    Object.keys(obj).forEach((key) => {
      const camelKey = camelCase(key)
      result[camelKey] = convertKeysToCamelCase((obj as Record<string, unknown>)[key])
    })

    return result as T
  }

  // Handling for arrays
  if (isArray(obj)) {
    return (obj as unknown[]).map((item) => convertKeysToCamelCase(item)) as unknown as T
  }

  // Return non-object values unchanged
  return obj
}
