import { fdsTheme } from 'fds3-src/themes/theme-fds3'
import { FormItem, Select } from 'formik-antd'
import styled from 'styled-components'

const { spacing, colors, radius, fontStyles, fontWeight } = fdsTheme

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    .ant-select-selection-item {
      display: flex;
      ${(props) =>
        props.mode === 'multiple' &&
        ` color: ${colors.active};
      ${fontStyles.bodyMedium};
      border-color: ${colors.active};
      background: ${colors.surfaceSubdueInformation};
      border-radius: ${radius.lg};
      padding: 0 ${spacing.md};
      font-weight: ${fontWeight.bold};
      height: 32px;
      line-height: 32px;`}
      margin: ${spacing.xxs} ${spacing.xxs}
    }
  }
`

export const StyledFormItem = styled(FormItem)`
  &.ant-form-item {
    margin-bottom: 0;
  }

  // error state + error message
  &.ant-form-item-has-error {
    .ant-select-selector {
      border-color: ${colors.critical} !important;
    }

    .ant-form-item-explain-error {
      margin-top: ${spacing.xs};
      margin-left: ${spacing.md};
      ${fontStyles.bodyMedium}
      color: ${colors.critical};
      text-align: left;
    }
  }

  // helper text
  .ant-form-item-extra {
    margin-top: ${spacing.xs};
    ${fontStyles.bodySmall}
    text-align: left;
    color: ${colors.helperInput};
  }

  // we want to hide if there is an error message - May be problematic if we want to show more errors in the future.
  .ant-form-item-control div:nth-of-type(3) {
    display: none;
  }
`
