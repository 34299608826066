import { SVGProps } from 'react'

function SvgNavTransactions(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 10.71a1.29 1.29 0 0 1 .256 2.556A1.3 1.3 0 0 1 10.7 12a1.3 1.3 0 0 1 1.29-1.29H12Zm-.01-1.5a2.8 2.8 0 1 0 .52 5.546A2.79 2.79 0 0 0 12 9.23l-.01-.02ZM8.73 15.98a.71.71 0 1 0 0-1.42.71.71 0 0 0 0 1.42ZM15.26 9.45a.71.71 0 1 0 0-1.42.71.71 0 0 0 0 1.42Z"
        fill="currentColor"
      />
      <path
        d="M13.23 5.16a2.48 2.48 0 0 1 1.77.73L18.1 9a2.501 2.501 0 0 1 0 3.54l-5.57 5.57a2.488 2.488 0 0 1-3.54 0L5.88 15a2.5 2.5 0 0 1 0-3.53l5.58-5.58a2.491 2.491 0 0 1 1.77-.73Zm0-1.5a4 4 0 0 0-2.83 1.17l-5.58 5.58a4 4 0 0 0 0 5.65l3.11 3.11a4 4 0 0 0 5.66 0l5.57-5.57a4 4 0 0 0 0-5.66l-3.11-3.11a4 4 0 0 0-2.82-1.17Z"
        fill="currentColor"
      />
      <path
        d="M2.78 17.9a.739.739 0 0 1-.53-.22 6.129 6.129 0 0 1 0-8.68l5.48-5.46h-2a.75.75 0 1 1 0-1.5h3.81a.75.75 0 0 1 .53 1.28l-6.76 6.76a4.64 4.64 0 0 0 0 6.54.741.741 0 0 1 0 1.06.73.73 0 0 1-.53.22ZM18.25 22h-3.79a.75.75 0 0 1-.53-1.28l6.76-6.76a4.64 4.64 0 0 0 0-6.54.75.75 0 1 1 1.06-1.06 6.13 6.13 0 0 1 0 8.66l-5.48 5.48h2a.75.75 0 0 1 0 1.5h-.02Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgNavTransactions
