import * as React from 'react'

function SvgCustomerSupport(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.049 28A1.951 1.951 0 0028 26.049V15c0-7.18-5.82-13-13-13S2 7.82 2 15v7.167a2 2 0 002 2h3.333a2 2 0 002-2V17a2 2 0 00-2-2H5C5 9.477 9.477 5 15 5s10 4.477 10 10h-2a2 2 0 00-2 2v5a2 2 0 002 2h2v1h-6.167a1.5 1.5 0 000 3h7.216z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCustomerSupport
