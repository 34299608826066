import type { Moment } from '~/types'

export type NewTransaction = {
  referenceId: string
  transactionId: string
  createdDate: string
  settledDate: string
  transactionType: string
  amount: string
  availableBalance: string
  description: string
  method: string
}

// TODO: will replace it with the new one after production testing by PM
export type Transaction = {
  referenceId: string
  transactionId: string
  createdDate: string
  settledDate: string
  transactionType: string
  amount: { prefix: string; postfix: string }
  availableBalance: string
  description: {
    accountHolderName: string
    descriptionDetail: { type: string; direction: string; data: string | { type: string } }
    type: string
    data: string
    direction: string
    internalTransactionType?: string
    paymentId?: string
  }
  method: string
}

export type TransactionMethod = 'payNow' | 'bankTransfer'

export type FilterValuesObject = {
  [filterKey: string]:
  | Moment
  | string[]
  | number[]
  | string
  | number
  | NewTransaction[]
  | Transaction[]
  | boolean
  | undefined
}

export type FilterValues = {
  startDate: Moment | string
  endDate: Moment | string
  currentPage: number
  pageLimit: number
  searchString: string
  transactionTypes: string[]
  sortType: string
  isTransactionLoading: boolean
  transactions: Transaction[]
  newTransactions: NewTransaction[]
  totalCount: number
  methodTypes: string[]
  [key: string]: FilterValuesObject[keyof FilterValuesObject]
}

export type TSgdAccountVa = {
  accountNumber: string
  bankName: string
  accountHolderName: string
}

export type TPaynowQr = {
  imageBase64: string
  VpaNumber: string
}

export enum SGDAccountActions {
  GET_NEW_TRANSACTIONS = 'SGD_ACCOUNT_ACTIONS.GET_NEW_TRANSACTIONS',
  GET_TRANSACTIONS = 'SGD_ACCOUNT_ACTIONS.GET_TRANSACTIONS',
  SET_IS_TRANSACTION_LOADING = 'SGD_ACCOUNT_ACTIONS.SET_IS_TRANSACTION_LOADING',
  SET_FILTERS = 'SGD_ACCOUNT_ACTIONS.SET_FILTERS',
  SET_TRANSACTIONS_FILTER_OPTIONS = 'SGD_ACCOUNT_ACTIONS.SET_TRANSACTIONS_FILTER_OPTIONS',
  SET_IS_WALLET_BALANCE_LOADING = 'SGD_ACCOUNT_ACTIONS.SET_IS_WALLET_BALANCE_LOADING',
  SET_WALLET_BALANCE = 'SGD_ACCOUNT_ACTIONS.SET_WALLET_BALANCE',
  SET_ACCOUNT_DETAILS_LOADING = 'SGD_ACCOUNT_ACTIONS.SET_ACCOUNT_DETAILS_LOADING',
  SET_SGD_ACCOUNT_VA = 'SGD_ACCOUNT_ACTIONS.SET_SGD_ACCOUNT_VA',
  SET_PAYNOW_QR = 'SGD_ACCOUNT_ACTIONS.SET_PAYNOW_QR',
  RESET_FILTERS = 'SGD_ACCOUNT_ACTIONS.RESET_FILTERS',
}

type GetNewTransactions = {
  type: typeof SGDAccountActions.GET_NEW_TRANSACTIONS
  newTransactions: NewTransaction[]
  totalCount: number
}

type GetTransactions = {
  type: typeof SGDAccountActions.GET_TRANSACTIONS
  transactions: Transaction[]
  totalCount: number
}

type SetIsTransactionLoading = {
  type: typeof SGDAccountActions.SET_IS_TRANSACTION_LOADING
  isTransactionLoading: boolean
}

type SetFilters = {
  type: typeof SGDAccountActions.SET_FILTERS
  filterValuesObject: FilterValuesObject
}

type ResetFilters = {
  type: typeof SGDAccountActions.RESET_FILTERS
}

type SetIsWalletBalanceLoading = {
  type: typeof SGDAccountActions.SET_IS_WALLET_BALANCE_LOADING
  isWalletBalanceLoading: boolean
}

type SetWalletBalance = {
  type: typeof SGDAccountActions.SET_WALLET_BALANCE
  availableBalance: string
  holdingBalance: string
}

type SetAccountDetailsLoading = {
  type: typeof SGDAccountActions.SET_ACCOUNT_DETAILS_LOADING
  isAccountDetailsLoading: boolean
}

type SetSgdAccountVa = {
  type: typeof SGDAccountActions.SET_SGD_ACCOUNT_VA
  sgdAccountVa: TSgdAccountVa
}

type SetPaynowQr = {
  type: typeof SGDAccountActions.SET_PAYNOW_QR
  paynowQr: TPaynowQr
}

export type ActionTypes =
  | GetTransactions
  | SetIsTransactionLoading
  | SetFilters
  | ResetFilters
  | SetIsWalletBalanceLoading
  | SetWalletBalance
  | SetAccountDetailsLoading
  | SetSgdAccountVa
  | SetPaynowQr
  | GetNewTransactions
