import type { Reducer } from 'redux'
import type { ActionTypes, State } from './types'
import { VerificationActions, ResidencyStatusType } from './types'

const initialState = {
  userDetails: {
    fullName: undefined,
    dateOfBirth: undefined,

    nationality: undefined,
    gender: undefined,
    addressLine1: undefined,
    addressLine2: undefined,
    city: undefined,
    countryOfResidence: undefined,
    postalCode: undefined,
    idNumber: undefined,
    expiryDate: undefined,
    proofOfAddressFile: undefined,
    idFrontFile: undefined,
    idBackFile: undefined,
  },
  myInfoOnlyDetails: {
    race: undefined,
    residentialStatus: undefined,
    aliasName: undefined,
    passStatusLastUpdated: undefined,
    state: undefined,
    withValidAddress: undefined,
    withValidExpiryDate: undefined,
    error: undefined,
  },
  immigrationStatus: undefined,
  residencyStatus: undefined,
  isIdTypeSgPr: true,
  isResidencyNonSg: false,
  isInternationalOnboarding: false,
  flow: undefined,
  error: '',
  screen: undefined,
  kycRejectedReason: '',
  retrievedDetails: undefined,
  controller: undefined,
}

export const verification: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case VerificationActions.SET_CONTROLLER: {
      return {
        ...state,
        controller: action.controller,
      }
    }

    case VerificationActions.SET_IMMIGRATION_STATUS: {
      return {
        ...state,
        immigrationStatus: action.status,
        isInternationalOnboarding: action.status !== 'sg_pr' && state.isResidencyNonSg,
      }
    }

    case VerificationActions.SET_RESIDENCY_STATUS: {
      return {
        ...state,
        residencyStatus: action.status,
        isResidencyNonSg: action.status === ResidencyStatusType.NOT_LIVING_IN_SG,
        isInternationalOnboarding:
          state.immigrationStatus !== 'sg_pr' &&
          action.status === ResidencyStatusType.NOT_LIVING_IN_SG,
      }
    }

    case VerificationActions.SET_FLOW: {
      return {
        ...state,
        flow: action.flow,
      }
    }

    case VerificationActions.SET_USER_DETAILS: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          [action.key]: action.value,
        },
      }
    }

    case VerificationActions.UPDATE_USER: {
      const errorMsg =
        action.error === 'Same request is still processing'
          ? 'Please try again in a minute as we are still processing.'
          : action.error
      return {
        ...state,
        error: errorMsg,
      }
    }

    case VerificationActions.SET_IS_ID_TYPE_SG_PR: {
      return {
        ...state,
        isIdTypeSgPr: action.isIdTypeSgPr,
      }
    }

    case VerificationActions.SET_SCREEN: {
      return {
        ...state,
        screen: action.screen,
      }
    }

    case VerificationActions.SET_MULTIPLE_USER_DETAILS: {
      return {
        ...state,
        userDetails: action.userDetails,
      }
    }

    case VerificationActions.SET_KYC_REJECTED_REASON: {
      return {
        ...state,
        kycRejectedReason: action.kycRejectedReason,
      }
    }

    case VerificationActions.SET_RETRIEVED_DETAILS: {
      return {
        ...state,
        retrievedDetails: action.retrievedDetails,
      }
    }

    case VerificationActions.SET_ERROR: {
      const errorMsg =
        action.error === 'Same request is still processing'
          ? 'Please try again in a minute as we are still processing.'
          : action.error
      return {
        ...state,
        error: errorMsg,
      }
    }

    case VerificationActions.SET_MY_INFO_ONLY_DETAILS: {
      return {
        ...state,
        myInfoOnlyDetails: action.myInfoOnlyDetails,
      }
    }

    default:
      return state
  }
}
