import { Label } from 'fds3-src/themes/Typography'
import { fdsTheme } from 'fds3-src/themes/theme-fds3'
import { Select as AntdSelect } from 'antd'
import { ArrowDown } from '@fazz/design-system-icons'
import { BaseSelectStyles, StyledSelect, dropdownStyle } from './Select.styles'
import type { SelectProps } from './Select.types'

const { spacing, colors } = fdsTheme

export function Select({
  label,
  children,
  style,
  selectStyles,
  isDisabled,
  placeholder,
  defaultValue,
  onChange,
  className,
  mode,
}: SelectProps) {
  return (
    <div style={style} className={className}>
      {label && (
        <Label
          size="md"
          style={{
            display: 'flex',
            color: colors.labelInput,
            marginBottom: spacing.xs,
          }}
        >
          {label}
        </Label>
      )}
      <StyledSelect
        placeholder={placeholder}
        style={selectStyles}
        dropdownClassName={dropdownStyle}
        disabled={isDisabled}
        defaultValue={defaultValue}
        onChange={onChange}
        mode={mode}
        suffixIcon={<ArrowDown width={16} height={16} color={colors.onNeutral} />}
      >
        {children}
      </StyledSelect>
      <BaseSelectStyles />
    </div>
  )
}

Select.Option = AntdSelect.Option
Select.displayName = 'SelectFds3'
