import * as React from 'react'

function SvgSparrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#sparrow_svg__clip0)">
        <path d="M15.582 9.567a.914.914 0 100-1.828.914.914 0 000 1.828z" fill="#fff" />
        <path
          d="M19.073 9.604c0 2.307-1.105 4.683-2.697 6.038 1.002 3.068 2.684 4.454 6.625 5.82 2.699.935 6.262 1.258 9.89.906-2.84 3.895-5.436 6.455-7.79 8.046-.92.621-.913 2.265-2.256 2.413-6.292.668-10.512-2.216-13.095-4.286C4.6 24.41 2.398 19.3 2.398 13.224c0-5.368 4.358-10.852 9.06-10.852 4.068 0 7.615 3.157 7.615 7.232z"
          fill="url(#sparrow_svg__paint0_linear)"
        />
        <path
          d="M16.377 15.642s-2.36 2.183-2.536 6.9c-.177 4.718 1.415 10.264 10.793 10.676 3.48-.176 7.491-2.24 8.966-4.6-5.545-.59-13.035-2.595-17.223-12.976z"
          fill="url(#sparrow_svg__paint1_linear)"
        />
        <path d="M15.623 9.657a.94.94 0 100-1.88.94.94 0 000 1.88z" fill="#fff" />
        <path
          d="M19.072 9.604c0 .052 0 .105-.006.157.4-.566 1.359-1.535 3.624-2.552a8.472 8.472 0 00-4.87-1.565 6.88 6.88 0 011.252 3.96z"
          fill="url(#sparrow_svg__paint2_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="sparrow_svg__paint0_linear"
          x1={7.223}
          y1={17.632}
          x2={25.196}
          y2={17.663}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009DFF" />
          <stop offset={0.82} stopColor="#0076FF" />
          <stop offset={1} stopColor="#006EFF" />
        </linearGradient>
        <linearGradient
          id="sparrow_svg__paint1_linear"
          x1={13.828}
          y1={24.43}
          x2={33.6}
          y2={24.43}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFCF00" />
          <stop offset={0.54} stopColor="#FFB500" />
          <stop offset={1} stopColor="#FFA700" />
        </linearGradient>
        <linearGradient
          id="sparrow_svg__paint2_linear"
          x1={17.816}
          y1={7.702}
          x2={22.686}
          y2={7.702}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFCA00" />
          <stop offset={0.75} stopColor="#FFB900" />
          <stop offset={1} stopColor="#FA0" />
        </linearGradient>
        <clipPath id="sparrow_svg__clip0">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgSparrow
