export type TopupRequest = {
  id: string
  createdDate: string
  expiredDate: string
  status: string
  amount: string
  bankAccount: { accountNo?: string; bankAbbrev: string; virtualAccountNumber?: string }
  recipientName: string
  recipientAccountNo: string
  recipientBankName: string
  referenceNumber?: string
  uniqueAmount?: number
  displayName?: string
}

export type TopupRequestData = {
  id: number
  createdDate: string
  expiredDate: string
  status: string
  amount: string
  bankAccount: { bankName: string; accountNo: string; bankAbbrev: string }
  transferInfo: TransferInfo
}

export type XfersAcceptContract = {
  id: string
  status: string
  amount: string
  currency: string
  description: string
  expirationTime: string
  fees: string
  referenceId: string
  createdAt: string
  paymentMethod: {
    accountHolderName: string
    accountNo: string
    bankType: string
    destinationAccountId: string
    type: string
    uniqueAmount: number
    customerRef?: string
    displayName?: string
    virtualAccountNumber?: string
  }
}

export type TransferInfo = {
  recipientName: string
  recipientBankName: string
  recipientAccountNo: string
  referenceNumber: string
}

export enum DepositRequestsActions {
  SET_IS_LOADING = 'DepositRequestsActions.SET_IS_LOADING',
  GET_TOPUP_REQUESTS = 'DepositRequestsActions.GET_TOPUP_REQUESTS',
  SET_CURRENT_PAGE = 'DepositRequestsActions.SET_CURRENT_PAGE',
}

type SetIsLoading = {
  type: typeof DepositRequestsActions.SET_IS_LOADING
  isLoading: boolean
}

type GetTopupRequests = {
  type: typeof DepositRequestsActions.GET_TOPUP_REQUESTS
  topupRequests: TopupRequest[]
  totalCount: number
}

type SetCurrentPage = {
  type: typeof DepositRequestsActions.SET_CURRENT_PAGE
  currentPage: number
}

export type ActionTypes = SetIsLoading | GetTopupRequests | SetCurrentPage
