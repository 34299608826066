import * as React from 'react'

function SvgPosb(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#POSB_svg__clip0)" fillRule="evenodd" clipRule="evenodd">
        <path
          d="M53.584 18.624v2.547h2.747c.75 0 1.59-.287 1.59-1.274 0-1.067-1.146-1.273-1.925-1.273h-2.412zm0-4.581v2.478h2.335c.734 0 1.498-.33 1.498-1.238 0-.94-.857-1.24-1.605-1.24h-2.228zm-1.98 9.246V12.015h3.934c1.897 0 3.959.415 3.959 2.866 0 1.259-.648 2.118-1.763 2.486v.03c1.42.191 2.267 1.29 2.267 2.755 0 2.358-2.139 3.137-4.064 3.137h-4.332zm-19.232-5.637c0 2.102 1.293 3.63 3.27 3.63 1.976 0 3.27-1.528 3.27-3.63s-1.294-3.63-3.27-3.63c-1.977 0-3.27 1.528-3.27 3.63zm-2.407 0c0-3.614 2.392-5.924 5.676-5.924 3.285 0 5.678 2.31 5.678 5.924 0 3.615-2.393 5.923-5.678 5.923-3.284 0-5.676-2.308-5.676-5.923zm-7.11-3.608v3.363h2.116c.93 0 1.857-.533 1.857-1.673 0-1.585-1.248-1.69-2.263-1.69h-1.71zm-2.343 9.245V12.015h4.15c2.484 0 4.545.813 4.545 3.72 0 2.869-1.908 3.75-4.426 3.75h-1.925v3.804h-2.344zm29.153-11.274v2.04H45.37c-.712 0-1.084.333-1.084.857 0 .492.18.712.656.966 1.43.762 5.148.602 5.148 4.198 0 1.84-1.154 3.21-3.384 3.21l-4.57.003V21.17h4.224c.346 0 1.347.009 1.347-1.07 0-.891-.588-1.078-1.246-1.31-1.61-.568-4.384-.69-4.384-3.576 0-1.79 1.242-3.2 3.222-3.2h4.366z"
          fill="#F90"
        />
        <path
          d="M11.073 23.65a1.52 1.52 0 011.524-1.517c.841 0 1.525.68 1.525 1.517 0 .838-.684 1.517-1.525 1.517a1.52 1.52 0 01-1.524-1.517zM4.207 10.973a4.534 4.534 0 01-.218-1.397c0-2.528 2.06-4.577 4.598-4.577 2.54 0 4.6 2.05 4.6 4.577 0 .488-.077.956-.22 1.397 2.357.198 4.208 2.164 4.208 4.56 0 2.528-2.059 4.577-4.599 4.577a4.606 4.606 0 01-2.223-.569v10.05H8.586a1.764 1.764 0 01-1.766-1.744v-8.306a4.593 4.593 0 01-2.22.57c-2.54 0-4.6-2.05-4.6-4.578 0-2.396 1.851-4.362 4.207-4.56zm6.688 4.56c0 .925.754 1.674 1.681 1.674.928 0 1.682-.75 1.682-1.674 0-.923-.754-1.673-1.682-1.673-.927 0-1.68.75-1.68 1.673zM6.907 9.576c0 .925.752 1.673 1.68 1.673.929 0 1.681-.748 1.681-1.673 0-.923-.752-1.673-1.681-1.673-.928 0-1.68.75-1.68 1.673zm-3.989 5.957c0 .925.753 1.674 1.681 1.674s1.68-.75 1.68-1.674c0-.923-.752-1.673-1.68-1.673-.928 0-1.68.75-1.68 1.673zM11.073 27.4a1.52 1.52 0 011.524-1.517c.841 0 1.525.68 1.525 1.517a1.52 1.52 0 01-1.525 1.516 1.52 1.52 0 01-1.524-1.516z"
          fill="#012081"
        />
      </g>
      <defs>
        <clipPath id="POSB_svg__clip0">
          <path fill="#fff" d="M0 0h60v35H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgPosb
