import { SVGProps } from 'react'

function CopyToClipboardFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.3098 20.4898C14.6619 21.0062 13.8583 21.2881 13.0298 21.2898H8.25977C6.80189 21.2871 5.40448 20.7068 4.3736 19.676C3.34272 18.6451 2.76241 17.2477 2.75977 15.7898V9.59979C2.76243 9.02832 2.89637 8.4651 3.15125 7.95361C3.40613 7.44212 3.77513 6.99603 4.22977 6.64979V6.44978C4.23136 5.886 4.3053 5.32475 4.44977 4.77979C3.50474 5.18292 2.69877 5.85449 2.13174 6.71127C1.5647 7.56804 1.26152 8.57237 1.25977 9.59979V15.7898C1.25977 17.6463 1.99726 19.4268 3.31002 20.7395C4.62277 22.0523 6.40325 22.7898 8.25977 22.7898H12.9998C13.8547 22.7903 14.6966 22.5808 15.4516 22.1798C16.2066 21.7787 16.8515 21.1984 17.3298 20.4898H15.3098Z"
        fill="#0C45E1"
      />
      <path
        d="M17.5 1.21H11C10.3097 1.20737 9.62569 1.34089 8.98707 1.60292C8.34845 1.86496 7.76778 2.25036 7.27828 2.73707C6.78879 3.22378 6.40008 3.80224 6.1344 4.43935C5.86873 5.07647 5.7313 5.75972 5.72999 6.45V13.75C5.72866 14.4415 5.86421 15.1264 6.12883 15.7653C6.39345 16.4042 6.7819 16.9843 7.27179 17.4724C7.76168 17.9604 8.34333 18.3466 8.9832 18.6088C9.62307 18.871 10.3085 19.004 11 19H17.5C18.8897 19 20.2225 18.4479 21.2052 17.4652C22.1879 16.4825 22.74 15.1497 22.74 13.76V6.45C22.7373 5.06108 22.1844 3.7298 21.2023 2.74768C20.2202 1.76556 18.8889 1.21264 17.5 1.21Z"
        fill="#0C45E1"
      />
    </svg>
  )
}

export default CopyToClipboardFilled
