import type { Reducer } from 'redux'
import type { PersonalVerificationStatus } from '~/types'
import type { ActionTypes, FilterOptions, FilterValues, PaginationCursor } from './types'
import { INVOICING_ACTIONS } from './types'

const initialState = {
  filterOptions: null,
  filterValues: {
    startDate: '',
    endDate: '',
    startDueDate: '',
    endDueDate: '',
    invoiceStatuses: [],
    currentPage: 1,
    pageLimit: 10,
    searchString: '',
    isInvoiceLoading: false,
    invoices: [],
    totalCount: 0,
  },
  useNewPagination: false,
  paginationCursors: [],
  userVerificationStatus: null,
}

type State = {
  filterOptions: null | FilterOptions
  filterValues: FilterValues
  useNewPagination: boolean
  paginationCursors: Array<PaginationCursor>
  userVerificationStatus: PersonalVerificationStatus
}

export const invoicing: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case INVOICING_ACTIONS.SET_INVOICES_FILTER_OPTIONS: {
      return { ...state, filterOptions: action.filterOptions }
    }

    case INVOICING_ACTIONS.GET_INVOICES: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          invoices: action.invoices,
          totalCount: action.totalCount,
        },
      }
    }

    case INVOICING_ACTIONS.SET_IS_INVOICE_LOADING: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          isInvoiceLoading: action.isInvoiceLoading,
        },
      }
    }

    case INVOICING_ACTIONS.SET_FILTERS: {
      const { filterValuesObject } = action

      const newTabFilterValues: FilterValues = {
        ...state.filterValues,
      }

      Object.keys(filterValuesObject).forEach((key: string) => {
        newTabFilterValues[key] = filterValuesObject[key]
      })

      return {
        ...state,
        filterValues: {
          ...newTabFilterValues,
        },
      }
    }

    case INVOICING_ACTIONS.SET_USE_NEW_PAGINATION: {
      return {
        ...state,
        useNewPagination: action.useNewPagination,
      }
    }

    case INVOICING_ACTIONS.SET_PERSONAL_VERIFICATION_STATUS: {
      return {
        ...state,
        userVerificationStatus: action.userVerificationStatus,
      }
    }

    case INVOICING_ACTIONS.SET_PAGINATION_CURSORS: {
      return {
        ...state,
        paginationCursors: action.paginationCursors,
      }
    }

    default:
      return state
  }
}
