/* eslint-disable max-lines */

/**
 * Role Based Access rules:
 * 1. Static permissions are those permissions which don't need any data apart from the user role.
 * 2. Dynamic permissions are permissions which need additional data to determine access.
 *
 * Live Document:
 * (Please inform Felix Rowen to make sure the document updated accordingly whenever rule changes)
 * [RBAC Master] https://www.notion.so/fazzfinancialgroup/Role-based-Access-Control-4db570e0d2ff494ab9ffe4a441957bea
 *
 * Old Document:
 * [RBAC v2] https://docs.google.com/spreadsheets/d/13SWuopGVollp0EwxiThoM9MpRKExG6HdK-mveEAMALA/edit#gid=1977665203
 * https://www.notion.so/fazzfinancialgroup/WIP-PRD-Fazz-Biz-Role-Based-Access-v1-0-eb1a16c7443243a1ab02506c83cf8c9f
 * https://www.notion.so/xfers/AuthZ-in-Multi-tenant-system-9fa7f5583da9485eb4b4d384a12f917f
 *
 * Note:
 * Initially feature matrix was going to be generated by express server and sent to FE but decided to hard code for MVP.
 * There's a plan to move this to backend to support custom role, probably 2022Q4 or 2023Q1.
 * Hence handling permission on FE might be deprecated in the future.
 */
import { store } from '~/bizRegional/store/store'
import type { RootState as RegionalStore } from '~/bizRegional/store/types'
import { WalletTag } from '~/rootStore/wallets/types'
import type { FeatureMatrixSelectorsType } from '~/types'
import { PERMISSIONS, UserRoles } from '~/types'

export function check(factors: (boolean | undefined)[]) {
  return factors.every((v) => v === true)
}

const getValueFromStore = <T>(fn: (state: RegionalStore) => T): T => fn(store.getState())

type RuleType = Partial<
  Record<
    UserRoles,
    {
      static: Partial<PERMISSIONS[]>
      dynamic: Partial<Record<PERMISSIONS, (args: FeatureMatrixSelectorsType) => void>>
    }
  >
>

const rules: RuleType = {
  [UserRoles.OWNER]: {
    static: [
      PERMISSIONS.TRANSACTION,
      PERMISSIONS.DEVELOPER_TOOLS,
      PERMISSIONS.SANDBOX,
      PERMISSIONS.VERIFICATION,
      PERMISSIONS.TEAM_READ,
      PERMISSIONS.TEAM_EXECUTE,
      PERMISSIONS.MANAGE_ACCOUNT_BANK,
      PERMISSIONS.SETTINGS,
      PERMISSIONS.APPLY_LOAN,
      PERMISSIONS.ACCOUNT_STATEMENT,
      PERMISSIONS.TRANSACTION_ACCOUNT_BALANCE,
      PERMISSIONS.RECEIVE_TRANSACTION_LIST,
      PERMISSIONS.PAY_TRANSACTION_LIST,
      PERMISSIONS.SB_LANDING_PAGE,
      PERMISSIONS.SB_WITHDRAWAL,
      PERMISSIONS.SB_DISBURSEMENTS,
      PERMISSIONS.SB_INVOICING,
      PERMISSIONS.SB_EARN,
      PERMISSIONS.SB_DEVELOPER_TOOLS,
      PERMISSIONS.SB_DEPOSIT,
      PERMISSIONS.PAYMENT_APPROVAL_SETTINGS,
    ],
    dynamic: {
      [PERMISSIONS.DISBURSEMENTS]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        const xfpayDisableDisbursements = getValueFromStore(
          (state: RegionalStore) => state.flippers.merchantId.xfpay_disable_disbursements
        )
        const { fazzbiz_pay_menu_disabled: isPayMenuDisabled } = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs
        )

        if (xfpayDisableDisbursements) return false
        return check([isAccountManagerVerified, isBizVerified, !isPayMenuDisabled])
      },
      [PERMISSIONS.DEPOSIT]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => {
        const isMerchantWithHighNestingRisk = getValueFromStore(
          (state: RegionalStore) =>
            state.flippers.envFlipperId.merchantId.enable_merchants_with_high_nesting_risk
        )
        if (isMerchantWithHighNestingRisk) {
          return check([isAccountManagerVerified, isBizVerified])
        }
        return false
      },
      [PERMISSIONS.WITHDRAW]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => {
        const isWithdrawDisabled = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs.fazzbiz_disable_withdraw
        )
        if (isWithdrawDisabled) return false
        return check([isAccountManagerVerified, isBizVerified])
      },
      [PERMISSIONS.TRANSFER_OUT]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.MANAGE_ACCOUNT_BANK_ADD_ACCOUNT]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.CREATE_NEW_MERCHANT]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isBizVerified, isAccountManagerVerified]),
      [PERMISSIONS.INVOICING]: () => {
        // Add condition for SG to consider High Nesting Risk merchants if true, to return false for payment Link access
        const isMerchantWithHighNestingRisk = getValueFromStore(
          (state: RegionalStore) =>
            state.flippers.envFlipperId.merchantId.enable_merchants_with_high_nesting_risk
        )
        if (isMerchantWithHighNestingRisk) return false
        return true
      },
      [PERMISSIONS.INVOICING_CREATE_INVOICE]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.XFERS_SEND]: () => {
        const enableXfersSend = getValueFromStore(
          (state: RegionalStore) => state.flippers.merchantId.xfpay_enable_xfers_send
        )
        return enableXfersSend
      },
      [PERMISSIONS.DEVELOPER_TOOLS_GENERATE_SECRET_KEY]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.USD_ACCOUNT]: () => {
        const enableUSDaccount = getValueFromStore(
          (state: RegionalStore) => state.flippers.emailId.fazzbiz_enable_usd_account
        )
        const { fazzbiz_enable_usd_account_maintenance_page: isUsdAccountPageUnderMaintenance } =
          getValueFromStore((state: RegionalStore) => state.featureGate.featureGateIDs)
        if (!enableUSDaccount || isUsdAccountPageUnderMaintenance) return false
        return true
      },
      [PERMISSIONS.USD_ACCOUNT_WALLEX_ACCOUNT_VERIFICATION]: () => {
        const isWallexAccountVerified = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_is_wallex_account_verified
        )
        const isUsdAccountOpened = getValueFromStore(
          (state: RegionalStore) =>
            state.featureGate.featureGateIDs.fazzbiz_is_wallex_account_verified
        )

        return isWallexAccountVerified || isUsdAccountOpened
      },
      [PERMISSIONS.MY_CARD]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.MY_CARD_MANAGEMENT]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.ALL_COMPANY_CARDS]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.SG_LOAN]: () => {
        const wallets = getValueFromStore((state: RegionalStore) => state.wallets.wallets)
        const termLoanUserProfile = getValueFromStore(
          (state: RegionalStore) => state.termLoan.userDetails.loanProfile
        )
        const hasValidTermLoanUserProfile = !!termLoanUserProfile && !!termLoanUserProfile.id
        return wallets.has(WalletTag.CREDIT_LINE) || hasValidTermLoanUserProfile
      },
      [PERMISSIONS.SG_CREDITLINE]: () => {
        const wallets = getValueFromStore((state: RegionalStore) => state.wallets.wallets)
        const checkCreditLineWallet = !!wallets.get(WalletTag.CREDIT_LINE)
        return checkCreditLineWallet
      },
      [PERMISSIONS.TERM_LOAN]: () => {
        const termLoanUserProfile = getValueFromStore(
          (state: RegionalStore) => state.termLoan.userDetails.loanProfile
        )
        return !!termLoanUserProfile && !!termLoanUserProfile.id
      },
      [PERMISSIONS.SGD_ACCOUNT_VA_AND_PAYNOW_QR]: ({
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified]),
      [PERMISSIONS.API_MENU]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.DEVELOPER_TOOLS_SG]: () => {
        const fazzbizEnableDevToolsMenu = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzbizEnableDevToolsMenu
      },
      [PERMISSIONS.SB_PAYMENT_API]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.INTEGRATION]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.EARN]: () => {
        const isEarnEnabled = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs.fazzbiz_enable_earn
        )
        return isEarnEnabled
      },
      [PERMISSIONS.EARN_EXECUTE]: () => {
        const isEarnEnabled = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs.fazzbiz_enable_earn
        )
        return isEarnEnabled
      },
      [PERMISSIONS.USD_EARN]: () => {
        const { fazzbiz_enable_usd_account_maintenance_page: isUsdAccountPageUnderMaintenance } =
          getValueFromStore((state: RegionalStore) => state.featureGate.featureGateIDs)
        if (isUsdAccountPageUnderMaintenance) {
          return false
        }
        return true
      },
      [PERMISSIONS.SB_PAY_MENU]: () => {
        const fazzBizEnablePayMenu = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_whitelist_enable_sandbox_pay_menu
        )
        return fazzBizEnablePayMenu
      },
      [PERMISSIONS.SG_RECIPIENTS]: () => {
        const isRecipientsEnabled = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs.fazzbiz_enable_sg_recipients
        )
        return isRecipientsEnabled
      },
      [PERMISSIONS.CUSTOMERS_CARDS]: () => {
        const isCustomerCardEnabled = getValueFromStore(
          (state: RegionalStore) => state.customerCard.isCustomerCardAdminPanelEnabled
        )
        return isCustomerCardEnabled
      },
    },
  },
  [UserRoles.ADMIN]: {
    static: [
      PERMISSIONS.TRANSACTION,
      PERMISSIONS.DEVELOPER_TOOLS,
      PERMISSIONS.SANDBOX,
      PERMISSIONS.TEAM_READ,
      PERMISSIONS.TEAM_EXECUTE,
      PERMISSIONS.MANAGE_ACCOUNT_BANK,
      PERMISSIONS.SETTINGS,
      PERMISSIONS.APPLY_LOAN,
      PERMISSIONS.ACCOUNT_STATEMENT,
      PERMISSIONS.TRANSACTION_ACCOUNT_BALANCE,
      PERMISSIONS.RECEIVE_TRANSACTION_LIST,
      PERMISSIONS.PAY_TRANSACTION_LIST,
      PERMISSIONS.SB_LANDING_PAGE,
      PERMISSIONS.SB_WITHDRAWAL,
      PERMISSIONS.SB_DISBURSEMENTS,
      PERMISSIONS.SB_INVOICING,
      PERMISSIONS.SB_EARN,
      PERMISSIONS.SB_DEVELOPER_TOOLS,
      PERMISSIONS.SB_DEPOSIT,
      PERMISSIONS.PAYMENT_APPROVAL_SETTINGS,
    ],
    dynamic: {
      [PERMISSIONS.DISBURSEMENTS]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        const xfpayDisableDisbursements = getValueFromStore(
          (state: RegionalStore) => state.flippers.merchantId.xfpay_disable_disbursements
        )

        const { fazzbiz_pay_menu_disabled: isPayMenuDisabled } = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs
        )

        if (xfpayDisableDisbursements) return false
        return check([isAccountManagerVerified, isBizVerified, !isPayMenuDisabled])
      },
      [PERMISSIONS.DEPOSIT]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => {
        const isMerchantWithHighNestingRisk = getValueFromStore(
          (state: RegionalStore) =>
            state.flippers.envFlipperId.merchantId.enable_merchants_with_high_nesting_risk
        )
        if (isMerchantWithHighNestingRisk) {
          return check([isAccountManagerVerified, isBizVerified])
        }
        return false
      },
      [PERMISSIONS.WITHDRAW]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => {
        const isWithdrawDisabled = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs.fazzbiz_disable_withdraw
        )
        if (isWithdrawDisabled) return false
        return check([isAccountManagerVerified, isBizVerified])
      },
      [PERMISSIONS.TRANSFER_OUT]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.MANAGE_ACCOUNT_BANK_ADD_ACCOUNT]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isBizVerified, isAccountManagerVerified]),
      [PERMISSIONS.CREATE_NEW_MERCHANT]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isBizVerified, isAccountManagerVerified]),
      [PERMISSIONS.INVOICING]: () => {
        const isMerchantWithHighNestingRisk = getValueFromStore(
          (state: RegionalStore) =>
            state.flippers.envFlipperId.merchantId.enable_merchants_with_high_nesting_risk
        )
        if (isMerchantWithHighNestingRisk) return false
        return true
      },
      [PERMISSIONS.INVOICING_CREATE_INVOICE]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.XFERS_SEND]: () => {
        const enableXfersSend = getValueFromStore(
          (state: RegionalStore) => state.flippers.merchantId.xfpay_enable_xfers_send
        )
        return enableXfersSend
      },
      [PERMISSIONS.DEVELOPER_TOOLS_GENERATE_SECRET_KEY]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.USD_ACCOUNT]: () => {
        const enableUSDaccount = getValueFromStore(
          (state: RegionalStore) => state.flippers.emailId.fazzbiz_enable_usd_account
        )
        return enableUSDaccount
      },
      [PERMISSIONS.USD_ACCOUNT_WALLEX_ACCOUNT_VERIFICATION]: () => {
        const isWallexAccountVerified = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_is_wallex_account_verified
        )
        const isUsdAccountOpened = getValueFromStore(
          (state: RegionalStore) =>
            state.featureGate.featureGateIDs.fazzbiz_is_wallex_account_verified
        )
        return isWallexAccountVerified || isUsdAccountOpened
      },
      [PERMISSIONS.MY_CARD]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.MY_CARD_MANAGEMENT]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.ALL_COMPANY_CARDS]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.SG_LOAN]: () => {
        const wallets = getValueFromStore((state: RegionalStore) => state.wallets.wallets)
        const termLoanUserProfile = getValueFromStore(
          (state: RegionalStore) => state.termLoan.userDetails.loanProfile
        )
        const hasValidTermLoanUserProfile = !!termLoanUserProfile && !!termLoanUserProfile.id
        return wallets.has(WalletTag.CREDIT_LINE) || hasValidTermLoanUserProfile
      },
      [PERMISSIONS.SG_CREDITLINE]: () => {
        const wallets = getValueFromStore((state: RegionalStore) => state.wallets.wallets)
        const checkCreditLineWallet = !!wallets.get(WalletTag.CREDIT_LINE)
        return checkCreditLineWallet
      },
      [PERMISSIONS.TERM_LOAN]: () => {
        const termLoanUserProfile = getValueFromStore(
          (state: RegionalStore) => state.termLoan.userDetails.loanProfile
        )
        return !!termLoanUserProfile && !!termLoanUserProfile.id
      },
      [PERMISSIONS.SGD_ACCOUNT_VA_AND_PAYNOW_QR]: ({
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified]),
      [PERMISSIONS.API_MENU]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.DEVELOPER_TOOLS_SG]: () => {
        const fazzBizEnableDevToolsMenu = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableDevToolsMenu
      },
      [PERMISSIONS.SB_PAYMENT_API]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.INTEGRATION]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.EARN]: () => {
        const isEarnEnabled = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs.fazzbiz_enable_earn
        )
        return isEarnEnabled
      },
      [PERMISSIONS.EARN_EXECUTE]: () => {
        const isEarnEnabled = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs.fazzbiz_enable_earn
        )
        return isEarnEnabled
      },
      [PERMISSIONS.USD_EARN]: () => {
        const { fazzbiz_enable_usd_earn: isUsdEarnEnabled } = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs
        )
        return isUsdEarnEnabled
      },
      [PERMISSIONS.SB_PAY_MENU]: () => {
        const fazzBizEnablePayMenu = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_whitelist_enable_sandbox_pay_menu
        )
        return fazzBizEnablePayMenu
      },
      [PERMISSIONS.SG_RECIPIENTS]: () => {
        const isRecipientsEnabled = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs.fazzbiz_enable_sg_recipients
        )
        return isRecipientsEnabled
      },
      [PERMISSIONS.CUSTOMERS_CARDS]: () => {
        const isCustomerCardEnabled = getValueFromStore(
          (state: RegionalStore) => state.customerCard.isCustomerCardAdminPanelEnabled
        )
        return isCustomerCardEnabled
      },
    },
  },
  [UserRoles.OPERATIONS]: {
    static: [
      PERMISSIONS.TRANSACTION,
      PERMISSIONS.SANDBOX,
      PERMISSIONS.TEAM_READ,
      PERMISSIONS.SETTINGS,
      PERMISSIONS.ACCOUNT_STATEMENT,
      PERMISSIONS.TRANSACTION_ACCOUNT_BALANCE,
      PERMISSIONS.RECEIVE_TRANSACTION_LIST,
      PERMISSIONS.PAY_TRANSACTION_LIST,
      PERMISSIONS.SB_LANDING_PAGE,
      PERMISSIONS.SB_WITHDRAWAL,
      PERMISSIONS.SB_DISBURSEMENTS,
      PERMISSIONS.SB_INVOICING,
      PERMISSIONS.SB_EARN,
      PERMISSIONS.SB_DEVELOPER_TOOLS,
      PERMISSIONS.SB_DEPOSIT,
    ],
    dynamic: {
      [PERMISSIONS.DEPOSIT]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => {
        const isMerchantWithHighNestingRisk = getValueFromStore(
          (state: RegionalStore) =>
            state.flippers.envFlipperId.merchantId.enable_merchants_with_high_nesting_risk
        )
        if (isMerchantWithHighNestingRisk) {
          return check([isAccountManagerVerified, isBizVerified])
        }
        return false
      },
      [PERMISSIONS.WITHDRAW]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => {
        const isWithdrawDisabled = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs.fazzbiz_disable_withdraw
        )
        if (isWithdrawDisabled) return false
        return check([isAccountManagerVerified, isBizVerified])
      },
      [PERMISSIONS.TRANSFER_OUT]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.DISBURSEMENTS]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        const xfpayDisableDisbursements = getValueFromStore(
          (state: RegionalStore) => state.flippers.merchantId.xfpay_disable_disbursements
        )

        const { fazzbiz_pay_menu_disabled: isPayMenuDisabled } = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs
        )

        if (xfpayDisableDisbursements) return false
        return check([isAccountManagerVerified, isBizVerified, !isPayMenuDisabled])
      },
      [PERMISSIONS.INVOICING]: ({ isHighRiskMerchant }: FeatureMatrixSelectorsType) => {
        if (isHighRiskMerchant) return false
        return true
      },
      [PERMISSIONS.INVOICING_CREATE_INVOICE]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.XFERS_SEND]: () => {
        const enableXfersSend = getValueFromStore(
          (state: RegionalStore) => state.flippers.merchantId.xfpay_enable_xfers_send
        )
        return enableXfersSend
      },
      [PERMISSIONS.USD_ACCOUNT]: () => {
        const enableUSDaccount = getValueFromStore(
          (state: RegionalStore) => state.flippers.emailId.fazzbiz_enable_usd_account
        )
        return enableUSDaccount
      },
      [PERMISSIONS.USD_ACCOUNT_WALLEX_ACCOUNT_VERIFICATION]: () => {
        const isWallexAccountVerified = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_is_wallex_account_verified
        )
        const isUsdAccountOpened = getValueFromStore(
          (state: RegionalStore) =>
            state.featureGate.featureGateIDs.fazzbiz_is_wallex_account_verified
        )
        return isWallexAccountVerified || isUsdAccountOpened
      },
      [PERMISSIONS.MY_CARD]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.MY_CARD_MANAGEMENT]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.ALL_COMPANY_CARDS]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.SGD_ACCOUNT_VA_AND_PAYNOW_QR]: ({
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified]),
      [PERMISSIONS.API_MENU]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.SB_PAYMENT_API]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.INTEGRATION]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.EARN]: () => {
        const isEarnEnabled = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs.fazzbiz_enable_earn
        )
        return isEarnEnabled
      },
      [PERMISSIONS.EARN_EXECUTE]: () => {
        const isEarnEnabled = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs.fazzbiz_enable_earn
        )
        return isEarnEnabled
      },
      [PERMISSIONS.USD_EARN]: () => {
        const { fazzbiz_enable_usd_earn: isUsdEarnEnabled } = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs
        )
        return isUsdEarnEnabled
      },
      [PERMISSIONS.CUSTOMERS_CARDS]: () => {
        const isCustomerCardEnabled = getValueFromStore(
          (state: RegionalStore) => state.customerCard.isCustomerCardAdminPanelEnabled
        )
        return isCustomerCardEnabled
      },
    },
  },
  [UserRoles.DEVELOPER]: {
    static: [
      PERMISSIONS.TRANSACTION,
      PERMISSIONS.TRANSACTION_ACCOUNT_BALANCE,
      PERMISSIONS.DEVELOPER_TOOLS,
      PERMISSIONS.SANDBOX,
      PERMISSIONS.RECEIVE_TRANSACTION_LIST,
      PERMISSIONS.PAY_TRANSACTION_LIST,
      PERMISSIONS.SB_LANDING_PAGE,
      PERMISSIONS.SB_DEVELOPER_TOOLS,
    ],
    dynamic: {
      [PERMISSIONS.XFERS_SEND]: () => {
        const enableXfersSend = getValueFromStore(
          (state: RegionalStore) => state.flippers.merchantId.xfpay_enable_xfers_send
        )
        return enableXfersSend
      },
      [PERMISSIONS.DEVELOPER_TOOLS_GENERATE_SECRET_KEY]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.MY_CARD]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.API_MENU]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.DEVELOPER_TOOLS_SG]: () => {
        const fazzbizEnableDevToolsMenu = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzbizEnableDevToolsMenu
      },
      [PERMISSIONS.SB_PAYMENT_API]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.INTEGRATION]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.CUSTOMERS_CARDS]: () => {
        const isCustomerCardEnabled = getValueFromStore(
          (state: RegionalStore) => state.customerCard.isCustomerCardAdminPanelEnabled
        )
        return isCustomerCardEnabled
      },
    },
  },
  [UserRoles.VIEWER]: {
    static: [
      PERMISSIONS.TRANSACTION,
      PERMISSIONS.SANDBOX,
      PERMISSIONS.TEAM_READ,
      PERMISSIONS.SETTINGS,
      PERMISSIONS.ACCOUNT_STATEMENT,
      PERMISSIONS.TRANSACTION_ACCOUNT_BALANCE,
      PERMISSIONS.RECEIVE_TRANSACTION_LIST,
      PERMISSIONS.PAY_TRANSACTION_LIST,
      PERMISSIONS.SB_LANDING_PAGE,
    ],
    dynamic: {
      [PERMISSIONS.INVOICING]: ({ isHighRiskMerchant }: FeatureMatrixSelectorsType) => {
        if (isHighRiskMerchant) return false
        return true
      },
      [PERMISSIONS.XFERS_SEND]: () => {
        const enableXfersSend = getValueFromStore(
          (state: RegionalStore) => state.flippers.merchantId.xfpay_enable_xfers_send
        )
        return enableXfersSend
      },
      [PERMISSIONS.USD_ACCOUNT]: () => {
        const enableUSDaccount = getValueFromStore(
          (state: RegionalStore) => state.flippers.emailId.fazzbiz_enable_usd_account
        )
        return enableUSDaccount
      },
      [PERMISSIONS.MY_CARD]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.API_MENU]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.SB_PAYMENT_API]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.SB_DEVELOPER_TOOLS]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.EARN]: () => {
        const isEarnEnabled = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs.fazzbiz_enable_earn
        )
        return isEarnEnabled
      },
      [PERMISSIONS.USD_EARN]: () => {
        const { fazzbiz_enable_usd_earn: isUsdEarnEnabled } = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs
        )
        return isUsdEarnEnabled
      },
    },
  },
  [UserRoles.FINANCE]: {
    static: [
      PERMISSIONS.TRANSACTION,
      PERMISSIONS.TEAM_READ,
      PERMISSIONS.SETTINGS,
      PERMISSIONS.ACCOUNT_STATEMENT,
      PERMISSIONS.TRANSACTION_ACCOUNT_BALANCE,
      PERMISSIONS.SB_LANDING_PAGE,
      PERMISSIONS.SB_DEPOSIT,
    ],
    dynamic: {
      [PERMISSIONS.DISBURSEMENTS]: () => {
        const xfpayDisableDisbursements = getValueFromStore(
          (state: RegionalStore) => state.flippers.merchantId.xfpay_disable_disbursements
        )

        const { fazzbiz_pay_menu_disabled: isPayMenuDisabled } = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs
        )

        if (xfpayDisableDisbursements) return false
        return !isPayMenuDisabled
      },
      [PERMISSIONS.DEPOSIT]: () => false,
      [PERMISSIONS.WITHDRAW]: ({ isBizVerified, isAccountManagerVerified }) => {
        const isWithdrawDisabled = getValueFromStore(
          (state: RegionalStore) => state.featureGate.featureGateIDs.fazzbiz_disable_withdraw
        )
        if (isWithdrawDisabled) return false
        return check([isAccountManagerVerified, isBizVerified])
      },
      [PERMISSIONS.TRANSFER_OUT]: () => false,
      [PERMISSIONS.INVOICING]: ({ isHighRiskMerchant }: FeatureMatrixSelectorsType) => {
        if (isHighRiskMerchant) return false
        return true
      },
      [PERMISSIONS.INVOICING_CREATE_INVOICE]: () => false,
      [PERMISSIONS.XFERS_SEND]: () => {
        const enableXfersSend = getValueFromStore(
          (state: RegionalStore) => state.flippers.merchantId.xfpay_enable_xfers_send
        )
        return enableXfersSend
      },
      [PERMISSIONS.MY_CARD]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.SGD_ACCOUNT_VA_AND_PAYNOW_QR]: ({
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified]),
      [PERMISSIONS.API_MENU]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.SB_PAYMENT_API]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.SB_DEVELOPER_TOOLS]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: RegionalStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.CUSTOMERS_CARDS]: () => {
        const isCustomerCardEnabled = getValueFromStore(
          (state: RegionalStore) => state.customerCard.isCustomerCardAdminPanelEnabled
        )
        return isCustomerCardEnabled
      },
    },
  },
  [UserRoles.BASIC]: {
    static: [],
    dynamic: {
      [PERMISSIONS.MY_CARD]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
    },
  },
}

export default rules
