import type { Moment } from '~/types'

export type UsdAccountTransaction = {
  id: string
  type: string
  amount: string
  counterparty: string
  timestamp: string
}

export type FilterValuesObject = {
  [filterKey: string]:
    | Moment
    | string[]
    | number[]
    | string
    | number
    | UsdAccountTransaction[]
    | boolean
    | undefined
}

export type FilterOptions = {
  transactionTypes: Array<{ label: string; value: string }>
  sortTypes: Array<{ label: string; value: string }>
}

export type FilterValues = {
  startDate: Moment | string
  endDate: Moment | string
  transactionType: string | undefined
  sortType: string | undefined
  currentPage: number
  pageSize: number
  [key: string]: FilterValuesObject[keyof FilterValuesObject]
}

export enum UsdAccountTransactionActions {
  GET_DATA = 'USD_ACCOUNT_TRANSACTIONS_ACTIONS.GET_DATA',
  GET_FILTER_OPTIONS = 'USD_ACCOUNT_TRANSACTIONS_ACTIONS.GET_FILTER_OPTIONS',
  SET_IS_LOADING = 'USD_ACCOUNT_TRANSACTIONS_ACTIONS.SET_IS_LOADING',
  SET_FILTERS = 'USD_ACCOUNT_TRANSACTIONS_ACTIONS.SET_FILTERS',
}

type GetData = {
  type: typeof UsdAccountTransactionActions.GET_DATA
  transactions: UsdAccountTransaction[]
  totalCount: number
}

type GetFilterOptions = {
  type: typeof UsdAccountTransactionActions.GET_FILTER_OPTIONS
  filterOptions: FilterOptions
}

type SetIsLoading = {
  type: typeof UsdAccountTransactionActions.SET_IS_LOADING
  isTransactionsLoading: boolean
}

type SetFilters = {
  type: typeof UsdAccountTransactionActions.SET_FILTERS
  filterValuesObject: FilterValuesObject
}

export type ActionTypes = GetData | GetFilterOptions | SetIsLoading | SetFilters
