import { SVGProps } from 'react'

function SvgDownload(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m20.17 7.39-5.74-5.74A2 2 0 0 0 13 1.06H9.25a6 6 0 0 0-6 6v9.88a6 6 0 0 0 6 6h5.5a6 6 0 0 0 6-6V8.8a2 2 0 0 0-.58-1.41Zm-1.65.48h-.85a3.5 3.5 0 0 1-3.5-3.5v-.85l4.35 4.35Zm-3.77 13.57h-5.5a4.5 4.5 0 0 1-4.5-4.5V7.06a4.5 4.5 0 0 1 4.5-4.5h3.42v1.81a5 5 0 0 0 5 5h1.58v7.57a4.5 4.5 0 0 1-4.5 4.5Z"
        fill="currentColor"
      />
      <path
        d="m11 17.48-2.66-2.69a.71.71 0 0 1-.22-.53.74.74 0 0 1 .22-.53.75.75 0 0 1 1.06 0l1.84 1.84V10a.75.75 0 1 1 1.5 0v5.58l1.86-1.87a.75.75 0 0 1 1.06 1.06L13 17.48a1.39 1.39 0 0 1-1 .41 1.361 1.361 0 0 1-1-.41Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgDownload
