import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import styled from '@emotion/styled'
import { useModal, Modal, fdsTheme, CheckboxSingle, Typography, Button } from '@fazz/design-system'
import { agreeIdTnc } from '~/api/initialisation/initialisation.api'
import SessionUtils from '~/utils/SessionUtils'
import TncDocViewer from './TnCDocViewer'

const { spacing, colors } = fdsTheme
const { Body } = Typography

type Props = {
  setIsTncChecked: Dispatch<SetStateAction<boolean>>
}

export default function TncModal({ setIsTncChecked }: Props) {
  const { resetModal } = useModal()

  const [isTncAgreed, setIsTncAgreed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const agreeHandler = async () => {
    setIsLoading(true)
    const res = await agreeIdTnc()
    if (res.status === 200) {
      resetModal()
      setIsTncChecked(true)
    }
    setIsLoading(false)
  }

  return (
    <Modal
      footer={null}
      maxWidth="620px"
      onClose={SessionUtils.ssoLogout}
      title="Terms & Conditions"
    >
      <Container>
        <DocContainer>
          <TncDocViewer />
        </DocContainer>
        <div>
          <CheckboxWrapper>
            <CheckboxSingle onClick={() => setIsTncAgreed(!isTncAgreed)} />
            <div>
              <Body size="medium">
                I have read & agreed to the <HighlightText>Terms & Conditions</HighlightText> to use
                the service provided by Fazz Business and partners.
              </Body>
              <TranslatedText color={colors.onNeutralSecondary} size="medium">
                Saya telah membaca dan setuju dengan Syarat dan Ketentuan untuk menggunakan layanan
                yang disediakan oleh Fazz Business dan mitra.
              </TranslatedText>
            </div>
          </CheckboxWrapper>
          <Button isLoading={isLoading} onClick={agreeHandler} isDisabled={!isTncAgreed} fullWidth>
            Accept and Continue
          </Button>
        </div>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.md};
`
const DocContainer = styled.div`
  overflow-y: scroll;
  font-size: 14px;
  height: 350px;
  padding-right: ${spacing.xs};
  text-align: justify;

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: left;
    padding-left: ${spacing.xxs};
  }

  ol {
    padding-left: 2em;
  }

  ul {
    padding-left: 2em;
  }

  li {
    margin-bottom: ${spacing.xs};
  }
`

const CheckboxWrapper = styled.div`
  display: flex;
  margin-bottom: ${spacing.md};
  gap: ${spacing.sm};
`
const HighlightText = styled.span`
  font-weight: bold;
`

const TranslatedText = styled(Body)`
  font-style: italic;
`
