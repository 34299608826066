import * as React from 'react'

function SvgSettings(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.586 21.779l.108.064h.001l1.172.695.899.533 1.296-1.293-.529-.888-.7-1.176.005-.01-.06-.102.612-1.223a4.25 4.25 0 00.252-.611l.431-1.293 1.321-.337 1.106-.283V14.09l-1.106-.282-1.32-.337-.432-1.293a4.25 4.25 0 00-.252-.61l-.613-1.225.7-1.175.53-.889-1.296-1.294-.9.534-1.171.695-.022-.01-.032.018-.352-.21-.814-.407a4.269 4.269 0 00-.613-.25l-1.294-.431-.339-1.321-.282-1.102h-1.853l-.268 1.047-.339 1.32-1.294.431c-.21.07-.364.13-.548.22l-1.176.703-.108-.064h-.001l-1.172-.695-.899-.533-1.296 1.293.529.888.7 1.176-.005.01.06.102-.612 1.223a4.245 4.245 0 00-.252.611l-.431 1.293-1.321.337-1.106.283v1.766l1.106.282 1.32.337.432 1.293c.078.233.145.398.252.61l.613 1.224-.7 1.176-.53.889 1.296 1.294.9-.533 1.171-.696.022.01.032-.018.352.21.814.406c.213.107.38.174.613.252l1.294.43.339 1.321.282 1.102h1.853l.268-1.047.339-1.32 1.294-.431c.21-.07.364-.13.548-.22l1.176-.703zm.006-16.468c-.158-.094-.602-.278-1.027-.45l-.578-2.254C17.877 2.276 17.6 2 17.268 2h-4.591c-.332 0-.664.22-.72.552l-.598 2.333c-.412.172-.825.35-.95.426L8.361 4.096c-.277-.165-.664-.11-.94.11L4.211 7.41c-.22.22-.276.607-.11.938l.005.009c.014.036.03.07.05.103L5.314 10.4c-.163.327-.273.6-.382.928l-2.379.607c-.332.056-.553.387-.553.718v4.694c0 .331.221.662.553.718l2.379.607c.11.331.221.607.387.938l-1.217 2.043c-.166.276-.11.662.11.938l3.21 3.202c.22.221.608.276.94.11l2.046-1.214c.158.095.602.278 1.027.45l.578 2.254c.11.331.387.607.719.607h4.591c.332 0 .664-.22.72-.552l.598-2.333c.412-.172.825-.35.95-.426l2.047 1.215c.277.165.664.11.94-.11l3.21-3.203c.22-.22.276-.607.11-.938l-.005-.009a.661.661 0 00-.05-.103L24.686 19.6c.163-.327.273-.6.382-.928l2.379-.607c.332-.056.553-.387.553-.718v-4.694c0-.331-.221-.662-.553-.718l-2.379-.607a6.746 6.746 0 00-.387-.938l1.217-2.043c.166-.276.11-.662-.11-.938l-3.21-3.202c-.22-.221-.608-.276-.94-.11L19.591 5.31zM18 15a3 3 0 11-6 0 3 3 0 016 0zm3 0a6 6 0 11-12 0 6 6 0 0112 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSettings
