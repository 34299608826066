import * as React from 'react'

function SvgCallback(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.957 10.058a.57.57 0 0 0-.69.405 5.925 5.925 0 0 1-11.235.787l.203.128a.57.57 0 0 0 .75-.195.562.562 0 0 0-.195-.75l-1.44-.885a.57.57 0 0 0-.75.195l-.9 1.462a.57.57 0 0 0 .195.75.555.555 0 0 0 .772-.195l.218-.36a7.058 7.058 0 0 0 13.5-.682.562.562 0 0 0-.428-.66ZM17.105 6a.563.563 0 0 0-.75.187l-.202.345a7.058 7.058 0 0 0-13.463.75.565.565 0 1 0 1.095.278 5.932 5.932 0 0 1 11.213-.81l-.248-.165a.564.564 0 0 0-.577.967l1.462.87a.57.57 0 0 0 .285.083h.143a.57.57 0 0 0 .345-.255l.892-1.5a.563.563 0 0 0-.195-.75Z"
        fill="currentColor"
      />
      <path d="M9.5 6.15a2.85 2.85 0 1 0 0 5.7 2.85 2.85 0 0 0 0-5.7Z" fill="currentColor" />
    </svg>
  )
}

export default SvgCallback
