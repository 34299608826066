import type { Reducer } from 'redux'
import type { ActionTypes } from '~/bizRegional/store/usdAccount/agreement/types'
import { USDLoanAccountAgreementActions } from '~/bizRegional/store/usdAccount/agreement/types'

const initialState = {
  agreement: false,
  showAgreementModal: false,
}

type State = {
  agreement?: boolean
  showAgreementModal: boolean
}

export const usdAccountAgreement: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case USDLoanAccountAgreementActions.GET_AGREEMENT: {
      return {
        ...state,
        agreement: action.agreement,
      }
    }
    case USDLoanAccountAgreementActions.SHOW_AGREEMENT_MODAL: {
      return {
        ...state,
        showAgreementModal: action.show,
      }
    }
    default:
      return state
  }
}
