import type { Reducer } from 'redux'
import type { InvoicingDetails, ActionTypes } from './types'
import { InvoicingDetailsActions } from './types'

const initialState = {
  isLoading: false,
  invoicingDetails: {
    uniqueId: '',
    state: '',
    createdAt: '',
    dueAt: '',
    paidAt: '',
    settledAt: '',
    expiredAt: '',
    customerReference: '',
    customerName: '',
    customerEmail: '',
    customerPhoneNumber: '',
    description: '',
    amount: '',
    currency: '',
    invoiceLink: '',
    paidPaymentMethod: {
      createdAt: '',
      paidAt: '',
      settledAt: '',
      paymentMethod: '',
    },
  },
}

type State = {
  isLoading: boolean
  invoicingDetails: InvoicingDetails
}

export const invoicingDetails: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case InvoicingDetailsActions.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }

    case InvoicingDetailsActions.GET_INVOICING_DETAILS: {
      return {
        ...state,
        invoicingDetails: action.invoicingDetails,
      }
    }

    default: {
      return state
    }
  }
}
