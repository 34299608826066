/** @deprecated please use fazzBizzThemeFds3 spacing theme instead */
export const EXTRA_SMALL_SPACING = `5px`
/** @deprecated please use fazzBizzThemeFds3 spacing theme instead */
export const SMALL_SPACING = `10px`
/** @deprecated please use fazzBizzThemeFds3 spacing theme instead */
export const MEDIUM_SPACING = `15px`
/** @deprecated please use fazzBizzThemeFds3 spacing theme instead */
export const LARGE_SPACING = `20px`
/** @deprecated please use fazzBizzThemeFds3 spacing theme instead */
export const EXTRA_LARGE_SPACING = `30px`

/** @deprecated please use fazzBizzThemeFds3 spacing theme instead */
export const LAYOUT_SPACING_DESKTOP = `24px`
/** @deprecated please use fazzBizzThemeFds3 spacing theme instead */
export const LAYOUT_SPACING_MOBILE = `16px`
