import * as React from 'react'

function SvgDextf(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#DEXTF_svg__clip0)">
        <path
          d="M17.844 17.393c.765.807 2.978 3.09 3.345 3.465l.435-.295s-3.046-3.18-3.046-3.17v-.01l2.936-3.033-.451-.274-3.214 3.307v.01h-.005z"
          fill="#fff"
        />
        <path
          d="M16.88 17.82c.095.095.184.19.274.285-.572.596-2.244 2.341-2.412 2.52l.445.301c.037-.042 1.893-1.972 2.333-2.436a602.73 602.73 0 012.286 2.426l.54-.354s-2.28-2.362-3.046-3.17v-.01a742.05 742.05 0 012.936-3.032l-.508-.311s-1.956 2.067-2.166 2.283c0 0-2.154-2.278-2.165-2.294l-.461.28c.335.358 1.898 2.014 2.254 2.394-.1.1-.189.195-.283.285-.43-.459-2.401-2.547-2.79-2.959l-.544.327c.503.506 2.469 2.558 2.92 3.043v.01c-.697.734-3.015 3.117-3.083 3.175l.498.338 2.973-3.101z"
          fill="#fff"
        />
        <path
          d="M11.956 25.604l6.522-.006c.137.01 1.5-.02 1.689-.09 3.486-.527 5.955-3.823 5.992-8.01.042-4.187-2.422-7.483-5.945-8.005-.236-.08-1.604-.111-1.715-.1h-6.543a1.884 1.884 0 00-1.877 1.887l-.005 12.43a1.89 1.89 0 001.882 1.894zm-.466-13.79c.005-.37.042-.65.246-.85.178-.174.52-.264.818-.264.084 0 .236 0 5.384.027.163-.01 1.626.031 1.82.068l.031.006c2.983.5 4.928 3.106 4.95 6.634-.022 3.528-1.967 6.133-4.976 6.64-.184.036-1.662.078-1.846.068l-5.138.005c-.97 0-1.258-.253-1.29-1.134 0-.079 0-1.476.006-3.254.005-6.66-.005-7.873-.005-7.947z"
          fill="#fff"
        />
        <path
          d="M3.174 31.874V3.075h30.419l.005-.675H2.42L2.4 32.55v.02h31.173l.005-.696H3.175z"
          fill="#fff"
        />
        <path d="M31.77 3.075v28.799h1.829V3.075H31.77z" fill="#0F0" />
        <path
          d="M3.176 31.874H31.77V3.075H3.176v28.8zm6.905-20.588c0-1.044.844-1.888 1.877-1.888H18.5c.11-.01 1.474.021 1.715.1 3.523.523 5.987 3.819 5.945 8.006-.042 4.187-2.506 7.483-5.992 8.01-.189.069-1.552.095-1.688.09h-6.523a1.884 1.884 0 01-1.876-1.888v-12.43z"
          fill="#010101"
        />
        <path
          d="M11.49 23.015c.032.875.32 1.128 1.29 1.134l5.138-.006c.184.016 1.668-.026 1.846-.068 3.01-.506 4.954-3.111 4.975-6.64-.02-3.522-1.966-6.127-4.949-6.634l-.031-.005c-.195-.037-1.657-.079-1.82-.069-5.148-.026-5.295-.026-5.384-.026-.299 0-.64.09-.818.264-.21.2-.241.48-.246.849 0 .074.01 1.287 0 7.947 0 1.777-.006 3.175 0 3.254zm10.02-8.665l-2.936 3.033v.01c0-.01 3.046 3.17 3.046 3.17l-.435.295c-.367-.374-2.58-2.658-3.345-3.465v-.01l3.213-3.307.457.274zm-5.023 3.064v-.01c-.45-.485-2.417-2.537-2.92-3.043l.545-.327c.388.411 2.36 2.5 2.79 2.958.088-.094.183-.184.282-.284-.356-.38-1.919-2.041-2.254-2.394l.461-.28c.01.016 2.165 2.294 2.165 2.294a944.59 944.59 0 002.166-2.283l.508.31c-.21.217-2.757 2.843-2.936 3.033v.01c.766.808 3.046 3.17 3.046 3.17l-.54.353a364.154 364.154 0 00-2.285-2.425c-.44.464-2.297 2.394-2.333 2.436l-.446-.3c.168-.175 1.845-1.92 2.412-2.522l-.273-.284-2.978 3.106-.498-.338c.073-.063 2.39-2.447 3.088-3.18z"
          fill="#010101"
        />
      </g>
      <defs>
        <clipPath id="DEXTF_svg__clip0">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgDextf
