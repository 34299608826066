import { Reducer } from 'redux'
import { MorakLoanVerificationStatus } from '~/types'
import { ActionTypes, Wallet, WalletActions, WalletTag } from './types'

export type State = {
  wallets: Map<WalletTag, Wallet | null>
  morakLoanVerificationStatus: MorakLoanVerificationStatus
  isMorakVerified: boolean
  morakLoanVerificationStatusLoading: boolean
  isWalletInitiated: boolean
}

export const initialState = {
  wallets: new Map<WalletTag, Wallet | null>([]),
  morakLoanVerificationStatus: undefined,
  isMorakVerified: false,
  morakLoanVerificationStatusLoading: false,
  isWalletInitiated: false,
}

export const wallets: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case WalletActions.SET_WALLETS: {
      return {
        ...state,
        wallets: action.wallets,
      }
    }

    case WalletActions.SET_IS_MORAK_VERIFIED: {
      return {
        ...state,
        isMorakVerified: action.isMorakVerified,
      }
    }

    case WalletActions.SET_MORAK_LOAN_VERIFICATION_STATUS_LOADING: {
      return {
        ...state,
        morakLoanVerificationStatusLoading: action.morakLoanVerificationStatusLoading,
      }
    }

    case WalletActions.SET_MORAK_LOAN_VERIFICATION_STATUS: {
      return {
        ...state,
        morakLoanVerificationStatus: action.morakLoanVerificationStatus,
      }
    }

    case WalletActions.SET_WALLET_IS_INITIATED: {
      return {
        ...state,
        isWalletInitiated: action.isWalletInitiated,
      }
    }
    default:
      return state
  }
}
