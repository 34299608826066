import type {
  MenuItem,
  SubMenuItem,
} from '@fazz/design-system/src/components/atoms/Menu/Menu.types'
import { Link } from '~/hooks/useReactRouter'
import { isHasPermission as isHasPermissionId } from '~/biz/permission'
import bizRoutes from '~/biz/routes/config'
import { isHasPermission as isHasPermissionSg } from '~/bizRegional/permission'
import bizRegionalRoutes from '~/bizRegional/routes/config'
import { AppType, type Route, type SubRoute } from '~/types'
import { ModeManager } from '~/managers/ModeManager'
import { getFooterMenuItems } from '../config/footerItems'
import { getMenuItemIcon, showBadge } from './uiHelpers'

type RouteConfig = {
  isSandbox: boolean
  isSg: boolean
}
// Check if Route or SubRoute for typesafety
const isRouteType = (route: Route | SubRoute): route is Route =>
  (route as Route).component !== undefined

// Filter routes based on permission
const filterRoutesByPermission = (
  routes: Route[] | SubRoute[],
  { isSandbox, isSg }: RouteConfig
): Route[] =>
  routes
    .filter((route: Route | SubRoute) => {
      const isHasPermission = isSg ? isHasPermissionSg : isHasPermissionId

      // Sandbox Mode. Check for sandbox permissions.
      if (isSandbox) {
        return route.sandboxPermission ? isHasPermission(route.sandboxPermission) : false
      }

      // Normal mode. Return true if no permissions were explicitly defined. This means it's always shown.
      if (!route.permission) {
        return true
      }

      // Normal mode. Check for permissions.
      return isHasPermission(route.permission)
    })
    .map((route: Route | SubRoute) => {
      const routeToCheck = { ...route }

      // Recursively check for permissions if Route has subroutes.
      if (isRouteType(routeToCheck) && routeToCheck.subRoutes) {
        routeToCheck.subRoutes = filterRoutesByPermission(routeToCheck.subRoutes, {
          isSandbox,
          isSg,
        }) as SubRoute[]
      }

      return routeToCheck as Route
    })

// Formats react-router subroutes into correct shape for Menu component
const formatSubRoutesToMenuItem = (subRoutes: SubRoute[]) =>
  subRoutes
    .filter((subRoute): subRoute is Required<SubRoute> => !!subRoute.sideBar)
    .map((filteredSubRoute) => {
      const formattedSubRoute: SubMenuItem = {
        key: filteredSubRoute.path + filteredSubRoute.sideBar,
        path: filteredSubRoute.path,
        label: filteredSubRoute.sideBar,
        badge: showBadge(filteredSubRoute.sideBar),
        render: () => <Link to={filteredSubRoute.path}>{filteredSubRoute.sideBar}</Link>,
      }

      return formattedSubRoute
    })

// Formats react-router routes into correct shape for Menu component
const formatRoutesToMenuItem = (routes: Route[]) =>
  routes
    .filter((route): route is Required<Route> => !!route.sideBar)
    .map((filteredRoute) => {
      const formattedRoute: MenuItem = {
        key: filteredRoute.path + filteredRoute.sideBar,
        path: filteredRoute.path,
        label: filteredRoute.sideBar,
        icon: getMenuItemIcon(filteredRoute.sideBar),
        badge: showBadge(filteredRoute.sideBar),
        render: () => <Link to={filteredRoute.path}>{filteredRoute.sideBar}</Link>,
      }

      if (filteredRoute.subRoutes) {
        formattedRoute.subItems = formatSubRoutesToMenuItem(filteredRoute.subRoutes)
      }

      return formattedRoute
    })

// Get the features to display on the sidebar
const getSidebarItems = (appType: AppType): MenuItem[] => {
  const isSg = appType === AppType.SINGAPORE
  const routes = isSg ? bizRegionalRoutes : bizRoutes
  const isSandbox = ModeManager.isSandboxMode()

  const filteredRoutes = filterRoutesByPermission(routes, { isSandbox, isSg })
  const featureItems = formatRoutesToMenuItem(filteredRoutes)
  const footerItems = getFooterMenuItems(isSg)

  return [...featureItems, { type: 'divider' }, ...footerItems]
}

export { getSidebarItems }
