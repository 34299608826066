import type { Reducer } from 'redux'
import type { ActionTypes, State } from './types'
import { BusinessVerificationActions } from './types'

const initialState: State = {
  initialized: false,
  currentPath: undefined,
  beneficiaries: [],
  acraBoList: [],
  verificationStatus: undefined,
  managerVerificationStatus: undefined,
  businessInformation: {
    businessType: undefined,
    businessName: undefined,
    companyDisplayname: undefined,
    businessRegistrationNumber: undefined,
    businessLicenseExpiryDate: undefined,
    businessContactNo: undefined,
    businessDateOfIncorporation: undefined,
    countryOfIncorporation: undefined,
    businessAddressLine1: undefined,
    businessAddressLine2: undefined,
    businessPostalCode: undefined,
    country: undefined,
    city: undefined,
    tinNpwp: undefined,
    merchantsFormerNames: [],
    prefilledByAcra: false,
  },
  businessRelation: {
    methods: [],
  },
  businessActivity: {
    countriesOfActivities: [],
    businessWebsite: undefined,
    businessActivitySummary: undefined,
    expectedTransactionVolume: undefined,
    expectedValuePerTransaction: undefined,
    expectedGtv: undefined,
    sourceOfWealth: undefined,
    sourceOfFunds: undefined,
    sourceOfGoods: undefined,
    ssic_description: undefined,
    ssic_other_description: undefined,
  },
  businessTaxResidency: {
    taxInformations: {
      details: [],
    },
  },
  individualOfficer: {
    declarations: {
      pepDeclaration: false,
      companyOwner: false,
      companyController: false,
    },
  },
  directorOrBoPage: {
    pepDeclaration: false,
  },
  uploadDocument: {
    putIdFrontUrl: undefined,
    getIdFrontUrl: undefined,
    putBizfileUrl: undefined,
    getBizfileUrl: undefined,
    putMemorandumUrl: undefined,
    getMemorandumUrl: undefined,
    putCertificateOfIncorporationUrl: undefined,
    getCertificateOfIncorporationUrl: undefined,
    putRegisterOfMembersUrl: undefined,
    getRegisterOfMembersUrl: undefined,
    putRegisterOfDirectorsUrl: undefined,
    getRegisterOfDirectorsUrl: undefined,
    putBoardResolutionUrl: undefined,
    getBoardResolutionUrl: undefined,
    putBusinessProofOfAddressUrl: undefined,
    getBusinessProofOfAddressUrl: undefined,
    putShareholdingChartUrl: undefined,
    getShareholdingChartUrl: undefined,
    putMolhrUrl: undefined,
    getMolhrUrl: undefined,
    putBankAccountProofUrl: undefined,
    getBankAccountProofUrl: undefined,
    idFrontUrl: undefined,
    isIdFrontPdf: false,
    bizfileUrl: undefined,
    isBizfilePdf: false,
    memorandumUrl: undefined,
    isMemorandumPdf: false,
    certificateOfIncorporationUrl: undefined,
    isCertificateOfIncorporationPdf: false,
    registerOfMembersUrl: undefined,
    isRegisterOfMembersPdf: false,
    registerOfDirectorsUrl: undefined,
    isRegisterOfDirectorsPdf: false,
    molhrUrl: undefined,
    isMolhrPdf: false,
    bankAccountProofUrl: undefined,
    isBankAccountProofPdf: false,
    boardResolutionUrl: undefined,
    isBoardResolutionPdf: false,
    shareholdingChartUrl: undefined,
    isShareholdingChartPdf: false,
    businessProofOfAddressUrl: undefined,
    isBusinessProofOfAddressPdf: false,

    // 2nd phase removal (deprecated fields)
    certificateOfIncorporation: undefined,
    certificateOfIncorporationSignedId: undefined,
    registerOfDirectors: undefined,
    registerOfDirectorsSignedId: undefined,
    registerOfMembers: undefined,
    registerOfMembersSignedId: undefined,

    // New fields after migration
    bizfile: [],
    boardResolution: [],
    memorandum: [],
    shareholdingChart: [],
    corporateOwnership: false,
  },
  accountManager: {
    fullName: undefined,
    dateOfBirth: undefined,
    countryOfBirth: undefined,
    nationality: undefined,
    nricType: undefined,
    NRIC: undefined,
    nricIssueDate: undefined,
    addressLine1: undefined,
    addressLine2: undefined,
    village: undefined,
    subDistrict: undefined,
    country: undefined,
    city: undefined,
    postalCode: undefined,
    rtRw: undefined,
    ownershipPercentage: undefined,
    verificationStatus: undefined,
    idFrontUrl: undefined,
    isIdFrontPdf: false,
    idBackUrl: undefined,
    isIdBackPdf: false,
    proofOfAddressUrl: undefined,
    isProofOfAddressPdf: false,
  },
  addFlowStep: null,
  isIndo: false,
  isSg: true,
  addFlowMeta: {
    type: 'individual',
    isDirectorOrBo: false,
    role: undefined,
    fullName: undefined,
    dateOfBirth: undefined,
    countryOfBirth: undefined,
    nationality: undefined,
    nricType: undefined,
    NRIC: undefined,
    nricIssueDate: undefined,
    nricExpiryDate: undefined,
    addressLine1: undefined,
    addressLine2: undefined,
    country: undefined,
    city: undefined,
    postalCode: undefined,
    ownershipPercentage: undefined,
    putIdFrontUrl: undefined,
    getIdFrontUrl: undefined,
    putIdBackUrl: undefined,
    getIdBackUrl: undefined,
    putProofOfAddressUrl: undefined,
    getProofOfAddressUrl: undefined,
    idFrontUrl: undefined,
    isIdFrontPdf: false,
    idBackUrl: undefined,
    isIdBackPdf: false,
    proofOfAddressUrl: undefined,
    isProofOfAddressPdf: false,
    deedOfAppointmentUrl: undefined,
    isDeedOfAppointmentPdf: false,
    idFrontDisabled: false,
    idBackDisabled: false,
    proofOfAddressDisabled: false,
    boInfoFromAcra: undefined,
    awareOfPoaSubmission: false,
  },
  acraBusinessInformation: {
    businessType: undefined,
    ssicCode: '',
    ssicDescription: '',
    ssicOtherDescription: '',
    businessName: '',
    businessRegistrationNumber: '',
    dateOfIncorporation: '',
    countryOfIncorporation: '',
    businessAddressLine_1: '',
    businessAddressLine_2: '',
    businessPostalCode: '',
    city: '',
    country: '',
    formerNames: [],
    beneficial_owners_information: [],
  },
  acraApiTries: 0,
  acraBOFetched: false,
  directorAndBoList: [],
}

export const businessVerification: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case BusinessVerificationActions.INIT_BUSINESS_DATA: {
      return {
        ...state,
        ...action.data,
        initialized: true,
      }
    }

    case BusinessVerificationActions.SET_BUSINESS_DATA: {
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          [action.key]: action.value,
        },
      }
    }

    case BusinessVerificationActions.SET_DIRECT_URL: {
      return {
        ...state,
        [action.page]: { ...state[action.page], ...action.urls },
      }
    }

    case BusinessVerificationActions.SET_ADD_FLOW_STEP: {
      return {
        ...state,
        addFlowStep: action.step,
      }
    }

    case BusinessVerificationActions.SET_ADD_FLOW_META: {
      return {
        ...state,
        addFlowMeta: { ...state.addFlowMeta, ...action.meta },
      }
    }

    case BusinessVerificationActions.SET_CURRENT_PATH: {
      return {
        ...state,
        currentPath: action.path,
      }
    }

    case BusinessVerificationActions.SET_ACRA_BUSINESS_INFO: {
      return {
        ...state,
        acraBusinessInformation: { ...action.data },
      }
    }

    case BusinessVerificationActions.SET_ACRA_BUSINESS_API_TRIES: {
      return {
        ...state,
        acraApiTries: action.data,
      }
    }

    case BusinessVerificationActions.SET_ACRA_BENEFICIAL_OWNER: {
      return {
        ...state,
        directorAndBoList: [...action.data],
      }
    }

    case BusinessVerificationActions.SET_BENEFICIARIES: {
      return {
        ...state,
        beneficiaries: [...action.data],
      }
    }

    case BusinessVerificationActions.SET_ACRABOLIST: {
      return {
        ...state,
        acraBoList: [...action.data],
      }
    }

    case BusinessVerificationActions.SET_ACRA_BENEFICIAL_OWNER_FETCHED: {
      return {
        ...state,
        acraBOFetched: action.data,
      }
    }

    default:
      return state
  }
}
