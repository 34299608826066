import { SVGProps } from 'react'

function CopyToClipboardOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.7581 17.0748C12.2182 17.5051 11.5486 17.7401 10.8581 17.7415H6.88314C5.66824 17.7393 4.50373 17.2557 3.64467 16.3966C2.7856 15.5376 2.30201 14.3731 2.2998 13.1582V7.99982C2.30076 7.52337 2.41182 7.05359 2.62432 6.62715C2.83682 6.20071 3.145 5.82915 3.5248 5.54149V5.37482C3.52613 4.905 3.58775 4.43729 3.70814 3.98315C2.92062 4.3191 2.24898 4.87874 1.77645 5.59272C1.30392 6.3067 1.05127 7.14364 1.0498 7.99982V13.1582C1.0498 14.7053 1.66439 16.189 2.75835 17.2829C3.85231 18.3769 5.33604 18.9915 6.88314 18.9915H10.8331C11.5456 18.9919 12.2472 18.8174 12.8763 18.4832C13.5055 18.149 14.043 17.6653 14.4415 17.0748H12.7581Z"
        fill="currentColor"
      />
      <path
        d="M14.5834 2.25833C15.4093 2.26053 16.2007 2.5896 16.7848 3.17361C17.3688 3.75762 17.6978 4.54908 17.7 5.375V11.4583C17.7 12.2849 17.3717 13.0777 16.7872 13.6621C16.2027 14.2466 15.41 14.575 14.5834 14.575H9.1667C8.34011 14.575 7.54737 14.2466 6.96288 13.6621C6.37839 13.0777 6.05003 12.2849 6.05003 11.4583V5.375C6.05223 4.54908 6.3813 3.75762 6.96531 3.17361C7.54932 2.5896 8.34078 2.26053 9.1667 2.25833H14.5834ZM14.5834 1.00833H9.1667C8.59146 1.00614 8.02145 1.11741 7.48927 1.33577C6.95708 1.55413 6.47319 1.8753 6.06528 2.28089C5.65736 2.68648 5.33344 3.16854 5.11204 3.69946C4.89065 4.23039 4.77612 4.79976 4.77503 5.375V11.4583C4.77392 12.0346 4.88688 12.6054 5.1074 13.1377C5.32791 13.6701 5.65162 14.1536 6.05987 14.5603C6.46811 14.967 6.95282 15.2889 7.48604 15.5074C8.01926 15.7258 8.59046 15.8366 9.1667 15.8333H14.5834C15.7415 15.8333 16.8522 15.3733 17.6711 14.5544C18.49 13.7355 18.95 12.6248 18.95 11.4667V5.375C18.9478 4.21756 18.4871 3.10816 17.6686 2.28973C16.8502 1.4713 15.7408 1.01054 14.5834 1.00833Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CopyToClipboardOutline
