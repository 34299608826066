import type { Reducer } from 'redux'
import type { ActionTypes, State } from './types'
import { VERIFICATION_INDO_ACTIONS } from './types'

const initialState = {
  userDetails: {
    idNumber: undefined,
    fullName: undefined,
    placeOfBirth: undefined,
    dateOfBirth: undefined,
    isDirector: undefined,
  },
  error: '',
  screen: undefined,
  kycRejectedReason: '',
  retrievedDetails: undefined,
}

export const verificationIndo: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case VERIFICATION_INDO_ACTIONS.SET_USER_DETAILS: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          [action.key]: action.value,
        },
      }
    }

    case VERIFICATION_INDO_ACTIONS.UPDATE_USER: {
      const errorMsg =
        action.error === 'Same request is still processing'
          ? 'Please try again in a minute as we are still processing.'
          : action.error
      return {
        ...state,
        error: errorMsg,
      }
    }

    case VERIFICATION_INDO_ACTIONS.SET_SCREEN: {
      return {
        ...state,
        screen: action.screen,
      }
    }

    case VERIFICATION_INDO_ACTIONS.SET_MULTIPLE_USER_DETAILS: {
      return {
        ...state,
        userDetails: action.userDetails,
      }
    }

    case VERIFICATION_INDO_ACTIONS.SET_KYC_REJECTED_REASON: {
      return {
        ...state,
        kycRejectedReason: action.kycRejectedReason,
      }
    }

    case VERIFICATION_INDO_ACTIONS.SET_RETRIEVED_DETAILS: {
      return {
        ...state,
        retrievedDetails: action.retrievedDetails,
      }
    }

    case VERIFICATION_INDO_ACTIONS.SET_ERROR: {
      const errorMsg =
        action.error === 'Same request is still processing'
          ? 'Please try again in a minute as we are still processing.'
          : action.error
      return {
        ...state,
        error: errorMsg,
      }
    }

    default:
      return state
  }
}
