import { cx, css } from 'emotion'
import AntSteps, { StepsProps, StepProps } from 'antd/es/steps'
import 'antd/es/steps/style/css'
import {
  baseStyle,
  ActiveDesktopStepperStyle,
  InactiveDesktopStepperStyle,
} from '../../../themes/deprecated/fontStyles'
import { fazzbizTheme } from '../../../themes/deprecated/theme'
import { useTheme } from '../../../themes/deprecated/themeContext'

function DesktopSteps({
  className,
  ...rest
}: StepsProps & {
  children: React.ReactNode
}) {
  const { base, interactive } = useTheme()
  const style = `
      & .ant-steps-item-title {
        padding-right: 0;
      }

      & .ant-steps-item {
        height: 65px;
      }
  
      & .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        ${baseStyle}
        ${InactiveDesktopStepperStyle}
      }
  
      & .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        ${baseStyle}
        ${ActiveDesktopStepperStyle}
      }
  
     
  
      &.ant-steps-dot .ant-steps-item-icon {
        width: 13px;
        height: 13px;
      }
  
      &.ant-steps-dot .ant-steps-item-content {
        width: unset;
        max-width: 100%;
      }
  
   

      &.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon {
        width: 13px;
        height: 13px;
      }
  
      &.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
        margin-top: 6px;
      }
  
      &.ant-steps-dot .ant-steps-item-tail::after {
        margin-left: 15px;
        background: ${base.line}
      }
  
      & .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
        background: ${base.line};
      }

      & .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
        background: ${base.line};
      }

      & .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
        background: ${base.line};
      }
  
      &.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
        top: 0;
      }
  
      &.ant-steps-vertical .ant-steps-item-icon {
        margin-right: 10px;
      }
  
      &.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
        left: 0;
      }
  
      & .ant-steps-item-icon > .ant-steps-icon {
        top: 0;
      }
  
      &.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
        top: 15px;
        left: 0px;
      }
  
    
  
      & .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        ${baseStyle}
        ${InactiveDesktopStepperStyle}
        color: ${fazzbizTheme.base.neutralSecondary};
      }


      .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        margin: 10px 0 0 15px;
        color: ${base.primary}
      }

      .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        margin: 10px 0 0 15px;
        font-weight: bold;
        color: ${interactive.inactive}
      }

      .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        margin: 10px 0 0 15px;
        font-weight: bold;
        color: ${base.neutral}
      }
    `

  return <AntSteps {...rest} className={cx(css(style), className)} />
}

function DesktopStep({ ...props }: StepProps) {
  const ellipseStyle = css`
    & .ant-steps-item-title {
      width: 100%;
      white-space: prep;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `
  return <AntSteps.Step className={cx(ellipseStyle)} {...props} />
}
DesktopSteps.Step = DesktopStep

export default DesktopSteps
