// Thinking of combining these functions with SSOManager to a SessionUtils
import clevertap, { onUserLogin } from 'clevertap-web-sdk'
import Cookies from 'js-cookie'
import type { UserRoles } from '~/types'

export type EventProperties = Record<string, string | boolean | number | Date>

type ClevertapUserProperties = {
  Name: string
  Identity: string
  Email: string
  Phone: string
  userRole: UserRoles | null
  hasEarnAccount: boolean
  hasSGDAccount: boolean
  hasUSDAccount: boolean
  hasIDRCashAccount: boolean
  accountManagerVerified: boolean
  bizVerified: boolean
  merchantRegion: 'SG' | 'ID'
  bizId: string
}

export default {
  onLogin: (userProperties: ClevertapUserProperties) => {
    onUserLogin.push({
      Site: { ...userProperties },
    })
  },

  /**
   * Method for clevertap custom events to track on Clevertap SDK or API.
   * @param {boolean} isClevertapInitiated - whether CleverTap is initialized or not
   * @param {string} eventName - name of the custom event
   * @param {EventProperties} eventProperties - Additional properties to send with the event. Value must be String, Boolean, Integer, or Float, or a Date object.
   * @param {boolean} triggerNps - To trigger additional push event to track Net Promoter Score (NPS)
   */
  pushCustomEvent: (
    isClevertapInitiated: boolean,
    eventName: string,
    eventProperties?: EventProperties,
    triggerNps?: boolean
  ) => {
    // if statement here so that we don't push events unnecessarily if CT is not initialized
    if (!isClevertapInitiated) {
      return
    }

    if (eventProperties) {
      clevertap.event.push(eventName, { ...eventProperties })
    } else {
      clevertap.event.push(eventName)
    }

    if (triggerNps) {
      clevertap.event.push('nps_campaign_event', {
        relatedEvent: eventName,
        ...eventProperties,
      })
    }
  },

  removeClevertapCookies: () => {
    const cookies = Object.keys(Cookies.get())
    cookies.forEach((cookie) => {
      if (cookie.startsWith('WZRK')) {
        Cookies.remove(cookie)
      }
    })
  },

  removeClevertapLocalStorage: () => {
    const localStorageKeys = Object.keys(localStorage)

    localStorageKeys.forEach((key) => {
      if (key.startsWith('WZRK')) {
        localStorage.removeItem(key)
      }
    })
  },

  removeClevertapSessionsStorage: () => {
    const sessionStorageKeys = Object.keys(sessionStorage)

    sessionStorageKeys.forEach((key) => {
      if (key.startsWith('WZRK')) {
        sessionStorage.removeItem(key)
      }
    })
  },
}
