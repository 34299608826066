import { css } from 'emotion'
import { fdsTheme } from 'fds3-src/themes/theme-fds3'
import styled, { createGlobalStyle } from 'styled-components'
import { Select } from 'antd'

const { spacing, colors, radius, fontStyles, fontWeight, shadow } = fdsTheme

export const dropdownStyle = css`
  &.ant-select-dropdown {
    padding: ${spacing.sm} 0;
    border-radius: ${radius.lg};
    box-shadow: ${shadow.action};
  }
  .ant-select-item-option-content {
    padding: ${spacing.xs} ${spacing.md};
    ${fontStyles.bodyLarge}
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 500;
    background-color: ${colors.surfaceNeutral};
    border-left: 2px solid ${colors.active};
  }
`

export const BaseSelectStyles = createGlobalStyle`
  .ant-select-selector {
    display: flex;
    align-items: center;
    background-color: ${colors.surfaceNeutral} !important;
    border: 1px solid ${colors.line} !important;
    border-radius: ${radius.lg} !important;
    padding: 0 ${spacing.md} !important;
    height: auto;
    min-height: 56px;
    min-width: 50px;
    padding: ${spacing.sm} !important;
    margin-right: ${spacing.md};

    &:hover {
      border-color: ${colors.active} !important;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      padding: 0;
      text-align: left;
      padding-right: ${spacing.md};
      ${fontStyles.bodyLarge};
      color: ${colors.placeholderInput};
    }

    .ant-select-selection-item {
      color: ${colors.onNeutral};
    }
  }

  // disabled select
  &.ant-select-disabled {
    .ant-select-selector {
      background-color: ${colors.surfaceDisabledSurface} !important;

      &:hover {
        border: 1px solid ${colors.line} !important;
      }
    }
  }

  // dropdown arrow
  .ant-select-arrow {
    width: 16px;
    height: 16px;
    // FIXME: Temp solution for now, should find a more permanent solution in the future.
    right: 16px;
    top: 47%;
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: ${colors.active};
    border-right-width: 1px;
    box-shadow: none;
  }
`

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    .ant-select-selection-item {
      ${(props) =>
        props.mode === 'multiple' &&
        ` color: ${colors.active};
      ${fontStyles.bodyMedium};
      border-color: ${colors.active};
      background: ${colors.surfaceSubdueInformation};
      border-radius: ${radius.lg};
      padding: 0 ${spacing.md};
      font-weight: ${fontWeight.bold};
      height: 32px;
      line-height: 32px;`}
    }
  }
`
