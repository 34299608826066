import * as React from 'react'

function SvgCimb(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.093 20.095a3.59 3.59 0 110-5.624l.029.027 1.529-1.526-.036-.032a5.72 5.72 0 00-3.751-1.404 5.748 5.748 0 103.751 10.098l.036-.036-1.53-1.528-.028.025zm3.63 2.615h2.198V11.854h-2.199V22.71zm10.858-3.918c-.081-.144-3.834-6.957-3.834-6.957h-2.143v10.857h2.188v-6.485l3.786 6.572 3.767-6.572v6.485h2.188V11.836h-2.146s-3.731 6.813-3.812 6.957l.006-.001zm14.99-1.644a2.564 2.564 0 001.274-2.22c0-1.895-1.295-3.07-3.375-3.07H52.1V22.71h4.55c2.06 0 3.346-1.202 3.346-3.145a2.549 2.549 0 00-1.425-2.418zm-4.276-3.326h2.014c.837 0 1.343.446 1.343 1.195 0 .749-.505 1.192-1.343 1.192h-2.011l-.003-2.387zm2.146 6.925h-2.143v-2.555h2.146a1.28 1.28 0 110 2.555M16.566 9H0v16.569h16.566V9z"
        fill="#700019"
      />
      <path d="M10.735 12.132H1.93l3.896 5.152h8.809l-3.9-5.152z" fill="#fff" />
      <path d="M14.633 17.283H5.825L1.93 22.44h8.803l3.9-5.156z" fill="#E50019" />
    </svg>
  )
}

export default SvgCimb
