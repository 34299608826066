import { Reducer } from 'redux'
import { ActionTypes, CustomerCardActions } from './types'

export type State = {
  isCustomerCardAdminPanelEnabled: boolean
  cardActiveTab: string
}

export const initialState: State = {
  isCustomerCardAdminPanelEnabled: false,
  cardActiveTab: 'transactions',
}

export const customerCard: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case CustomerCardActions.SET_CUSTOMER_CARDS_CONFIG: {
      return {
        ...state,
        isCustomerCardAdminPanelEnabled: action.isCustomerCardAdminPanelEnabled,
      }
    }
    case CustomerCardActions.SET_ACTIVE_TAB: {
      return {
        ...state,
        cardActiveTab: action.cardActiveTab,
      }
    }
    default:
      return state
  }
}
