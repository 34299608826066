function CornerSupergraphic() {
  return (
    <svg width={381} height={800} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M380.8 800H0L380.8 0v800Z" fill="url(#a)" />
      <path d="M380.8 800H86.4l294.4-619.2V800Z" fill="url(#b)" />
      <defs>
        <linearGradient
          id="a"
          x1={381.407}
          y1={0}
          x2={381.407}
          y2={799.394}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0925D9" stopOpacity={0} />
          <stop offset={1} stopColor="#00C1D5" />
        </linearGradient>
        <linearGradient
          id="b"
          x1={381.269}
          y1={180.8}
          x2={381.269}
          y2={799.531}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00C1D5" stopOpacity={0} />
          <stop offset={1} stopColor="#0925D9" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export default CornerSupergraphic
