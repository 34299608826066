import * as React from 'react'

function SvgCompany(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5v20h3v-6a2 2 0 012-2h6a2 2 0 012 2v6h3V5H7zm11.003 23H24a2 2 0 002-2V4a2 2 0 00-2-2H6a2 2 0 00-2 2v22a2 2 0 002 2h12.003zM17 25v-5h-4v5h4zM9 9.5A1.5 1.5 0 0110.5 8h2a1.5 1.5 0 010 3h-2A1.5 1.5 0 019 9.5zm8.5 2.5a1.5 1.5 0 000 3h2a1.5 1.5 0 000-3h-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCompany
