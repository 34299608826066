import * as React from 'react'

function SvgZilswap(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#zilswap_svg__clip0)" fill="#29CCC4">
        <path d="M21.118 21.02l-3.12-1.819-3.12-1.797-3.12-1.798-3.12-1.798-3.12-1.797-3.12 1.797 3.12 1.798 3.12 1.798 3.12 1.797 3.12 1.819 3.12 1.797 3.12 1.798-3.12 1.797-3.12-1.797-3.12-1.798-3.12-1.797v3.595l3.12 1.797 3.12 1.798 3.12 1.798 3.12-1.798 3.12-1.798 3.12-1.797-3.12-1.798-3.12-1.797zM11.757 8.395l-3.12 1.818 3.12 1.798 3.12 1.798 3.12 1.797 3.12 1.798 3.12 1.797 3.12 1.819 3.12 1.797 3.12-1.797-3.12-1.819-3.12-1.797-3.12-1.798-3.12-1.797-3.12-1.798-3.12-1.798 3.12-1.818 3.12 1.818 3.12 1.798 3.12 1.798v-3.596l-3.12-1.818-3.12-1.798-3.12-1.797-3.12 1.797-3.12 1.798z" />
      </g>
      <defs>
        <clipPath id="zilswap_svg__clip0">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgZilswap
