import styled from '@emotion/styled'
import { Body } from '../../../themes/Typography'
import { DESKTOP_BREAKPOINT } from '../../../themes/deprecated/breakPoints'
import { fdsTheme } from '../../../themes/theme-fds3'
import Card from '../Card'
import Divider from '../Divider'

type ActionCardProps = {
  title: React.ReactNode
  footer?: React.ReactNode
  children: React.ReactNode
  style?: React.CSSProperties
}

const { colors, spacing, radius, fontStyles } = fdsTheme

function ActionCard({ children, title, footer, style }: ActionCardProps) {
  const ResponsiveCard = styled(Card)`
    border-style: none;
    background-color: ${colors.primary};
    border-radius: ${radius.lg};
    border: 0;

    & > div.ant-card-body {
      width: 100%;
      bottom: 0;
      right: 0;
      background: ${colors.surfaceNeutral};
      border: 'none';
      border-top: 1px solid ${colors.line};
      ${!footer && `display: none;`}
      border-radius: 0 0 ${radius.lg} ${radius.lg};
    }
    ${DESKTOP_BREAKPOINT} {
      border-style: solid;
      & > div.ant-card-body {
        position: static;
        z-index: auto;
        width: auto;
      }
    }
  `
  const Title = styled.div`
    ${fontStyles.titleSmall}

    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > .badge-icon {
      margin-right: ${spacing.sm};
    }
  `

  const Container = styled.div`
    min-height: calc(100vh - 100px);
    padding: ${spacing.md};
    ${DESKTOP_BREAKPOINT} {
      padding: ${spacing.lg};
      min-height: unset;
    }
  `

  const AlignRightContainer = styled.div`
    display: flex;
    justify-content: flex-end;

    & > button[type='button'],
    button[type='submit'] {
      margin-left: ${spacing.sm};
    }

    & > button[type='button']:last-child,
    button[type='submit']:last-child {
      margin-right: ${spacing.md};
    }
  `

  return (
    <ResponsiveCard
      style={style}
      title={
        <Container>
          <Title>{title}</Title>
          <Divider spacing="lg" />
          <Body>{children}</Body>
        </Container>
      }
    >
      <AlignRightContainer>{footer}</AlignRightContainer>
    </ResponsiveCard>
  )
}

export default ActionCard
