import copy from 'copy-to-clipboard'
import 'antd/es/button/style/css'
import styled from '@emotion/styled'
import { fazzbizTheme } from '../../../themes/deprecated/theme'
import { useTheme } from '../../../themes/deprecated/themeContext'
import Button from '../DeprecatedButton/Button'
import Notification from '../Notification'

type CopyBoxType = {
  value: string
  prefix?: string
  withCopy?: boolean
  valueToCopy?: string
  label?: string
  notificationCopiedMessage?: string
  onButtonClick?: () => void
}

function CopyBox({
  value,
  prefix,
  withCopy = true,
  valueToCopy = value,
  label,
  notificationCopiedMessage = 'Copied!',
  onButtonClick,
  ...props
}: CopyBoxType) {
  const { base } = useTheme()
  return (
    <Container data-cy={label} {...props}>
      {prefix && <Prefix>{prefix}</Prefix>}
      <InputValue withPrefix={!!prefix} withCopy={withCopy}>
        {value}
      </InputValue>
      {withCopy && (
        <Button
          type="secondary"
          style={{
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px',
            color: base.primary,
            height: 'auto',
            backgroundColor: '#ffffff',
            border: '1px solid #E0E0E0',
          }}
          onClick={() => {
            if (onButtonClick !== undefined) onButtonClick()
            copy(valueToCopy)
            Notification({ type: 'success', message: notificationCopiedMessage })
          }}
        >
          Copy
        </Button>
      )}
    </Container>
  )
}

export default CopyBox

const Container = styled.div`
  display: flex;
`

const Prefix = styled.div`
  width: 48px;
  border: 1px solid #e8e8e8;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-sizing: border-box;
  background: #f6f7f9;
  color: ${fazzbizTheme.base.neutral};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 15px;
  padding-right: 12px;
  word-break: break-all;
`

const InputValue = styled.div<{ withPrefix: boolean; withCopy: boolean }>`
  border: 1px solid #e8e8e8;
  border-right: 0px;
  box-sizing: border-box;
  background: #f6f7f9;
  color: ${fazzbizTheme.base.neutral};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 15px;
  padding-right: 12px;
  flex-basis: 0px;
  flex-grow: 1;
  flex-shrink: 1;
  word-break: break-all;
  border-radius: 0px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;

  // if withPrefix is false then:
  ${(props) =>
    props.withPrefix ||
    `
      border-left: 1px solid #e8e8e8 !important;
  `}

  // if withCopy is false then:
  ${(props) =>
    props.withCopy ||
    `
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
      border-right: 1px solid #e8e8e8 !important;
  `}
`
