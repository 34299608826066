import { CardFds3 } from 'fds3-src/index'
import styled from 'styled-components'
import { fdsTheme } from '../../../themes/theme-fds3'
import type { InfoCardProps } from './InfoCard.types'

const { spacing } = fdsTheme

export const StyledInfoCard = styled(CardFds3).attrs((props: InfoCardProps) => ({
  bgColor: props.bgColor,
}))`
  display: flex;
  gap: ${spacing.xs};
  background-color: ${({ bgColor }) => bgColor};
  text-align: left;
`
