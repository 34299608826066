import styled from 'styled-components'
import Typography from 'antd/lib/typography'
import { TypographyProps } from './Typography.types'
import { fdsTheme } from './theme-fds3'

const { Text } = Typography

export const StyledDisplay = styled(Text).attrs((props: TypographyProps) => ({
  size: props.size,
  display: props.display,
  color: props.color,
  inline: props.inline,
}))`
  ${({ size, display, color, inline }) => {
    switch (size) {
      case 'medium':
      case 'md':
        // font-family: FazzNeue;
        // font-weight: 700;
        // letter-spacing: -0.03em;
        // font-size: 45px;
        // line-height: 54px;
        return `
          ${fdsTheme.fontStyles.displayMedium}
          display: ${inline ? 'inline' : display};
          color: ${color};
        `
      case 'small':
      case 'sm':
        // font-family: FazzNeue;
        // font-weight: 700;
        // letter-spacing: -0.03em;
        // font-size: 36px;
        // line-height: 44px;
        return `
          ${fdsTheme.fontStyles.displaySmall}
          display: ${inline ? 'inline' : display};
          color: ${color};
        `
      case 'large':
      case 'lg':
      default:
        // font-family: FazzNeue;
        // font-weight: 700;
        // letter-spacing: -0.03em;
        // font-size: 64px;
        // line-height: 78px;
        return `
          ${fdsTheme.fontStyles.displayLarge}
          display: ${inline ? 'inline' : display};
          color: ${color};
        `
    }
  }}
`

export const StyledHeadline = styled(Text).attrs((props: TypographyProps) => ({
  size: props.size,
  display: props.display,
  color: props.color,
  inline: props.inline,
}))`
  ${({ size, display, color, inline }) => {
    switch (size) {
      case 'medium':
      case 'md':
        // font-family: FazzNeue;
        // font-weight: 400;
        // letter-spacing: 0em;
        // font-size: 24px;
        // line-height: 42px;
        return `
          ${fdsTheme.fontStyles.headlineMedium}
          display: ${inline ? 'inline' : display};
          color: ${color};
        `
      case 'small':
      case 'sm':
        // font-family: FazzNeue;
        // font-weight: 400;
        // letter-spacing: 0em;
        // font-size: 20px;
        // line-height: 36px;
        return `
          ${fdsTheme.fontStyles.headlineSmall}
          display: ${inline ? 'inline' : display};
          color: ${color};
        `
      case 'large':
      case 'lg':
      default:
        // font-family: FazzNeue;
        // font-weight: 400;
        // letter-spacing: 0em;
        // font-size: 28px;
        // line-height: 48px;
        return `
          ${fdsTheme.fontStyles.headlineLarge}
          display: ${inline ? 'inline' : display};
          color: ${color};
        `
    }
  }}
`

export const StyledTitle = styled(Text).attrs((props: TypographyProps) => ({
  size: props.size,
  display: props.display,
  color: props.color,
  inline: props.inline,
}))`
  ${({ size, display, color, inline }) => {
    switch (size) {
      case 'medium':
      case 'md':
        // font-family: FazzNeue;
        // font-weight: 700;
        // letter-spacing: -0.02em;
        // font-size: 24px;
        // line-height: 36px;
        return `
          ${fdsTheme.fontStyles.titleMedium}
          display: ${inline ? 'inline' : display};
          color: ${color};
        `
      case 'small':
      case 'sm':
        // font-family: FazzNeue;
        // font-weight: 700;
        // letter-spacing: -0.02em;
        // font-size: 20px;
        // line-height: 30px;
        return `
          ${fdsTheme.fontStyles.titleSmall}
          display: ${inline ? 'inline' : display};
          color: ${color};
        `
      case 'large':
      case 'lg':
      default:
        // font-family: FazzNeue;
        // font-weight: 700;
        // letter-spacing: -0.02em;
        // font-size: 28px;
        // line-height: 42px;
        return `
          ${fdsTheme.fontStyles.titleLarge}
          display: ${inline ? 'inline' : display};
          color: ${color};
        `
    }
  }}
`

export const StyledLabel = styled(Text).attrs((props: TypographyProps) => ({
  size: props.size,
  display: props.display,
  color: props.color,
  inline: props.inline,
}))`
  ${({ size, display, color, inline }) => {
    switch (size) {
      case 'medium':
      case 'md':
        // font-family: FazzNeue;
        // font-weight: 700;
        // letter-spacing: -0.01em;
        // font-size: 14px;
        // line-height: 20px;
        return `
          ${fdsTheme.fontStyles.labelMedium}
          display: ${inline ? 'inline' : display};
          color: ${color};
        `
      case 'small':
      case 'sm':
        // font-family: FazzNeue;
        // font-weight: 700;
        // letter-spacing: -0.01em;
        // font-size: 12px;
        // line-height: 18px;
        return `
          ${fdsTheme.fontStyles.labelSmall}
          display: ${inline ? 'inline' : display};
          color: ${color};
        `
      case 'large':
      case 'lg':
      default:
        // font-family: FazzNeue;
        // font-weight: 700;
        // letter-spacing: -0.01em;
        // font-size: 16px;
        // line-height: 24px;
        return `
          ${fdsTheme.fontStyles.labelLarge}
          display: ${inline ? 'inline' : display};
          color: ${color};
        `
    }
  }}
`

export const StyledBody = styled(Text).attrs((props: TypographyProps) => ({
  size: props.size,
  display: props.display,
  color: props.color,
  inline: props.inline,
}))`
  ${({ size, display, color, inline }) => {
    switch (size) {
      case 'medium':
      case 'md':
        // font-family: FazzNeue;
        // font-weight: 400;
        // letter-spacing: -0.01em;
        // font-size: 14px;
        // line-height: 20px;
        return `
          ${fdsTheme.fontStyles.bodyMedium}
          display: ${inline ? 'inline' : display};
          color: ${color};
        `
      case 'small':
      case 'sm':
        // font-family: FazzNeue;
        // font-weight: 400;
        // letter-spacing: -0.01em;
        // font-size: 12px;
        // line-height: 18px;
        return `
          ${fdsTheme.fontStyles.bodySmall}
          display: ${inline ? 'inline' : display};
          color: ${color};
        `
      case 'large':
      case 'lg':
      default:
        // font-family: FazzNeue;
        // font-weight: 400;
        // letter-spacing: -0.01em;
        // font-size: 16px;
        // line-height: 24px;
        return `
          ${fdsTheme.fontStyles.bodyLarge}
          display: ${inline ? 'inline' : display};
          color: ${color};
        `
    }
  }}
`
