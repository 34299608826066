import * as React from 'react'

function SvgIdFlag(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <path fill="url(#id_flag_svg__pattern0)" stroke="#E8E8E8" d="M-17.5-1.5h64v33h-64z" />
      <defs>
        <pattern
          id="id_flag_svg__pattern0"
          patternContentUnits="objectBoundingBox"
          width={1}
          height={1}
        >
          <use xlinkHref="#id_flag_svg__image0" transform="matrix(.02778 0 0 .0531 0 -.137)" />
        </pattern>
        <image
          id="id_flag_svg__image0"
          width={36}
          height={24}
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAYAQMAAACods0NAAAABlBMVEX/AAD///9BHTQRAAAAEUlEQVQI12NgoBL4DwQfKCcBULA7EWvmCwgAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  )
}

export default SvgIdFlag
