import { css } from 'emotion'
import { notification } from 'antd'
import { MOBILE_LAPTOP_BREAKPOINT } from '../../../themes/deprecated/breakPoints'
import { fdsTheme } from '../../../themes/theme-fds3'
import { ToastType, ToastProps } from './Toast.types'

const { colors, radius, spacing, fontSize } = fdsTheme

export const fontColor = colors.surfaceNeutral
export const backgroundColorMapping: Record<ToastType, string> = {
  default: colors.onNeutral,
  positive: colors.positive,
  warning: colors.warning,
  critical: colors.critical,
}

function getStyle(type: ToastType) {
  return css`
    background-color: ${backgroundColorMapping[type]};
    color: ${fontColor};
    border-radius: ${radius.xs};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: ${fontSize.bodyMedium};
    padding: ${spacing.md};
    .ant-notification-notice-content div:first-child {
      display: flex;
      align-items: center;
    }
    .ant-notification-notice-message {
      display: none !important;
    }

    .ant-notification-notice-with-icon .ant-notification-notice-description {
      margin-left: ${spacing.xxl};
    }

    .ant-notification-notice-close {
      color: ${colors.surfaceNeutral} !important;
      top: 50%;
      transform: translateY(-50%);
    }

    .ant-notification-notice-close:hover {
      color: ${colors.surfaceNeutral};
    }

    ${MOBILE_LAPTOP_BREAKPOINT} {
      .ant-notification-notice-close {
        display: none;
      }
    }
  `
}

export const StyledToast = ({
  type,
  description,
  icon,
  duration,
  onClick,
  onClose,
}: ToastProps) => {
  notification.open({
    message: '',
    description,
    className: getStyle(type),
    duration,
    icon,
    onClick,
    onClose,
  })
}
