import { ThemeProvider } from 'styled-components'
import type { Key } from 'react'
import { cloneElement, useState } from 'react'
import { Badge } from '../Badge'
import { MenuItemContainer, MenuItemLabel, StyledMenu, iconStyles, menuThemes } from './Menu.styles'
import { isDivider, type MenuProps } from './Menu.types'

export function Menu({
  style,
  className,
  onClick,
  items,
  colorScheme = 'default',
  collapseInactiveMenus = false,
}: // DO NOT USE SPREAD OPERATOR i.e. {...props}
MenuProps) {
  const [openKeys, setOpenKeys] = useState<Key[]>([])

  const rootSubmenuKeys = items.map((item) => !isDivider(item) && item.key)

  const onOpenChange: ((openKeys: Key[]) => void) | undefined = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)

    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const collapsibleProps = collapseInactiveMenus
    ? {
        openKeys: openKeys as string[],
        onOpenChange,
      }
    : {}

  return (
    <ThemeProvider theme={menuThemes[colorScheme]}>
      <StyledMenu
        className={className}
        style={style}
        mode="inline"
        inlineIndent={12}
        {...collapsibleProps}
        // DO NOT USE SPREAD OPERATOR i.e. {...props}
      >
        {items.map((item) => {
          if (isDivider(item)) {
            return <StyledMenu.Divider key={item.type} />
          }

          const clonedIcon = item.icon && cloneElement(item.icon, iconStyles.base)

          if (!item.subItems) {
            return (
              <StyledMenu.Item
                icon={clonedIcon}
                key={item.key}
                onClick={onClick ? (info) => onClick({ ...info, path: item.path }) : undefined}
              >
                <MenuItemContainer>
                  <MenuItemLabel>{item.render ? item.render() : item.label}</MenuItemLabel>
                  {item.badge && <Badge variant={item.badge.variant}>{item.badge.label}</Badge>}
                </MenuItemContainer>
              </StyledMenu.Item>
            )
          }
          return (
            <StyledMenu.SubMenu
              icon={clonedIcon}
              key={item.key}
              title={
                <MenuItemContainer>
                  <MenuItemLabel>{item.label}</MenuItemLabel>
                  {item.badge && (
                    <Badge size="xs" variant={item.badge.variant}>
                      {item.badge.label}
                    </Badge>
                  )}
                </MenuItemContainer>
              }
            >
              {item.subItems.map((subItem) => (
                <StyledMenu.Item
                  key={subItem.key}
                  onClick={onClick ? (info) => onClick({ ...info, path: subItem.path }) : undefined}
                >
                  <MenuItemContainer>
                    <MenuItemLabel>
                      {subItem.render ? subItem.render() : subItem.label}
                    </MenuItemLabel>
                    {subItem.badge && (
                      <Badge variant={subItem.badge.variant}>{subItem.badge.label}</Badge>
                    )}
                  </MenuItemContainer>
                </StyledMenu.Item>
              ))}
            </StyledMenu.SubMenu>
          )
        })}
      </StyledMenu>
    </ThemeProvider>
  )
}

export default Menu
