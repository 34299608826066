import { SVGProps } from 'react'

function SvgDepositTwoTone(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m16.823 7.8-.833-1.455a8.476 8.476 0 0 0-7.343-4.26H6.75a.187.187 0 0 1-.135-.06l-.292-.293.105-.135A.563.563 0 1 0 5.573.87l-4.5 5.25a.563.563 0 0 0 .855.727l.202-.217a6.495 6.495 0 0 0 6 1.86h3.06l.54.105a3.518 3.518 0 0 1 1.935 1.155l.203.225a1.83 1.83 0 0 0 2.955-2.138V7.8Zm-.915 1.132a.75.75 0 0 1-1.2.255l-.203-.225a4.688 4.688 0 0 0-1.942-1.282c.165-.266.253-.572.255-.885A1.725 1.725 0 0 0 11.1 5.077H7.793a.165.165 0 0 1-.128-.052l-.45-.405a.578.578 0 0 0-.382-.12.555.555 0 0 0-.413.18.563.563 0 0 0 0 .795l.45.412c.244.218.559.338.885.338H11.1a.592.592 0 0 1 0 1.185H7.883A5.385 5.385 0 0 1 2.85 5.797l2.738-3.21.232.233a1.312 1.312 0 0 0 .93.39h1.898A7.305 7.305 0 0 1 15 6.9l.833 1.455a.683.683 0 0 1 .075.577ZM9 9.75a3 3 0 1 0 3.038 3A3.037 3.037 0 0 0 9 9.75Zm.75 4.178a.945.945 0 0 1-.562.262v.428l-.375.075v-.488a1.972 1.972 0 0 1-.375-.045 2.247 2.247 0 0 1-.36-.12l.075-.427c.226.035.454.055.682.06.3 0 .45-.083.45-.255a.173.173 0 0 0-.067-.135 1.23 1.23 0 0 0-.255-.135l-.12-.068-.195-.098a1.372 1.372 0 0 1-.458-.345.75.75 0 0 1-.142-.457.75.75 0 0 1 .202-.555.923.923 0 0 1 .57-.262v-.42l.368-.075v.48c.192.017.38.068.555.15l-.075.427a3.885 3.885 0 0 0-.57-.045.488.488 0 0 0-.278.067.21.21 0 0 0-.105.196.195.195 0 0 0 .075.15.9.9 0 0 0 .225.142l.21.105c.143.073.281.156.413.248a.826.826 0 0 1 .232.255.66.66 0 0 1 .083.337.75.75 0 0 1-.203.532v.015Z"
        fill="currentColor"
      />
      <path
        d="M9 17.332c-1.035 0-6.195-.09-6.195-1.897 0-.968 1.5-1.343 1.973-1.463a.565.565 0 0 1 .277 1.095c-.357.071-.702.198-1.02.375.488.308 2.19.75 4.965.75s4.5-.457 4.965-.75a3.487 3.487 0 0 0-1.02-.375.565.565 0 1 1 .278-1.095c.487.12 1.972.495 1.972 1.463 0 1.815-5.16 1.897-6.195 1.897Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgDepositTwoTone
