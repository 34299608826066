export enum PERMISSIONS {
  TRANSACTION = 'transaction:view',

  DEPOSIT = 'deposit:execute',
  WITHDRAW = 'withdraw:execute',
  TRANSFER_OUT = 'transferOut:execute',
  DISBURSEMENTS = 'disbursements:execute',
  INVOICING = 'invoicing:view',
  INVOICING_CREATE_INVOICE = 'invoicingCreateInvoice:execute',

  DEVELOPER_TOOLS = 'developerTools:view',
  DEVELOPER_TOOLS_SG = 'developerToolsSg:view',
  DEVELOPER_TOOLS_GENERATE_SECRET_KEY = 'developerToolsGenerateSecretKey:edit',

  TEAM_READ = 'team:read',
  TEAM_EXECUTE = 'team:execute',

  // To create new business account
  CREATE_NEW_MERCHANT = 'merchantAccount:create',

  SANDBOX = 'sandbox:view',

  MANAGE_ACCOUNT_BANK = 'bankAccount:edit',

  /**
   * For Card Issuing feature: debit cards, user can see their own card or company cards.
   */
  MY_CARD = 'myCard:view',
  MY_CARD_MANAGEMENT = 'myCard:management',
  ALL_COMPANY_CARDS = 'allCompanyCards:view',

  /**
   * For Card Issuing feature: customer cards, user can see their customer cards.
   */
  CUSTOMERS_CARDS = 'customersCards:view',

  /**
   * For Upload Receipt feature: [open in Setting Menu]
   */
  ENABLE_UPLOAD_RECEIPT_FEATURE = 'enableUploadReceiptFeature:edit',

  API_MENU = 'apiMenu:view',

  XFERS_ACCEPT = 'xfersAccept:view',
  XFERS_SEND = 'xfersSend:view',

  VERIFICATION = 'verification:edit',

  // Regional permissions
  MANAGE_ACCOUNT_BANK_ADD_ACCOUNT = 'addBankAccount:edit',
  DISBURSEMENTS_PROCEED_TRANSFER_DETAILS = 'disbursementsProceedTransferDetails:view',

  SETTINGS = 'settings:view',

  // ID only
  ACCOUNT_STATEMENT = 'accountStatement:read',
  APPLY_LOAN = 'loan:applyLoan',
  APPLY_LOAN_EXECUTE = 'loan:applyLoanExecute',
  LOAN_ACCOUNT = 'loan:loanAccount',
  FLEX_PAY_ACCOUNT = 'loan:flexPayAccount',

  ID_PAY = 'idPay:view',
  ID_PAY_EXECUTE = 'idPay:execute',

  NEED_KYC = 'needKyc:view',
  ID_EARN = 'idEarn:execute',

  // SG only
  TRANSACTION_ACCOUNT_BALANCE = 'transactionAccountBalance:read',
  RECEIVE_TRANSACTION_LIST = 'receiveTransactionList:read',
  PAY_TRANSACTION_LIST = 'payTransactionList:read',
  EARN = 'earn:view',
  EARN_EXECUTE = 'earn:execute',
  USD_EARN = 'usdEarn: execute',
  USD_ACCOUNT = 'usdAccount:view',
  USD_ACCOUNT_WALLEX_ACCOUNT_VERIFICATION = 'usdAccountWallexVerification:execute',
  SGD_ACCOUNT_VA_AND_PAYNOW_QR = 'sgdAccountVaPaynowQr:read',
  SG_CREDITLINE = 'sgCreditline:view',
  SG_LOAN = 'sgLoan:view',
  PHYSICAL_CARDHOLDER_KYC = 'physicalCardholderKYC:execute',
  TERM_LOAN = 'termLoan:view',
  SG_RECIPIENTS = 'sgRecipients:view',
  PAYMENT_APPROVAL_SETTINGS = 'paymentApproval:execute',

  // NEW_FEATURE_ONLY_MERCHANT_SINGAPORE [PLAN ID IN THE FUTURE]
  INTEGRATION = 'integrations:execute',

  // SANDBOX
  SB_LANDING_PAGE = 'sandboxLandingPage:view',
  SB_DEPOSIT = 'sandboxDeposit:execute',
  SB_WITHDRAWAL = 'sandboxWithdrawal:execute',
  SB_DISBURSEMENTS = 'sandboxDisbursement:execute',
  SB_INVOICING = 'sandboxInvoicing:execute',
  SB_PAYMENT_API = 'sandboxPaymentAPI:execute',
  SB_DEVELOPER_TOOLS = 'sandboxDeveloperTools:execute',
  SB_EARN = 'sandboxEarn:execute',
  SB_PAY_MENU = 'sandboxPay:execute',

  // MORAK
  MORAK_LOAN = 'morakLoan:read',

  // LOAN
  NEW_LOAN = 'newLoan:view',
  OLD_APPLY_LOAN = 'oldApplyLoan:view',
  LOAN_SELF_SERVE = 'loanSelfServe:view',
}
