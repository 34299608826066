import { CardFds3, DESKTOP_BREAKPOINT, Divider, Typography, fdsTheme } from 'fds3-src/index'
import type { ReactNode } from 'react'
import styled from '@emotion/styled'

const { Title, Label, Body } = Typography
const { spacing } = fdsTheme

function CardTitle({ children }: { children: ReactNode }) {
  return (
    <div>
      <Title size="sm" style={{ marginBottom: spacing.xs }}>
        {children}
      </Title>
      <Divider />
    </div>
  )
}

function DetailLabel({ children }: { children: ReactNode }) {
  return (
    <Label size="md" display="inline" style={{ flexShrink: 1 }}>
      {children}
    </Label>
  )
}

function DetailValue({ children }: { children: ReactNode }) {
  return (
    <Body size="md" display="inline" style={{ flexShrink: 1 }}>
      {children}
    </Body>
  )
}

function TransactionDetailsCard({ children }: { children: ReactNode }) {
  return (
    <CardFds3
      padding="lg"
      radius="md"
      style={{ display: 'flex', flexDirection: 'column', gap: spacing.md }}
    >
      {children}
    </CardFds3>
  )
}

function TransactionDetailsInnerCard({ children }: { children: ReactNode }) {
  return (
    <CardFds3
      padding="lg"
      radius="xs"
      style={{ display: 'flex', flexDirection: 'column', gap: spacing.xs }}
      hasOutline
    >
      {children}
    </CardFds3>
  )
}

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 348px;
`
const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: ${spacing.md};
`

const CardContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.md};
`

const DetailsColumnsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  flex-direction: column;
  gap: ${spacing.md};
`

const TransactionDetailsSubContentContainer = styled.div`
  display: block;
  width: 100%;
  > * {
    :not(:last-child) {
      margin-bottom: ${spacing.md};
    }
  }

  ${DESKTOP_BREAKPOINT} {
    display: block;
    width: unset;
  }
`

TransactionDetailsCard.CardTitle = CardTitle
TransactionDetailsCard.CardContentContainer = CardContentContainer
TransactionDetailsCard.DetailsColumnsContainer = DetailsColumnsContainer
TransactionDetailsCard.DetailRow = DetailRow
TransactionDetailsCard.DetailContainer = DetailContainer
TransactionDetailsCard.DetailLabel = DetailLabel
TransactionDetailsCard.DetailValue = DetailValue
TransactionDetailsCard.SubContentContainer = TransactionDetailsSubContentContainer
TransactionDetailsCard.InnerCard = TransactionDetailsInnerCard

export default TransactionDetailsCard
