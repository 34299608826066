import * as React from 'react'

function SvgUnagii(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#unagii_svg__clip0)">
        <path d="M23.247 7.64l-5.24-5.24-5.238 5.24 5.239 5.239 5.24-5.24z" fill="#4E4E4E" />
        <path d="M16.845 14.041l-5.24-5.24-9.207 9.209 5.239 5.239 9.208-9.208z" fill="#FFD500" />
        <path
          d="M33.581 18.006L24.43 8.854 8.836 24.448l9.152 9.151L33.58 18.006z"
          fill="#FF6D2C"
        />
      </g>
      <defs>
        <clipPath id="unagii_svg__clip0">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgUnagii
