import * as React from 'react'

function SvgSignature(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.548 1.797a.873.873 0 0 1 .14-1.74l-.07.87.07-.87h.004L1.7.06l.028.002a8.936 8.936 0 0 1 .458.051c.302.039.724.101 1.211.198.96.19 2.229.525 3.32 1.1 1.069.565 2.16 1.467 2.39 2.875.229 1.403-.458 2.95-2.05 4.656-.827.885-1.377 1.677-1.728 2.378l-.807-2.032c.35-.499.767-1.01 1.258-1.537 1.462-1.566 1.702-2.583 1.604-3.184-.097-.598-.588-1.14-1.482-1.612-.873-.46-1.95-.754-2.845-.932A16.319 16.319 0 0 0 1.571 1.8l-.019-.001H1.55Zm2.974 7.49c-.792 1.127-1.237 2.185-1.405 3.174a5.065 5.065 0 0 0 .705 3.6c.576.927 1.36 1.594 1.975 2.022a8.23 8.23 0 0 0 1.038.613l.072.034.022.01.007.003.003.001h.001c0 .001.001.001.351-.798l-.35.8a.873.873 0 0 0 .702-1.599l-2.314-5.828-.807-2.032Zm.806 2.032 2.314 5.827-.007-.002-.042-.02-.177-.09a6.502 6.502 0 0 1-.622-.384c-.503-.35-1.083-.856-1.489-1.51a3.321 3.321 0 0 1-.467-2.387 5.11 5.11 0 0 1 .49-1.434Zm-.806-2.032-2.974-7.49 2.974 7.49Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m16.208 4.395-.93.93 3.104 3.105.93-.93-3.104-3.105Zm-.621-1.242L14.09 4.65a1.975 1.975 0 0 0-2.239 2.479l-2.951.59a.878.878 0 0 0-.635.515l-3.31 7.722a.878.878 0 0 0 .187.967l1.645 1.645a.878.878 0 0 0 .962.188l7.63-3.22a.878.878 0 0 0 .517-.622l.655-3.025a1.976 1.976 0 0 0 2.546-2.312l1.457-1.457a.878.878 0 0 0 0-1.242L16.83 3.153a.878.878 0 0 0-1.242 0ZM13.289 6.13c.25-.25.65-.257.908-.022l3.46 3.46a.659.659 0 0 1-.953.909l-3.415-3.415a.659.659 0 0 1 0-.932Zm-7.006 10.07 3.101-7.235 3.287-.658 2.719 2.72-.733 3.384-7.147 3.016-.305-.305 3.764-3.764a1.757 1.757 0 1 0-.621-.62l-3.764 3.763-.3-.3Zm4.957-3.735a.878.878 0 1 1 1.242-1.241.878.878 0 0 1-1.242 1.242Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgSignature
