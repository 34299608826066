import type { Reducer } from 'redux'
import type { VerificationStatusType } from '~/types'
import { VerificationStatus } from '~/constants/verificationStatus'
import type { PendingTasks, ActionTypes, BusinessAccount, BssVaAccount } from './types'
import { NewLandingPageActions } from './types'

const initialState = {
  businessVerificationStatus: VerificationStatus.Initial,
  verificationStatus: VerificationStatus.Initial,
  pendingTasks: {
    tasks: [],
    isLoading: false,
  },
  businessAccount: null,
  isLoadingBusinessAccount: false,
  loanAccount: null,
  isLoadingLoanAccount: false,
  bssVaAccount: null,
  isLoadingBssVaAccount: false,
  isLebaranBannerHidden: false,
  isHomepageBannerDismissed: false,
}

type State = {
  verificationStatus: VerificationStatusType
  businessVerificationStatus: VerificationStatusType
  pendingTasks: PendingTasks
  businessAccount: BusinessAccount | null
  isLoadingBusinessAccount: boolean
  loanAccount: null
  isLoadingLoanAccount: boolean
  bssVaAccount: BssVaAccount | null
  isLoadingBssVaAccount: boolean
  isLebaranBannerHidden: boolean
  isHomepageBannerDismissed: boolean
}

export const newLandingPage: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case NewLandingPageActions.SetPersonalVerificationStatus: {
      return {
        ...state,
        verificationStatus: action.verificationStatus,
      }
    }

    case NewLandingPageActions.SetBusinessVerificationStatus: {
      return {
        ...state,
        businessVerificationStatus: action.businessVerificationStatus,
      }
    }

    case NewLandingPageActions.SetPendingTasks: {
      return {
        ...state,
        pendingTasks: {
          ...state.pendingTasks,
          tasks: action.pendingTasks,
        },
      }
    }

    case NewLandingPageActions.SetIsPendingTasksLoading: {
      return {
        ...state,
        pendingTasks: {
          ...state.pendingTasks,
          isLoading: action.isLoading,
        },
      }
    }
    case NewLandingPageActions.SetBusinessAccount: {
      return {
        ...state,
        businessAccount: action.businessAccount,
      }
    }

    case NewLandingPageActions.SetIsLoadingBusinessAccount: {
      return {
        ...state,
        isLoadingBusinessAccount: action.isLoading,
      }
    }

    case NewLandingPageActions.SetBssVaAccount: {
      return {
        ...state,
        bssVaAccount: action.bssVaAccount,
      }
    }

    case NewLandingPageActions.SetIsLoadingBssVaAccount: {
      return {
        ...state,
        isLoadingBssVaAccount: action.isLoading,
      }
    }

    case NewLandingPageActions.SetLebaranBannerHidden: {
      return {
        ...state,
        isLebaranBannerHidden: action.isHidden,
      }
    }

    case NewLandingPageActions.SET_DISMISS_HOMEPAGE_BANNER: {
      return {
        ...state,
        isHomepageBannerDismissed: action.isHomepageBannerDismissed,
      }
    }

    default:
      return state
  }
}
