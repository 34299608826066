import { cx, css } from 'emotion'
import type { TableProps } from 'antd/es/table'
import Table from 'antd/es/table'
import 'antd/es/table/style/css'
import styled from '@emotion/styled'
import { MAIN_FONT } from '../../../themes/deprecated/TypographyStyles'
import { BLACK } from '../../../themes/deprecated/colors'
import { fdsTheme } from '../../../themes/theme-fds3'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CustomTableProps = TableProps<any> & {
  className?: string
  emptyText?: React.ReactNode
}

const { colors, radius, rootPrimitives, fontStyles, fontFamily, fontSize } = fdsTheme

const baseStyle = css`
  -webkit-font-smoothing: antialiased;
  font-family: ${MAIN_FONT};
  color: ${BLACK.DEFAULT};
`

function CustomTable({ className, emptyText, ...restProps }: CustomTableProps) {
  return (
    <Table
      className={cx(baseStyle, className)}
      locale={{
        emptyText,
      }}
      {...restProps}
    />
  )
}

const StyledTable = styled(CustomTable)`
  .ant-table-thead .ant-table-cell {
    ${fontStyles.labelLarge}
    background-color: ${rootPrimitives.cultured};
  }

  .ant-table-content table {
    border-radius: ${radius.lg};
    overflow: hidden;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    button {
      border: none;

      &:hover {
        & > span {
          color: ${colors.primary};
        }
      }
    }
  }

  .ant-pagination-item {
    font-family: ${fontFamily.fazzNeue};
    font-size: ${fontSize.bodyMedium};
    text-align: center;
    border: none;

    a {
    }
    &:hover {
      & > a {
        border-radius: 999px;
        color: ${colors.primary};
      }
    }
  }

  .ant-pagination-item-active {
    &:hover {
      & > a {
        color: ${colors.onContainer};
      }
    }

    & > a {
      border-radius: 999px;
      background-color: ${colors.active};
      color: ${colors.onContainer};
    }
  }
`
/**
 * @deprecated - Use the new FDS3 version: TableFds3.
 */
export default StyledTable
