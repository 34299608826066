import styled from 'styled-components'
import { fdsTheme } from '../../../themes/theme-fds3'
import { CardBorderRadius, CardPadding, CardProps, CardShadow } from './Card.types'

// Retrieve all theming data
const { spacing, colors, shadow: boxShadow } = fdsTheme

// Functions when there are more than 2 conditions
const getBorderRadius = (radius?: CardBorderRadius) => {
  switch (radius) {
    case 'none':
      return 0
    case 'xs':
      return spacing.xs
    case 'sm':
      return spacing.sm
    case 'md':
    default:
      return spacing.md
  }
}

const getShadow = (shadow?: CardShadow) => {
  switch (shadow) {
    case 'action':
      return boxShadow.action
    case 'overlay':
      return boxShadow.shadowOverlay
    case 'raised':
      return boxShadow.raised
    case 'reverse-raised':
      return boxShadow.reverseRaised
    case 'none':
    default:
      return null
  }
}

const getPadding = (padding?: CardPadding) => {
  switch (padding) {
    case 'none':
      return '0'
    case 'lg':
      return spacing.lg
    case 'sm':
      return spacing.sm
    case 'xs':
      return spacing.xs
    case 'md':
    default:
      return spacing.md
  }
}

export const StyledCard = styled.div.attrs(
  ({ radius, shadow, padding, hasOutline }: CardProps) => ({
    radius,
    shadow,
    padding,
    hasOutline,
  })
)`
  display: block;
  width: 100%;
  background-color: ${colors.surfaceNeutral};

  ${({ radius, shadow, padding, hasOutline }) =>
    `
      border: ${hasOutline ? `1px solid ${colors.line}` : 'none'};
      border-radius: ${getBorderRadius(radius)};
      box-shadow: ${getShadow(shadow)};
      padding: ${getPadding(padding)};
  `}
`
