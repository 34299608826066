import styled from '@emotion/styled/macro'
import { fdsTheme } from '@fazz/design-system'

const { colors } = fdsTheme

function SplashPageLayout({ children }: { children: React.ReactNode }) {
  return <PageContainer>{children}</PageContainer>
}

const PageContainer = styled.div`
  margin: auto auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.backgroundNeutral};
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
`

export default SplashPageLayout
