import * as React from 'react'
import { SVGProps } from 'react'

function SvgLeftArrow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.92.29.8 5.41a2.25 2.25 0 0 0 0 3.18l5.12 5.12a.75.75 0 0 0 1.06 0 .74.74 0 0 0 0-1.06l-4.9-4.9h11a.75.75 0 1 0 0-1.5h-11L7 1.35a.74.74 0 0 0 .22-.53A.73.73 0 0 0 7 .29a.75.75 0 0 0-1.08 0Z"
        fill="#121212"
      />
    </svg>
  )
}

export default SvgLeftArrow
