import * as React from 'react'

function SvgTokenizeXchange(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#tokenize-xchange_svg__clip0)">
        <path
          d="M17.985 33.589a15.65 15.65 0 0015.57-14.842v-.05-.393-.391-.462-.373a15.578 15.578 0 00-30.07-4.852H7.47A11.886 11.886 0 0117.914 6.01h.118a11.757 11.757 0 017.007 2.3l-.05.048a12.028 12.028 0 012.453 2.453l.062-.063a11.949 11.949 0 010 14.34l-.039-.039a11.936 11.936 0 01-2.426 2.458l.024.023a11.926 11.926 0 01-18.906-7.713h3.856V16.02h-7.5a15.676 15.676 0 0015.473 17.57z"
          fill="#F97756"
        />
        <path
          d="M14.903 23.634l-2.669-2.677L24.11 9.056l2.234-2.242 2.665 2.67-14.105 14.151z"
          fill="#F97756"
        />
        <path
          d="M29.102 26.48l-2.665 2.665-14.183-14.18 2.669-2.668 14.18 14.183z"
          fill="#F97756"
        />
        <path
          d="M17.142 6.034c.279-.024.569-.031.847-.031.64.002 1.278.054 1.909.156h.125c.086 0 .169.028.25.051.083.024.256.047.393.079h.043c1.566.37 3.04 1.059 4.33 2.022l.044.043a11.837 11.837 0 012.386 2.352l.036.035c.517.683.957 1.422 1.313 2.202 0 .047.043.09.062.141l.051.11c.445 1.032.744 2.12.89 3.233A15.676 15.676 0 0017.67 2.244a15.637 15.637 0 00-14.214 9.99h4.005a11.887 11.887 0 019.68-6.2z"
          fill="url(#tokenize-xchange_svg__paint0_linear)"
        />
        <path
          d="M14.599 33.197A15.641 15.641 0 0029.374 21.44a11.868 11.868 0 01-1.866 3.648l-.039-.043a12.05 12.05 0 01-2.43 2.457h.024A11.925 11.925 0 016.157 19.81h3.856v-3.793h-7.5c-.079.629-.117 1.262-.114 1.897a15.676 15.676 0 0012.2 15.284z"
          fill="url(#tokenize-xchange_svg__paint1_linear)"
        />
        <path
          d="M14.903 23.635l-2.669-2.677L25.014 8.15l2.599 2.72-12.71 12.765z"
          fill="url(#tokenize-xchange_svg__paint2_linear)"
        />
        <path
          d="M12.277 14.966l2.67-2.67 15.21 15.262-2.662 2.673-15.218-15.265z"
          fill="url(#tokenize-xchange_svg__paint3_linear)"
        />
        <path
          d="M3.59 12.226h3.872a11.898 11.898 0 018.955-6.121A13.125 13.125 0 003.59 12.227z"
          fill="url(#tokenize-xchange_svg__paint4_linear)"
        />
        <path
          d="M6.54 16.016H2.52c.438 4.083 3.527 8.622 7.191 10.558-2.308-2.125-4.268-5.28-3.17-10.558z"
          fill="url(#tokenize-xchange_svg__paint5_linear)"
        />
        <path
          d="M29.631 8.836L27.151 6l-9.238 9.273-2.994-3.018-2.63 2.63a.595.595 0 000 .082l2.951 2.975-2.998 3.01 2.661 2.672 3.002-3.01 6.988 7.008 2.622-2.712-6.945-6.968 9.061-9.105z"
          fill="url(#tokenize-xchange_svg__paint6_linear)"
        />
        <path
          d="M33.578 17.442v-.372a15.653 15.653 0 00-3.527-9.112c-5.263.255-10.809 4.66-10.809 10.005l1.352-.03 7.031-7.055a11.914 11.914 0 010 14.108l-7.011-7.054-1.352.032c0 5.326 5.561 9.648 10.809 9.895a15.641 15.641 0 003.527-9.112v-.05-.393-.391-.392l-.02-.079zm-7.446-8.104c.239.23.462.462.69.717l-.69-.717zm1.36 1.414c0 .028.04.051.055.083l-.055-.083z"
          fill="url(#tokenize-xchange_svg__paint7_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="tokenize-xchange_svg__paint0_linear"
          x1={3.9}
          y1={10.569}
          x2={30.32}
          y2={10.569}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F97756" />
          <stop offset={0.11} stopColor="#F97F53" />
          <stop offset={0.7} stopColor="#F9A942" />
          <stop offset={1} stopColor="#F9B93B" />
        </linearGradient>
        <linearGradient
          id="tokenize-xchange_svg__paint1_linear"
          x1={3.91}
          y1={25.734}
          x2={27.209}
          y2={15.551}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F97756" />
          <stop offset={0.58} stopColor="#F9A045" />
          <stop offset={1} stopColor="#F9B93B" />
        </linearGradient>
        <linearGradient
          id="tokenize-xchange_svg__paint2_linear"
          x1={27.558}
          y1={15.628}
          x2={12.509}
          y2={16.136}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F97756" />
          <stop offset={0.49} stopColor="#F97955" />
          <stop offset={0.67} stopColor="#F98052" />
          <stop offset={0.79} stopColor="#F98B4E" />
          <stop offset={0.89} stopColor="#F99C47" />
          <stop offset={0.98} stopColor="#F9B23E" />
          <stop offset={1} stopColor="#F9B93B" />
        </linearGradient>
        <linearGradient
          id="tokenize-xchange_svg__paint3_linear"
          x1={-89267}
          y1={-323381}
          x2={-90061.2}
          y2={-323412}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F97756" />
          <stop offset={0.49} stopColor="#F97955" />
          <stop offset={0.67} stopColor="#F98052" />
          <stop offset={0.79} stopColor="#F98B4E" />
          <stop offset={0.89} stopColor="#F99C47" />
          <stop offset={0.98} stopColor="#F9B23E" />
          <stop offset={1} stopColor="#F9B93B" />
        </linearGradient>
        <linearGradient
          id="tokenize-xchange_svg__paint4_linear"
          x1={-44413.2}
          y1={-37565.9}
          x2={-44831.3}
          y2={-37565.9}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F97756" />
          <stop offset={0.58} stopColor="#F9A045" />
          <stop offset={1} stopColor="#F9B93B" />
        </linearGradient>
        <linearGradient
          id="tokenize-xchange_svg__paint5_linear"
          x1={-12590}
          y1={-110463}
          x2={-12806.7}
          y2={-110565}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F97756" />
          <stop offset={0.58} stopColor="#F9A045" />
          <stop offset={1} stopColor="#F9B93B" />
        </linearGradient>
        <linearGradient
          id="tokenize-xchange_svg__paint6_linear"
          x1={29.543}
          y1={16.187}
          x2={12.194}
          y2={16.187}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.23} stopColor="#F97756" />
          <stop offset={0.28} stopColor="#F98052" />
          <stop offset={0.44} stopColor="#F99948" />
          <stop offset={0.61} stopColor="#F9AB41" />
          <stop offset={0.79} stopColor="#F9B63C" />
          <stop offset={1} stopColor="#F9B93B" />
        </linearGradient>
        <linearGradient
          id="tokenize-xchange_svg__paint7_linear"
          x1={33.527}
          y1={18.077}
          x2={19.13}
          y2={18.077}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F97756" />
          <stop offset={0.27} stopColor="#F98451" />
          <stop offset={0.78} stopColor="#F9A742" />
          <stop offset={1} stopColor="#F9B93B" />
        </linearGradient>
        <clipPath id="tokenize-xchange_svg__clip0">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgTokenizeXchange
