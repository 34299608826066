import { Body } from 'fds3-src/themes/Typography'
import { fdsTheme } from 'fds3-src/themes/theme-fds3'
import { FormItem, Input } from 'formik-antd'
import styled from 'styled-components'
import { Button } from '../../Button'
import { FormikSelect } from '../FormikSelect'
import type {
  FormikInputAddonButtonProps,
  FormikInputAddonSelectProps,
  FormikInputAddonTextProps,
  FormikInputProps,
} from './FormikInput.types'

const { spacing, colors, radius, fontFamily, fontStyles } = fdsTheme

export const StyledInput = styled(Input).attrs((props: FormikInputProps) => ({
  hasAddonBefore: !!props.addonBefore,
  hasAddonAfter: !!props.addonAfter,
}))`
  ${({ hasAddonBefore, hasAddonAfter }) =>
    `
    &.ant-input,
    .ant-input {
      font-family: ${fontFamily};
      height: 56px;
      border: 1px solid ${colors.line};
      color: ${colors.onNeutral};
      border-radius: ${radius.lg};
      padding: 0px ${spacing.md};
      background: ${colors.surfaceNeutral};

      ::-webkit-input-placeholder {
        ${fontStyles.bodyLarge};
        color: ${colors.placeholderInput};
      }

      &:focus {
        border: 1px solid ${colors.active};
        box-shadow: none;
      }

      &:disabled {
        background-color: ${colors.surfaceDisabledSurface};
      }
    }

    .ant-input-group-addon {
      cursor: default;
      background-color: transparent;
      border: 0;
      border-bottom-left-radius: ${hasAddonAfter ? 0 : spacing.md};
      border-top-left-radius: ${hasAddonAfter ? 0 : spacing.md};
      border-bottom-right-radius: ${hasAddonBefore ? 0 : spacing.md};
      border-top-right-radius: ${hasAddonBefore ? 0 : spacing.md};
      padding: 0;
    }
  `}
`

export const StyledFormItem = styled(FormItem)`
  &.ant-form-item {
    margin-bottom: 0;
  }
  // error state + error message
  &.ant-form-item-has-error {
    .ant-typography {
      color: ${colors.critical};
    }

    .ant-input {
      border-color: ${colors.critical};
    }

    .ant-form-item-explain-error {
      margin-top: ${spacing.xs};
      margin-left: ${spacing.md};
      ${fontStyles.bodyMedium}
      color: ${colors.critical};
      text-align: left;
    }
  }

  // helper text
  .ant-form-item-extra {
    margin-top: ${spacing.xs};
    margin-left: ${spacing.md};
    ${fontStyles.bodySmall}
    text-align: left;
    color: ${colors.helperInput};
  }

  // we want to hide if there is an error message - May be problematic if we want to show more errors in the future.
  .ant-form-item-control div:nth-of-type(3) {
    display: none;
  }
`

export const StyledAddonSelect = styled(FormikSelect).attrs(
  (props: FormikInputAddonSelectProps) => ({
    isAfter: props.isAfter,
  })
)`
  ${({ isAfter }) =>
    `
    &.ant-form-item {
      height: 56px;
      margin-bottom: 0; 
    }
    
    .ant-select {
      margin: 0;
    }
    
    .ant-select-selector {
      border-left: ${isAfter ? '1px' : ''} !important;
      border-right: ${!isAfter ? '1px' : ''} !important;
      border-bottom-left-radius: ${isAfter ? 0 : spacing.md} !important;
      border-top-left-radius: ${isAfter ? 0 : spacing.md} !important;
      border-bottom-right-radius: ${!isAfter ? 0 : spacing.md} !important;
      border-top-right-radius: ${!isAfter ? 0 : spacing.md} !important;
    }
    .ant-select-arrow {
      width: 14px;
      height: 7px;
      // FIXME: Temp solution for now, should find a more permanent solution in the future.
      right: 16px;
      top: 47%;
  }
`}
`

export const StyledAddonButton = styled(Button).attrs((props: FormikInputAddonButtonProps) => ({
  isAfter: props.isAfter,
}))`
  ${({ isAfter }) =>
    `
    &.ant-btn {
      height: 56px;
      border-bottom-left-radius: ${isAfter ? 0 : spacing.md};
      border-top-left-radius: ${isAfter ? 0 : spacing.md};
      border-bottom-right-radius: ${!isAfter ? 0 : spacing.md};
      border-top-right-radius: ${!isAfter ? 0 : spacing.md};
    } 
  `}
`

export const StyledAddonText = styled(Body).attrs((props: FormikInputAddonTextProps) => ({
  isAfter: props.isAfter,
  color: props.color,
}))`
  ${({ isAfter, color }) =>
    `
      &.ant-typography {
        display: flex;
        align-items: center;
        padding: 0 16px;
        height: 56px;
        border: 1px solid ${colors.line};
        border-bottom-left-radius: ${isAfter ? 0 : spacing.md};
        border-top-left-radius: ${isAfter ? 0 : spacing.md};
        border-bottom-right-radius: ${!isAfter ? 0 : spacing.md};
        border-top-right-radius: ${!isAfter ? 0 : spacing.md};
        border-left: ${isAfter ? '1px' : ''};
        border-right: ${!isAfter ? '1px' : ''};
        color: ${color ? `${color} !important` : `${colors.onNeutral} !important`};
      } 
  `}
`
