import type { Reducer } from 'redux'
import type { VerificationStatusType } from '~/api/idVerification/idVerification.types'
import type { UserDetails, ActionTypes, Wallet } from './types'
import { MyAccountActions } from './types'

const initialState = {
  userDetails: {
    fullName: '',
    bankAccounts: [],
  },
  isUserDetailLoading: true,
  wallets: null,
  amVerificationStatus: undefined,
  businessVerificationStatus: undefined,
}

type State = {
  userDetails: UserDetails
  isUserDetailLoading: boolean
  wallets: Wallet[] | null
  amVerificationStatus?: VerificationStatusType
  businessVerificationStatus?: VerificationStatusType
}

export const myAccount: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case MyAccountActions.SET_VERIFICATION_STATUS: {
      return {
        ...state,
        amVerificationStatus: action.amVerificationStatus,
        businessVerificationStatus: action.businessVerificationStatus,
      }
    }
    case MyAccountActions.GET_USER_DETAILS: {
      return {
        ...state,
        userDetails: action.userDetails,
      }
    }

    case MyAccountActions.DELETE_BANK_ACCOUNTS: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          bankAccounts: action.bankAccounts,
        },
      }
    }

    case MyAccountActions.GET_WALLETS: {
      return {
        ...state,
        wallets: action.wallets,
      }
    }
    case MyAccountActions.SET_IS_USER_DETAIL_LOADING: {
      return {
        ...state,
        isUserDetailLoading: action.isUserDetailLoading,
      }
    }
    default:
      return state
  }
}
