import * as React from 'react'

function SvgCalendar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 5h-3V3.5a1.5 1.5 0 00-3 0V5H10V3.5a1.5 1.5 0 10-3 0V5H4a2 2 0 00-2 2v19a2 2 0 002 2h22a2 2 0 002-2V7a2 2 0 00-2-2zm-1 3H5v2h20V8zm0 5H5v12h20V13z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCalendar
