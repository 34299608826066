import { fdsTheme } from 'fds3-src/themes/theme-fds3'
import styled, { type CSSProperties } from 'styled-components'
import { Modal } from 'antd'

const { spacing, colors, radius, shadow, fontStyles } = fdsTheme

export const dialogMaskStyle: CSSProperties = {
  backgroundColor: colors.colorOverlay,
}

export const StyledDialog = styled(Modal)`
  padding: 0;
  max-height: 72vh;

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: inherit;
    border-radius: ${radius.lg};
    background-color: ${colors.surfaceNeutral};
    box-shadow: ${shadow.shadowOverlay};
    overflow: hidden;
  }

  .ant-modal-body {
    ${fontStyles.bodyMedium}
    flex: 1;
    padding: 0;
    min-height: 60px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .ant-modal-header {
    padding: ${spacing.md};
    margin-right: ${spacing.xl};
    border: none;
  }

  .ant-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.onNeutral};
    height: 62px; // Modal header top and bottom padding/margin + title height (16px + 16px + 30px)
  }

  .ant-modal-footer {
    padding: ${spacing.md};
    border: none;

    button {
      margin: 0;
    }
  }
`

export const ContentContainer = styled.div`
  padding: 0 ${spacing.md};
`
