import * as React from 'react'

function SvgVecDeposits(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 130 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.8}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.003 15.228c1.326-4.192 2.56-6.802 3.7-7.83 3.157-2.849 7.522-1.299 8.45-1.077 3.26.782 2.2-4.364 4.997-5.41 1.864-.697 3.397.155 4.6 2.556 1.065-2.24 2.688-3.266 4.87-3.083 3.271.277 4.416 11.328 8.912 8.909 4.497-2.42 10.01-2.973 12.365.623.51.778.704-.429 4.166-4.55 3.462-4.122 6.914-5.938 13.996-3.538 3.22 1.09 5.868 4.045 7.947 8.864 0 6.878 5.06 10.95 15.178 12.213 15.179 1.895 3.397 18.214-15.178 22.993-18.576 4.78-61.343 7.36-84.55-4.716-15.473-8.049-11.957-16.7 10.546-25.954h.001z"
        fill="url(#vec_deposits_svg__paint0_linear)"
      />
      <path
        d="M64.987 76.008c15.457 0 27.987-2.9 27.987-6.478s-12.53-6.478-27.987-6.478C49.53 63.052 37 65.952 37 69.53s12.53 6.478 27.987 6.478z"
        fill="url(#vec_deposits_svg__paint1_linear)"
      />
      <path
        opacity={0.675}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.201 77.613c-21.043 5.278-92.87-.76-100.515-3.516-3.721-1.343-7.075-3.868-10.061-7.576a2.822 2.822 0 012.198-4.593h125.514c2.605 6.938-3.107 12.166-17.136 15.685z"
        fill="url(#vec_deposits_svg__paint2_linear)"
      />
      <path
        d="M87.396 57.052L77.825 45.86a2.434 2.434 0 00-1.837-.86h-20.58c-.706 0-1.377.324-1.837.86L44 57.052v6.144h43.396v-6.144z"
        fill="url(#vec_deposits_svg__paint3_linear)"
      />
      <path
        d="M64.94 61.181h2.012l.017-1.968c3.69-.282 5.83-2.242 5.838-5.2-.009-2.905-2.199-4.448-5.233-5.13l-.503-.12.034-4.26c1.134.264 1.824.988 1.926 2.062h3.537c-.042-2.821-2.147-4.84-5.429-5.199l.017-2.003h-2.011l-.017 1.986c-3.333.324-5.685 2.335-5.668 5.242-.008 2.573 1.807 4.048 4.756 4.755l.818.205-.043 4.508c-1.389-.264-2.326-1.116-2.42-2.54H59c.085 3.444 2.344 5.37 5.957 5.685l-.017 1.977zm2.063-5.122l.034-3.98c1.321.418 2.037.955 2.045 1.926-.008 1.032-.792 1.799-2.08 2.054zm-1.943-7.815c-1.066-.367-1.833-.92-1.816-1.9 0-.913.648-1.62 1.85-1.867l-.034 3.767z"
        fill="url(#vec_deposits_svg__paint4_linear)"
      />
      <defs>
        <linearGradient
          id="vec_deposits_svg__paint0_linear"
          x1={67.794}
          y1={37.313}
          x2={67.794}
          y2={-8.37}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DEDEDE" stopOpacity={0} />
          <stop offset={1} stopColor="#A9A9A9" stopOpacity={0.3} />
        </linearGradient>
        <linearGradient
          id="vec_deposits_svg__paint1_linear"
          x1={62.071}
          y1={76.008}
          x2={62.071}
          y2={63.052}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#96A1C5" stopOpacity={0.373} />
        </linearGradient>
        <linearGradient
          id="vec_deposits_svg__paint2_linear"
          x1={65.474}
          y1={79.706}
          x2={65.474}
          y2={58.43}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#919191" stopOpacity={0.15} />
        </linearGradient>
        <linearGradient
          id="vec_deposits_svg__paint3_linear"
          x1={65.997}
          y1={50.435}
          x2={65.997}
          y2={63.196}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2F8DEE" />
          <stop offset={1} stopColor="#5FA8F2" />
        </linearGradient>
        <linearGradient
          id="vec_deposits_svg__paint4_linear"
          x1={65.999}
          y1={39.363}
          x2={65.999}
          y2={61.181}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5FA8F2" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgVecDeposits
