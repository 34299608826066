import axios, { AxiosError } from 'axios'
import { Notification } from '~/components/Notification'

/**
 * @deprecated
 */
function makeUploadRequest({
  url,
  additionalHeaders = {},
  data = {},
}: {
  url: string
  additionalHeaders: object
  data: object
}) {
  return axios({
    method: 'put',
    url,
    headers: {
      ...additionalHeaders,
    },
    data,
    validateStatus: (status) => status >= 200 && status < 400, // NOTE: adding this since axios is handling 304 as error responses, ref: https://github.com/axios/axios/issues/703
  })
    .then((response) => response.data)
    .catch((err: AxiosError) => {
      const statusCode = err.response?.status
      if (statusCode) {
        Notification.error({
          message: `Error ${statusCode}`,
          description:
            'An error occurred when uploading. Please try again later or contact our support team if this problem persists.',
        })
      } else {
        Notification.error({
          message: 'Network Error',
          description:
            'Unable to fulfil the upload request. Please make sure your device is connected to Internet and try again later. Contact our support team if this problem persists.',
        })
      }

      throw err
    })
}

/**
 * @deprecated
 */
function uploadS3PresignedUrl({
  uploadUrl,
  file,
  fileBlobType,
}: {
  uploadUrl: string
  file: File
  fileBlobType: string
}): Promise<any> {
  return makeUploadRequest({
    url: uploadUrl,
    additionalHeaders: { 'Content-Type': fileBlobType },
    data: file,
  })
}

export { uploadS3PresignedUrl }
