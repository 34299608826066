import { RollbarContext } from '@rollbar/react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHeaderContext } from '~/hooks/useHeaderContext'
import { Route } from '~/hooks/useReactRouter'
import {
  hasPermission as hasPermissionBiz,
  withPermission as withPermissionBiz,
  isHasPermission as isHasPermissionBiz,
} from '~/biz/permission'
import { getFeatureMatrixFactors } from '~/biz/store/route/selectors'
import {
  hasPermission as hasPermissionBizRegional,
  withPermission as withPermissionBizRegional,
  isHasPermission as isHasPermissionBizRegional,
} from '~/bizRegional/permission'
import { PATHS } from '~/bizRegional/routes/paths'
import { getFeatureMatrixFactors as getRegionalFeatureMatrixFactors } from '~/bizRegional/store/route/selectors'
import type { RootState } from '~/bizRegional/store/types'
import { AppType } from '~/types'
import type { Route as RouteType } from '~/types'
import AccessDenied from '~/components/AccessDenied'
import SandboxModeNotAvailable from '~/components/SandboxModeNotAvailable'
// temp inject static content here for local/staging, TODO: solve PUBLIC_PATH issue in index.html and remove this
import { isProduction, isLocal } from '~/managers/ConfigManager'
import { ModeManager } from '~/managers/ModeManager'
import MaintenancePage from '../MaintenancePage'

const ASSETS_BASE_URL_PRODUCTION = 'https://d3r7nsslvs6aaf.cloudfront.net/frontend/'
const ASSETS_BASE_URL_STAGING = 'https://dwlrc822bt02f.cloudfront.net/frontend/'
const ASSETS_BASE_URL_LOCAL = '/'

let ASSETS_BASE_URL = ASSETS_BASE_URL_PRODUCTION
if (!isProduction && !isLocal) {
  ASSETS_BASE_URL = ASSETS_BASE_URL_STAGING
} else if (isLocal) {
  ASSETS_BASE_URL = ASSETS_BASE_URL_LOCAL
}

interface PrivateRouteProps extends RouteType {
  routes: RouteType
  appType?: AppType
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function PrivateRoute(routeProps: PrivateRouteProps) {
  const {
    sideBar,
    path,
    routes,
    permission,
    hasSandboxMode,
    appType,
    sandboxPermission,
    breadcrumbName,
  } = routeProps
  const { setHeader, header } = useHeaderContext()
  const { t: i18nTranslation } = useTranslation('common/commonTranslations')
  const { fazzbiz_enable_sgd_earn_maintenance_page: isSgdEarnPageUnderMaintenance } = useSelector(
    (state: RootState) => state.featureGate.featureGateIDs
  )

  useEffect(() => {
    if (!header) {
      setHeader(breadcrumbName || i18nTranslation('BACK'))
    }
  }, [header])

  useEffect(
    () => () => {
      setHeader('')
    },
    []
  )

  const tabTitle = `${sideBar ? `${sideBar} | ` : ''}Fazz - Business Finance for Southeast Asia`

  const sitePermissions =
    appType === AppType.INDONESIA
      ? {
          hasPermission: hasPermissionBiz,
          withPermission: withPermissionBiz,
          isHasPermission: isHasPermissionBiz,
        }
      : {
          hasPermission: hasPermissionBizRegional,
          withPermission: withPermissionBizRegional,
          isHasPermission: isHasPermissionBizRegional,
        }

  const featureMatrix = useSelector(getFeatureMatrixFactors)
  const regionalFeatureMatrix = useSelector(getRegionalFeatureMatrixFactors)

  // START TODO: Remove after USD Migration finishes
  if (
    path === PATHS.EARN &&
    isSgdEarnPageUnderMaintenance &&
    !hasPermissionBizRegional(sandboxPermission, regionalFeatureMatrix)
  ) {
    return (
      <Route
        component={() => (
          <MaintenancePage body="SGD Earn is under maintenance at this moment. All services should resume shortly. We apologize for any inconvenience." />
        )}
        path={path as string}
      />
    )
  }
  // END TODO: Remove after USD Migration finishes

  if (ModeManager.isSandboxMode() && !hasSandboxMode)
    return <Route component={SandboxModeNotAvailable} path={path as string} />
  if (
    ModeManager.isSandboxMode() &&
    hasSandboxMode &&
    sandboxPermission &&
    !hasPermissionBiz(sandboxPermission, featureMatrix) &&
    !hasPermissionBizRegional(sandboxPermission, regionalFeatureMatrix)
  ) {
    return <Route component={SandboxModeNotAvailable} path={path as string} />
  }

  if (
    !ModeManager.isSandboxMode() &&
    permission &&
    (appType === AppType.INDONESIA
      ? !hasPermissionBiz(permission, featureMatrix)
      : !hasPermissionBizRegional(permission, regionalFeatureMatrix))
  ) {
    return <Route component={AccessDenied} path={path as string} />
  }

  return (
    <Route
      sensitive
      path={path as string}
      render={(props) => (
        <>
          <Helmet>
            <title>{tabTitle}</title>
            {!isProduction && <link rel="icon" href={`${ASSETS_BASE_URL}favicon-fazz.ico`} />}
            {!isProduction && (
              <link
                rel="apple-touch-icon"
                sizes="180x180"
                href={`${ASSETS_BASE_URL}apple-touch-icon.png`}
              />
            )}
            {!isProduction && <link rel="manifest" href={`${ASSETS_BASE_URL}manifest-fazz.json`} />}
          </Helmet>
          <RollbarContext context={path}>
            <routeProps.component {...props} permission={sitePermissions} routes={routes} />
          </RollbarContext>
        </>
      )}
    />
  )
}

export default PrivateRoute
