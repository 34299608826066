import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import { Notification } from '~/components/Notification'
import SessionUtils from '~/utils/SessionUtils'

type XeroErrorObjectType = {
  title: string
  detail: string
  type: string
  status: number
}

const axiosClient = applyCaseMiddleware(
  axios.create({
    baseURL: '/fe/fazzbiz',
  })
)

const axiosClientXero = applyCaseMiddleware(
  axios.create({
    baseURL: '/fe/fazzbiz',
  })
)

axiosClient.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response?.status) {
      handleResponseError(err.response.status)
    } else {
      // TODO Kerk(fazzbiz-rollbar): Capture error in rollbar in separate ticket
    }
    throw err
  }
)

axiosClientXero.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response?.status) {
      handleOnlyXeroResponseError(err.response.status, err.response.data.errors[0])
    } else {
      // TODO Kerk(fazzbiz-rollbar): Capture error in rollbar in separate ticket
    }
    throw err
  }
)

const handleResponseError = (status: number) => {
  if (status === 401) {
    SessionUtils.ssoLogout()
    return
  }
  // Skip case, as this error is expected. User has permissions, just no account with the external service or resource not found
  if (status === 405 || status === 404 || status === 403) {
    return
  }
  const errorMessages: { [key: string]: { message: string; description: string } } = {
    '403': {
      message: 'Error 403: Forbidden',
      description: 'An error occurred when we tried to process your request.',
    },
    '404': {
      message: 'Error 404: Not Found',
      description: 'An error occurred when we tried to process your request.',
    },
    '413': {
      message: 'Error 413: Payload Too Large',
      description:
        'The file that you tried to upload is too large. Please try again with a smaller file.',
    },
    '500': {
      message: 'Error 500: Internal Server Error',
      description: 'Something went wrong. Please try again or contact our customer support.',
    },
    '504': {
      message: 'Error 504: Gateway Timeout',
      description: 'Something went wrong. Please try again or contact our customer support.',
    },
  }
  const { message = '', description = 'Error' } = errorMessages[status.toString()] || {}
  // when description is empty, notification UI will be broken
  Notification.error({
    message,
    description,
  })
}

const handleOnlyXeroResponseError = (
  status: number,
  errors: XeroErrorObjectType = {
    title: 'Internal Error',
    status,
    detail: '',
    type: 'fazzbiz-internal-error',
  }
) => {
  if (status === 401) {
    SessionUtils.ssoLogout()
    return
  }

  const errorMessages: { [key: string]: { [type: string]: string } } = {
    '400': {
      'invalid-request':
        'The request was unsuccessful. Please try again or contact our customer support.',
    },
    '403': {
      'invalid-application':
        'Something went wrong. Please try again or contact our customer support.',
      'invalid-organisation-bank-feeds': 'The organisation does not support automated bank feeds.',
      'invalid-organisation-multi-currency':
        'The selected organisation does not support multi currency.',
      'invalid-feed-connection-for-organisation':
        'The feed connection was not found. Try resetting your Xero connection and contact our customer support if the issue persists.',
      'invalid-user-role':
        "The current user's role doesn't allow setting up bank feeds. Please try again with another user with the correct role on Xero.",
      'feed-connected-in-different-organisation':
        'The account is connected to another Xero Bank Account associated with this bank. This Xero Bank Account belongs to a different Xero Organisation.',
      'suspended-or-terminated-application':
        'Something went wrong. Try resetting your Xero connection or contact our customer support if the issue persists.',
      'invalid-feed-connection':
        'The feed connection was not found. Please try again or contact our customer support.',
      'fazzbiz-internal-error': 'An error occurred when we tried to process your request.',
    },
    '404': {
      'bank-feed-not-found':
        'The bank feed was not found. Try resetting your Xero connection or contact our customer support if the issue persists.',
      'statement-not-found':
        'The statement was not found. Please try again or contact our customer support.',
      'fazzbiz-internal-error': 'An error occurred when we tried to process your request.',
    },
    '409': {
      'feed-already-connected-in-current-organisation':
        'The account is already connected to another Xero Bank Account associated with this bank in the selected Xero Organisation.',
      'feed-already-connected-in-different-organisation':
        'The account is already connected to another Xero Bank Account. This Xero Bank Account belongs to a different Xero Organisation.',
      'duplicate-statement': 'The statement was duplicated. Please contact our customer support.',
    },
    '413': {
      'invalid-request': 'Something went wrong. Please try again or contact our customer support.',
      'fazz-internal-error':
        'The file that you tried to upload is too large. Please try again with a smaller file.',
    },
    '422': {
      'invalid-country-specified': 'No matching financial institute found for country specified.',
      'account-not-valid':
        'Feed cannot be connected to the Xero Bank Account. Please try again or contact our customer support.',
      'invalid-end-balance':
        'End balance is invalid. Please try again or contact our customer support.',
      'invalid-start-and-end-date': 'The end date must be later than the start date.',
      'invalid-start-date': "The start date must be no earlier than 1 year from today's date.",
      'invalid-end-date': 'The end date must be not be in the future.',
    },
    '429': {
      'too-many-requests':
        'Something went wrong. Please try again or contact our customer support.',
    },
    '500': {
      'internal-error': 'Something went wrong. Please try again or contact our customer support.',
      'fazzbiz-internal-error':
        'Something went wrong. Please try again or contact our customer support.',
    },
    '504': {
      'fazzbiz-internal-error':
        'Something went wrong. Please try again or contact our customer support.',
    },
  }
  const defaultErrorMessage =
    'Something went wrong. Please try again or contact our customer support.'
  const { type } = errors
  const description =
    errorMessages[status.toString()][type] ||
    errorMessages[status.toString()]['fazzbiz-internal-error'] ||
    defaultErrorMessage

  // we'll remove this case in automation Notification - need overwrite by myself

  if (status === 404 && errors.type === 'bank-feed-not-found') {
    return
  }

  // when description is empty, notification UI will be broken

  Notification.error({
    message: type || 'Error',
    description,
  })
}

export const { get, post, put, patch, delete: del } = axiosClient
export const {
  get: getXero,
  post: postXero,
  put: putXero,
  patch: patchXero,
  delete: delXero,
} = axiosClientXero
