import { cloneElement } from 'react'
import styled from '@emotion/styled'
import { useTheme } from '../../../themes/deprecated/themeContext'
import Button from '../../deprecated/DeprecatedButton/Button'

type ActionButtonProps = {
  type?: 'primary'
  icon: React.ReactElement
  disabled?: boolean
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'>

export default function ActionButton({ icon, type, disabled, ...props }: ActionButtonProps) {
  const { interactive, brand, actionButton, base } = useTheme()

  const disabledStyle = `
    cursor: auto;
    pointer-events: none;
    opacity: ${brand.action.disabledOpacity};
  `

  const defaultStyle = `
        color: ${actionButton.color};

        &:focus {
            color: ${actionButton.color};
        }

        &:hover {
            color: ${actionButton.hover};
        }

        &:active {
            color: ${actionButton.color};
        }
  `
  const primaryStyle = `
        color: ${base.primary};

        &:focus {
            color: ${interactive.active};
        }

        &:hover {
            color: ${brand.action.hover};
        }

        &:active {
            color: ${interactive.active};
        }
  `

  const StyledButton = styled(Button)`
    &.ant-btn {
      ${disabled === true && disabledStyle}
      & > svg {
        ${type === 'primary' ? primaryStyle : defaultStyle}
        vertical-align: middle;
        font-size: 1.4em;
        margin: 0;
      }
    }
  `
  const ResizedChildren = () =>
    cloneElement(icon, {
      width: 17,
      height: 17,
    })

  return (
    <StyledButton type="secondary" {...props}>
      <ResizedChildren />
    </StyledButton>
  )
}
