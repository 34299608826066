import type { WALLET_TYPES, VerificationStatusType } from '~/types'

export type WalletIdType = WALLET_TYPES.GENERAL | WALLET_TYPES.TEMPORARY_HOLDING_BALANCE | undefined

export type UserDetails = {
  fullName: string
  bankAccounts: BankAccount[]
  accountFullyVerified: boolean
  kycInfoVerifying: boolean
  kycRejectReason: Record<string, string>
}

export type BankAccount = {
  id: number
  bankName: string
  accountNumber: string
  status: 'unverified' | 'verified' | 'pending' | 'rejected'
}

export type Account = {
  attributes: {
    wallet_id: number
    limitations: object
    type: string
  }
}

export type Wallet = {
  type: WalletIdType
  balance: string
  formattedBalance: string
  label: string
  accountId: number
  accountType: string
  fundPoolId: number
}

export enum MyAccountActions {
  GET_USER_DETAILS = 'MyAccountActions.GET_USER_DETAILS',
  GET_STABLECOIN_DATA = 'MyAccountActions.GET_STABLECOIN_DATA',
  SET_IS_USER_DETAIL_LOADING = 'MyAccountActions.SET_IS_USER_DETAIL_LOADING',
  DELETE_BANK_ACCOUNTS = 'MyAccountActions.DELETE_BANK_ACCOUNT',
  GET_WALLETS = 'MyAccountActions.GET_WALLETS',
  SET_VERIFICATION_STATUS = 'MyAccountActions.SET_VERIFICATION_STATUS',
  SET_HAS_ASSIGNED_CARDS = 'MyAccountActions.SET_HAS_ASSIGNED_CARDS',
  SET_INDIVIDUAL_KYC_STATUS = 'MyAccountActions.SET_INDIVIDUAL_KYC_STATUS',
}

type GetUserDetails = {
  type: typeof MyAccountActions.GET_USER_DETAILS
  userDetails: UserDetails
}

type DeleteBankAccount = {
  type: typeof MyAccountActions.DELETE_BANK_ACCOUNTS
  bankAccounts: BankAccount[]
}

type SetIsUserDetailLoading = {
  type: typeof MyAccountActions.SET_IS_USER_DETAIL_LOADING
  isUserDetailLoading: boolean
}

type GetWallets = {
  type: typeof MyAccountActions.GET_WALLETS
  wallets: Wallet[]
}

type SetVericationStatus = {
  type: typeof MyAccountActions.SET_VERIFICATION_STATUS
  amVerificationStatus: VerificationStatusType
  businessVerificationStatus: VerificationStatusType
}

type SetHasAssignedCards = {
  type: typeof MyAccountActions.SET_HAS_ASSIGNED_CARDS
  hasAssignedCards: boolean
}
type SetIndividualKycStatus = {
  type: typeof MyAccountActions.SET_INDIVIDUAL_KYC_STATUS
  individualKycStatus: VerificationStatusType
}

export type ActionTypes =
  | GetUserDetails
  | DeleteBankAccount
  | SetIsUserDetailLoading
  | GetWallets
  | SetVericationStatus
  | SetHasAssignedCards
  | SetIndividualKycStatus
