import { SVGProps } from 'react'

function SvgFilter(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.316 1.8a1.4 1.4 0 0 1 1.4 1.4v.425a1.258 1.258 0 0 0 .726 1.134c.31.144.609.314.891.508.21.147.46.226.717.225a1.3 1.3 0 0 0 .625-.167L15 5.11c.21-.122.449-.185.691-.184a1.408 1.408 0 0 1 1.217.7l.317.55a1.365 1.365 0 0 1 .141 1.067 1.351 1.351 0 0 1-.65.833l-.366.217a1.225 1.225 0 0 0-.617 1.192V10.5a1.225 1.225 0 0 0 .65 1.167l.367.217a1.392 1.392 0 0 1 .508 1.908l-.316.55a1.4 1.4 0 0 1-1.909.517l-.367-.217a1.267 1.267 0 0 0-1.35.058 5.002 5.002 0 0 1-.875.509 1.258 1.258 0 0 0-.725 1.133v.425a1.4 1.4 0 0 1-1.4 1.4h-.633a1.4 1.4 0 0 1-1.4-1.4v-.425a1.258 1.258 0 0 0-.725-1.133c-.311-.13-.61-.29-.892-.475a1.242 1.242 0 0 0-.716-.225c-.22 0-.435.058-.625.166L5 14.892a1.409 1.409 0 0 1-1.908-.517l-.317-.55a1.392 1.392 0 0 1 .475-1.908l.366-.25a1.242 1.242 0 0 0 .617-1.192V9.46a1.225 1.225 0 0 0-.617-1.125l-.366-.25a1.35 1.35 0 0 1-.65-.834 1.367 1.367 0 0 1 .141-1.066l.317-.55a1.408 1.408 0 0 1 1.217-.7c.253-.007.503.053.725.175l.366.216c.191.109.406.166.625.167.243-.008.477-.086.675-.225.278-.197.574-.367.884-.508a1.258 1.258 0 0 0 .725-1.134V3.2a1.4 1.4 0 0 1 1.4-1.4h.641ZM10.309.55h-.633A2.65 2.65 0 0 0 7.033 3.2v.425a7.634 7.634 0 0 0-1.075.617l-.367-.217a2.708 2.708 0 0 0-1.316-.35A2.65 2.65 0 0 0 1.975 5l-.309.55a2.642 2.642 0 0 0 .959 3.617l.366.217v1.233l-.366.217a2.642 2.642 0 0 0-.959 3.616l.317.55a2.65 2.65 0 0 0 2.3 1.325c.462.002.916-.12 1.317-.35l.366-.216c.34.237.7.443 1.075.616v.425a2.65 2.65 0 0 0 2.65 2.65h.634a2.65 2.65 0 0 0 2.65-2.65v-.425a6.9 6.9 0 0 0 1.075-.616l.366.216c.405.228.861.349 1.325.35A2.65 2.65 0 0 0 18.026 15l.317-.55a2.643 2.643 0 0 0-.967-3.616l-.367-.217V9.384l.367-.217a2.642 2.642 0 0 0 .958-3.617L18.025 5a2.65 2.65 0 0 0-2.3-1.325 2.709 2.709 0 0 0-1.317.35l-.367.217a7.64 7.64 0 0 0-1.075-.617V3.2a2.65 2.65 0 0 0-2.65-2.65h-.008Z"
        fill="currentColor"
      />
      <path
        d="M10 7.917a2.083 2.083 0 1 1 0 4.166 2.083 2.083 0 0 1 0-4.167Zm0-1.25a3.333 3.333 0 1 0 0 6.666 3.333 3.333 0 0 0 0-6.667Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFilter
