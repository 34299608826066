import * as React from 'react'

function SvgUob(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M12.5464 25.4794C12.5464 25.8779 13.0943 25.9275 13.3931 25.9275H13.6421C14.389 25.8785 14.389 25.5792 14.389 25.4794V9.44808C14.4382 9.04911 13.8908 9 13.5424 9H13.3949C13.0942 9 12.5464 9.04919 12.5464 9.44808L12.5464 25.4794Z"
          fill="#E1091D"
        />
        <path
          d="M2.53912 25.4794C2.53912 25.8779 3.08696 25.9275 3.38568 25.9275H3.63481C4.38163 25.8785 4.38163 25.5792 4.38163 25.4794V9.44808C4.38115 9.04911 3.88341 9 3.53507 9H3.38761C3.0368 9 2.53906 9.04919 2.53906 9.44808L2.53912 25.4794Z"
          fill="#E1091D"
        />
        <path
          d="M5.875 25.4794C5.875 25.8779 6.42282 25.9275 6.72156 25.9275H7.02028C7.7671 25.8785 7.7671 25.5792 7.7671 25.4794V9.44808C7.76662 9.04911 7.21926 9 6.92054 9H6.72156C6.42282 9 5.875 9.04919 5.875 9.44808V25.4794Z"
          fill="#E1091D"
        />
        <path
          d="M9.21051 25.4794C9.21051 25.8779 9.75833 25.9275 10.0571 25.9275H10.3061C11.053 25.8785 11.053 25.5792 11.053 25.4794V9.44808C11.0525 9.04911 10.5051 9 10.2064 9H10.059C9.75829 9 9.21045 9.04919 9.21045 9.44808L9.21051 25.4794Z"
          fill="#E1091D"
        />
        <path
          d="M0.448104 16.5176C0.0491414 16.5176 0 17.0654 0 17.3641V17.6629C0.0492111 18.4097 0.348363 18.4097 0.448104 18.4097H16.4794C16.8779 18.4097 16.9275 17.8619 16.9275 17.5631V17.3641C16.9275 17.0654 16.8785 16.5176 16.4794 16.5176H0.448104Z"
          fill="#E1091D"
        />
        <path
          d="M40.276 22.5413C37.6375 22.5413 36.64 21.0973 36.64 17.3636C36.64 13.6786 37.5859 12.3353 40.2269 12.3353H40.3251C42.9139 12.3353 43.9118 13.6796 43.9118 17.3636C43.8626 21.0978 42.8663 22.5413 40.2759 22.5413H40.276ZM40.2759 10.2441C35.7949 10.2441 33.7539 12.4846 33.7539 17.4132C33.7539 22.2922 35.845 24.6325 40.1265 24.6821H40.3255C44.6572 24.6821 46.7478 22.2922 46.7478 17.4132C46.7483 12.4345 44.7569 10.2441 40.2759 10.2441Z"
          fill="#002469"
        />
        <path
          d="M22.205 23.5869C23.1012 24.2841 24.3458 24.5828 26.3372 24.5828H26.4354C28.4268 24.5828 29.6713 24.2841 30.6176 23.5869C31.9118 22.5915 32.1624 21.399 32.1624 19.156V11.0411C32.1684 10.968 32.1584 10.8944 32.1329 10.8255C32.1075 10.7567 32.0672 10.6942 32.015 10.6426C31.8135 10.4422 31.3654 10.3439 30.6186 10.3439C30.1494 10.284 29.674 10.3896 29.2743 10.6426C29.2221 10.6942 29.1818 10.7567 29.1564 10.8255C29.1309 10.8944 29.1208 10.968 29.1268 11.0411V19.5049C29.1268 20.8988 29.1269 21.1975 28.4798 21.7454C27.8403 22.1457 27.0921 22.3371 26.339 22.2932C25.5832 22.361 24.8272 22.1667 24.1977 21.7429C23.5506 21.2452 23.5507 20.9465 23.5507 19.5525V11.0411C23.5567 10.968 23.5466 10.8944 23.5211 10.8255C23.4957 10.7567 23.4554 10.6942 23.4032 10.6426C23.0026 10.3888 22.5258 10.2832 22.0554 10.3439C21.5862 10.284 21.1108 10.3896 20.7111 10.6426C20.6589 10.6942 20.6187 10.7567 20.5932 10.8255C20.5677 10.8944 20.5577 10.968 20.5637 11.0411V19.156C20.662 21.3965 20.8625 22.5915 22.2067 23.5869"
          fill="#002469"
        />
        <path
          d="M54.4673 22.4929H51.281V18.261H54.6663C56.5584 18.261 56.9068 19.0575 56.9068 20.4018C56.956 22.1942 56.3093 22.4929 54.4673 22.4929ZM51.281 12.3861H54.517C56.2096 12.3861 56.3093 13.2327 56.3093 14.3279C56.3093 15.6722 55.7119 16.2195 54.2683 16.2195H51.281V12.3861ZM58.0024 11.1912C57.2556 10.6433 56.3594 10.4443 54.6171 10.4443H48.8415C48.7096 10.4446 48.5831 10.4971 48.4898 10.5904C48.3966 10.6837 48.344 10.8101 48.3438 10.9421V24.0858C48.344 24.2177 48.3966 24.3442 48.4898 24.4375C48.5831 24.5308 48.7096 24.5833 48.8415 24.5836H54.9154C56.5584 24.5836 57.7033 24.5343 58.6987 23.7871C59.7939 22.9405 59.9934 21.5967 59.9934 20.6007C60.0299 20.048 59.9363 19.4943 59.7202 18.9842C59.504 18.4742 59.1714 18.0218 58.7488 17.6635C58.3927 17.4315 58.0077 17.2473 57.6035 17.1157L57.3544 17.0175L57.6035 16.9192C58.7985 16.3714 59.1969 15.5749 59.1969 14.0316C59.1477 13.2352 58.9979 11.8909 58.002 11.1937"
          fill="#002469"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="60" height="35" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgUob
