import React, { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Global, css, keyframes } from '@emotion/core'
import styled from '@emotion/styled'

const ModalContext = React.createContext<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setModal: React.SetStateAction<any>
  resetModal: () => void
}>({
  setModal: () => null,
  resetModal: () => null,
})

const bodyElement = document.body

type ModalContainerProps = {
  modal: React.ReactChild | undefined
}
const ModalContainer = ({ modal }: ModalContainerProps) => {
  // eslint-disable-next-line @typescript-eslint/typedef
  const [mainElement] = useState(document.createElement('div'))
  mainElement.setAttribute('id', 'modal-root')

  useEffect(() => {
    bodyElement.appendChild(mainElement)
    return () => {
      bodyElement.removeChild(mainElement)
    }
  }, [mainElement])

  const preventBackgroundScrolling = (
    <Global
      styles={css`
        body {
          overflow: hidden;
        }
      `}
    />
  )
  return ReactDOM.createPortal(
    <ModalMask open={!!modal}>
      {!!modal && preventBackgroundScrolling}
      {modal}
    </ModalMask>,
    mainElement
  )
}

type ModalProviderProps = { children: React.ReactChild }
function ModalProvider({ children }: ModalProviderProps) {
  // eslint-disable-next-line @typescript-eslint/typedef
  const [modal, setModal] = useState<React.ReactElement | undefined>()
  const resetModal = useCallback(() => {
    setModal(undefined)
  }, [setModal])

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ModalContext.Provider value={{ resetModal, setModal }}>
      {children}
      <ModalContainer modal={modal} />
    </ModalContext.Provider>
  )
}

const useModal = () => {
  const context = React.useContext(ModalContext)

  if (context === undefined) {
    throw new Error('useModal must be used within a UserProvider')
  }
  return context
}

export { ModalProvider, useModal }

const showAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const ModalMask = styled.div<{ open: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  display: none;
  overflow: auto;
  z-index: 9; // bigger than nav bar z-index: 8
  ${(props) =>
    props.open &&
    css`
      display: block;
      animation: ${showAnimation} 0.3s ease;
    `}
`
