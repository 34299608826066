export type RfiQuestion = {
  question: string
  optional: boolean
  replyType: RfiQuestionReplyType
  reply: string
  presignedUrl?: {
    putDocUrl: string
    getDocUrl: string
  }
  // additional
  key: string
}

export type RfiData = {
  title: string
  rfiExternalId: string
  rfiQuestions: RfiQuestion[]
}

export enum RfiQuestionReplyType {
  TEXT = 'text',
  DOC = 'doc',
}

export enum SubmissionStatus {
  SUBMITTING = 'SUBMITTING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export type State = {
  rfiData: RfiData | undefined
  submissionStatus: SubmissionStatus | undefined
}

export enum RfiActions {
  SET_RFI_DATA = 'RFI_ACTIONS.SET_RFI_DATA',
  SET_SUBMISSION_STATUS = 'RFI_ACTIONS.SET_SUBMISSION_STATUS',
}

type SetRfi = {
  type: typeof RfiActions.SET_RFI_DATA
  rfiData: RfiData | undefined
}

type SetSubmissionStatus = {
  type: typeof RfiActions.SET_SUBMISSION_STATUS
  submissionStatus: SubmissionStatus | undefined
}

export type ActionTypes = SetRfi | SetSubmissionStatus
