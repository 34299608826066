import { cx, css } from 'emotion'
import Spin from 'antd/es/spin'
import 'antd/es/spin/style/css'
import { useTheme } from '../../../themes/deprecated/themeContext'

type SpinProps = {
  size?: 'small' | 'large' | 'default'
}

function MainSpin({ size, ...props }: SpinProps) {
  const { base } = useTheme()
  const brandStyle = css`
    & .ant-spin-dot-item {
      background-color: ${base.primary};
    }
  `

  return <Spin className={cx(brandStyle)} size={size} {...props} />
}

export default MainSpin
