import type { Reducer } from 'redux'
import type {
  Wallet,
  BankAccount,
  TransferInfo,
  ActionTypes,
  IndoBankAccount,
  IndoUniqueAmtTransferInfo,
  IndoVirtualAccount,
  DepositMethod,
} from './types'
import { DepositActions } from './types'

const initialState = {
  isLoading: false,
  wallets: [],
  bankAccounts: [],
  destination: undefined,
  destinationAmount: '',
  transferInfo: {
    recipientName: '',
    recipientBankName: '',
    recipientAccountNo: '',
    referenceNumber: '',
    expiredDate: '',
    depositAmount: '',
    userBankAccount: '',
  },
  indoBankAccounts: [
    {
      id: 1,
      bankName: 'BNI',
    },
    {
      id: 2,
      bankName: 'BRI',
    },
    {
      id: 3,
      bankName: 'MANDIRI',
    },
    {
      id: 4,
      bankName: 'BCA',
    },
  ],
  indoUniqueAmtTransferInfo: {
    recipientName: '',
    recipientBankName: '',
    recipientAccountNo: '',
    expiredDate: '',
    uniqueAmount: 0,
    depositAmount: '',
  },
  depositMethod: undefined,
  indoVirtualAccounts: [],
  selectedVirtualAccount: {
    bankName: undefined,
    bankFullName: '',
    accountNumber: '',
    recipientName: '',
  },
  isIdVAEnabled: false,
}

type State = {
  isLoading: boolean
  wallets: Wallet[]
  bankAccounts: BankAccount[]
  destination: number | undefined
  destinationAmount: string
  transferInfo: TransferInfo
  indoBankAccounts: IndoBankAccount[]
  indoUniqueAmtTransferInfo: IndoUniqueAmtTransferInfo
  depositMethod: DepositMethod
  indoVirtualAccounts: IndoVirtualAccount[]
  selectedVirtualAccount: IndoVirtualAccount
  isIdVAEnabled: boolean
}

export const deposit: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case DepositActions.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }

    case DepositActions.SET_WALLETS: {
      return {
        ...state,
        wallets: action.wallets,
        isLoading: false,
      }
    }

    case DepositActions.SET_BANK_ACCOUNTS: {
      return {
        ...state,
        bankAccounts: action.bankAccounts,
        isLoading: false,
      }
    }

    case DepositActions.SET_INDO_VIRTUAL_ACCOUNTS: {
      return {
        ...state,
        indoVirtualAccounts: action.virtualAccounts,
        isLoading: false,
      }
    }

    case DepositActions.SET_DESTINATION: {
      return {
        ...state,
        destination: action.destination,
        destinationAmount: '',
      }
    }

    case DepositActions.SET_DESTINATION_AMOUNT: {
      return {
        ...state,
        destinationAmount: action.destinationAmount,
      }
    }

    case DepositActions.CREATE_TOPUP_REQUEST: {
      return {
        ...state,
        transferInfo: action.transferInfo,
        isLoading: false,
      }
    }

    case DepositActions.SET_TRANSFER_INFO: {
      return {
        ...state,
        transferInfo: action.transferInfo,
        isLoading: false,
      }
    }

    case DepositActions.SET_UNIQUE_AMT_PAYMENT_INSTRUCTIONS: {
      return {
        ...state,
        indoUniqueAmtTransferInfo: action.indoUniqueAmtTransferInfo,
        isLoading: false,
      }
    }

    case DepositActions.SET_DEPOSIT_METHOD: {
      return {
        ...state,
        depositMethod: action.depositMethod,
      }
    }

    case DepositActions.SET_IS_ID_VA_DEPOSIT_ENABLED: {
      return {
        ...state,
        isIdVAEnabled: action.isIdVAEnabled,
      }
    }

    case DepositActions.SET_SELECTED_INDO_VIRTUAL_ACCOUNT: {
      return {
        ...state,
        selectedVirtualAccount: action.selectedVirtualAccount,
      }
    }

    default:
      return state
  }
}
