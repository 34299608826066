import * as React from 'react'

function SvgCoinut(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.286 2.4c.678.044 1.133.309 1.373.855 1.095 2.478 2.18 4.96 3.268 7.437 1.414 3.217 2.828 6.435 4.241 9.655.488 1.112.977 2.22 1.458 3.336.376.875.058 1.675-.817 2.058l-15.184 6.654-5.102 2.238c-.915.4-1.705.071-2.105-.851-1.271-2.915-2.55-5.824-3.82-8.74-1.36-3.112-2.72-6.224-4.079-9.34-.315-.721-.627-1.447-.95-2.165-.182-.407-.236-.814-.074-1.241.15-.387.427-.64.804-.807 2.234-.976 4.464-1.956 6.695-2.932 2.654-1.163 5.312-2.322 7.967-3.485 1.915-.838 3.834-1.682 5.753-2.512.203-.085.43-.119.572-.16zm-3.095 24.612v.03c1.251 0 2.502-.01 3.753.004 1.034.014 1.627-.742 1.546-1.556a4.056 4.056 0 01.007-.76c.084-.867-.587-1.6-1.55-1.576-2.003.048-4.007.027-6.01.014a5.294 5.294 0 01-1.288-.153c-1.383-.37-2.36-1.224-2.906-2.556-.467-1.142-.339-2.339-.267-3.519.03-.474.18-.973.39-1.4.816-1.647 2.206-2.424 4.013-2.444 2.048-.024 4.099-.003 6.15-.003.895 0 1.46-.56 1.474-1.451.004-.299.004-.6.004-.899-.004-.963-.583-1.542-1.543-1.542-2.22 0-4.444-.014-6.664.01a9.25 9.25 0 00-1.885.207c-3.316.722-5.75 3.78-5.743 7.173.003.997-.003 1.997.003 2.993.01 1.685.495 3.214 1.543 4.547 1.393 1.77 3.21 2.773 5.471 2.874 1.163.051 2.333.007 3.502.007z"
        fill="#036CA5"
      />
    </svg>
  )
}

export default SvgCoinut
