import * as React from 'react'

function SvgBinance(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#binance_svg__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.998 33.6c8.616 0 15.6-6.984 15.6-15.6 0-8.615-6.984-15.6-15.6-15.6-8.615 0-15.6 6.985-15.6 15.6 0 8.616 6.985 15.6 15.6 15.6z"
          fill="#F3BA2F"
        />
        <path
          d="M14.788 17.645L18 14.417l3.214 3.23 1.869-1.879L18 10.66l-5.082 5.108 1.87 1.878zM9.738 18.92l1.905-1.907 1.907 1.906-1.907 1.905-1.905-1.905zM14.787 20.19L18 23.42l3.213-3.232 1.87 1.878L18 27.176l-5.083-5.106 1.87-1.88zM22.445 18.919l1.905-1.906 1.907 1.904-1.907 1.907-1.905-1.905z"
          fill="#fff"
        />
        <path
          d="M19.905 18.921L18 17.013l-1.41 1.41-.163.162-.333.334L18 20.824l1.905-1.902z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="binance_svg__clip0">
          <path fill="#fff" d="M0 0h36v36H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgBinance
