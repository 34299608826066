import { SVGProps } from 'react'

function SvgAddWithBorder(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 1.69A9.31 9.31 0 1 1 1.69 11 9.33 9.33 0 0 1 11 1.69Zm0-1.5a10.81 10.81 0 1 0 0 21.62A10.81 10.81 0 0 0 11 .19Z"
        fill="currentColor"
      />
      <path
        d="M14.88 10.251h-3.13v-3.13a.75.75 0 1 0-1.5 0v3.13H7.12a.75.75 0 1 0 0 1.5h3.13v3.13a.75.75 0 1 0 1.5 0v-3.13h3.13a.75.75 0 1 0 0-1.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
export default SvgAddWithBorder
