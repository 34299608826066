import { SVGProps } from 'react'

function QRIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="40" height="40" rx="16" fill="#F4F5FA" />
      <g clipPath="url(#clip0_958_6400)">
        <path
          d="M8.86011 20.75V24.14C8.86011 25.9965 9.59761 27.777 10.9104 29.0897C12.2231 30.4025 14.0036 31.14 15.8601 31.14H24.1401C25.9966 31.14 27.7771 30.4025 29.0899 29.0897C30.4026 27.777 31.1401 25.9965 31.1401 24.14V20.75H8.86011ZM17.5601 27.63H17.3101C16.0318 27.63 14.8058 27.1222 13.9019 26.2183C12.9979 25.3143 12.4901 24.0883 12.4901 22.81V22.55C12.4901 22.3511 12.5691 22.1603 12.7098 22.0197C12.8504 21.879 13.0412 21.8 13.2401 21.8C13.439 21.8 13.6298 21.879 13.7704 22.0197C13.9111 22.1603 13.9901 22.3511 13.9901 22.55V22.81C13.9901 23.6905 14.3399 24.535 14.9625 25.1576C15.5851 25.7802 16.4296 26.13 17.3101 26.13H17.5601C17.759 26.13 17.9498 26.209 18.0904 26.3497C18.2311 26.4903 18.3101 26.6811 18.3101 26.88C18.3101 27.0789 18.2311 27.2697 18.0904 27.4103C17.9498 27.551 17.759 27.63 17.5601 27.63ZM27.5601 22.81C27.5601 23.443 27.4354 24.0697 27.1932 24.6545C26.951 25.2393 26.5959 25.7707 26.1484 26.2183C25.7008 26.6658 25.1694 27.0209 24.5846 27.2631C23.9999 27.5053 23.3731 27.63 22.7401 27.63H22.4901C22.2912 27.63 22.1004 27.551 21.9598 27.4103C21.8191 27.2697 21.7401 27.0789 21.7401 26.88C21.7401 26.6811 21.8191 26.4903 21.9598 26.3497C22.1004 26.209 22.2912 26.13 22.4901 26.13H22.7401C23.6102 26.1143 24.4393 25.7576 25.049 25.1366C25.6587 24.5157 26.0002 23.6802 26.0001 22.81V22.55C26.0001 22.3511 26.0791 22.1603 26.2198 22.0197C26.3604 21.879 26.5512 21.8 26.7501 21.8C26.949 21.8 27.1398 21.879 27.2804 22.0197C27.4211 22.1603 27.5001 22.3511 27.5001 22.55L27.5601 22.81Z"
          fill="#0C45E1"
        />
        <path
          d="M31.1401 19.2499V15.8599C31.1401 14.0033 30.4026 12.2229 29.0899 10.9101C27.7771 9.59736 25.9966 8.85986 24.1401 8.85986H15.8601C14.0036 8.85986 12.2231 9.59736 10.9104 10.9101C9.59761 12.2229 8.86011 14.0033 8.86011 15.8599V19.2499H31.1401ZM22.4401 12.3699H22.6901C23.9685 12.3699 25.1944 12.8777 26.0984 13.7816C27.0023 14.6855 27.5101 15.9115 27.5101 17.1899V17.4499C27.5101 17.6488 27.4311 17.8395 27.2904 17.9802C27.1498 18.1208 26.959 18.1999 26.7601 18.1999C26.5612 18.1999 26.3704 18.1208 26.2298 17.9802C26.0891 17.8395 26.0101 17.6488 26.0101 17.4499V17.1899C26.0101 16.7539 25.9242 16.3222 25.7574 15.9194C25.5905 15.5166 25.346 15.1506 25.0377 14.8423C24.7294 14.534 24.3634 14.2894 23.9606 14.1226C23.5578 13.9557 23.1261 13.8699 22.6901 13.8699H22.4401C22.2412 13.8699 22.0504 13.7908 21.9098 13.6502C21.7691 13.5095 21.6901 13.3188 21.6901 13.1199C21.6901 12.921 21.7691 12.7302 21.9098 12.5895C22.0504 12.4489 22.2412 12.3699 22.4401 12.3699ZM12.4901 17.1899C12.4901 15.9115 12.9979 14.6855 13.9019 13.7816C14.8058 12.8777 16.0318 12.3699 17.3101 12.3699H17.5601C17.759 12.3699 17.9498 12.4489 18.0904 12.5895C18.2311 12.7302 18.3101 12.921 18.3101 13.1199C18.3101 13.3188 18.2311 13.5095 18.0904 13.6502C17.9498 13.7908 17.759 13.8699 17.5601 13.8699H17.3101C16.4313 13.8725 15.5894 14.2235 14.969 14.8458C14.3485 15.4681 14.0001 16.3111 14.0001 17.1899V17.4499C14.0001 17.6488 13.9211 17.8395 13.7804 17.9802C13.6398 18.1208 13.449 18.1999 13.2501 18.1999C13.0512 18.1999 12.8604 18.1208 12.7198 17.9802C12.5791 17.8395 12.5001 17.6488 12.5001 17.4499L12.4901 17.1899Z"
          fill="#0C45E1"
        />
      </g>
      <defs>
        <clipPath id="clip0_958_6400">
          <rect width="24" height="24" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default QRIcon
