import styled from '@emotion/styled/macro'
import { DESKTOP_BREAKPOINT, Typography, Link, fdsTheme } from '@fazz/design-system'
import { useLocation } from '~/hooks/useReactRouter'
import { PATHS } from '~/bizRegional/routes/paths'
import { AppType } from '~/types'
import { TERMS_OF_SERVICE_LINK_SG } from '~/common/commonLinks'

type Props = {
  appType: AppType | undefined
}

const { Body } = Typography
const { spacing, colors } = fdsTheme

export default function Footer({ appType }: Props) {
  const location = useLocation()
  const isEarnPage = location.pathname.includes('earn')
  const isTermLoanPage = location.pathname.includes(PATHS.TERM_LOAN)
  const isCreditLinePage = location.pathname.includes(PATHS.CREDITLINE)
  const isKycIntendedRelationsPage = location.pathname.includes(PATHS.BIZ_KYC_INTENDED_RELATIONS)

  let footerContent
  let additionalFooterContent

  if (isEarnPage) {
    if (appType === AppType.INDONESIA) {
      footerContent = (
        <Advisory size="sm">
          Earn adalah produk milik Koperasi KAS Pintar yang ditawarkan oleh PT Xfers Indonesia
          Teknologi melalui website Fazz Business yang merupakan operator platform digital
          terlisensi berdasarkan Hukum Indonesia.
        </Advisory>
      )
    } else {
      footerContent = (
        <Advisory size="sm" style={{ marginBottom: spacing.xs }}>
          Consumer advisory - Earn is a product offered by XFERS PTE. LTD., which is the holder of a
          Major Payment Institution license for e-money issuance under the Payment Services Act. The
          Monetary Authority of Singapore (MAS) does not endorse the Earn product, and your funds in
          the Earn product may not be regarded as &quot;e-money&quot; under Singapore law. Consumers
          (Users) are advised to read our{' '}
          <Link
            size="small"
            href="https://fazz.com/sg/terms-and-conditions/fazz-biz/earn/"
            rel="noopener noreferrer"
            target="_blank"
          >
            terms of service
          </Link>{' '}
          carefully.
        </Advisory>
      )
    }
  } else if (appType === AppType.SINGAPORE) {
    footerContent = (
      <Advisory size="sm">
        Consumer advisory - XFERS PTE. LTD. is the holder of a Major Payment Institution license for
        e-money issuance under the new Payment Services Act. The electronically stored monetary
        value issued by XFERS PTE. LTD. is regarded as &quot;e-money&quot; under Singapore law.
        Consumers (Users) are advised to read our{' '}
        <Link
          size="small"
          href={TERMS_OF_SERVICE_LINK_SG}
          rel="noopener noreferrer"
          target="_blank"
        >
          terms of service
        </Link>{' '}
        carefully.
      </Advisory>
    )
  } else {
    footerContent = (
      <Advisory size="sm">
        All material herein © by Fazz Business under its respective entities. All rights reserved.
      </Advisory>
    )
  }

  if (isTermLoanPage || isCreditLinePage || isKycIntendedRelationsPage) {
    additionalFooterContent = (
      <Advisory size="sm">
        From 1 May 2023, Loans are offered by Fazz Commerce Pte. Ltd., as an excluded moneylender
        under the Moneylenders Act. Fazz Commerce Pte. Ltd. is not regulated or licensed by the MAS.
        Borrowers are advised to read the terms and conditions of the loan agreement carefully.
      </Advisory>
    )
  }

  if (isTermLoanPage || isCreditLinePage || isKycIntendedRelationsPage) {
    additionalFooterContent = (
      <Advisory size="sm">
        From 1 May 2023, Loans are offered by Fazz Commerce Pte. Ltd., as an excluded moneylender
        under the Moneylenders Act. Fazz Commerce Pte. Ltd. is not regulated or licensed by the MAS.
        Borrowers are advised to read the terms and conditions of the loan agreement carefully.
      </Advisory>
    )
  }

  return (
    <FooterContainer>
      {footerContent}
      {additionalFooterContent}
      {process.env.MODE !== 'production' && (
        <Advisory size="sm">{process.env.REACT_APP_VERSION}</Advisory>
      )}
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
  min-width: 100%;
  background-color: ${colors.backgroundSecondaryNeutral};
  padding: ${spacing.md} ${spacing.md} ${spacing.md} ${spacing.lg};
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};

  ${DESKTOP_BREAKPOINT} {
    padding: ${spacing.lg};
  }
`

const Advisory = styled(Body)`
  color: ${colors.onNeutralSecondary};
  background-color: ${colors.backgroundSecondaryNeutral};
`
