export type FlexPayBillingSummary = {
  attributes: {
    paymentMethods: [
      {
        type: string
        accountNo: number
        displayName: string
        bankShortCode: string
      }
    ]
    userStatus: string
    outstandingPrincipal: number
    adminFee: number
    lateFee: number
    otherFee: number
    totalOutstanding: number
    minimumRepayment: number
    unpaidDue: number
    currentCycleBilling: number
  }
}

export enum FlexpayActions {
  SET_FLEXPAY_BILLING_SUMMARY = 'flexpayActions.SET_FLEXPAY_BILLING_SUMMARY',

}

type SetBillingSummary = {
  type: typeof FlexpayActions.SET_FLEXPAY_BILLING_SUMMARY
  flexpayBillingSummary?: FlexPayBillingSummary
}

export type ActionTypes = SetBillingSummary
