/* eslint-disable max-lines */

/**
 * Role Based Access rules:
 * 1. Static permissions are those permissions which don't need any data apart from the user role.
 * 2. Dynamic permissions are permissions which need additional data to determine access.
 *
 * Live Document:
 * (Please inform Felix Rowen and PM to make sure the document updated accordingly whenever rule changes)
 * [RBAC Master] https://www.notion.so/fazzfinancialgroup/Role-based-Access-Control-4db570e0d2ff494ab9ffe4a441957bea
 *
 * Old Document:
 * [RBAC v2] https://docs.google.com/spreadsheets/d/13SWuopGVollp0EwxiThoM9MpRKExG6HdK-mveEAMALA/edit#gid=1977665203
 * https://www.notion.so/fazzfinancialgroup/WIP-PRD-Fazz-Biz-Role-Based-Access-v1-0-eb1a16c7443243a1ab02506c83cf8c9f
 * https://www.notion.so/xfers/AuthZ-in-Multi-tenant-system-9fa7f5583da9485eb4b4d384a12f917f
 *
 * Note:
 * Initially feature matrix was going to be generated by express server and sent to FE but decided to hard code for MVP.
 * There's a plan to move this to backend to support custom role, probably 2022Q4 or 2023Q1.
 * Hence handling permission on FE might be deprecated in the future.
 */
import type { RootState as BizStore } from '~/biz/store/types'
import { PERMISSIONS, UserRoles } from '~/types'
import { store } from '../store/store'
import type { FeatureMatrixSelectorsType } from './types'

export function check(factors: (boolean | undefined)[]) {
  return factors.every((v) => v === true)
}

const getValueFromStore = <T>(fn: (state: BizStore) => T): T => fn(store.getState())

type RuleType = Partial<
  Record<
    UserRoles,
    {
      static: Partial<PERMISSIONS[]>
      dynamic: Partial<Record<PERMISSIONS, (args: FeatureMatrixSelectorsType) => void>>
    }
  >
>

const rules: RuleType = {
  [UserRoles.OWNER]: {
    static: [
      PERMISSIONS.TRANSACTION,
      PERMISSIONS.SANDBOX,
      PERMISSIONS.VERIFICATION,
      PERMISSIONS.TEAM_READ,
      PERMISSIONS.TEAM_EXECUTE,
      PERMISSIONS.MANAGE_ACCOUNT_BANK,
      PERMISSIONS.SETTINGS,
      PERMISSIONS.ACCOUNT_STATEMENT,
      PERMISSIONS.APPLY_LOAN,
      PERMISSIONS.TRANSACTION_ACCOUNT_BALANCE,
      PERMISSIONS.RECEIVE_TRANSACTION_LIST,
      PERMISSIONS.PAY_TRANSACTION_LIST,
      PERMISSIONS.EARN,
      PERMISSIONS.EARN_EXECUTE,
      PERMISSIONS.ID_PAY,
      PERMISSIONS.SB_LANDING_PAGE,
      PERMISSIONS.SB_DEPOSIT,
      PERMISSIONS.SB_WITHDRAWAL,
      PERMISSIONS.SB_DISBURSEMENTS,
      PERMISSIONS.SB_INVOICING,
      PERMISSIONS.DEVELOPER_TOOLS,
      PERMISSIONS.SB_DEVELOPER_TOOLS,
    ],
    dynamic: {
      [PERMISSIONS.APPLY_LOAN_EXECUTE]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.DISBURSEMENTS]: ({
        isAccountManagerVerified,
        isBizVerified,
        hasFundPoolForIdDisbursement,
      }: FeatureMatrixSelectorsType) => {
        const xfpayDisableDisbursements = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_disable_disbursements
        )
        if (xfpayDisableDisbursements) return false
        const isIndonesiaLegacyAccountForRBAC133 = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_whitelist_rbac133_id_legacy
        )
        if (isIndonesiaLegacyAccountForRBAC133) {
          return check([isBizVerified, hasFundPoolForIdDisbursement])
        }
        return check([isAccountManagerVerified, hasFundPoolForIdDisbursement])
      },
      [PERMISSIONS.DEPOSIT]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => {
        const isIndonesiaLegacyAccountForRBAC133 = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_whitelist_rbac133_id_legacy
        )
        if (isIndonesiaLegacyAccountForRBAC133) return isBizVerified
        return check([isAccountManagerVerified, isBizVerified])
      },
      [PERMISSIONS.WITHDRAW]: ({
        isAccountManagerVerified,
        hasFundPoolForIdWithdraw,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        const isWithdrawDisabled = getValueFromStore(
          (state: BizStore) => state.featureGate.featureGateIDs.fazzbiz_disable_withdraw
        )
        if (isWithdrawDisabled) return false
        return check([isAccountManagerVerified, hasFundPoolForIdWithdraw, isBizVerified])
      },
      [PERMISSIONS.CREATE_NEW_MERCHANT]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.INVOICING]: ({ isHighRiskMerchant }: FeatureMatrixSelectorsType) => {
        if (isHighRiskMerchant) return false
        const disablePaymentLinkByMerchants = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_disable_payment_link_by_merchants
        )
        return !disablePaymentLinkByMerchants
      },
      [PERMISSIONS.INVOICING_CREATE_INVOICE]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        const isIndonesiaLegacyAccountForRBAC133 = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_whitelist_rbac133_id_legacy
        )
        if (isIndonesiaLegacyAccountForRBAC133) {
          return true
        }
        return check([isAccountManagerVerified, isBizVerified])
      },
      [PERMISSIONS.XFERS_ACCEPT]: () => {
        const disableXfersAccept = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_disable_xfers_accept
        )
        return !disableXfersAccept
      },
      [PERMISSIONS.XFERS_SEND]: () => {
        const enableXfersSend = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_enable_xfers_send
        )
        return enableXfersSend
      },
      [PERMISSIONS.DEVELOPER_TOOLS_GENERATE_SECRET_KEY]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        const isIndonesiaLegacyAccountForRBAC133 = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_whitelist_rbac133_id_legacy
        )
        if (isIndonesiaLegacyAccountForRBAC133) return true
        return check([isAccountManagerVerified, isBizVerified])
      },
      [PERMISSIONS.ID_PAY_EXECUTE]: ({ isAccountManagerVerified }: FeatureMatrixSelectorsType) =>
        check([isAccountManagerVerified]),
      [PERMISSIONS.LOAN_ACCOUNT]: () => {
        const fazzBizEnableLoanAccount = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.fazzbiz_enable_loan_account
        )
        const fazzbizEnableNewLoan = getValueFromStore(
          (state: BizStore) => state.flippers.emailId.fazzbiz_enable_new_loan_dashboard
        )
        if (fazzbizEnableNewLoan) {
          // TODO: update after approved to production
          return false
        }
        return fazzBizEnableLoanAccount
      },
      [PERMISSIONS.FLEX_PAY_ACCOUNT]: () => {
        const fazzBizEnableFlexpayAccount = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_enable_flex_pay_account
        )
        return fazzBizEnableFlexpayAccount
      },
      [PERMISSIONS.NEED_KYC]: ({ isAccountManagerVerified }: FeatureMatrixSelectorsType) =>
        check([isAccountManagerVerified]),
      [PERMISSIONS.API_MENU]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.MORAK_LOAN]: () => {
        const isMorakLoanVerified = getValueFromStore(
          (state: BizStore) => state.wallets.isMorakVerified
        )
        return isMorakLoanVerified
      },
      [PERMISSIONS.NEW_LOAN]: () => {
        const fazzbizEnableNewLoan = getValueFromStore(
          (state: BizStore) => state.flippers.emailId.fazzbiz_enable_new_loan_dashboard
        )
        return fazzbizEnableNewLoan
      },
      [PERMISSIONS.OLD_APPLY_LOAN]: () => {
        // TODO - Kevin: This is temporary permission to control Apply Loan navigation between whitelisted user
        // will be deprecated as soon as loan self serve is fully released

        const fazzbizEnableLoanSelfServe = getValueFromStore(
          (state: BizStore) =>
            state.flippers.emailId.fazzbiz_id_test_whitelist_email_loan_self_serve
        )
        return !fazzbizEnableLoanSelfServe
      },
      [PERMISSIONS.LOAN_SELF_SERVE]: () => {
        const fazzbizEnableLoanSelfServe = getValueFromStore(
          (state: BizStore) =>
            state.flippers.emailId.fazzbiz_id_test_whitelist_email_loan_self_serve
        )
        return fazzbizEnableLoanSelfServe
      },
      [PERMISSIONS.SB_PAYMENT_API]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.SB_PAY_MENU]: () => {
        const fazzBizEnablePayMenu = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_whitelist_enable_sandbox_pay_menu
        )
        return fazzBizEnablePayMenu
      },
      [PERMISSIONS.ID_EARN]: ({ isAccountManagerVerified, isBizVerified }) => {
        const businessType = getValueFromStore(
          (state: BizStore) => state.businessVerification.businessInformation.businessType
        )
        return !(
          isAccountManagerVerified &&
          isBizVerified &&
          businessType?.toLowerCase() !== 'shop' &&
          businessType !== null
        )
      },
      [PERMISSIONS.CUSTOMERS_CARDS]: () => {
        const isCustomerCardEnabled = getValueFromStore(
          (state: BizStore) => state.customerCard.isCustomerCardAdminPanelEnabled
        )
        return isCustomerCardEnabled
      },
    },
  },
  [UserRoles.ADMIN]: {
    static: [
      PERMISSIONS.TRANSACTION,
      PERMISSIONS.SANDBOX,
      PERMISSIONS.TEAM_READ,
      PERMISSIONS.TEAM_EXECUTE,
      PERMISSIONS.MANAGE_ACCOUNT_BANK,
      PERMISSIONS.SETTINGS,
      PERMISSIONS.ACCOUNT_STATEMENT,
      PERMISSIONS.APPLY_LOAN,
      PERMISSIONS.TRANSACTION_ACCOUNT_BALANCE,
      PERMISSIONS.RECEIVE_TRANSACTION_LIST,
      PERMISSIONS.PAY_TRANSACTION_LIST,
      PERMISSIONS.EARN,
      PERMISSIONS.EARN_EXECUTE,
      PERMISSIONS.ID_PAY,
      PERMISSIONS.SB_LANDING_PAGE,
      PERMISSIONS.SB_DEPOSIT,
      PERMISSIONS.SB_WITHDRAWAL,
      PERMISSIONS.SB_DISBURSEMENTS,
      PERMISSIONS.SB_INVOICING,
      PERMISSIONS.SB_DEVELOPER_TOOLS,
      PERMISSIONS.DEVELOPER_TOOLS,
    ],
    dynamic: {
      [PERMISSIONS.APPLY_LOAN_EXECUTE]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.DISBURSEMENTS]: ({
        isAccountManagerVerified,
        isBizVerified,
        hasFundPoolForIdDisbursement,
      }: FeatureMatrixSelectorsType) => {
        const xfpayDisableDisbursements = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_disable_disbursements
        )
        if (xfpayDisableDisbursements) return false
        const isIndonesiaLegacyAccountForRBAC133 = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_whitelist_rbac133_id_legacy
        )
        if (isIndonesiaLegacyAccountForRBAC133) {
          return check([isBizVerified, hasFundPoolForIdDisbursement])
        }
        return check([isAccountManagerVerified, hasFundPoolForIdDisbursement])
      },
      [PERMISSIONS.DEPOSIT]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => {
        const isIndonesiaLegacyAccountForRBAC133 = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_whitelist_rbac133_id_legacy
        )
        if (isIndonesiaLegacyAccountForRBAC133) return isBizVerified
        return check([isAccountManagerVerified, isBizVerified])
      },
      [PERMISSIONS.WITHDRAW]: ({
        isBizVerified,
        isAccountManagerVerified,
        hasFundPoolForIdWithdraw,
      }: FeatureMatrixSelectorsType) => {
        const isWithdrawDisabled = getValueFromStore(
          (state: BizStore) => state.featureGate.featureGateIDs.fazzbiz_disable_withdraw
        )
        if (isWithdrawDisabled) return false

        const isIndonesiaLegacyAccountForRBAC133 = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_whitelist_rbac133_id_legacy
        )
        if (isIndonesiaLegacyAccountForRBAC133) {
          return check([isBizVerified, hasFundPoolForIdWithdraw])
        }
        return check([isAccountManagerVerified, hasFundPoolForIdWithdraw, isBizVerified])
      },
      [PERMISSIONS.CREATE_NEW_MERCHANT]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => check([isAccountManagerVerified, isBizVerified]),
      [PERMISSIONS.INVOICING]: ({ isHighRiskMerchant }: FeatureMatrixSelectorsType) => {
        if (isHighRiskMerchant) return false
        const disablePaymentLinkByMerchants = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_disable_payment_link_by_merchants
        )
        return !disablePaymentLinkByMerchants
      },
      [PERMISSIONS.INVOICING_CREATE_INVOICE]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        const isIndonesiaLegacyAccountForRBAC133 = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_whitelist_rbac133_id_legacy
        )
        if (isIndonesiaLegacyAccountForRBAC133) {
          return true
        }
        return check([isAccountManagerVerified, isBizVerified])
      },
      [PERMISSIONS.XFERS_ACCEPT]: () => {
        const disableXfersAccept = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_disable_xfers_accept
        )
        return !disableXfersAccept
      },
      [PERMISSIONS.XFERS_SEND]: () => {
        const enableXfersSend = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_enable_xfers_send
        )
        return enableXfersSend
      },
      [PERMISSIONS.DEVELOPER_TOOLS_GENERATE_SECRET_KEY]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => {
        const isIndonesiaLegacyAccountForRBAC133 = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_whitelist_rbac133_id_legacy
        )
        if (isIndonesiaLegacyAccountForRBAC133) return true
        return check([isAccountManagerVerified, isBizVerified])
      },
      [PERMISSIONS.ID_PAY_EXECUTE]: ({ isAccountManagerVerified }: FeatureMatrixSelectorsType) =>
        check([isAccountManagerVerified]),
      [PERMISSIONS.LOAN_ACCOUNT]: () => {
        const fazzBizEnableLoanAccount = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.fazzbiz_enable_loan_account
        )
        return fazzBizEnableLoanAccount
      },
      [PERMISSIONS.API_MENU]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.MORAK_LOAN]: () => {
        const isMorakLoanVerified = getValueFromStore(
          (state: BizStore) => state.wallets.isMorakVerified
        )
        return isMorakLoanVerified
      },
      [PERMISSIONS.SB_PAYMENT_API]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.SB_PAY_MENU]: () => {
        const fazzBizEnablePayMenu = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_whitelist_enable_sandbox_pay_menu
        )
        return fazzBizEnablePayMenu
      },
      [PERMISSIONS.ID_EARN]: ({ isAccountManagerVerified, isBizVerified }) => {
        const businessType = getValueFromStore(
          (state: BizStore) => state.businessVerification.businessInformation.businessType
        )
        return !(
          isAccountManagerVerified &&
          isBizVerified &&
          businessType?.toLowerCase() !== 'shop' &&
          businessType !== null
        )
      },
      [PERMISSIONS.CUSTOMERS_CARDS]: () => {
        const isCustomerCardEnabled = getValueFromStore(
          (state: BizStore) => state.customerCard.isCustomerCardAdminPanelEnabled
        )
        return isCustomerCardEnabled
      },
    },
  },
  [UserRoles.OPERATIONS]: {
    static: [
      PERMISSIONS.TRANSACTION,
      PERMISSIONS.SANDBOX,
      PERMISSIONS.TEAM_READ,
      PERMISSIONS.SETTINGS,
      PERMISSIONS.ACCOUNT_STATEMENT,
      PERMISSIONS.TRANSACTION_ACCOUNT_BALANCE,
      PERMISSIONS.RECEIVE_TRANSACTION_LIST,
      PERMISSIONS.PAY_TRANSACTION_LIST,
      PERMISSIONS.EARN,
      PERMISSIONS.EARN_EXECUTE,
      PERMISSIONS.ID_PAY,
      PERMISSIONS.SB_LANDING_PAGE,
      PERMISSIONS.SB_DEPOSIT,
      PERMISSIONS.SB_WITHDRAWAL,
      PERMISSIONS.SB_DISBURSEMENTS,
      PERMISSIONS.SB_INVOICING,
      PERMISSIONS.SB_DEVELOPER_TOOLS,
    ],
    dynamic: {
      [PERMISSIONS.DEPOSIT]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => {
        const isIndonesiaLegacyAccountForRBAC133 = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_whitelist_rbac133_id_legacy
        )
        if (isIndonesiaLegacyAccountForRBAC133) return isBizVerified
        return check([isAccountManagerVerified, isBizVerified])
      },
      [PERMISSIONS.WITHDRAW]: ({
        isAccountManagerVerified,
        hasFundPoolForIdWithdraw,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        const isWithdrawDisabled = getValueFromStore(
          (state: BizStore) => state.featureGate.featureGateIDs.fazzbiz_disable_withdraw
        )
        if (isWithdrawDisabled) return false
        return check([isAccountManagerVerified, hasFundPoolForIdWithdraw, isBizVerified])
      },
      [PERMISSIONS.DISBURSEMENTS]: ({
        isAccountManagerVerified,
        isBizVerified,
        hasFundPoolForIdDisbursement,
      }: FeatureMatrixSelectorsType) => {
        const xfpayDisableDisbursements = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_disable_disbursements
        )
        if (xfpayDisableDisbursements) return false
        const isIndonesiaLegacyAccountForRBAC133 = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_whitelist_rbac133_id_legacy
        )
        if (isIndonesiaLegacyAccountForRBAC133) {
          return check([isBizVerified, hasFundPoolForIdDisbursement])
        }
        return check([isAccountManagerVerified, hasFundPoolForIdDisbursement])
      },
      [PERMISSIONS.INVOICING]: ({ isHighRiskMerchant }: FeatureMatrixSelectorsType) => {
        if (isHighRiskMerchant) return false
        const disablePaymentLinkByMerchants = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_disable_payment_link_by_merchants
        )
        return !disablePaymentLinkByMerchants
      },
      [PERMISSIONS.INVOICING_CREATE_INVOICE]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        const isIndonesiaLegacyAccountForRBAC133 = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_whitelist_rbac133_id_legacy
        )
        if (isIndonesiaLegacyAccountForRBAC133) {
          return true
        }
        return check([isAccountManagerVerified, isBizVerified])
      },
      [PERMISSIONS.XFERS_ACCEPT]: () => {
        const disableXfersAccept = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_disable_xfers_accept
        )
        return !disableXfersAccept
      },
      [PERMISSIONS.XFERS_SEND]: () => {
        const enableXfersSend = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_enable_xfers_send
        )
        return enableXfersSend
      },
      [PERMISSIONS.API_MENU]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.SB_PAYMENT_API]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.CUSTOMERS_CARDS]: () => {
        const isCustomerCardEnabled = getValueFromStore(
          (state: BizStore) => state.customerCard.isCustomerCardAdminPanelEnabled
        )
        return isCustomerCardEnabled
      },
    },
  },
  [UserRoles.DEVELOPER]: {
    static: [
      PERMISSIONS.TRANSACTION,
      PERMISSIONS.SANDBOX,
      PERMISSIONS.RECEIVE_TRANSACTION_LIST,
      PERMISSIONS.PAY_TRANSACTION_LIST,
      PERMISSIONS.SB_LANDING_PAGE,
      PERMISSIONS.DEVELOPER_TOOLS,
      PERMISSIONS.SB_DEVELOPER_TOOLS,
    ],
    dynamic: {
      [PERMISSIONS.XFERS_ACCEPT]: () => {
        const disableXfersAccept = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_disable_xfers_accept
        )
        return !disableXfersAccept
      },
      [PERMISSIONS.XFERS_SEND]: () => {
        const enableXfersSend = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_enable_xfers_send
        )
        return enableXfersSend
      },
      [PERMISSIONS.DEVELOPER_TOOLS_GENERATE_SECRET_KEY]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => {
        const isIndonesiaLegacyAccountForRBAC133 = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_whitelist_rbac133_id_legacy
        )
        if (isIndonesiaLegacyAccountForRBAC133) return true
        return check([isAccountManagerVerified, isBizVerified])
      },
      [PERMISSIONS.API_MENU]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.SB_PAYMENT_API]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.ID_PAY_EXECUTE]: ({ isAccountManagerVerified }: FeatureMatrixSelectorsType) =>
        check([isAccountManagerVerified]),
      [PERMISSIONS.MORAK_LOAN]: () => {
        const isMorakLoanVerified = getValueFromStore(
          (state: BizStore) => state.wallets.isMorakVerified
        )
        return isMorakLoanVerified
      },
      [PERMISSIONS.CUSTOMERS_CARDS]: () => {
        const isCustomerCardEnabled = getValueFromStore(
          (state: BizStore) => state.customerCard.isCustomerCardAdminPanelEnabled
        )
        return isCustomerCardEnabled
      },
    },
  },
  [UserRoles.VIEWER]: {
    static: [
      PERMISSIONS.TRANSACTION,
      PERMISSIONS.SANDBOX,
      PERMISSIONS.TEAM_READ,
      PERMISSIONS.SETTINGS,
      PERMISSIONS.ACCOUNT_STATEMENT,
      PERMISSIONS.TRANSACTION_ACCOUNT_BALANCE,
      PERMISSIONS.RECEIVE_TRANSACTION_LIST,
      PERMISSIONS.PAY_TRANSACTION_LIST,
      PERMISSIONS.EARN,
      PERMISSIONS.SB_LANDING_PAGE,
    ],
    dynamic: {
      [PERMISSIONS.INVOICING]: ({ isHighRiskMerchant }: FeatureMatrixSelectorsType) => {
        if (isHighRiskMerchant) return false
        const isPaymentLinkDisabled = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_disable_payment_link_by_merchants
        )
        return !isPaymentLinkDisabled
      },
      [PERMISSIONS.XFERS_ACCEPT]: () => {
        const disableXfersAccept = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_disable_xfers_accept
        )
        return !disableXfersAccept
      },
      [PERMISSIONS.XFERS_SEND]: () => {
        const enableXfersSend = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_enable_xfers_send
        )
        return enableXfersSend
      },
      [PERMISSIONS.API_MENU]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.ID_PAY_EXECUTE]: ({ isAccountManagerVerified }: FeatureMatrixSelectorsType) =>
        check([isAccountManagerVerified]),
    },
  },
  [UserRoles.FINANCE]: {
    static: [
      PERMISSIONS.TRANSACTION,
      PERMISSIONS.TEAM_READ,
      PERMISSIONS.SETTINGS,
      PERMISSIONS.ACCOUNT_STATEMENT,
      PERMISSIONS.TRANSACTION_ACCOUNT_BALANCE,
      PERMISSIONS.ID_PAY,
    ],
    dynamic: {
      [PERMISSIONS.DISBURSEMENTS]: ({
        isAccountManagerVerified,
        isBizVerified,
        hasFundPoolForIdDisbursement,
      }: FeatureMatrixSelectorsType) => {
        const xfpayDisableDisbursements = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_disable_disbursements
        )
        if (xfpayDisableDisbursements) return false
        const isIndonesiaLegacyAccountForRBAC133 = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_whitelist_rbac133_id_legacy
        )
        if (isIndonesiaLegacyAccountForRBAC133) {
          return check([isBizVerified, hasFundPoolForIdDisbursement])
        }
        return check([isAccountManagerVerified, hasFundPoolForIdDisbursement])
      },
      [PERMISSIONS.DEPOSIT]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        const isIndonesiaLegacyAccountForRBAC133 = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_whitelist_rbac133_id_legacy
        )
        if (isIndonesiaLegacyAccountForRBAC133) return isBizVerified
        return check([isAccountManagerVerified, isBizVerified])
      },
      [PERMISSIONS.WITHDRAW]: ({
        isAccountManagerVerified,
        hasFundPoolForIdWithdraw,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        const isWithdrawDisabled = getValueFromStore(
          (state: BizStore) => state.featureGate.featureGateIDs.fazzbiz_disable_withdraw
        )
        if (isWithdrawDisabled) return false

        const isIndonesiaLegacyAccountForRBAC133 = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_whitelist_rbac133_id_legacy
        )
        if (isIndonesiaLegacyAccountForRBAC133) {
          return check([isBizVerified, hasFundPoolForIdWithdraw])
        }
        return check([isAccountManagerVerified, hasFundPoolForIdWithdraw, isBizVerified])
      },
      [PERMISSIONS.INVOICING]: ({ isHighRiskMerchant }: FeatureMatrixSelectorsType) => {
        const isPaymentLinkDisabled = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_disable_payment_link_by_merchants
        )
        if (isHighRiskMerchant) return false
        return !isPaymentLinkDisabled
      },
      [PERMISSIONS.INVOICING_CREATE_INVOICE]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        const isIndonesiaLegacyAccountForRBAC133 = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_whitelist_rbac133_id_legacy
        )
        if (isIndonesiaLegacyAccountForRBAC133) {
          return true
        }
        return check([isAccountManagerVerified, isBizVerified])
      },
      [PERMISSIONS.XFERS_ACCEPT]: () => {
        const disableXfersAccept = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_disable_xfers_accept
        )
        return !disableXfersAccept
      },
      [PERMISSIONS.XFERS_SEND]: () => {
        const enableXfersSend = getValueFromStore(
          (state: BizStore) => state.flippers.merchantId.xfpay_enable_xfers_send
        )
        return enableXfersSend
      },
      [PERMISSIONS.API_MENU]: () => {
        const fazzBizEnableAPIMenu = getValueFromStore(
          (state: BizStore) => state.flippers.bizId.fazzbiz_enable_api_menu
        )
        return fazzBizEnableAPIMenu
      },
      [PERMISSIONS.ID_PAY_EXECUTE]: ({ isAccountManagerVerified }: FeatureMatrixSelectorsType) =>
        check([isAccountManagerVerified]),
      [PERMISSIONS.MORAK_LOAN]: () => {
        const isMorakLoanVerified = getValueFromStore(
          (state: BizStore) => state.wallets.isMorakVerified
        )
        return isMorakLoanVerified
      },
      [PERMISSIONS.CUSTOMERS_CARDS]: () => {
        const isCustomerCardEnabled = getValueFromStore(
          (state: BizStore) => state.customerCard.isCustomerCardAdminPanelEnabled
        )
        return isCustomerCardEnabled
      },
    },
  },
}

export default rules
