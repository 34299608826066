import { SVGProps } from 'react'

const SvgWarningWithCircle = function SvgWarningCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.278 3.101A9.5 9.5 0 0 0 11 1.5 9.51 9.51 0 0 0 1.5 11a9.5 9.5 0 1 0 14.778-7.899ZM4.888 1.854a11 11 0 1 1 12.223 18.292A11 11 0 0 1 4.89 1.854ZM9.88 15.546c.059.145.147.276.26.384a1.199 1.199 0 0 0 .86.37 1.16 1.16 0 0 0 1.094-.703c.06-.144.09-.3.086-.457a1.079 1.079 0 0 0-.35-.83A1.16 1.16 0 0 0 11 14a1.2 1.2 0 0 0-.86.26 1.07 1.07 0 0 0-.34.83 1.11 1.11 0 0 0 .08.456ZM10 5.85v3.47l.12 3.47h1.7L12 9.32V5.85h-2Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgWarningWithCircle
