import { Tooltip as AntdTooltip } from 'antd'
import { TooltipGlobalStyle } from './Tooltip.styles'
import { TooltipProps } from './Tooltip.types'

export function Tooltip({ style, className, label, children, placement = 'top' }: TooltipProps) {
  return (
    <AntdTooltip title={label} className={className} style={style} placement={placement}>
      <TooltipGlobalStyle />
      {children}
    </AntdTooltip>
  )
}

export default Tooltip
