import { StyledButton } from './Button.styles'
import { ButtonProps } from './Button.types'

export function Button({
  variant = 'primary',
  colorScheme = 'default',
  size = 'lg',
  isDisabled = false,
  isLoading = false,
  fullWidth = false,
  type = 'button',
  icon,
  style,
  className,
  onClick,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  onMouseDown,
  children,
}: ButtonProps) {
  return (
    <StyledButton
      size={size}
      disabled={isDisabled}
      loading={isLoading}
      onClick={onClick}
      onFocus={onFocus}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={style}
      className={className}
      fullWidth={fullWidth}
      colorScheme={colorScheme}
      variant={variant}
      icon={icon}
      htmlType={type}
    >
      {children}
    </StyledButton>
  )
}

export default Button
