import {
  FacebookFilled,
  GlobalOutlined,
  InstagramFilled,
  LinkedinFilled,
  TwitterCircleFilled,
} from '@ant-design/icons'
import { Trans, useTranslation } from 'react-i18next'
import styled from '@emotion/styled/macro'
import { Button, DESKTOP_BREAKPOINT, fdsTheme, Link, Typography } from '@fazz/design-system'
import { FazzBusinessLogo } from '@fazz/design-system-logos'
import { useAppTypeContext } from '~/hooks/useAppTypeContext'
import { AppType } from '~/types'
import { FazzBrandingLinks, SupportLinks } from '~/common/commonLinks'
import i18n from '~/i18n'
import SessionUtils from '~/utils/SessionUtils'
import CornerSupergraphic from '../SplashPagesShared/CornerSupergraphic'
import SplashPageLayout from '../SplashPagesShared/SplashPageLayout'
import MaintenanceSVG from './MaintenanceSVG'

type Props = {
  title?: string
  body?: React.ReactNode
  endDate?: string
}

const { spacing, colors, fontSize } = fdsTheme
const { Body, Title, Label } = Typography

function MaintenancePage({ title, body, endDate }: Props) {
  const [FAZZ_BIZ_LOGO_WIDTH, FAZZ_BIZ_LOGO_HEIGHT] = [100, 40]
  const { appType } = useAppTypeContext()
  const { t: i18nTranslation } = useTranslation([
    'common/maintenancePage',
    'common/commonTranslations',
  ])
  const appTypeText = appType === AppType.SINGAPORE ? 'Singapore' : 'Indonesia'
  const formattedEndDateText =
    endDate &&
    new Intl.DateTimeFormat(i18n.language, {
      dateStyle: 'long',
      timeStyle: 'long',
    }).format(new Date(endDate))

  return (
    <SplashPageLayout>
      <CornerDecoratorTopLeft>
        <CornerSupergraphic />
      </CornerDecoratorTopLeft>
      <MainContainer>
        <FazzBusinessLogo width={FAZZ_BIZ_LOGO_WIDTH} height={FAZZ_BIZ_LOGO_HEIGHT} />
        <MaintenanceSVG />
        <div>
          <Title size="md" style={{ marginBottom: spacing.xs }}>
            {title ?? i18nTranslation('MAINTENANCE_TITLE_DEFAULT')}
          </Title>
          {body ? (
            <Body>{body}</Body>
          ) : (
            <div>
              <Body display="inline">
                {i18nTranslation('MAINTENANCE_BODY_DEFAULT', {
                  appType: appTypeText,
                })}{' '}
              </Body>

              {formattedEndDateText && (
                <Body display="inline">
                  <Trans
                    t={i18nTranslation}
                    i18nKey="MAINTENANCE_END_DATE_DEFAULT"
                    values={{ endDate: formattedEndDateText }}
                  >
                    All transactions will resume normally on <strong>{{ endDate }}</strong>
                  </Trans>{' '}
                </Body>
              )}

              <Body display="inline">{i18nTranslation('MAINTENANCE_INCONVENIENCE_APOLOGY')}</Body>
            </div>
          )}
        </div>
        <Button onClick={() => SessionUtils.ssoLogout()}>{i18nTranslation('BACK_TO_LOGIN')}</Button>
        <Link
          href={
            appType === AppType.SINGAPORE
              ? SupportLinks.WebsiteSupportSg
              : SupportLinks.WebsiteSupportID
          }
          target="_blank"
          rel="noreferrer noopener"
        >
          <Label size="md" color={colors.primary}>
            {i18nTranslation('CUSTOMER_SUPPORT', { ns: 'common/commonTranslations' })}
          </Label>
        </Link>
        <SocialsContainer>
          <Body size="md" color={colors.onNeutralSecondary}>
            {i18nTranslation('CONNECT_WITH_US')}
          </Body>
          <SocialsIcons>
            <a href={FazzBrandingLinks.MainWebsite} target="_blank" rel="noreferrer noopener">
              <GlobalOutlined />
            </a>
            <a href={FazzBrandingLinks.Facebook} target="_blank" rel="noreferrer noopener">
              <FacebookFilled />
            </a>
            <a href={FazzBrandingLinks.Instagram} target="_blank" rel="noreferrer noopener">
              <InstagramFilled />
            </a>
            <a href={FazzBrandingLinks.Twitter} target="_blank" rel="noreferrer noopener">
              <TwitterCircleFilled />
            </a>
            <a href={FazzBrandingLinks.LinkedIn} target="_blank" rel="noreferrer noopener">
              <LinkedinFilled />
            </a>
          </SocialsIcons>
        </SocialsContainer>
      </MainContainer>
      <CornerDecoratorBottomRight>
        <CornerSupergraphic />
      </CornerDecoratorBottomRight>
    </SplashPageLayout>
  )
}

const CornerDecoratorTopLeft = styled.div`
  position: absolute;
  width: 25vw;
  height: 25vh;
  top: 0;
  left: 0;

  & > svg {
    position: absolute;

    transform-origin: center;
    transform: rotate(180deg) scale(30%) translate(130%, 130%);
    top: 0;
    left: 0;

    ${DESKTOP_BREAKPOINT} {
      transform: rotate(180deg) scale(80%) translate(30%, 30%);
    }
  }
`

const CornerDecoratorBottomRight = styled.div`
  position: absolute;
  width: 25vw;
  height: 25vh;
  bottom: 0;
  right: 0;

  & > svg {
    position: absolute;

    bottom: 0;
    right: 0;
    transform: scale(30%) translate(130%, 130%);
    ${DESKTOP_BREAKPOINT} {
      transform: scale(80%) translate(30%, 30%);
    }
  }
`

const MainContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: ${spacing.lg};
  padding: ${spacing.md};
  max-width: 612px;
  z-index: 999;
`

const SocialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacing.sm};
`

const SocialsIcons = styled.div`
  display: flex;
  color: ${colors.active};
  font-size: ${fontSize.labelLarge};
  gap: ${spacing.sm};

  & > * {
    &:hover {
      cursor: pointer;
    }
  }

  a {
    color: inherit;
  }
`
export default MaintenancePage
