import { SVGProps } from 'react'

function SvgGpn(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 75 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m44.162 9.6 3.175 9.15a2.5 2.5 0 0 1 0 1.688l-2.862 5.812-3.163-3.925L40.6 20.2a2.075 2.075 0 0 1 0-1.25l.525-1.162c.125-.25.362-.263.437 0L43.037 22a1.425 1.425 0 0 0 .313-1.462l-1.438-3.963a3.3 3.3 0 0 1 .613-2.637c.125-.213.312-.213.412 0l1.675 4.812c.35-.125.488-.837.238-1.563L43.312 12.5a3.137 3.137 0 0 1 .213-1.725l.637-1.138V9.6Zm1.575 16.1a15.67 15.67 0 0 0 3.363.137 1.699 1.699 0 0 1 1.062.763c1.188 0 1.7.537 1.575 1.537a1.137 1.137 0 0 0-1.962-.337.912.912 0 0 0 1.175 0c0 .462-.513.75-1.725.825-.775.113-3.263 2.175-4.8 3.55a13.59 13.59 0 0 1-5.15 2.363l-12.5 3.75c-.263.075-.4-.2-.25-.3a11.963 11.963 0 0 1 2.812-1.688c-.062-.162-.387-.2-.875-.162L24.1 37.963c.137-.526.762-1.126 1.987-1.763l6.663-4.013c.187-.1.225-.2.075-.312-.625 0-3.025-2.6-5-4.538-.33-.339-.537-.779-.588-1.25v-1.35c0-.224.113-.224.313 0l4.95 4.638c.512.462.475-.5.1-.837L26.725 22.6a2.5 2.5 0 0 1-.525-1.188V19.9c0-.188.1-.225.312 0l5.988 5.825c.45.375.4-.663 0-1.088l-6.675-6.562a3.038 3.038 0 0 1-.825-1.55V14.85c0-.2.125-.225.387 0l7.113 6.925c.387.375.637-.387 0-1.012l-7.888-7.876a2.712 2.712 0 0 1-.725-1.4v-1.75c0-.25.238-.3.425-.125L32.5 17.5c.5.525.512-.262.15-.825L23.362 7.5a4.825 4.825 0 0 1-.487-3.25c0-.175.125-.213.275-.088L35 15.688 44.875 28.1c.125.15.25.2.4.125v-.55l-.475-.825 1.45-.325-.463-.825h-.05Zm2.9.913.775.062c-.262.337-.6.363-1.037 0l.1-.225.162.15v.012Z"
        fill="#F5303E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.163 34.438c-3.463-.338-8.026-.138-8 6.025.087 3.5 1.9 5.312 5.687 5.45a10.249 10.249 0 0 0 4.4-.913v-5a1.7 1.7 0 0 0-2.238-.512l-3.187 1.25V41h3.137v2.325c-2.924.875-5.287.1-5.274-3a5.536 5.536 0 0 1 .412-2.375c.15-.525.762-1.125 1.987-1.75l3.013-1.825.063.063ZM44.075 34.225h1.575a.837.837 0 0 1 .65.388l5 6.787v-7.012a.15.15 0 0 1 .137-.175h1.488a.974.974 0 0 1 .925.887v10.3a.287.287 0 0 1-.3.313H51.8c-.363 0-.625-.375-.863-.675l-4.487-6.163v6.588a.237.237 0 0 1-.263.25h-1.425a1.075 1.075 0 0 1-.912-.888V34.45a.201.201 0 0 1 .134-.216.2.2 0 0 1 .09-.009ZM37.112 36.25l3.938-.875a4.137 4.137 0 0 1 .788 4.875 3.45 3.45 0 0 1-3.026 1.95h-3.325v3.275c0 .188-.087.275-.312.275h-1.337a1.25 1.25 0 0 1-.913-1.013v-7.35c0-.162.4-.412.875-.525l1.175-.262c.288 0 .587 0 .6.137v3.338h2.5c.837 0 1.512-.613 1.525-1.825.012-1.212-.462-1.913-2.5-1.763v-.237h.012Z"
        fill="#003563"
      />
    </svg>
  )
}

export default SvgGpn
