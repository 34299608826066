import Switch, { SwitchProps } from 'antd/lib/switch'
import styled from '@emotion/styled'
import { useTheme } from '../../../themes/deprecated/themeContext'

export default function CustomSwitch({ ...rest }: SwitchProps) {
  const { status, button } = useTheme()
  const StyledSwitch = styled(Switch)`
    background-color: ${button.disabled};

    &.ant-switch-checked {
      background-color: ${status.positive};
    }
  `
  return <StyledSwitch {...rest} />
}
