import type { PATHS } from '~/biz/routes/paths'
import type { VerificationStatusType } from '~/api/idVerification/idVerification.types'
import type { Moment } from '~/types'

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum BusinessVerificationActions {
  SAVE_BUSINESS_DATA = 'BusinessVerificationActions.SAVE_BUSINESS_DATA',
  SAVE_BUSINESS_DATA_NOT_INITIALIZED = 'BusinessVerificationActions.SAVE_BUSINESS_DATA_NOT_INITIALIZED',
  SET_BUSINESS_DATA = 'BusinessVerificationActions.SET_BUSINESS_DATA',
  SET_CURRENT_PATH = 'BusinessVerificationActions.SET_CURRENT_PATH',
}

export type BusinessInformation = {
  businessType: BusinessType
  businessName?: string
  companyDisplayname?: string
  businessRegistrationNumber?: string
  businessContactNo?: number
  businessDateOfIncorporation?: Moment | null
  businessLicenseExpiryDate?: Moment | undefined
  countryOfIncorporation?: string
  businessAddressLine1?: string
  businessAddressLine2?: string
  businessPostalCode?: number
  city?: string
  country?: string
  tinNpwp?: string
}

export type BusinessType = 'private_company' | 'public_company' | 'shop' | undefined

export type BusinessRelation = {
  methods?: Array<'xfers_accept' | 'xfers_send' | 'xfers_wallet'>
}

export type BusinessActivity = {
  countriesOfActivities?: Array<string>
  businessWebsite?: string
  businessCategory?: string
  businessSubcategory?: string
  businessActivitySummary?: string
  expectedTransactionVolume?: string
  expectedValuePerTransaction?: string
  expectedGtv?: string
  sourceOfWealth?: string
  sourceOfFunds?: string
  sourceOfGoods?: string
}

export type UploadDocument = {
  tinNpwpSignedId?: string
  bankAccountProofSignedId?: string
  businessLicenseSignedId?: string
  deedOfEstablishmentSignedId?: string
  powerOfAttorneySignedId?: string
  tinNpwpUrl?: string
  bankAccountProofUrl?: string
  businessLicenseUrl?: string
  deedOfEstablishmentUrl?: string
  powerOfAttorneyUrl?: string
}

export type Role = 'bo' | 'director'

export type State = {
  managerVerificationStatus?: VerificationStatusType
  isManagerDirector?: boolean
  businessInformation: BusinessInformation
  businessRelation: BusinessRelation
  businessActivity: BusinessActivity
  uploadDocument: UploadDocument
  verificationStatus?: 'unverified' | 'rejected' | 'verified' | 'pending' | 'initial' | 'verifying'
  currentPath?: PATHS
  review?: undefined
  initialized: boolean
}

export type Pages =
  | 'businessInformation'
  | 'businessRelation'
  | 'businessActivity'
  | 'uploadDocument'
  | 'review'

export type DocumentTypes =
  | 'bankAccountProof'
  | 'businessLicense'
  | 'deedOfEstablishment'
  | 'tinNpwp'
  | 'powerOfAttorney'

type SaveBusinessData = {
  type: typeof BusinessVerificationActions.SAVE_BUSINESS_DATA
  data: object
}

type SaveBusinessDataNotInitialized = {
  type: typeof BusinessVerificationActions.SAVE_BUSINESS_DATA_NOT_INITIALIZED
  data: object
}

type SetBusinessData = {
  type: typeof BusinessVerificationActions.SET_BUSINESS_DATA
  page: Pages
  key: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
}

type SetCurrentPath = {
  type: typeof BusinessVerificationActions.SET_CURRENT_PATH
  path: PATHS
}

export type ActionTypes =
  | SaveBusinessData
  | SaveBusinessDataNotInitialized
  | SetBusinessData
  | SetCurrentPath
