import type { WALLET_TYPES } from '~/types'

export type Wallet = {
  accountId: number
  type: WALLET_TYPES
  balance: string
  label: string
  accountType: string
}

export type CopyTextCountry = {
  id: CopyTextViewType
}

export type CopyTextViewType = {
  walletForm: WalletFormCopyText
}

export type WalletFormCopyText = {
  title: string
  selectTitle: string
  placeholder: string
}

export enum DepositActions {
  SET_IS_LOADING = 'DepositActions.SET_IS_LOADING',
  SET_WALLETS = 'DepositActions.SET_WALLETS',
  GET_ACCOUNT_LIMITS = 'DepositActions.GET_ACCOUNT_LIMITS',
}

type SetIsLoading = {
  type: typeof DepositActions.SET_IS_LOADING
  isLoading: boolean
}

type SetWallets = {
  type: typeof DepositActions.SET_WALLETS
  wallets: Wallet[]
}

export type ActionTypes = SetIsLoading | SetWallets
