import { SVGProps } from 'react'

function SvgRequest(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.117 8.083a.633.633 0 0 0-.358.834c.193.482.292.997.291 1.516a4.168 4.168 0 0 1-7.113 2.947 4.165 4.165 0 0 1 3.689-7.047.625.625 0 0 0 .208-1.233 5.342 5.342 0 0 0-.933-.1 5.4 5.4 0 1 0 5.024 3.425.625.625 0 0 0-.808-.342Z"
        fill="currentColor"
      />
      <path
        d="M19.451 4.508a.834.834 0 0 0-.5-1.416l-1.133-.125-.15-1.25a.833.833 0 0 0-.833-.725.833.833 0 0 0-.583.241l-1.5 1.5a8.483 8.483 0 0 0-3.842-.916 8.618 8.618 0 0 0-4.227 16.151 8.616 8.616 0 0 0 12.757-6.7 8.615 8.615 0 0 0-1.289-5.435l1.3-1.325Zm-2.924-1.791.183 1.391 1.241.134-.833.833-.575.575-.25.25-1.325-.067-.167-1.35.15-.15.9-.9.676-.716Zm1.715 7.7a7.35 7.35 0 1 1-7.34-7.35 7.166 7.166 0 0 1 2.891.616.832.832 0 0 0-.233.692l.216 1.708-3.334 3.334a.616.616 0 0 0 0 .883.591.591 0 0 0 .442.183.616.616 0 0 0 .441-.183l3.334-3.333 1.708.183h.092a.833.833 0 0 0 .584-.242l.241-.241a7.34 7.34 0 0 1 .958 3.75Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgRequest
