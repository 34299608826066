type ErrorContent = {
  [key: string]: ErrorDetails
}

type ErrorDetails = {
  errorTitle: string
  errorSubtitle?: string
  errorContent?: string | React.ReactNode
}

const ERROR_CONTENT: ErrorContent = {
  '400': {
    errorTitle: '400',
    errorSubtitle: 'Bad request',
    errorContent: 'Your request resulted in an error.',
  },
  '401': {
    errorTitle: '401',
    errorSubtitle: 'No authorization found',
    errorContent: 'You do not have permission to access this site.',
  },
  '403': {
    errorTitle: '403',
    errorSubtitle: 'Forbidden',
    errorContent: 'An error occurred when we tried to process your request.',
  },
  '404': {
    errorTitle: '404',
    errorSubtitle: 'Page Not Found',
  },
  '408': {
    errorTitle: '408',
    errorSubtitle: 'Request time-out',
    errorContent: "Your browser didn't send a complete request this time.",
  },
  '500': {
    errorTitle: '500',
    errorSubtitle: 'Internal server error',
    errorContent: "Your browser didn't send a complete request this time.",
  },
  'Access denied': {
    errorTitle: 'Oops!',
    errorSubtitle: 'Access denied',
    errorContent: 'You do not have the permission to view the page.',
  },
  'Sandbox Mode Not Available': {
    errorTitle: 'Oops!',
    errorSubtitle: 'Page Not Available',
    errorContent: 'Sandbox mode is not available for this page.',
  },
}
export default {
  getErrorObject: (errorCode: string) => ERROR_CONTENT[errorCode],
}
