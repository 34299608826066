import { ReactChild, useContext, createContext } from 'react'
import NormalizeStyles from './NormalizeStyles'
import { fazzbizTheme } from './theme'

const ThemeContext = createContext(fazzbizTheme)

type ThemeProviderProps = {
  theme?: typeof fazzbizTheme
  children: ReactChild
}
function ThemeProvider({ theme = fazzbizTheme, children }: ThemeProviderProps) {
  return (
    <ThemeContext.Provider value={theme}>
      <NormalizeStyles />
      {children}
    </ThemeContext.Provider>
  )
}

/** @deprecated please use fazzBizzThemeFds3 theme instead */
const useTheme = () => {
  const context = useContext(ThemeContext)
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}

export { ThemeProvider, useTheme }
