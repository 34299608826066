import * as React from 'react'

function SvgSearch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 12a7 7 0 11-14 0 7 7 0 0114 0zm-1.07 8.052A9.955 9.955 0 0112 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10c0 2.22-.724 4.271-1.948 5.93l7.509 7.51a1.5 1.5 0 01-2.122 2.12l-7.508-7.508z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSearch
