import * as React from 'react'

function SvgCompanyComplexTwoTone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 55 55" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 10V8h18v2H19zm-3 0V6a1 1 0 011-1h22a1 1 0 011 1v4h4a1 1 0 011 1v39a1 1 0 01-1 1H12a1 1 0 01-1-1V11a1 1 0 011-1h4zm23 3H14v35h7v-9.5a1.5 1.5 0 013 0V48h8v-9.5a1.5 1.5 0 013 0V48h7V13h-3zm-19 5v3h5v-3h-5zm-1-2a1 1 0 00-1 1v5a1 1 0 001 1h7a1 1 0 001-1v-5a1 1 0 00-1-1h-7zm1 14v-3h5v3h-5zm-2-4a1 1 0 011-1h7a1 1 0 011 1v5a1 1 0 01-1 1h-7a1 1 0 01-1-1v-5zm13-8v3h5v-3h-5zm-1-2a1 1 0 00-1 1v5a1 1 0 001 1h7a1 1 0 001-1v-5a1 1 0 00-1-1h-7zm1 14v-3h5v3h-5zm-2-4a1 1 0 011-1h7a1 1 0 011 1v5a1 1 0 01-1 1h-7a1 1 0 01-1-1v-5z"
        fill="#1B2736"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 37.5a1.5 1.5 0 011.5-1.5h19a1.5 1.5 0 010 3h-19a1.5 1.5 0 01-1.5-1.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCompanyComplexTwoTone
