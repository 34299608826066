import { get } from '~/bizRegional/helpers/apiHelper'
import type { PATHS } from '~/bizRegional/routes/paths'
import type { Dispatch } from '~/bizRegional/store/types'
import { setCustomerCardsConfig } from '~/rootStore/customerCard/action'
import { getFeatureGateStatus } from '~/rootStore/featureGate/actions'
import { getFlipperStatus } from '~/rootStore/flippers/actions'
import { ENDPOINTS } from '~/api'
import { getCustomerCardsConfig } from '~/api/customerCard.api'
import { get as getFromFazzbiz } from '~/api/fazzbizBaseApi'
import { getVerificationStatusSummary } from '~/api/initialisation'
import type { VerificationStatusSummaryResponse } from '~/api/initialisation/initialisation.types'
import type { AppType, VerificationStatusType } from '~/types'
import { BusinessRegistrationCountry, UserRoles, WALLET_TYPES } from '~/types'
import { Notification as NotificationComponent } from '~/components/Notification'
import { MocksManager } from '~/managers/MocksManager'
import StringUtils from '~/utils/StringUtils'
import type { ActionTypes, FeatureMatrix, Notification } from './types'
import { RouteActions } from './types'

export const redirect = (to: PATHS) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: RouteActions.SET_REDIRECT,
    to,
  })
}

export const resetRedirect = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: RouteActions.SET_REDIRECT,
    to: null,
  })
}

export const getBusinessEntities = () => async (dispatch: Dispatch<ActionTypes>) => {
  try {
    const resp = await getFromFazzbiz(ENDPOINTS.FAZZBIZ_API_BUSINESS_ENTITIES)
    const businessEntities = resp.data.data
    dispatch({
      type: RouteActions.SET_BUSINESS_ENTITIES,
      businessEntities,
    })
  } catch (e: any) {
    NotificationComponent.error({
      message: 'Error',
      description: `Failed to initialize: ${e.message || 'Error resolving BusinessEntity'}.`,
      duration: 0,
    })
    // TODO Kerk(fazzbiz-rollbar): Capture error in rollbar in separate ticket
  }
}

export const getEmployers = () => async (dispatch: Dispatch<ActionTypes>) => {
  try {
    const resp = await getFromFazzbiz(ENDPOINTS.FAZZBIZ_API_EMPLOYERS)
    const employers = resp.data.data
    dispatch({
      type: RouteActions.SET_EMPLOYERS,
      employers,
    })
  } catch (e: any) {
    NotificationComponent.error({
      message: 'Error',
      description: `Failed to initialize: ${e.message ? e.message : 'Error resolving Employers'}.`,
      duration: 0,
    })
    // TODO Kerk(fazzbiz-rollbar): Capture error in rollbar in separate ticket
  }
}

export const init = (appType: AppType) => (dispatch: Dispatch<ActionTypes>) => {
  Promise.all([
    get(ENDPOINTS.API_SSO_PROFILE),
    get(ENDPOINTS.API_V3_USER_PROFILE),
    get(ENDPOINTS.API_V3_MERCHANT_DETAILS),
    getVerificationStatusSummary(),
    getCustomerCardsConfig(),
  ]).then(
    ([
      ssoProfileResp,
      userProfileResp,
      merchantDetailsResp,
      verificationSummaryResp,
      customerCardConfigResp,
    ]) => {
      // checks if permissions are supposed to be mocked and returns the appropriate data
      const isPermissionsMocked = MocksManager.isMockMode('permissions')
      const permissionMocks = MocksManager.getMocks('permission_mocks')

      const isWalletMocked = MocksManager.isMockMode('wallet')
      const walletMocks = MocksManager.getMocks('wallet_mocks')

      const verificationSummary = verificationSummaryResp.data.data.attributes
      verificationSummaryResp as VerificationStatusSummaryResponse
      const [wrappedSsoProfileResp, wrappedMerchantDetailsResp, wrappedVerificationSummary] =
        getWrappedResponses(isPermissionsMocked, permissionMocks, {
          ssoProfileResp,
          merchantDetailsResp,
          verificationSummary,
        })

      const wrappedUserProfileResp = getUserWrappedResponse(
        isWalletMocked,
        walletMocks,
        userProfileResp
      )

      // TODO Kerk(fazzbiz-rollbar): Set User in rollbar in separate ticket
      dispatch({
        type: RouteActions.SET_USER_PROFILE,
        user: {
          kcId: wrappedSsoProfileResp.kc_id,
          bizId: ssoProfileResp.merchant_id,
          merchantId: userProfileResp.merchant_id,
          fullName: ssoProfileResp.full_name,
          email: wrappedSsoProfileResp.email,
          countryCode: wrappedSsoProfileResp.country_code,
          mobileNumber: wrappedSsoProfileResp.mobile_number,
          companyId: wrappedSsoProfileResp.company_id,
          displayname: userProfileResp.displayname,
          userRole: ssoProfileResp.user_role,
        },
      })
      dispatch({
        type: RouteActions.SET_TRANSACTION_ACCOUNTS,
        /* eslint-disable @typescript-eslint/no-explicit-any */
        accounts: wrappedUserProfileResp.accounts.map((acc: any) =>
          StringUtils.convertKeysToCamelCase(acc)
        ),
      })

      const featureMatrix = parseFeatureMatrixValues(
        wrappedSsoProfileResp,
        wrappedMerchantDetailsResp,
        wrappedVerificationSummary
      )

      dispatch({
        type: RouteActions.SET_FEATURE_MATRIX,
        featureMatrix,
      })

      dispatch({
        type: RouteActions.INITIATE_GLOBAL_STORE,
        isInitiated: true,
      })

      dispatch(
        // @ts-expect-error: multiple dispatch types error
        getFlipperStatus(
          appType,
          wrappedSsoProfileResp.email,
          userProfileResp.merchant_id,
          ssoProfileResp.merchant_id
        )
      )

      dispatch(
        // @ts-expect-error: multiple dispatch types error
        getFeatureGateStatus(appType, ssoProfileResp.merchant_id)
      )

      dispatch(
        // @ts-expect-error: multiple dispatch types error
        setCustomerCardsConfig(customerCardConfigResp)
      )
    }
  )

  // fetching notifications in the init function, it only needs to be fetched once at the beginning of the session and stored in redux
  dispatch(fetchNotifications())
}

export const invalidateGlobalStore = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: RouteActions.INVALIDATE_GLOBAL_STORE,
    isInitiated: false,
  })
}

export const fetchAccounts = () => (dispatch: Dispatch<ActionTypes>) => {
  get(ENDPOINTS.API_XFERS_ACCOUNTS).then((resp) => {
    dispatch({
      type: RouteActions.SET_TRANSACTION_ACCOUNTS,
      /* eslint-disable @typescript-eslint/no-explicit-any */
      accounts: resp.map((account: any) => StringUtils.convertKeysToCamelCase(account)),
    })
  })
}

export const fetchNotifications = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: RouteActions.TOGGLE_NOTIFICATIONS_LOADING,
    isLoading: true,
  })

  get(ENDPOINTS.API_XFERS_USER_MESSAGES).then((resp) => {
    const unreadCount = resp.filter(
      (notification: Notification) => notification.is_read === 0
    ).length

    dispatch({
      type: RouteActions.SET_NOTIFICATIONS,
      data: resp,
      unreadCount,
      isLoading: false,
    })
  })
}

export const setDashboardRevampEnabled =
  (enabled: boolean) => (dispatch: Dispatch<ActionTypes>) => {
    dispatch({
      type: RouteActions.SET_DASHBOARD_REVAMP_ENABLED,
      enabled,
    })
  }

const parseFeatureMatrixValues = (
  ssoProfileResp: any,
  merchantDetailsResp: any,
  verificationSummary: {
    amVerificationStatus: VerificationStatusType
    businessVerificationStatus: VerificationStatusType
  }
) => {
  // Note: Setting this as 'invalid' because we need to be able to differentiate between
  // undefined that comes from API not being able to read the ssoProfileResp.userRole[0] value
  // or undefined because the API hasn't resolved yet. This is string not in the UserRoles enum
  // because UserRoles should only include values that is actually valid and can be handled
  // in rbacRules, as checked in rbacHoc checkAllowed function
  const ssoProfileUserRole =
    ssoProfileResp?.user_role && ssoProfileResp.user_role.length > 0
      ? // Find the element that match one of our UserRoles
        ssoProfileResp.user_role.find((role: UserRoles) => Object.values(UserRoles).includes(role))
      : 'invalid'

  return {
    userRole: ssoProfileUserRole,
    // TODO: can remove country from featureMatrix?
    country: BusinessRegistrationCountry.SINGAPORE,
    isHighRiskMerchant: merchantDetailsResp.high_risk,
    businessVerificationStatus: verificationSummary.businessVerificationStatus,
    accountManagerVerificationStatus: verificationSummary.amVerificationStatus,
  } as FeatureMatrix
}

const getWrappedResponses = (
  isPermissionsMocked: boolean,
  mocks: any,
  responses: {
    ssoProfileResp: any
    merchantDetailsResp: any
    verificationSummary: {
      amVerificationStatus: VerificationStatusType
      businessVerificationStatus: VerificationStatusType
    }
  }
) => {
  const { ssoProfileResp, merchantDetailsResp, verificationSummary } = responses

  if (!mocks || !isPermissionsMocked) {
    return [ssoProfileResp, merchantDetailsResp, verificationSummary]
  }

  const { businessVerificationStatus, accountManagerVerificationStatus, role, isHighRisk } = mocks

  const mockSsoResp = { ...ssoProfileResp, user_role: [role] }

  const amVerificationStatus = accountManagerVerificationStatus
  const mockVerificationSummary = {
    ...verificationSummary,
    businessVerificationStatus,
    amVerificationStatus,
  }

  const mockMerchantDetailsResp = {
    ...merchantDetailsResp,
    account_manager: {
      ...merchantDetailsResp.account_manager,
      verification_status: accountManagerVerificationStatus,
    },
    high_risk: isHighRisk,
  }

  return [mockSsoResp, mockMerchantDetailsResp, mockVerificationSummary]
}

const getUserWrappedResponse = (isMocked: boolean, mocks: any, userProfileResp: any) => {
  if (!mocks || !isMocked) return userProfileResp

  const { balance } = mocks

  const balanceNum = +balance
  const mockUserProfileResp = {
    ...userProfileResp,
    accounts: userProfileResp.accounts.map((account: any) => {
      if (account.id === WALLET_TYPES.GENERAL) {
        return {
          ...account,
          formatted_balance: `$${balanceNum.toFixed(2)}`,
          formatted_available_balance: `$${balanceNum.toFixed(2)}`,
          formatted_total_balance: `$${balanceNum.toFixed(2)}`,
          balance: `${balanceNum.toFixed(1)}`,
          total_balance: `${balanceNum.toFixed(1)}`,
        }
      }
      return account
    }),
  }

  return mockUserProfileResp
}
