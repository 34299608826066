import { composeWithDevTools } from '@redux-devtools/extension'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import { customerCard } from '~/rootStore/customerCard/reducer'
import { featureGate } from '~/rootStore/featureGate/reducer'
import { flippers } from '~/rootStore/flippers/reducer'
import { wallets } from '~/rootStore/wallets/reducer'
import { internalTools } from '~/sharedModules/InternalTools/reducer'
import { requestForInformation } from '~/sharedModules/RequestForInformation/reducer'
import { landingPage } from './LandingPage/reducer'
import { businessVerification } from './businessVerification/reducer'
import { deposit } from './deposit/reducer'
import { depositRequests } from './depositRequests/reducer'
import { developerTools } from './developerTools/reducer'
import { disbursements } from './disbursements/reducer'
import { saveDisbursementMiddleware } from './disbursements/storage'
import { earnAccountDetails } from './earnAccountDetails/reducer'
import { earnTransaction } from './earnTransaction/reducer'
import { invoicing } from './invoicing/reducer'
import { invoicingDetails } from './invoicingDetails/reducer'
import { myAccount } from './myAccount/reducer'
import { payTransfer } from './payTransfer/reducer'
import { physicalCardholder } from './physicalCardholder/reducer'
import { receive } from './receive/reducer'
import { route } from './route/reducer'
import { sgdAccount } from './sgdAccount/reducer'
import { simulateDeposit } from './simulateDeposit/reducer'
import { termLoan } from './termLoan/reducer'
import { transactionDetails } from './transactionDetails/reducer'
import { transactions } from './transactions/reducer'
import { usdAccountAgreement } from './usdAccount/agreement/reducer'
import { usdAccountTransactions } from './usdAccount/transactions/reducer'
import { verification } from './verification/reducer'
import { withdraw } from './withdraw/reducer'

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: [],
}

const businessVerificationPersistConfig = {
  key: 'businessVerification',
  storage,
  whitelist: ['acraBusinessInformation', 'acraApiTries'], // acra business info & acra tries state persisted, nested state
}

const withdrawPersistConfig = {
  key: 'withdraw',
  storage,
  whitelist: ['internationalTransfer', 'whitelisted'], // internationalTransfer & whitelisted state persisted, nested state
}

const routePersistConfig = {
  key: 'route',
  storage,
  whitelist: [''],
}

const landingPagePersistConfig = {
  key: 'landingPage',
  storage,
  whitelist: ['isHomepageBannerDismissed'],
}

export const resetStore = async () => {
  await persistor.purge()
  await persistor.flush()
}

export const rootReducer = combineReducers({
  disbursements,
  myAccount,
  transactionDetails,
  deposit,
  depositRequests,
  simulateDeposit,
  withdraw: persistReducer(withdrawPersistConfig, withdraw),
  verification,
  developerTools,
  internalTools,
  businessVerification: persistReducer(businessVerificationPersistConfig, businessVerification),
  landingPage: persistReducer(landingPagePersistConfig, landingPage),
  route: persistReducer(routePersistConfig, route),
  transactions,
  sgdAccount,
  invoicing,
  invoicingDetails,
  earnTransaction,
  earnAccountDetails,
  usdAccountTransactions,
  usdAccountAgreement,
  receive,
  requestForInformation,
  flippers,
  payTransfer,
  wallets,
  physicalCardholder,
  featureGate,
  termLoan,
  customerCard,
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)
const middlewares = [thunk, saveDisbursementMiddleware]
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)))
const persistor = persistStore(store)

export { persistor, store }
