import type { Moment } from '~/types'

export enum SCREENS {
  STATUS = 'STATUS',
  PERSONAL_DETAILS = 'PERSONAL_DETAILS',
  KTP_PHOTO = 'KTP_PHOTO',
  KTP_CHECK = 'KTP_CHECK',
  CLAIM_KYC_ERROR = 'CLAIM_KYC_ERROR',
  REVIEW = 'REVIEW',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum VERIFICATION_INDO_ACTIONS {
  SET_USER_DETAILS = 'VERIFICATION_INDO_ACTIONS.SET_USER_DETAILS',
  UPDATE_USER = 'VERIFICATION_INDO_ACTIONS.UPDATE_USER',
  SET_SCREEN = 'VERIFICATION_INDO_ACTIONS.SET_SCREEN',
  SET_MULTIPLE_USER_DETAILS = 'VERIFICATION_INDO_ACTIONS.SET_MULTIPLE_USER_DETAILS',
  SET_KYC_REJECTED_REASON = 'VERIFICATION_INDO_ACTIONS.SET_KYC_REJECTED_REASON',
  SET_RETRIEVED_DETAILS = 'VERIFICATION_INDO_ACTIONS.SET_RETRIEVED_DETAILS',
  SET_ERROR = 'VERIFICATION_INDO_ACTIONS.SET_ERROR',
}

export type State = {
  userDetails: UserDetails
  error: string
  screen: SCREENS | undefined
  kycRejectedReason: string
  retrievedDetails: RetrievedDetails | undefined
}

export type RetrievedDetails = {
  userId: string
  verificationStatus: string
  kycRejectedReason: string | { [key: string]: string }
  accountLocked: boolean

  idNumber?: string
  fullName?: string
  placeOfBirth?: string
  dateOfBirth?: Moment
  isDirector?: boolean

  idFrontUrl?: string
  idFrontUploaded: boolean

  selfie2idUrl?: string
  selfieUploaded: boolean
}

export type UserDetails = {
  idNumber?: string
  fullName?: string
  placeOfBirth?: string
  dateOfBirth?: Moment
  isDirector?: string[]
  ktpFile?: {
    fileData?: string
    fileName?: string
    getUrl?: string
    signedId?: string
  }
  selfieKtpFile?: {
    fileData?: string
    fileName?: string
    getUrl?: string
    signedId?: string
  }
}

type SetUserDetails = {
  type: typeof VERIFICATION_INDO_ACTIONS.SET_USER_DETAILS
  key: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
}

type UpdateUser = {
  type: typeof VERIFICATION_INDO_ACTIONS.UPDATE_USER
  error: string
}

type SetScreen = {
  type: typeof VERIFICATION_INDO_ACTIONS.SET_SCREEN
  screen: SCREENS
}

type SetMultipleUserDetails = {
  type: typeof VERIFICATION_INDO_ACTIONS.SET_MULTIPLE_USER_DETAILS
  userDetails: UserDetails
}

type SetKycRejectedReason = {
  type: typeof VERIFICATION_INDO_ACTIONS.SET_KYC_REJECTED_REASON
  kycRejectedReason: string
}

type SetRetrievedDetails = {
  type: typeof VERIFICATION_INDO_ACTIONS.SET_RETRIEVED_DETAILS
  retrievedDetails: RetrievedDetails
}

type SetError = {
  type: typeof VERIFICATION_INDO_ACTIONS.SET_ERROR
  error: string
}

export type ActionTypes =
  | SetUserDetails
  | UpdateUser
  | SetScreen
  | SetMultipleUserDetails
  | SetKycRejectedReason
  | SetRetrievedDetails
  | SetError
