import { SVGProps } from 'react'

function SvgFaq(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#Faq_svg__a)" fill="currentColor">
        <path d="M10.003 2.083a7.916 7.916 0 0 1 1.542 15.682A7.916 7.916 0 0 1 2.085 10a7.926 7.926 0 0 1 7.918-7.917Zm0-1.25a9.168 9.168 0 0 0-1.791 18.158A9.167 9.167 0 1 0 10.003.833Z" />
        <path d="M9.901 12.033a.633.633 0 0 1-.625-.625 2.317 2.317 0 0 1 1.1-2.15c.27-.18.5-.412.676-.683a1.458 1.458 0 0 0 0-1.208.992.992 0 0 0-.958-.442 1.225 1.225 0 0 0-.934.308 1.092 1.092 0 0 0-.266.734.626.626 0 0 1-1.25.075 2.392 2.392 0 0 1 .592-1.667 2.45 2.45 0 0 1 1.858-.708 2.216 2.216 0 0 1 2 1.083 2.666 2.666 0 0 1 0 2.5 3.483 3.483 0 0 1-1 1.05 1.125 1.125 0 0 0-.6 1.15.634.634 0 0 1-.593.583ZM9.984 14.292a.717.717 0 1 0 0-1.434.717.717 0 0 0 0 1.434Z" />
      </g>
      <defs>
        <clipPath id="Faq_svg__a">
          <path fill="#fff" transform="translate(.003) skewX(-.01)" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgFaq
